import React, { useEffect, useState } from "react";
import OnboardingHeader from "../../SpaceOnBoarding/OnboardingHeader";
import OnboardingFooter from "../../SpaceOnBoarding/OnboardingFooter";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { _l } from "../../../hooks/utilities";
import TenantType from "./TenantType";
import ViewTenants from "./ViewTenants";
import CreateTenant from "./CreateTenant";
import CustomerServices from "../../../services/customer-services";
import CommanLoader from "../../Loader/CommanLoader";
import customerServices from "../../../services/customer-services";
import { useDispatch, useSelector } from "react-redux";
import { leftPanelFilters } from "../../../actions/customer";

let actionType;
let role;

const AddTenants = () => {
  const {leftPanelTab, isTenantsDataVaild} = useSelector((state) => state.customer);

  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  let spaceId = searchParams.get("space");
  localStorage.setItem("selectedOffice", spaceId);
  const userType = localStorage.getItem("user_type");

  let spaceData = localStorage.getItem("spaces");
  spaceData = spaceData ? JSON.parse(spaceData) : [];
  let spaceDetails = spaceData.find((w) => w.project_id == spaceId);

  const [currentStep, setCurrentStep] = useState(isTenantsDataVaild && leftPanelTab === "my-tenants" ? "add-contact" : "select-tenant-type");
  const [headerText, setHeaderText] = useState("");
  const [titlePrefix, setTitlePrefix] = useState("");
  const [headerDescriptionText, setheaderDescriptionText] = useState("");
  const [backButtonText, setBackButtonText] = useState("");
  const [nextButtonText, setNextButtonText] = useState("");
  const [skipButtonText, setSkipButtonText] = useState("");
  const [tenantsData, setTenantsData] = useState({
    countryCode: "+33",
    email: "",
    firstname: "",
    lastname: "",
    manager: 0,
    phonenumber: "",
    role: role == "lease-contact" ? 5 : 0,
    assigned_project_ids: [spaceId],
    team_project_id: spaceId,
    tenantsList: [],
    team_members: [],
    team_name: "",
    teamId: 0,
    position : ""
  });
  const [teamsList, setTeamsList] = useState([]);
  const [loader, setLoader] = useState(false);

  const getJSX = (step) => {
    if (mapJSXwithSteps.hasOwnProperty(step)) {
      return mapJSXwithSteps[step];
    } else {
      return <></>;
    }
  };

  const handleStepChange = (buttonId) => {
    //Button Ids, 1 = next, 0 = skip, -1 = back
    switch (currentStep) {
      case "select-tenant-type":
        if (buttonId > 0) {
          setCurrentStep(
            actionType === "create-team" ? "create-team" : "add-contact"
          );
        } else {
          navigate(`/explore-features?space=${spaceId}&setting-menu=people&section=people-list`);
        }
      break;
      case "add-employee":
      case "add-contact":
        if(localStorage.getItem("is_registered") == 1){
          // const clientId = localStorage.getItem("client_id");
          // const contactId = localStorage.getItem("contact_id");
          
          // customerServices.getCustomerSpaces(clientId, contactId).then((res) => {
          //   if (res && res.status) {
          //     let spaces = res.data && res.data.length > -1 ? res.data : [];
          //     if(userType == "contact"){
          //       let is_onboarding = spaces.filter((space)=>space.is_onboard_completed_contact != 1)
          //       if (is_onboarding.length == res.data.length) {
                  navigate("/aichat")
          //       }else{
          //         navigate(`/explore-features?space=${spaceId}&setting-menu=people&section=people-list`);
          //       }

          //     }else if(userType == "operator"){
          //       let is_onboarding = spaces.filter((space)=>space.is_onboard_completed_operator != 1)
          //       if (is_onboarding.length == res.data.length) {
          //         navigate("/aichat")
          //       }else{
          //         navigate(`/explore-features?space=${spaceId}&setting-menu=people&section=people-list`);

          //       }
          //     }
              
          //   }
          // })

        } else if(buttonId > 0) {
          navigate(`/explore-features?space=${spaceId}&setting-menu=people&section=people-list`);
        }
        else {
          if(location.pathname === "/providers")
          {
            const categoryId = searchParams.get("category");
            const categoryTitle = searchParams.get("categoryName");

            navigate( `/connect-provider?space=${spaceId}&provider-type=invite_provider&categoryName=${encodeURIComponent(
              categoryTitle
            )}&categoryId=${categoryId}`);
            return;
          }
          else {
            setCurrentStep( "select-tenant-type");
          }
        }
        if (buttonId < 0) {
          searchParams.delete("actionType");
          setSearchParams(searchParams);
        }
        break;
      case "view-existing-tenants":
        if (buttonId > 0) {
          navigate(`/explore-features?space=${spaceId}&setting-menu=people&section=people-list`);
        } else {
          setCurrentStep("add-contact");
        }
        break;
      case "create-team":
        if (buttonId > 0) {
          navigate(`/explore-features?space=${spaceId}&setting-menu=people&section=people-list`);
        } else {
          setCurrentStep("select-tenant-type");
          searchParams.delete("actionType");
          setSearchParams(searchParams);
        }
        break;
      default:
        break;
    }
  };

  const getTeamsList = async () => {
    try {
      setLoader(true)
      const response = await CustomerServices.getTeams();
      if (response.status) {
        setTeamsList(response.data);
      }
      const employee = await CustomerServices.getCompanyEmployee(
        "",
        1,
        spaceId
      );
      if (employee.status && employee.data.length) {
        setTenantsData({ ...tenantsData, tenantsList: employee.data});
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(false)
    }
  };

  const getEmployeesList = () => {

  };

  const mapJSXwithSteps = {
    "select-tenant-type": (
      <div className="d-flex justify-content-center flex-wrap">
        <TenantType />
      </div>
    ),
    "add-contact": (
      <CreateTenant
        setTenantsData={setTenantsData}
        tenantsData={tenantsData}
        projectId={spaceId}
        showTeams={false}
        getTenantsList={getEmployeesList}
        setHeaderText={setHeaderText}
        setheaderDescriptionText={setheaderDescriptionText}
        setTitlePrefix={setTitlePrefix}
      />
    ),
    "add-employee": (
      <CreateTenant
        setTenantsData={setTenantsData}
        tenantsData={tenantsData}
        projectId={spaceId}
        showTeams={false}
        getTenantsList={getEmployeesList}
        setHeaderText={setHeaderText}
        setheaderDescriptionText={setheaderDescriptionText}
        setTitlePrefix={setTitlePrefix}
      />
    ),
    "view-existing-tenants": <ViewTenants tenants={tenantsData.tenantsList} />,
    "create-team": (
      <CreateTenant
        getTeamList={getTeamsList}
        showTeams={true}
        teamList={teamsList}
        setTenantsData={setTenantsData}
        tenantsData={tenantsData}
        projectId={spaceId}
      />
    ),
  };

  useEffect(() => {
    const titleElement = document.getElementById("header-page-title");
    const addressElement = document.getElementById("header-page-address");

    if (titleElement && addressElement) {
      titleElement.textContent = _l("l_tenants"); //To capitalize the first letter
      addressElement.textContent = spaceDetails
        ? spaceDetails.postal_address.replace(/^./, (str) => str.toUpperCase())
        : "";
    }
    getTeamsList();
  }, []);
  
  useEffect(() => {
    let newHeaderText = "";
    let newHeaderDescription = "";
    let newNextButtonText = "";
    let newSkipButtonText = "";
    let newBackButtonText = "";

    switch (currentStep) {
      case "select-tenant-type":
        newHeaderText = userType === "operator" ? "l_select_type_of_employee" : "l_select_type_of_tenant";
        newHeaderDescription = "l_description";
        newNextButtonText = "l_next";
        newBackButtonText = "";
        break;
      case "add-employee":
      case "add-contact":
        newHeaderText = userType === "operator" ? "l_add_employee" : "l_add_tenant";
        newHeaderDescription = location.pathname === "/providers" ? "l_add_provider_description" : (userType === "operator" ? "l_add_employee_description" : "l_add_contact_description");
        newBackButtonText = localStorage.getItem("is_registered") == 1  ? "" : "l_back";
        newNextButtonText = localStorage.getItem("is_registered") == 1  ? "l_go_to_chat_screen" : "l_tenant_setting";
        break;
      case "view-existing-tenants":
        newBackButtonText = "l_add_tenants";
        newNextButtonText = "l_tenant_setting";
        break;
      case "create-team":
        newHeaderText = tenantsData.teamId > 0 ? "l_edit_team" : "l_create_team";
        newHeaderDescription = "l_create_team_description";
        newBackButtonText = "l_back";
        newNextButtonText = "l_tenant_setting";
        break;
      default:
        break;
    }

    setHeaderText(newHeaderText);
    setheaderDescriptionText(newHeaderDescription);
    setNextButtonText(newNextButtonText);
    setSkipButtonText(newSkipButtonText);
    setBackButtonText(newBackButtonText);
    setTitlePrefix("");
  }, [currentStep, tenantsData.teamId]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    actionType = queryParams.get("actionType");
    role = queryParams.get("role");
    if (actionType) {
      setCurrentStep(actionType);
    }
    if (role == "lease-contact") {
      setTenantsData({...tenantsData,role : 5})
    }
  }, [location.search]);

  return (
    <div
      className={`onboarding-wrapper-main d-flex m-auto h-100 flex-column onboarding-with-bg ${["create-team"].includes(currentStep) || (["add-contact", "add-employee"].includes(currentStep) &&tenantsData && tenantsData.tenantsList.length) ? "with-modified-width" : ""}`}
    >
      <OnboardingHeader
        description={headerDescriptionText}
        title={headerText}
        titlePrefix={titlePrefix}
        backBtnHandler={() => {
          if (location.state && location.state.from) {
            navigate(location.state && location.state.from ? location.state.from : "/",{state: {from: currentStep}})
          }else{
            handleStepChange(-1);
          }
        }}
        backButtonText={backButtonText}
        hideBackButton={false}
        loader={false}
        loadingNextStep={false}
        nextBtnHandler={() => {
          handleStepChange(1);
        }}
        nextButtonText={nextButtonText}
      />
      <div
        className={`onboarding-content-scroll-wrapper overflow-hiiden-auto flex-column`}
      >
        {getJSX(currentStep)}
      </div>
      <OnboardingFooter
        backBtnHandler={() => {
          // if (location.state && location.state.from) {
          //   navigate(location.state && location.state.from ? location.state.from : "/",{state: {from: currentStep}})
          // }else{
            handleStepChange(-1);
          // }
        }}
        backButtonText={backButtonText}
        currentStep={""}
        hideBackButton={false}
        loader={false}
        loadingNextStep={false}
        nextBtnHandler={() => {
          handleStepChange(1);
        }}
        nextButtonText={nextButtonText}
        skipButtonHandler={() => {
          handleStepChange(0);
        }}
        skipButtonText={skipButtonText}
        totalSteps={4}
      />
    </div>
  );
};

export default AddTenants;
