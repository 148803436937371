import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import OnboardingHeader from "../../SpaceOnBoarding/OnboardingHeader";
import OnboardingFooter from "../../SpaceOnBoarding/OnboardingFooter";
import { useDispatch } from "react-redux";
import { getDefaultTopics, setProject } from "../../../actions/customer";
import EquipmentsServices from "../../../services/equipments-services";
import { _l, showError } from "../../../hooks/utilities";
import SelectEquipmentAction from "./SelectEquipmentAction";
import EquipmentsList from "./EquipmentsList";
import EquipmentDetail from "./EquipmentDetail";
import EquipmentAssignment from "./EquipmentAssignment";
import { getFloorDetails } from "../../../actions/benchmark-actions/benchmark-actions";
import ChecklistScreen from "./CommonComponents/ChecklistScreen";
import Fullcalendar from "../../../components/Fullcalendar";
import BenchmarkServices from "../../../services/benchmark-services";
import CustomerServices from "../../../services/customer-services";
import CommanLoader from "../../Loader/CommanLoader";
import AddBulkEquipments from "../../Modals/AddBulkEquipments";
import { showConfirmation, toastCloseAction } from "../../../actions/messages";
import moment from "moment";

const EQUIPMENT_STEPS = {
  BACK: 0,
  NEXT: 1,
  SKIP: 2,
};

const VALID_EQUIPMENTS_ACTIONS = ["add_equipments", "find_equipments"];

const FULL_WIDTH_SCREENS = ["equipment-checklist"];

const CHECKLIST_ITEM_TEMPLATE = {
  action_count: 0,
  assignee_id: 0,
  assignee_name: "",
  assignee_image: "",
  assigned_items: [],
  available_from: "",
  available_items: [],
  comment: "",
  description: "",
  days: {
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
    monthly: false,
    quarterly: false,
    yearly: false,
    half_yearly: false,
  },
  assigned_days: {
    mon: -1,
    tue: -1,
    wed: -1,
    thu: -1,
    fri: -1,
    sat: -1,
    sun: -1,
    monthly: -1,
    quarterly: -1,
    yearly: -1,
    half_yearly: -1,
  },
  time_slot: {
    mon: "",
    tue: "",
    wed: "",
    thu: "",
    fri: "",
    sat: "",
    sun: "",
    monthly: "",
    quarterly: "",
    yearly: "",
    half_yearly: "",
  },
  frequency: [],
  selected_count: 0,
  id: 0,
};

const EquipmentMainScreen = () => {
  let spaces = localStorage.getItem("spaces");
  spaces = spaces ? JSON.parse(spaces) : [];

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const itemDetailRef = useRef();
  const isConsumableRef = useRef();


  const projectId = searchParams.get("space");

  const [currentStep, setCurrentStep] = useState(searchParams.get("action") || "select-equipment-action");
  const [headerContent, setHeaderContent] = useState({
    title: "l_title",
    description: "l_description",
  });
  const [buttonContent, setButtonContent] = useState({
    nextButtonText: "",
    backButtonText: "",
    skipButtonText: "",
  });
  const [loadingNextStep, setLoadingNextStep] = useState(false);
  const [equipmentData, setEquipmentData] = useState({
    action: "add_equipments",
    title: "",
    id: Number(searchParams.get("equipment")),
    schedule_id: 0,
    imagePreviews: [],
    quantity: 0,
    equipmentItems: [],
    provider_assignee: 0,
    contact_assignee: 0,
    project_id: projectId,
    is_consumable:0,
    minimum_quantity: 0,
    notification_time : "",
  });
  const [equipmentList, setEquipmentList] = useState([]);
  const [equipmentChecklist, setEquipmentChecklist] = useState([
    { ...CHECKLIST_ITEM_TEMPLATE },
  ]);
  const [showSetupScreen, setShowSetupScreen] = useState(false);
  const [spaceData, setSpaceData] = useState();
  const [hideHeader, setHideHeader] = useState(false);
  const [hideFooter, setHideFooter] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [uploadBulkEquipments, setUploadBulkEquipments] = useState(false);
  const [isAddOperationOnGoing, setIsAddOperationOnGoing] = useState(false);

  const getEquipmentList = async () => {
    try {
      setShowLoader(true);
      const response = await EquipmentsServices.getEquimenntsList(projectId);
      if (response.status) {
        setEquipmentList(response.data);
      } else {
        showError(response.message);
      }
    } catch (e) {
      console.error(e);
    }
    finally {
      setShowLoader(false);
    }
  };

  const deleteEquipment = (id) => {
    dispatch(
      showConfirmation(
        _l("l_delete"),
        _l("l_delete_equipment_confirmation"),
        _l("l_delete"),
        _l("l_cancel"),
        () => {},
        async () => {
          dispatch(toastCloseAction());
          try {
            const response = await EquipmentsServices.deleteEquipment(
              id,
              projectId
            );
            if (response.status) {
              setEquipmentList(equipmentList.filter((w) => w.id != id));
            } else {
              showError(response.message);
            }
          } catch (e) {
            console.error(e);
          }
        }
      )
    );
  };


  const getEquipmentChecklists = async (id) => {
    try {
      setShowLoader(true);
      const response = await CustomerServices.getBenchmarkWeekSchedule(id);
      if (response.status) {
        let updatedChecklists = equipmentChecklist.slice().filter((w) => w.id == 0);
        updatedChecklists = updatedChecklists.concat(response.data.week_schedule_details.map((checklist) => {
          return {...checklist, description: checklist.item_name, type_of_space_item_id: undefined, room_id: undefined}
        }));
        updatedChecklists = updatedChecklists.filter(
          (obj, index, self) =>
            index ===
            self.findIndex(
              (o) =>
                (obj.description == o.description) ||
                (obj.description == "" && o.description == "")
            )
        );
        updatedChecklists.sort((a, b) => (!a.description && b.description) ? 1 : (a.description === b.description) ? 0 : -1);
        setEquipmentChecklist(updatedChecklists);
      } else {
        showError(response.message);
      }
    } catch (e) {
      showError("l_something_went_wrong");
      console.error(e);
    }
    finally {
      setShowLoader(false);
    }
  };

  const getEquipmentDetail = async (id, changeStep = true) => {
    try {
      setShowLoader(changeStep);
      const response = await EquipmentsServices.getEquipmentDetails(
        projectId,
        id
      );
      if (response.status) {
        const { data } = response;
        setEquipmentData({
          ...equipmentData,
          ...data,
          title: data.equipment_name,
          equipmentItems: data.quantity_details
        });
        if (changeStep) {
          setCurrentStep("equipment-details");
          setButtonContent({
            ...buttonContent,
            // backButtonText: "l_back",
            nextButtonText: "l_next",
          });
        }
        searchParams.set("equipment", id);
        if (data.schedule_id > 0) {
          searchParams.set("id", data.schedule_id);
          getEquipmentChecklists(data.schedule_id);
        }
        setSearchParams(searchParams);
      } else {
        showError(response.message);
      }
    }
    catch(e)
    {
      console.error(e);
      showError("l_something_went_wrong");
    }
    finally {
      setShowLoader(false);
    }
  };

  const validateChecklistItems = () => {
    let flag = true;
    if (
      !equipmentChecklist.length ||
      !equipmentChecklist.filter(
        (w) => w.description && w.description.trim().length
      ).length
    ) {
      navigate(`/explore-features?space=${projectId}&setting-menu=equipments&section=all`);
      return false;
    } else if (
      equipmentChecklist.filter(
        (w) =>
          w.description &&
          w.description.trim().length &&
          !Object.values(w.days).filter((z) => z).length
      ).length
    ) {
      //Frequency related validation
      showError("l_please_select_checklist_frequency");
      flag = false;
    }
    return flag;
  };

  const addChecklistItemsToEquipment = async () => {
    if (validateChecklistItems() && equipmentData.schedule_id > 0) {
      let formattedChecklist = [];
      equipmentChecklist.forEach((checklist) => {
        if (checklist.description) {
          formattedChecklist.push({
            id: checklist.id || 0,
            slot: checklist.slot,
            slot_title: checklist.slot_title,
            room_id: 0,
            floor_id: 0,
            item_name: checklist.description,
            checklist: [
              {
                name: checklist.description,
                photo: 0,
                selected: 1,
                addedFromTemplate: false,
                unique_id: checklist.id,
                is_default_checklist: false,
                tasks_per_month: 1,
              },
            ],
            days: checklist.days,
            priority: checklist.priority,
            comments: checklist.item_comments,
            photo_required: checklist.photo_required,
          });
        }
      });
      try {
        setShowLoader(true);
        const response = await BenchmarkServices.addBenchmarkChecklists(
          equipmentData.schedule_id,
          projectId,
          formattedChecklist,
          0,
          0,
          equipmentData.id
        );
        if (response.status) {
          setCurrentStep("equipment-tasks");
          setHideHeader(false);
          setHideFooter(false);
        } else {
          showError(response.message);
        }
      } catch (e) {
        console.error(e);
        showError("l_something_went_wrong");
      } finally {
        setShowLoader(false);
      }
    }
  };

  const selectEquipmentAction = (action, step) => {
    if (!VALID_EQUIPMENTS_ACTIONS.includes(action)) {
      showError("l_invalid_action");
    } else {
      setEquipmentData({
        ...equipmentData,
        action,
      });
      setButtonContent({
        ...buttonContent,
        backButtonText: "l_back",
        nextButtonText: "l_next",
      });
      if (step === EQUIPMENT_STEPS.NEXT) {
        setCurrentStep("equipment-list");
      } else {
        navigate(`/explore-features?space=${projectId}&setting-menu=equipments&section=all`);
      }
    }
  };

  const selectEquipment = async (title, id, step) => {
    if (step === EQUIPMENT_STEPS.BACK) {
      setCurrentStep("select-equipment-action");
      return;
    } else if (!title || !title.trim().length) {
      showError("l_invalid_equipment_title");
      return;
    }
    try {
      setIsAddOperationOnGoing(true);
      if (id > 0) {
        await getEquipmentDetail(id);
      } else {
        const response = await EquipmentsServices.addNewEquipment(
          projectId,
          title,
          "",
          "",
          "",
          "",
          equipmentList.is_consumable
        );
        if (response.status && response.data.equipments_id > 0) {
          setEquipmentList(equipmentList.concat([{title, id: response.data.equipments_id}]));
          setEquipmentData({...equipmentData, title: ""});
        } else {
          showError(response.message);
        }
      }
    } catch (e) {
      showError("l_something_went_wrong");
      console.error(e);
    }
    finally {
      setIsAddOperationOnGoing(false);
    }
  };

  const setupEquipment = (button) => {
    switch (button) {
      case EQUIPMENT_STEPS.BACK:
        if (showSetupScreen) {
          setShowSetupScreen(false);
        } else {
          setCurrentStep("equipment-list");
          setButtonContent({...buttonContent, backButtonText: "", nextButtonText: ""});
          searchParams.delete("equipment");
          setSearchParams(searchParams);
          setEquipmentData({...equipmentData, id: 0, title: ""});
        }
        break;
      case EQUIPMENT_STEPS.NEXT:
        if (showSetupScreen) {
          updateEquipmentItemDetails();
        } else {
          setCurrentStep("equipment-assignment");
          setButtonContent({
            ...buttonContent,
            backButtonText: "l_back"
          });
        }
        break;
    }
  };

  const assignEquipments = async (button) => {
    if (showSetupScreen) {
      setShowSetupScreen(false);
      return;
    }
    switch (button) {
      case EQUIPMENT_STEPS.BACK:
        setCurrentStep("equipment-details");
        setButtonContent({...buttonContent, backButtonText: ""});
        break;
      case EQUIPMENT_STEPS.NEXT:
        if (equipmentData.schedule_id <= 0) {
          try {
            const response = await EquipmentsServices.createEquipmentSchedule(
              equipmentData.id,
              projectId
            );
            if (response.status) {
              setEquipmentData({
                ...equipmentData,
                schedule_id: response.data.benchmark_id,
              });
              searchParams.set("id", response.data.benchmark_id);
              setSearchParams(searchParams);
            } else {
              showError(response.message);
              return;
            }
          } catch (e) {
            console.error(e);
            return;
          }
        }
        setCurrentStep("equipment-checklist");
        setHideHeader(true);
        setHideFooter(true);
        break;
    }
  };

  const setEquipmentsChecklist = (button) => {
    switch (button) {
      case EQUIPMENT_STEPS.BACK:
        setCurrentStep("equipment-assignment");
        setHideHeader(false);
        setHideFooter(false);
        break;
      case EQUIPMENT_STEPS.NEXT:
        addChecklistItemsToEquipment();
        break;
    }
  };

  const viewEquipmentCalendar = (button) => {
    switch (button) {
      case EQUIPMENT_STEPS.BACK:
        setCurrentStep("equipment-checklist");
        setHideHeader(true);
        setHideFooter(true);
        break;
      case EQUIPMENT_STEPS.NEXT:
        dispatch(getDefaultTopics(projectId)).then(() => {
          navigate(`/explore-features?space=${projectId}&setting-menu=equipments&section=all`);
        });
        break;
    }
  };

  const handleStepChange = (button) => {
    switch (currentStep) {
      case "select-equipment-action":
        selectEquipmentAction(equipmentData.action, button);
        break;
      case "equipment-list":
        selectEquipment(equipmentData.title, equipmentData.id, button);
        break;
      case "equipment-details":
        setupEquipment(button);
        break;
      case "equipment-assignment":
        assignEquipments(button);
        break;
      case "equipment-checklist":
        setEquipmentsChecklist(button);
        break;
      case "equipment-tasks":
        viewEquipmentCalendar(button);
        break;
    }
  };

  const deleteEquipmentItem = async (equipmentItemId) => {
    if (equipmentItemId || equipmentData.equipmentItems.length) {
      try {
        let recordId = equipmentItemId || equipmentData.equipmentItems.pop().id;
        const response = await EquipmentsServices.deleteEquipmentQuantity(
          equipmentData.id,
          equipmentItemId || recordId,
          projectId
        );
        if (response.status) {
          const updatedEquipmentList = equipmentData.equipmentItems.filter(
            (w) => w.id != recordId
          );
          setEquipmentData({
            ...equipmentData,
            equipmentItems: updatedEquipmentList,
            quantity: updatedEquipmentList.length,
          });
        } else {
          showError(response.message);
        }
      } catch (e) {
        console.error(e);
        showError("l_something_went_wrong");
      }
    }
  };

  const addNewEquipmentItem = async () => {
    try {
      const response = await EquipmentsServices.addEquipmentQuantity(
        projectId,
        equipmentData.id,
        1
      );
      if (response.status) {
        setEquipmentData({
          ...equipmentData,
          // quantity: Number(equipmentData.quantity) + 1,
          quantity: response.data.length,
          equipmentItems: response.data,
        });
      } else {
        showError(response.message);
      }
    } catch (e) {
      console.error(e);
      showError("l_something_went_wrong");
    }
  };

  const updateEquipmentDetail = async (field, value) => {
    try {
      const response = await EquipmentsServices.updateEquipmentDetail(
        projectId,
        equipmentData.id,
        field,
        value,
        isConsumableRef && isConsumableRef.current && isConsumableRef.current.checked,
        equipmentData.minimum_quantity ? equipmentData.minimum_quantity : 0,
      );
      if (response.status) {
        let key = field === "name" ? "title" : field;
        setEquipmentData({ ...equipmentData, [key]: value });
      } else {
        showError(response.message);
      }
    } catch (e) {
      console.error(e);
      showError("l_something_went_wrong");
    }
  };

  const assignToContact = (option) => {
    if (option.value && option.value !== equipmentData.contact_assignee) {
      updateEquipmentDetail("contact_assignee", option.value);
    }
  };

  const assignToProvider = (option) => {
    if (option.value && option.value !== equipmentData.contact_assignee) {
      updateEquipmentDetail("provider_assignee", option.value);
    }
  };

  const editEquipmentTitle = async (value, is_consumable = false,date = "") => {
    if (value && value.trim() !== equipmentData.title.trim()) {
      await updateEquipmentDetail("name", value.trim());
      await getEquipmentDetail(equipmentData.id);
    }else if(is_consumable){
      await updateEquipmentDetail(value,is_consumable,date );
      await getEquipmentDetail(equipmentData.id);
    }
  };

  const updateEquipmentItemDetails = async () => {
    try {
      const itemData =
        itemDetailRef.current.getUpdatedEquipmentQuantityDetails();
      const response = await EquipmentsServices.updateEquipmentItem(
        projectId,
        equipmentData.id,
        itemData.id,
        itemData.deviceId,
        itemData.itemName,
        itemData.contact_assignee,
        itemData.provider_assignee
      );
      if (response.status) {
        await getEquipmentDetail(equipmentData.id);
      } else {
        showError(response.message);
      }
    } catch (e) {
      showError("l_something_went_wrong");
      console.error(e);
    } finally {
      setShowSetupScreen(false);
    }
  };

  const assignEquipmentToSpace = async (assignmentData) => {
    let flag = false;
    try {
      const response = await EquipmentsServices.assignEquipmentToSpace(
        equipmentData.id,
        assignmentData.id,
        projectId,
        assignmentData.checked ? assignmentData.selected_space_item_id : 0
      );
      if (response.status) {
        await getEquipmentDetail(equipmentData.id, false);
        flag = true;
      } else {
        showError(response.message);
      }
    } catch (e) {
      showError("l_something_went_wrong");
      console.error(e);
    }
    finally {
      return flag;
    }
  };

  const removeChecklistItem = (checklist) => {
    BenchmarkServices.removeScheduleChecklistItem(
      equipmentData.schedule_id,
      projectId,
      [checklist.id]
    );
  };

  const bulkChecklistDelete = (itemIds) => {
    BenchmarkServices.removeScheduleChecklistItem(
      equipmentData.schedule_id,
      projectId,
      itemIds
    ).then((res) => {
      if (res.status) {
        getEquipmentChecklists(equipmentData.schedule_id);
      }
    });
  };

  const ActiveComponent = {
    "select-equipment-action": (
      <div className="d-flex justify-content-center">
        <SelectEquipmentAction
          data={{ value: equipmentData.action }}
          onChange={(e) => {
            selectEquipmentAction(e.target.value, EQUIPMENT_STEPS.NEXT);
          }}
        />
      </div>
    ),
    "equipment-list": (
      <EquipmentsList
        projectId={projectId}
        equipmentData={equipmentData}
        list={equipmentList.map((w) => {
          return {...w, label: w.title, value: w.id };
        })}
        onClick={(label, value) => {
          selectEquipment(label, value, EQUIPMENT_STEPS.NEXT);
        }}
        setEquipmentData={setEquipmentData}
        setUploadBulkEquipments={setUploadBulkEquipments}
        isLoading={isAddOperationOnGoing}
        deleteEquipment={deleteEquipment}
      />
    ),
    "equipment-details": (
      <EquipmentDetail
        equipmentData={equipmentData}
        addNewEquipmentItem={addNewEquipmentItem}
        deleteEquipmentItem={deleteEquipmentItem}
        assignToContact={assignToContact}
        assignToProvider={assignToProvider}
        editEquipmentTitle={editEquipmentTitle}
        showSetupScreen={showSetupScreen}
        setShowSetupScreen={setShowSetupScreen}
        saveEquipmentDetails={updateEquipmentItemDetails}
        ref={itemDetailRef}
        setEquipmentData={setEquipmentData}
        isConsumableRef = {isConsumableRef}
      />
    ),
    "equipment-assignment": (
      <EquipmentAssignment
        equipmentData={equipmentData}
        showSetupScreen={showSetupScreen}
        setShowSetupScreen={setShowSetupScreen}
        spaceData={spaceData}
        assignEquipmentToSpace={assignEquipmentToSpace}
      />
    ),
    "equipment-checklist": (
      <ChecklistScreen
        checklists={equipmentChecklist}
        data={equipmentData}
        kpiCounts={[
          { title: "l_quantity", count: equipmentData && equipmentData.equipmentItems ? equipmentData.equipmentItems.length : 0 },
        ]}
        setChecklists={setEquipmentChecklist}
        readOnly={false}
        stepper={{
          totalSteps: 5,
          currentStep: 4,
        }}
        handleStepChange={handleStepChange}
        bulkChecklistDelete={bulkChecklistDelete}
        singleChecklistDelete={removeChecklistItem}
      />
    ),
    "equipment-tasks": 
    <div className="h-100">
    <Fullcalendar />,
    </div>
  };

  useEffect(() => {
    if (
      !projectId ||
      isNaN(projectId) ||
      !Number(projectId) ||
      !spaces.length ||
      !spaces.find((w) => w.project_id == projectId)
    ) {
      navigate(-1);
    } else {
      localStorage.setItem("selectedOffice", projectId);
      dispatch(setProject(projectId));
      dispatch(getFloorDetails(projectId));
      setSpaceData(spaces.find((w) => w.project_id == projectId));
      const equipmentId = searchParams.get("equipment");
      if (equipmentId) {
        getEquipmentDetail(equipmentId);
      }
    }
  }, []);

  useEffect(() => {
    let headerTitle = "";
    let headerDescription = "";

    switch(currentStep)
    {
      case "select-equipment-action":
        headerTitle = "equipment_step_1_title";
        headerDescription = "equipment_step_1_description";
        break;
      case "equipment-list":
        headerTitle = "equipment_step_2_title";
        headerDescription = "equipment_step_2_description";
        break;
      case "equipment-details":
        headerTitle = "equipment_step_3_title";
        headerDescription = "equipment_step_3_description";
        break;
      case "equipment-assignment":
        headerTitle = "equipment_step_4_title";
        headerDescription = "equipment_step_4_description";
        break;
      case "equipment-checklist":
        headerTitle = "equipment_step_5_title";
        headerDescription = "equipment_step_5_description";
        break;
      case "equipment-tasks":
        headerTitle = "equipment_step_6_title";
        headerDescription = "equipment_step_6_description";
        break;
    }
    setHeaderContent({
      description: headerDescription,
      title: headerTitle
    });

  }, [currentStep])
  
  return (
    <React.Fragment>
     
        <div
          className={`onboarding-wrapper-main d-flex m-auto h-100 flex-column onboarding-with-bg ${[].includes(currentStep) ? "with-modified-width" : ""} ${
            FULL_WIDTH_SCREENS.includes(currentStep) ? "w-100" : ""
          }`}
        >
          {hideHeader ? (
            <React.Fragment></React.Fragment>
          ) : (
            <OnboardingHeader
              description={headerContent.description}
              title={headerContent.title}
              backButtonText={buttonContent.backButtonText}
              backBtnHandler={() => handleStepChange(EQUIPMENT_STEPS["BACK"])}
              hideBackButton={!buttonContent.backButtonText.length}
              loadingNextStep={loadingNextStep}
              nextBtnHandler={() => handleStepChange(EQUIPMENT_STEPS["NEXT"])}
              nextButtonText={buttonContent.nextButtonText}
            />
          )}
          <div
            className={`onboarding-content-scroll-wrapper overflow-hiiden-auto flex-column`}
          >
            {ActiveComponent[currentStep]}
          </div>
          {hideFooter ? (
            <React.Fragment></React.Fragment>
          ) : (
            <OnboardingFooter
              backButtonText={buttonContent.backButtonText}
              backBtnHandler={() => handleStepChange(EQUIPMENT_STEPS["BACK"])}
              currentStep={
                Object.keys(ActiveComponent).findIndex(
                  (w) => w === currentStep
                ) + 1
              }
              hideBackButton={!buttonContent.backButtonText.length}
              loadingNextStep={loadingNextStep}
              nextBtnHandler={() => handleStepChange(EQUIPMENT_STEPS["NEXT"])}
              nextButtonText={buttonContent.nextButtonText}
              skipButtonHandler={() =>
                handleStepChange(EQUIPMENT_STEPS["SKIP"])
              }
              skipButtonText={buttonContent.skipButtonText}
              totalSteps={Object.keys(ActiveComponent).length}
            />
          )}
          {
            uploadBulkEquipments
            ?
            <AddBulkEquipments handleClose={() => setUploadBulkEquipments(false)} setShow={setUploadBulkEquipments} show={uploadBulkEquipments} projectId={projectId} />
            :
            <React.Fragment></React.Fragment>
          }
        </div>
      
    </React.Fragment>
  );
};

export default EquipmentMainScreen;
