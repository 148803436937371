import React from "react";
import { _l } from "../../hooks/utilities";
import Button from "react-bootstrap/Button";
import { ArrowLeft, CaretLeft, CaretRight, ClockCounterClockwise, MapPin } from "phosphor-react";
import Spinner from "react-bootstrap/Spinner";
import GeneralListing from "../TaskModalComponents/GeneralListing";
import ReactTooltip from "react-tooltip";

const OnboardingHeader = ({
  title,
  description,
  status,
  statusClass,
  titlePrefix = "",
  nextButtonText,
  backButtonText,
  backBtnHandler,
  nextBtnHandler,
  skipButtonHandler,
  hideBackButton,
  skipButtonText,
  loadingNextStep,
  loader,
  hideNextButton = false,
  alignHeaderContentLeft,
  hideSkipButton,
  showContractbtn = false,
  from = "",
  subAddContract,
  spaceRecords = []
}) => {
  return (
    <>
      <div className={`onboarding-header text-center p-20 position-relative ${title === "l_edit_profile" ? "px-0 pt-20pximp" : ""}`}>
        <div className="d-flex align-items-center justify-content-between">
          <div className={alignHeaderContentLeft ? "d-none " : ""}>
            <div
              className={` d-flex ${!backButtonText ? "d-none" : ""}`}
              // style={{ top: "40%" }}
            >
              {backButtonText && !hideBackButton ? (
                <a href="#/"
                  className="border-0 btn c-font color-white f-15 title-fonts btn-sm text-nowrap ps-0"
                  onClick={backBtnHandler}
                >
                  <ArrowLeft size={18} weight="light" className="c-icons" />
                  <span className="ms-2">{_l(backButtonText)}</span>
                </a>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </div>
          </div>
          {/* <Button variant="secondary" size="" className="position-absolute start-0 d-flex h40w40 p-0" style={{top:"40%"}}><CaretLeft size={16} className="c-icons" /></Button> */}
          {/* <Button variant="secondary" size="" className="position-absolute end-0 mb-3 d-flex h40w40 p-0" style={{top:"40%"}}><CaretRight size={16} className="c-icons" /></Button> */}
          <div className={` ${alignHeaderContentLeft ? "p-0" : "px-3" } `}>
            <div className={`c-font fw-semibold title-fonts ${alignHeaderContentLeft ? "f-14 pt-10px" : "py-2 f-20"}`}>
              {`${_l(title)} ${titlePrefix}`}
              <span className={`badge rounded-pill ms-2 ${statusClass}`}>
                {status}
              </span>
            </div>
            <div className={`c-font f-16 sub-header py-1 text-center ${alignHeaderContentLeft ? "d-none" : ""} `}>
              <span>{_l(description)}</span>
            </div>
          </div>
          <div className={`${from === "space-details" ? "d-flex gap-2" : ""}`}>
            {
              from === "space-details"
                ? 
                <div className="comman_action_icon">
                  <div className="action_icon with_bg h32w32"
                      data-class="tooltip-main"
                      data-tip={_l("l_location_history")}
                      data-place="top"
                      data-delay-show='500'
                      data-effect="solid"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false">
                      <ClockCounterClockwise size={18}  weight="light" className="c-icons" />
                      <GeneralListing
                            unControlList={true}
                            list={spaceRecords.length && spaceRecords.map((space) => {
                              return {
                                name: space.postal_address,
                              };
                            })}
                            placeHolderName={_l("l_no_previous_addresses")}
                            widthClass={"w-460"}
                          />
                  </div>
                  <ReactTooltip/>
                  </div>
              : <></>
            }

            <div
              className={`d-flex ${!nextButtonText ? "d-none" : ""}`}
              // style={{ top: "40%" }}
            >
              {hideNextButton ? (
                <></>
              ) : (
                <div className="d-flex right-side-button ms-auto align-items-center">
                  {/* {
                    showContractbtn ?  
                    <Button variant="secondary btn-sm"
                      className={` color-white-60  title-fonts text-nowrap ms-10px me-10px`}
                      onClick={subAddContract}
                    >
                      <span className="">{_l("l_sub_contract")}</span>
                    </Button>
                    : <React.Fragment></React.Fragment>
                  } */}
                  <Button
                    disabled={loadingNextStep || loader}
                    variant={"primary btn-sm"}
                    className={`text-nowrap ${!backButtonText ? "mx-auto text-nowrap" : ""}`}
                    onClick={nextBtnHandler}
                    id="footer-primary-button"
                  >
                    {loadingNextStep || loader ? (
                      <>
                       <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          className={` `}
                          aria-hidden="true"
                       />

                      <span className="ms-2">{_l("l_please_wait")}</span>
                      </>
                    ) : (
                      <span>
                        {_l(nextButtonText)}
                      </span>
                    )}
                  </Button>
                  {skipButtonText && !hideSkipButton ? (
                    <Button variant="secondary btn-sm"
                      className={` color-white-60  title-fonts text-nowrap ms-10px`}
                      onClick={skipButtonHandler}
                    >
                      <span className="">{_l(skipButtonText)}</span>
                    </Button>
                    ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </div>
              )}
            </div>
            {
             !["lease-setup", "connect-provider"].includes(from) && localStorage.getItem("is_registered") != 1 && skipButtonText  && !hideSkipButton ? (
              <Button variant="secondary btn-sm"
                className={` color-white-60  title-fonts mt-2 text-nowrap`}
                onClick={skipButtonHandler}
              >
                <span className="">{_l(skipButtonText)}</span>
              </Button>
              ) : (
              <React.Fragment></React.Fragment>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OnboardingHeader;
