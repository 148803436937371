import React, { useEffect, useState } from "react";
import LogoGreenDark from "../assets/images/tomoonfulllogo.svg";
import LogoGreenLight from "../assets/images/tomoonfulllogo.svg";
// import LogoGreenDark from "../assets/icons/LogoGreenDark";
// import LogoGreenLight from "../assets/icons/LogoGreenLight";
import { hideContactDirectory, _l } from "../hooks/utilities";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import HeaderUserDropdown from "./Dropdowns/HeaderUserDropdown";
import { CaretLeft, Sun } from "phosphor-react";
import jquery from "jquery";
import customerServices from "../services/customer-services";
import { setOpenOrCloseContactDirectoryChat, setProjectTheme } from "../actions/customer";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const ExternalHeader = ({
  spaceTitle,
  spaceAddress,
  userName,
  userDescription,
  profileImageURL,
  setlanguage,
  language,
  languageSelect,
  setLanguage,
  hideLeftPanel,
  pageName,
  fieldStep,
  changeFields,
  stepperProgress
}) => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userType = localStorage.getItem("user_type");
  const {themeColor} = useSelector((state) => state.customer)

  const languageSelects = [
    { value: "english", label: _l('l_EN') },
    { value: "french", label: _l('l_FR') },
  ];
  const [LightTheme, setLightTheme] = useState(false);
  const setTheme = (theme) => {
    customerServices.setUserTheme(theme ? 1 : 0).then((res) => {
      localStorage.setItem('selectedTheme', theme ? "light" : "dark");
      setLightTheme(theme);
      dispatch(setProjectTheme(theme ? "light" :  "dark" ))
    });
  }

  useEffect(() => {
    if (themeColor == "light") {
      jquery("html").addClass("light-theme")
      setLightTheme(true);
    }else{
      jquery("html").removeClass("light-theme")
      setLightTheme(false);
    }
  }, [themeColor])
  return (
    <>
      <div className={`external_header ${pageName === "registrationPage" ? "registrationHeader" : ""}`}>
        <div className="d-flex align-items-center h-100">
          {hideLeftPanel ? (
            <React.Fragment></React.Fragment>
          ) : (
            <div
              id="external-left-panel"
              className="fixed-left-panel office-list intro-office-list d-flex flex-column"
            >
              <div className="upper-part ">
                <div className="company-logo-box position-relative">
                  <div className="h50w50 h-100 d-flex align-items-center  position-relative">
                    <div className="comman-round-box with-bg d-flex align-items-center rounded-circle  p-5px">
                      <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle">
                      {LightTheme ? <img src={LogoGreenLight} className=""/> :  <img src={LogoGreenDark} className=""/>}
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lower-part mt-auto   ">
                <div className="position-relative left-option-box mt-0 d-flex ">
                  <div className="bg-white-05 h50w50 comman-round-box radius_3 d-flex border-transparent office-list-icon-search">
                    <div className="comman-bg-img h-100 w-100 d-flex on-hover-active-toggle-img"></div>
                  </div>
                </div>
                <div className="position-relative left-option-box mt-0 d-flex ">
                  <div className="bg-white-05 h50w50 comman-round-box radius_3 d-flex border-transparent office-list-icon-search">
                    <div className="comman-bg-img h-100 w-100 d-flex on-hover-active-toggle-img"></div>
                  </div>
                </div>
                <div className="position-relative left-option-box mt-0 d-flex ">
                  <div className="bg-white-05 h50w50 comman-round-box radius_3 d-flex border-transparent office-list-icon-search">
                    <div className="comman-bg-img h-100 w-100 d-flex on-hover-active-toggle-img"></div>
                  </div>
                </div>
                <div className="position-relative left-option-box mt-0 d-flex ">
                  <div className="bg-white-05 h50w50 comman-round-box radius_3 d-flex border-transparent office-list-icon-search">
                    <div className="comman-bg-img h-100 w-100 d-flex on-hover-active-toggle-img"></div>
                  </div>
                </div>
                <div className="position-relative left-option-box mt-0 d-flex ">
                  <div className="bg-white-05 h50w50 comman-round-box radius_3 d-flex border-transparent office-list-icon-search">
                    <div className="comman-bg-img h-100 w-100 d-flex on-hover-active-toggle-img"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={`after-login-header  ${pageName === "space-invite" ? "p20px" : pageName === "registrationPage" ? "" : "ps-0"}`}>
            <div className="extranlthemebtn" onClick={()=>{
             
              setTheme(!LightTheme);
              }}>
            <a href="#/" className=" h40w40 comman-round-box rounded-circle bg-white-05 d-flex ">
           <div
             className="comman-bg-img h-100 w-100  d-flex">
              <Sun size={18} weight="light" className="c-icons" />
             </div>
         </a>
            </div>
            <div className="d-flex align-items-center h-100">
              <div className="header-wrapper position-relative h-100 w-100">
                <div className="d-flex align-items-center justify-content-between h-100">
                  <div className="left-part  text-truncate comman_action_icon">
                    {["OnboardingProposal","hireNewAgent","OnboardingEmployee","OnboardingProposalView"].includes(pageName) ? <>
                      <a className="action_icon d-flex h45w45 with_bg light-theme-white-bg ms-20px" onClick={() => {
                        if (pageName == "OnboardingProposalView") {
                          navigate("/onboarding-proposal")
                        }else{
                          navigate("/aichat");
                          hideContactDirectory();
                          dispatch(setOpenOrCloseContactDirectoryChat(false));
                        }
                        }
                      }
                        >
                        <CaretLeft size={18} weight="light" className="c-icons" />
                      </a>
                    </> : <></>}
                    {pageName === "registrationPage" ? <>
                      {["fullName", "Congratulations"].includes(fieldStep) ? <></> : <>  
                        <a className="action_icon d-flex h45w45 with_bg light-theme-white-bg" onClick={() => changeFields(fieldStep, "back")}>
                          <CaretLeft size={18} weight="light" className="c-icons" />
                        </a>
                      </>}
                    </>
                    :
                    <><div className="fw-semibold c-font f-12 title-fonts text-truncate">
                        {spaceTitle}
                      </div><div className="c-font f-10 text-truncate">
                          {spaceAddress}
                        </div></>}
                  </div>
                  <div className={`center-part flex-grow-1 ${pageName === "registrationPage" ? "transform0" : ''}`}>
                    {pageName === "registrationPage" ? <div className="align-items-center d-flex flex-column justify-content-center">
                      <div className="c-font f-13 text-center title-fonts">
                        <span>Step {stepperProgress.step} : </span>
                        <span> {_l("l_feel_free_to_reach_out")}</span>
                      </div>
                      <div className="d-flex progress-slider pt-20px">
                        <div className="progress w_400px"><div className="progress-bar" style={{width:`${stepperProgress.rate}%`}}></div></div>
                      </div>
                    </div>
                    :
                    <a href="#/" className="header-logo position-center-element">
                    {LightTheme ? <img src={LogoGreenLight} className=""/> :  <img src={LogoGreenDark} className=""/>}
                    </a>}
                  </div>
                  <div className="right-part">
                    <div className="d-flex align-items-center justify-content-end">
                      <div className={`d-flex  text-end ${pageName === "space-invite" ? "" : "flex-column"}`}>
                        <div className="stand-alone-top p-0">
                        <Form.Group className={` c-input-box position-relative with_separator_10 ${pageName === "registrationPage" ? "before-dnone" : "before-block"}`}>
                          <Select
                            className="custom-select-menu with-filter-select me-0 border-0 min-w-auto"
                            closeMenuOnSelect={true}
                            placeholder={`${_l(language ? language : "l_FR")}`}
                            classNamePrefix="react-select"
                            options={languageSelects}
                            value={languageSelects.filter(dd=> dd.value == language)}
                            onChange={(value)=>{setLanguage(value.value)
                            setlanguage(value.value)}}
                            // menuIsOpen={true}
                          />
                        </Form.Group>
                        </div>
                        <div className="d-flex flex-column">
                        <div className="fw-semibold c-font f-12 title-fonts text-truncate">
                          {userName}
                        </div>
                        <div className="c-font f-10 text-truncate">
                          {userDescription}
                        </div>
                        </div>
                      </div>
                      <Form.Group className="me-2 c-input-box position-relative d-none">
                        <Select
                          className="custom-select-menu with-filter-select me-0"
                          closeMenuOnSelect={true}
                          placeholder={`${_l(
                            language ? language : "l_FR"
                          )}`}
                          classNamePrefix="react-select"
                          options={languageSelect}
                          value={languageSelect.filter(
                            (dd) => dd.value == language
                          )}
                          onChange={(value) => {
                            setLanguage(value.value);
                            setlanguage(value.value);
                          }}
                        />
                      </Form.Group>
                      <div className={`position-relative dropdown dropdown-center d-flex no-after ms-0 ${pageName === "registrationPage" ? "" : "h-user-part"} `}>
                      {pageName === "registrationPage" ?
                      <div className="d-flex align-items-center">
                        <div className="px-3 d-none"><a href="#/" className="color-green f-14 c-font">Skip</a></div>
                        <Button variant="primary d-none" className="h45w45 w-auto px-4 ms-20px"  >{_l("l_next")}</Button>
                      </div>
                      :
                        <><a href="#/" className={`h40w40 comman-round-box with-bg d-flex align-items-center rounded-circle border-dashed ${userType === "contact" ? "contact_user" : ""} p-5px m-auto`}
                            type="button"
                            id="headerUserDropdown"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main"
                            data-bs-auto-close="outside"
                            data-place="left"
                          >
                            <div
                              className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                              style={{
                                backgroundImage: `url('${profileImageURL}')`,
                              }}
                            ></div>
                          </a>
                          <HeaderUserDropdown isExternalHeader={true} user_name={userName} userDescription={userDescription} profileImageURL={profileImageURL} />
                          </>
                          }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExternalHeader;
