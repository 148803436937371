import React, { useEffect, useState } from "react";
import LoaderVideo from "../../assets/images/myr_new_loader.gif";
import { _l } from "../../hooks/utilities";
const CommanLoader = (props) => {
  const LoaderClass 	= (props.className) ? props.className : "";
  return (
    <>
      <div className={`main-loader-wrapper full-screen with-opacity up-left-panel ${LoaderClass}`}>
          <div className="w-100 h-100 d-flex">
            <div className="loader-video">
              <div className="sk-circle">
                <div className="sk-circle1 sk-child"></div>
                <div className="sk-circle2 sk-child"></div>
                <div className="sk-circle3 sk-child"></div>
                <div className="sk-circle4 sk-child"></div>
                <div className="sk-circle5 sk-child"></div>
                <div className="sk-circle6 sk-child"></div>
                <div className="sk-circle7 sk-child"></div>
                <div className="sk-circle8 sk-child"></div>
                <div className="sk-circle9 sk-child"></div>
                <div className="sk-circle10 sk-child"></div>
                <div className="sk-circle11 sk-child"></div>
                <div className="sk-circle12 sk-child"></div>
            </div>
            {/* <div class="loading-bar">
              <div class="progress-bar"></div>
            </div> */}
              <img className="d-none" src={LoaderVideo} alt={LoaderVideo} />
            {/* <video  autostart="true" autoPlay="true" loop="true" src={LoaderVideo} type={LoaderVideo}/> */}
              {/* <div className="loader-text pt-15px fw-semibold">{props.message ? _l(props.message) : _l("l_please_wait")}</div> */}
              <div className="loader-text">{props.message ? _l(props.message) : _l("l_please_wait")}</div>
            </div>
          </div>
      </div>
    </>
  );
};

export default CommanLoader;