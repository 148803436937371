import { Check, CheckCircle, Hash, MagnifyingGlass } from "phosphor-react";
import React, { useState } from "react";
import { Button, Col } from "react-bootstrap";
import Autocomplete from "../../Dropdowns/Autocomplete";
import { _l, showError } from "../../../hooks/utilities";
import CommanPlaceholder from "../../Placeholder/CommanPlaceholder";

const ListingWithCheckbox = ({ list, setList, readOnly, handleAddNewCategory, isRegister = false }) => {
  const [inputValue, setInputValue] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const addNewCategory = () => {
    if (
      !list.find(
        (w) =>
          inputValue.trim().length &&
          w.label.trim().toLowerCase() === inputValue.trim().toLowerCase()
      )
    ) {
      handleAddNewCategory(inputValue.trim());
    } else {
      showError("l_already_exists");
    }
  };

  const CategoryListing = list.map((item, index) => {
    if (
      searchInput &&
      searchInput.trim().length &&
      item.label &&
      !item.label
        .trim()
        .toLowerCase()
        .includes(searchInput.trim().toLowerCase())
    ) {
      return <React.Fragment></React.Fragment>;
    }
    return (
      <Col xs={4} key={index}>
        <div className="select-input-cox p-20 w-100">
          <input
            className="bottom-0 end-0 form-check-input h-100 position-absolute start-0 top-0 w-100 bg-transparent"
            name="provider-categories"
            type="checkbox"
            checked={item.checked}
            disabled={readOnly}
            onChange={(e) => {
              setList(
                Object.values({
                  ...list,
                  [index]: { ...list[index], checked: e.target.checked },
                })
              );
            }}
          />
          <a href="#/" className="check-image">
            <div className="check-image-box">
              <Check size={14} className="c-icons color-white-03-solid" />
            </div>
          </a>
          <div className="align-items-center d-flex">
            <div className="h25w25">
              <Hash size={22} weight="light" className="c-icons" />
            </div>
            <div className="c-font f-14 text-truncate  ps-2">{item.label}</div>
          </div>
        </div>
      </Col>
    );
  });

  return (
    <React.Fragment>
      <div className="row my-0 g-4">
        {readOnly ? (
          <React.Fragment></React.Fragment>
        ) : (
          <div className={`d-flex justify-content-end m-0 position-sticky top-0 z-index-2 ${isRegister ? "remove-img " : "bg-white-03-solid-imp"}`}>
            <div className="d-flex align-items-center justify-content-between w-100 ">
              <div class={`d-flex justify-content-between flex-grow-1 ${isRegister ? "" : "align-items-center "}`}>
                <div className="flex-grow-1 bg-white-03-solid" key={-1}>
                  <div className="align-items-center comment-input-box d-flex form-control">
                    <div className="align-items-center d-flex flex-grow-1 position-relative">
                      <div className="pe-2">
                        <MagnifyingGlass
                          size={18}
                          weight="light"
                          className="c-icons"
                        />
                      </div>
                      <input
                        type="text"
                        placeholder={_l("l_type_to_search")}
                        value={searchInput}
                        onChange={(e) => {
                          setSearchInput(e.target.value);
                        }}
                        className=" p-0 bg-transparent border-0   pe-5 shadow-none "
                      />
                      {/* {searchInput.length ? (
                        <a href="#/"
                          className="send-arrow-btn with_position_right_zero"
                          onClick={() => {
                            setSearchInput("");
                          }}
                        >
                          <div className="img-width-18 m-auto">
                            <X size={16} weight="light" className="c-icons" />
                          </div>
                        </a>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )} */}
                    </div>
                  </div>
                </div>
                <div className="" key={-2}>
                  <div className={`comment-input-box d-flex ms-2 h-100  ${isRegister ? "" : "align-items-center"}"`}>
                    <div className="align-items-center d-flex flex-grow-1 position-relative">
                      <Button
                        className="p-2 h-100 px-3"
                        variant="primary"
                        disabled={!inputValue.length}
                        onClick={addNewCategory}
                      >
                        {_l("l_add")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <React.Fragment>
          {readOnly ? (
            <React.Fragment></React.Fragment>
          ) : (
            <Col xs={4} key={-1}>
              <div className="select-input-cox p-20 w-100">
                <div className="align-items-center d-flex">
                  <div className="h25w25">
                    <Hash size={22} weight="light" className="c-icons" />
                    <Autocomplete
                      placeholder={_l("l_add_category")}
                      inputClass="form-control bottom-0 end-0 h-100 position-absolute start-0 top-0 w-100 c-font f-14 text-truncate ps-5 bg-transparent"
                      dropdownClass="max-height-20vh start-0 mt-3"
                      value={inputValue}
                      onChange={(e) => {
                        setInputValue(e.target.value);
                      }}
                      onSelect={(value) => {
                        setInputValue(value.label);
                      }}
                      options={[]}
                    />
                  </div>
                </div>
              </div>
            </Col>
          )}
          {list.length
          ?
          CategoryListing
          :
          <CommanPlaceholder imgType="no-benchmark"
          mainSpacing="h-100 mt-auto bg-transparent"
          placeholderText={`${_l("l_no")} ${_l("l_category")}`}/>
        }
        </React.Fragment>
      </div>
    </React.Fragment>
  );
};

export default ListingWithCheckbox;
