import React, { useEffect, useRef, useState } from "react";
import { MentionsInput, Mention } from "react-mentions";
import { CloseCommentModal, _l, getFileType, getImagePreviewIcon, getPreviewFromURL, showLeftPanelNew, stringReplacer } from "../../hooks/utilities";
import ReactTooltip from "react-tooltip";
import CommanLoader from "../Loader/CommanLoader";
import { X, ArrowRight, DownloadSimple, DotsThreeVertical, Paperclip, Smiley, ArrowBendUpLeft, ArrowBendDownLeft, PlayCircle } from "phosphor-react";
import ActionDropdown from "../Dropdowns/ActionDropdown";
import { useDispatch } from "react-redux";
import { showConfirmation, showMessage, toastCloseAction } from "../../actions/messages";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import ReactHtmlParser from "react-html-parser";
import CommanOffCanvas from "../Offcanvas/CommanOffCanvas";
import { DropdownMenu, DropdownItem } from 'reactstrap';
import jquery from "jquery";
import { mentionStyle } from "../../assets/mention/mentionStyle";
import EmojiPicker from "emoji-picker-react";
import moment from "moment";
import TaskShareLinkModal from "../Modals/TaskShareLinkModal";
import DocumentsServices from "../../services/documents-services";
import DocumentPreview from "../Documents/DocumentPreview";
import { commanoffcanvasisOpen } from "../../actions/customer";
import Lightbox from "yet-another-react-lightbox";
import { Spinner } from "react-bootstrap";
import { mentionInputStyle } from "../../assets/mention/mentionInputStyle";

let fileUplods = [];

const CommentMinified = ({
  id,
  comments,
  mentionList,
  handleAddComment,
  loading,
  disabled,
  task,
  // handleImagePreview,
  deleteComment,
  unContolList = false,
  pageName="",
  is_liteversion = false,
  showComments,
  from="",
  setReplyCommentData = () => {},
  fileLoader,
}) => {

  const dispatch = useDispatch();

  const [commentInput, setCommentInput] = useState("");
  const [commentsList, setCommentsList] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [showTaskImage, setShowTaskImage] = useState(false);
  const [attachmentDetails, setAttachmentDetails] = useState({});
  const [allAttachments, setAllAttachments] = useState([]);
  const [commentShareModalData, setCommentShareModalData] = useState({});
  const [contacts, setContacts] = useState([]);
  const [openLightBox, setOpenLightBox] = useState(false);
  const [lightBoxData, setlightBoxData] = useState([]);
  const [replyMsgcomment, setReplyMsgcomment] = useState({});

  const contact_id = localStorage.getItem("contact_id");
  const contact_image = localStorage.getItem("contact_image");
  const staff_id = localStorage.getItem("staff_id");
  let isExternal = localStorage.getItem("guest_id") ? true : false;

  let commentId = useRef(0);
  const commentRef = useRef();
  const messagesEndRef = useRef(null);
  useEffect(() => {
    scrollToBottom()
  }, [messagesEndRef]);
  const [showEmoji, setShowEmoji] = useState(false);
  const onClick = (emojiData) => {
    setCommentInput((commentInputCallback)=>commentInputCallback + emojiData.emoji)
  }
  const handleEditAction = (id) => {
    const targetComment = commentsList.filter((comment) => comment.id == id);
    if (targetComment.length) {
      setCommentInput(targetComment[0].content || targetComment[0].comment || targetComment[0].description);
      commentId.current = id;
    }
  };

  const handleDeleteAction = (comment,index) => {
    dispatch(showConfirmation(_l('l_are_you_sure'), _l('l_delete_comment'), _l('l_delete'), _l('l_cancel'), () => { }, () => { deleteComment(comment,index); dispatch(toastCloseAction()) }))
  };

  const preparePreview = (files) => {
    let previrewArray = [];
    Array.from(files).map((img) => {
      let preview_url = getImagePreviewIcon(img);
      previrewArray.push({ name: img.name, url: preview_url });
    });
    fileUplods = files;
    setImagePreviews(previrewArray);
  };

  const removeImageSelection = (name) => {
    imagePreviews.forEach((thumbnail) => {
      URL.revokeObjectURL(thumbnail.url);
    });
    const updatedFileList = Array.from(fileUplods).filter(
      (img) => img.name != name
    );

    // setImageBuffer(updatedFileList);
    preparePreview(updatedFileList);
  };

  const handleImagePreview = (comment, image, index) => {
    if(task && ["proposal", "benchmark, schedule"].includes(task.docType))
    {
      setOpenLightBox(true);
      setlightBoxData([{src: image.file_url || image.file_name, type: getFileType(image.file_url || image.file_name)}]);
    }
    else {
      let preview = {};
      preview.url = getPreviewFromURL(image.file_url || image.file_name);
      if (task && ["proposal", "benchmark, schedule"].includes(task.docType) && typeof preview.url !== "string") {
        const link = document.createElement("a");
        link.href = image.file_url;
        link.download = image.file_url || image.file_name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setShowTaskImage(true);
        dispatch(commanoffcanvasisOpen(true));
        if(pageName=="calendar"){
          showLeftPanelNew();
          jquery("body").addClass("with-right-penel");
          jquery("body").removeClass("task-modal-offcanvas-open");
          jquery("body").addClass("right-penel-no-padding");
        }
        setAttachmentDetails({
          id: image.id,
          task_id: task ? task.id : 0,
          task_comment_id: comment.id,
          image_url: image.file_url || image.file_name,
          dateadded: comment.dateadded
        });
      }
      // setAttachments({...attachments, file_array: comment.attachments, currentIndex: 0, date: comment.dateadded});
    }
  };

  const scrollToBottom = () => {
    var commentsAreaDiv = document.getElementById("conversation-area");
    try {
      // if (commentsAreaDiv.scrollHeight && commentsAreaDiv.scrollHeight != null)
      //   commentsAreaDiv.scrollTop = commentsAreaDiv.scrollHeight
      //     ? commentsAreaDiv.scrollHeight
      //     : 0;
      if(messagesEndRef && messagesEndRef.current)
      {
        messagesEndRef.current.scrollIntoView();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchRecipients = async() => {
    try {
      const response = await DocumentsServices.getCommentMailRecipients(task.proposal_id, task.docType, task.project_id);
      if(response.data && response.data.length)
      {
        setContacts(response.data.map((w) => {return {...w, full_name: w.name}}));
      }
    }
    catch(e)
    {
      console.error(e);
    }
  };

  function urlify(text) {
    var urlRegex = /(?<!")(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function(url) {
        return '<a href="' + url + '">' + url + '</a>';
    }).replace(/<a[\s]+/gm, `<a class="color-green" target="_blank"`);
  } 

  const commentReply = (messageId) => {
    setReplyCommentData(messageId)
    const targetMessage = commentsList.filter((msg) => msg.id == messageId.id);
    if (targetMessage.length > 0) {
      setReplyMsgcomment(targetMessage[0]);
    }
    jquery(".mension-box__input").focus();
    jquery(".commentDropdownclose").removeClass("show");
  };

  const handleCancleReply = () => {
    setReplyMsgcomment({});
    setReplyCommentData("");
  }
  // For Scroll
  useEffect(() => {
    if (commentsList && commentsList.length > 0) {
      commentsList.map((item) => {
        if (item.attachments && item.attachments.length > 0) {
          item.attachments.map((item2) => {
            setAllAttachments((allAttachments) => [
              ...allAttachments,
              {
                id: item2.id,
                task_id: task ? task.id : 0,
                task_comment_id: item.id,
                image_url: item2.file_name,
                dateadded: item.dateadded,
              },
            ]);
          });
        }
      });
      
    }

    if(commentsList.length)
    {
      try {
        messagesEndRef.current.scrollIntoView();
      }
      catch(e)
      {
          console.error(e);
      }
    }
  }, [commentsList])

  const CommnetminifiedContain = showComments ? (
    <React.Fragment>
      <ul className="">
        <li>
          <div className="dropdown-header">
            <div className="d-flex align-items-center justify-content-between">
              <div className="c-list-detail title-fonts text-truncate color-white c-font f-13">
                {_l("l_comments")}
              </div>
              <a href="#/" className="right-image d-none" data-bs-dismiss="dropdown">
                <X size={18} weight="light" className="c-icons opacity-60" />
              </a>
            </div>
          </div>
        </li>
        <li>
          <hr className="dropdown-divider mt-0" />
        </li>
        <li></li>
      </ul>
      <ul className="dropdown-contain overflowscroll comment-dropdown-with-min-height-20vh"  id="conversation-area">
        
          <>
            {commentsList.length ? (
              <>{
              commentsList.map((comment, index) => {

                const replacements = [
                  { keyword: '{username}', replacement: comment.mention_staff_name },
                  { keyword: '{mention_id}', replacement: comment.mention_id },
                  { keyword: '{mention_type}', replacement: comment.mention_type },
                  { keyword: '{sender_name}', replacement: comment.sender_name },
                ];
                comment.content = stringReplacer(replacements,_l(comment.content));

                let className =
                  localStorage.getItem("user_type") == "contact" && contact_id
                    ? contact_id !== comment.contact_id
                      ? ""
                      : ""
                    : staff_id == comment.staffid && staff_id
                      ? ""
                      : "";

                const commentContent = comment.content;
                const dummyDiv = document.createElement("div");
                dummyDiv.innerHTML = commentContent;
                return (
                  comment.attachments && comment.attachments.length > 0 || (comment.content != undefined || commentContent > 0 ) ?
                  <div ref={commentRef} key={index} className="chat-wrapper">
                    <li>
                      <div className={`chat-box max-w-100 ${className} ${comment.is_allowed_to_edit == 1 || is_liteversion ? "" : "" }`}>
                        <div className="d-flex">
                          <div
                            className="comman-image-box h25w25 rounded-circle"
                            data-tip={comment.full_name}
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main"
                          >
                            <div
                              className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                              style={{
                                backgroundImage: `url('${comment.profile_url}')`,
                              }}
                            ></div>
                          </div>
                          <div className="chat-msg">
                            <div className="chat-text-box">
                            <div
                                  className={`p-0 w-100 ${
                                    comment && parseInt(comment.parent_comment_id) > 0
                                      ? ""
                                      : "d-none"
                                  }`}
                                >
                                  <div className="bg-transparent  p-0 w-100">
                                    <div>
                                      <ArrowBendDownLeft
                                        size={14}
                                        className="c-icons m-auto"
                                        weight="light"
                                      />
                                    </div>
                                    <div className="border-bottom image-upload-wrapper mb-2 w-100">
                                      <div className="d-flex align-items-center flex-wrap base-body-color radius_3">
                                        {
                                          comment && comment.parent_comment_details && !comment.parent_comment_details.file_url
                                          ?
                                          <span className="p-1">{ReactHtmlParser(
                                            comment && comment.parent_comment_details && comment.parent_comment_details.content && comment.parent_comment_details.content.replace(
                                              /(?:\r\n|\r|\n)/g,
                                              "<br>"
                                            )
                                          )}</span>
                                          :
                                          <a href="#/" class="h40w40 comman-round-box with-bg d-flex align-items-center bg-white-05 p-5px radius_3  mb-2">
                                            <div
                                              className="comman-bg-img h-100 w-100 bg-style-cover"
                                              style={{
                                                backgroundImage: `url(${
                                                  comment &&
                                                  comment.parent_comment_details
                                                    ? `${comment.parent_comment_details.file_url}`
                                                    : ""
                                                })`,
                                              }}
                                            ></div>
                                          </a>
                                        }
                                      </div>
                                    </div>
                                    {/* <div className="chat-text c-font f-12">image</div> */}
                                  </div>
                                </div>
                              {/* <div className="chat-text c-font f-12"> */}
                              <div className="image-upload-wrapper">

                                {comment.attachments &&
                                  comment.attachments.length ? (
                                  <div className="d-flex align-items-center flex-wrap ">
                                    {comment.attachments.map(
                                      (image, index) => {
                                        if (index < 2) {
                                          let preview = {};
                                          preview.url = getPreviewFromURL(image.file_name);

                                          return (

                                            <>
                                            <a href="#/"
                                              
                                              className="h62w62 comman-round-box  with-bg d-flex align-items-center bg-white-05 p-5px radius_3  m-1"
                                            >
                                               <PlayCircle size={32} color="#00ff6a"  weight="light" className="c-icons" />
                                                  
                                            </a>
                                            <div class="p-1 pb-0"><div class="c-font color-white-60 f-11 ms-1">⏺️ Call Recording (Manager 1)</div></div>
                                            <a href="#/"
                                              key={index}
                                              className="h62w62 comman-round-box  with-bg d-flex align-items-center bg-white-05 p-5px radius_3  m-1  with-top-overlay"
                                            >
                                                <div className="absolute-download-icon"
                                                  onClick={() => { window.open(image.file_url || image.file_name, "_self"); } }>
                                                  <DownloadSimple size={14} color="#ffffff" weight="light" />
                                                </div>
                                                {typeof preview.url === "string"
                                                  ?
                                                  <DropdownItem toggle={true} tag="div" className="comman-bg-img h-100 w-100 bg-style-cover"
                                                    onClick={() => {
                                                      handleImagePreview(comment, image, index);
                                                    } }
                                                    style={{
                                                      backgroundImage: `url('${image.file_url || image.file_name}')`,
                                                    }}
                                                  ></DropdownItem>
                                                  :
                                                  <>
                                                    <div className="h-100 w-100 d-flex" style={{
                                                      alignContent: 'center',
                                                      flexWrap: 'wrap'
                                                    }}>
                                                      <preview.url size={72} weight="light" className="c-icons" />
                                                    </div>
                                                    <div className="max-w-130px pt-2 d-none">
                                                      <div className="c-font text-truncate f-12 color-white-60 ">
                                                        {decodeURIComponent(image.file_name.substring(image.file_name.lastIndexOf('/') + 1))}
                                                      </div>
                                                    </div>
                                                  </>}
                                              </a></>
                                          );
                                        } else if (index === 2) {
                                          return (
                                            <a href="#/"
                                              className="h62w62 comman-round-box  with-bg d-flex align-items-center bg-white-05 p-5px radius_3 m-1 with_overlay"
                                              onClick={() => {
                                                handleImagePreview(comment, image, index)
                                              }
                                              }
                                            >
                                              <div class="unread_count m-auto">
                                                +
                                                {comment.attachments
                                                  .length - 2}
                                              </div>
                                            </a>
                                          );
                                        }
                                      }
                                    )}
                                  </div>
                                ) : (
                                  comment.description != undefined || comment.content != undefined ? 
                                  <div>
                                    {ReactHtmlParser((comment.description || comment.content).replace(/(?:\r\n|\r|\n)/g, '<br>'))}
                                  </div> :<></>
                                  
                                )}
                              </div>
                              <div
                                className={`chat-action-option ${comment.is_allowed_to_edit == "0"
                                  ? ""
                                  : ""
                                  }`}
                              >
                                <a href="#/"
                                  className="line-height-0 "
                                  type="button"
                                  id="commentOptionDropdown"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  data-tip={_l("l_options")}
                                  data-effect="solid"
                                  data-delay-show='1000'
                                  data-class="tooltip-main"
                                >
                                  <DotsThreeVertical size={18} weight="light" className="c-icons" />
                                </a>
                                <ActionDropdown
                                  actions={ task && ["proposal"].includes(task.docType) ? 
                                  [
                                    {
                                      actionName: _l("l_edit"),
                                      actionHandler: () => {
                                        handleEditAction(comment.id)
                                      },
                                      actionIcon: "PencilSimpleLineIcon",
                                    },
                                    {
                                      actionName: _l("l_send_to_email"),
                                      actionHandler: () => {
                                        setCommentShareModalData(comment)
                                      },
                                      actionIcon: "EnvelopeSimpleIcon",
                                    },
                                    {
                                      actionName: _l("l_delete"),
                                      actionHandler: () => {
                                        handleDeleteAction(comment)
                                      },
                                      actionIcon: "TrashIcon",
                                    },
                                  ] 
                                  : comment.staffid == staff_id ?
                                  [
                                    {
                                      actionName: _l("l_replay"),
                                      actionHandler: () => {
                                        commentReply(comment)
                                      },
                                      actionIcon: "ReplyIcon",
                                    },
                                    {
                                      actionName: _l("l_edit"),
                                      actionHandler: () => {
                                        handleEditAction(comment.id)
                                      },
                                      actionIcon: "PencilSimpleLineIcon",
                                    },
                                    {
                                      actionName: _l("l_delete"),
                                      actionHandler: () => {
                                        handleDeleteAction(comment,index)
                                      },
                                      actionIcon: "TrashIcon",
                                    }
                                  ]
                                  :
                                  [
                                    {
                                      actionName: _l("l_replay"),
                                      actionHandler: () => {
                                        commentReply(comment)
                                      },
                                      actionIcon: "ReplyIcon",
                                    }
                                  ]}
                                />
                              </div>
                            </div>
                            <div className="chat-send-name c-font f-10 opacity-50">
                              <span className="d-none">
                                {comment.contact_full_name} -{" "}
                              </span>
                              <span>{moment(comment.dateadded).format("DD/MM/YYYY hh:mm A")}</span>
                              {/* <span>{comment.date}</span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <ReactTooltip />
                  </div> : <></>
                );
              })
              }</>
            ) : (
              <>
                <CommanPlaceholder imgType="no-comment" placeholderText={_l("l_no_comments")} />
              </>
            )}
          </>
        <div className="dec" ref={messagesEndRef} ></div>
      </ul>
      <ul className="dropdown-footer">
        <li>
          <hr className="dropdown-divider mb-0" />
        </li>
        <li className="">
          <React.Fragment>
            {imagePreviews && imagePreviews.length ? (
              <div className="upload-image-preview p-2 border-bottom calendar-modal-color">
                <div className="d-flex align-items-center justify-content-start">
                  {imagePreviews.map((preview, index) => {
                    if (typeof preview.url === "string") {
                      return (
                        <div
                          key={index}
                          className="comman-image-box h60w60 radius_3 upload-image with-margin"
                        >
                          <div
                            className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                            style={{
                              backgroundImage: `url(
                                  '${preview.url}'
                                )`,
                            }}
                          ></div>
                          <a href="#/"
                            className="upload-close"
                            onClick={() => {
                              // handleAction('removePreview', preview.name)
                              removeImageSelection(preview.name);
                            }}
                          >
                            <X size={10} weight="light" className="c-icons" />
                          </a>
                        </div>
                      );
                    }
                    else {
                      return (
                        <div
                          key={index}
                          className="comman-image-box h60w60 radius_3 upload-image with-margin d-flex"
                        >
                          <preview.url size={40} weight="light" className="c-icons" />
                          <a href="#/"
                            className="upload-close"
                            onClick={() => {
                              // handleAction('removePreview', preview.name)
                              removeImageSelection(preview.name);
                            }}
                          >
                            <X size={10} weight="light" className="c-icons" />
                          </a>
                        </div>
                      )
                    }
                  })}
                </div>
              </div>
            ) : (
              <></>
            )}
          </React.Fragment>
          {Object.keys(replyMsgcomment).length > 0 ?
            <div className="aicommentbox-reply px-2" id="aicommentbox-reply">
              <div className="d-flex justify-content-end p-1">
              <a href="#/" className="aicomment-reply-close"onClick={() => { handleCancleReply() }}>
                  <X weight="light" size="14" className="c-icons" />
              </a>
              </div>
              <div className="d-flex flex-wrap align-items-center">
                  <ArrowBendUpLeft weight="light" size="18" className="c-icons" />
                  <div className="overflowscroll w100minus30">
                    {replyMsgcomment.id ?
                      <div className="d-flex align-items-center justify-content-start base-body-color radius_3 overflow-hidden p-1 ">
                        <div className="color-white-60">
                          {replyMsgcomment.content != '' ?
                            ReactHtmlParser(urlify(replyMsgcomment.content.replace(/(?:\r\n|\r|\n)/g, '<br>')))
                            :
                          <a href="#/" class="h40w40 comman-round-box with-bg d-flex align-items-center bg-white-05 p-5px radius_3  mb-2">
                            <div
                              className="comman-bg-img h-100 w-100 bg-style-cover"
                              style={{
                                backgroundImage: `url(${replyMsgcomment &&
                                    replyMsgcomment.attachments[0].file_url
                                    ? `${replyMsgcomment.attachments[0].file_url}`
                                    : ""
                                  })`,
                              }}
                            ></div>
                          </a>}
                        </div>
                      </div>
                      : <></>
                    }
                  </div>
              </div>
            </div> 
            : <></>
          }
          {
            disabled
            ?
              <React.Fragment></React.Fragment>
            :
            <div className="chat-comment-box">
              <div className="comment-input-box with-mention-top">
                <div className="d-flex position-relative form-control  border-0 bg-transparent px-2 align-items-center">
                  <div
                    data-bs-toggle="tooltip"
                    className="comman-image-box h25w25 rounded-circle me-2"
                  >
                    <div
                      className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                      style={{
                        backgroundImage: `url('${contact_image}')`,
                      }}
                    ></div>
                  </div>
                  <MentionsInput
                    value={commentInput}
                    allowSpaceInQuery={true}
                    className="mension-box with-calc-95px w100minus100per withpl7px"
                    style={mentionInputStyle}
                    onChange={(e) => {
                      setCommentInput(e.target.value);
                    }}
                    placeholder={`${_l("l_write_comment")}`}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13 && !e.ctrlKey && !e.shiftKey) {
                        e.preventDefault();
                        setShowEmoji(false)
                        handleAddComment(commentInput, fileUplods, commentId.current);
                        setCommentInput("");
                        commentId.current = 0;
                        setImagePreviews([]);
                        fileUplods = [];
                        scrollToBottom();
                        handleCancleReply();
                      }
                    }}
                    disabled={disabled}
                  >
                    <Mention
                      markup='<span style="font-weight: 600;" contenteditable="false" data-mention-id="__id__"  data-mce-style="font-weight: 600;" data-mce-selected="1">@__display__</span>'
                      trigger="@"
                      data={mentionList}
                      displayTransform={(id,display) => `@${display}`}
                      style={mentionStyle}
                      renderSuggestion={(
                        suggestion,
                        search,
                        highlightedDisplay
                      ) => {
                        return (
                          <div className="user d-flex align-items-center">
                            <div className="comman-image-box h25w25 rounded-circle me-2 ">
                              <div
                                className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                style={{
                                  backgroundImage: `url('${suggestion.image}')`,
                                }}
                              ></div>
                            </div>
                            {highlightedDisplay}
                          </div>
                        );
                      }}
                    />
                  </MentionsInput>
                  <div className="d-flex">
                      <div className="comman_action_icon ">
                        <a href="#/"
                          className={`action_icon with_bg`}
                          onClick={() => { setShowEmoji(!showEmoji) }}
                        >
                          <Smiley size={18} className="c-icons" weight="light" />

                        </a>
                      </div>
                    <div className={`comman_action_icon with_separator_10 before-dnone ${from == "addequipments" ? "d-none" : ""}`}>
                      <div className="d-flex align-items-center">
                        <a href="#/" className="action_icon with_bg"
                          data-tip={_l('l_choose_file')}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                        >
                          <input
                            type="file"
                            multiple="multiple"
                            className="absolute-input"
                            accept=".png,.jpg,.pdf,.doc,.docx,.xls,.xlsx,.zip,.rar,.txt,.jpeg,.mp4,.m4r,.m4a,.mp3,.glb"
                            onChange={(event) => {
                              if (event.target.files.length > 4) {
                                dispatch(
                                  showMessage(
                                    "unsucess",
                                    _l("l_error"),
                                    _l(
                                      "l_maximum_4_files_are_allowed_at_a_time"
                                    )
                                  )
                                );
                                return;
                              }
                              preparePreview(event.target.files);
                            }}
                            onClick={(e) => {
                              e.target.value = "";
                            }}
                          />
                          <Paperclip size={16} weight="light" className="c-icons" />
                        </a>
                      </div>
                    </div>
                    <div className={`comman_action_icon 12 ${commentInput.length > 0 || fileUplods.length > 0 ? "" : "for-disabled"}`}>
                        {fileLoader ? <div className="action_icon with_bg justify-content-center align-items-center"> <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> </div>
                          :
                          <a href="#/"
                            className="action_icon with_bg"
                            onClick={() => {
                              setShowEmoji(false)
                              handleAddComment(commentInput, fileUplods, commentId.current);
                              setCommentInput("");
                              commentId.current = 0;
                              setImagePreviews([]);
                              fileUplods = [];
                              handleCancleReply();
                            }}
                          >
                            <ArrowRight size={16} weight="light" className="c-icons lt-black" />
                          </a>
                        }
                    </div>
                  </div>
                </div>
              </div>
                {showEmoji ?
                  <EmojiPicker
                    onEmojiClick={onClick}
                    autoFocusSearch={false}
                    lazyLoadEmojis={true}
                    previewConfig={{
                      // defaultEmoji: string; // defaults to: "1f60a"
                      // defaultCaption: string; // defaults to: "What's your mood?"
                      showPreview: false // defaults to: true
                    }}
                  />
                  : <></>
                }
              <div className="comment-bottom-option d-none"></div>
            </div>
          }
        </li>
      </ul>
      
      {showTaskImage && Object.keys(attachmentDetails).length > 0 && !isExternal ?
        <CommanOffCanvas
          show={showTaskImage}
          handleClose={() => {
            setShowTaskImage(false);
          }}
          data={{
            id: attachmentDetails.id,
            taskId: task.id,
            projectId: localStorage.getItem("selectedOffice"),
            attachmentDetails: attachmentDetails,
            taskDetails: task,
            allAttachments: allAttachments
          }}
          docType={"imageview"}
          setSelectedId={() => { }}
          commentList={comments}
          selectedTaskId={task.id}
          handleParentComponentAction={() => { }}
          CloseCommentModal={CloseCommentModal}
          isChat={true}
          pageName={pageName}
          isExternal={localStorage.getItem("guest_id") ? true : false}
        /> : <>{showTaskImage && Object.keys(attachmentDetails).length > 0 ? <></> : <>
        <Lightbox
        key={0}
        open={showTaskImage}
        close={() => setShowTaskImage(false)}
        slides={allAttachments.map((img) => {
          return { src: getPreviewFromURL(img.image_url) };
        })}
      />
        </>}</>
      }
    </React.Fragment>
  ) : <React.Fragment></React.Fragment>


  useEffect(() => {
    if (comments && comments.length) {
      setCommentsList(comments);
      scrollToBottom();
      comments.map((item) => {
        if (item.attachments && item.attachments.length > 0) {
          item.attachments.map((item2) => {
            setAllAttachments((allAttachments) => [...allAttachments, {
              id: item2.id,
              task_id: task ? task.id : 0,
              task_comment_id: item.id,
              image_url: item2.file_url ? item2.file_url :item2.file_name,
              dateadded: item.dateadded
            }]);
          });
        }
      });
      if(!contacts.length)
      {
        fetchRecipients();
      }
    }
    else {
      setCommentsList(comments);
    }
  }, [JSON.stringify(comments)]);

  useEffect(() => {
    if(!loading && comments && comments.length)
    {
      setTimeout(() => {
        scrollToBottom();
      }, 250);
    }
  }, [loading]);
  const setAttchmentOnTop = (id) => {
    const targetAttchement = allAttachments.filter(
      (attachment) => attachment.id === id
    );
    const otherAttachments = allAttachments.filter(
      (attachment) => attachment.id != id
    );
    setAllAttachments(targetAttchement.concat(otherAttachments));
  };

  return (
    <>
      {
        unContolList ? (
          <DropdownMenu container="body" direction="down" className={`dropdown-menu h-auto`} id={`commentDropDownOpen_${id}`}>{CommnetminifiedContain}</DropdownMenu>
        ) : (
          <ul className="dropdown-menu h-auto" aria-labelledby={`minified_comment_${id}`}
            id={`commentDropDownOpen_${id}`}>{CommnetminifiedContain}</ul>
        )
      }
      {commentShareModalData && Object.keys(commentShareModalData).length ? 
        (
          <TaskShareLinkModal
            show={true}
            handleClose={() => {setCommentShareModalData({})}}
            handleShow={() => {}}
            link={``}
            data={{...task, mentionList: mentionList, contacts }}
            docType={task.docType}
            mailForComment={commentShareModalData}
          />
        ) : (
        <></>
        )
      }
      {openLightBox && lightBoxData ? (
        <>
        <DocumentPreview
          open={openLightBox}
          setOpen={setOpenLightBox}
          slides={lightBoxData}
        />
        </>
      ) : (
        <></>
      )}
    </>

  );
};

export default CommentMinified;
