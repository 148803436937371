import React, { useEffect, useState, useRef } from "react";
import { changeTaskStatusFromChecklistItems, _l, averageTaskChecklistRatings, showError, removeDuplicateObjectsFromArray, removeDuplicateObjects } from "../../hooks/utilities";
import HighPriorityIcon from "../../assets/icons/HighPriorityIcon";
import HighPriorityActiveIcon from "../../assets/icons/HighPriorityActiveIcon";
import CommentDropdown from "../Dropdowns/CommentDropdown";
import FollowersDropdown from "../Dropdowns/FollowersDropdown";
import CustomerServices from "../../services/customer-services";
import jquery from "jquery";
import {
  getTaskChecklist,
  checkListUpdate,
  updateedTaskChecklist,
  setBulkTaskRating,
  setEditScheduleJsonData,
  setEquipmentConsumbleCheckList,
} from "../../actions/customer";
import Toasts from "../Toasts/Toasts";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import CommanLoader from "../Loader/CommanLoader";
import { useParams, useSearchParams } from "react-router-dom";
import {
  showConfirmation,
  showMessage,
  toastCloseAction,
} from "../../actions/messages";
import {
  ArrowRight,
  ChatCircle,
  UserCircleGear,
  X,
  CaretDoubleDown,
  CaretRight,
  MagnifyingGlass,
  Funnel,
  DotsNine,
  Star,
  Trash,
  CalendarBlank,
  FadersHorizontal,
  Wrench,
  Camera,
} from "phosphor-react";
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { FloorPlanSummary } from "../SpaceOnBoarding/SpaceDetails/FloorPlanSummary";
import ActionDropdown from "../Dropdowns/ActionDropdown";
import StarRatingDropdown from "./StarRatingDropdown";
import { Accordion } from "react-bootstrap";
import GeneralListing from "./GeneralListing";
import CommanOffCanvas from "../Offcanvas/CommanOffCanvas";
import Lightbox from "yet-another-react-lightbox";
import { updateSpaceCoefficient } from "../../actions/benchmark-actions/benchmark-actions";
import DatePicker from "react-datepicker";
import moment from "moment";
import { callAction } from "../../actions/chat";
import UnControlMainFiltersDropDown from "./LeadsMainFiltersDropDown";


const ChecklistComponent = ({
  task_id,
  projectId,
  isChecked,
  setIsChecked,
  setRequestChecklist,
  isSystemTask = null,
  toastClose,
  closeAllTost,
  isRequestTask = 0,
  setCheckListCount,
  checklistFilter=[],
  updateRequestTitle,
  isExternal = false,
  checklistAssigneesList,
  showFullscreen = false,
  fullHeight = true,
  noModalSpace=false,
  setChecklistFilter=()=>{},
  actionMenuItems,
  data,
  docType="",
  step="",
  isAllowToEdit,
  iscallModal = false,
}) => {

  const [checklistItems, setChecklistItems] = useState([]);
  const [addChecklistItem, setAddChecklistItem] = useState("");
  const [addChecklistItemEvent, setAddChecklistItemEvent] = useState("");
  const [disableOnClickEvent, setDisableOnClickEvent] = useState(false);
  const [openAttachmentsDropdown, setOpenAttachmentsDropdown] = useState(false);
  const [checkListTexts, setCheckListTexts] = useState([]);
  const [openLinksDropdown, setOpenLinksDropdown] = useState(false);
  const [openCommentTab, setOpenCommentTab] = useState(false);
  const [openFollowersDropdown, setOpenFollowersDropdown] = useState(false);
  const [updatedLinkCount, setUpdatedLinkCount] = useState(0);
  const [updatedCommentsCount, setUpdatedCommentsCount] = useState(0);
  const [updatedImagesCount, setUpdatedImagesCount] = useState(0);
  const [linkCount, setLinkCount] = useState([]);
  const [commentCount, setCommentCount] = useState([]);
  const [imageCount, setImageCount] = useState([]);
  const [TextareaHeight, setTextareaHeight] = useState([]);
  const [toastClass, setToastClass] = useState("unsucess");
  const [toastHeader, setToastHeader] = useState("");
  const [confirmButtonText, setConfirmButtonText] = useState("");
  const [cancelButtonText, setCancelButtonText] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [checkListId, setCheckListId] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [taskStatus, settaskStatus] = useState();
  const [checklistSearchString, setChecklistSearchString] = useState("")
  const [openGallery, setOpenGallery] = useState(false);
  const [lightBoxAttachments,setLightBoxAttachments] = useState([])
  //
  const inputReference = useRef(null);
  const dispatch = useDispatch();
  const checklistStateData = useSelector(
    (state) => state.customer.taskChecklist
  );
  const toastdata = useSelector((state) => state.customer.toastClose);
  const [showChecklistLoader, setShowChecklistLoader] = useState(false);
  const [CheckedInput, setCheckedInput] = useState(false);
  const { id: hash_id, page } = useParams();
  const { userTeams, selectedTask, taskList,isCallFullscreen, callComments, editedScheduleData , defaultTopics} = useSelector((state) => state.customer);
  const { callStatus, isCallRecived } = useSelector((state) => state.chat);

  const { floorDetails } = useSelector((state) => state.benchmarkReducer);
  const [taskPriority, setTaskPriority] = useState(
    selectedTask ? (selectedTask.priority == "3" ? 3 : 2) : 0
  );
  const [showFloorList, setShowFloorList] = useState(selectedTask ? selectedTask.floor_id > 0 : false);
  const [selectedFloor, setSelectedFloor] = useState({ id: -1, title: "" });
  const [selectedTypeOfSpace, setSelectedTypeOfSpace] = useState({id: -1});
  const [checkListIconToggle, setCheckListIconToggle] = useState(-1);
  const contact_image = localStorage.getItem("contact_image");
  const contact_id = localStorage.getItem("contact_id");
  const client_id = localStorage.getItem("client_id");
  const staff_id = localStorage.getItem("staff_id");
  const contact_role = localStorage.getItem("contact_role");
  const user_type = localStorage.getItem("user_type");
  const [ChecklistSearchToggle,setChecklistSearchToggle]= useState(false)
  // let checklistItemAssigneeList = useRef(checklistAssigneesList);
  const [checklistItemAssigneeList, setChecklistItemAssigneeList] = useState(
    []
  );
  const [loadPageNo, setLoadPageNo] = useState(1);
  const [selectedTypeOfSpaceItem, setSelectedTypeOfSpaceItem] = useState();
  const [selectedAttachment, setSelectedAttachment] = useState();
  const [showTaskImage, setShowTaskImage] = useState(false);
  const [allAttachments, setAllAttachments] = useState([]);
  const [appliedInitialFilter, setAppliedInitialFilter] = useState(false);

  let selectedTheme = localStorage.getItem("selectedTheme");
  const [isEuipmentData, setisEuipmentData] = useState([]);
  const [isEquipmentselected, setisEquipmentselected] = useState([]);
  const [euipmentModals, seteuipmentModals] = useState(-1)
  const [totalEquipments, setTotalEquipments] = useState([])
  const itemsPerPage = 10;
  const selectedDate =
    localStorage.getItem("calendarData") &&
    localStorage.getItem("calendarData") != "undefined" && 
    localStorage.getItem("calendarData").length > 0
      ? JSON.parse(localStorage.getItem("calendarData"))[3].date
      : new Date();

  const [searchParams] = useSearchParams();

  const scheduleChecklistId = searchParams.get("update-schedule-checklist");
  const checklistDay = searchParams.get("task-day");
  const operator = searchParams.get("operator");
  const view = searchParams.get("view");
  const handleAddChecklistItem = (e) => {
    let checklistArr = [];
    if (e.type == "paste") {
      checklistArr = e.clipboardData.getData("Text").split("\n");
    }
    checklistArr = e.target.value.split("\n");
    let newArr = [];
    for (let i = 0; i < checklistArr.length; i++) {
      if (checklistArr[i].trim().length) {
        newArr.push({ description: checklistArr[i], finished: 0, type_of_space_item_id: selectedTypeOfSpaceItem ? selectedTypeOfSpaceItem.id : 0, item_id: selectedTypeOfSpace.id >= 0 ? selectedTypeOfSpace.id : 0 });
      }
    }
    if (scheduleChecklistId) {
      jquery("#create_new_checklist").each(function () {
        jquery(this).css("height", "32px");
      });
      let checklistText = e.target.value;
      let newList = [];
      // for (let j = 0; j < res.data.checklist_data.length; j++) {
        newList.push({
          addedfrom: contact_id,
          description: e.target.value.trim(),
          finished: "0",
          id: Math.floor(Math.random() * 10000000).toString().padStart(7, '0'),
          is_added_from_contact: "1",
          list_order: "0",
          req_photo: "0",
          addedfrom_details: { profile_url: contact_image, full_name : localStorage.getItem("full_name") },
          comments_count: 0,
          links_count: 0,
          attachments_count: 0,
          is_allowed_to_edit: 1,
          status: 0,
          space_item_id: selectedTypeOfSpaceItem ? selectedTypeOfSpaceItem.id : 0, 
          item_id: selectedTypeOfSpace.id >= 0 ? selectedTypeOfSpace.id : 0 ,
          dateadded : new Date(),
          start_date : new Date(),
          update_schedule_id: scheduleChecklistId,
          days: checklistDay ? {[checklistDay]: true} : {},
          item_name: checklistText.trim(),
          assignee_id: operator,
          type_of_space_item_id : selectedTypeOfSpaceItem ? selectedTypeOfSpaceItem.id : 0,
          room_id: selectedTypeOfSpace.id >= 0 ? selectedTypeOfSpace.id : 0 
        });
        // setChecklistItems((checklistItems) => [
        //   ...checklistItems,newList
        // ]);
        // dispatch(updateedTaskChecklist([...checklistStateData,newList]))
      // }
      let scheduleChecklist =   {
        "id": 0,
        "item_name": e.target.value.trim(),
        "type_of_space_item_id": selectedTypeOfSpaceItem ? selectedTypeOfSpaceItem.id : 0,
        "priority": "0",
        "photo_required": "1",
        "assignee_id": operator,
        "type_of_space_id" : selectedTypeOfSpace.id >= 0 ? selectedTypeOfSpace.id : 0 ,
        "item_slot" :"12:00:00",
        "day": checklistDay
      }
      dispatch(setEditScheduleJsonData([...editedScheduleData, scheduleChecklist]))
      dispatch(updateedTaskChecklist(checklistStateData.concat(newList)));
      setAddChecklistItem("");
      
    } else {
    CustomerServices.addChecklistItem(task_id, projectId, newArr).then(
      (res) => {
        if (res.status) {
          jquery("#create_new_checklist").each(function () {
            jquery(this).css("height", "32px");
          });
          let checklistText = checkListTexts;
          let newList = [];
          for (let j = 0; j < res.data.checklist_data.length; j++) {
            newList.push({
              addedfrom: contact_id,
              description: res.data.checklist_data[j].description.trim(),
              finished: "0",
              id: res.data.checklist_data[j].id,
              is_added_from_contact: "1",
              list_order: "0",
              req_photo: "0",
              addedfrom_details: { profile_url: contact_image, full_name : localStorage.getItem("full_name") },
              comments_count: 0,
              links_count: 0,
              attachments_count: 0,
              is_allowed_to_edit: 1,
              status: 0,
              space_item_id: selectedTypeOfSpaceItem ? selectedTypeOfSpaceItem.id : 0, 
              item_id: selectedTypeOfSpace.id >= 0 ? selectedTypeOfSpace.id : 0 ,
              dateadded : new Date(),
              start_date : new Date(),
              update_schedule_id: scheduleChecklistId,
              days: checklistDay ? {[checklistDay]: true} : {},
              item_name: res.data.checklist_data[j].description.trim(),
              assignee_id: operator,
              type_of_space_item_id: newArr[j].type_of_space_item_id,
              room_id: selectedTypeOfSpace.id >= 0 ? selectedTypeOfSpace.id : 0 
            });
            // setChecklistItems((checklistItems) => [
            //   ...checklistItems,newList
            // ]);
            // dispatch(updateedTaskChecklist([...checklistStateData,newList]))
          }
          let latestChecklistItems = checklistStateData.concat(newList);

          if (iscallModal || isCallRecived) {
            dispatch(callAction({
              userId: callStatus.callerInfo._id,
              receiverId: callStatus.receiverInfo._id,
              callRole: callStatus.callRole,
              actionKey: "newChecklist",
              actionVal: res.data.checklist_data[0].id,
              extraData : {
                  projectId : localStorage.getItem("selectedOffice"),
                  checklist : removeDuplicateObjects(checklistStateData.concat(newList)),
              }
          }));
            
          }

          if (isRequestTask == 1) {
            dispatch(checkListUpdate(latestChecklistItems));
            setCheckListCount(checklistStateData.length + 1);
          }
          if(selectedTask)
          {
            changeTaskStatusFromChecklistItems(
              latestChecklistItems.map((x) => x.finished != 0),
              selectedTask.id,
              selectedTask.status,
              projectId
            );
          }
          dispatch(updateedTaskChecklist(checklistStateData.concat(newList)));
          res.data.checklist_data.forEach((item) => {
            checklistText[item.id] = item.description.trim();
          });
          setCheckListTexts(checklistText);
          setAddChecklistItem("");
        }
      }
    );
  }
  };

  const handleAddChecklist = () => {
    // var format = /[^a-zA-Z0-9!@#$%^&*()\ \,.;]/;
    // if (addChecklistItem.trim() && !format.test(addChecklistItem)) {

    let pattern = addChecklistItem.match("<(\"[^\"]*\"|'[^']*'|[^'\">])*>");

    if (pattern === null) {
      if (addChecklistItem.trim()) {
        CustomerServices.addChecklistItem(task_id, projectId, [
          { description: addChecklistItem.trim(), finished: 0 },
        ]).then((res) => {
          if (res.status) {
            const contact_id = localStorage.getItem("contact_id");
            // setChecklistItems((checklistItems) => [
            //   ...checklistItems,
            //   {
            //     addedfrom: contact_id,
            //     description: addChecklistItem.trim(),
            //     finished: "0",
            //     id: res.data.checklist_data[0].id,
            //     is_added_from_contact: "1",
            //     list_order: "0",
            //     req_photo: "0",
            //     addedfrom_details: { profile_url: contact_image },
            //     comments_count: 0,
            //     links_count: 0,
            //     attachments_count: 0,
            //     is_allowed_to_edit: 1,
            //     status : 0
            //   },
            // ]);
            dispatch(
              updateedTaskChecklist([
                ...checklistStateData,
                {
                  addedfrom: contact_id,
                  description: addChecklistItem.trim(),
                  finished: "0",
                  id: res.data.checklist_data[0].id,
                  is_added_from_contact: "1",
                  list_order: "0",
                  req_photo: "0",
                  addedfrom_details: { profile_url: contact_image , full_name : localStorage.getItem("full_name")  },
                  comments_count: 0,
                  links_count: 0,
                  attachments_count: 0,
                  is_allowed_to_edit: 1,
                  status: 0,
                },
              ])
            );
            setCheckListTexts({
              ...checkListTexts,
              [`${res.data.checklist_data[0].id}`]: addChecklistItem.trim(),
            });

            setCommentCount({
              ...commentCount,
              [res.data.checklist_data[0].id]: 0,
            });
            setImageCount({
              ...imageCount,
              [res.data.checklist_data[0].id]: 0,
            });
            setLinkCount({ ...linkCount, [res.data.checklist_data[0].id]: 0 });
            setAddChecklistItem("");
            let latestChecklistItems = [
              ...checklistStateData,
              {
                addedfrom: contact_id,
                description: addChecklistItem.trim(),
                finished: "0",
                id: res.data.checklist_data[0].id,
                is_added_from_contact: "1",
                list_order: "0",
                req_photo: "0",
                addedfrom_details: { profile_url: contact_image , full_name : localStorage.getItem("full_name") },
                comments_count: 0,
                links_count: 0,
                attachments_count: 0,
                is_allowed_to_edit: 1,
              },
            ];
            if (isRequestTask == 1) {
              dispatch(checkListUpdate(latestChecklistItems));
              setCheckListCount(checklistItems.length + 1);
            }
            changeTaskStatusFromChecklistItems(
              latestChecklistItems.map((x) => x.finished != 0),
              selectedTask.id,
              selectedTask.status,
              projectId
            );
          }
        });
      } else if (addChecklistItem != "") {
        dispatch(
          showMessage(
            "unsucess",
            _l("l_error"),
            _l("l_please_enter_valid_checklist")
          )
        );
        // setToastHeader(_l("l_error"));
        // setToastMessage(_l("l_please_enter_valid_checklist"));
        // setConfirmButtonText("");
        // setCancelButtonText("");
        // setToastClass("unsucess");
        // setShowToast(true);
      }
    } else {
      setAddChecklistItem("");
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_please_enter_valid_checklist")
        )
      );
    }
  };
  useEffect(() => {
    if (disableOnClickEvent == "" && addChecklistItemEvent != "") {
      handleAddChecklistItem(addChecklistItemEvent);
    }
  }, [disableOnClickEvent]);
  const [tempChecklistToggle,setTempChecklistToggle]= useState();
  const [tempOpenCommentTab, setTempOpenCommentTab] = useState(false);
  // useEffect(() => {
  //   jquery(".checklist-comment, .checklist-followers-dropdown, .with-abs-position, .right-checklist-action").on("mouseleave", function () {
  //     if(jquery(".checklist-comment").hasClass("show") || jquery(".checklist-followers-dropdown").hasClass("show")){
  //     }else{
  //       setCheckListIconToggle(-1);
  //       setOpenFollowersDropdown(false);
  //       setTempOpenCommentTab(false);
  //       jquery(".dropdown-menu").removeClass("show");
  //     }
  //   });
  // }, [tempOpenCommentTab,openFollowersDropdown,checkListIconToggle]);
  useEffect(() => {
    if(isSystemTask){
    setChecklistSearchToggle(false);
    }else{
      setChecklistSearchToggle(false);
    }
  }, [isSystemTask]);
  
  const handleEdit = (id) => {
    // const input = document.querySelector(`#checklist_input_${id}`);
    // input.selectionStart = input.selectionEnd = input.value.length;
    // input.selectionStart = input.selectionEnd
    // input.focus();
  };

  useEffect(() => {
    jquery(".checklist_textarea").each(function () {
      jquery(this).css("height", "20px");
      this.style.height = this.scrollHeight + "px";
    });
  }, [checkListTexts, checklistItems, loadPageNo, showFullscreen,checklistFilter]);

  useEffect(() => {
    //
    if (task_id && typeof isChecked !== "undefined") {
      setShowChecklistLoader(true);
      setChecklistItems([]);
      dispatch(
        getTaskChecklist(task_id, projectId, isExternal ? hash_id : false)
      ).then(() => {
        setShowChecklistLoader(false);
      });
    }else{
      setChecklistItems([]);
    }
    setLoadPageNo(1);
    setSelectedTypeOfSpaceItem();
    setAppliedInitialFilter(false);
    setSelectedTypeOfSpace({id: -1});
  }, [task_id]);

  useEffect(() => {
    if (!isChecked) {
      setChecklistItems(
        checklistItems.map((x) => {
          x.showDetails = false;
          return x;
        })
      );
    }
  }, [isChecked]);

  useEffect(() => {
    setAddChecklistItem("");
    setChecklistItems([]);
    if (checklistStateData && checklistStateData.length) {
      const checklists = checklistStateData;
      let checkBoxes = [];
      let checklistInputs = [];
      let comments = [];
      let links = [];
      let images = [];
      checklists.forEach((item) => {
        checkBoxes[item.id] = item.finished === "0" ? false : true;
        comments[item.id] = item.comments_count;
        links[item.id] = item.links_count;
        images[item.id] = item.attachments_count;
        checklistInputs[item.id] = item.description;
        item.showDetails = false;
      });
      if(selectedTypeOfSpaceItem && selectedTypeOfSpaceItem.id > 0)
      {
        const spaceItemChecklists = checklists.filter((w) => w.space_item_id == selectedTypeOfSpaceItem.id && !isNaN(Number(w.rating)) && Number(w.rating) > 0);
        const rating = spaceItemChecklists.length == 0 ? 0 : spaceItemChecklists.reduce((total, next) => total + parseInt(next.rating), 0) / spaceItemChecklists.length;
        const progress = spaceItemChecklists.length == 0 ? 0 : spaceItemChecklists.filter((w) => w.finished == 1).length > 0 ? (spaceItemChecklists.filter((w) => w.finished == 1).length * 100) / spaceItemChecklists.length : 0;
        setSelectedTypeOfSpaceItem({...selectedTypeOfSpaceItem, rating, progress, count: spaceItemChecklists.length});
        setChecklistItems(checklists.filter((w) => w.space_item_id == selectedTypeOfSpaceItem.id));
      }
      else if(selectedTypeOfSpace.is_default == 1)
      {
        setChecklistItems(checklists.filter((w) => [0, 1].includes(Number(w.space_item_id))));
      }
      else {
        setChecklistItems(checklists);
      }
      if(!appliedInitialFilter && checklists.some((w) => w.floor_id > 0))
      {
        setAppliedInitialFilter(true);
        setChecklistFilter(["floor"]);
      }else if(!(checklists.some((w) => w.floor_id > 0))){
        setShowFloorList(false)
        setChecklistFilter(["all"]);
      }
      if (isRequestTask == 1) {
        setCheckListCount(checklists.length);
      }
      setCommentCount(comments);
      setLinkCount(links);
      setImageCount(images);
      setCheckListTexts(checklistInputs);
      jquery(".checklist_textarea").each(function () {
        jquery(this).css("height", "20px");
        this.style.height = this.scrollHeight + "px";
      });
    }
    updateTaskRatingCalculation();


    if (checklistStateData && checklistStateData.length) {
      checklistStateData.map((item, index) => {
        if (item.consumable_equipments_assignee && item.consumable_equipments_assignee.length > 0) {
          item.consumable_equipments_assignee.map((w)=>{
            if (totalEquipments.length == 0) {
              let obj = {
                ...w,
                qty : item.consumable_equipments_assignee.filter((item) => item.equipment_id == w.equipment_id).length,
                checkListId : item.id
              }
              setTotalEquipments([...totalEquipments,obj])
            } else {
              // totalEquipments.map((equip) => {
                if (totalEquipments.some((item) => item.equipment_id == w.equipment_id)) {
                  // don't do anything
                }else{
                  let obj = {
                    ...w,
                    qty : item.consumable_equipments_assignee.filter((item) => item.equipment_id == w.equipment_id).length,
                    checkListId : item.id
                  }
                  setTotalEquipments([...totalEquipments,obj])
                }
              // })
            }
          })
          
        }
      })
    }


   
  }, [JSON.stringify(checklistStateData)]);
  // useEffect(() => {
  //   if(isCallRecived || iscallModal){
  //     dispatch(callAction({
  //       userId: callStatus.callerInfo._id,
  //       receiverId: callStatus.receiverInfo._id,
  //       callRole: callStatus.callRole,
  //       actionKey: "newChecklist",
  //       actionVal: checklistStateData && checklistStateData.length > 0 ? checklistStateData[0].id : "test",
  //       extraData: {
  //         projectId: localStorage.getItem("selectedOffice"),
  //         checklist: removeDuplicateObjects(checklistStateData),
  //       }
  //     }));
  //   }
    
  // }, [JSON.stringify(checklistStateData)],JSON.stringify(callStatus))
  

  const handleCheckListRating = (star,review,isFrom,checklistId)=>{
    CustomerServices.addEditChecklistRating(
      projectId,
      checklistId,
      task_id,
      star,
      review,
      isFrom
    ).then((res)=>{
      if(res.status){
        const index = checklistItems.findIndex(
          (item) => item.id == checklistId
        );

        setChecklistItems(checklistItems.map((w) => {
          if(w.id == checklistId)
          {
            w.rating = star;
            w.review = review;
          }
          return w;
        }));

        let TotalRating = 0
        checklistItems.map((item)=>{
          TotalRating = TotalRating + parseInt(item.rating)
        });

        TotalRating = TotalRating / checklistItems.length
        let taskListNew = taskList;
        let indexnew = taskListNew.findIndex((d) => d.id == data.id);
        if (indexnew > -1){
          taskListNew[indexnew].rating = star;
           data.rating = TotalRating % 1 !== 0 ? TotalRating.toFixed(1) : TotalRating.toFixed(0) ;
           data.average_rating = `${averageTaskChecklistRatings()}`;
        }
        dispatch(setBulkTaskRating(taskListNew))  
      }else{
        dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)))
      }
    })

  }

  const itemFinished = (checklistData, checked, index) => {
    checklistData.finished = checked ? "1" : "0";
    handleCheckListUpdate(
      checklistData.id,
      checklistData.description,
      checklistData.finished,
      true,
      index,
      checklistData.priority,
      checklistData.status,
      checklistData.dateadded
    );
    // setChecklistItems(
    //   Object.values({ ...checklistItems, [index]: checklistData })
    // );
    let tempChecklist = checklistStateData.slice();
    let key = tempChecklist.findIndex((c) => c.id == checklistData.id);
    tempChecklist[key] = checklistData
    dispatch(updateedTaskChecklist(tempChecklist));
    const element = document.getElementById(
      `taskcard_checkbox_${checklistData.id}`
    );
    if (element) {
      const currentValue = element.checked;
      element.checked = !currentValue;
    }
    if (selectedTask) {
      let latestCheckboxValues = checklistStateData.map((x) => x.finished);
      latestCheckboxValues[index] = checked ? "1" : "0";
      const isScheduled =
        selectedTask.schedule_id != null && selectedTask.schedule_id != 0;
      changeTaskStatusFromChecklistItems(
        latestCheckboxValues.map((checkboxValue) => {
          return checkboxValue == 1;
        }),
        selectedTask.id,
        selectedTask.status,
        projectId,
        isScheduled
      );
    }
  };
  const itemPriority = (checklistId, isFinished, item, priority) => {
    CustomerServices.updateChecklistItem(
      projectId,
      task_id,
      checklistId,
      isFinished,
      item,
      priority
    ).then((res) => {
      if (res.status) {
        const index = checklistItems.findIndex(
          (item) => item.id == checklistId
        );
        let updatedChecklist = checklistItems[index];
        updatedChecklist.priority = priority;
        setChecklistItems(
          Object.values({ ...checklistItems, [index]: updatedChecklist })
        );
      }
    });
  };

  const handleTaskStatus = (
    checklistId,
    description,
    finished,
    checkUnCheck,
    index_id,
    priority,
    status
  ) => {
    const index = checklistItems.findIndex((item) => item.id == checklistId);
    let updatedChecklist = checklistItems[index];
    updatedChecklist.status = status;
    setChecklistItems(
      Object.values({ ...checklistItems, [index]: updatedChecklist })
    );
    handleCheckListUpdate(
      checklistId,
      description,
      finished,
      checkUnCheck,
      index_id,
      priority,
      status
    );
  };

  const handleCheckListUpdate = (
    id,
    value,
    isFinished,
    checkUnCheck = false,
    index = -1,
    priority,
    taskStatus,
    time,
    item_id
  ) => {
    console.trace()
    const prev =
      checklistItems && checklistItems.length
        ? checklistItems.filter((previousValue) => previousValue.id == id)
        : {};

    if (
      !checkUnCheck &&
      prev[0].addedfrom != contact_id &&
      user_type == "contact"
      && !scheduleChecklistId
    ) {
      return;
    }

    // const format = /[`_+\-=\[\]{};':"\\|<>\/?~]/;
    if (scheduleChecklistId) {
      let updatedChecklistItems = checklistStateData.slice().map((w) => {
        if(w.id == id)
        {
          w = {
            ...w,
            update_schedule_id: scheduleChecklistId,
            days: checklistDay ? {[checklistDay]: true} : {},
            item_name: value.trim(),
            description: value.trim(),
            assignee_id: operator,
            type_of_space_item_id: w.type_of_space_item_id,
          };
        }
        return w;
      });
      let scheduleChecklist  = {
        "id": item_id,
        "item_name":value,
        "priority": priority,
        "photo_required": "1",
        "day": checklistDay,
        "assignee_id":operator
      }
      dispatch(setEditScheduleJsonData([...editedScheduleData, scheduleChecklist]))
      dispatch(updateedTaskChecklist(updatedChecklistItems));
      return
    } else {    
    if (value) {
      //if (value && !format.test(value)) { //Temporary disabling the validations
      CustomerServices.updateChecklistItem(
        projectId,
        task_id,
        id,
        isFinished,
        value.trim(),
        priority,
        taskStatus,
        time
      ).then((res) => {
        if (res.status) {

          if (isRequestTask == 1 && index == 0) {
            /**To Modify the title of task request if first checklist item is modified */
            updateRequestTitle("", value); 
          }
          if (iscallModal) {
            dispatch(callAction({
              userId: callStatus.callerInfo._id,
              receiverId: callStatus.receiverInfo._id,
              callRole: callStatus.callRole,
              actionKey: "newChecklist",
              actionVal: id,
              extraData: {
                projectId: localStorage.getItem("selectedOffice"),
                checklist: removeDuplicateObjects(checklistItems)
              }
            }));
          }
          if(scheduleChecklistId > 0)
          {
            let updatedChecklistItems = checklistStateData.slice().map((w) => {
              if(w.id == id)
              {
                w = {
                  ...w,
                  update_schedule_id: scheduleChecklistId,
                  days: checklistDay ? {[checklistDay]: true} : {},
                  item_name: value.trim(),
                  description: value.trim(),
                  assignee_id: operator,
                  type_of_space_item_id: w.type_of_space_item_id,
                };
              }
              return w;
            });
            dispatch(updateedTaskChecklist(updatedChecklistItems));
          }
        }
      });
    } else if (addChecklistItem != "") {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_please_enter_valid_checklist")
        )
      );
    }
  }
  };

  const removeChecklist = (id, item_id) => {
    if (scheduleChecklistId) {
      let scheduleChecklist  = {
        "id": item_id,
        "is_delete" : "1",
        "day": checklistDay,
        "assignee_id":operator
      }
      dispatch(setEditScheduleJsonData([...editedScheduleData, scheduleChecklist]))
      dispatch(
        updateedTaskChecklist(checklistStateData.filter((item) => item.id !== id))
      );
      dispatch(toastCloseAction());

      
    } else {      
      CustomerServices.deleteChecklistItem(projectId, task_id, id).then((res) => {
        if (res.status) {
          // setChecklistItems(checklistItems.filter((item) => item.id !== id));
          dispatch(
            updateedTaskChecklist(checklistStateData.filter((item) => item.id !== id))
          );
          if (iscallModal) {
            dispatch(callAction({
              userId: callStatus.callerInfo._id,
              receiverId: callStatus.receiverInfo._id,
              callRole: callStatus.callRole,
              actionKey: "newChecklist",
              actionVal: id,
              extraData: {
                projectId: localStorage.getItem("selectedOffice"),
                checklist: removeDuplicateObjects(checklistStateData.filter((item) => item.id !== id))
              }
            }));
          }
          setAddChecklistItem("");
          let latestChecklistItems = checklistStateData.filter(
            (item) => item.id !== id
          );
          dispatch(toastCloseAction());
          if (isRequestTask == 1) {
            dispatch(checkListUpdate(latestChecklistItems));
            setCheckListCount(latestChecklistItems.length);
          }
          changeTaskStatusFromChecklistItems(
            latestChecklistItems.map((x) => x.finished != 0),
            selectedTask && selectedTask.id,
            selectedTask && selectedTask.status,
            projectId
          );
        }
      });
    }
  };

  const handleSearchCheckList = (value) =>{
    setChecklistSearchString(value)
  }
  const handleChecklistAssignee = (user, checklist_id) => {
    let assigneeId = 0;

    if (user) {
      user.profile_image_url = user.user_image || user.profile_image;
      assigneeId = user.assigneeid || user.id;
    }

    CustomerServices.updateChecklistAssignee(
      projectId,
      task_id,
      checklist_id,
      assigneeId
    ).then((res) => {
      if (res.status && res.data) {
        const index = checklistItems.findIndex(
          (item) => item.id === checklist_id
        );
        let updatedChecklist = checklistItems[index];
        updatedChecklist.assigned_details = user;
        if (user) {
          if (user_type == "contact") {
            updatedChecklist.assigned_details["id"] = user.id;
          } else {
            updatedChecklist.assigned_details["staffid"] = user.id;
          }
          updatedChecklist.assigned_details.full_name = user.name;
        }

        if (assigneeId) {
          updatedChecklist.assigned = 1;
        } else {
          updatedChecklist.assigned = 0;
        }
        setChecklistItems(
          Object.values({ ...checklistItems, [index]: updatedChecklist })
        );
      }
    });
  };
  const openToastClose = () => {
    setToastClass("unsucess");
    setToastHeader("");
    setConfirmButtonText("");
    setCancelButtonText("");
    setToastMessage(0);
    setCheckListId(0);
    setShowToast(false);
  };

  const changeTaskPriority = (e) => {
    setTaskPriority(taskPriority == !taskPriority);
  };

  const handleDeleteChecklist = (id,item_id) => {
    dispatch(
      showConfirmation(
        _l("l_are_you_sure"),
        _l("l_you_want_to_delete_this_checklist"),
        _l("l_delete"),
        _l("l_cancel"),
        undefined,
        () => {
          removeChecklist(id,item_id);
        }
      )
    );
  };

  const handleEditCheckList = (e, id) => {
    setCheckListTexts({
      ...checkListTexts,
      [`${id}`]: e.target.value,
    });
  };

  const setChecklistAssigneeList = async () => {
    let teamIds = [];
    let taskMembers = [];
    checklistAssigneesList &&
      checklistAssigneesList.forEach((member) => {
        if (member.main_sub_key == "team") {
          teamIds.push(Math.abs(member.id).toString());
        } else {
          taskMembers.push(member);
        }
      });

    if (teamIds.length) {
      try {
        if (userTeams) {
          userTeams.forEach((team) => {
            if (teamIds.includes(team.id) && team.manager_id) {
              taskMembers.push({
                id: team.manager_id,
                name: team.manager_name,
                profile_image: team.manager_profile_image,
              });
            }
            if (teamIds.includes(team.id) && team.team.length) {
              team.team.forEach((team_member) => {
                if(!taskMembers.some((member) => member.id == team_member.member_id))
                {
                  taskMembers.push({
                    id: team_member.member_id,
                    name: `${team_member.firstname} ${team_member.lastname}`,
                    profile_image: team_member.profile_image,
                    main_sub_key: "team"
                  });
                }
              });
            }
          });
        }
      } catch (e) {}
    }
    setChecklistItemAssigneeList(removeDuplicateObjectsFromArray(taskMembers, "id"));
  };

  const applyFloorbaseFilter = (floor) => {
    setSelectedFloor(floor);
    setShowFloorList(false);
    const generalSpace = floorDetails.type_of_space.find((w) => w.is_default == 1);
    applyTypeOfSpaceBasedFilter(generalSpace);
  };

  const applyTypeOfSpaceBasedFilter = (space) => {
    // setChecklistItems(checklistStateData.filter((w) => w.space_item_id == space.id));
    setSelectedTypeOfSpace(space);
    setSelectedTypeOfSpaceItem();
    if(space.is_default > 0)
    {
      setChecklistItems(checklistStateData.filter((w) => [1, 0].includes(Number(w.space_item_id))));
    }
  };

  const clearFloorFilter = () => {
    setSelectedFloor({ id: -1, title: "" });
    setShowFloorList(true);
    setSelectedTypeOfSpace({ id: -1, title: "" });
    setChecklistItems(checklistStateData);
  }

  const getTypeOfSpaceCount = (id) => {
    let spaceItemsArray = [];
    spaceItemsArray = checklistStateData.filter((w) => w.floor_id == id).map(w => w.space_item_id);
    spaceItemsArray = [...new Set(spaceItemsArray)];
    return spaceItemsArray.length;
  }

  const getFloorwiseChecklistCount = (id) => {
    return checklistItems.filter((w) => w.floor_id == id).length;
  }

  const getChecklistAttachmentCount = (id) => {
    //To be implemented
    return 0;
  }

  const typeOfSpaceItemToggle = (space) => {
    if(selectedTypeOfSpaceItem && space.id == selectedTypeOfSpaceItem.id)
    {
        setSelectedTypeOfSpaceItem();
    }
    else {
      const spaceItemChecklists = checklistStateData.filter((w) => w.space_item_id == space.id && !isNaN(Number(w.rating)) && Number(w.rating) > 0);
      const rating = spaceItemChecklists.length == 0 ? 0 : spaceItemChecklists.reduce((total, next) => total + parseInt(next.rating), 0) / spaceItemChecklists.length;
      const progress = spaceItemChecklists.length == 0 ? 0 : spaceItemChecklists.filter((w) => w.finished == 1).length > 0 ? (spaceItemChecklists.filter((w) => w.finished == 1).length * 100) / spaceItemChecklists.length : 0;
   
      setSelectedTypeOfSpaceItem({...space, rating, progress});
      setChecklistItems(checklistStateData.filter((w) => w.space_item_id == space.id));
    }
};

  useEffect(() => {
    checklistItems.map((item, index) => {
      if (item.unread_comment_count > 0) {
        setChecklistItems(
          Object.values({
            ...checklistItems,
            [index]: {
              ...item,
              showDetails: !item.showDetails,
            },
          })
        );
      }
    });

    if (view && view == "floor") {
      setChecklistFilter(["floor"]);
      setShowFloorList(true);
    }
  }, []);

  useEffect(() => {
    if (checklistAssigneesList) {
      setChecklistAssigneeList();
    }
  }, [JSON.stringify(checklistAssigneesList), JSON.stringify(userTeams)]);

  const adjustTextAreaHeight = () => {
    jquery("#create_new_checklist").each(function () {
      jquery(this).css("height", "20px");
      this.style.height = this.scrollHeight + "px";
    });
  };

  const selectAttachment = (attachData) => {
    setSelectedAttachment(attachData.otherData);
      setShowTaskImage(true); 
  }
  const setAttchmentOnTop = (id, attachments) => {
    const targetAttchement = attachments.filter(
      (attachment) => attachment.id === id
    );
    const otherAttachments = attachments.filter(
      (attachment) => attachment.id != id
    );
    setLightBoxAttachments(targetAttchement.concat(otherAttachments));
  };
  useEffect(() => {
    if (checklistFilter && checklistFilter.length > 0 && checklistStateData) {
      if (!checklistFilter.includes("floor")) {
        setShowFloorList(view == "floor" ? true : false);
        setSelectedFloor({ id: -1, title: "" });
      }
      let Arr = checklistStateData;
      for (let i = 0; i < checklistFilter.length; i++) {
        for (let j = 0; j < checklistStateData.length; j++) {
          if (checklistFilter[i] == "all") {
            Arr = checklistStateData;
          }
          else if (checklistFilter[i] == "checked") {
            Arr = checklistStateData.filter((s) => s.finished == 1);
          }
          else if (checklistFilter[i] == "notchecked") {
            Arr = checklistStateData.filter((s) => s.finished == 0);
          }
          else if (checklistFilter[i] == "priority") {
            Arr = checklistStateData.filter((s) => s.priority == 1);
          }
          else if (checklistFilter[i] == "inprogress") {
            Arr = checklistStateData.filter(
              (s) => s.status == 2 && s.finished == 0
            );
          }
          else if (checklistFilter[i] == "todo") {
            Arr = checklistStateData.filter(
              (s) => s.status == 0 && s.finished == 0
            );
          }
          else if (checklistFilter[i] == "done") {
            Arr = checklistStateData.filter((s) => s.finished == 1);
          }
          else if (checklistFilter[i] == "floor") {
            setShowFloorList(true);
          }
        }
      }
      setChecklistItems(Arr);
    }

  }, [JSON.stringify(checklistFilter)]);

  const getFloorListWithChecklist = () => {
    if(floorDetails && floorDetails.floor)
    {
      const floorsWithChecklists = [...new Set(checklistStateData.map((w) => Number(w.floor_id)))];
      return floorDetails.floor.filter((x) => !floorsWithChecklists.includes(x.id)).map((w) => w.id);
    }
    else {
      return [];
    }
  };

  const updateTaskRatingCalculation = () => {
    if(data && data.id && taskList)
    {
      let taskListNew = taskList;
      let taskIndex = taskListNew.findIndex((d) => d.id == data.id);
      if (taskIndex > -1){
        taskListNew[taskIndex].average_rating = `${averageTaskChecklistRatings()}`;
        data.average_rating = `${averageTaskChecklistRatings()}`;
      }
      dispatch(setBulkTaskRating(taskListNew));
    }
  }

  const setSpaceCoefficient = async (coeffcient, selectSpaceId) => {
    if (coeffcient > 0) {
      try {
          dispatch(updateSpaceCoefficient(selectSpaceId, coeffcient));
          updateTaskRatingCalculation();
          const response = await CustomerServices.setSpaceCoefficient(staff_id, contact_id, client_id, projectId, selectSpaceId, coeffcient) ;
          if(!response.status){
            showError(response.message);
            updateTaskRatingCalculation();
          }
      }
      catch(e){
          updateTaskRatingCalculation();
          console.error(e);
      }  
    }
    else {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          "Value should be grater than or equals to 1"
        )
      )
    }
  };

  const getFloorWiseChecklistRating = (floorId) => {
    let ratedSpaceCount = 0;
    let totalRating = 0;
    
    floorDetails.type_of_space.filter((w) => w.is_default == 1 || w.details.find((x) => x.floor_id == floorId)).map((item, index) => {
        const allSpaceItemIdArray = item.details.map((w) => w.id);
        const spaceItemsIdArray = item.details.filter((w) => w.floor_id == floorId).map((w) => w.id);
        const floorChecklist = checklistStateData.filter((w) => ((item.is_default > 0 && w.space_item_id == 0)) || spaceItemsIdArray.find((z) => z == w.space_item_id));
        const checklistForTypeOfSpace = checklistStateData.filter((w) => ((item.is_default > 0 && w.space_item_id == 0)) || allSpaceItemIdArray.find((z) => z == w.space_item_id) && !isNaN(Number(w.rating)) && Number(w.rating) > 0);
        let avgRating = checklistForTypeOfSpace.length == 0 ? 0 : checklistForTypeOfSpace.reduce((total, next) => total + parseInt(next.rating), 0) / checklistForTypeOfSpace.filter((w) => w.rating > 0).length ;
        if(avgRating > 0)
        {
          totalRating += avgRating;
          ratedSpaceCount++;
        }
    });

    if(totalRating && ratedSpaceCount)
    {
      return (
        <a href="#/" className="comman_action_icon w-100 mt-2 d-flex align-items-center close-toast border-0">
          <div className="action_icon h-auto  position-relative light-theme-img">
            <Star size={14} className="c-icons" weight="fill" color="#00ff6a" />
          </div>
          <span className="c-font f-14 d-flex justify-content-center fw-semibold flex-grow-1 number-counter-toggle">{((totalRating / ratedSpaceCount).toFixed(1))}</span>
        </a>
      )
    }
    else {
      return (
        <a href="#/" className="comman_action_icon w-100 mt-2 d-flex align-items-center close-toast border-0">
          <div className="action_icon  h-auto position-relative light-theme-img">
          <Star size={14} className="c-icons" weight="light" />
          </div>
        </a>
      )
    }
  };

  const isAllowedToRemoveChecklistAssignment = (checklist) => {
    if(isExternal)
    {
      return false;
    }
    else if(contact_role == 3)
    {
      return false;
    }
    else if(checklist.is_allowed_to_edit != 1)
    {
      return false;
    }
    return true;
  };

  const addEuipmentchecklists = (item, euipment) => {
    CustomerServices.updateChecklistItem(
      projectId,
      task_id,
      item.id,
      '',
      '',
      '',
      "",
      "",
      euipment,
      true
    ).then((res) => {
      if (res.status) {
        let elementId = [];
        let ckecklist  = item.consumable_equipments_assignee
        euipment.forEach((euiq) => {
          if (euiq.count > euiq.initialEuipment) {
            const sub_categories = euiq.sub_category.filter((sub) => sub.task_checklist_id == 0 && sub.is_euipment == 0);
            for (let index = 0; index < euiq.count - euiq.initialEuipment; index++) {
              elementId.push({equipment_status : 1, equipment_quantity_id : sub_categories[index].id})
              ckecklist.push({equipment_id : euiq.filter_key, equipment_quantity_id : sub_categories[index].id})
            }
          }else if (euiq.count < euiq.initialEuipment) {
              const sub_categories = euiq.sub_category.filter((sub) => sub.task_checklist_id != 0 && sub.is_euipment == 1);
              for (let index = 0; index < euiq.initialEuipment - euiq.count; index++) {
                elementId.push({equipment_status : 0, equipment_quantity_id : sub_categories[index].id})
                ckecklist = ckecklist.filter((checkID) => Number(checkID.equipment_quantity_id) != Number(sub_categories[index].id))
              }
            }
          
        })
        const index = checklistItems.findIndex(
          (items) => items.id == item.id
        );
        let updatedChecklist = checklistItems[index];
        updatedChecklist.consumable_equipments_assignee = ckecklist.slice();
        setChecklistItems(
          Object.values({ ...checklistItems, [index]: updatedChecklist })
        );
        dispatch(updateedTaskChecklist(Object.values({ ...checklistItems, [index]: updatedChecklist })))
        dispatch(setEquipmentConsumbleCheckList(elementId));
        seteuipmentModals(-1);
      }
    });
  }

  useEffect(() => {
    console.log(docType,"step");
  }, [docType])
  
  
  return (
    <React.Fragment>
      {/* <div className="h-100 d-flex flex-column"> */}
     
        <></>
      {showFloorList  ? (
        <React.Fragment>
          <div className="d-flex align-items-center justify-content-between p-2 mx-1">
            <span className="title-fonts fw-semibold">{_l('l_floor_filter')}</span>
            <a onClick={() => {
              setShowFloorList(false) 
              setChecklistFilter(['all'])
              }}className="bg-white-03 d-flex h25w25">
              <X size={14} className="c-icons"weight="light" />
            </a>
          </div>
          <div className="px-2">
          <FloorPlanSummary
            showGeneralAreaAsFloor={true}
            hideFloorDetails={true}
            kpiData={[[{field: "l_type_of_space_label", value: getTypeOfSpaceCount},{field: "l_checklist", value: getFloorwiseChecklistCount}, {field: "l_rating", getJSX: getFloorWiseChecklistRating}]]}
            // {field: "l_type_of_space_label", value:20},{field: "l_checklist", value:20},{field: "l_attachments", value:20}
            onFloorSelect={(floor) => {
              applyFloorbaseFilter(floor);
            }}
            filteredFloors={((!isAllowToEdit) || isSystemTask )? getFloorListWithChecklist() : []}
          />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className={`d-flex py-0 flex-grow-1 ${isCallFullscreen ?"overflow-hiiden-auto" : ""}  ${docType=="lead"? "p-10 py-0":"p-10"} ${fullHeight ? "flex-column" : ""} ${selectedFloor.id >= 0 ? "  border overflow-hiiden-auto checklist-with-content-part" : ""}`}>
            {selectedFloor.id >= 0 ? (
              <React.Fragment>
                <div className="d-flex justify-content-between w-100 p-10 bg-white-03-solid position-sticky top-0 z-index-2 radius_3 mt-10">
                  <div className="title-fonts fw-semibold">
                    {selectedFloor.title}
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="with_separator_10 d-flex align-items-center pe-0">
                      {
                        selectedFloor.id > 0
                          ?
                          <div class="d-flex align-items-center pe-3">
                            <div class="c-font color-white-80 f-12">{_l("l_type_of_space_label")}:</div>
                            <div class="c-font f-14 fw-semibold title-fonts ps-2">{getTypeOfSpaceCount(selectedFloor.id)}</div>
                          </div>
                          :
                          <React.Fragment></React.Fragment>
                      }
                      {/* <div class="d-flex align-items-center pe-3">
                        <div class="c-font color-white-80 f-12">{_l("l_checklist")}:</div>
                        <div class="c-font f-14 fw-semibold title-fonts ps-2">{getFloorwiseChecklistCount(selectedFloor.id)}</div>
                      </div>
                      <div class="d-flex align-items-center pe-3">
                        <div class="c-font color-white-80 f-12">{_l("l_attachments")}:</div>
                        <div class="c-font f-14 fw-semibold title-fonts ps-2">0</div>
                      </div> */}
                    </div>
                    <a href="#/"
                      onClick={() => clearFloorFilter()}
                      className="with_separator_10 before-dnone pe-0"
                    >
                      <X />
                    </a>
                  </div>
                </div>

              </React.Fragment>
            ) : (
              <></>
            )}
            <div className="d-flex flex-grow-1 w-100 h100minus70px pt-10px pb-10px">
              {
                selectedFloor.id > 0  ?
                  <div className="left-content-part w-300 p-0 res-width-100 d-flex flex-column bg-transparent border overflow-auto h-100 floor-listing-main">
                    <div className="comman-content-scroll-wrapper overflow-hiiden-web bgOfficelisting bgwhiteimp">
                      <div className="comman-content-scroll comman-verticle-tab">
                        {floorDetails.type_of_space.filter((w) => w.is_default == 1 || w.details.find((x) => x.floor_id == selectedFloor.id)).map((item, index) => {
                          const allSpaceItemIdArray = item.details.map((w) => w.id);
                          const spaceItemsIdArray = item.details.filter((w) => w.floor_id == selectedFloor.id).map((w) => w.id);
                          var floorChecklist = checklistStateData.filter((w) => ((item.is_default > 0 && w.space_item_id == 0)) || spaceItemsIdArray.find((z) => z == w.space_item_id));
                          const checklistForTypeOfSpace = checklistStateData.filter((w) => ((item.is_default > 0 && w.space_item_id == 0)) || allSpaceItemIdArray.find((z) => z == w.space_item_id) && !isNaN(Number(w.rating)) && Number(w.rating) > 0);
                          var avgRating = checklistForTypeOfSpace.length == 0 ? 0 : checklistForTypeOfSpace.reduce((total, next) => total + parseInt(next.rating), 0) / checklistForTypeOfSpace.filter((w) => w.rating > 0).length ;
                          var compliPercent = floorChecklist.length == 0 ? 0 : floorChecklist.filter((w) => w.finished == 1).length > 0 ? (floorChecklist.filter((w) => w.finished == 1).length * 100) / floorChecklist.length : 0;
                          return (
                            <li key={index} className="comman-list p-0 w-100">
                              <a
                                className={`w-100 position-relative tab_link p13px ${selectedTypeOfSpace.id == item.id ? "active" : ""} `}
                                onClick={() => applyTypeOfSpaceBasedFilter(item)}
                              >
                                <div className="d-flex align-items-center text-truncate">
                                  <div className="comman-list-left text-truncate flex-grow-1">
                                    <div className="d-flex align-items-center">
                                      <div className="d-flex flex-column">
                                        <div class="image-text text-truncate w_92px">
                                          <div class="text-truncate c-font f-13 " 
                                          data-tip={item.title}
                                          data-effect="solid"
                                          data-delay-show='1000'
                                          data-class="tooltip-main"
                                          >
                                            {item.title}
                                          </div>
                                          <ReactTooltip />
                                        </div>
                                        <div className="progress-slider w-70px pe-10p pt-5px">
                                          <div className="d-flex align-items-center">
                                            <div className="progress">
                                              <div className="progress-bar" style={{ width: `${compliPercent}%` }}></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      { item.id == 1 ? (
                                          <React.Fragment></React.Fragment>
                                        ) : (
                                          <>
                                          <div className="d-flex h32w32"> <X size={16} className="c-icons opacity-50" weight="light" /></div>
                                          <div className="comman_action_icon">
                                            <input className="action_icon with_bg h32w40 text-center" placeholder="32" type="number" min="1" defaultValue={item.coeffcient_value}
                                            disabled={isExternal}
                                            onBlur={(e) => {
                                                if(isExternal)
                                                {
                                                  return;
                                                }
                                                setSpaceCoefficient(e.target.value, item.id) }
                                               } />
                                          </div>
                                        </>
                                        )
                                      }
                                    </div>
                                  </div>
                                  <div className="comman-list-right">
                                    <div className="d-flex align-items-center comman_action_icon">    
                                      <div className="d-flex align-items-center me-10px">
                                        <div className="white-border-box color-white-60 action_icon h32w32 with_bg with-border-active ">
                                          {avgRating > 0 ?
                                            <Star size={16} className="c-icons" weight="fill" color="#00ff6a" />
                                            :
                                            <Star size={16} className="c-icons" weight="light" />}
                                        </div>
                                        <div className="ps-1 c-font f-12 d-flex justify-content-center flex-grow-1 number-counter-toggle">{!isNaN(avgRating) ? Number(avgRating).toFixed(1) : 0}</div>
                                      </div>
                                        <div className={`align-items-center badge fw-semibold d-flex green-text h20w20 justify-content-center p-0 rounded-circle with-bg  ${floorChecklist.length ? "" : "opacity-0"}`}><span class="ps-0">{floorChecklist.length}</span></div>
                                      <CaretRight size={16} className="c-icons" weight="light" />
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </li>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                  :
                  <React.Fragment></React.Fragment>
              }
              <div
                className={`${selectedFloor.id >= 0
                    ? "right-content-part width-calc-300 overflow-auto h-100 ps-10px"
                    : "w-100"
                  } d-flex flex-column flex-grow-1`}
              >
                {
                  selectedTypeOfSpace && selectedTypeOfSpace.id > 0 && selectedTypeOfSpace.is_default == 0
                  ?
                  <React.Fragment></React.Fragment>
                  :
                  <div className={`d-flex commn-search justify-content-end comment-input-box with-sticky  border-0 rounded-0 p-10 px-0 ${["request-checklists","add-proposal-amount"].includes(step) ? "" : "bg-update pt-0"}`}>
                    <div className={`flex-grow-1 p-5px light-theme-border radius_3 ${ChecklistSearchToggle || isCallRecived || scheduleChecklistId ? "bg-white-03":""} ${isSystemTask ? "" :"bg-white-03"}`}>  
                  {
                    ChecklistSearchToggle ? 
                  
                  <div className="comment-input-box flex-grow-1">
                    <div className="d-flex align-items-center">
                      <MagnifyingGlass size={14} className="c-icons mx-2"weight="light"  />
                      <input
                        type="text"
                        className=" border-0 max-h15vh pe-3 bg-transparent  h32w32 w-100"
                        placeholder={`${_l("l_search")}`}
                        value={checklistSearchString}
                        onChange={(e)=>{handleSearchCheckList(e.target.value)}}
                      />
                    </div> 
                  </div> : 
                  
                    <div
                      className={`comment-input-box flex-grow-1 d-flex align-items-center`}
                    >
                      <textarea
                        id="create_new_checklist"
                        className="bg-transparent border-0  max-h15vh p-2 pt-1  H32 w100minus40"
                        value={addChecklistItem}
                        onChange={(e) => {
                          setAddChecklistItem(
                            e.target.value
                            // e.target.value.replace(
                            //   /[^a-zA-Z0-9^a-zA-Z0-9!@#$%^&*()\ \,.;]/gi,
                            //   ""
                            // )
                          );
                          setAddChecklistItemEvent(e);
                          if (e.target.value != "") {
                            adjustTextAreaHeight();
                            setDisableOnClickEvent(
                              e.target.value
                              // e.target.value.replace(
                              //   /[^a-zA-Z0-9^a-zA-Z0-9!@#$%^&*()\ \,.;]/gi,
                              //   ""
                              // )
                            );
                          } else {
                            e.target.focus();
                            adjustTextAreaHeight();
                          }
                        }}
                        // onPaste={(e) => {
                        //     handleAddChecklistItem(e);
                        // }}
                        onKeyDown={(e) => {
                          if (
                            (e.key === "Enter" || e.keyCode == 13) &&
                            !e.ctrlKey &&
                            !e.shiftKey
                          ) {
                            // if (disableOnClickEvent != "") {
                            //   setDisableOnClickEvent("");
                            // }
                            e.preventDefault();
                            handleAddChecklistItem(e);
                          }
                          // if (e.keyCode === 13) {
                          //   if (disableOnClickEvent != "") {
                          //     setDisableOnClickEvent("");
                          //   }
                          // }
                        }}
                        // type="text"
                        placeholder={`${_l("l_create_checklist_item")}`}
                        aria-label="default input example"
                        ref={inputReference}
                        disabled={isCallRecived ? false : isSystemTask && !scheduleChecklistId}
                      />
                      <a className={`send-arrow-btn end-0 ${isSystemTask ? "d-none" : ""}`}>
                        <div
                          onClick={() => {
                            if (disableOnClickEvent != "") {
                              setDisableOnClickEvent("");
                            } else {
                              dispatch(
                                showMessage(
                                  "unsucess",
                                  _l("l_error"),
                                  _l("l_please_enter_valid_checklist")
                                )
                              );
                              // setToastHeader(_l("l_error"));
                              // setToastMessage(_l("l_please_enter_valid_checklist"));
                              // setConfirmButtonText("");
                              // setCancelButtonText("");
                              // setToastClass("unsucess");
                              // setShowToast(true);
                            }
                          }}
                          alt={"White arrow"}
                          className="img-width-18 m-auto"
                        >
                          <ArrowRight size={16} weight="light" className="c-icons" />
                        </div>
                      </a>

                    <div className={`modal-sticky-spacing d-none ${noModalSpace ? "d-none":""} `}></div>
                    </div>
                  }
                  </div>
                  <div className={`d-flex align-items-center bg-white-03 p-5px light-theme-border radius_3 ChecklistSearch audiocallbg ms-2 ${ChecklistSearchToggle ?"":""}`}>
                    <a className={ `comman_action_icon ${isSystemTask ? "":""}`}>
                      <div className="d-flex align-items-center ">
                        <div className={`action_icon h32w32 with_bg ${ChecklistSearchToggle ? "":"d-none"} `} onClick={()=>{setChecklistSearchToggle(false)}}>
                          <X size={18} className="c-icons" weight="light" />
                        </div>
                        <div className={`action_icon h32w32 with_bg ${ChecklistSearchToggle ? "d-none":""}`} onClick={()=>{setChecklistSearchToggle(!ChecklistSearchToggle)}}>
                          <MagnifyingGlass
                            size={18}
                            className="c-icons"
                            weight="light"
                          />
                        </div>
                      </div>
                    </a>
                    
                    <div className={`dropstart dropstart p-5px py-0 pe-0 ${docType=="lead"?"d-none":""}`}>
                    <a className={`comman_action_icon ${checklistFilter.length > 0 ? "iconactive" : ""}`}
                    type="button"
                    id="commentOptionDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-tip={_l("l_options")}
                    data-effect="solid"
                    data-delay-show='1000'
                    data-class="tooltip-main"
                    >
                      <div className="d-flex align-items-center  ">
                        <div className="action_icon h32w32 with_bg on-hover-active-toggle-img comman-round-box-border-color">
                          <Funnel
                            size={18}
                            className="c-icons"
                            weight="light"
                            // color={`${checklistFilter.length > 0 ? "#00ff6a" : "#1d3545"}`}
                          />
                        </div>
                      </div>
                    </a>
                    <ActionDropdown
                      actions={actionMenuItems}
                    />
                    </div>
                    </div>
                </div>
                }
              {/* <div className="flex-grow-1"> */}
              <React.Fragment>
                {selectedTypeOfSpace && selectedTypeOfSpace.id > 1 && !selectedTypeOfSpaceItem
                ?
                <div className="d-flex flex-column w-100   ">
                  <div className="checklist-listing-wrapper pt-2 right-content-part width-calc-240 ps-0 overflow-hiiden-auto checklist-with-content-part">
                    <div className="custom-accordian-main res-padding-right0 h-100 task-list-accordian px-0">
                      <Accordion  className="task-list-accordian-main mt-0" defaultActiveKey="0">
                          {
                              selectedTypeOfSpace.details.map((space, index2) => {

                                if(space.floor_id == selectedFloor.id)
                                {
                                    const spaceItemChecklists = checklistStateData.filter((w) => w.space_item_id == space.id && !isNaN(Number(w.rating)) && Number(w.rating) > 0);
                                    const rating = spaceItemChecklists.length == 0 ? 0 : spaceItemChecklists.reduce((total, next) => total + parseInt(next.rating), 0) / spaceItemChecklists.length;
                                    const progress = spaceItemChecklists.length == 0 ? 0 : spaceItemChecklists.filter((w) => w.finished == 1).length > 0 ? (spaceItemChecklists.filter((w) => w.finished == 1).length * 100) / spaceItemChecklists.length : 0;

                                    return (
                                        <Accordion.Item eventKey={index2} className="with-pb-15">
                                            <div className="accordian-header-box d-flex align-items-center justify-content-between with-accordian-bg border-0 ">
                                                <Accordion.Header className="commanTaskCount bg-white-03 w-100 min-w-100imp lighthtemebg">
                                                    <div className="row w-100"  onClick={() => typeOfSpaceItemToggle(space)}>
                                                        <div className="accordion-header-wrapper py_0 pe-0">
                                                            <div className="d-flex align-items-center justify-content-between" >
                                                                <div className="left-task-header">
                                                                    <div className="title-font c-font f-12 fw-semibold ">
                                                                        <span className="task-title me-2">{space.title}</span>
                                                                        {
                                                                            checklistItems.filter((w) => w.type_of_space_item_id == space.id).length
                                                                            ?
                                                                            <div className="badge rounded-pill text-nowrap list-toggle-view-none d-inline-block me-1 h-auto">
                                                                                <span className=" p-0 ">{checklistItems.filter((w) => w.type_of_space_item_id == space.id).length}</span>
                                                                            </div>
                                                                            :
                                                                            <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="ms-auto d-flex align-items-center">
                                                                <div className="progress-slider w-70px pe-10px">
                                                                  <div className="d-flex align-items-center">
                                                                    <div className="progress">
                                                                      <div className="progress-bar" style={{ width: `${progress}%` }}></div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className="d-flex align-items-center me-10px comman_action_icon">
                                                                  <div className="white-border-box color-white-60 action_icon h32w32 with_bg with-border-active ">
                                                                    {rating > 0 ?
                                                                      <Star size={16} className="c-icons" weight="fill" color="#00ff6a" />
                                                                      :
                                                                      <Star size={16} className="c-icons" weight="light" />}
                                                                  </div>
                                                                  <div className="ps-1 c-font f-12 d-flex justify-content-center flex-grow-1 number-counter-toggle">{!isNaN(rating) ? Number(rating).toFixed(1) : 0}</div>
                                                                </div>
                                                                {
                                                                  spaceItemChecklists && spaceItemChecklists.length 
                                                                  ?
                                                                  <div class="align-items-center fw-semibold badge d-flex green-text h20w20 justify-content-center p-0 rounded-circle with-bg"><span class="ps-0">{spaceItemChecklists.length}</span></div>
                                                                  :
                                                                 <></>
                                                                }
                                                                {
                                                                    selectedTypeOfSpaceItem
                                                                    ?
                                                                        <div className='d-flex justify-content-end '>
                                                                            <a
                                                                                className="remove-img"
                                                                            >
                                                                                <X
                                                                                    size={18}
                                                                                    weight="light"
                                                                                    className="c-icons"
                                                                                />
                                                                            </a>
                                                                        </div>
                                                                    :
                                                                    <div className='d-flex justify-content-end'>
                                                                      <a
                                                                          className="remove-img"
                                                                      >
                                                                          <CaretRight
                                                                              size={18}
                                                                              weight="light"
                                                                              className="c-icons"
                                                                          />
                                                                      </a>
                                                                    </div>
                                                                }
                                                            </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </Accordion.Header>
                                            </div>
                                            <Accordion.Body className="taskCountToggle border-0" >
                                                {
                                                    selectedTypeOfSpaceItem
                                                    ?
                                                        <></>
                                                    :
                                                        <></>
                                                }
                                                {
                                                    
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                  }
                                  return <React.Fragment></React.Fragment>
                              })
                          }
                      </Accordion>
                    </div>
                  </div>
                </div>
               
                :
                <React.Fragment>
                  {
                    selectedTypeOfSpace && selectedTypeOfSpaceItem && selectedTypeOfSpace.id > 0 && selectedTypeOfSpaceItem.id > 0
                    ?
                    <div className=" commanTaskCount ">
                      <div className="bg-white-03 m-2 mx-0 lighthtemebg">
                      <div className="row  p-10"  onClick={() => typeOfSpaceItemToggle(selectedTypeOfSpaceItem)}>
                          <div className="accordion-header-wrapper w-100 ">
                              <div className="d-flex align-items-center justify-content-between" >
                                  <div className="left-task-header d-flex align-items-center justify-content-between flex-grow-1">
                                      <div className="title-font c-font f-12 fw-semibold ">
                                          <span className="task-title me-2">{selectedTypeOfSpaceItem.title}</span>
                                          {
                                              checklistItems.filter((w) => w.type_of_space_item_id == selectedTypeOfSpaceItem.id).length
                                              ?
                                              <div className="badge rounded-pill text-nowrap list-toggle-view-none d-inline-block me-1 h-auto">
                                                  <span className=" p-0 ">{checklistItems.filter((w) => w.type_of_space_item_id == selectedTypeOfSpaceItem.id).length}</span>
                                              </div>
                                              :
                                              <></>
                                          }
                                      </div>
                                      <div className="ms-auto d-flex align-items-center">
                                      <div className="progress-slider w-70px pe-10px">
                                          <div className="d-flex align-items-center">
                                            <div className="progress">
                                              <div className="progress-bar" style={{ width: `${selectedTypeOfSpaceItem.progress}%` }}></div>
                                            </div>
                                          </div>
                                        </div>
                                      <div className="d-flex align-items-center me-10px comman_action_icon">
                                        <div className="white-border-box color-white-60 action_icon h32w32 with_bg with-border-active ">
                                          {selectedTypeOfSpaceItem.rating > 0 ?
                                            <Star size={16} className="c-icons" weight="fill" color="#00ff6a" />
                                            :
                                            <Star size={16} className="c-icons" weight="light" />}
                                        </div>
                                        <div className="ps-1 c-font f-12 d-flex justify-content-center flex-grow-1 number-counter-toggle">{!isNaN(selectedTypeOfSpaceItem.rating) ? Number(selectedTypeOfSpaceItem.rating).toFixed(1) : 0}</div>
                                      </div>
                                      </div>  
                                  </div>
                                  {
                                      selectedTypeOfSpaceItem
                                      ?
                                          <div className='d-flex justify-content-end comman_action_icon'>
                                              <a
                                                  className="remove-img action_icon with_bg h32w32"
                                              >
                                                  <X
                                                      size={18}
                                                      weight="light"
                                                      className="c-icons"
                                                  />
                                              </a>
                                          </div>
                                      :
                                      <div className='d-flex justify-content-end'>
                                          <a
                                              className="remove-img"
                                          >
                                              <CaretRight
                                                  size={18}
                                                  weight="light"
                                                  className="c-icons"
                                              />
                                          </a>
                                      </div>
                                  }
                              </div>
                          </div>
                          
                      </div>
                      </div>
                      <div className="flex-grow-1 ">
                        <div
                          className={`comment-input-box light-theme-border radius_3 p-5px bg-white-03 ${isSystemTask && !scheduleChecklistId ? "d-none" : "flex-grow-1 d-flex align-items-center"}`}>
                        <textarea
                          id="create_new_checklist"
                          className="bg-transparent border-0  max-h15vh p-2  H32 w100minus40"
                          value={addChecklistItem}
                          onChange={(e) => {
                            setAddChecklistItem(
                              e.target.value
                              // e.target.value.replace(
                              //   /[^a-zA-Z0-9^a-zA-Z0-9!@#$%^&*()\ \,.;]/gi,
                              //   ""
                              // )
                            );
                            setAddChecklistItemEvent(e);
                            if (e.target.value != "") {
                              adjustTextAreaHeight();
                              setDisableOnClickEvent(
                                e.target.value
                                // e.target.value.replace(
                                //   /[^a-zA-Z0-9^a-zA-Z0-9!@#$%^&*()\ \,.;]/gi,
                                //   ""
                                // )
                              );
                            } else {
                              e.target.focus();
                              adjustTextAreaHeight();
                            }
                          }}
                          // onPaste={(e) => {
                          //     handleAddChecklistItem(e);
                          // }}
                          onKeyDown={(e) => {
                            if (
                              (e.key === "Enter" || e.keyCode == 13) &&
                              !e.ctrlKey &&
                              !e.shiftKey
                            ) {
                              // if (disableOnClickEvent != "") {
                              //   setDisableOnClickEvent("");
                              // }
                              e.preventDefault();
                              handleAddChecklistItem(e);
                            }
                            // if (e.keyCode === 13) {
                            //   if (disableOnClickEvent != "") {
                            //     setDisableOnClickEvent("");
                            //   }
                            // }
                          }}
                          // type="text"
                          placeholder={`${_l("l_create_checklist_item")}`}
                          aria-label="default input example"
                          ref={inputReference}
                        />
                        <a className={`send-arrow-btn right-5px`}>
                          <div
                            onClick={() => {
                              if (disableOnClickEvent != "") {
                                setDisableOnClickEvent("");
                              } else {
                                dispatch(
                                  showMessage(
                                    "unsucess",
                                    _l("l_error"),
                                    _l("l_please_enter_valid_checklist")
                                  )
                                );
                                // setToastHeader(_l("l_error"));
                                // setToastMessage(_l("l_please_enter_valid_checklist"));
                                // setConfirmButtonText("");
                                // setCancelButtonText("");
                                // setToastClass("unsucess");
                                // setShowToast(true);
                              }
                            }}
                            alt={"White arrow"}
                            className="img-width-18 m-auto"
                          >
                            <ArrowRight size={16} weight="light" className="c-icons" />
                          </div>
                        </a>
                        <div className={`modal-sticky-spacing d-none ${noModalSpace ? "d-none":""} `}></div>
                        </div>
                        </div>
                    </div>
                    :
                    <React.Fragment></React.Fragment>
                  }
                  <div className={`${isCallFullscreen ? "overflow-auto" : ""}`}>
                  {checklistItems && checklistItems.length ? (
                    checklistItems
                      .filter(
                        (data) =>
                          data.description &&
                          data.description
                            .toLowerCase()
                            .includes(
                              checklistSearchString
                                ? checklistSearchString.toLowerCase()
                                : ""
                            )
                      )
                      .reverse()
                      .sort((a, b) => {
                        if (a.priority > b.priority) return -1;
                      })
                      .slice(0, loadPageNo * itemsPerPage)
                      .map((item, index) => {
                        const categories = defaultTopics && defaultTopics.length > 0 && defaultTopics.find((topic) => topic.main_key === "equipments")
                        categories && categories.categories.forEach((category) => {
                          item.consumable_equipments_assignee && item.consumable_equipments_assignee.forEach((equip) => {
                          if (category.filter_key == equip.equipment_id) {
                          category.sub_category.forEach((sub) => {
                                if (sub.id == equip.equipment_quantity_id) {
                                  equip.name = sub.name
                                }
                              })
                            }
                          })
                        })
                        return (
                          <div key={index} className={`task-checklist  ${selectedFloor.id > 0 ? "task-checklist-icon-wrap p-10":""}`}>
                            <div className="d-flex justify-content-between flex-wrap flex-lg-nowrap">
                              <div className="left-checklist w100minus100per res100per">
                                <div className="d-flex align-items-start">
                                    <div
                                      className="task-creator-icon"
                                      data-tip={`${item &&
                                          item.addedfrom_details &&
                                          item.addedfrom_details.full_name
                                          ? item.addedfrom_details.full_name
                                          : "User Name"
                                        }`}
                                      data-effect="solid"
                                      data-delay-show='1000'
                                      data-class="tooltip-main"
                                      data-place="top"
                                      data-bs-toggle="tooltip"
                                    >
                                      <div
                                        className="h32w32 comman-round-box with-bg rounded-circle bg-white-05"
                                        data-bs-toggle="creator"
                                      >
                                        <div
                                          className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                          style={{
                                            backgroundImage: `url(${item.addedfrom_details.profile_url})`,
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                    <div className="checklist-list  pt-1 ">
                                      <div className="form-check">
                                        <div
                                          className={`d-flex align-items-start ${isExternal ? " for-disabled" : ""
                                            }`}
                                        >
                                          <input
                                            className="form-check-input mt-1"
                                            type="checkbox"
                                            checked={item.finished != 0}
                                            onChange={(e) => {
                                              if (item.consumable_equipments_assignee && item.consumable_equipments_assignee.length > 0) {
                                                if (item.attachments && item.attachments.length > 0) {
                                                  if (item.finished == 0) {
                                                    itemFinished(
                                                      item,
                                                      e.target.checked,
                                                      index
                                                    );
                                                  }
                                                }else{
                                                  dispatch(showMessage("unsucess",_l("l_error"),_l("l_please_add_ attachments")));
                                                }
                                              }else{
                                                itemFinished(
                                                  item,
                                                  e.target.checked,
                                                  index
                                                );
                                              }
                                            }}
                                            id={`taskdetail_checkbox_${item.id}`}
                                            disabled={isSystemTask ? true : false}
                                          />
                                          <textarea
                                            id={`checklist_input_${item.id}`}
                                            className="w-100 bg-transparent border-0 checklist_textarea pt-1 ps10px pe-0"
                                            value={checkListTexts[item.id]}
                                            onInput={(e) => { }}
                                            onChange={(e) =>
                                              handleEditCheckList(e, item.id)
                                            }
                                            onClick={() => {
                                              if (item.is_allowed_to_edit == 1) {
                                                handleEdit(item.id);
                                              }
                                            }}
                                            onBlur={(e) => {
                                              if (e.target.value.length < 1) {
                                                let checklisttxt =
                                                  checklistItems.find(
                                                    (p) => p.id === item.id
                                                  ).description;
                                                dispatch(
                                                  showMessage(
                                                    "unsucess",
                                                    _l("l_error"),
                                                    _l("l_checklist_empty")
                                                  )
                                                );
                                                setCheckListTexts({
                                                  ...checkListTexts,
                                                  [`${item.id}`]: checklisttxt,
                                                });
                                              } else {
                                                checklistItems.find(
                                                  (p) =>
                                                    p.id === item.id &&
                                                    ((p.description = e.target.value),
                                                      true)
                                                );
                                                setRequestChecklist(checklistItems);
                                                handleCheckListUpdate(
                                                  item.id,
                                                  e.target.value,
                                                  item.finished == "1",
                                                  undefined,
                                                  index,
                                                  item.priority,
                                                  undefined,
                                                  undefined,
                                                  item.item_id
                                                );
                                              }
                                            }}
                                            disabled={
                                              scheduleChecklistId > 0 && (contact_role != 3)
                                                ? false
                                                : user_type == "contact"
                                                  ? contact_id !== item.addedfrom && contact_role != 1
                                                  : item.is_allowed_to_edit == 1
                                                    ? isExternal
                                                    : true
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <ReactTooltip />
                                <div className={`right-checklist-action dropend comman_action_icon d-flex align-items-start resw100 justify-content-end justify-contnet-lg-start with-abs-position-bg-taskcard `}>
                                  
                                  <div className={`d-flex align-items-center z-index-2 ${ step == "add-proposal-amount" ? "with-abs-position" : ""}${step == "request-checklists" ? "bg-white03" : ""}`}>
                                    <div className={`bottom-left-checklist comman_action_icon`}>
                                      <div className="d-flex align-items-center table-list-attechment">

                                        {/* status icon start */}
                                        {checkListIconToggle == index ?
                                          <div
                                            data-effect="solid"
                                            data-delay-show='1000'
                                            data-class="tooltip-main"
                                            data-tip={_l(
                                              item.finished == 0
                                                ? item.status == 0
                                                  ? "l_todo"
                                                  : "l_inprogress"
                                                : "l_done"
                                            )}
                                            className={`me-10px ${item.finished == 1 || isExternal ? " for-disabled" : ""}`}>
                                            <a href="#/"
                                              className={`white-border-box color-white-60 action_icon h32w32 with_bg `}
                                              id="TaskListStatusDropdown"
                                              aria-expanded="false"
                                              onClick={() => {
                                                settaskStatus(
                                                  item.status == 0 ? 2 : 0
                                                );
                                                handleTaskStatus(
                                                  item.id,
                                                  item.description,
                                                  item.finished,
                                                  "",
                                                  index,
                                                  item.priority,
                                                  item.status == 0 ? 2 : 0
                                                );
                                              }}
                                            >
                                              <span
                                                className={`c-dots c-10 ${item.finished == 0
                                                  ? item.status == 0
                                                    ? "in-progress-white"
                                                    : "in-progress-blue"
                                                  : "done"
                                                  } rounded-circle`}
                                              ></span>
                                            </a>{" "}
                                          </div> : <></>}
                                        {/* status icon end */}

                                        {/* star icon start */}
                                        {checkListIconToggle == index || item.rating > 0 ?
                                          <div className={`dropend d-flex align-items-center me-2`}>
                                            <UncontrolledDropdown className={`w-100 h-100`}>
                                              <DropdownToggle className="p-0" data-toggle="dropdown" tag="span">
                                                <a href="#/" className="comman_action_icon "
                                                  type="button"
                                                  data-bs-toggle="dropdown"
                                                  data-bs-auto-close="outside"
                                                  aria-expanded="false">
                                                  <div className="action_icon h32w32 with_bg position-relative on-hover-active-toggle-img d-flex align-items-center">
                                                    {parseInt(item.rating) > 0 ?
                                                      <Star size={16} className="c-icons" weight="fill" color={selectedTheme == "light" ? "#FCA120" : "#00ff6a"} />
                                                      :
                                                      <Star size={16} className="c-icons" weight="light" />}
                                                  </div>
                                                </a>
                                              </DropdownToggle>

                                              <DropdownItem toggle={false} className="p-0">
                                                <StarRatingDropdown handler={(starValue, review, isFrom) => {
                                                  handleCheckListRating(starValue, review, isFrom, item.id)
                                                }}
                                                  rating={item.rating}
                                                  review={item.review} />
                                              </DropdownItem>
                                            </UncontrolledDropdown>
                                            <span className="c-font f-12 d-flex justify-content-center flex-grow-1 number-counter-toggle">{parseInt(item.rating) > 0 ? item.rating : 0}</span>
                                          </div> : <></>}
                                        {/* star icon end */}

                                        {/* priority icon start */}
                                        {checkListIconToggle == index || item.priority == 1 ?
                                          <div
                                            className={`comman_action_icon me-2`}
                                            data-tip={`${_l("l_priority")}`}
                                            data-effect="solid"
                                            data-delay-show='1000'
                                            data-class="tooltip-main"
                                          >
                                            <div
                                              className={` white-border-box d-flex align-items-center h32w32 action_icon with_bg ${isExternal || isSystemTask
                                                ? " for-disabled"
                                                : ""
                                                }`}
                                            >
                                              <div className="priority-checkbox text_wrap position-relative">
                                                <input
                                                  type="checkbox"
                                                  className="absolute-input"
                                                  id="priority"
                                                  onClick={(e) => {
                                                    itemPriority(
                                                      item.id,
                                                      item.finished,
                                                      item.description,
                                                      item.priority == 1 ? 0 : 1
                                                    );
                                                    setCheckListIconToggle([0]);
                                                  }}
                                                  checked={item.priority == 1}
                                                />
                                                <div
                                                  className="check_box"
                                                  onChange={() => {
                                                    // handleChecklistAssignee(0, item.id, 'priority')
                                                  }}
                                                >
                                                  <HighPriorityActiveIcon className="HW18 check_span_img active" />
                                                  <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                                                </div>
                                              </div>
                                            </div>
                                          </div> : <></>}
                                        {/* priority icon end */}

                                        {/* comment icon start */}
                                        {checkListIconToggle == index || item.comment_count > 0  ?
                                          <div
                                            className={`dropend d-flex align-items-center me-10px `}
                                            data-tip={_l("l_comments")}
                                            data-effect="solid"
                                            data-delay-show='1000'
                                            data-class="tooltip-main"
                                          >
                                            {/* ${isExternal
                                                ? " for-disabled"
                                                : ""
                                              } */}
                                            <UncontrolledDropdown
                                              className={`w-100 h-100`}
                                              direction="left"
                                            >
                                              <DropdownToggle
                                                data-toggle="dropdown"
                                                tag="div"
                                              >
                                                <a href="#/"
                                                  className="comman_action_icon "
                                                  type="button"
                                                  id="writeComMentDropdown"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                  data-bs-auto-close="outside"
                                                  onClick={(e) => {
                                                    setOpenCommentTab(!openCommentTab);
                                                    setTempOpenCommentTab(!tempOpenCommentTab);
                                                  }
                                                  }
                                                >
                                                  <div className="d-flex align-items-center">
                                                    <div className="action_icon h32w32 with_bg position-relative on-hover-active-toggle-img z-index-12 for-accessible">
                                                      <ChatCircle
                                                        size={18}
                                                        weight="light"
                                                        className="c-icons"
                                                      />
                                                    </div>
                                                    <span className="c-font f-12 d-flex justify-content-center flex-grow-1 number-counter-toggle">{item.comment_count}</span>
                                                    <span
                                                      className={`rounded-pill badge-custom p-0 ${item.unread_comment_count > 0
                                                        ? ""
                                                        : "d-none"
                                                        }`}
                                                      data-unread-cmt-id={item.id}
                                                    >
                                                      {item.unread_comment_count}
                                                    </span>
                                                    <span className="c-font f-12 opacity-50 text-on-hover-active-green number-counter-toggle">
                                                      {commentCount[item.id]}
                                                    </span>
                                                  </div>
                                                </a>
                                              </DropdownToggle>
                                              <DropdownItem
                                                toggle={false}
                                                className="p-0"
                                              >
                                                {
                                                  <CommentDropdown
                                                    docType={"imageview"}
                                                    data={selectedTask}
                                                    key={index}
                                                    openCommentTab={openCommentTab}
                                                    setOpenCommentTab={
                                                      setOpenCommentTab
                                                    }
                                                    projectId={projectId}
                                                    task_id={task_id}
                                                    updatedCommentCount={(count) => {
                                                      setCommentCount({
                                                        ...commentCount,
                                                        [item.id]: count,
                                                      });
                                                    }}
                                                    checklistId={item.id}
                                                    allComment={1}
                                                    unContolList={true}
                                                    isExternal={isExternal}
                                                    hash_id={isExternal ? data.hash : 0}
                                                    mainClassName="checklist-comment"
                                                    setChecklistItems={setChecklistItems}
                                                    checklistItems={checklistItems}
                                                  />
                                                }
                                              </DropdownItem>
                                            </UncontrolledDropdown>
                                          </div> : <></>}
                                        {/* comment icon end */}

                                        {/* equipment Assigness start */}

                                      { checkListIconToggle == index || selectedFloor.id < 0 ?
                                        <div
                                          className={`dropdown-center multi_inner_wrapper with-min-width ps-0 d-flex with_minus ${
                                            totalEquipments && totalEquipments.length >= 1
                                              ? ""
                                              : "d-none"
                                          }`}
                                        >   
                                        {totalEquipments && totalEquipments.length > 0 && totalEquipments.map((equip , index)=>{
                                          if (equip.checkListId == item.id) {
                                            if (totalEquipments.filter((w)=> w.checkListId == item.id).length == 1) {
                                              return (
                                                <div
                                                  className="badge rounded-pill list-toggle-view-none c-font f-12 d-flex align-items-center"
                                                  key={index}
                                                  data-tip={`${
                                                    equip.main_key == "my_task" ? "#" : ""
                                                  } ${_l(equip.equipment_name)}`}
                                                  data-effect="solid"
                                                  data-delay-show="1000"
                                                  data-class="tooltip-main"
                                                >
                                                  <span className="rounded-circle badge with-bg d-flex h20w20 p-0 me-1">
                                                    <span className="m-auto p-0">
                                                      {equip.qty}
                                                    </span>
                                                  </span>
                                                  <span className={` p-0 text-truncate c-font f-12`}>
                                                    {equip.main_key == "my_task"
                                                      ? "#"
                                                      : ""}
                                                    {_l(equip.equipment_name)}
                                                  </span>
                                                  <ReactTooltip />
                                                </div>
                                              );
                                            }else{
                                              if (index == 0) {
                                                return (
                                                  <div
                                                    className="badge rounded-pill list-toggle-view-none c-font f-12 d-flex align-items-center ps-5px"
                                                    key={index}
                                                    data-tip={`${
                                                      equip.main_key == "my_task" ? "#" : ""
                                                    } ${_l(equip.equipment_name)}`}
                                                    data-effect="solid"
                                                    data-delay-show="1000"
                                                    data-class="tooltip-main"
                                                  >
                                                    <span className="rounded-circle badge with-bg d-flex h20w20 p-0 me-1">
                                                    <span className="m-auto p-0">
                                                      {equip.qty}
                                                    </span>
                                                  </span> 
                                                    <span className={` p-0 text-truncate c-font f-12`}>
                                                      {equip.main_key == "my_task"
                                                        ? "#"
                                                        : ""}
                                                      {_l(equip.equipment_name)}
                                                    </span>
                                                    <ReactTooltip />
                                                  </div>
                                                );
                                              }else{
                                                return(
                                                  <>
                                                    <a
                                                      href="#/"
                                                      className="badge rounded-pill text-nowrap h32w32 list-toggle-view-none ms-10px me-10px c-font f-12 d-flex align-items-center"
                                                      data-bs-toggle="dropdown"
                                                      aria-expanded="false"
                                                      data-tip={`${_l("l_view_all")}`}
                                                      data-effect="solid"
                                                      data-delay-show="1000"
                                                      data-class="tooltip-main"
                                                    // onClick={handleTaskAssigneeData}
                                                    >
                                                      <div className="unread_count m-auto c-font f-12
                                              ">
                                                        +{totalEquipments.length - 1}
                                                      </div>
                                                    </a>
                                                    {totalEquipments && totalEquipments.length ? (
                                            <GeneralListing
                                              handler={() => {}}
                                              list={
                                                totalEquipments &&
                                                totalEquipments.length &&
                                                totalEquipments.map((item, index) => {
                                                  return {
                                                    name: _l(item.equipment_name),
                                                    id: index,
                                                    qty : item.qty
                                                  };
                                                })
                                              }
                                              isHash={false}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                                  </>
                                                )

                                              }

                                            }
                                            
                                          }
                                        })}
                                        </div>
                                        : <></>
                                      }
                                        
                                       {/* <div
                                          className={`dropdown-center multi_inner_wrapper with-min-width ps-0 d-flex with_separator_10 with_minus ${
                                            item.consumable_equipments_assignee && item.consumable_equipments_assignee.length >= 1
                                              ? ""
                                              : "d-none"
                                          }`}
                                        >
                                          {item.consumable_equipments_assignee &&
                                          item.consumable_equipments_assignee.length >= 1 ? (
                                            item.consumable_equipments_assignee.map((assignee, index) => {
                                              if (index < 1)
                                                return (
                                                  <div
                                                    className="badge rounded-pill text-nowrap list-toggle-view-none c-font f-12 d-flex align-items-center  max-width75"
                                                    key={index}
                                                    data-tip={`${
                                                      assignee.main_key == "my_task" ? "#" : ""
                                                    } ${_l(assignee.equipment_name)}`}
                                                    data-effect="solid"
                                                    data-delay-show="1000"
                                                    data-class="tooltip-main"
                                                  >
                                                    <span className={` p-0 text-truncate c-font f-12`}>
                                                      {assignee.main_key == "my_task"
                                                        ? "#"
                                                        : ""}
                                                      {_l(assignee.equipment_name)+" "+ String(Number(index) + 1 )}
                                                    </span>
                                                    <ReactTooltip />
                                                  </div>
                                                );
                                            })
                                          ) : (
                                            <></>
                                          )}
                                          {item.consumable_equipments_assignee && item.consumable_equipments_assignee.length > 1 ? (
                                            <a
                                              href="#/"
                                              className="badge rounded-pill text-nowrap list-toggle-view-none ms-10px c-font f-12 d-flex align-items-center"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                              data-tip={`${_l("l_view_all")}`}
                                              data-effect="solid"
                                              data-delay-show="1000"
                                              data-class="tooltip-main"
                                              // onClick={handleTaskAssigneeData}
                                            >
                                              <div className="unread_count m-auto c-font f-12
                                              ">
                                                +{item.consumable_equipments_assignee.length - 1}
                                              </div>
                                            </a>
                                          ) : (
                                            <></>
                                          )}
                                          {item.consumable_equipments_assignee && item.consumable_equipments_assignee.length ? (
                                            <GeneralListing
                                              handler={() => {}}
                                              list={
                                                item.consumable_equipments_assignee &&
                                                item.consumable_equipments_assignee.length &&
                                                item.consumable_equipments_assignee.map((item, index) => {
                                                  return {
                                                    name: _l(item.equipment_name) + " " + String(Number(index) + 1 ),
                                                    id: index,
                                                  };
                                                })
                                              }
                                              isHash={false}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </div> 
                                        */}
                                      { checkListIconToggle == index || (selectedFloor.id < 0 && item.consumable_equipments_assignee && item.consumable_equipments_assignee.length > 0) ?
                                        <UncontrolledDropdown className={`p-10  res py-0 pe-0 comman_action_icon  ps-0`}>
                                          <div className="d-flex align-items-center m-auto light-theme-img shadow-none comman_action_icon justify-content-center">
                                            <DropdownToggle tag={'div'} className="d-flex dropdown dropdown-center team-list-dropdown ">
                                              <a href="#/"
                                                className={`d-flex on-hover-active-toggle-img h32w32 with_bg action_icon me-10px 'activeicon'`}
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                data-bs-auto-close="outside"
                                                aria-expanded="false"
                                                data-tip={_l("l_equipment")}
                                                data-effect="solid"
                                                data-delay-show='1000'
                                                data-class="tooltip-main"
                                                onClick={() => seteuipmentModals(index)}
                                                >
                                                <Wrench
                                                size={18}
                                                className="c-icons"
                                                weight="light"
                                                /> 
                                                {/* {selectedleadfilers.length > 0 ?
                                                  <span className="badge-custom email-orange d-flex rounded-pill p-0"></span>
                                                : <></>}                                   */}
                                              </a>
                                              <ReactTooltip />
                                            </DropdownToggle>
                                            <div toggle={false} className="px-0"> 
                                            {euipmentModals === index ? 
                                              <UnControlMainFiltersDropDown 
                                                type={"isckecklistEuipment"}
                                                data= {item}
                                                addEuipmentchecklists = {addEuipmentchecklists}
                                                />
                                              :<></>}
                                            </div>
                                          </div>
                                        </UncontrolledDropdown> : <></>
                                      }
                                        {/* equipment Assigness end */}

                                         {/* Camera Icon start */}
                                        { (selectedFloor.id < 0 && item.req_photo > 0) || checkListIconToggle == index ?
                                          <div className={`comman_action_icon card-view-less-padding p-0 me-10px`}>
                                            <a href="#/"
                                              className={` white-border-box action_icon with_bg h32w32`}
                                              data-tip={_l("l_photo_required")}
                                              data-effect="solid"
                                              data-delay-show='1000'
                                              data-class="tooltip-main"
                                            >
                                              <div className="">
                                                <Camera size={18} className="c-icons" weight="light" />
                                              </div>
                                            </a>
                                          </div> : <></>}
                                        {/* Camera Icon end */}

                                        {/* Delete Icon start */}
                                        {checkListIconToggle == index ?
                                          <div className={`comman_action_icon card-view-less-padding p-0 me-10px ${scheduleChecklistId ? "" : item.is_allowed_to_edit == 1
                                            ? ""
                                            : "for-disabled"
                                            } ${isExternal ? "d-none" : ""}`}>
                                            <a href="#/"
                                              className={`priority-checkbox span-13 white-border-box action_icon with_bg h32w32`}
                                              data-tip={_l("l_delete")}
                                              data-effect="solid"
                                              data-delay-show='1000'
                                              data-class="tooltip-main"
                                              onClick={() => { handleDeleteChecklist(item.id,item.item_id); }}
                                            >
                                              <div className="">
                                                <Trash size={18} className="c-icons" weight="light" />
                                              </div>
                                            </a>
                                          </div> : <></>}
                                        {/* Delete Icon end */}

                                        {/* checklist Assigness start */}
                                        { (selectedFloor.id < 0 && item.assigned_details) || checkListIconToggle == index ?
                                        <div
                                          className={`bottom-right-checklist for-accessible`}
                                        >
                                          <div className="d-flex align-items-center">
                                            {item.assigned_details &&
                                              (item.assigned_details.full_name ||
                                                (item.assigned_details &&
                                                  item.assigned_details.name)) ? (
                                              <div className="assignee-img-with-remove me-10px">
                                                <div
                                                  className="h32w32 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle"
                                                  data-tip={
                                                    item.assigned_details.full_name ||
                                                    (item.assigned_details &&
                                                      item.assigned_details.name)
                                                  }
                                                  data-place="left"
                                                  data-effect="solid"
                                                  data-delay-show='1000'
                                                  data-class="tooltip-main"
                                                >
                                                  <div
                                                    className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                                    style={{
                                                      backgroundImage: `url('${item.assigned_details
                                                        .profile_image_url ||
                                                        item.assigned_details
                                                          .profile_image
                                                        }')`,
                                                    }}
                                                  ></div>
                                                  {isAllowedToRemoveChecklistAssignment(item) ? (
                                                    <a href="#/"
                                                      className="remove-img bg-blue"
                                                      onClick={() => {
                                                        handleChecklistAssignee(
                                                          "",
                                                          item.id
                                                        );
                                                      }}
                                                    >
                                                      <X
                                                        size={10}
                                                        weight="light"
                                                        className="c-icons"
                                                      />
                                                    </a>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </div>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                          : <></>}
                                        {/* checklist Assigness end */}

                                      {/* checklist Attachments start */}
                                    { checkListIconToggle == index || selectedFloor.id < 0 ?
                                      <div className={`d-flex align-items-center comman_action_icon multi_inner_wrapper ${isExternal ? "z-index-12" : ""}`}>
                                        {item && item.attachments && item.attachments.length > 0 &&
                                        item.attachments.map ((img , index) =>
                                        {return(<>
                                        {index == 0 ? 
                                        <div className="assignee-img-with-remove me-10px">
                                          <a href="#/"
                                            className="h32w32 comman-round-box with-bg d-flex align-items-center bg-white-05 radius_3"
                                            data-tip={""}
                                            data-place="left"
                                            data-effect="solid"
                                            data-delay-show='1000'
                                            data-class="tooltip-main"
                                            onClick={()=>{
                                              let tempdetails = {
                                                id: img.id,
                                                name : img.image_url,
                                                image: img.image_url,
                                                otherData : img
                                              }
                                              if(isExternal) {
                                                setOpenGallery(true);
                                                setLightBoxAttachments(item.attachments)
                                              } else {
                                              setAllAttachments(item.attachments)
                                              selectAttachment(tempdetails)}}
                                              }
                                          >
                                            <div
                                              className="comman-bg-img h-100 w-100 bg-style-cover radius_3"
                                              style={{
                                                backgroundImage: `url('${img.image_url}')`,
                                              }}
                                            ></div>
                                          </a>
                                        </div> : <></>}
                                        {index == 1 && item.attachments.length > 1 ?
                                        <>
                                        <div className="assignee-img-with-remove me-10px">
                                         <a href="#/"
                                            className="h32w32 comman-round-box with_overlay d-flex align-items-center bg-white-05 radius_3"
                                            data-tip={""}
                                            data-place="left"
                                            data-effect="solid"
                                            data-delay-show='1000'
                                            data-class="tooltip-main"
                                          >
                                          <div class="comman-bg-img h-100 w-100 bg-style-cover radius_3 d-flex"
                                            type="button"
                                            id="GeneralListing"
                                            data-bs-toggle="dropdown"
                                            data-bs-auto-close="outside"
                                            aria-expanded="false">
                                              <div class="unread_count m-auto">+{item.attachments.length - 1}</div> 
                                          </div>
                                          <GeneralListing 
                                          handler={
                                            isExternal ? (attachData)=>{
                                              setLightBoxAttachments(item.attachments);
                                              setOpenGallery(true);
                                              setAttchmentOnTop(attachData.id ,item.attachments);
                                            } :
                                            selectAttachment} 
                                          roundedImage={false} list={item.attachments.map((generalAttach, index) => {
                                            return {
                                              name: generalAttach.file_name,
                                              image: generalAttach.image_url,
                                              id: generalAttach.id,
                                              otherData: generalAttach
                                            };
                                          })} />
                                          </a>
                                          </div>
                                        </>
                                      : <></>}
                                        </>)}
                                        )}
                                        
                                       
                                      </div>
                                      : <></>
                                    }
                                      {/* checklist Attachments end */}
                                      
                                        {item.rating > 0 || item.priority == 1 || item.assigned_details || item.comment_count > 0 || (item.attachments && item.attachments.length > 0) || checkListIconToggle == index ? <div className="with_separator_10 before-block p-0 me-10px"></div> : <></>}


                                      {/* calendar icon strat */}

                                      <div className="comman_action_icon custom-datepicker withinputabsolute action_icon h32w32 with_bg  me-10px w-330px"
                                        data-tip={moment(item.start_date).format("DD/MM/YYYY")}
                                        data-effect="solid"
                                        data-delay-show='1000'
                                        data-class="tooltip-main">
                                        <div className={`m-auto on-hover-active-toggle-img light-theme-img position-relative`}>
                                          <CalendarBlank
                                            size={18}
                                            weight="light"
                                            className="c-icons"
                                          />
                                          <DatePicker
                                            portalId="custom_datepicker"
                                            calendarClassName="custom-datepicker"
                                            className="close-toast"
                                            selected={new Date(item.start_date)}
                                            onChange={(date)=>{
                                              handleCheckListUpdate(
                                                item.id,
                                                item.description,
                                                item.finished,
                                                undefined,
                                                index,
                                                item.priority,
                                                item.status,
                                                date
                                                 )
                                                 const index = checklistItems.findIndex(
                                                  (i) => i.id == item.id
                                                );
                                                let updatedChecklist = checklistItems[index];
                                                updatedChecklist.start_date = moment(new Date(date)).format('YYYY-MM-DD HH:mm:ss');
                                                setChecklistItems(
                                                  Object.values({ ...checklistItems, [index]: updatedChecklist })
                                                );
                                            }}
                                          ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                                        </div>
                                      </div>

                                      {/* calendar icon end */}

                                        {/* assignne icon start */}
                                        <div
                                          className={`dropdown me-10px ${isSystemTask ? "d-none" : ""
                                            }`}
                                        >
                                          <a href="#/"
                                            className=" h32w32 justify-content-center align-items-center"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            data-bs-auto-close="outside"
                                            aria-expanded="false" 
                                            onClick={()=>{
                                              setOpenFollowersDropdown(!openFollowersDropdown)
                                            }}>
                                            <div className="action_icon h32w32 with_bg position-relative on-hover-active-toggle-img d-flex align-items-center">
                                                <UserCircleGear size={16} className="c-icons" weight="light" />
                                            </div>
                                          </a>
                                          <FollowersDropdown
                                            openFollowersDropdown={
                                              openFollowersDropdown
                                            }
                                            setOpenFollowersDropdown={
                                              setOpenFollowersDropdown
                                            }
                                            task_id={task_id}
                                            checklistId={item.id}
                                            handler={handleChecklistAssignee}
                                            checklistAssigneesList={
                                              checklistItemAssigneeList
                                            }
                                            mainClassName="checklist-followers-dropdown"
                                            is_task_checklist={1}
                                            selectedId={
                                              item.assigned_details != ""
                                                ? localStorage.getItem(
                                                  "user_type"
                                                ) == "contact"
                                                  ? item.assigned_details &&
                                                  item.assigned_details.id
                                                  : item.assigned_details &&
                                                  item.assigned_details.staffid
                                                : 0
                                            }
                                          />
                                        </div>
                                        {/* assignne icon end */}
                                        {isChecked ? <></> : <></>}
                                      </div>
                                      <ReactTooltip />
                                    </div>
                                  </div> 
                                  <a className={`white-border-box color-white-60 action_icon h32w32 with_bg with-border-active for-accessible ${isCallRecived ? "" : "z-index-12"}`}  onMouseEnter={()=>{
                                    let arr = checkListIconToggle
                                    // setTempChecklistToggle(index);
                                    // if(jquery("#commentDropDownOpen").hasClass("show")|| jquery("#FolloWersDropdown").hasClass("show")){
    
                                    // }else
                                    // if (arr.includes(index)) {
                                    //   arr = arr.filter((d) => parseInt(d) != parseInt(index))
                                    // }else{
                                    //   arr.push(parseInt(index))
                                    //   setCheckListIconToggle(arr.slice())
                                    // }
                                    // arr.push(parseInt(index))
                                    // setCheckListIconToggle([arr.pop()].slice());
                                    // jquery(".checklist-comment, .checklist-followers-dropdown").removeClass("show");
                                    }}
                                    onClick={()=>{
                                      if(checkListIconToggle != index){
                                        setCheckListIconToggle(index)
                                      }else{
                                        setCheckListIconToggle(-1)
                                      }
                                    jquery(".checklist-comment, .checklist-followers-dropdown").removeClass("show");
    
    
                                    }}>
                                    <DotsNine size={16}  weight="light" className="c-icons" />
                                  </a>
                                  
                                </div>
    
                              </div>
                            </div>
                          );
                        })
                    ) : (
                      <>
                        <CommanPlaceholder
                          imgType="no-checklist"
                          mainSpacing="m-auto"
                          placeholderText={_l("l_no_checklist")}
                        />
                      </>
                    )}
                  </div>

                  {/* Tasklist attachment images share task without login start*/}
                    <Lightbox
                      open={openGallery}
                      close={() => setOpenGallery(false)}
                      slides={lightBoxAttachments.map ((img , index)  => {
                        return { src: img.image_url };
                      })}
                    />
                  {/* Tasklist attachment images share task without login end*/}

                    {checklistItems &&
                      checklistItems.length &&
                      checklistItems.length > loadPageNo * itemsPerPage ? (
                      <a
                        className="btn btn-white-03 w-100 view-more-btn"
                        onClick={() => {
                          setLoadPageNo(loadPageNo + 1);
                        }}
                      >
                        <div className="d-flex align-item-center justify-content-center">
                          <span className=" title-fonts c-font f-12 ms-2">
                            {_l("l_view_more_btn")} (
                            {checklistItems.length - loadPageNo * itemsPerPage})
                          </span>
                          <CaretDoubleDown
                            size={12}
                            className="c-icons mx-2"
                            weight="light"
                          />
                        </div>
                      </a>
                    ) : (
                      <></>
                    )}
                </React.Fragment>
                }
              </React.Fragment>
              </div>
            </div>

          </div>
        </React.Fragment>
      )}
      {showToast && !toastdata ? (
        <Toasts
          delay={2000}
          handler={() => {
            setShowToast(false);
            setCheckListId(0);
          }}
          header={toastHeader}
          message={toastMessage}
          toastClass={toastClass}
          cancelButtonText={cancelButtonText}
          cancelButtonHandler={() => {
            setShowToast(false);
            setCheckListId(0);
          }}
          confirmButtonText={confirmButtonText}
          confirmButtonHandler={() => {
            removeChecklist(checkListId);
            setShowToast(false);
            setCheckListId(0);
          }}
        />
      ) : (
        <></>
      )}
      {showTaskImage && Object.keys(selectedAttachment).length > 0 ? 
        <CommanOffCanvas
          show={showTaskImage}
          handleClose={() => {
            setShowTaskImage(false);
          }}
          data={{
            id: selectedAttachment.id,
            taskId: data.id,
            projectId: projectId,
            attachmentDetails: selectedAttachment,
            taskDetails: data,
            allAttachments: allAttachments
          }}
          docType={"imageview"}
          setSelectedId={()=>{}}
          selectedTaskId={data.id}
          handleParentComponentAction={() => {
            
          }}
        /> : <></>
      }
    </React.Fragment>
  );
};

export default ChecklistComponent;
