import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ReactTooltip from "react-tooltip";
import { _l } from "../../hooks/utilities";
import $ from "jquery";
import { showMessage } from "../../actions/messages";
import ChatServices from "../../services/chat-services";
import PremiumEstimateIconCrown from "../../assets/icons/PremiumEstimateIconCrown";
import Spinner from "react-bootstrap/Spinner";

import {
  Pencil
} from "phosphor-react";

import {
  ATTACH_BASE_URL,
  USR_IMG_PLACEHOLDER,
  MULTI_USR_PLACEHOLDER
} from "../../actions/chat-action-type";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import EditProfileCategoryIcon from "./EditProfileCategoryIcon";

const ChatGroupDetailModal = ({
  projectId,
  show,
  handleClose,
  groupList,
  viewGroupDetails,
  groupDetails,
  isGroupAdmin,
  groupMemberIds,
  setGroupMemberIds,
  previewImage,
  fromaichat,
  providerOpearator,
  preparePreview,
  topicLogo
}) => {
  const dispatch = useDispatch();
  const { defaultTopics, taskFilters } = useSelector((state) => state.customer);
  const [groupName, setGroupName] = useState("");
  const [groupIcon, setGroupIcon] = useState("");
  const [groupIconURL, setGroupIconURL] = useState("");
  const [imgType, setImgType] = useState("");
  const [IconComponent , setIconComponent] = useState(<React.Fragment></React.Fragment>)
  const [groupIconFile, setGroupIconFile] = useState({});
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [chatChannelList, setChatChannelList] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [loder, setLoder] = useState(false);
  const [myContact, setMyContact] = useState([]);
  const [editCategoryIcon, setEditCategoryIcon] = useState(false);
  const userType = localStorage.getItem("user_type");
  const contactRole = localStorage.getItem("contact_role");
  let chatUserId = localStorage.getItem("chatUserId");

  useEffect(() => {
    setChatChannelList(groupList);
  }, [groupList]);

  useEffect(() => {
    setGroupName(viewGroupDetails ? groupDetails && groupDetails.groupName : "");
    setGroupIcon(viewGroupDetails ? groupDetails && groupDetails.displayPicture : "");
    setGroupIconURL(topicLogo ? topicLogo.url : viewGroupDetails ? groupDetails && groupDetails.displayPicture != "" ? ATTACH_BASE_URL+groupDetails.displayPicture : MULTI_USR_PLACEHOLDER : MULTI_USR_PLACEHOLDER );
   
  }, [groupDetails, viewGroupDetails]);

  const capitalize = (str) => {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  };

  const getInitials = (str) => {
    var parts = str.split(" ");
    var initials = "";
    parts.forEach(function (part) {
      if (part.length > 0 && part !== "") {
        initials += capitalize(part[0]);
      }
    });
    return initials;
  };

  const setMember = (id) => {
    var members = selectedMembers.slice();
    var index = members.indexOf(id);
    if (index === -1) {
      members.push(id);
    } else {
      members.splice(index, 1);
    }
    setSelectedMembers(members);
  }

  async function createGroup() {
    if(groupName == ""){
      dispatch(showMessage("unsucess", _l("l_error"),'Please add group name'));
    }else if(selectedMembers.length == 0 || (viewGroupDetails && selectedMembers.length == 1)){
      dispatch(showMessage("unsucess", _l("l_error"),'Please select at least one member'));
    }else{
      setLoder(true);
      var chatGroupIcon = groupIcon;
      if (!$.isEmptyObject(groupIconFile)) {
        const response = await ChatServices.uploadChatMedia(groupIconFile);
        if(response.status == 1){
          chatGroupIcon = response.data.filename;
          setGroupIcon(chatGroupIcon);
          setGroupIconURL(ATTACH_BASE_URL+chatGroupIcon);
          setGroupIconFile({});
        }else{
          dispatch(showMessage("unsucess", _l("l_error"),'Unable to upload group icon'));
        }
      }
      var projectId = localStorage.getItem('selectedOffice');
      var chatUserId = localStorage.getItem('chatUserId');
      var chatGroupId = localStorage.getItem('activeGroupId');

      var groupMembers = [];
      selectedMembers.map((item) => {
        if(item != chatUserId){
          groupMembers.push({ userId: item });
        }
      });

      if(viewGroupDetails){
        ChatServices.editChatGroup(chatUserId, chatGroupId, groupName, groupMembers, chatGroupIcon).then((res) => {
          setLoder(false);
          if (res.success) {
            setSearchString("");
            setGroupMemberIds(selectedMembers);
            handleClose();
            dispatch(showMessage("sucess", _l("l_success"),res.message));
          }else{
            dispatch(showMessage("unsucess", _l("l_error"),res.message));
          }
        });
      }else{
        ChatServices.createChatGroup(projectId, chatUserId, groupName, groupMembers, chatGroupIcon).then((res) => {
          setLoder(false);
          if (res.success) {
            setGroupName("");
            setGroupIcon("");
            setGroupIconFile({});
            setSearchString("");
            setSelectedMembers([]);
            handleClose();
            dispatch(showMessage("sucess", _l("l_success"),res.message));
          }else{
            dispatch(showMessage("unsucess", _l("l_error"),res.message));
          }
        });
      }
    }
  }

  useEffect(() => {
    setSelectedMembers(groupMemberIds);
  }, [groupMemberIds]);

  useEffect(() => {
    if(!viewGroupDetails){
      setSelectedMembers([]);
    }else{
      setSelectedMembers(groupMemberIds);
    }
  }, [viewGroupDetails]);

  useEffect(() => {
    if (defaultTopics && defaultTopics.length && groupDetails && groupDetails.extraData && groupDetails.extraData.mainKey != "my_task") {
      const topics = defaultTopics.filter((key)=> key.main_key == "my_contact");
      if(topics.length)
      {
        let contact = topics[0].categories
        setMyContact(contact)
      }
    }else{
      setMyContact([])
    }    
  }, [defaultTopics, groupDetails])
  

  async function groupIconPreview() {
    var file = await previewImage("group-icon-upload","group-icon-preview","style");
    if(file){
      setGroupIconFile(file);
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>{_l("l_group_details")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0">
          <div className="form-wrapper-main pt-0">
            <div className="row">
              <ul className="p-3 text-center custom-modal-section-sticky pb-1">
                <div 
                  className="h70w70 comman-round-box rounded-circle m-auto bg-white-05 d-flex align-items-center justify-content-center"
                >
                  { 
                    imgType === "icon"
                    ? <IconComponent size={48}/>
                    : <div 
                        className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" 
                        style={{ backgroundImage: `url('${groupIconURL}')` }}
                        id="group-icon-preview"
                      >
                      </div>
                  }
                  
                  {userType == "staff" || contactRole == 1 || contactRole == 2 ?
                  <>
                    {/* <input
                      type="file"
                      accept="image/png, image/jpeg, image/jpg"
                      className="absolute-input"
                      id="group-icon-upload"
                      onChange={(event) =>   
                        { 
                        groupIconPreview()
                        if (event.target.files.length == 1) {
                        preparePreview(event.target.files);                                
                      }}}
                    /> */}
                    <a href="#/" className="remove-img large chatgrp" onClick={(e) => {
                      e.stopPropagation();
                      setEditCategoryIcon(true)
                    }} >
                      <Pencil size={14} weight="light" className="c-icons m-auto" />
                    </a>
                  </>
                  : <></>}
                </div>
                <Form.Group 
                  className="w-75  m-auto"
                >
                  <Form.Control
                    placeholder="Group Name"
                    type="text"
                    className="bg-transparent border-0 form-control text-center"
                    value={ 
                      userType != "staff" && contactRole == 3 
                        ? groupDetails && groupDetails.members && groupDetails.members.filter((item) => item._id != chatUserId).map((item) => item.fname+" "+item.lname).join(', ')
                        : taskFilters && taskFilters.length > 0 && taskFilters.some((key)=> key.filter_key == "myrai_support") 
                          ? "Myr.ai Support" 
                          : taskFilters && taskFilters.length > 0 && taskFilters.filter((filter) => filter.main_key == "my_task")[0] 
                            ? localStorage.getItem('full_name') 
                            : groupDetails && groupDetails.groupName }
                    // onChange={(e) => setGroupName(e.target.value)}
                    disabled={true}
                  />
                </Form.Group>
                {isGroupAdmin || !viewGroupDetails ?
                <>
                <Form.Group className="comment-input-box d-flex flex-grow-1 border-top pt-3">
                  <Form.Control
                    className=" border-0"
                    type="text"
                    placeholder={`${_l("l_search")}`}
                    aria-label="default input example"
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                  />
                 
                </Form.Group>
                
              </>
                : <></>}
                <ReactTooltip />
              </ul>

              <div className="comman_vertical_btn_h scroll_110px">
                <div className="comman-content-scroll-wrapper">
                
                  <ul className="comman-verticle-tab row m-0">
                    {
                      groupDetails && groupDetails.members && groupDetails.members.length > 0 ?
                      groupDetails.members
                        .filter((item) => 
                          (item.fname && item.fname.toLowerCase().includes(searchString.toLowerCase()) 
                          || item.lname && item.lname.toLowerCase().includes(searchString.toLowerCase()))
                        )
                        .map((member, i) => {
                        var userImg = member.displayPicture;
                        if (userImg == "") {
                          userImg = USR_IMG_PLACEHOLDER;
                        } else {
                          userImg = ATTACH_BASE_URL + userImg;
                        }
                        var userName = member.fname+" "+member.lname;
                        var userRole = getInitials(member.myrUserType2);
                        var isChecked = selectedMembers.includes(member._id) ? true : false;
                        return(
                          <li className="comman-list second-last-child-none with-after-50 p-0 col-xl-6">
                            <a href="#/" className="w-100 position-relative tab_link  left-chat ">
                              <div className="d-flex align-items-center">
                                <div className="c-list-icon">
                                  <div className="h35w35 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-5px">
                                    <div
                                      className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                      style={{ backgroundImage: `url('${userImg}')` }}
                                    ></div>
                                  </div>
                                </div>
                                <div className="c-list-detail ps-2 w100minus35">
                                  <div className="top-part pb-1">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div className="chat-u-name text-truncate">{userName}</div>
                                      <div className="d-flex align-items-cente">
                                        <div 
                                          className="badge badge-white rounded-pill badge  badge-white rounded-pill fw-light me-1"
                                          data-tip={member.myrUserType2} 
                                          data-effect="solid"
                                          data-delay-show='1000'
                                          data-class="tooltip-main"
                                        >
                                          <span className="">{userRole}</span>
                                        </div>
                                        {userType == "staff" || contactRole == 1 || contactRole == 2 ?
                                        <div className="topic-switch d-flex align-items-center">
                                          <div className="form-check form-check-inline m-0 ps-2">
                                            <Form.Check
                                              className=" with-no-label-space"
                                              type="switch"
                                              role="switch"
                                              checked={isChecked}
                                              onChange={() => {
                                                setMember(member._id);
                                              }}
                                            />
                                            <label
                                              className="form-check-label p-0"
                                            ></label>
                                          </div>
                                        </div>
                                        : <></> }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                            <ReactTooltip />
                          </li>
                        );
                      })
                      : 
                      <CommanPlaceholder imgType="no-userfound" placeholderText = {_l("l_no_user")} />
                    }
                  </ul>
                              
                  
                
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            {_l("l_close")}
          </Button>
          { userType == "staff" || contactRole == 1 || contactRole == 2 ?
          <Button 
            variant="primary" size="sm"
            onClick = {() => createGroup()}
            disabled={loder ? true : false}
          >
            {loder
              ? _l('l_please_wait')
              : _l("l_save")
            }
            {loder ? <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              className={`ms-2 `}
              aria-hidden="true"
            />
              : <></>
            }
          </Button>
          : <></>}
        </Modal.Footer>
      </Modal>
      {
        editCategoryIcon ? (
          <EditProfileCategoryIcon
            show={editCategoryIcon}
            onClose={ () =>{ 
              setEditCategoryIcon(false)
            }}
            setGroupIconURL={(data) => {
              if (data.type === "icon") {
                setIconComponent(data.file)
              } else {
                setGroupIconURL(data.file)
              }
              setImgType(data.type)
            }}
          />
        ) : (
          <></>
      )}
    </>
  );
};

export default ChatGroupDetailModal;