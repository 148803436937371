import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { _l, showError } from "../../hooks/utilities";
import PremiumBillingTable from "../PremiumPage/PremiumBillingTable";
import CustomerServices from "../../services/customer-services";
import CommanLoader from "../Loader/CommanLoader";

const SpaceIBANConfiguration = ({ show, handleClose }) => {
  const [spaceIbanDetails, setSpaceIbanDetails] = useState({});

  useEffect(() => {
    const getIbanDetails = async () => {
      try {
        const response = await CustomerServices.getIbanList();
        if (response.status && response.data) {
          setSpaceIbanDetails(response.data);
        }
      } catch (e) {
        console.error(e);
        showError("l_something_went_wrong");
      }
    };
    getIbanDetails();
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="custom-modal-style"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={false}
      keyboard={false}
      size={"lg"}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {_l("l_payment")} {_l("l_setup")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className=" pt-0">
        {Object.keys(spaceIbanDetails).length ? (
          <PremiumBillingTable
            insideModal={true}
            handlerIBAN={handleClose}
            BillingsData={spaceIbanDetails}
            IBANStatus={spaceIbanDetails.single_iban_for_all_spaces}
          />
        ) : (
         <></>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SpaceIBANConfiguration;
