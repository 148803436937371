import React, { useState, useEffect } from 'react'
import { Clock } from "phosphor-react";
import { _l, getDateRanges } from '../../../hooks/utilities';
import DatePicker from "react-datepicker";
import useScheduleSteps from '../../../hooks/useScheduleSteps';
import { useDispatch, useSelector } from 'react-redux';
import BenchmarkServices from '../../../services/benchmark-services';
import { showMessage } from '../../../actions/messages';
import moment from 'moment';
import Spinner from "react-bootstrap/Spinner";
import { getBenchmarkDetail, getBenchmarkList, getTopicTasks, projectTaskCounts } from '../../../actions/customer';
import CommanLoader from '../../Loader/CommanLoader';
import { addDays } from '@fullcalendar/react';
import ChecklistCountHeader from './ChecklistCountHeader';

const ScheduleSummary = ({ handleStep, handleClose, selectedTab, hideFooter,step="" }) => {

  const {
    operatorAvailability,
    setOperatorAvailability,
    calculationsCounts,
    assignedChecklistItems,

  } = useScheduleSteps();

  const [isLoading, setIsLoading] = useState(false);
  const [blockedDays, setBlockedDays] = useState([]);
  const [cycleRangeDates, setCycleRangeDates] = useState({
    minMonthlyCycleDate: '',
    maxMonthlyCycleDate: '',
    minQuarterlyCycleDate: '',
    maxQuarterlyCycleDate: '',
    minHalfYearlyCycleDate: '',
    maxHalfYearlyCycleDate: '',
    minYearlyCycleDate: '',
    maxYearlyCycleDate: ''
  });

  const dispatch = useDispatch();
  const { createScheduleDetail } = useSelector(
    (state) => state.benchmarkReducer
  );

  const { selectedProject, benchmarkDetail, benchmarkSchedule } =
    useSelector((state) => state.customer);

  const filterDate = (date) => {
    const day = date.getDay();
    return !blockedDays.includes(day);
  };

  const handleOperatorTimeSelect = (position, day, time) => {
    setOperatorAvailability(Object.values({ ...operatorAvailability, [position]: { ...operatorAvailability[position], time_slot: { ...operatorAvailability[position].time_slot, [day]: time } } }));
  };

  const setScheduleCycleDates = () => {
    if(createScheduleDetail)
    {
      let scheduleStartDate = createScheduleDetail.startDate;
      if (scheduleStartDate) {
        scheduleStartDate = moment(createScheduleDetail.startDate);

        //Monthly Cycle Date setup
        let monthlyDateRange = getDateRanges(scheduleStartDate, 30);

        //Quarterly Cycle Date setup
        let quarterlyDateRange = getDateRanges(scheduleStartDate, 90);

        //Half Yearly Cycle Date setup
        let halfYearlyDateRange = getDateRanges(scheduleStartDate, 180);

        //Yearly Cycle Date setup
        let yearlyDateRange = getDateRanges(scheduleStartDate, 365);

        setCycleRangeDates({ ...cycleRangeDates, maxHalfYearlyCycleDate: new Date(halfYearlyDateRange.maxDate), maxMonthlyCycleDate: new Date(monthlyDateRange.maxDate), maxQuarterlyCycleDate: new Date(quarterlyDateRange.maxDate), maxYearlyCycleDate: new Date(yearlyDateRange.maxDate), minHalfYearlyCycleDate: new Date(halfYearlyDateRange.minDate), minMonthlyCycleDate: new Date(monthlyDateRange.minDate), minQuarterlyCycleDate: new Date(quarterlyDateRange.minDate), minYearlyCycleDate: new Date(yearlyDateRange.minDate) });
      }
    }
  };

  const handleSave = () => {
    if (operatorAvailability && operatorAvailability.length) {
      let validation = true;
      const remainingAssignmentItems = calculationsCounts && calculationsCounts.headerCounts ? Object.values(calculationsCounts.headerCounts).reduce(
        (a, b) => a + b
      ) : 0;
      if (remainingAssignmentItems > 0) {
        dispatch(
          showMessage(
            "unsucess",
            _l("l_error"),
            `${_l("l_assign_all_available_slots")}`
          )
        );
        return false;
      }
      operatorAvailability.forEach((x, index) => {
        if (x.assignee_id <= 0 && x.assigned_items && x.assigned_items.length) {
          validation = false;
          dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              `${_l("l_please_select_assignees")} ${index + 1} `
            )
          );
        }
      });
      if (validation) {
        setIsLoading(true);
        let availability = [];
        const assignees = operatorAvailability.filter((x) => {
          if (x.assigned_items.length) {
            const availabilityObject = {
              id: x.id,
              assignee_id: x.assignee_id,
              assignee_name: x.assignee_name,
              task_title: x.task_title,
              available_from: moment(x.available_from).format("HH:mm"),
              days: {},
              time_slot: {}
            }
            Object.keys(x.days).forEach((day_name) => {
              availabilityObject.days[day_name] = x.days[day_name];
              if (x.time_slot[day_name] == "") {
                availabilityObject.time_slot[day_name] = "";
              }
              if (!['monthly', 'quarterly', 'half_yearly', 'yearly'].includes(day_name)) {
                availabilityObject.time_slot[day_name] = x.time_slot[day_name] ? moment(x.time_slot[day_name]).format("HH:mm") : "";
              }
              else {
                availabilityObject.time_slot[day_name] = x.time_slot[day_name] ? moment(x.time_slot[day_name] || new Date).format("YYYY-MM-DD HH:mm") : "";
              }
            });
            availability.push(availabilityObject);
            return {
              id: x.id,
              assignee_id: x.assignee_id,
              item: x.assigned_items.map((x) => x.id),
              days: x.days,
            };
          }
        });
        const assigned_items = [];
        assignedChecklistItems.forEach((item) => {
          if (item.assigned_days) {
            let slotDetail = { item_id: item.id, days: {}, priority: 0 };
            Object.keys(item.days).forEach((day_name) => {
              let operatorSlotIndex = item.assigned_days[day_name] == undefined ? -1 : item.assigned_days[day_name];
              if (operatorSlotIndex !== undefined && operatorSlotIndex !== -1) {
                slotDetail.days[day_name] = operatorAvailability[operatorSlotIndex].assignee_id;
              }
              else if (operatorSlotIndex === -1) {
                slotDetail.days[day_name] = 0;
              }
            });
            slotDetail.priority = item.priority;
            assigned_items.push(slotDetail);
          }
        });
        BenchmarkServices.setScheduleSlotAssign(
          createScheduleDetail.benchmarkId,
          availability,
          assignees,
          assigned_items
        ).then((res) => {
          if (res.status) {
            dispatch(getBenchmarkList(selectedProject));
            dispatch(getBenchmarkDetail(benchmarkDetail.id, undefined, "edit", "generated_tasks", res.data));
            dispatch(getTopicTasks(selectedProject));
            dispatch(projectTaskCounts(selectedProject));
            handleStep("task-summary");
          }
        }).catch(() => {
          handleClose();
        })
        .finally(() => {
          setIsLoading(false);
        });
      }
    } else {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          `${_l("l_please_add_checklist_items")}`
        )
      );
    }
  };

  useEffect(() => {
    if (benchmarkSchedule && benchmarkSchedule.total_count) {
      let blockedDaysArray = [];
      Object.keys(benchmarkSchedule.total_count).forEach((day_name) => {
        if (benchmarkSchedule.total_count[day_name] == 0) {
          switch (day_name) {
            case "sun":
              blockedDaysArray.push(0);
              break;
            case "mon":
              blockedDaysArray.push(1);
              break;
            case "tue":
              blockedDaysArray.push(2);
              break;
            case "wed":
              blockedDaysArray.push(3);
              break;
            case "thu":
              blockedDaysArray.push(4);
              break;
            case "fri":
              blockedDaysArray.push(5);
              break;
            case "sat":
              blockedDaysArray.push(6);
              break;
            default:
              break;
          }
        }
      });
      if(blockedDaysArray.length == 7)
      {
        blockedDaysArray = [0, 6];
      }
      setBlockedDays(blockedDaysArray);
    }
    setScheduleCycleDates();
  }, [benchmarkDetail, benchmarkSchedule, createScheduleDetail]);

  return (
    <React.Fragment>
      
          <React.Fragment>
            <div className="task-checklist-wrapper add-benchmark-wrapper comman-content-scroll-wrapper flex-grow-1">
              <div className="h-100 position-relative w-100">
                <div className="h-100 d-flex comman-data-table overflow-auto">
                <div className="bg-transparent left-content-part pt-0 w-300 p-0 left-sticky d-flex flex-column">
                  <ChecklistCountHeader selectedTab={selectedTab} componentReender="leftpart" />
                  <div className="benchmark-left left-content-part d-flex flex-column px-0 pt-0 w-100 flex-grow-1">
                    <table className="dataTable left-content-part bg-transparent res-height-100 p-0 comman-table">
                      <thead className="bgspacelisting bgOfficelisting" style={{top: "76px"}}>
                        <tr className="bgspacelisting bgOfficelisting">
                          <th className="border-bottom px-0 fw-semibold px-2">
                            {_l("l_operators")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          operatorAvailability.map((operator, index) => {
                            return (
                              <tr key={index}>
                                <td className="hr_1 px-0 w-100  me-2  px-2">
                                  <div className="d-flex align-items-center w-100">
                                    <div className="c-list-icon">
                                      <div className="h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle">
                                        <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{ backgroundImage: `url('${operator.assignee_image}')` }}></div>
                                      </div>
                                    </div>
                                    <div className="ps-2 w100minus20 text_wrap">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div className="c-list-detail text-truncate color-white-60 max-w-130px">{operator.assignee_name}</div>
                                        <div className="flex-grow-1"><div><input placeholder={`${_l("l_task_name")}`} type="text" class="form-control h-35 text-truncate" value={operator.task_title} disabled /></div></div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="right-content-part width-calc-300 p-0">
                  <ChecklistCountHeader selectedTab={selectedTab} componentReender="rightpart" step={step} />
                  <div className="d-flex flex-column res-overflow-auto w-100">
                    <table className="dataTable smaller-table">
                      <thead className="bgspacelisting lightthemebgblue table-th-px-26px" style={{top: "76px"}}>
                        <th
                          style={{ width: "5%" }}
                          className="text-center border-bottom "
                        >
                          {_l("l_week_single_letter_monday")}
                        </th>
                        <th
                          style={{ width: "5%" }}
                          className="text-center border-bottom"
                        >
                          {_l("l_week_single_letter_tuesday")}
                        </th>
                        <th
                          style={{ width: "5%" }}
                          className="text-center border-bottom"
                        >
                          {_l("l_week_single_letter_wednesday")}
                        </th>
                        <th
                          style={{ width: "5%" }}
                          className="text-center border-bottom"
                        >
                          {_l("l_week_single_letter_thrusday")}
                        </th>
                        <th
                          style={{ width: "5%" }}
                          className="text-center border-bottom"
                        >
                          {_l("l_week_single_letter_friday")}
                        </th>
                        <th
                          style={{ width: "5%" }}
                          className="text-center border-bottom"
                        >
                          {_l("l_week_single_letter_saturday")}
                        </th>
                        <th
                          style={{ width: "5%" }}
                          className="text-center border-bottom"
                        >
                          {_l("l_week_single_letter_sunday")}
                        </th>
                        <td
                            style={{ width: "1%" }}
                            className="text-center fw-normal py-1 user-select-none border-bottom"
                        ></td>
                        <th
                          style={{ width: "8%" }}
                          className="text-center border-bottom"
                        >
                          {_l("l_single_letter_monthly")}
                        </th>
                        <th
                          style={{ width: "8%" }}
                          className="text-center border-bottom"
                        >
                          {_l("l_single_letter_quaterly")}
                        </th>
                        <th
                          style={{ width: "8%" }}
                          className="text-center border-bottom"
                        >
                          {_l("l_single_letter_semester")}
                        </th>
                        <th
                          style={{ width: "8%" }}
                          className="text-center border-bottom"
                        >
                          {_l("l_single_letter_yearly")}
                        </th>
                      </thead>
                      <tbody>
                        {
                          operatorAvailability.map((operator, index) => {
                            return (
                              <tr key={index}>
                                <td className="px-0 px-1 ps-2 h45w45 w-auto">
                                  {
                                    operator.days["mon"] ?
                                      <a href="#/"
                                        className="white-border-box custom-datepicker with-margin-15 mx-0 f-10 c-font bg-transparent form-control h-35"
                                        data-bs-toggle="tooltip"
                                        data-tip=""
                                        data-effect="solid"
                                        data-delay-show='1000'
                                        data-class="tooltip-main"
                                      >
                                        <div className="d-flex align-items-center custom-timepicker">
                                          {/* <Clock size={16} className="c-icons" weight="light" /> */}
                                          <DatePicker
                                            onKeyDown={(e) => {
                                              e.preventDefault();
                                            }}
                                            className="m-0 ps-0 text-center"
                                            readOnly={!operator.days["mon"]}
                                            onChange={(date) => { handleOperatorTimeSelect(index, "mon", date) }}
                                            selected={operator.days["mon"] && (moment(operator.days["mon"]).isValid() || operator.time_slot["mon"] instanceof Date) ? operator.time_slot["mon"] : ""}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            placeholderText={`${_l('l_time_placeholder')}`}
                                            dateFormat="h:mm aa"
                                            calendarStartDay={1}
                                          />
                                        </div>
                                      </a>
                                      :
                                      <></>
                                  }
                                </td>
                                <td className="px-0 px-1 h45w45 w-auto">
                                  {operator.days["tue"] ?
                                    <a href="#/"
                                      className="white-border-box custom-datepicker with-margin-15 mx-0 f-10 c-font bg-transparent form-control h-35"
                                      data-bs-toggle="tooltip"
                                      data-tip=""
                                      data-effect="solid"
                                      data-delay-show='1000'
                                      data-class="tooltip-main"
                                    >
                                      <div className="d-flex align-items-center custom-timepicker">
                                        {/* <Clock size={16} className="c-icons" weight="light" /> */}
                                        <DatePicker
                                          onKeyDown={(e) => {
                                            e.preventDefault();
                                          }}
                                          className="m-0 ps-0 text-center"
                                          readOnly={!operator.days["tue"]}
                                          onChange={(date) => { handleOperatorTimeSelect(index, "tue", date) }}
                                          selected={operator.days["tue"] && (moment(operator.days["tue"]).isValid() || operator.time_slot["tue"] instanceof Date) ? operator.time_slot["tue"] : ""}
                                          showTimeSelect
                                          showTimeSelectOnly
                                          timeIntervals={15}
                                          timeCaption="Time"
                                          placeholderText={`${_l('l_time_placeholder')}`}
                                          dateFormat="h:mm aa"
                                          calendarStartDay={1}
                                        />
                                      </div>
                                    </a>
                                    :
                                    <></>}

                                </td>
                                <td className="px-0 px-1 h45w45 w-auto">
                                  {
                                    operator.days["wed"] ?
                                      <a href="#/"
                                        className="white-border-box custom-datepicker with-margin-15 mx-0 f-10 c-font bg-transparent form-control h-35"
                                        data-bs-toggle="tooltip"
                                        data-tip=""
                                        data-effect="solid"
                                        data-delay-show='1000'
                                        data-class="tooltip-main"
                                      >
                                        <div className="d-flex align-items-center custom-timepicker">
                                          {/* <Clock size={16} className="c-icons" weight="light" /> */}
                                          <DatePicker
                                            onKeyDown={(e) => {
                                              e.preventDefault();
                                            }}
                                            className="m-0 ps-0 text-center"
                                            readOnly={!operator.days["wed"]}
                                            onChange={(date) => { handleOperatorTimeSelect(index, "wed", date) }}
                                            selected={operator.days["wed"] && (moment(operator.days["wed"]).isValid() || operator.time_slot["wed"] instanceof Date) ? operator.time_slot["wed"] : ""}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            placeholderText={`${_l('l_time_placeholder')}`}
                                            dateFormat="h:mm aa"
                                            calendarStartDay={1}
                                          />
                                        </div>
                                      </a>
                                      :
                                      <></>
                                  }
                                </td>
                                <td className="px-0 px-1 h45w45 w-auto">
                                  {
                                    operator.days["thu"] ?
                                      <a href="#/"
                                        className="white-border-box custom-datepicker with-margin-15 mx-0 f-10 c-font bg-transparent form-control h-35"
                                        data-bs-toggle="tooltip"
                                        data-tip=""
                                        data-effect="solid"
                                        data-delay-show='1000'
                                        data-class="tooltip-main"
                                      >
                                        <div className="d-flex align-items-center custom-timepicker">
                                          {/* <Clock size={16} className="c-icons" weight="light" /> */}
                                          <DatePicker
                                            onKeyDown={(e) => {
                                              e.preventDefault();
                                            }}
                                            className="m-0 ps-0 text-center"
                                            readOnly={!operator.days["thu"]}
                                            onChange={(date) => { handleOperatorTimeSelect(index, "thu", date) }}
                                            selected={operator.days["thu"] && (moment(operator.days["thu"]).isValid() || operator.time_slot["thu"] instanceof Date) ? operator.time_slot["thu"] : ""}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            placeholderText={`${_l('l_time_placeholder')}`}
                                            dateFormat="h:mm aa"
                                            calendarStartDay={1}
                                          />
                                        </div>
                                      </a>
                                      :
                                      <></>
                                  }
                                </td>
                                <td className="px-0 px-1 h45w45 w-auto">
                                  {
                                    operator.days["fri"] ?
                                      <a href="#/"
                                        className="white-border-box custom-datepicker with-margin-15 mx-0 f-10 c-font bg-transparent form-control h-35"
                                        data-bs-toggle="tooltip"
                                        data-tip=""
                                        data-effect="solid"
                                        data-delay-show='1000'
                                        data-class="tooltip-main"
                                      >
                                        <div className="d-flex align-items-center custom-timepicker">
                                          {/* <Clock size={16} className="c-icons" weight="light" /> */}
                                          <DatePicker
                                            onKeyDown={(e) => {
                                              e.preventDefault();
                                            }}
                                            className="m-0 ps-0 text-center"
                                            readOnly={!operator.days["fri"]}
                                            onChange={(date) => { handleOperatorTimeSelect(index, "fri", date) }}
                                            selected={operator.days["fri"] && (moment(operator.days["fri"]).isValid() || operator.time_slot["fri"] instanceof Date) ? operator.time_slot["fri"] : ""}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            placeholderText={`${_l('l_time_placeholder')}`}
                                            dateFormat="h:mm aa"
                                            calendarStartDay={1}
                                          />
                                        </div>
                                      </a>
                                      :
                                      <></>
                                  }
                                </td>
                                <td className="px-0 px-1 h45w45 w-auto">
                                  {
                                    operator.days["sat"] ?
                                      <a href="#/"
                                        className="white-border-box custom-datepicker with-margin-15 mx-0 f-10 c-font bg-transparent form-control h-35"
                                        data-bs-toggle="tooltip"
                                        data-tip=""
                                        data-effect="solid"
                                        data-delay-show='1000'
                                        data-class="tooltip-main"
                                      >
                                        <div className="d-flex align-items-center custom-timepicker">
                                          {/* <Clock size={16} className="c-icons" weight="light" /> */}
                                          <DatePicker
                                            onKeyDown={(e) => {
                                              e.preventDefault();
                                            }}
                                            className="m-0 ps-0 text-center"
                                            readOnly={!operator.days["sat"]}
                                            onChange={(date) => { handleOperatorTimeSelect(index, "sat", date) }}
                                            selected={operator.days["sat"] && (moment(operator.days["sat"]).isValid() || operator.time_slot["sat"] instanceof Date) ? operator.time_slot["sat"] : ""}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            placeholderText={`${_l('l_time_placeholder')}`}
                                            dateFormat="h:mm aa"
                                            calendarStartDay={1}
                                          />
                                        </div>
                                      </a>
                                      :
                                      <></>
                                  }
                                </td>
                                <td className="px-0 px-1 h45w45 w-auto">
                                  {
                                    operator.days["sun"] ?
                                      <a href="#/"
                                        className="white-border-box custom-datepicker with-margin-15 mx-0 f-10 c-font bg-transparent form-control h-35"
                                        data-bs-toggle="tooltip"
                                        data-tip=""
                                        data-effect="solid"
                                        data-delay-show='1000'
                                        data-class="tooltip-main"
                                      >
                                        <div className="d-flex align-items-center custom-timepicker">
                                          {/* <Clock size={16} className="c-icons" weight="light" /> */}
                                          <DatePicker
                                            onKeyDown={(e) => {
                                              e.preventDefault();
                                            }}
                                            className="m-0 ps-0 text-center"
                                            readOnly={!operator.days["sun"]}
                                            onChange={(date) => { handleOperatorTimeSelect(index, "sun", date) }}
                                            selected={operator.days["sun"] ? operator.time_slot["sun"] : ""}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            placeholderText={`${_l('l_time_placeholder')}`}
                                            dateFormat="h:mm aa"
                                            calendarStartDay={1}
                                          />
                                        </div>
                                      </a>
                                      :
                                      <></>
                                  }
                                </td>
                                <td
                                  style={{ width: "1%" }}
                                  className="text-center fw-normal py-1 user-select-none"
                              ></td>
                                <td className="px-0 px-1 h45w45 w-auto">
                                  {
                                    operator.days["monthly"] ?
                                      <a href="#/"
                                        className="white-border-box custom-datepicker with-margin-15 mx-0 f-10 c-font bg-transparent form-control h-35"
                                        data-bs-toggle="tooltip"
                                        data-tip=""
                                        data-effect="solid"
                                        data-delay-show='1000'
                                        data-class="tooltip-main"
                                      >
                                        <div className="d-flex align-items-center">
                                          {/* <Clock size={16} className="c-icons" weight="light" /> */}
                                          <DatePicker
                                            onKeyDown={(e) => {
                                              e.preventDefault();
                                            }}
                                            className="m-0 ps-0 text-center"
                                            filterDate={filterDate}
                                            readOnly={!operator.days["monthly"] || !benchmarkDetail.startdate}
                                            onChange={(date) => { handleOperatorTimeSelect(index, "monthly", date) }}
                                            selected={operator.days["monthly"] && (moment(operator.days["monthly"]).isValid() || operator.time_slot["monthly"] instanceof Date) ? operator.time_slot["monthly"] : ""}
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            showTimeSelect
                                            placeholderText={_l("l_duedate")}
                                            dateFormat="dd/MM/yyyy hh:mm aa"
                                            minDate={cycleRangeDates.minMonthlyCycleDate}
                                            maxDate={cycleRangeDates.maxMonthlyCycleDate}
                                            calendarStartDay={1}
                                          ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                                        </div>
                                      </a>
                                      :
                                      <></>
                                  }
                                </td>
                                <td className="px-0 px-1 h45w45 w-auto">
                                  {
                                    operator.days["quarterly"] ?
                                      <a href="#/"
                                        className="white-border-box custom-datepicker with-margin-15 mx-0 f-10 c-font bg-transparent form-control h-35"
                                        data-bs-toggle="tooltip"
                                        data-tip=""
                                        data-effect="solid"
                                        data-delay-show='1000'
                                        data-class="tooltip-main"
                                      >
                                        <div className="d-flex align-items-center">
                                          {/* <Clock size={16} className="c-icons" weight="light" /> */}
                                          <DatePicker
                                            onKeyDown={(e) => {
                                              e.preventDefault();
                                            }}
                                            className="m-0 ps-0 text-center"
                                            filterDate={filterDate}
                                            readOnly={!operator.days["quarterly"] || !benchmarkDetail.startdate}
                                            onChange={(date) => { handleOperatorTimeSelect(index, "quarterly", date) }}
                                            selected={operator.days["quarterly"] && (moment(operator.days["quarterly"]).isValid() || operator.time_slot["quarterly"] instanceof Date) ? operator.time_slot["quarterly"] : ""}
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            showTimeSelect
                                            placeholderText={_l("l_duedate")}
                                            dateFormat="dd/MM/yyyy hh:mm aa"
                                            minDate={cycleRangeDates.minQuarterlyCycleDate}
                                            maxDate={cycleRangeDates.maxQuarterlyCycleDate}
                                            calendarStartDay={1}
                                          ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                                        </div>
                                      </a>
                                      :
                                      <></>
                                  }
                                </td>
                                <td className="px-0 px-1 h45w45 w-auto">
                                  {
                                    operator.days["half_yearly"] ?
                                      <a href="#/"
                                        className="white-border-box custom-datepicker with-margin-15 mx-0 f-10 c-font bg-transparent form-control h-35"
                                        data-bs-toggle="tooltip"
                                        data-tip=""
                                        data-effect="solid"
                                        data-delay-show='1000'
                                        data-class="tooltip-main"
                                      >
                                        <div className="d-flex align-items-center">
                                          {/* <Clock size={16} className="c-icons" weight="light" /> */}
                                          <DatePicker
                                            onKeyDown={(e) => {
                                              e.preventDefault();
                                            }}
                                            className="m-0 ps-0 text-center"
                                            filterDate={filterDate}
                                            readOnly={!operator.days["half_yearly"] || !benchmarkDetail.startdate}
                                            onChange={(date) => { handleOperatorTimeSelect(index, "half_yearly", date) }}
                                            selected={operator.days["half_yearly"] && (moment(operator.days["half_yearly"]).isValid() || operator.time_slot["half_yearly"] instanceof Date) ? operator.time_slot["half_yearly"] : ""}
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            showTimeSelect
                                            placeholderText={_l("l_duedate")}
                                            dateFormat="dd/MM/yyyy hh:mm aa"
                                            minDate={cycleRangeDates.minHalfYearlyCycleDate}
                                            maxDate={cycleRangeDates.maxHalfYearlyCycleDate}
                                            calendarStartDay={1}
                                          ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                                        </div>
                                      </a>
                                      :
                                      <></>
                                  }
                                </td>
                                <td className="px-0 pe-0 h45w45 w-auto">
                                  {
                                    operator.days["yearly"] ?
                                      <a href="#/"
                                        className="white-border-box custom-datepicker with-margin-15 mx-0 f-10 c-font bg-transparent form-control h-35"
                                        data-bs-toggle="tooltip"
                                        data-tip=""
                                        data-effect="solid"
                                        data-delay-show='1000'
                                        data-class="tooltip-main"
                                      >
                                        <div className="d-flex align-items-center">
                                          {/* <Clock size={16} className="c-icons" weight="light" /> */}
                                          <DatePicker
                                            onKeyDown={(e) => {
                                              e.preventDefault();
                                            }}
                                            className="m-0 ps-0 text-center"
                                            filterDate={filterDate}
                                            readOnly={!operator.days["yearly"] || !benchmarkDetail.startdate}
                                            onChange={(date) => { handleOperatorTimeSelect(index, "yearly", date) }}
                                            selected={operator.days["yearly"] && (moment(operator.days["yearly"]).isValid() || operator.time_slot["yearly"] instanceof Date) ? operator.time_slot["yearly"] : ""}
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            showTimeSelect
                                            placeholderText={_l("l_duedate")}
                                            dateFormat="dd/MM/yyyy hh:mm aa"
                                            minDate={cycleRangeDates.minYearlyCycleDate}
                                            maxDate={cycleRangeDates.maxYearlyCycleDate}
                                            calendarStartDay={1}
                                          ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                                        </div>
                                      </a>
                                      :
                                      <></>
                                  }
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                </div>
              </div>
            </div>
            {
              hideFooter
              ?
              <React.Fragment></React.Fragment>
              :
              <div className="comman-content-bottom-btn d-flex justify-content-between align-items-center mt-auto border-top p-15 px-0 ">
                <a href="#/"
                  className="btn btn-secondary"
                  onClick={() => { handleStep("assignment-schedule") }}
                >
                  {_l("l_previous")}
                </a>
                <a href="#/"
                  className="btn btn-primary"
                  onClick={handleSave}
                >
                  {isLoading ?
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      className={`ms-2 `}
                      aria-hidden="true"
                    />
                    :
                    _l("l_save")
                  }
                </a>
              </div>
            }
          </React.Fragment>
      
    </React.Fragment>
  )
}

export default ScheduleSummary