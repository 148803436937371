import {
  IS_TOAST_CLOSE,
  FETCH_SPACES,
  FETCH_CALENDAR_TASKS,
  FETCH_TASK_LIST,
  UPDATE_TASK_STATUS,
  CHANGE_PRIORITY,
  UPDATE_PIN,
  GET_TOPICS_TASK,
  GET_DEFAULT_TOPICS,
  UPDATE_TASK_PROJECT,
  SET_TASK_DATA,
  GET_TASK_DEFAULT_ASSIGNEE,
  GET_SELECTED_TASK,
  SET_SELECTED_PROJECT,
  UPDATE_TASK_ASSIGNEE,
  TASK_LIST_PAGINATION,
  CLIENT_TEAM_DATA,
  LEFT_PANEL_TASK_FILTERS,
  SET_TASK_COUNTS,
  TASK_SEARCH_STRING,
  SET_SELECTED_TASK,
  GET_TASK_CHECKLISTITEMS,
  UPDATE_CHECKLISTITEMS,
  GET_BENCHMARK_DETAIL,
  GET_BENCHMARK_SCHEDULE,
  APPLY_ALL_TASKS_FILTERS,
  APPLY_TASKS_STATUS_FILTERS,
  GET_MENTION_LIST,
  VIEW_ALL_TASKS,
  ACTIVE_TASK_STATUS_FILTERS,
  GET_CONTACT_PERMISSIONS,
  WALLET_DETAILS,
  UNREAD_COMMENT_TASKS,
  UPDATE_PROJECT_TOPICS,
  TASK_UNREAD_CMT_CNT_ALL,
  UNREAD_COMMENT_COUNT_UPDATE,
  IS_PREMUIM_ESTIMATE_DISABLE,
  IS_IBAN_MODAL_OPEN,
  UPDATE_SPACE_NOTIFICATION_COUNT,
  CREATE_SCHDULE_DETAILS,
  GET_BENCHMARK_LIST,
  SET_FILTER_BENCHMARK_CATEGORY,
  UPDATE_PROVIDER_ASSIGNEE,
  UPDATE_REQUEST_TASK_ASSIGNEE,
  UPDATE_ALL_PROJ_NOTI_COUNT,
  TASK_TYPE_FILTER,
  FETCH_MAILS,
  CALENDARS_ASSIGNEE,
  CHECK_LIST_UPDATE,
  SYN_MYR_TASK_ASSIGNEE,
  UPDATE_TASK_ASSIGNEE_NEW,
  UPDATE_BENCHMARK_PROPOSALS_COMMENTS,
  UPDATE_MAIL_CALENDAR_COUNTER,
  FETCH_PROPOSAL_COMMENTS,
  UPDATE_TASK_ASSIGNEE_LIST,
  UPDATE_MAIL_CC_ASSIGNEE,
  PRODUCT_TOUR,
  GET_TASK_CHAT_ASSIGNEE,
  VIRTUAL_SPACE_LIST,
  UPDATE_VIRTUAL_SPACE_NOTI_COUNT,
  UPDATE_DOCUMENT_STATUS,
  TASK_BULK_ACTION,
  GET_FLOOR_DOCUMENT,
  SELECTED_FLOOR_ID,
  GET_FLOOR_LIST,
  GET_MAIL_ASSIGNEE,
  LEAD_MODAL_TOGGLE,
  SET_GLOBAL_SEARCH_STRING,
  SET_GET_LANGUAGE,
  GET_COMPANY_EMPLOYEE,
  FETCH_LOCK_TASK_LIST,
  UPDATE_HIDE_LOADER,
  GET_DEFAULT_TOPIC_LIST,
  GET_USER_INFO,
  GET_SPACE_WORK_SCORE,
  GET_CALENDAR_TASK_LIST,
  SET_CATEGORY_NOTIFICATION_COUNTS,
  GET_ATTENDANCE,
  GET_ATTENDANCE_LIST,
  GET_ATTENDANCE_COUNT_WEEK_CALENDAR,
  GET_PROJECT_ATTENDANCE_COUNT,
  SET_TEAMS,
  GET_TASK_LISTS,
  GET_TASK_ATTACHMENTS,
  SET_EQUIPMENT_CATEGORY_ID,
  GET_DETAILS_FOR_LOGIN_AS_USER,
  SELECTED_SUB_FLOOR_ID,
  SET_POI_LAT_LNG,
  GET_POIS_LIST,
  GET_ACTIVE_POIS_LIST,
  SET_BENCHMARK_SCHEDULE,
  GET_AR_LOCAL_ANCHORS,
  CLOSE_OUT_SIDE_CLICK,
  MODEL_VIEWER_POINT,
  EDIT_ACCESS_SPACE,
  COMMANOFFCANVAS_IS_OPEN,
  OPEN_TASK_DETAILS_CALENDAR,
  UPDATE_SPACE_CONTENT,
  TASK_ATTACHMENTS_FOR_CREATE_TASK,
  UPDATE_UNREAD_CHAT_COUNT_TASKLIST,
  GET_PROJECT_THEME,
  SET_FAVORITE_DASHBOARD,
  _NEW_CLIENT_DATA,
  GET_OPERATOR_BY_CATEGORY,
  COUNTRY_CODE,
  GET_ALL_USER_LIST_FOR_STAFF,
  SELECTED_CHAT_USER,
  IS_BACKDROP,
  IS_ALL_CHAT_USERLIST_SHOW,
  GENERAL_UNREAD_COUNT,
  SEARCH_STRING_CHAT_ALL_USER,
  DAY_GRID_MONTH,
  DAY_GRID_WEEK,
  DAY_GRID_DAY,
  IS_TASKLIST_VIEW,
  IS_AI_CHAT,
  SET_SELECTED_CALENDAR_DATE,
  CREATE_TASK_REQ_INPUT_ICONS,
  DAY_DIFFERENCE,
  APPLIED_DATE_RANGE_FILTER,
  SELECTED_VIEW,
  DATERANGE_FILTER_APPLIED,
  CALENDAR_VIEW,
  BENCHMARK_CREATE_OR_NOT,
  SELECTED_DATERANGE_FILTER,
  PINNED_DELAY_FILTER,
  GET_CHECKED_TASK,
  IMAGEVIEWER_IS_OPEN,
  TASKLIST_LOADER,
  OPEN_CHATAI_INPUTBOX,
  SELECTED_DATE_VIEW,
  GET_EQUIPMENTS_LIST,
  SET_EQUIPMENTS_QUANTITY,
  SET_EQUIPMENTS_QUANTITY_LIST,
  SET_NEW_EQUIPMENT,
  LAST_CHAT_SESSION,
  METRICS_DETAILS,
  SELECTED_TYPE_OF_SPACE_ID,
  FILTER_FOR_3D_MODEL,
  UPDATE_TASK_LIST,
  SELECTED_DATE_CHATAI,
  DASHBOARD_DETAILS_WITH_ALL_SPACE,
  CREATE_TASK_MODAL_IS_OPEN,
  DASHBOARD_VIEW,
  DASHBOARD_FILTER,
  TASK_RATING,
  UPDATE_WEEK_CALENDAR,
  TASK_AND_MESSAGES,
  FIRST_TASK_DATE,
  LAST_TASK_DATE,
  LOADING_BENCHMARK_DETAILS,
  SET_SPACE_LIST,
  DASHBOARD_DETAILS_WITH_TOTAL,
  SHOW_THREE_DAYS_WEEK,
  CREDITNOTES_DETAILS,
  USER_LOGOUT,
  CHAT_UNREADMESSAGE_COUNT,
  CHANGE_WEEK_CAL_NAVI,
  UNREADTASK_COMMENT,
  UPDATE_SELECTED_TASK_DETAIL,
  GENERATE_AI_THREAD_ID,
  IS_TOPIC_LIST_LOADING,
  CLEAR_DEFAULT_TOPICS,
  SELECTED_DATE_INDEX_FOR_CALENDAR,
  TASK_LIST_FOR_CHAT,
  CLIENT_TAB_DATA,
  LAST_FILTER_DETAILS,
  LAST_MESSAGE_SPACE,
  DISPLAY_CALL_FULLSCREEN,
  IS_CALL_RECORDING,
  CREATE_TASK_FOR_AUDIO_CALL,
  GET_CALL_TASK_DETAILS,
  GET_COMMENTS_DURING_AUDIO_CALL,
  CALL_RECORDING_ACTION_DETAILS,
  SHOW_MUTE_MIC,
  CALL_FUNCTION_OF_CALL_DISCONNECT,
  FULL_SCREEN_LOADER_CHANGE_SPACE,
  SELECTED_PLAN_FROM_CHAT,
  SELECTED_PROPOSAL,
  GET_COMPANY_INVITETION_LIST,
  IS_JOB_PROPOSAL_PAGE,
  OPEN_CONTACT_DIRECTORY_CHAT,
  ACCORDION_EVENT_KEY,
  LOADING_BENCHMARK_WEEK_SCHEDULE,
  IBAN_ACCOUNTS_LIST,
  EDIT_SCHEDULE_JSON,
  SHOW_API_LOADING_LOADER,
  SET_LIST_OF_SELECTEDOPERATORE_LIST,
  MAIL_REPLAY_FROM_LIST,
  SET_EQUIPMENT_CONSUMBLE_CHECK_LIST,
  IS_CREATE_TASK_DEFAULT_TOPICS,
  GET_PROVIDER_LIST,
  SET_RISK_DATA_LIST,
  PROVIDER_LIST_TABLE_DATA,
  UPDATE_PROVIDER_LIST_TABLE_DATA,
  SET_PROVIDER_LIST_LOADER,
  UPDATE_DEFAULT_TOPICS_AI_DATA,
  IS_TODO_TABLE,
  UPDATE_SCHEDUALE_EMAILS,
  SELECTED_LEFT_PANEL_TAB,
  PROVIDER_AGENT_LIST,
  PROVIDER_CLIENT_LIST,
  FILTER_SPACE_PROJECT_ID,
  UPDATE_DEFAULT_TOPICS,
  EMAIL_TOKEN_EXPIRED,
  IS_TENANTS_DATA,
  PROVDER_WALLET_LIST,
  MY_DRIVE_TOPIC
} from "./action-type";
import CustomerServices from "../services/customer-services";
import SlackServices from "../services/slack-services";
import GoogleCalendar from "../services/google-calendar";
import outlookCalendar from "../services/outlook-calendar";
import { REMOVE_BENCHMARK } from "./benchmark-actions/benchmark-action-type";
import BenchmarkServices from "../services/benchmark-services";
import DocumentsServices from "../services/documents-services";
import customerServices from "../services/customer-services";
import authServices from "../services/auth-services";
import { compareArraysOfObjects, fetchTasksFromIDB, formateDate, isAllowedToAccessPremiumFeatures, showError, updateTaskListInIDB } from "../hooks/utilities";
import benchmarkServices from "../services/benchmark-services";
import store from "../store";
import equipmentsServices from "../services/equipments-services";
import moment from "moment";
import { getRecordsByIdFromIDB, insertCollectionToDB, readCollectionFromDB } from "../hooks/indexed_db_helpers";
import { CALL_TASK_LIST, CONTACT_DIR_COUNT, LAST_MESSAGE_CONTACTDIR } from "./chat-action-type";
import { data, type } from "jquery";

let topicListFetchQueueId;
let taskListFetchQueueId;

export const getCustomerSpaces = (client_id, contact_id) => (dispatch) => {
  setSpacesFromIDB(dispatch);
  return CustomerServices.getCustomerSpaces(client_id, contact_id).then(
    async(data) => {
      if(data.data)
      {
        let filter_details = "", action = "", topicFilterClear = "all"
        dispatch({
          type: FETCH_SPACES,
          payload: { data },
        });
      
        dispatch({
          type: LEFT_PANEL_TASK_FILTERS,
          payload: { filter_details, action, topicFilterClear },
        })
        //Update data to indexed DB
        let spaceArray = data.data;
        const user_type = localStorage.getItem("user_type");

        if(user_type != "staff")
        {
          spaceArray = spaceArray.filter((w) => w.init_space != 1);
        }
        insertCollectionToDB("spaces", spaceArray, "project_id");
        localStorage.setItem("spaces", JSON.stringify(spaceArray));
      }
      if(data.extra && data.extra.unread_schedule_comment !== undefined)
      {
        localStorage.setItem("unread_schedule_comment", Number(data.extra.unread_schedule_comment));
      }
      if(data.extra && data.extra.unread_contract_comment !== undefined)
      {
        localStorage.setItem("unread_contract_comment", Number(data.extra.unread_contract_comment));
      }
      if(data.extra && data.extra.unread_proposal_comment !== undefined)
      {
        localStorage.setItem("unread_proposal_comment", Number(data.extra.unread_proposal_comment));
      }

      if(data.extra)
      {
        if(data.extra.subscription_details && data.extra.subscription_details.length)
        {
          localStorage.setItem("pending_payments", JSON.stringify(data.extra.subscription_details));
        }
        else {
          localStorage.removeItem("pending_payments");
        }
      }

      if (data.extra && data.extra.general_chat_notification_count !== undefined && data.extra.general_chat_notification_count != null) { 
        dispatch({
          type: GENERAL_UNREAD_COUNT,
          payload: parseInt(data.extra.general_chat_notification_count),
        });
      }
    }
  );
};

export const setSpaceList = (spaces) => (dispatch) => {
  dispatch({
    type: FETCH_SPACES,
    payload: { data: { data: spaces } },
  });
};

export const setProject = (project_id) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_PROJECT,
    payload: { project_id },
  });
};

export const getTaskCount =
  (
    client_id,
    contact_id,
    project_id,
    startDate = "",
    filters,
    last_filter,
    statusFilters,
    action,
    removefilterkey,
    is_lead = 0, 
    pageName,
    globalview,
    FloorId = 0,
    leadsFilters,
    globalSearchString
  ) =>
    (dispatch) => {
      if(window.location.pathname == "/liteversion" || globalview == "liteVersionView" && !is_lead) 
      {   
        // customerServices.getCountLockTask(project_id, startDate, localStorage.getItem('is_dashboard') ? localStorage.getItem('is_dashboard') : 0).then((res) => {
        //   if(res.status)
        //   {
        //       let data = res.data;
        //       dispatch({
        //           type: COUNT_LOCK_TASKS,
        //           payload: {data}
        //       });
        //   }
        // })
        CustomerServices.getTaskCount(
          client_id,
          contact_id,
          project_id,
          startDate,
          localStorage.getItem("isLiteVersion") == "true" || ( globalview == "liteVersionView" && (project_id == null || project_id == undefined || project_id == 0))  ? filters &&filters.filter((a) => a.filter_key != "pinned" && a.filter_key != "overdue")  : filters,
          last_filter,
          statusFilters,
          action,
          removefilterkey, pageName,
          globalview,
          FloorId
        ).then((data) => {
          dispatch({
            type: FETCH_CALENDAR_TASKS,
            payload: { data },
          });
        });
      } else
      if (is_lead) {
        return CustomerServices.getLeadsCountWeekCalendar(
          startDate,
          filters,
          last_filter,
          statusFilters,
          action,
          removefilterkey,
          leadsFilters,
          globalSearchString
        ).then((data) => {
          dispatch({
            type: FETCH_CALENDAR_TASKS,
            payload: { data },
          });
        });
      } else {
        return CustomerServices.getTaskCount(
          client_id,
          contact_id,
          project_id,
          startDate,
          filters,
          last_filter,
          statusFilters,
          action,
          removefilterkey, pageName,
          globalview, 
          FloorId
        ).then((data) => {
          if (data && data.status == 1 && data.data && data.data.length > 0) {
            if (localStorage.getItem("fromDate") == null || localStorage.getItem("fromDate") == undefined || localStorage.getItem("toDate") == null || localStorage.getItem("toDate") == undefined ) {
              localStorage.setItem("fromDate",data.data[0].date);
              localStorage.setItem("toDate",data.data[6].date);
            }
          }
          dispatch({
            type: FETCH_CALENDAR_TASKS,
            payload: { data },
          });
        });
      }
    };

export const getTaskList =
  (
    client_id,
    contact_id,
    project_id,
    startDate = "",
    page = 1,
    status = 0,
    filters = [],
    searchString = "",
    viewAll = 0,
    isDateActive = 1,
    start_Date = new Date(),
    endDate = new Date(),
    is_mobile = 0,
    FloorId
  ) =>
    async (dispatch) => {
      if(typeof project_id === "undefined")
      {
        return;
      }
      dispatch({
        type :TASKLIST_LOADER,
        payload: true,
      })
      const globalView = store.getState().customer.globalView
      const tempdata = []
      dispatch({
        type: FETCH_TASK_LIST,
        payload: { tempdata} ,
      });
      if (client_id == "taskclear") {
        let data = [];
        dispatch({
          type: FETCH_TASK_LIST,
          payload: { data },
        });
      } else {
        //Track multiple requests
        let currentQueueId = crypto.randomUUID();
        taskListFetchQueueId = currentQueueId;

        let idbTaskList = await fetchTasksFromIDB(project_id, filters, start_Date, status, dispatch);

        return CustomerServices.getTaskList(
          client_id,
          contact_id,
          project_id,
          startDate,
          page,
          status,
          filters,
          searchString,
          viewAll,
          isDateActive,
          start_Date,
          endDate,
          is_mobile,
          globalView,
          FloorId
        ).then((data) => {
         
          updateTaskListInIDB(project_id, data && data.data, filters, start_Date, status);

          //Sometimes multiple API calls are triggered and if there is a big difference in response time, it is creating a flickker in UI, so to avoid that, only show the very last response to state.
          if (
            currentQueueId === taskListFetchQueueId &&
            !compareArraysOfObjects(data.data ? data.data : [], idbTaskList, "id", () => {
              dispatch({
                type: TASKLIST_LOADER,
                payload: true,
              });
            })
          ) {
            dispatch({
              type: FETCH_TASK_LIST,
              payload: { data },
            });
          }
          dispatch({
            type: TASKLIST_LOADER,
            payload: false,
          });
        });
      }
    };

  export const getLockScreenTaskList =
  (
    client_id,
    contact_id,
    project_id,
    startDate = "",
    page = 1,
    status = 0,
    filters = [],
    searchString,
    is_dashboard
  ) =>
    (dispatch) => {
      const globalView = store.getState().customer.globalView
      if (client_id == "taskclear") {
        let data = [];
        dispatch({
          type: FETCH_LOCK_TASK_LIST,
          payload: { data },
        });
      } else {
        return CustomerServices.getLockScreenTaskList(
          client_id,
          contact_id,
          project_id,
          startDate,
          page,
          status,
          localStorage.getItem("isLiteVersion") == "true" || ( globalView == "liteVersionView" && (project_id == null || project_id == undefined || project_id == 0))  ? filters && filters.filter((a) => a.filter_key != "pinned" && a.filter_key != "overdue")  : filters,
          searchString,
          undefined,
          undefined,
          is_dashboard
        ).then((data) => {
          dispatch({
            type: FETCH_LOCK_TASK_LIST,
            payload: { data },
          });
        });
      }
    };

export const updatedTaskList =
  (taskData) =>
    (dispatch) => {
      dispatch({
        type: FETCH_TASK_LIST,
        payload: {data: {data: taskData ,extra:[]}},
      });
    }

export const updateTaskStatus =
  (task_id, project_id, status, is_requested_task = 0, provider = false, file_id = "", file_name="") =>
    (dispatch) => {
      return CustomerServices.updateTaskStatus(
        task_id,
        project_id,
        status,
        provider,
        status == 'task_convert_to_request' ? 1 : 0,
        file_id, 
        file_name
      ).then((response) => {
        if (response.status) {
          let payload = {
            task_id: task_id,
            status,
            is_requested_task,
            original_task_id: response.data.task_id
          };
          post_message_to_slack(task_id, status, project_id);

          dispatch({
            type: UPDATE_TASK_STATUS,
            payload: { payload },
          });

          if (provider === "google_drive" || provider === "one_drive") {
            dispatch({
              type: UPDATE_DOCUMENT_STATUS,
              payload: { payload },
            });
          }
          return response.status
        }
      });
    };

export const changePriority =
  (task_id, project_id, taskPriority, provider = false) => (dispatch) => {
    return CustomerServices.changePriority(task_id, project_id, provider).then((data) => {
      if (data.status) {
        let payload = { task_id, taskPriority };
        if (window.location.pathname === "/providerlist") {
          dispatch({
            type  : UPDATE_PROVIDER_LIST_TABLE_DATA,
            payload : {providerData : {taskId : task_id, priority : taskPriority} , tabName : "task", keys :'priority'}
          });
        }else {
          dispatch({ 
            type: CHANGE_PRIORITY,
            payload: { payload },
          });
        }
      }
    });
  };

export const updateTaskPin =
  (task_id, project_id, taskPinShow, provider = false, file_id = "", file_name = "") => (dispatch) => {
    CustomerServices.pinTask(task_id, project_id, provider).then((data) => {
      let payload = { task_id, taskPinShow };
      if (data.status) { 
        if (window.location.pathname === "/providerlist") {
          dispatch({
            type  : UPDATE_PROVIDER_LIST_TABLE_DATA,
            payload : {providerData : {taskId : task_id, taskPin : taskPinShow} , tabName : "task", keys :'pins'}
          });
        }else{
          dispatch({
            type: UPDATE_PIN,
            payload: { payload },
          });
        }
      }
    });
  };

export const getTopicTasks =
  (project_id, pageName = "", virtual_space = 0,startDate = "", filters = [],) =>
    (dispatch) => {
      return CustomerServices.getTopicTasks(
        project_id,
        pageName,
        virtual_space,
        startDate,
        filters
      ).then((data) => {
        if (data.status) {
          let topicData = data.data;
          if(project_id && !isAllowedToAccessPremiumFeatures(project_id) && topicData.topics)
          {
            topicData.topics = topicData.topics.filter((x) => ["my_task", "provider_task"].includes(x.main_key));
          }
          dispatch({
            type: GET_TOPICS_TASK,
            payload: topicData,
            project_id,
          });
        }
      });
    };

export const setCalendarsAssignee =
  (taskId, calendar_id, assignee_id) => (dispatch) => {
    return GoogleCalendar.assignee_task_of_google(
      taskId,
      calendar_id,
      assignee_id
    ).then((res) => {
      if (res.status == 1 && res.data) {
        dispatch({
          type: CALENDARS_ASSIGNEE,
          payload: res.data,
        });
      }
    });
  };

export const getDefaultTopics =
  (project_id, type = "", id = "", pageName = "" , taskFilters = []) =>
    (dispatch) => {
      if (type != "" && id != "") {
        dispatch({
          type: GET_DEFAULT_TOPICS,
          payload: { type, id },
        });
      } else {

        const topicProjectId = Number(project_id)|| Number(localStorage.getItem('selectedOffice'));

        setTopicsFromIDB(dispatch, topicProjectId);
        dispatch({
          type: IS_TOPIC_LIST_LOADING,
          payload: true
        });
        let currentBurgermenuQueueId = crypto.randomUUID();
        topicListFetchQueueId = currentBurgermenuQueueId;

        return CustomerServices.getDefaultTopic(topicProjectId, pageName , taskFilters).then(
          async(data) => {
            if (data.status) {
              let {topics} = data.data;
              let lastChatSession = []
              let isRegister = localStorage.getItem("space_setup_pending")  ? localStorage.getItem("space_setup_pending")  : 0
              let spaces = localStorage.getItem("spaces") ? JSON.parse(localStorage.getItem("spaces")): []

              if(isRegister != 1 || (localStorage.getItem("user_type") == "operator" && spaces.length > 0)){
                if(data.extra.main_key != ""){
                lastChatSession = [{
                main_key : data.extra.main_key,
                filter_key : data.extra.filter_key,
                main_sub_key : data.extra.main_sub_key,
                image : "",
                sub_category : undefined,
                title : ""
              }]
            }else{
              lastChatSession = [{
                main_key: "my_task",
                filter_key: "assigned_to_me",
                title: "l_to_me",
                image: "",
                main_sub_key: "my_task",
                sub_category: undefined,
              }]
            }}
              if(!isAllowedToAccessPremiumFeatures(project_id) && (project_id != null && project_id.length > 0)) 
              {
                
              }

              //Update data to indexed DB
              insertCollectionToDB("topics", [{project_id: topicProjectId, topics}], "project_id");
              insertCollectionToDB("last_chat_sessions", [{project_id: topicProjectId, ...data.extra}], "project_id");
              if(currentBurgermenuQueueId == topicListFetchQueueId)
              {
                dispatch({
                  type: GET_DEFAULT_TOPICS,
                  payload: topics,
                });
              }
              dispatch({
                type: LAST_CHAT_SESSION,
                payload: lastChatSession,
              });
            }
          }
        ).finally(() => {
          dispatch({
            type: IS_TOPIC_LIST_LOADING,
            payload: false
          });
        });
      }
    };

export const changeTaskProject = (project_id, task_id) => (dispatch) => {
  return CustomerServices.changeTaskProject(project_id, task_id).then(
    (data) => {
      if (data.status) {
        dispatch({
          type: UPDATE_TASK_PROJECT,
          payload: { task_id, project_id },
        });
      }
    }
  );
};
export const dayClickModal =
  (project_id, task_id, task_name, startDate, is_requested, dueDate) =>
    (dispatch) => {
      dispatch({
        type: SET_TASK_DATA,
        payload: { task_id, task_name, startDate, dueDate, is_requested },
      });
    };
export const setTaskData =
  (
    project_id,
    task_id,
    task_name,
    startDate,
    dueDate,
    total,
    is_requested,
    requestTaskId = 0,
    convertTaskId = 0,
    flag = "",
    description,
    visible_to_client,
    visible_to_provider,
    isAttachmentTask = false,
    attchmentId = 0,
    attachmentTaskId = 0
  ) =>
    (dispatch) => {
      if (requestTaskId > 0 && convertTaskId > 0) {
        let task_id = requestTaskId;
        dispatch({
          type: SET_TASK_DATA,
          payload: { task_id, convertTaskId },
        });
      } else if (flag == "pinnedFlag") {
        dispatch({
          type: SET_TASK_DATA,
          payload: { task_id, flag },
        });
      } else {
        return CustomerServices.setTaskData(
          project_id,
          task_id,
          task_name,
          startDate,
          dueDate,
          total,
          description,
          visible_to_client,
          visible_to_provider,
          isAttachmentTask,
          attchmentId,
          attachmentTaskId
        ).then((data) => {
          if (data.status) {
            localStorage.setItem("task_updated", JSON.stringify(true));
            window.dispatchEvent(new Event("task_updated"));
            let is_requested = is_requested == 1 ? "requestTask" : "";
            if (window.location.pathname === "/providerlist") {
              dispatch({
                type  : UPDATE_PROVIDER_LIST_TABLE_DATA,
                payload : {providerData : {taskId : task_id, dueDate : dueDate} , tabName : "task", keys :'date'}
              });
            }else {
              dispatch({ 
                type: SET_TASK_DATA,
                payload: { task_id, task_name, startDate, dueDate, is_requested , visible_to_client, visible_to_provider},
              });
            }
          }
        });
      }
    };
export const getDefaultTaskAssignee =
  (project_id, task_id = 0) =>
    (dispatch) => {
      return CustomerServices.getDefaultTaskAssignee(project_id, task_id).then(
        (data) => {
          if (data.status) {
            if (data.data) {
              if (data.data.slack_channel.slack) {
                dispatch({
                  type: GET_TASK_CHAT_ASSIGNEE,
                  payload: { data: data.data },
                });
              }
            }
            dispatch({
              type: GET_TASK_DEFAULT_ASSIGNEE,
              payload: { data: data.data },
            });
          }
        }
      );
    };

export const filterDashboardTasks =
  (client_id, contact_id, project_id, filters) => (dispatch) => {
    return CustomerServices.filterDashboardTasks(
      client_id,
      contact_id,
      project_id,
      filters
    ).then((data) => {
      dispatch({
        type: FETCH_TASK_LIST,
        payload: { data },
      });
    });
  };
export const getSelectedTaskCalendar =
  (calendar_id, event_id, calendar_type, project_id) => (dispatch) => {
    if (calendar_type === true) {
      return outlookCalendar
        .get_single_event(calendar_id, event_id, project_id)
        .then((data) => {
          dispatch({
            type: GET_SELECTED_TASK,
            payload: { data },
          });
        });
    } else if (calendar_type === 1) {
      return GoogleCalendar.get_single_event(calendar_id, event_id).then(
        (data) => {
          dispatch({
            type: GET_SELECTED_TASK,
            payload: { data },
          });
        }
      );
    }
  };

export const getSelectedTask =
  (
    project_id,
    task_id,
    is_requested = null,
    fromCalendar = null,
    is_clear_data = 0,
    provider = "",
    data ={}
  ) =>
    (dispatch) => {
      if (is_clear_data == 1) {
        if (data && Object.keys(data).length > 0) {
          dispatch({
            type: GET_SELECTED_TASK,
            payload: { data, selected : 1 },
          });
        }else{
        const data = {};
        dispatch({
          type: GET_SELECTED_TASK,
          payload: { data },
        });
        }
      } else {
        if (is_requested) {
          if (project_id && task_id) {
            return CustomerServices.getRequestedTaskData(
              project_id,
              task_id,
              fromCalendar
            ).then((data) => {
              if (data.status == 1 && data.data) { 
              data = { ...data, is_requested };
              data.data.id = task_id
              dispatch({
                type: GET_SELECTED_TASK,
                payload: { data },
              });
            }
            });
          } else {
            const data = { data: [] };
            dispatch({
              type: GET_SELECTED_TASK,
              payload: { data },
            });
          }
        } else {
          return CustomerServices.getTaskData(
            project_id,
            task_id,
            fromCalendar,
            0,
            provider
          ).then((data) => {
            if (data.status == 1 && data.data) { 
            data.data.id = task_id
            dispatch({
              type: GET_SELECTED_TASK,
              payload: { data },
            });
            }
          });
        }
      }
    };

export const setTaskAssignee =
  (assignee, type, fromTaskcard = null, is_tag = "") =>
    (dispatch) => {
      let fromtask = fromTaskcard > 0 ? fromTaskcard : null;
      dispatch({
        type: UPDATE_TASK_ASSIGNEE,
        payload: { assignee, type, fromtask, is_tag },
      });
    };

export const taskListPagination =
  (
    client_id,
    contact_id,
    project_id,
    startDate = "",
    page = 1,
    status = 0,
    filters = [],
    viewAll = 0,
    isDateActive = 1
  ) =>
    (dispatch) => {
      return CustomerServices.getTaskList(
        client_id,
        contact_id,
        project_id,
        startDate,
        page,
        status,
        filters,
        "",
        viewAll,
        isDateActive
      ).then((data) => {
        dispatch({
          type: TASK_LIST_PAGINATION,
          payload: { data },
        });
      });
    };

export const getClientTeamData = (project_id) => (dispatch) => {
  return CustomerServices.getClientTeamData(project_id).then((data) => {
    dispatch({
      type: CLIENT_TEAM_DATA,
      payload: { data, project_id },
    });
  });
};

export const leftPanelFilters =
  (filter_details, action = "remove", topicFilterClear = "") =>
  (dispatch) => {
    // console.trace("LeftPanelFilterscalled");
      dispatch({
        type: LEFT_PANEL_TASK_FILTERS,
        payload: { filter_details, action, topicFilterClear },
      });
      if (filter_details != "") {
      dispatch({
        type: LAST_FILTER_DETAILS,
        payload:  filter_details
      });
    }
    };

export const projectTaskCounts =
  (project_id, filters, last_filter, is_lead = 0, pageName, globalView, leadsFilters, globalSearchString) =>
    (dispatch) => {
      const globalView = store.getState().customer.globalView
      if (project_id == "countClear") {
        let data = [];
        dispatch({
          type: SET_TASK_COUNTS,
          payload: { data },
        });
      } else if (is_lead) {
        return CustomerServices.getLeadsCount(
          project_id,
          filters,
          last_filter,
          '',
          leadsFilters,
          globalSearchString
        ).then((data) => {
          dispatch({
            type: SET_TASK_COUNTS,
            payload: { data, is_lead: 1 },
          });
        });
      } else {
        return CustomerServices.getFilteredProjectCount(
          project_id,
          localStorage.getItem("isLiteVersion") == "true" || ( globalView == "liteVersionView" && (project_id == null || project_id == undefined || project_id == 0))  ? filters  : filters,
          last_filter, [], pageName
        ).then((data) => {
          let firstDate = data && data.extra && data.extra.topic_task_start_date != "" ? data.extra.topic_task_start_date : moment(new Date()).format("YYYY-MM-DD")
          let lastDate = data && data.extra &&  data.extra.topic_task_end_date != "" ? data.extra.topic_task_end_date : moment(new Date()).format("YYYY-MM-DD")
          dispatch({
            type: SET_TASK_COUNTS,
            payload: { data },
          });
          dispatch({
            type: FIRST_TASK_DATE,
            payload: firstDate ,
          });
          setLastMessageOrTaskDate(formateDate(new Date()))
          // dispatch({
          //   type: LAST_TASK_DATE,
          //   payload: formateDate(moment(new Date()))
            // payload: formateDate(moment.max([moment(lastDate),moment(new Date())]))
          // });
        });
      }
    };

export const setTaskSearchString = (string) => (dispatch) => {
  dispatch({
    type: TASK_SEARCH_STRING,
    payload: { string },
  });
};

export const SetSelectedTask = (data) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_TASK,
    payload: { data },
  });
};

export const getTaskChecklist =
  (task_id, project_id, isExternal) => (dispatch) => {
    return CustomerServices.getCheckList(task_id, project_id, isExternal).then(
      (data) => {
        dispatch({
          type: GET_TASK_CHECKLISTITEMS,
          payload: { data: data.data },
        });
      }
    );
  };

export const updateedTaskChecklist = (data) => (dispatch) => {
    dispatch({
      type: GET_TASK_CHECKLISTITEMS,
      payload: { data: data },
    });
}

export const addNoteToChecklist =
  (project_id, task_id, checklist_id, finished, description) => (dispatch) => {
    return CustomerServices.updateChecklistItem(
      project_id,
      task_id,
      checklist_id,
      finished,
      description
    ).then((data) => {
      dispatch({
        type: UPDATE_CHECKLISTITEMS,
        payload: { data: data.data },
      });
    });
  };

export const updateChecklist =
  (project_id, task_id, checklist_id, finished, description) => (dispatch) => {
    return CustomerServices.updateChecklistItem(
      project_id,
      task_id,
      checklist_id,
      finished,
      description
    ).then((data) => {
      dispatch({
        type: UPDATE_CHECKLISTITEMS,
        payload: { data: data.data },
      });
    });
  };

export const getBenchmarkDetail =
  (
    benchmark_id,
    clientId,
    benchmarkDetailClear = "",
    keyName,
    updatedValue = 0
  ) =>
    (dispatch) => {
      if (benchmarkDetailClear == "all") {
        dispatch({
          type: GET_BENCHMARK_DETAIL,
          payload: { benchmarkDetailClear: benchmarkDetailClear },
        });
      } else if (benchmarkDetailClear == "edit") {
        dispatch({
          type: GET_BENCHMARK_DETAIL,
          payload: {
            benchmarkDetailClear: benchmarkDetailClear,
            keyName: keyName,
            value: updatedValue,
          },
        });
      } else {
        dispatch({
          type: LOADING_BENCHMARK_DETAILS,
          payload: { flag: true },
        });
        return CustomerServices.getBenchmarkDetail(benchmark_id, clientId).then(
          (data) => {
            if(!data.status)
            {
              showError("l_benchmark_not_found");
            }
            dispatch({
              type: GET_BENCHMARK_DETAIL,
              payload: { data: data.data },
            });
          }
        ).catch().finally(() => {
          dispatch({
            type: LOADING_BENCHMARK_DETAILS,
            payload: { flag: false },
          });
        });
      }
    };

export const editBenchmarkDetail = (benchmark) => (dispatch) => {
  dispatch({
    type: GET_BENCHMARK_DETAIL,
    payload: { data: benchmark },
  });
};

export const getBenchmarkWeekSchedule =
  (benchmark_id, weekScheduleClear = "", copy) =>
    (dispatch) => {
      if (weekScheduleClear == "all") {
        dispatch({
          type: GET_BENCHMARK_SCHEDULE,
          payload: { weekScheduleClear: weekScheduleClear },
        });
      } 
      else {
        dispatch({
          type: LOADING_BENCHMARK_WEEK_SCHEDULE,
          payload: { flag: true },
        });
        return CustomerServices.getBenchmarkWeekSchedule(benchmark_id).then(
          (data) => {
            dispatch({
              type: GET_BENCHMARK_SCHEDULE,
              payload: { data: data.data, copy },
            });
          }
        ).finally(() => {
          dispatch({
            type: LOADING_BENCHMARK_WEEK_SCHEDULE,
            payload: { flag: false },
          });
        });
      }
    };

export const applyAllTaskFilters = (filters) => (dispatch) => {
  dispatch({
    type: APPLY_ALL_TASKS_FILTERS,
    payload: { filters },
  });
};

export const applyTaskStatusFilters =
  (statusFilters, flag = "") =>
    (dispatch) => {
      dispatch({
        type: APPLY_TASKS_STATUS_FILTERS,
        payload: { statusFilters, flag },
      });
    };

export const getMentionList = (rel_id, rel_type, isExternal, project_id, is_added_from_contact, addedfrom, user_type) => (dispatch) => {
  return CustomerServices.getMentionlist(rel_id, rel_type, isExternal, project_id, is_added_from_contact, addedfrom, user_type).then(
    (data) => {
      if (data.data) { 
      dispatch({
        type: GET_MENTION_LIST,
        payload: { data: data.data },
      });
      }
    }
  );
};
export const viewAllTasks =
  (
    client_id,
    contact_id,
    project_id,
    startDate = "",
    page = 1,
    status = 0,
    filters = [],
    viewAll = 0,
    isDateActive = 1,
    searchString
  ) =>
    (dispatch) => {
      return CustomerServices.getTaskList(
        client_id,
        contact_id,
        project_id,
        startDate,
        page,
        status,
        filters,
        searchString,
        viewAll,
        isDateActive
      ).then((data) => {
        dispatch({
          type: VIEW_ALL_TASKS,
          payload: { data },
        });
      });
    };

export const setActiveTaskStatusFilters =
  (statusList, isClear = 0) =>
    (dispatch) => {
      dispatch({
        type: ACTIVE_TASK_STATUS_FILTERS,
        payload: { statusList, isClear },
      });
    };

export const getContactPermissions = () => (dispatch) => {
  return CustomerServices.getContactPermissions().then((data) => {
    dispatch({
      type: GET_CONTACT_PERMISSIONS,
      payload: { data: data.data },
    });
  });
};

export const getWalletSettings = () => (dispatch) => {
  return CustomerServices.getWalletSettings().then((data) => {
    dispatch({
      type: WALLET_DETAILS,
      payload: { data: data.data },
    });
  });
};

export const unReadCommentTasks = (flag) => (dispatch) => {
  dispatch({
    type: UNREAD_COMMENT_TASKS,
    payload: { flag },
  });
};

export const IsToastOpenOrClose = (flag) => (dispatch) => {
  dispatch({
    type: IS_TOAST_CLOSE,
    payload: { flag },
  });
};
export const setWalletSettings = () => (dispatch) => {
  return CustomerServices.setWalletSettings().then((data) => {
    dispatch({
      type: WALLET_DETAILS,
      payload: { data: data.data },
    });
  });
};

export const loginToMyrAdmin = (username, password) => (dispatch) => {
  return CustomerServices.loginToMyrAdmin(username, password).then((data) => {
  });
};

export const updateProjectTopics = (newTopic, main_key, is_edit_id,action = "") => (dispatch) => {
  dispatch({
    type: UPDATE_PROJECT_TOPICS,
    payload: { newTopic, main_key, is_edit_id ,action},
  });
};

export const setTaskUnreadCmtCntAll = (taskUnreadCmtCntAll) => (dispatch) => {
  dispatch({
    type: TASK_UNREAD_CMT_CNT_ALL,
    payload: { taskUnreadCmtCntAll },
  });
};

export const updateUnreadChatCountTasklist = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_UNREAD_CHAT_COUNT_TASKLIST,
    payload: data,
  });
};

export const updateUnreadCommentCount = (task_id, count) => (dispatch) => {
  dispatch({
    type: UNREAD_COMMENT_COUNT_UPDATE,
    payload: { task_id, count },
  });
};
export const isPremuimEstimateDisable = () => (dispatch) => {
  dispatch({
    type: IS_PREMUIM_ESTIMATE_DISABLE,
  });
};

export const isIBANModalOpen = () => (dispatch) => {
  dispatch({
    type: IS_IBAN_MODAL_OPEN,
  });
};

/**
 *
 * @param {*} project_id
 * @param {*} count -> to be added or subtracted
 * @param {*} directon -> 1 for add to existing count, -1 for subtracting from existing count
 * @returns
 */
export const setSpaceNotificationCount =
  (project_id, count, direction, notiType = "") => (dispatch) => {
    dispatch({
      type: UPDATE_SPACE_NOTIFICATION_COUNT,
      payload: { project_id, count, direction, notiType },
    });
  };
export const setCreateScheduledetails = (schedule) => (dispatch) => {
  dispatch({
    type: CREATE_SCHDULE_DETAILS,
    payload: { schedule },
  });
};

export const getBenchmarkList =
  (projectId, categoryId = 0, createSchduleDetails = [], is_proposal = 0,taskFilters) =>
    (dispatch) => {
      if (createSchduleDetails.length) {
        dispatch({
          type: GET_BENCHMARK_LIST,
          payload: { createSchduleDetails: createSchduleDetails },
        });
      } else {
        return CustomerServices.getBenchmarks(projectId, categoryId, "", 0, 0, 0, 1, 10, is_proposal,taskFilters).then(
          (data) => {
            dispatch({
              type: GET_BENCHMARK_LIST,
              payload: {
                data: data.data,
                createSchduleDetails: createSchduleDetails,
                proposalKpiCount: data.extra
              },
            });
          }
        );
      }
    };

export const setFilterBenchmarkCategory = (categoryId) => (dispatch) => {
  dispatch({
    type: SET_FILTER_BENCHMARK_CATEGORY,
    payload: { categoryId },
  });
};

export const setGloablSearchString = (search_string) => (dispatch) => {
  dispatch({
    type: SET_GLOBAL_SEARCH_STRING,
    payload: { search_string },
  });
};

export const userLogout = () => (dispatch) => {
  dispatch({
    type: USER_LOGOUT
  });
  return CustomerServices.userLogout().then((data) => { });
};

export const updateProviderAssignee =
  (schedule_id, staff, template) => (dispatch) => {
    dispatch({
      type: UPDATE_PROVIDER_ASSIGNEE,
      payload: { schedule_id, staff, template },
    });
  };

export const requestTaskAssignees =
  (assignees = []) =>
    (dispatch) => {
      dispatch({
        type: UPDATE_REQUEST_TASK_ASSIGNEE,
        payload: { assignees },
      });
    };

export const updateAllProjNotiCount =
  (projects = []) =>
    (dispatch) => {
      dispatch({
        type: UPDATE_ALL_PROJ_NOTI_COUNT,
        payload: { projects },
      });
    };

export const setTaskTypeFilter =
  (type = "all") =>
    (dispatch) => {
      dispatch({
        type: TASK_TYPE_FILTER,
        payload: { type },
      });
    };
export const fetchMails =
  (taskFilters, all = 0, token = "") =>
    (dispatch) => {
      return CustomerServices.getEmails(taskFilters, all, token).then(
        (data) => {
          data.pretoken = token;
          dispatch({
            type: FETCH_MAILS,
            payload: { data },
          });
        }
      );
    };
export const checkListUpdate =
  (checklist = []) =>
    (dispatch) => {
      dispatch({
        type: CHECK_LIST_UPDATE,
        payload: { checklist },
      });
    };
export const get_assignee_of_sync_task =
  (calendar_id, event_id, task_id = "", project_id = "") =>
    (dispatch) => {
      if (calendar_id && event_id) {
        return GoogleCalendar.syncronize_task_event(
          calendar_id,
          event_id,
          task_id,
          project_id
        ).then((res) => {
          dispatch({
            type: SYN_MYR_TASK_ASSIGNEE,
            payload: res.data,
          });
        });
      }
    };

const post_message_to_slack = (task_id = "", status = "", project_id = "") => {
  SlackServices.post_message_to_slack(task_id, status, project_id).then(
    (data) => { }
  );
};

export const deleteBenchmark = (benchmark_id) => (dispatch) => {
  return BenchmarkServices.deleteBenchmark(benchmark_id).then(() => {
    dispatch({
      type: REMOVE_BENCHMARK,
      payload: { benchmark_id },
    });
  });
};

export const setTaskAssigneeNew =
  (
    assigneeDetails,
    type,
    filter_key,
    taskId = 0,
    sub_category,
    is_requested = 0,
    myr_total = 0,
    schedule_assignees,
    isOpen = false,
    isRequest = false
  ) =>
    (dispatch) => {
      dispatch({
        type: UPDATE_TASK_ASSIGNEE_NEW,
        payload: {
          assigneeDetails,
          type,
          taskId,
          filter_key,
          is_requested,
          myr_total,
          sub_category,
          isOpen,
          schedule_assignees,
          isRequest
        },
      });
    };

export const updateBenchmarkProposalComments =
  (proposal_id, comment, operation) => (dispatch) => {
    /**Expected values in operation parameter: 0 if editing the existing comment. 1 if adding new comment, -1 for removing the comment */
    dispatch({
      type: UPDATE_BENCHMARK_PROPOSALS_COMMENTS,
      payload: { proposal_id, comment, operation },
    });
  };

export const updateMailCalendarCounter =
  (inbox_messages, important_messages, filter) => (dispatch) => {
    if (["inbox", "sent", "draft", "shared", "scheduled"].includes(filter)) {
      important_messages = [];
    }
    if (filter == "important") {
      inbox_messages = [];
    }
    dispatch({
      type: UPDATE_MAIL_CALENDAR_COUNTER,
      payload: { inbox_messages, important_messages, filter },
    });
  };

export const fetchProposalComments = (proposalId, reset) => (dispatch) => {
  if (reset) {
    dispatch({
      type: FETCH_PROPOSAL_COMMENTS,
      payload: { comments: [], id: proposalId },
    });
  } else {
    return DocumentsServices.getComments(proposalId, 'proposal').then((data) => {
      dispatch({
        type: FETCH_PROPOSAL_COMMENTS,
        payload: { comments: data.data, id: proposalId },
      });
    });
  }
};

export const updateTaskAssigneesList = (assignee, taskId) => (dispatch) => {
  dispatch({
    type: UPDATE_TASK_ASSIGNEE_LIST,
    payload: { assignee, taskId },
  });
};
export const setMailCcAssignee = (data, type) => (dispatch) => {
  dispatch({
    type: UPDATE_MAIL_CC_ASSIGNEE,
    payload: { data: data, type: type },
  });
};
export const ProductTour = (data) => (dispatch) => {
  dispatch({
    type: PRODUCT_TOUR,
    payload: { data: data },
  });
};
export const setVirtualSpaceList = (data) => (dispatch) => {
  dispatch({
    type: VIRTUAL_SPACE_LIST,
    payload: { data: data },
  });
};
export const setVirtualSpaceNotificationCount = (project_id, count, direction, notiType = "") => (dispatch) => {
  dispatch({
    type: UPDATE_VIRTUAL_SPACE_NOTI_COUNT,
    payload: { project_id, count, direction, notiType },
  });
};

export const clearDocumentData = () => (dispatch) => {
  return dispatch({
    type: GET_FLOOR_DOCUMENT,
    payload: [],
  });
}

export const taskBulkAction = (taskIds, type, status) => (dispatch) => {
  dispatch({
    type: TASK_BULK_ACTION,
    payload: { taskIds, type, status },
  });
};
export const getFloorDocumentByFloorId = (id) => (dispatch) => {
  return CustomerServices.getSpaceDocument(id).then(
    (data) => {
      // if (data.status) {
        dispatch({
          type: GET_FLOOR_DOCUMENT,
          payload: data.data,
        });
      // }
    }
  );
};
export const getMailAssigees = (type,id) => (dispatch) => {
  return CustomerServices.getAssingees(type,id).then(
    (data) => {
      if (data.status) {
        dispatch({
          type: GET_MAIL_ASSIGNEE,
          payload: data.data,
        });
      }
    }
  );
};

export const getSpaceFloorList = (isExternal, hashId) => (dispatch) => {
  return CustomerServices.getSpaceFloor(isExternal, hashId).then(
    (data) => {
      if (data.status == 1) {
        dispatch({
          type: GET_FLOOR_LIST,
          payload: data.data,
        });
      }
    }
  );
}

export const selectedFloorId = (floorId) => (dispatch) => {
  return dispatch({
    type: SELECTED_FLOOR_ID,
    payload: floorId,
  });
}

export const selectedSubFloorId = (floorId) => (dispatch) => {
  return dispatch({
    type: SELECTED_SUB_FLOOR_ID,
    payload: floorId,
  });
}

export const setPOILatLong = (data) => (dispatch) => {
  return dispatch({
    type: SET_POI_LAT_LNG,
    payload: data,
  });
}
export const getPOIsListUpdate = (data, avctive = 0) => (dispatch) => {
  if (avctive == 1) {
    return dispatch({
      type: GET_ACTIVE_POIS_LIST,
      payload: data,
    });
  } else {
    return dispatch({
      type: GET_POIS_LIST,
      payload: data,
    });
  }
}

export const getPOPIsList = (SubFloorId,not_set_for_web) => (dispatch) => {
  return customerServices.getArPOIs(SubFloorId,not_set_for_web).then((data) => {
      if (data.status == 1) {
        if (not_set_for_web == 0) { 
          dispatch({
            type: GET_ACTIVE_POIS_LIST,
            payload: data.data,
          });
        } else {
          dispatch({
            type: GET_POIS_LIST,
            payload: data.data,
          });
        }
      }
    }
  );
}

export const getArLocalAnchors = (FloorId) => (dispatch) => {
  return customerServices.getArLocalAnchors(FloorId).then((data) => {
    if (data.status == 1) {
      dispatch({
        type: GET_AR_LOCAL_ANCHORS,
        payload: data.data,
      });
    }
  }
  );
}

export const updateLocalAnchor = (data) => (dispatch) => {
  dispatch({
    type: GET_AR_LOCAL_ANCHORS,
    payload: data,
  });
}

export const updatedFloorList = (floor) => (dispatch) => {
  return dispatch({
    type: GET_FLOOR_LIST,
    payload: floor,
  });
}

export const leadModalToggle = (lead) => (dispatch) => {
  return dispatch({
    type: LEAD_MODAL_TOGGLE,
    payload: lead,
  });
}

export const setSelectedLanguage = (lan) => (dispatch) => {
  return dispatch({
    type: SET_GET_LANGUAGE,
    payload: lan,
  });
}

export const getCompanyEmployee = (is_pilot_onboarding = 0) => (dispatch) => {
  return CustomerServices.getCompanyEmployee(is_pilot_onboarding).then((data) => {
      if (data.status == 1) {
        dispatch({
          type: GET_COMPANY_EMPLOYEE,
          payload: data.data,
        });
      }
    }
  );
}

export const updateHideLoader = (hideLoader) => (dispatch) => {
  return dispatch({
    type: UPDATE_HIDE_LOADER,
    payload: hideLoader,
  });
}
export const updatedCompanyEmployeeList = (data) => (dispatch) => {
  dispatch({
    type: GET_COMPANY_EMPLOYEE,
    payload: data,
  });
}

export const getDefaultTopicList = () => (dispatch) => {
  return CustomerServices.getDefaultTopicList().then((data) => {
    if (data && data.status == 1) {
      dispatch({
        type: GET_DEFAULT_TOPIC_LIST,
        payload: data.data,
      });
    }
  }
);
 
}

export const getUserDetail = (type, id, setShowLoader) => (dispatch) => {
  return authServices.getUserInfo(type, id).then(
    (res) => {
      setShowLoader(true);
      if (res.status == 1) {
        dispatch({
          type: GET_USER_INFO,
          payload: res.data,
        });
      }
    }
  );
};

export const getAttendance = (attendance_date) => (dispatch) => {
  return CustomerServices.getAttendance(attendance_date).then((data) => {
    if (data.status == 1) {
      dispatch({
        type: GET_ATTENDANCE,
        payload: data.data,
      });
    }
  }
  );
}

export const getAttendanceList = (fromDate,toDate) => (dispatch) => {
  return CustomerServices.getAttendanceList(fromDate,toDate).then((data) => {
    if (data.status == 1) {
      dispatch({
        type: GET_ATTENDANCE_LIST,
        payload: data.data,
      });
    }
  }
  );
}

export const updateedAttendance = (data) => (dispatch) => {
  dispatch({
    type: GET_ATTENDANCE,
    payload: data,
  });
}

export const getCalendarTaskList = (data) => (dispatch) => {
  dispatch({
    type: GET_CALENDAR_TASK_LIST,
    payload: data,
  });
};
export const getSpaceWorkScore = () => (dispatch) => {
  return CustomerServices.getSpaceWorkScore().then((data) => {
    if (data.status == 1) {
      dispatch({
        type: GET_SPACE_WORK_SCORE,
        payload: data.data,
      });
    }
  }
);
 
}

export const setCategoryNotificationCounts = (main_key, filter_key, notification_param, count, direction) => (dispatch) => {
  dispatch({
    type: SET_CATEGORY_NOTIFICATION_COUNTS,
    payload: {main_key, filter_key, count, direction, notification_param}
  })
}

export const getAttendanceCountWeekCalendar = (project_id ,formattedDate) => (dispatch) => {
  return CustomerServices.getAttendanceCountWeekCalendar(
    project_id,
    formattedDate
  ).then((data) => {
    if (data.status) {
      dispatch({
        type: GET_ATTENDANCE_COUNT_WEEK_CALENDAR,
        payload: data
      });
    }
  });
};

export const getProjectAttendanceCount = (project_id ) => (dispatch) => {
  return CustomerServices.projectAttendanceCount(project_id).then((data) => {
    if (data.status == 1) {
      dispatch({
        type: GET_PROJECT_ATTENDANCE_COUNT,
        payload: data
      });
    }
  }
  );
};

export const getTeams = () => (dispatch) => {
  return CustomerServices.getTeams().then((data) => {
    if (data.status == 1) {
      dispatch({
        type: SET_TEAMS,
        payload: data.data
      });
    }
  });
};

export const updateTeams = (data) => (dispatch) => {
      dispatch({
        type: SET_TEAMS,
        payload: data.data
      });
};

export const setTaskMapData = (taskList, selectedTask) => (dispatch) => {
  dispatch({
    type: GET_TASK_LISTS,
    payload: {taskList: taskList, selectedTask: selectedTask}
  })
}

export const setEquipmentCategoryId = (id) => (dispatch) => {
  dispatch({
    type: SET_EQUIPMENT_CATEGORY_ID,
    payload: id
  })
}

export const getTaskAttechments = (task_id, projectId, checklist_id, hash_id) => (dispatch) => {
  return CustomerServices.getAttachments(task_id, projectId, checklist_id, hash_id).then((data) => {
    if (data.status == 1) {
      dispatch({
        type: GET_TASK_ATTACHMENTS,
        payload: data.data
      });
    }
  }
  );
};
export const updateTaskAttechments = (data) => (dispatch) => {
  return dispatch({
    type: GET_TASK_ATTACHMENTS,
    payload: data
  });
}
export const getDetailForLoginAsUser = (client_id, project_id) => (dispatch) => {
  return CustomerServices.getDetailForLoginAsUser(client_id, project_id).then((data) => {
      if (data && data.status == 1) {
        dispatch({
          type: GET_DETAILS_FOR_LOGIN_AS_USER,
          payload: data.data,
        });
      }
    }
  );
}

export const setBenchmarkSchedule = (benchmarkSchedule) => (dispatch) => {
  dispatch({
    type: SET_BENCHMARK_SCHEDULE,
    payload: benchmarkSchedule,
  });
}

export const closeOutsideClick = (data) => (dispatch) => {
  dispatch({
    type: CLOSE_OUT_SIDE_CLICK,
    payload: data,
  });
}

export const ModelViewerPoint = (data) => (dispatch) => {
  dispatch({
    type: MODEL_VIEWER_POINT,
    payload: data,
  });
}

export const editSpaceAccess = (data) => (dispatch) => {
  dispatch({
    type: EDIT_ACCESS_SPACE,
    payload: data,
  });
}

export const commanoffcanvasisOpen=(commanoffcanvasIsHide) => (dispatch) =>
{
  return(dispatch({
    type: COMMANOFFCANVAS_IS_OPEN,
    payload: commanoffcanvasIsHide
  }))
}
export const setImageViewerisOPen=(showImageViewer) => (dispatch) =>
{
  return(dispatch({
    type: IMAGEVIEWER_IS_OPEN,
    payload: showImageViewer
  }))
}
export const setTasklistLoader=(show) => (dispatch) =>
{
  return(dispatch({
    type: TASKLIST_LOADER,
    payload: show
  }))
}
export const commanoffcanvasisOpenfromCalendar=(commanoffcanvasIsHideCalendar) => (dispatch) =>
{
  return(dispatch({
    type: OPEN_TASK_DETAILS_CALENDAR,
    payload: commanoffcanvasIsHideCalendar
  }))
}
export const isBackDrop = (isBackdrop) => (dispatch) =>{
  return(dispatch({
    type : IS_BACKDROP,
    payload: isBackdrop
  }))
}
export const openChatAiInput = (showInput) => (dispatch) =>{
  return(dispatch({
    type : OPEN_CHATAI_INPUTBOX,
    payload: showInput
  }))
}
export const setcreateTaskReqInputIcons = (createTaskReqInputIcons) => (dispatch) =>{
  return(dispatch({
    type : CREATE_TASK_REQ_INPUT_ICONS,
    payload: createTaskReqInputIcons
  }))
}

export const setDaterangedayDifference = (dayDiff) => (dispatch) =>{
  return(dispatch({
    type : DAY_DIFFERENCE,
    payload: dayDiff
  }))
}

export const setDaterangeFilter = (filter) => (dispatch) =>{
  return(dispatch({
    type : APPLIED_DATE_RANGE_FILTER,
    payload: filter
  }))
}

export const updateSpaceContent=(data) => (dispatch) =>
{
  return(dispatch({
    type: UPDATE_SPACE_CONTENT,
    payload: data
  }))
}

export const setTaskAttchmentsForCreate=(data) => (dispatch) =>
{
  return(dispatch({
    type: TASK_ATTACHMENTS_FOR_CREATE_TASK,
    payload: data
  }))
}
export const setProjectTheme=(data) => (dispatch) =>
{
  return(dispatch({
    type: GET_PROJECT_THEME,
    payload: data
  }))
}
export const favoriteDdashboard = (projectId, dashboardName) => (dispatch) =>
{
  return CustomerServices.favoriteDdashboard(projectId, dashboardName).then((data) => {
    if (data.status == 1) {
      dispatch({
        type: SET_FAVORITE_DASHBOARD,
        payload: data.data,
      });
    }
  }
);
}
export const getNewCustomer = (client_id, project_id) => (dispatch) => {
  return CustomerServices.getNewCustomer(project_id, client_id).then((data) => {
      if (data && data.status == 1) {
        dispatch({
          type: _NEW_CLIENT_DATA,
          payload: data.data,
        });
      }
    }
  );
}

export const getOperatorsByCategory = (project_id, id, all_myr_provider,include_manager,setLoader, for_task_assignment) => (dispatch) => {
  setLoader(true);
  return benchmarkServices.getOperatorsByCategory(project_id, id, all_myr_provider,include_manager, for_task_assignment).then((data) => {
    setLoader(false);
    if (data.status == 1) {
      dispatch({
        type: GET_OPERATOR_BY_CATEGORY,
        payload: data,
      });
    }
  }
  );
}

export const updateOperators = (data) => (dispatch) => {
  if (data.length > 0) {
    dispatch({
      type: GET_OPERATOR_BY_CATEGORY,
      payload: data,
    });
  }
}

export const getCountryCodes = (is_default) => (dispatch) => {
  return customerServices.getCountryCodes(is_default).then((res) => {
    if (res && res.status == '1') {
      dispatch({
        type: COUNTRY_CODE,
        payload: res.data,
      });
    }
  });
}
export const getAlluserForStaffUser = (search_text,page) => (dispatch) => {
  return customerServices.getAllUserForStaff(search_text,page).then((res) => {
    if (res.status == '1') {
      dispatch({
        type: GET_ALL_USER_LIST_FOR_STAFF,
        payload: res.data,
      });
    }
  });
}
export const selectedChatUser = (user) => (dispatch) => {
  dispatch({
    type: SELECTED_CHAT_USER,
    payload: user,
  });
}
export const setAllChatUserList = (show) => (dispatch) => {
  dispatch({
    type: IS_ALL_CHAT_USERLIST_SHOW,
    payload: show,
  });
}

export const setGeneralUnreadCount = (count) => (dispatch) => {
  dispatch({
    type: GENERAL_UNREAD_COUNT,
    payload: count,
  });
}

export const setChatUserSearchString = (searchString) => (dispatch) => {
  dispatch({
    type: SEARCH_STRING_CHAT_ALL_USER,
    payload: searchString,
  });
}
export const dayGridMonth = (isDayGridMonth) => (dispatch) =>{
  return(dispatch({
    type : DAY_GRID_MONTH,
    payload: isDayGridMonth
  }))
}

export const dayGridWeek = (isDayGridWeek) => (dispatch) =>{
  return(dispatch({
    type : DAY_GRID_WEEK,
    payload: isDayGridWeek
  }))
}

export const dayGridDay = (isDayGridDay) => (dispatch) =>{
  return(dispatch({
    type : DAY_GRID_DAY,
    payload: isDayGridDay
  }))
}

export const showTasklistInCalendar = (isTasklistView) => (dispatch) =>{
  return(dispatch({
    type : IS_TASKLIST_VIEW,
    payload: isTasklistView
  }))
}
export const isAiChatShow = (isAiChat) => (dispatch) =>{
  return(dispatch({
    type : IS_AI_CHAT,
    payload: isAiChat
  }))
}

export const selectedCalendarDate = (selectedDate) => (dispatch) =>{
  return(
    dispatch({
    type : SET_SELECTED_CALENDAR_DATE,
    payload: selectedDate
  }))
}
export const setSelectedGloablView = (view) => (dispatch) =>{
  return(
    dispatch({
    type : SELECTED_VIEW,
    payload: view
  }))
}

export const setSelectedDateView = (view) => (dispatch) =>{
  return(
    dispatch({
    type : SELECTED_DATE_VIEW,
    payload: view
  }))
}

export const setDaterangeFilterApplied = (applied) => (dispatch) =>{
  return(
    dispatch({
    type : DATERANGE_FILTER_APPLIED,
    payload: applied
  }))
}
export const setselectedDaterangeFilter = (filter) => (dispatch) =>{
  return(
    dispatch({
    type : SELECTED_DATERANGE_FILTER,
    payload: filter
  }))
}

export const setCalendarView  = (view) => (dispatch) =>{
  return(
    dispatch({
    type : CALENDAR_VIEW,
    payload: view
    }))
  }

  export const setAllCheckedTask = (data) => (dispatch) =>{
    return(
      dispatch({
      type : GET_CHECKED_TASK,
      payload: data
    }))
  }

export const createBenchmarkOrNot = (isCreate) => (dispatch) =>{
  return(
    dispatch({
    type : BENCHMARK_CREATE_OR_NOT,
    payload: isCreate
  }))
}
export const isPinnedDelayFilter = (filter) => (dispatch) =>{
  return(
    dispatch({
    type : PINNED_DELAY_FILTER,
    payload: filter
  }))
}

export const getEquimenntsList = (project_id,getSelectedFloorId,is_scan_3d) => (dispatch) => {
  return equipmentsServices.getEquimenntsList(project_id,getSelectedFloorId,is_scan_3d).then((res) => {
    if (res.status == 1) {
      dispatch({
        type: GET_EQUIPMENTS_LIST,
        payload: res.data,
      });
    }
  }
  );
}

export const editedEquipmentsList = (data) => (dispatch) => {
  return dispatch({
    type: GET_EQUIPMENTS_LIST,
    payload: data,
  });
}

export const setEquipmentQuantity = (data) => (dispatch) => {
  dispatch({
    type: SET_EQUIPMENTS_QUANTITY,
    payload: data
  });
};

export const setEquipmentQuantityList = (data) => (dispatch) => {
  dispatch({
    type: SET_EQUIPMENTS_QUANTITY_LIST,
    payload: data
  });
};

export const setNewEquipment = (data) => (dispatch) => {
  dispatch({
    type: SET_NEW_EQUIPMENT,
    payload: data
  });
};

export const lastSessionOfChat = (data) => (dispatch) => {
  dispatch({
    type : LAST_CHAT_SESSION,
    payload : data
  })
}

export const setMetricsDetails = (data) => (dispatch) => {
  dispatch({
    type : METRICS_DETAILS,
    payload : data
  });
}
export const selectedTypeOfSapceId = (Id) => (dispatch) => {
  return dispatch({
    type: SELECTED_TYPE_OF_SPACE_ID,
    payload: Id,
  });
}

export const filterFor3Dmodel = (Id) => (dispatch) => {
  return dispatch({
    type: FILTER_FOR_3D_MODEL,
    payload: Id,
  });
}
export const updateTaskList = (task) => (dispatch) => {
  return dispatch({
    type: UPDATE_TASK_LIST,
    payload: task,
  });
}
export const setSelectedDateForChatai = (date) => (dispatch) => {
  return dispatch({
    type: SELECTED_DATE_CHATAI,
    payload: date,
  });
}

export const setDashBoardView = (data) => (dispatch) => {
  dispatch({
    type : DASHBOARD_VIEW,
    payload : data
  });
}

export const getDashboardDetailswithSpace = (filter= "",filterList,setloader,taskFilters, status_filters) => (dispatch) => {
  setloader(true);
  return customerServices.getDashboardDetailsWthAllSpace(filter,filterList,taskFilters, status_filters).then((res) => {
    setloader(false);
    let user_type = localStorage.getItem('user_type');
    if (res && res.status == 1) {
      dispatch({
        type: DASHBOARD_DETAILS_WITH_ALL_SPACE,
        payload: res.data,
      });
      if(res.data.length > 0 && user_type == "operator"){
        localStorage.setItem("dashboard_client_id",res.data[0].client_id)
      } 
      dispatch({
        type: DASHBOARD_DETAILS_WITH_TOTAL,
        payload: res.extra && res.extra.summary_data ? res.extra.summary_data : {},
      });
      dispatch({
        type: CREDITNOTES_DETAILS,
        payload: res.extra
      });
    }
  }
  );
}
export const setCreateTaskModalIsopen = (isOpen) => (dispatch) => {
  dispatch({
    type: CREATE_TASK_MODAL_IS_OPEN,
    payload: isOpen,
  });
}

export const getDashboardFilterList = (filter= "") => (dispatch) => {
  return customerServices.getDashboardFilter(filter).then((res) => {
    if (res && res.status == 1) {
      dispatch({
        type: DASHBOARD_FILTER,
        payload: res.data,
      });
    }
  }
  );
}

export const setBulkTaskRating = (tasklist) => (dispatch) => {  
  dispatch({
    type: TASK_RATING,
    payload: tasklist,
  });
}

export const updateWeekCalendarData = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_WEEK_CALENDAR,
    payload: data,
  });

}
export const setFetchedTaskandMessages = (data) => (dispatch) => {
  dispatch({
    type: TASK_AND_MESSAGES,
    payload: data,
  });

}

export const setLastMessageOrTaskDate = (date)=> (dispatch) => {
  dispatch({
    type :LAST_TASK_DATE,
    payload : date
  })
}

const setSpacesFromIDB = async(dispatch) => {
  try {
    const data = await readCollectionFromDB("spaces", "project_id");
    if(data.length)
    {
      dispatch({
        type: SET_SPACE_LIST,
        payload: { data },
      });
    }
  }
  catch (e)
  {
    console.error(e);
  }
}

const setTopicsFromIDB = async(dispatch, project_id) => {
  try {
    const data = await readCollectionFromDB("topics", "project_id");
    if(data.length)
    {
      const currentProjectTopics = data.find((projectTopics) => projectTopics.project_id == project_id);
      if(currentProjectTopics && currentProjectTopics.topics)
      {
        dispatch({
          type: GET_DEFAULT_TOPICS,
          payload: currentProjectTopics.topics,
          topicLoadedFromIDB: true
        });
      }
    }
    else {
      dispatch({
        type: GET_DEFAULT_TOPICS,
        payload: []
      });
    }

    const chatSessions = await readCollectionFromDB("last_chat_sessions", "project_id");
    if(chatSessions.length)
    {
      const currentProjectLastChatSession = chatSessions.find((session) => session.project_id == project_id);
      if(currentProjectLastChatSession)
      {
        const lastChatSession = [{
          main_key: currentProjectLastChatSession.main_key,
          filter_key: currentProjectLastChatSession.filter_key,
          title: "",
          image: "",
          main_sub_key: currentProjectLastChatSession.main_sub_key,
          sub_category: undefined,
        }]

         dispatch({
          type: LAST_CHAT_SESSION,
          payload: lastChatSession,
        });
      }
    }
  }
  catch (e)
  {
    console.error(e);
  }
}

export const setShowWeekcalendardays = (data)=> (dispatch) => {
  dispatch({
    type :SHOW_THREE_DAYS_WEEK,
    payload : data
  })
}

export const chatUnreadMessageCount = (data , action, notiType, count = 'Chat')=> (dispatch) => {
  dispatch({
    type :CHAT_UNREADMESSAGE_COUNT,
    payload : {data, action, notiType, count}
  })
}

export const changeWeekCalNavi = (side = "")=> (dispatch) => {
  dispatch({
    type: CHANGE_WEEK_CAL_NAVI,
    payload: side
  })
}

export const unreadTaskComment  = (data = '', action = '')=> (dispatch) => {
  dispatch({
    type: UNREADTASK_COMMENT,
    payload: {data, action}
  })
}

export const setTaskScheduleId  = (schedule_id)=> (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_TASK_DETAIL,
    payload: schedule_id
  })
}

export const setTopicThreadId  = (thread_id, topic_main_key, topic_filter_key, topic_main_sub_key)=> (dispatch) => {
  dispatch({
    type: GENERATE_AI_THREAD_ID,
    payload: {thread_id, topic_main_key, topic_filter_key, topic_main_sub_key}
  })
}
export const clearDefaultTopicsList = (data)=> (dispatch) => {
  dispatch({
    type: CLEAR_DEFAULT_TOPICS ,
    payload: data ? data : []
  })
}
export const setIndexForCalendarSelectedTask = (data)=> (dispatch) => {
  dispatch({
    type: SELECTED_DATE_INDEX_FOR_CALENDAR ,
    payload: data
  })
}

export const getTasksDetailsForChat = (projectId,ids) => (dispatch) => {

  const dispatchEvents = (res) => {
    dispatch({
      type :TASKLIST_LOADER,
      payload: false,
    })
    if (res && res.status == 1) {
      dispatch({
        type: TASK_LIST_FOR_CHAT,
        payload: res.data ? res.data : [] ,
      });      
    } else {
      dispatch({
        type: TASK_LIST_FOR_CHAT,
        payload: [] ,
      }); 
      
    }
  }

  getRecordsByIdFromIDB("tasks", "idb_key", "task_id", ids).then((data) => {
    dispatchEvents({data});
  });

  return CustomerServices.getTasklistForChat(projectId,ids).then((res) => {
    dispatchEvents(res);
  });

}

export const clientTabData = (space) => (dispatch) => {
  dispatch({
    type: CLIENT_TAB_DATA,
    payload: space
  });
};

export const clearChatTaskList = () => (dispatch) => {
  dispatch({
    type: TASK_LIST_FOR_CHAT,
    payload: [] ,
  }); 

}

export const lastMessageSpace = (project_id, messages, notiType ) => (dispatch) => {
  dispatch({
    type : LAST_MESSAGE_SPACE,
    payload : {project_id, messages, notiType }
  })
}

export const lastMessageContactDir = (project_id, messages, notiType ) => (dispatch) => {
  dispatch({
    type : LAST_MESSAGE_CONTACTDIR,
    payload : {project_id, messages, notiType }
  })
}

export const contactDirCount = (groupId) => (dispatch) => {
  dispatch({
    type : CONTACT_DIR_COUNT,
    payload : groupId
  })
}

export const setCallFullscreen = ( data ) => (dispatch) => {
  dispatch({
    type : DISPLAY_CALL_FULLSCREEN,
    payload : data
  })
}
export const setCallRecording = ( data ) => (dispatch) => {
  dispatch({
    type : IS_CALL_RECORDING,
    payload : data
  })
}
export const createTaskForAudioCall = (  
  project_id, 
  is_requested = 0, 
  status = 1,
  filters = [], 
  meeting_link="", 
  task_name = "" ,
  visible_to_client ,
  visible_to_provider, 
  sub_categories ,
  priority = 2, 
  is_pinned = false, 
  comments =[], 
  checklist = [],
  files =[],
  checklistFiles = [],
  startDate,
  assigneeData,
  amount,
  createBenchmark,
  FloorId = 0,
  taskAttachments = []
  ) => (dispatch) => {
    customerServices.createNewTask(
      project_id, 
      is_requested , 
      status,
      filters , 
      meeting_link, 
      task_name ,
      visible_to_client ,
      visible_to_provider, 
      sub_categories ,
      priority , 
      is_pinned, 
      comments, 
      checklist,
      files,
      checklistFiles,
      startDate,
      assigneeData,
      amount,
      createBenchmark,
      FloorId,
      taskAttachments).then((res)=>{
        if (res && res.status) {
          dispatch({
            type: CREATE_TASK_FOR_AUDIO_CALL,
            payload: res.data
          })
        }
      })
}

export const getTasksDetailsForcallTask = (projectId,ids) => (dispatch) => {
  return CustomerServices.getTasklistForChat(projectId,ids).then((res) => {
    if(res && res.status) {
      dispatch({
        type: GET_CALL_TASK_DETAILS,
        payload: res.data ? res.data : []
      })
    }
  })

}
export const getCommentsForAudiocall = (data) => (dispatch) => {
  dispatch({
    type: GET_COMMENTS_DURING_AUDIO_CALL,
    payload: data
  })
}
export const setCallRecordingAction = (data) => (dispatch) => {
  dispatch({
    type: CALL_RECORDING_ACTION_DETAILS,
    payload: data
  })
}
export const setShowMicDisaply = (data) => (dispatch) => {
  dispatch({
    type: SHOW_MUTE_MIC,
    payload: data
  })
}
export const setCallDisconnect = (data) => (dispatch) => {
  dispatch({
    type: CALL_FUNCTION_OF_CALL_DISCONNECT,
    payload: data
  })
}
export const fullScreenLoaderChangeSpace = (data) => (dispatch) => {
  dispatch({
    type: FULL_SCREEN_LOADER_CHANGE_SPACE,
    payload: data
  })
}
export const setSelectedPlanFromAichat = (data) => (dispatch) => {
  dispatch({
    type: SELECTED_PLAN_FROM_CHAT,
    payload: data
  })
}
export const setSelectedProsalData = (data) => (dispatch) => {
  dispatch({
    type: SELECTED_PROPOSAL,
    payload: data
  })
}
export const getCompanylistOfInvitation = () => (dispatch) => {
  customerServices.getCompanyInvitation().then((res)=>{
    if (res && res.status) {    
      dispatch({
        type: GET_COMPANY_INVITETION_LIST,
        payload: res.data ? res.data : []
      })
    }
  })
}
export const selectJobProposalPage = (data) => (dispatch) => {  
      dispatch({
        type: IS_JOB_PROPOSAL_PAGE,
        payload: data
      })
}

export const setOpenOrCloseContactDirectoryChat = (data) => (dispatch) => {  
      dispatch({
        type: OPEN_CONTACT_DIRECTORY_CHAT,
        payload: data
      })
    }

export const accordionsEventKey = (data) => (dispatch) => {
  dispatch({
    type : ACCORDION_EVENT_KEY,
    payload : data
  })
}
export const setListOfIBANAccounts = (data) => (dispatch) => {
  dispatch({
    type : IBAN_ACCOUNTS_LIST,
    payload : data
  })
}
export const setEditScheduleJsonData = (data) => (dispatch) => {
  dispatch({
    type : EDIT_SCHEDULE_JSON,
    payload : data
  })
}
export const setApiLoaderShow = (flag) => (dispatch) => {
  dispatch({
    type : SHOW_API_LOADING_LOADER,
    payload : flag
  })
}

export const setListOfSelectedOperator = (data) => (dispatch) => {
  dispatch({
    type : SET_LIST_OF_SELECTEDOPERATORE_LIST,
    payload : data
  })
}
export const setMailReply = (flag) => (dispatch) => {
  dispatch({
    type : MAIL_REPLAY_FROM_LIST,
    payload : flag
  })
}
export const setEquipmentConsumbleCheckList = (equipment, datatype = '') => (dispatch) => {
  dispatch({
    type : SET_EQUIPMENT_CONSUMBLE_CHECK_LIST,
    payload : {equipment, datatype}
  })
}
export const isCreareTaskDefaultTopics = (isdefaultTopics, isFromCreateTask = '') => (dispatch) => { 
  dispatch({
    type : IS_CREATE_TASK_DEFAULT_TOPICS,
    payload : {isdefaultTopics, isFromCreateTask}
  })
}
export const getProviderList = (category_ids = []) => (dispatch) => { 
  dispatch({
    type : SET_PROVIDER_LIST_LOADER,
    payload : true
  })
  customerServices.fetchProviderlistByCatgory(category_ids).then((res)=>{
    if (res && res.status) {      
      dispatch({
        type : GET_PROVIDER_LIST,
        payload : res.data
      })
      dispatch({
        type : SET_PROVIDER_LIST_LOADER,
        payload : false
      })
    }else{
      dispatch({
        type : SET_PROVIDER_LIST_LOADER,
        payload : false
      })
    }

  })
}
export const getListOfRiskData = (data = []) => (dispatch) => {
  dispatch({
    type  : SET_RISK_DATA_LIST,
    payload : data
  })
}
export const getProviderListTableData = (data = {}) => (dispatch) => {
  dispatch({
    type  : PROVIDER_LIST_TABLE_DATA,
    payload : data
  })
}
export const updateProviderListTableData = (providerData, tabName = '', keys = '') => (dispatch) => {
  dispatch({
    type  : UPDATE_PROVIDER_LIST_TABLE_DATA,
    payload : {providerData, tabName, keys}
  })
}

export const updateProviderListLoader = (flag) => (dispatch) => {
  dispatch({
    type : SET_PROVIDER_LIST_LOADER,
    payload : flag
  })
}
export const updateDefaultTopics = (filterkey, mainKkey ,main_sub_key) => (dispatch) => {
  dispatch({
    type : UPDATE_DEFAULT_TOPICS_AI_DATA,
    payload : {filterkey, mainKkey ,main_sub_key}
  })}
  
export const istodoTable = (flag) => (dispatch) => {
  dispatch({
    type : IS_TODO_TABLE,
    payload : flag
  })
}
export const updateSchedualEmail = (data) => (dispatch) => {
  dispatch({
    type : UPDATE_SCHEDUALE_EMAILS,
    payload : data
  })
}
export const updateProviderList = (data) => (dispatch) => {
  dispatch({
    type : GET_PROVIDER_LIST,
    payload : data
  })
}
export const setLeftPanelTab = (tab = "") => (dispatch) => {
  dispatch({
    type : SELECTED_LEFT_PANEL_TAB,
    payload : tab
  })
}

export const setProviderAgnetList = (data = []) => (dispatch) => {
  dispatch({
    type : PROVIDER_AGENT_LIST,
    payload : data
  })
}
export const setProvideClientList = (data = []) => (dispatch) => {
  dispatch({
    type : PROVIDER_CLIENT_LIST,
    payload : data
  })
}

export const setFilterSpaceProjectid = (projectId = 0) => (dispatch) => {
  dispatch({
    type : FILTER_SPACE_PROJECT_ID,
    payload : projectId
  })
}
export const updatedDefaultTopics = (data) => (dispatch) => {
  dispatch({
    type : UPDATE_DEFAULT_TOPICS,
    payload : data
  })
}
export const emailTokenExpired = (data) => (dispatch) => {
  dispatch({
    type : EMAIL_TOKEN_EXPIRED,
    payload : data
  })
}
export const isTenantsData = (data) => (dispatch) => {
  dispatch({
    type : IS_TENANTS_DATA,
    payload : data
  })
}
export const setProviderWalleftList = (data = []) => (dispatch) => {
  dispatch({
    type : PROVDER_WALLET_LIST,
    payload : data
  })
}

export const setMyDriveTopic = (data = {}) => (dispatch) => {
  dispatch({
    type : MY_DRIVE_TOPIC,
    payload : data
  })
}
