import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { _l, showError } from "../../hooks/utilities";
import PremiumBillingTable from "../PremiumPage/PremiumBillingTable";
import CustomerServices from "../../services/customer-services";
import CommanLoader from "../Loader/CommanLoader";

const StaffIbanSetup = ({ show, handleClose }) => {
  const [spaceIbanDetails, setSpaceIbanDetails] = useState({});

  useEffect(() => {
    const getStaffIbanDetails = async () => {
      try {
        const response = await CustomerServices.getStaffIBANData();
        switch (response.status) {
          case 1:
            setSpaceIbanDetails({
              ...response.data,
              billing_address: response.data.company_address,
              title: response.data.company_name,
              single_iban_for_all_spaces:
                response.data.unique_iban != "" ||
                !response.data.project_data.length,
            });
            break;
          case 2:
            setSpaceIbanDetails({
              ...response.data,
              office_title: response.data.company_name,
              title: response.data.company_name,
              billing_address: response.data.company_address,
              unique_iban: response.data.unique_iban,
              single_iban_for_all_spaces:
                response.data.unique_iban != "" ||
                !response.data.project_data.length,
            });
            break;
          default:
            showError(response.message);
            handleClose();
            break;
        }
      } catch (e) {
        showError("l_something_went_wrong");
        handleClose();
      }
    };
    getStaffIbanDetails();
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="custom-modal-style"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={false}
      keyboard={false}
      size={"lg"}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {_l("l_payment")} {_l("l_setup")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className=" py-0 overflow-hiiden-auto">
        {Object.keys(spaceIbanDetails).length ? (
          <PremiumBillingTable
            insideModal={true}
            handlerIBAN={handleClose}
            BillingsData={spaceIbanDetails}
            IBANStatus={spaceIbanDetails.single_iban_for_all_spaces}
          />
        ) : (
         <></>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default StaffIbanSetup;
