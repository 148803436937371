import React, { useEffect, useRef, useState } from "react";
import AssigneeSubMenu from "./AssigneeSubMenu";
import { useDispatch, useSelector } from "react-redux";
import $, { event } from "jquery";
import {
  getAssigneeTypesForRequest,
  _l,
  showSuccess,
  removeDuplicateObjectsFromArray,
  deepCopyArray,
} from "../../hooks/utilities";
import { CaretRight, Hash } from "phosphor-react";
import jQuery from "jquery";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import store from "../../store";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import CustomerServices from "../../services/customer-services";
import { setTaskScheduleId } from "../../actions/customer";

const AddAssigneeMainDropdownNew = ({
  taskId,
  openAssigneeDropdown,
  setOpenAssigneeDropdown,
  selectedTagId = 0,
  isRequest = false,
  moduleType,
  pageName = "",
  actionStausChange,
  action = false,
  direction = null /*Dropdown direction Left or right */,
  bulkAction = false,
  actionType = "",
  actionBtnLoader = false,
  actionTypeId = "",
  dropDownToggle,
  setSelectedAssignee,
  selectedAssignee,
  saveBtnShow = true,
  fromCreate = false,
  defaultSubMenuOpen = [false, false, false, false, false, false],
}) => {

  const userType = localStorage.getItem("user_type");
  const staffId = localStorage.getItem("staff_id");

  const [assigneeData, setAssigneeData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [defaultTags, setDefaultTags] = useState([]);
  const [assignee_list, setassignee_list] = useState([]);
  const [newAssigneeState, setNewAssigneeState] = useState([]);
  const [newAssigneeBackup, setNewAssigneeBackup] = useState(false);
  const [searchAssignees, setSearchAssignees] = useState("");
  // const [openSubmenuIndex, setOpenSubmenuIndex] = useState([]);
  const [openSubmenuIndex, setOpenSubmenuIndex] = useState(defaultSubMenuOpen);
  const [stop, setStop] = useState(true);
  const isDefaultTopicsDataAccess = ["task", "LiteVersion"];
  const rel_type = localStorage.getItem("user_type");
  const contact_role = localStorage.getItem("contact_role");
  const { defaultTopics, taskFilters } = useSelector((state) => state.customer);

  const { taskList } = store.getState().customer;
  const dispatch = useDispatch();

  let tempTopic = [];
  const dropdownRef = useRef();

  let provider = "";

  let assigneeTypes = [];
  const virtualspace = localStorage.getItem("selectedVirtualSpace")
    ? "task_topic"
    : "myr_staff";
  if (window.location.pathname == "/calendar") {
    assigneeTypes = [
      "my_self",
      "myr_network",
      "user_journey",
      virtualspace,
      "equipments",
      "my_task",
      provider,
      "",
    ];
  } else if (window.location.pathname == "/MyEmail") {
    if (moduleType == "cc") {
      if (rel_type == "operator") {
        assigneeTypes = ["my_peoples", "my_customers"];
      } else if (rel_type == "contact") {
        assigneeTypes = ["my_peoples", "provider_task"];
      } else {
        assigneeTypes = ["myr_staff_task", "myr_customers", "myr_providers"];
      }
    } else {
      assigneeTypes = ["my_mail_peoples"];
    }
  } else if (window.location.pathname === "/documents") {
    assigneeTypes = [
      "my_self",
      "myr_staff",
      "my_task",
      "one_drive",
      "google_drive",
    ];
  } else {
    assigneeTypes = [
      "my_self",
      "current_providers",
      "my_peoples",
      "myr_network",
      "myr_staff",
      "equipments",
      "my_task",
      provider ? provider : "",
      "",
    ];
  }
  if (pageName != "" && isDefaultTopicsDataAccess.indexOf(pageName) > -1) {
    assigneeTypes = ["my_task"];
    assigneeTypes = assigneeTypes.concat(getAssigneeTypesForRequest());

    if (provider) {
      assigneeTypes.push(provider);
    }

    if (localStorage.getItem("selectedVirtualSpace")) {
      assigneeTypes = assigneeTypes.filter((type) => type != "myr_staff_task");
    }
  }

  const isAllowedToModifyAssignee = () => {
    let flag = true;
    if (!taskId || taskId <= 0) {
      return flag;
    } else if (taskList && taskList.length) {
      const currentTask = taskList.find((w) => w.id == taskId);
      if (currentTask) {
        flag = currentTask.is_allowed_to_edit == 1;
      }
    }
    return flag;
  };

  const handleSelectAssigneeNew = (
    type,
    itemId,
    item,
    sub_category,
    isAutoAssign = true
  ) => {
    if (!isAllowedToModifyAssignee()) {
      return false;
    }
    setNewAssigneeBackup(true);
    let selectedAssignees = {
      main_key: type,
      filter_key: itemId,
      image: item.profile_image || "",
      name: item.name || "",
      main_sub_key: item.main_sub_key,
      sub_category: sub_category ? [sub_category] : [],
    };
    let sub_categories = [];
    if (sub_category) {
      sub_categories = [
        {
          main_key: type,
          categories: [
            {
              filter_key: itemId,
              categories: [sub_category].map((sc) => sc.id),
            },
          ],
        },
      ];
    } else if (!sub_category && itemId === "myrai_support") {
      selectedAssignees.sub_category = [
        {
          main_key: type,
          categories: [
            {
              filter_key: itemId,
            },
          ],
        },
      ];
    }
    if (handleRequestAssignee) {
      handleRequestAssignee(selectedAssignees);
      if (taskId > 0 && !isAutoAssign) {
        assigneeUpdate();
      }
      return;
    }
  };

  const assigneeUpdate = () => {
    let formattedAssigneeList = deepCopyArray(selectedAssignee);
    
    CustomerServices.setRequestTaskAssignee(
      taskId,
      selectedAssignee && selectedAssignee.length > 0
        ? formattedAssigneeList
        : tempTopic,
      isRequest
    ).then((res) => {
      if (res && res.status == 1) {
        if(res.data && res.data.benchmark_id > 0)
        {
          dispatch(setTaskScheduleId(res.data.benchmark_id));
        }
        setNewAssigneeBackup(false);
        tempTopic = newAssigneeState.slice();
        setSelectedAssignee(newAssigneeState.slice());
        setOpenAssigneeDropdown(false);
        showSuccess(res.message);
      }
    });
  };

  const handleAssigneSubmenu = (index) => {
    let indexs = index;
    if (openSubmenuIndex.length == 1) {
      indexs = index - 1;
    }
    setOpenSubmenuIndex(
      openSubmenuIndex.map((w, i) => (w = w == true ? false : i == indexs))
    );
    setSearchAssignees("");
    if ($(".w-200").hasClass("show")) {
      $(".overflowscroll").scrollTop(0);
    }
  };

  const handleRequestAssignee = (assigneeDetail) => {
    let taskAssign =
      selectedAssignee && selectedAssignee.length
        ? selectedAssignee.slice()
        : [];
    let Index = taskAssign.findIndex(
      (e) => e.main_key == assigneeDetail.main_key
    );
    if (Index > -1) {
      let IndexT = taskAssign[Index]["categories"].findIndex(
        (taskassign) => taskassign.filter_key == assigneeDetail.filter_key
      );
      if (IndexT > -1) {
        if (
          assigneeDetail.main_key == "equipments" ||
          (isRequest &&
            assigneeDetail.main_key == "providers" &&
            ((rel_type == "contact") ||
              rel_type == "staff") &&
            assigneeDetail.filter_key != "myrai_support")
        ) {
          let IndexU =
            taskAssign[Index]["categories"][IndexT].sub_category &&
            taskAssign[Index]["categories"][IndexT].sub_category.length > 0
              ? taskAssign[Index]["categories"][IndexT].sub_category.findIndex(
                  (taskassign) =>
                    (assigneeDetail.sub_category.length &&
                      assigneeDetail.main_key == "equipments" &&
                      taskassign == assigneeDetail.sub_category[0].id) ||
                    (taskassign &&
                      assigneeDetail.main_key != "equipments" &&
                      taskassign.id == assigneeDetail.sub_category[0].id)
                )
              : -1;
          if (IndexU > -1) {
            if (assigneeDetail.main_key == "equipments") {
              taskAssign[Index]["categories"][IndexT].sub_category = taskAssign[
                Index
              ]["categories"][IndexT].sub_category.filter(
                (taskassign) => taskassign != assigneeDetail.sub_category[0].id
              );
            } else {
              taskAssign[Index]["categories"][IndexT].sub_category = taskAssign[
                Index
              ]["categories"][IndexT].sub_category.filter(
                (taskassign) =>
                  taskassign &&
                  assigneeDetail.sub_category &&
                  assigneeDetail.sub_category.length &&
                  taskassign.id != assigneeDetail.sub_category[0].id
              );
            }
          } else {
            if (
              assigneeDetail.main_key == "equipments" &&
              assigneeDetail.sub_category.length > 0
            ) {
              taskAssign[Index]["categories"][IndexT].sub_category.push(
                assigneeDetail.sub_category[0].id
              );
            } else {
              if (taskAssign[Index]["categories"][IndexT].sub_category) {
                taskAssign[Index]["categories"][IndexT].sub_category.push(
                  assigneeDetail.sub_category[0]
                );
              } else {
                taskAssign[Index]["categories"][IndexT].sub_category = [
                  assigneeDetail.sub_category[0],
                ];
              }
            }
          }
        } else {
          taskAssign[Index]["categories"] = taskAssign[Index][
            "categories"
          ].filter(
            (taskassign) => taskassign.filter_key != assigneeDetail.filter_key
          );
        }
      } else {
        if ([ "providers", "myr_support"].includes(assigneeDetail.main_key)) {
          taskAssign[Index]["categories"] = [
            {
              filter_key: assigneeDetail.filter_key,
              main_sub_key: assigneeDetail.main_sub_key,
              sub_category: assigneeDetail.sub_category,
            },
          ];
        } else {
          taskAssign[Index]["categories"].push({
            filter_key: assigneeDetail.filter_key,
            main_sub_key: assigneeDetail.main_sub_key,
            sub_category:
              assigneeDetail.sub_category.length > 0
                ? assigneeDetail.sub_category.map((dd) => dd.id)
                : [],
          });
        }
      }
    } else {
      taskAssign.push({
        main_key: assigneeDetail.main_key,
        categories: [
          {
            filter_key: assigneeDetail.filter_key,
            main_sub_key: assigneeDetail.main_sub_key,
            sub_category:
              assigneeDetail.main_key == "providers"
                ? assigneeDetail.sub_category.length > 0
                  ? assigneeDetail.sub_category
                  : []
                : assigneeDetail.sub_category.length > 0
                ? assigneeDetail.sub_category.map((dd) => dd.id)
                : [],
          },
        ],
      });
    }
    if (taskId && taskId != "" && saveBtnShow == true) {
      tempTopic = taskAssign.slice();
      setNewAssigneeState(taskAssign.slice());
      setSelectedAssignee(taskAssign.slice());
    } else {
      tempTopic = taskAssign.slice();
      setSelectedAssignee(taskAssign.slice());
    }
  };

  const getTaskAssignee = () => {
    let assignee = [];
    if (taskList && taskList.length) {
      const taskIndex = taskList.findIndex((w) => w.id == taskId);
      if (taskIndex > -1) {
        const task = taskList[taskIndex];
        assignee = task.assignee_list_DEV;
      }
    }
    return assignee;
  };

  useEffect(() => {
    setDefaultTags([]);
    if (defaultTopics) {
      const index = defaultTopics.findIndex(
        (assignee) => assignee.main_key == "my_task"
      );
      if (index > -1) {
        //Added this condition to avoid run time crash....
        setDefaultTags(
          defaultTopics[index].categories
            .filter((tag) => tag.filter_key > 0)
            .sort((a, b) => {
              if (a.is_active > b.is_active) {
                return -1;
              }
              if (a.is_actve < b.is_active) {
                return 1;
              }
              return 0;
            })
        );
      }
    }

    if (pageName != "" && isDefaultTopicsDataAccess.indexOf(pageName) > -1) {
      let topicList = defaultTopics ? defaultTopics : [];
      if (taskList && taskList.length) {
        const taskIndex = taskList.findIndex((w) => w.id == taskId);
        if (taskIndex > -1) {
          const task = taskList[taskIndex];
          if (
            task.schedule_id > 0 &&
            !(
              task.is_allowed_to_delete > 0 &&
              task.is_allowed_to_edit > 0 &&
              task.is_one_time_benchmark > 0
            )
          ) {
            topicList = topicList.filter((x) => x.title == "l_my_people");
          }
        }
      }
      topicList.filter((d) => d.main_key == "equipments")[0] &&
        topicList
          .filter((d) => d.main_key == "equipments")[0]
          .categories.forEach((equipment) => {
            let subCategory = equipment.sub_category;
            subCategory &&
              subCategory.forEach((df) => {
                df.is_assigned = 0;
              });
          });
      setAssigneeData(topicList);
      // setOpenSubmenuIndex(Array.from({ length: topicList.length }).fill(false))
    }
  }, [defaultTopics]);

  useEffect(() => {
    if (selectedAssignee.length > 0) {
      let task_assignee_ids = [];

      selectedAssignee.map((ta) => {
        ta.categories &&
          ta.categories.map((taskAssign) => {
            defaultTopics &&
              defaultTopics.forEach((topic) => {
                if (topic.main_key == ta.main_key) {
                  topic.categories.forEach((provider) => {
                    if (taskAssign.filter_key == provider.filter_key) {
                      task_assignee_ids.push({
                        id: provider.filter_key,
                        name: topic.main_key,
                      });
                      let subCategory = [];
                      if (topic.main_key == "providers") {
                        taskAssign &&
                          taskAssign.sub_category &&
                          taskAssign.sub_category.forEach((dd) => {
                            subCategory.push(dd);
                          });

                        if (
                          taskAssign &&
                          taskAssign.sub_category &&
                          taskAssign.sub_category.length == 0 &&
                          contact_role != 3
                        ) {
                          task_assignee_ids = [];
                        }
                      } else {
                        provider.sub_category &&
                          provider.sub_category.forEach(() => {
                            taskAssign.sub_category &&
                              taskAssign.sub_category.forEach((dd) => {
                                subCategory.push(dd);
                              });
                          });
                      }
                      if (subCategory.length == 0) {
                        task_assignee_ids = task_assignee_ids.filter(
                          (tas) => tas.name != "equipments"
                        );
                      }
                      task_assignee_ids.push({
                        id: provider.filter_key,
                        name: provider.title,
                        sub_category: subCategory,
                        key: topic.main_key,
                      });
                    }
                  });
                }
              });
          });
      });
      setSelectedIds(task_assignee_ids.slice());
    }
  }, [JSON.stringify(selectedAssignee)]);

  useEffect(() => {
    if (fromCreate && taskFilters && taskFilters.length > 0) {
      let tempcat = taskFilters.filter((topic) =>
        [
          "created_by_me",
          "assigned_to_me",
          "pinned",
          "overdue",
          "date_range_filter",
        ].includes(topic.filter_key)
      );
      if (tempcat.length == 0) {
        if (taskFilters[0].main_key == "equipments") {
          taskFilters[0].sub_category.map(() => {
            handleRequestAssignee({
              ...taskFilters[0],
              sub_category: [],
              name: taskFilters[0].title,
            });
          });
        } else if (taskFilters[0].main_key == "my_task") {
          taskFilters.forEach((data) => {
            if (
              data.main_key == "my_task" &&
              ![
                "created_by_me",
                "assigned_to_me",
                "pinned",
                "overdue",
                "date_range_filter",
              ].includes(data.filter_key)
            ) {
              handleRequestAssignee({
                ...data,
                sub_category: data.sub_category ? data.sub_category : [],
                name: data.title,
              });
            }
          });
        } else {
          handleRequestAssignee({
            ...taskFilters[0],
            sub_category: taskFilters[0].sub_category
              ? taskFilters[0].sub_category
              : [],
            name: taskFilters[0].title,
          });
        }
      }
    }
  }, [JSON.stringify(taskFilters), fromCreate]);

  useEffect(() => {
    let tempcat = taskFilters
      .filter((topic) =>
        [
          "created_by_me",
          "assigned_to_me",
          "pinned",
          "overdue",
          "date_range_filter",
        ].includes(topic.filter_key)
      )
      .slice();
    if (
      fromCreate &&
      taskFilters &&
      taskFilters.length > 0 &&
      openSubmenuIndex.length > 0 &&
      stop &&
      tempcat.length == 0 &&
      openAssigneeDropdown
    ) {
      let keys = assigneeTypes.findIndex((dd) => dd == taskFilters[0].main_key);
      setStop(false);
      handleAssigneSubmenu(keys);
    }
  }, [openSubmenuIndex, fromCreate, taskFilters, openAssigneeDropdown]);

  useEffect(() => {
    if (openAssigneeDropdown == true && saveBtnShow == true) {
      $(document).mouseup(function (e) {
        if (openAssigneeDropdown == true && saveBtnShow == true) {
          var container = $(`.AssigneeDropdownCloseOutside${taskId}`);
          if (!container.is(e.target) && container.has(e.target).length === 0) {
            // setOpenAssigneeDropdown(false);
            if (newAssigneeBackup && taskId && taskId != "") {
              // assigneeUpdate();
            }
          }
        }
      });
    } else {
      $(document).unbind("mouseup");
    }
  }, [openAssigneeDropdown, taskId, newAssigneeBackup]);

  // useEffect(() => {
  //   if (contact_role != 3 && taskFilters && taskFilters.length && !taskId) {
  //     let removeFilters = taskFilters.filter(
  //       (w) =>
  //         w.main_key !== "my_task" ||
  //         (w.main_key === "my_task" &&
  //           !["created_by_me", "pinned", "overdue", "assigned_to_me"].includes(
  //             w.filter_key
  //           ))
  //     );

  //     // if(userType === "operator")
  //     // {
  //     //   removeFilters = removeFilters.filter((w) => w.main_key !== "providers");
  //     // }
     
  //     if (removeFilters.length) {
  //       let tempData = removeFilters[0];
  //       handleSelectAssigneeNew(
  //         tempData.main_key,
  //         tempData.filter_key,
  //         tempData,
  //         []
  //       );
  //     }
  //   }
  // }, [JSON.stringify(taskFilters)]);

  useEffect(() => {
    if (taskId > 0) {
      let taskAssignees = getTaskAssignee();
      taskAssignees.forEach((topic) => {
        if (topic.main_key === "providers" && topic.categories) {
          topic.categories.forEach((category) => {
            if (
              category.sub_category &&
              !category.sub_category.find((w) => w.is_assigned == 1)
            ) {
              category.sub_category.push({ is_assigned: 1 });
            } else {
              category = { ...category, sub_category: [{ is_assigned: 1 }] };
            }
          });
        }
      });
      tempTopic = taskAssignees.slice();
      setSelectedAssignee(taskAssignees);
      setNewAssigneeState(taskAssignees.slice());
    }
  }, [taskId]);

  useEffect(() => {
    const clickListener = (e) => {
      const clickedElement = e.target;
      let currentElement = clickedElement;
      while (currentElement.parentNode) {
        if (currentElement.parentNode.nodeName === "BODY") {
          break;
        }
        currentElement = currentElement.parentNode;
      }
      if (currentElement && currentElement.id === "root") {
        const bodyFirstChild = document.body.firstChild;

        if (bodyFirstChild) {
          let currentElement = bodyFirstChild.nextSibling;
          while (currentElement !== null) {
            if (
              currentElement.classList &&
              currentElement.classList.length > 0
            ) {
              const classNames = Array.from(currentElement.classList).join(" ");
              if (
                classNames.includes(`AssigneeDropdownCloseOutside${taskId}`) &&
                !e.target.parentNode.id.includes(`New_${taskId}`) &&
                !e.target.id.includes(`New_${taskId}`)
              ) {
                setOpenAssigneeDropdown(false);
              }
            }
            currentElement = currentElement.nextSibling;
          }
        }
      }
    };
    document.addEventListener("click", clickListener);

    return () => {
      document.removeEventListener("click", clickListener);
    };
  }, []);

  return (
    <DropdownMenu
      className={`w-200 overflowscroll AssigneeDropdownCloseOutside${taskId}`}
      aria-labelledby="AddAssigneeMainDropdownNew"
      container="body"
      ref={dropdownRef}
      id={`main_assignee_menu_${taskId}`}
    >
      {assigneeData ? (
        <>
          {assigneeTypes.map((type, index) => {
            let filterData =
              assigneeData && assigneeData.length
                ? assigneeData.filter((assign) => assign.main_key == type)
                : [];

            let assignDataKey = [];

            if (
              filterData &&
              filterData.length &&
              filterData[0]["main_key"] == type
            ) {
              let assigneeOptions = [];
              if (
                isRequest ||
                isDefaultTopicsDataAccess.indexOf(pageName) > -1
              ) {
                if (
                  isDefaultTopicsDataAccess.indexOf(pageName) > -1 &&
                  filterData &&
                  filterData.length
                ) {
                  assignDataKey = filterData[0];
                }
                switch (type) {
                  case "providers":
                    let providerId = "";
                    assignDataKey.categories.forEach((provider) => {
                      let subCategory = [];
                      selectedIds.forEach((se) => {
                        if (provider.filter_key == se.id) {
                          providerId = provider.filter_key;
                          se.sub_category &&
                            se.sub_category.map((sc) => {
                              // this condition is added because assignee counts are not properly displayed ** is assigneed : 1  added when the subCategory is not empty **
                              if (Object.keys(sc).length > 0) {
                                subCategory.push({ ...sc, is_assigned: 1 });
                              }
                            });
                        }
                      });
                      if(contact_role == 3 && provider.filter_key === "myrai_support")
                      {
                        return;
                      }
                      assigneeOptions.push({
                        name: provider.title,
                        id: provider.filter_key,
                        is_assigned: provider.is_assigned || 0,
                        profile_image: provider.image || "",
                        is_tag: 1,
                        subCategory: subCategory,
                        main_sub_key: provider.main_sub_key,
                        type: type,
                      });
                    });
                    break;
                  case "proposals":
                    assignDataKey.categories.forEach((proposal) => {
                      assigneeOptions.push({
                        name: proposal.title,
                        id: proposal.filter_key,
                        is_assigned: proposal.is_assigned || 0,
                        profile_image: proposal.image || "",
                        main_sub_key: proposal.main_sub_key,
                        is_tag: 1,
                      });
                    });
                    break;
                  case "my_task":
                    assignDataKey.categories.forEach((my_task) => {
                      if (
                        !isNaN(parseInt(my_task.filter_key)) &&
                        assigneeOptions.filter(
                          (assign) => assign.id == my_task.filter_key
                        ).length == 0 &&
                        parseInt(my_task.filter_key) > 0
                      ) {
                        assigneeOptions.push({
                          name: my_task.title,
                          id: my_task.filter_key,
                          is_assigned: my_task.is_assigned,
                          profile_image: my_task.image || "",
                          is_tag: 1,
                          main_sub_key: my_task.main_sub_key,
                          type: type,
                        });
                      }
                    });
                  case "task_topic":
                    if (assignDataKey.categories != undefined) {
                      assignDataKey.categories.forEach((my_task) => {
                        // if (!isNaN(parseInt(my_task.filter_key))) {
                        assigneeOptions.push({
                          name: my_task.title,
                          id: my_task.filter_key,
                          is_assigned: my_task.is_assigned,
                          profile_image: my_task.image || "",
                          main_sub_key: my_task.main_sub_key,
                          // is_tag: 1
                          type: type,
                        });
                        // }
                      });
                    }

                    break;
                  case "my_operator":
                    assignDataKey.categories.forEach((my_operator) => {
                      if (
                        !isNaN(parseInt(my_operator.filter_key)) &&
                        assigneeOptions.filter(
                          (assign) => assign.id == my_operator.filter_key
                        ).length == 0 &&
                        parseInt(my_operator.filter_key) > 0
                      ) {
                        assigneeOptions.push({
                          name: my_operator.title,
                          id: my_operator.filter_key,
                          is_assigned: my_operator.is_assigned,
                          profile_image: my_operator.image || "",
                          main_sub_key: my_operator.main_sub_key,
                          is_tag: 1,
                          type: type,
                        });
                      }
                    });
                    break;
                  case "my_staff":
                    assignDataKey.categories.forEach((my_staff) => {
                      if (
                        !isNaN(parseInt(my_staff.filter_key)) &&
                        assigneeOptions.filter(
                          (assign) => assign.id == my_staff.filter_key
                        ).length == 0 &&
                        parseInt(my_staff.filter_key) > 0
                      ) {
                        assigneeOptions.push({
                          name: my_staff.title,
                          id: my_staff.filter_key,
                          is_assigned: my_staff.is_assigned,
                          profile_image: my_staff.image || "",
                          main_sub_key: my_staff.main_sub_key,
                          is_tag: 1,
                          type: type,
                        });
                      }
                    });
                    break;
                  case "my_contact":
                    assignDataKey.categories.forEach((my_contact) => {
                      if (
                        !isNaN(parseInt(my_contact.filter_key)) &&
                        assigneeOptions.filter(
                          (assign) => assign.id == my_contact.filter_key
                        ).length == 0 &&
                        parseInt(my_contact.filter_key) > 0
                      ) {
                        assigneeOptions.push({
                          name: my_contact.title,
                          id: my_contact.filter_key,
                          is_assigned: my_contact.is_assigned,
                          profile_image: my_contact.image || "",
                          main_sub_key: my_contact.main_sub_key,
                          is_tag: 1,
                          type: type,
                        });
                      }
                    });
                    break;
                  case "equipments":
                    let equipmentId = "";
                    assignDataKey.categories.forEach((equipment) => {
                      let subCategory = equipment.sub_category;
                      selectedIds.forEach((se) => {
                        if (equipment.filter_key == se.id) {
                          equipmentId = equipment.filter_key;
                          let selectedSubCate = se.sub_category
                            ? se.sub_category
                            : [];
                          subCategory.forEach((df) => {
                            if (selectedSubCate.includes(df.id)) {
                              df.is_assigned = 1;
                            } else {
                              df.is_assigned = 0;
                            }
                          });
                        }
                        if (equipmentId != equipment.filter_key) {
                          subCategory &&
                            subCategory.forEach((df) => {
                              df.is_assigned = 0;
                            });
                        }
                      });
                      if (
                        !isNaN(parseInt(equipment.filter_key)) &&
                        assigneeOptions.filter(
                          (assign) => assign.id == equipment.filter_key
                        ).length == 0 &&
                        parseInt(equipment.filter_key) > 0
                      ) {
                        assigneeOptions.push({
                          name: equipment.title,
                          id: equipment.filter_key,
                          is_assigned: equipment.is_assigned,
                          profile_image: equipment.image || "",
                          is_tag: 1,
                          subCategory: subCategory,
                          main_sub_key: equipment.main_sub_key,
                          type: type,
                        });
                      }
                    });
                  case "myr_support":
                    assignDataKey.categories.forEach((myr_support) => {
                      let subCategory = [];
                      selectedIds.forEach((se) => {
                        if (myr_support.filter_key == se.id) {
                          se.sub_category &&
                            se.sub_category.map((sc) => {
                              subCategory.push({ ...sc, is_assigned: 1 });
                            });
                        }
                      });
                      assigneeOptions.push({
                        name: myr_support.title,
                        id: myr_support.filter_key,
                        is_assigned: myr_support.is_assigned || 0,
                        profile_image: myr_support.image || "",
                        is_tag: 1,
                        subCategory: subCategory,
                        main_sub_key: myr_support.main_sub_key,
                        type: type,
                      });
                    });

                  default:
                    break;
                }
              } else {
                switch (type) {
                  case "my_self":
                    assigneeOptions =
                      moduleType == "subscription" ||
                      moduleType == "estimate" ||
                      moduleType == "invoice"
                        ? []
                        : [
                            {
                              name: "To Me",
                              email: localStorage.getItem("email"),
                              id: 1,
                              is_assigned: assigneeData[type].is_assigned,
                              profile_image:
                                assigneeData[type].profile_image || "",
                              type: type,
                            },
                          ];
                    defaultTags.forEach((tag) => {
                      assigneeOptions.push({
                        name: tag.title,
                        id: tag.filter_key,
                        is_assigned: tag.filter_key == selectedTagId ? 1 : 0,
                        is_tag: 1,
                        type: type,
                      });
                    });
                    break;
                  case "current_providers":
                    assigneeData[type].providers.forEach((provider) => {
                      assigneeOptions.push({
                        name: provider.name,
                        id: provider.email,
                        is_assigned: provider.is_assigned || 0,
                        profile_image: provider.profile_image || "",
                        type: type,
                      });
                    });
                    break;
                  case "my_peoples":
                    assigneeData[type].employees.forEach((employee) => {
                      assigneeOptions.push({
                        name: employee.name,
                        id: employee.contact_id,
                        is_assigned: employee.is_assigned || 0,
                        profile_image: employee.profile_image || "",
                        type: type,
                      });
                    });
                    break;
                  case "user_journey":
                    assigneeData[type].categories.forEach((proposal) => {
                      assigneeOptions.push({
                        name: proposal.title,
                        id: proposal.filter_key,
                        is_assigned: proposal.is_assigned || 0,
                        profile_image: proposal.image || "",
                        is_tag: 1,
                        type: type,
                      });
                    });
                    break;
                  case "myr_staff":
                    assigneeData[type].staff.forEach((employee) => {
                      assigneeOptions.push({
                        name: employee.name,
                        id: employee.staffid,
                        is_assigned: employee.is_assigned || 0,
                        profile_image: employee.profile_image || "",
                        type: type,
                      });
                    });
                    break;
                  case "my_operator":
                    assigneeData[type].staff.forEach((employee) => {
                      assigneeOptions.push({
                        name: employee.title,
                        id: employee.staffid,
                        is_assigned: employee.is_assigned || 0,
                        profile_image: employee.image || "",
                        type: type,
                      });
                    });
                    break;
                  // case "my_operator":
                  //   assigneeData[type].staff.forEach((employee) => {
                  //     assigneeOptions.push({
                  //       name: employee.name,
                  //       id: employee.staffid,
                  //       is_assigned: employee.is_assigned || 0,
                  //       profile_image: employee.profile_image || "",
                  //       type: type
                  //     });
                  //   });
                  //   break;
                  case "equipments":
                    assigneeData[type].equipments.forEach((equipment) => {
                      assigneeOptions.push({
                        name: equipment.equipment_name,
                        id: equipment.equipment_id,
                        is_assigned: equipment.is_assigned || 0,
                        profile_image: equipment.profile_image || "",
                        subCategory: equipment.sub_category,
                        type: type,
                      });
                    });
                    break;
                  case "task_topic":
                    assigneeData[type].tasks.categories.forEach((proposal) => {
                      assigneeOptions.push({
                        name: proposal.title,
                        id: proposal.filter_key,
                        is_assigned: proposal.is_assigned || 0,
                        profile_image: proposal.image || "",
                        // is_tag: 1
                        type: type,
                      });
                    });
                    break;
                  case "myr_network":
                    assigneeData[type].providers.forEach((provider) => {
                      assigneeOptions.push({
                        name: provider.name,
                        id: provider.provider_id,
                        is_assigned: provider.is_assigned || 0,
                        profile_image: provider.profile_image || "",
                        type: type,
                      });
                    });
                    break;
                  case "providers":
                    if (
                      assigneeData[type].contacts ||
                      assigneeData[type].attendees
                    ) {
                      assigneeOptions.push({
                        name: "Selected Contacts",
                        id: 0,
                      });
                      assigneeData[type].attendees.forEach((attendees) => {
                        assigneeOptions.push({
                          name: attendees.name || attendees.email,
                          email: attendees.email,
                          id: attendees.id || 0,
                          is_assigned: attendees.is_assigned || 0,
                          profile_image: attendees.profileImage || "",
                        });
                      });
                      assigneeOptions.push({
                        name: "Suggested Contacts",
                        id: 0,
                      });
                      assigneeData[type].contacts.forEach((contacts) => {
                        assigneeOptions.push({
                          name: contacts.name || contacts.email,
                          id: contacts.id || 0,
                          email: contacts.email,
                          is_assigned: contacts.is_assigned || 0,
                          profile_image: contacts.profileImage || "",
                        });
                      });
                    }
                    break;
                  case "one_drive":
                    if (
                      jQuery.isArray(assigneeData[type].contacts) &&
                      jQuery.isArray(assigneeData[type].attendees)
                    ) {
                      assigneeOptions.push({
                        name: "Selected Contacts",
                        id: 0,
                      });
                      assigneeData[type].attendees.forEach((attendees) => {
                        assigneeOptions.push({
                          name: attendees.name || attendees.email,
                          email: attendees.email,
                          id: attendees.id || 0,
                          is_assigned: attendees.is_assigned || 0,
                          profile_image: attendees.profileImage || "",
                        });
                      });
                      assigneeOptions.push({
                        name: "Suggested Contacts",
                        id: 0,
                      });
                      assigneeData[type].contacts.forEach((contacts) => {
                        assigneeOptions.push({
                          name: contacts.name || contacts.email,
                          id: contacts.id || 0,
                          email: contacts.email,
                          is_assigned: contacts.is_assigned || 0,
                          profile_image: contacts.profileImage || "",
                        });
                      });
                    }
                    break;

                  default:
                    break;
                }
              }

              assigneeOptions = removeDuplicateObjectsFromArray(
                assigneeOptions,
                "id"
              );

              return (
                <>
                  <UncontrolledDropdown
                    key={index}
                    id={type}
                    isOpen={
                      openAssigneeDropdown &&
                      openSubmenuIndex[
                        openSubmenuIndex.length == 1 ? index - 1 : index
                      ]
                    }
                    setActiveFromChild={true}
                    direction="left"
                    className={`w-200 ${
                      (rel_type == "staff" &&
                        !isRequest &&
                        (assignDataKey.main_key == "my_contact" ||
                          assignDataKey.main_key == "providers")) ||
                      (rel_type == "contact" &&
                        ["my_operator", "my_staff", "myr_support", ""].includes(
                          assignDataKey.main_key
                        )) ||
                      (rel_type == "staff" &&
                        [
                          "equipments",
                          "my_operator",
                          "my_contact",
                          "providers",
                        ].includes(assignDataKey.main_key)) ||
                      (rel_type == "operator" &&
                        !["my_task", "my_operator", "equipments", "providers"].includes(
                          assignDataKey.main_key
                        ))
                        ? "d-none"
                        : ""
                    }`}
                  >
                    {/* ||
                      (!isRequest && rel_type == "contact" && assignDataKey.main_key == "providers") ? "d-none" : "" */}
                    <DropdownToggle
                      toggle="false"
                      className="w-100 custom-btn-dropdown-items"
                    >
                      <a
                        href="#/"
                        className={`hr_sepretr ${
                          direction == "left" ? "dropstart" : "dropend"
                        } w-100`}
                      >
                        <div
                          className="d-flex align-items-center text-truncate dropdown-item custom-assigneesMain "
                          type="button"
                          id="CurrentProviderDropdown"
                          data-bs-toggle="dropdown"
                          // data-bs-auto-close={`${dropDownToggle ? "outside" : "false"}`}
                          data-bs-auto-close="outside"
                          aria-expanded="false"
                          data-popper-placement="left-start"
                          data-assigneeMain={type}
                          onClick={() => {
                            // setDropDownToggle(true);
                            // handleAssigneSubmenu(type)
                            handleAssigneSubmenu(index);
                          }}
                        >
                          <div className="c-list-icon">
                            <div className="h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle">
                              <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle d-flex">
                                <Hash
                                  size={16}
                                  weight="light"
                                  className="c-icons"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="ps-2 w100minus40 text_wrap">
                            <div className="c-list-detail text-truncate ">
                              {(assigneeData[type] &&
                                _l(assigneeData[type].title)) ||
                                (assignDataKey && _l(assignDataKey.title))}
                            </div>
                          </div>
                          {(pageName == "" ||
                            isDefaultTopicsDataAccess.indexOf(pageName) ==
                              -1) &&
                          assigneeOptions.filter(
                            (assignee) => parseInt(assignee.is_assigned) > 0
                          ).length ? (
                            <span className="rounded-circle green-text badge with-bg d-flex h20w20 p-0">
                              <span className="m-auto p-0">
                                {
                                  assigneeOptions.filter(
                                    (assignee) =>
                                      parseInt(assignee.is_assigned) > 0
                                  ).length
                                }
                              </span>
                            </span>
                          ) : isDefaultTopicsDataAccess.indexOf(pageName) >
                              -1 &&
                            selectedIds.length &&
                            selectedIds.filter((select) => select.name == type)
                              .length > 0 ? (
                            assignDataKey.main_key == "provider_task" &&
                            isRequest ? (
                              assigneeOptions.map((ss) =>
                                ss.subCategory.length > 0 ? (
                                  <span className="rounded-circle green-text badge with-bg d-flex h20w20 p-0">
                                    <span className="m-auto p-0">{1}</span>
                                  </span>
                                ) : (
                                  <></>
                                )
                              )
                            ) : (
                              <span className="rounded-circle green-text badge with-bg d-flex h20w20 p-0">
                                <span className="m-auto p-0">
                                  {
                                    selectedIds.filter(
                                      (select) => select.name == type
                                    ).length
                                  }
                                </span>
                              </span>
                            )
                          ) : 
                            selectedIds.filter((select) => select.key == type)
                              .length ? (
                            <>
                              <span className="rounded-circle green-text badge with-bg d-flex h20w20 p-0">
                                <span className="m-auto p-0">
                                  {
                                    selectedIds.filter(
                                      (select) => select.key == type
                                    ).length
                                  }
                                </span>
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                          <div className="c-list-icon">
                            <CaretRight
                              size={14}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                        </div>
                      </a>
                    </DropdownToggle>
                    <AssigneeSubMenu
                      data={assigneeOptions}
                      type={type}
                      provider={provider}
                      handler={(type, itemId, item, isTag = "", sub) => {
                        if (
                          pageName != "" &&
                          isDefaultTopicsDataAccess.indexOf(pageName) > -1
                        ) {
                          handleSelectAssigneeNew(
                            type,
                            itemId,
                            item,
                            sub,
                            false
                          );
                        }
                      }}
                      searchAssignees={searchAssignees}
                      setSearchAssignees={setSearchAssignees}
                      selectedId={selectedIds}
                      action={action}
                      isRequest={isRequest}
                      openAssigneeDropdown={
                        openAssigneeDropdown && openSubmenuIndex[index]
                      }
                      taskId={taskId}
                    />
                  </UncontrolledDropdown>
                </>
              );
            }
          })}
          {bulkAction ? (
            <div className="d-flex justify-content-end p-2">
              <Button
                variant="primary"
                className="border w-100 p-1 text-center"
                onClick={() =>
                  actionStausChange(0, assignee_list, "set_assignee")
                }
                size="sm"
              >
                {_l("l_save")}
              </Button>
            </div>
          ) : (
            <></>
          )}
          {actionType == "task_from_chat" ||
          actionType == "request_from_chat" ? (
            <li className="hr_sepretr">
              <div className="dropdown-item d-flex justify-content-end no-hover">
                <Button
                  variant="primary"
                  size="sm"
                  className={`border w-100 p-1 text-center ${
                    actionBtnLoader ? "for-disabled" : ""
                  }`}
                  onClick={() => {
                    actionStausChange(
                      actionTypeId,
                      assignee_list,
                      actionType == "request_from_chat" ? 1 : 0
                    );
                  }}
                >
                  {actionBtnLoader
                    ? _l("l_please_wait")
                    : actionType == "request_from_chat"
                    ? _l("l_create_request")
                    : _l("l_create_task")}
                  {actionBtnLoader ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      className="ms-2"
                      aria-hidden="true"
                    />
                  ) : (
                    <></>
                  )}
                </Button>
              </div>
            </li>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </DropdownMenu>
  );
};

export default AddAssigneeMainDropdownNew;
