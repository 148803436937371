import React, { useEffect, useState } from 'react'
import { isLoadingData } from '../../hooks/loader_helper'
import CommanLoader from '../Loader/CommanLoader'
import { useDispatch, useSelector } from 'react-redux';
import { _l, formateDate, hideLeftPanelNew } from '../../hooks/utilities';
import { UncontrolledDropdown } from 'reactstrap';
import { DropdownItem, DropdownToggle } from 'react-bootstrap';
import UncontrolledGeneralListing from '../TaskModalComponents/UncontrolledGeneralListing';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import User_2 from "../../assets/images/default-image-placeholder.png";
import { commanoffcanvasisOpenfromCalendar, getMentionList, getTaskChecklist, leadModalToggle, setIndexForCalendarSelectedTask } from '../../actions/customer';
import { getLeadDetail } from '../../actions/leads-actions/leads-actions';
import CommanOffCanvas from '../Offcanvas/CommanOffCanvas';
import jquery, { isNumeric } from "jquery";
import CommanPlaceholder from '../Placeholder/CommanPlaceholder';
import $ from "jquery";
import CheckedDropdown from '../Dropdowns/CheckedDropdown';
import { ChatCircle, Image } from 'phosphor-react';
import CommentMinified from '../Comments/CommentMinified';
import documentsServices from '../../services/documents-services';
import ImageUploadDropdown from '../Dropdowns/ImageUploadDropdown';
import CommanCustomFullcalendar from '../CommanCustomFullcalendar';


const LeadCustomCalendar = ({ pageName,
  setselectedEventDate = () => { },
  selectStartDate,
  setDashboardStartDate=() => { },
  setDashboardEndDate = () => { },
}) => {
  const {
    calendarData,
    isDayGridDay,
    isDayGridMonth,
    isDayGridWeek,
    commanoffcanvasIsOpen,
    globalSearchString,
    appliedDaterangeFilter,
    daterangeFilterApplied,
    displayThreeDays,
    mentionList
  } = useSelector((state) => state.customer);

  const {
    leads: leadsData,
    leadDetails: leadDetailsData,
    totalCounts,
    myrStaffList,
    myrLeadSources,
    leadsFilters
  } = useSelector((state) => state.leadsReducer);

  const [calendarMonthData, setCalendarMonthData] = useState([]);
  const [calendarWeekData, setCalendarWeekData] = useState([]);
  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [taskcreateId, setTaskcreateId] = useState(0);
  const [events, setEvents] = useState([]);
  const [activeTaskID, setActiveTaskID] = useState(0);
  const [dayofWek, setdayofWek] = useState(0);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [taskId, setTaskId] = useState(0);
  const [task_type, setTask_type] = useState('lead');
  const [commonModalData, setCommonModalData] = useState();
  const [leadDetails, setLeadDetails] = useState({
    lead_id: 0,
    lead_profile: {},
    lead_contact: {},
    lead_attachments: {},
    lead_call_logs: {},
    lead_tracked_emails: {},
    lead_views_tracking: {},
  });
  const [selectedDateIndex, setSelectedDateIndex] = useState(0)
  const [dropdownToggle, setDropdownToggle] = useState(false);
  const [openCheckList, setOpenCheckList] = useState(false);
  const [commentsList, setCommentsList] = useState([]);
  const [tempTaskId, setTempTaskId] = useState(0);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [commentCount, setCommentCount] = useState(0);
  const [openAttachmentsDropdown, setOpenAttachmentsDropdown] = useState(false);
  const [attchamentCount, setAttchamentCount] = useState(0);


  const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
  const savedDate = localStorage.getItem("selectedDate");
  const contact_id = localStorage.getItem("contact_id");

  const dispatch = useDispatch();

  useEffect(() => {
    if (calendarData && isDayGridMonth == false) {
        setCalendarWeekData(calendarData);
        setstartDate(calendarData[0].date);
        setEndDate(calendarData[6].date);
    } else if(daterangeFilterApplied == false){
        const firstDayOfMonth = moment( todayDate).startOf('month');
        const lastDayOfMonth = moment(selectStartDate ? selectStartDate : todayDate ).endOf('month');
        const todayDate = new Date();
    }
}, [JSON.stringify(calendarData), JSON.stringify(savedDate)])

  useEffect(() => {
    if (isDayGridMonth) {
        const todayDate = new Date();
        const firstDayOfMonth = moment(selectStartDate ? selectStartDate : todayDate).startOf('month');
        const lastDayOfMonth = moment(selectStartDate ?selectStartDate : todayDate ).endOf('month');
        const monthDatesArray = [];

        let currentDate = firstDayOfMonth;
        while (currentDate.isSameOrBefore(lastDayOfMonth)) {
            monthDatesArray.push({ date: currentDate.format('YYYY-MM-DD') });
            currentDate.add(1, 'day');
        }
        setCalendarMonthData(monthDatesArray)
        const date = moment(monthDatesArray[0].date); // Thursday Feb 2015
        const dow = date.day();
        // setdayofWek( dow - 1)
        setdayofWek(dow == 0 ? dow + 6 : dow - 1)
        // if(daterangeFilterApplied == false){
        //     localStorage.setItem("fromDate", monthDatesArray[0].date)
        //     localStorage.setItem("toDate", monthDatesArray[monthDatesArray.length - 1].date)
        // }
        if(appliedDaterangeFilter != "all"){
        setstartDate(monthDatesArray[0].date);
        setEndDate(monthDatesArray[monthDatesArray.length - 1].date);
        }
    }

    }, [isDayGridMonth, selectStartDate])

    useEffect(() => {
    setCommonModalData(leadDetailsData);
    },[JSON.stringify(leadDetailsData)])
    useEffect(() => {
        if (leadDetailsData && leadDetailsData.lead_id > 0) {
        setLeadDetails({ ...leadDetails, ...leadDetailsData });
        if (!showTaskModal) {
            setShowTaskModal(true);
        }
        }
    }, [JSON.stringify(leadDetailsData)]);

  useEffect(() => {
  if (leadsData.length > 0) {
      var templeadsData = [];
      leadsData.map((eItem) => {
        eItem.lead_appointments.map((appo) => {
          if (appo.date == null) {
            templeadsData.push({...eItem, startdate : savedDate});
          }else{
            templeadsData.push({...eItem, startdate : appo.date});
          }
        })
    });

      let validDateData = templeadsData.sort((a, b) => {
        let event_dateA = "";
        if (a.startdate != null) {
            event_dateA = moment(a.startdate).format("hh:mm A");
        }
        let event_dateB = "";
        if (b.startdate != null) {
            event_dateB = moment(b.startdate).format("hh:mm A");
        }
        // return moment(event_dateA, 'hh:mm A').diff(moment(event_dateB, 'hh:mm A'));
        return event_dateA.localeCompare(event_dateB) ;

    })
    setEvents(validDateData);
    }   

  }, [JSON.stringify(leadsData), isDayGridWeek, isDayGridMonth,isDayGridDay])

  const findIndexOfselectedTask = (date) => {
    let index = calendarWeekData.findIndex((week)=>week.date === date)
    
    switch (index) {
        case 0:
            setSelectedDateIndex(1);
            dispatch(setIndexForCalendarSelectedTask(1));
            break;
        case 6:
            setSelectedDateIndex(5);
            dispatch(setIndexForCalendarSelectedTask(5))                
            break;
        default:
            setSelectedDateIndex(index);
            dispatch(setIndexForCalendarSelectedTask(index));
            break;
    }
  }

  const getMentionlist = (id) => {
    dispatch(getMentionList(id, "lead"));
  };

  const getTaskComments = async (taskId) => {
    setTempTaskId(taskId)
    setCommentsLoading(true);
    setShowComments(true)
    try {
        const comments = await documentsServices.getComments(taskId, 'task', undefined, undefined, 1 );
        if (comments.data) {
            setCommentsList(comments.data.map((comment) => {
                return {
                    id: comment.id,
                    content: comment.content,
                    contact_id: comment.contact_id,
                    staffid: comment.staffid,
                    full_name: comment.contact_full_name,
                    profile_url: comment.addedfrom_details.profile_url,
                    attachments: comment.attachments,
                    date: comment.time,
                    is_allowed_to_edit: comment.is_allowed_to_edit
                }
            }));
        }
        // if (commentUnreadCount > 0) {
        //     dispatch(setTaskUnreadCmtCntAll(Number(taskUnreadCmtCntAll) - Number(commentUnreadCount)))
        //     if (localStorage.getItem('selectedVirtualSpace') && JSON.parse(localStorage.getItem('selectedVirtualSpace'))) {
        //         dispatch(setVirtualSpaceNotificationCount(projectId, commentUnreadCount, -1));
        //     }
        //     else {
        //         dispatch(setSpaceNotificationCount(projectId, commentUnreadCount, -1));
        //     }
        //     setCommentUnreadCount(0);
        //     // let taskListNew = taskList;
        //     // let index = taskListNew.findIndex((d) => d.id == taskId);
        //     // if (index > -1){
        //     //   taskListNew[index].unread_comment_count = 0;
        //     // }
        //     // dispatch(updateUnreadChatCountTasklist(taskListNew));
        // }
    }
    catch (e) {
        console.error(e);
    }
    finally {
        setCommentsLoading(false);
    }
  }
  
  const handleAddEditComment = async (content, files, id) => {
  try {
      const response = await documentsServices.addDocumentComment('task', tempTaskId, content, 0, id, files);
      if (id > 0) {
          const commentIndex = commentsList.findIndex(w => w.id == id);
          setCommentsList(Object.values({ ...commentsList, [commentIndex]: { ...commentsList[commentIndex], content } }));
      }
      else {
          const staffid = localStorage.getItem("staff_id");
          const profile_url = localStorage.getItem("contact_image");
          const full_name = localStorage.getItem("full_name");
          let attachments = [];

          if (files) {
              Array.from(files).map((file) => {
                  attachments.push({
                      file_url: URL.createObjectURL(file),
                      file_name: file.name
                  })
              });
          }

          setCommentsList(commentsList.concat([
              {
                  id: response.data.comment_id,
                  content: content,
                  contact_id: contact_id,
                  staffid: staffid,
                  full_name: full_name,
                  profile_url: profile_url,
                  attachments: attachments,
                  date: response.data.time,
                  dateadded:new Date()
              }
          ]));
      }
        let index = events.findIndex((task)=>task.id == tempTaskId)
        let temptaskList = events[index]
       temptaskList.comments_count = (commentsList.length + 1)
       setEvents(
        Object.values({...events, [index] : temptaskList})
       )
  }
  catch (e) {
      console.error(e);
  }
  };
  const handleDeleteComment = (comment) => {
    try {
        const response = documentsServices.removeComment(taskId, 'task', comment.id);
        setCommentsList(commentsList.filter(x => x.id != comment.id));
        setCommentCount(commentsList.length - 1);
    }
    catch (e) {
        console.error(e);
    }
  }


  return (
    <>
    <React.Fragment>
            <CommanCustomFullcalendar 
                type_calendar = {"lead"}
                daysOfWeek = {daysOfWeek}
                calendarWeekData = {calendarWeekData}
                calendarMonthData = {calendarMonthData}
                dayofWek = {dayofWek}
                events = {events}
                activeTaskID = {activeTaskID}
                dropdownToggle = {dropdownToggle}
                selectedDateIndex = {selectedDateIndex}
                commonModalData = {leadDetails}
                OpenCommanOffCanvasModal = {(event, dates, index) => {
                    if (isDayGridMonth) {
                            dispatch(commanoffcanvasisOpenfromCalendar(true));
                            jquery("body").removeClass("with-left-penel");
                            jquery("body").addClass("pb-0 right-penel-no-padding modal-open with-right-penel task-modal-offcanvas-open")
                            if (!commanoffcanvasIsOpen) {
                            setTaskId(event.id);
                            setActiveTaskID(index);
                            setTaskcreateId(event.id);
                            dispatch(getLeadDetail(event.id, "lead_profile"));                                                      
                            setShowTaskModal(true);
                            setselectedEventDate(dates.date)
                        }
                    }else if (isDayGridWeek) {
                            dispatch(commanoffcanvasisOpenfromCalendar(true))
                            jquery("body").removeClass("with-left-penel");
                            jquery("body").addClass("pb-0 right-penel-no-padding modal-open with-right-penel task-modal-offcanvas-open")
                            if (!commanoffcanvasIsOpen) {
                            setTaskId(event.id);
                            setActiveTaskID(index);
                            setTaskcreateId(event.id);
                            findIndexOfselectedTask(dates.date)
                            setselectedEventDate(dates.date)
                            setShowTaskModal(true);
                            dispatch(getLeadDetail(event.id, "lead_profile"));
                        }
                    }else if (isDayGridDay) {
                            dispatch(commanoffcanvasisOpenfromCalendar(true))
                            jquery("body").removeClass("with-left-penel");
                            jquery("body").addClass("pb-0 right-penel-no-padding modal-open with-right-penel task-modal-offcanvas-open")
                            if (!commanoffcanvasIsOpen) {
                            setActiveTaskID(index)
                            setTaskcreateId(event.id);
                            setselectedEventDate(dates.date)
                            setShowTaskModal(true);
                            dispatch(getLeadDetail(event.id, "lead_profile"));
                        }
                    }
                }}
            />
        {
                showTaskModal ? <CommanOffCanvas
                    show={showTaskModal}
                    handleClose={() => {
                        setShowTaskModal(false);
                        dispatch(getLeadDetail("", "", 1));
                        dispatch(commanoffcanvasisOpenfromCalendar(false));
                        setTaskId(0);
                        setTask_type('')
                    }}
                    data={leadDetails}
                    pageName={"calendar"}
                    docType={task_type}
                    setSelectedId={setTaskId}
                    selectedTaskId={taskId}
                    IsKanbanView={false}
                    canvasfullHeightwithHeaderFooter={true}
                    setActiveTaskID={setActiveTaskID}
                    isFromCalendar = {true}
                /> : <></>
            }
        </React.Fragment>    
    
    </>
  )
}

export default LeadCustomCalendar