import React, { useEffect, useState } from "react";
import { _l, getDatePickerLocale } from "../../hooks/utilities";
import "react-datepicker/dist/react-datepicker.css";
import { DropdownMenu, DropdownItem } from 'reactstrap';
import { Hash, X } from "phosphor-react";
import { getTaskCount, isPinnedDelayFilter, leftPanelFilters, projectTaskCounts } from "../../actions/customer";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

const HeaderFilterDropdown = ({ widthClass, unControlList = false ,list = [], projectId, pageName, isLeadPage, removeStatusFilter}) => {
  const dispatch = useDispatch();
  const {
    taskFilters,
    activeTaskStatusFilters,
    pinDelayfilter
  } = useSelector((state) => state.customer);


  const HeaderFilterDropdownContain = (<>
  {list && list.map((value)=>{return(<>
    <li className="hr_sepretr">
      <div className="d-flex align-items-center text-truncate  dropdown-item">
        <div className="align-items-center d-flex w100minus20 flex-grow-1">
          
            
            <div className="h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle">
            {value.image && value.main_key != "my_task" ?
            <div
            className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
            style={{
              backgroundImage: `url('${value.image}')`,
            }}>
          </div>  :
              <Hash size={18} weight="light" className="c-icons" /> }
            </div>
          
          <div className="ps-2 w100minus30">
            <div className="c-list-detail text-truncate">{value.title}</div>
          </div>
        </div>
        {/* {pageName == "Liteversion" ? <></> :
        <a href="#/" className="d-none"
          onClick={() => {
            if(value.status == "pinned"){
              dispatch(isPinnedDelayFilter(pinDelayfilter.filter((f) => f != value.status)))
            }else if(value.status == "overdue"){
              dispatch(isPinnedDelayFilter(pinDelayfilter.filter((f) => f != value.status)))
            }else if(value.filter_key != "" || value.main_key != ""){
            dispatch(
              leftPanelFilters({
                main_key:
                  value.main_key,
                filter_key:
                  value.filter_key,
                title: value.title,
                main_sub_key: value.main_sub_key,
              })
            );

            dispatch(
              getTaskCount(
                0,
                0,
                localStorage.getItem(
                  "selectedOffice"
                ),
                moment(
                  localStorage.getItem(
                    "selectedDate"
                  )
                ).format(
                  "YYYY-MM-DD"
                ),
                taskFilters,
                "",
                activeTaskStatusFilters,
                "remove",
                value.filter_key,
                isLeadPage,
                pageName
              )
            );
            dispatch(
              projectTaskCounts(
                projectId,
                taskFilters,
                {
                  main_key:
                    value.main_key,
                  filter_key:
                    value.filter_key,
                  title: value.title,
                },
                isLeadPage,
                pageName
              )
            );
            window.dispatchEvent(
              new Event(
                "filter_key_close"
              )
            );
          }else {
            removeStatusFilter(value.status);
            // removeStatusFilter({flag: true, status : value.title == "Done" ? 5 :value.title == "In progress" ? 4 : value.title == "Not started" ? 1 : 0})
          }
            
          }}
          >
          <X size={14} weight="light" className="c-icons" />
        </a>} */}
      </div>
    </li>
  </>)})}
  </>)
  return (
    <>
      {
        !unControlList ? (
          <DropdownMenu container="body" direction="down" className={`dropdown-menu w-170 overflowscroll ${widthClass}`} aria-labelledby="HeaderFilterDropdown">{HeaderFilterDropdownContain}</DropdownMenu>
        ) : (
          <ul className={`dropdown-menu w-170 overflowscroll ${widthClass}`} aria-labelledby="HeaderFilterDropdown">{HeaderFilterDropdownContain}</ul>
        )
      }
    </>
  );
};

export default HeaderFilterDropdown;
