import React, { useEffect, useState, useRef } from "react";
import HighPriorityIcon from "../../../assets/icons/HighPriorityIcon";
import HighPriorityActiveIcon from "../../../assets/icons/HighPriorityActiveIcon";
import User_8 from "../../../assets/images/users/user_8.jpg";
import User_7 from "../../../assets/images/users/user_7.jpg";
import { UserCircleGear, ChatCircle, DotsThreeVertical, Buildings, PushPin, CalendarBlank } from "phosphor-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { _l } from "../../../hooks/utilities";
import Form from 'react-bootstrap/Form';
import TaskListStatusDropdown from "../../Dropdowns/TaskListStatusDropdown";
import UncontrolledGeneralListing from "../../TaskModalComponents/UncontrolledGeneralListing";
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import AddAssigneeMainDropdown from "../../TaskModalComponents/AddAssigneeMainDropdown";


const WalletOffcanvasListCard = ({ data }) => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <div>
      <div className="task-list-card list-view" >
        <div className="task-list-wrapper">
          <div className="w-100">
            <div className="d-flex task-list-detail align-items-start">
              <div className="task-left flex-grow-1 width100minus160 align-items-start">
                <Form.Check
                  type="checkbox"
                  className="d-flex me-3" />
                <div className="d-flex align-items-center w-100 task-left-part-toggle text-truncate flex-wrap">
                  <div className="task-top-part flex-grow-1 main-calc-width-task d-flex text-truncate w-100">
                    <div className="d-flex align-items-center w-100">
                      <div className="task-creator-img">
                        <div className="comman-image-box h25w25 rounded-circle">
                          <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{
                            backgroundImage: `url('https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80')`,
                          }}></div>
                        </div>
                      </div>
                      <a href="#/"
                        className="task-list-name text-truncate"
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                        data-place="top"
                      >
                        <span className="me-2">Wipe down and disinfect countertops</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center task-bottom-part-full">
                  <div className="task-right-static-options">
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center justify-content-end table-list-attechment">
                        <div className="task-mid-part w-460 d-flex res-flex-wrap-nowrap align-items-center  for-content-between comman_action_icon justify-content-end">
                          <div className="d-flex align-items-center justify-content-end res-space-start res-width-100">
                            <div className="c-font f-12 fw-semibold badge radius_3 me-2">
                              <span className="color-green">€ 50.00</span>
                            </div>
                            <div className="pill-min-width pill-bottom-show  text-end">
                              <div className="d-flex align-items-center">
                                <a href="#/" className="badge  badge-white rounded-pill  me-2"><span className=" p-0">{_l("l_task")}</span></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="task-assign-section">
                <div className="d-flex align-items-center justify-content-end">
                  <a href="#/" className="comman_action_icon w-100 d-flex pe-1" >
                    <div className="action_icon with_bg on-hover-active-toggle-img position-relative">
                      <PushPin size={18} className="c-icons" weight="light" />
                      <PushPin size={18} className="c-icons d-none" weight="fill" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center w100minus40 ms-auto mt-2">
              <div className="white-border-box p-0 me-2 d-flex">
                <a href="#/" className="custom-datepicker datepicker-input w-90 m-auto ms-1 c-font">
                  <div className="d-flex align-items-center">
                    <div className="h20w20"><CalendarBlank size={18} weight="light" className="c-icons" /></div>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                      }}
                      placeholderText={`${_l(
                        "l_select_date"
                      )}`}
                      calendarStartDay={1}
                    ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                  </div>
                </a>
              </div>
              <div className="comman_action_icon pe-2 card-view-less-padding">
                <div className="white-border-box color-white-60 action_icon with_bg  dropdown d-flex" type="button" 
                id="TaskListStatusDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-tip={`${_l("l_task_status")}`}
                data-effect="solid"
                data-delay-show='1000'
                data-class="tooltip-main"
                >
                 <div className="d-flex align-items-center m-auto">
                    <span className="c-dots c-10 in-progress-white rounded-circle c-dots c-10 in-progress-red rounded-circle"></span>
                        <TaskListStatusDropdown/>
                    </div>
                </div>
              </div>
              <div className="comman_action_icon pe-2 card-view-less-padding">
                <div className="priority-checkbox span-13 white-border-box action_icon with_bg  ">
                  <input type="checkbox" id="priority" className="absolute-input" checked="" />
                  <div className="check_box">

                    <HighPriorityActiveIcon className="HW18 check_span_img  active" />

                    <HighPriorityIcon className="HW18 check_span_img inactive HW18 check_span_img inactive" />

                  </div>
                </div>
              </div>
              <div
                className="dropdown progress-wrapper-minus d-flex flex-grow-1 me-2"
              >
                <a href="#/"
                  className="progress-wrapper-link flex-grow-1"
                >
                  <div className="progress-slider with_separator_10 before-dnone card-view-less-padding">
                    <div
                      className="d-flex align-items-center"
                    >
                      <div className="progress w_100">
                        <div
                          className="progress-bar"
                          style={{ width: `60%` }}
                        ></div>
                      </div>
                      <div className="progress-text c-font f-12 title-fonts">
                        60%
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="multi_image_wrapper dropdown">
                <div className="multi_inner_wrapper d-flex position-relative pe-2">
                  <a href="#/" className="comman-image-box h20w20 rounded-circle multi-wrapper-border">
                    <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url('${User_7}')` }} >

                    </div>
                  </a>
                  <a href="#/" className="comman-image-box h20w20 rounded-circle multi-wrapper-border">
                    <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url('${User_8}')` }} >

                    </div>
                  </a>

                  <UncontrolledDropdown>
                          <DropdownToggle tag="a" className="comman-image-box h20w20 with_overlay d-flex rounded-circle dropdown team-list-dropdown" >
                          <div
                                className="comman-image-box h20w20 with_overlay d-flex rounded-circle"
                                type="button"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="outside"
                                aria-expanded="false"
                              >
                                <div className="unread_count m-auto">+5</div>
                              </div>
                              <UncontrolledGeneralListing list={[1, 2, 3].map((index) => {
                            return {
                                name: "User name",
                                image: 'https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
                                id: index,
                            };
                        })} />
                          </DropdownToggle>
                        </UncontrolledDropdown>

                  {/* <a href="#/"
                    className="comman-image-box h25w25 with_overlay d-flex rounded-circle"  type="button"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false">
                    <div className="unread_count m-auto">+5</div>
                  </a>
                  <GeneralListing list={[1, 2, 3].map((index) => {
                            return {
                                name: "User name",
                                image: 'https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
                                id: index,
                            };
                        })} /> */}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end table-list-attechment">
                <div className=" with_separator_10 d-flex align-items-center card-view-less-padding">
                  <a href="#/" className="comman_action_icon w-100 d-flex" >
                    <div className="d-flex align-items-center w-100">
                      <div className="action_icon with_bg on-hover-active-toggle-img position-relative"d="AddAssigneeMainDropdown"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    data-bs-auto-close="outside"
                                    type="button">
                        <UserCircleGear size={18} className="c-icons" weight="light" />
                      </div>
                      <AddAssigneeMainDropdown />
                    </div>
                  </a>
                </div>
                <div className=" with_separator_10 d-flex align-items-center card-view-less-padding ">
                  <a href="#/" className="comman_action_icon w-100 d-flex" >
                    <div className="d-flex align-items-center w-100">
                      <div className="action_icon with_bg position-relative on-hover-active-toggle-img">
                        <ChatCircle size={18} className="c-icons" weight="light" />
                      </div>
                      <span className="c-font f-12 opacity-50 d-flex justify-content-center flex-grow-1 text-on-hover-active-green">10</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WalletOffcanvasListCard;