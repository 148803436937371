import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { ChatCircle, Trash, Wrench } from "phosphor-react";
import "react-datepicker/dist/react-datepicker.css";
import {
  checklistItem,
  _l,
  getDatePickerLocale,
  showError,
} from "../../../hooks/utilities";
import ReactTooltip from "react-tooltip";
import HighPriorityIcon from "../../../assets/icons/HighPriorityIcon";
import HighPriorityActiveIcon from "../../../assets/icons/HighPriorityActiveIcon";
import ScheduleComments from "../../Comments/ScheduleComments";
import { useDispatch } from "react-redux";
import { showConfirmation, toastCloseAction } from "../../../actions/messages";
import moment from "moment";
import BenchmarkServices from "../../../services/benchmark-services";
import { FREQUENCIES, WEEK_DAYS } from "../../../constants/constants";
import CommanPlaceholder from "../../Placeholder/CommanPlaceholder";
import Form from "react-bootstrap/Form";
import { removeChecklistItemFromClipBoard } from "../../../actions/benchmark-actions/benchmark-actions";
import { useSearchParams } from "react-router-dom";
import UnControlMainFiltersDropDown from "../../TaskModalComponents/LeadsMainFiltersDropDown";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { setEquipmentConsumbleCheckList } from "../../../actions/customer";


const GeneralItemTable = ({
  checklists,
  setChecklists,
  room_id,
  setBulkActionsCheckboxes,
  bulkActionsCheckboxes,
  isReadOnly,
  room_title,
  readOnlyMode,
  deleteChecklistItem = () => {},
  type_of_space_item_id,
  bulkChecklistDelete = () => {},
  nonStickey
}) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  let ProviderType = searchParams.get("provider-type");



  const [tableHeaderZindex, setTableHeaderZindex] = useState(false);
  const [toggle, setToggle] = useState(false);
  let totalSlotsPerSpace = checklists.filter(
    (x) => x.room_id == room_id && x.description != ""
  );

  let sr_no = 0;
  const contact_id = localStorage.getItem("contact_id");
  const staff_id = localStorage.getItem("staff_id");
  const full_name = localStorage.getItem("full_name");
  const contact_image = localStorage.getItem("contact_image");
  const [euipmentModals, seteuipmentModals] = useState(-1)

  const emptySlots = () => {
    let isEmpty = false;
    checklists.forEach((checklist) => {
      if (
        (checklist.room_id == room_id &&
          checklist.type_of_space_item_id == type_of_space_item_id &&
          !checklist.description) ||
        (checklist.description && !checklist.description.trim().length)
      ) {
        isEmpty = true;
      }
    });
    return isEmpty;
  };

  const appendNewSlot = (e) => {
    e.preventDefault();
    if (!emptySlots()) {
      setChecklists(
        checklists.concat([
          {
            ...checklistItem,
            type_of_space_item_id,
            room_id: room_id,
            slot_title: room_title,
            slot:
              getLastChecklistSlot() || checklists[checklists.length - 1].slot,
            id: crypto.randomUUID(),
            type_of_space_items:
              checklists[checklists.length - 1].type_of_space_items,
              equipment_detail : [],
              total_equipment : 0
          },
        ])
      );
    } else {
      let emptyChecklistsOfSpace = checklists.filter(
        (x) =>
          x.room_id == room_id &&
          x.type_of_space_item_id == type_of_space_item_id &&
          (!x.description || x.description.trim().length == 0)
      );
      if (emptyChecklistsOfSpace.length <= 1) {
        return false;
      }
      let emptyChecklistIndex = checklists.findIndex(
        (x) =>
          x.room_id == room_id &&
          x.type_of_space_item_id == type_of_space_item_id &&
          (!x.description || x.description.trim().length == 0)
      );
      let newChecklistArray = checklists.slice();
      newChecklistArray.splice(emptyChecklistIndex, 1);
      setChecklists(newChecklistArray);
    }
  };

  const deleteChecklist = (index) => {
    let newArr = [...checklists];
    let checklistItem = newArr[index];
    if (checklists.filter((x, k) => k == index).length) {
      newArr = checklists.filter((c, key) => key != index);
    }
    if (checklistItem.id) {
      deleteChecklistItem(checklistItem);
    }
    dispatch(removeChecklistItemFromClipBoard(checklistItem));
    setChecklists(newArr);
  };

  const handleBulkselection = (checked, index, checklistItem) => {
    if (!checked) {
      if (index == -1) {
        setBulkActionsCheckboxes([]);
      } else {
        setBulkActionsCheckboxes(
          bulkActionsCheckboxes.filter(
            (x) =>
              x.description &&
              checklistItem.description &&
              x.description.trim().toLowerCase() !=
                checklistItem.description.trim().toLowerCase() &&
              x.type_of_space_item_id == type_of_space_item_id &&
              x.room_id == room_id
          )
        );
      }
    } else {
      if (index == -1) {
        const checklistItemsForSection = checklists.filter(
          (x) =>
            x.type_of_space_item_id == type_of_space_item_id &&
            x.room_id == room_id
        )
        setBulkActionsCheckboxes(checklistItemsForSection);
      } else {
        setBulkActionsCheckboxes(
          bulkActionsCheckboxes.concat(
            checklists.filter(
              (x) =>
                x.description &&
                checklistItem.description &&
                x.description.trim().toLowerCase() ==
                  checklistItem.description.trim().toLowerCase() &&
                x.type_of_space_item_id == type_of_space_item_id &&
                x.room_id == room_id
            )
          )
        );
      }
    }
  };

  const getLastChecklistSlot = () => {
    let lastChecklist = checklists.filter((check) => check.room_id == room_id);
    return lastChecklist.length
      ? lastChecklist[lastChecklist.length - 1].slot
      : new Date();
  };

  const addEditSlotComment = (
    checklistItemIndex,
    comment,
    files,
    commentIndex
  ) => {
    if (!comment && !files) {
      return;
    }
    let formattedComment = {
      id: 0,
      date: moment().fromNow(),
      attachments: files ? Array.from(files) : [],
      content: comment,
      contact_id,
      staff_id,
      full_name,
      profile_url: contact_image,
    };
    if (!checklists[checklistItemIndex].item_comments) {
      checklists[checklistItemIndex].item_comments = [];
    }
    if (checklists[checklistItemIndex].id > 0) {
      const commentId =
        commentIndex === -1
          ? 0
          : checklists[checklistItemIndex].item_comments[commentIndex].id;
      BenchmarkServices.addEditChecklistComment(
        comment,
        files,
        checklists[checklistItemIndex].id,
        commentId
      ).then((res) => {
        formattedComment.id = res.data.comment_id;
        formattedComment.attachments = res.data.files_list;
        if (commentIndex > -1) {
          setChecklists(
            Object.values({
              ...checklists,
              [checklistItemIndex]: {
                ...checklists[checklistItemIndex],
                item_comments: Object.values({
                  ...checklists[checklistItemIndex].item_comments,
                  [commentIndex]: formattedComment,
                }),
              },
            })
          );
        } else {
          setChecklists(
            Object.values({
              ...checklists,
              [checklistItemIndex]: {
                ...checklists[checklistItemIndex],
                item_comments: checklists[
                  checklistItemIndex
                ].item_comments.concat([formattedComment]),
              },
            })
          );
        }
      });
    } else {
      if (commentIndex > -1) {
        setChecklists(
          Object.values({
            ...checklists,
            [checklistItemIndex]: {
              ...checklists[checklistItemIndex],
              item_comments: Object.values({
                ...checklists[checklistItemIndex].item_comments,
                [commentIndex]: formattedComment,
              }),
            },
          })
        );
      } else {
        setChecklists(
          Object.values({
            ...checklists,
            [checklistItemIndex]: {
              ...checklists[checklistItemIndex],
              item_comments: checklists[
                checklistItemIndex
              ].item_comments.concat([formattedComment]),
            },
          })
        );
      }
    }
  };

  const confirmDeleteComment = (slotId, commentIndex) => {
    dispatch(
      showConfirmation(
        _l("l_are_you_sure"),
        _l("l_delete_comment"),
        _l("l_delete"),
        _l("l_cancel"),
        () => {},
        () => {
          deleteSlotCommnet(slotId, commentIndex);
          dispatch(toastCloseAction());
        }
      )
    );
  };

  const deleteSlotCommnet = (checklistItemIndex, commentIndex) => {
    if (checklists[checklistItemIndex].id > 0) {
      BenchmarkServices.deleteChecklistComment(
        checklists[checklistItemIndex].id,
        checklists[checklistItemIndex].item_comments[commentIndex].id
      ).then((res) => {
        setChecklists(
          Object.values({
            ...checklists,
            [checklistItemIndex]: {
              ...checklists[checklistItemIndex],
              item_comments: checklists[
                checklistItemIndex
              ].item_comments.filter((w, pos) => pos != commentIndex),
            },
          })
        );
      });
    } else {
      setChecklists(
        Object.values({
          ...checklists,
          [checklistItemIndex]: {
            ...checklists[checklistItemIndex],
            item_comments: checklists[checklistItemIndex].item_comments.filter(
              (w, pos) => pos != commentIndex
            ),
          },
        })
      );
    }
  };

  const handleCalendarClose = () => {
    setTableHeaderZindex(false);
  };

  const handleCalendarOpen = () => {
    setTableHeaderZindex(true);
  };

  const selectChecklistItemFrequency = (
    checklist,
    position,
    day_name,
    checked
  ) => {
    if (FREQUENCIES.includes(day_name) && checked) {
      setChecklists(
        Object.values({
          ...checklists,
          [position]: {
            ...checklist,
            days: {
              ...checklist.days,
              mon: false,
              tue: false,
              wed: false,
              thu: false,
              fri: false,
              sat: false,
              sun: false,
              [day_name]: checked,
            },
          },
        })
      );
    } else if (!FREQUENCIES.includes(day_name) && checked) {
      setChecklists(
        Object.values({
          ...checklists,
          [position]: {
            ...checklist,
            days: {
              ...checklist.days,
              monthly: false,
              yearly: false,
              half_yearly: false,
              yearly: false,
              [day_name]: checked,
            },
          },
        })
      );
    } else {
      setChecklists(
        Object.values({
          ...checklists,
          [position]: {
            ...checklist,
            days: {
              ...checklist.days,
              [day_name]: checked,
            },
          },
        })
      );
    }
  };

  const disableWeekdaysSelection = (days) => {
    let disabled = false;
    if (days) {
      Object.keys(days).forEach((day_name) => {
        if (FREQUENCIES.includes(day_name) && days[day_name]) {
          disabled = true;
        }
      });
    }
    return disabled;
  };

  const disableFrequencySelection = (days, currentFrequency) => {
    let disabled = false;
    if (days) {
      Object.keys(days).forEach((day_name) => {
        if (
          (WEEK_DAYS.includes(day_name) ||
            (FREQUENCIES.includes(day_name) &&
              day_name !== currentFrequency)) &&
          days[day_name]
        ) {
          disabled = true;
        }
      });
    }
    return disabled;
  };

  const bulkSelectionChecked = (day_name) => {
    let checkedDaysCount = 0;
    totalSlotsPerSpace.forEach((slot) => {
      if (slot.days && slot.days[day_name]) {
        checkedDaysCount++;
      }
    });
    return (
      totalSlotsPerSpace.length > 0 &&
      checkedDaysCount == totalSlotsPerSpace.length
    );
  };

  const bulkDeleteOperation = () => {
    if(bulkActionsCheckboxes && bulkActionsCheckboxes.length)
    {
      dispatch(showConfirmation(_l("l_are_you_sure"), _l("l_confirm_delete_selected_checklists"), _l("l_delete"), _l("l_cancel"), () => {}, () => {
        let checklistItemIds = [];
        bulkActionsCheckboxes.forEach((checklistItem) => {
          dispatch(removeChecklistItemFromClipBoard(checklistItem));
          setChecklists(checklists.filter((w) => bulkActionsCheckboxes.findIndex((z) => z.description != "" && w.description == z.description && w.room_id == z.room_id && w.type_of_space_item_id == z.type_of_space_item_id) == -1));
        });
        checklistItemIds = bulkActionsCheckboxes.filter((w) => w.id > 0).map((x) => x.id);
        if(checklistItemIds.length)
        {
          bulkChecklistDelete(checklistItemIds);
        }
        dispatch(toastCloseAction());
      }));
      setBulkActionsCheckboxes([]);
    }
    else {
      showError("l_select_items_to_delete")
    }
  };

  const bulkEnterChecklistOnPaste = (bulkChecklists, sampleChecklist,index) => {
    let itemsArray = bulkChecklists.split("\n");
    if(itemsArray && itemsArray.length)
    {
      let formattedChecklists = [];
      itemsArray.forEach((checklist) => {
        formattedChecklists.push({...sampleChecklist, description: checklist.trim(), is_new :  1, id : crypto.randomUUID() });
      });
      formattedChecklists.push({...sampleChecklist});
      setChecklists(checklists.concat(formattedChecklists));
    }
  }

  const addEuipmentchecklists = (item, euipment, index) => {
    let checklistsNew = checklists;

    let checklistItemIndex = checklistsNew.findIndex(checklist => checklist.id == item.id);
    let totalEquipments = [];
    let totalCount = Number(0);
    if (checklistItemIndex > -1) {
      let equipmentItem = checklistsNew[checklistItemIndex];
      let ckecklist = equipmentItem.equipment_quantity ? equipmentItem.equipment_quantity : [];

      euipment.map((item, index) => {
        if (item.count > 0) {
          let obj = {
            equipment_id: item.filter_key,
            equipment_quantity: Number(item.count)
          }
          totalCount = Number(totalCount) + Number(item.count)
          totalEquipments.push(obj);
        } else {
          let obj = {
            equipment_id: item.filter_key,
            equipment_quantity: Number(item.count)
          }
          totalCount = 0;
          totalEquipments.push(obj);

        }
      })
      let updatedChecklist = checklists[checklistItemIndex];
      updatedChecklist.equipment_detail = totalEquipments;
      updatedChecklist.total_equipment = totalCount;
      updatedChecklist.equipment_quantity = ckecklist.slice();

      setChecklists(
        Object.values({ ...checklists, [checklistItemIndex]: updatedChecklist })
      );
    }
  }

  useEffect(() => {
    let emptyChecklistsOfSpace = checklists.filter(
      (x) =>
        x.room_id == room_id &&
        x.type_of_space_item_id == type_of_space_item_id &&
        (!x.description || x.description.trim().length == 0)
    );
    if (emptyChecklistsOfSpace && emptyChecklistsOfSpace.length > 1) {
      let emptyChecklistIndex = checklists.findIndex(
        (x) =>
          x.room_id == room_id &&
          x.type_of_space_item_id == type_of_space_item_id &&
          (!x.description || x.description.trim().length == 0)
      );
      let newChecklistArray = checklists.slice();
      newChecklistArray.splice(emptyChecklistIndex, 1);
      setChecklists(newChecklistArray);
    }

  }, [JSON.stringify(checklists)])

  
  return (
    <>
      <div className="comman-data-table GeneralItemTable shadow-none">
        <table className="dataTable comman-table smaller-table">
          <thead
            className={`fw-normal c-font f-14 bg-white-03-solid ${
              tableHeaderZindex ? "z-index-5imp" : ""
            } ${nonStickey ? "" : ""}`}
          >
            <tr className={`p-5px bg-white-03 radius_3  ${ProviderType ? "" : "lighthtemebg"}`}>
              {!readOnlyMode ? (
                <th className="fw-normal py-2 text-center"
                style={{ width: "40px" }}>
                  <input
                    className="form-check-input "
                    type="checkbox"
                    checked={
                      checklists.filter(
                        (x) =>
                          x.room_id == room_id &&
                          x.type_of_space_item_id == type_of_space_item_id
                      ).length ==
                      bulkActionsCheckboxes.filter(
                        (x) =>
                          x.room_id == room_id &&
                          x.type_of_space_item_id == type_of_space_item_id
                      ).length
                    }
                    onChange={(e) => {
                      handleBulkselection(e.target.checked, -1);
                    }}
                    disabled={isReadOnly}
                  />
                </th>
              ) : (
                <></>
              )}
              <th className="fw-normal py-2" style={{ width: "2%" }}>
                #
              </th>
              <th className="fw-normal py-2" style={{ width: "26%" }}>
                {_l("l_item")}
              </th>
              <th className="fw-normal py-2 text-nowrap">
                <div className="c-input-box">
                  <span className="c-font color-white f-13">
                    {_l("l_photo")}
                  </span>
                </div>
              </th>
              <th className="fw-normal py-2 text-center">
                {_l("l_priority")}
              </th>
              <th className="fw-normal py-2 text-center">
                {_l("l_comments")}
              </th>
              <th className="fw-normal py-2 text-center">
                {_l("l_consumables")}
              </th>
              <th
                className="fw-normal py-2 text-center on-hover-hide-show-main"
                style={{ width: "80px" }}
              >
                <div className={`${readOnlyMode ? "" : "on-hover-hide"} `}>{_l("l_time_slots")}</div>
                <div className={`${readOnlyMode ? "d-none" : "on-hover-show" }`}>
                  <div
                    className={`white-border-box c-font f-12 p-0 text-center custom-datepicker max-width75 custom-timepicker h32wauto custom-timepicker ms-1 ${
                      readOnlyMode ? "d-none" : ""
                    }`}
                  >
                    <DatePicker
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      showTimeSelect
                      locale={getDatePickerLocale()}
                      className="text-center"
                      onChange={(date) => {
                        setChecklists(
                          checklists.map((x) => {
                            if (x.room_id == room_id && x.description != "") {
                              x.slot = date;
                            }
                            return x;
                          })
                        );
                      }}
                      onCalendarClose={handleCalendarClose}
                      onCalendarOpen={handleCalendarOpen}
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      placeholderText={`${_l("l_time_placeholder")}`}
                      dateFormat="h:mm aa"
                      readOnly={isReadOnly}
                      calendarStartDay={1}
                    />
                  </div>
                </div>
              </th>
              <th className="fw-normal py-2 text-center on-hover-hide-show-main">
                <th className={`d-flex flex-column p-0 ${readOnlyMode ? "" : "on-hover-hide"}`}>
                  <tr>{_l("l_week_single_letter_monday")}</tr>
                </th>
                <div className={`${readOnlyMode ? "d-none" : "on-hover-show" }`}>
                  <label for="" className={`${readOnlyMode ? "d-none" : ""}`}>
                    <input
                      className="form-check-input "
                      type="checkbox"
                      disabled={isReadOnly}
                      checked={bulkSelectionChecked("mon")}
                      onChange={(e) => {
                        setChecklists(
                          checklists.map((x) => {
                            if (
                              x.room_id == room_id &&
                              x.description != "" &&
                              !disableWeekdaysSelection(x.days)
                            ) {
                              x.days = { ...x.days, mon: e.target.checked };
                            }
                            return x;
                          })
                        );
                      }}
                    />
                  </label>
                </div>
              </th>
              <th className="fw-normal py-2 text-center on-hover-hide-show-main">
                <th className={`d-flex flex-column p-0 ${readOnlyMode ? "" : "on-hover-hide"}`}>
                  <tr>{_l("l_week_single_letter_tuesday")}</tr>
                </th>
                <div className={`${readOnlyMode ? "d-none" : "on-hover-show" }`}>
                  <label for="" className={`${readOnlyMode ? "d-none" : ""}`}>
                    <input
                      className="form-check-input "
                      type="checkbox"
                      disabled={isReadOnly}
                      checked={bulkSelectionChecked("tue")}
                      onChange={(e) => {
                        setChecklists(
                          checklists.map((x) => {
                            if (
                              x.room_id == room_id &&
                              x.description != "" &&
                              !disableWeekdaysSelection(x.days)
                            ) {
                              x.days = { ...x.days, tue: e.target.checked };
                            }
                            return x;
                          })
                        );
                      }}
                    />
                  </label>
                </div>
              </th>
              <th className="fw-normal py-2 text-center on-hover-hide-show-main">
                <th className={`d-flex flex-column p-0 ${readOnlyMode ? "" : "on-hover-hide"}`}>
                  <tr>{_l("l_week_single_letter_wednesday")}</tr>
                </th>
                <div className={`${readOnlyMode ? "d-none" : "on-hover-show" }`}>
                  <label for="" className={`${readOnlyMode ? "d-none" : ""}`}>
                    <input
                      className="form-check-input "
                      type="checkbox"
                      disabled={isReadOnly}
                      checked={bulkSelectionChecked("wed")}
                      onChange={(e) => {
                        setChecklists(
                          checklists.map((x) => {
                            if (
                              x.room_id == room_id &&
                              x.description != "" &&
                              !disableWeekdaysSelection(x.days)
                            ) {
                              x.days = { ...x.days, wed: e.target.checked };
                            }
                            return x;
                          })
                        );
                      }}
                    />
                  </label>
                </div>
              </th>
              <th className="fw-normal py-2 text-center on-hover-hide-show-main">
                <th className={`d-flex flex-column p-0 ${readOnlyMode ? "" : "on-hover-hide"}`}>
                  <tr>{_l("l_week_single_letter_thrusday")}</tr>
                </th>
                <div className={`${readOnlyMode ? "d-none" : "on-hover-show" }`}>
                  <label for="" className={`${readOnlyMode ? "d-none" : ""}`}>
                    <input
                      className="form-check-input "
                      type="checkbox"
                      disabled={isReadOnly}
                      checked={bulkSelectionChecked("thu")}
                      onChange={(e) => {
                        setChecklists(
                          checklists.map((x) => {
                            if (
                              x.room_id == room_id &&
                              x.description != "" &&
                              !disableWeekdaysSelection(x.days)
                            ) {
                              x.days = { ...x.days, thu: e.target.checked };
                            }
                            return x;
                          })
                        );
                      }}
                    />
                  </label>
                </div>
              </th>
              <th className="fw-normal py-2 text-center on-hover-hide-show-main">
                <th className={`d-flex flex-column p-0 ${readOnlyMode ? "" : "on-hover-hide"}`}>
                  <tr>{_l("l_week_single_letter_friday")}</tr>
                </th>
                <div className={`${readOnlyMode ? "d-none" : "on-hover-show" }`}>
                  <label for="" className={`${readOnlyMode ? "d-none" : ""}`}>
                    <input
                      className="form-check-input "
                      type="checkbox"
                      disabled={isReadOnly}
                      checked={bulkSelectionChecked("fri")}
                      onChange={(e) => {
                        setChecklists(
                          checklists.map((x) => {
                            if (
                              x.room_id == room_id &&
                              x.description != "" &&
                              !disableWeekdaysSelection(x.days)
                            ) {
                              x.days = { ...x.days, fri: e.target.checked };
                            }
                            return x;
                          })
                        );
                      }}
                    />
                  </label>
                </div>
              </th>
              <th className="fw-normal py-2 text-center on-hover-hide-show-main">
                <th className={`d-flex flex-column p-0 ${readOnlyMode ? "" : "on-hover-hide"}`}>
                  <tr>{_l("l_week_single_letter_saturday")}</tr>
                </th>
                <div className={`${readOnlyMode ? "d-none" : "on-hover-show" }`}>
                  <label for="" className={`${readOnlyMode ? "d-none" : ""}`}>
                    <input
                      className="form-check-input "
                      type="checkbox"
                      disabled={isReadOnly}
                      checked={bulkSelectionChecked("sat")}
                      onChange={(e) => {
                        setChecklists(
                          checklists.map((x) => {
                            if (
                              x.room_id == room_id &&
                              x.description != "" &&
                              !disableWeekdaysSelection(x.days)
                            ) {
                              x.days = { ...x.days, sat: e.target.checked };
                            }
                            return x;
                          })
                        );
                      }}
                    />
                  </label>
                </div>
              </th>
              <th className="fw-normal py-2 text-center on-hover-hide-show-main">
                <th className={`d-flex flex-column p-0 ${readOnlyMode ? "" : "on-hover-hide"}`}>
                  <tr>{_l("l_week_single_letter_sunday")}</tr>
                </th>
                <div className={`${readOnlyMode ? "d-none" : "on-hover-show" }`}>
                  <label for="" className={`${readOnlyMode ? "d-none" : ""}`}>
                    <input
                      className="form-check-input "
                      type="checkbox"
                      disabled={isReadOnly}
                      checked={bulkSelectionChecked("sun")}
                      onChange={(e) => {
                        setChecklists(
                          checklists.map((x) => {
                            if (
                              x.room_id == room_id &&
                              x.description != "" &&
                              !disableWeekdaysSelection(x.days)
                            ) {
                              x.days = { ...x.days, sun: e.target.checked };
                            }
                            return x;
                          })
                        );
                      }}
                    />
                  </label>
                </div>
              </th>
              <th className="fw-normal py-2 text-center"></th>
              <th className="fw-normal py-2 text-center on-hover-hide-show-main">
                <th className={`d-flex flex-column p-0 ${readOnlyMode ? "" : "on-hover-hide"}`}>
                  <tr>{_l("l_single_letter_monthly")}</tr>
                </th>
                <div className={`${readOnlyMode ? "d-none" : "on-hover-show" }`}>
                  <label for="" className={`${readOnlyMode ? "d-none" : ""}`}>
                    <input
                      className="form-check-input "
                      type="checkbox"
                      disabled={isReadOnly}
                      checked={bulkSelectionChecked("monthly")}
                      onChange={(e) => {
                        setChecklists(
                          checklists.map((x) => {
                            if (
                              x.room_id == room_id &&
                              x.description != "" &&
                              !disableFrequencySelection(x.days, "monthly")
                            ) {
                              x.days = {
                                ...x.days,
                                monthly: e.target.checked,
                              };
                            }
                            return x;
                          })
                        );
                      }}
                    />
                  </label>
                </div>
              </th>
              <th className="fw-normal py-2 text-center on-hover-hide-show-main">
                <th className={`d-flex flex-column p-0 ${readOnlyMode ? "" : "on-hover-hide"}`}>
                  <tr>{_l("l_single_letter_quaterly")}</tr>
                </th>
                <div className={`${readOnlyMode ? "d-none" : "on-hover-show" }`}>
                  <label for="" className={`${readOnlyMode ? "d-none" : ""}`}>
                    <input
                      className="form-check-input "
                      type="checkbox"
                      disabled={isReadOnly}
                      checked={bulkSelectionChecked("quarterly")}
                      onChange={(e) => {
                        setChecklists(
                          checklists.map((x) => {
                            if (
                              x.room_id == room_id &&
                              x.description != "" &&
                              !disableFrequencySelection(x.days, "quarterly")
                            ) {
                              x.days = {
                                ...x.days,
                                quarterly: e.target.checked,
                              };
                            }
                            return x;
                          })
                        );
                      }}
                    />
                  </label>
                </div>
              </th>
              <th className="fw-normal py-2 text-center on-hover-hide-show-main">
                <th className={`d-flex flex-column p-0 ${readOnlyMode ? "" : "on-hover-hide"}`}>
                  <tr>{_l("l_single_letter_semester")}</tr>
                </th>
                <div className={`${readOnlyMode ? "d-none" : "on-hover-show" }`}>
                  <label for="" className={`${readOnlyMode ? "d-none" : ""}`}>
                    <input
                      className="form-check-input "
                      type="checkbox"
                      disabled={isReadOnly}
                      checked={bulkSelectionChecked("half_yearly")}
                      onChange={(e) => {
                        setChecklists(
                          checklists.map((x) => {
                            if (
                              x.room_id == room_id &&
                              x.description != "" &&
                              !disableFrequencySelection(
                                x.days,
                                "half_yearly"
                              )
                            ) {
                              x.days = {
                                ...x.days,
                                half_yearly: e.target.checked,
                              };
                            }
                            return x;
                          })
                        );
                      }}
                    />
                  </label>
                </div>
              </th>
              <th className="fw-normal py-2 text-center on-hover-hide-show-main">
                <th className={`d-flex flex-column p-0 ${readOnlyMode ? "" : "on-hover-hide"}`}>
                  <tr>{_l("l_single_letter_yearly")}</tr>
                </th>
                <div className={`${readOnlyMode ? "d-none" : "on-hover-show" }`}>
                  <label for="" className={`${readOnlyMode ? "d-none" : ""}`}>
                    <input
                      className="form-check-input "
                      type="checkbox"
                      disabled={isReadOnly}
                      checked={bulkSelectionChecked("yearly")}
                      onChange={(e) => {
                        setChecklists(
                          checklists.map((x) => {
                            if (
                              x.room_id == room_id &&
                              x.description != "" &&
                              !disableFrequencySelection(x.days, "yearly")
                            ) {
                              x.days = {
                                ...x.days,
                                yearly: e.target.checked,
                              };
                            }
                            return x;
                          })
                        );
                      }}
                    />
                  </label>
                </div>
              </th>
              <th className="fw-normal py-2 align-middle text-center"
              style={{ width: "40px" }}>
              <div
                  className={`text-center comman_action_icon ${
                    readOnlyMode ? "d-none" : ""
                  }`}
                >
                  <a className="action_icon with_bg h32w32 no-hover m-auto"
                    onClick={() => {
                      bulkDeleteOperation()
                    }}
                  >
                    <Trash size={16} className="c-icons" weight="light" />
                  </a>
                </div>
              </th>
            </tr>
            <tr className="d-none">
              {!readOnlyMode ? (
                <th className="fw-normal pb-2 pt-0 text-center align-top">
                 
                </th>
              ) : (
                <></>
              )}
              <th className="fw-normal pb-2 pt-0 align-top" style={{ width: "2%" }}>
                
              </th>
              <th className="fw-normal pb-2 pt-0 align-top" style={{ width: "26%" }}>
                
              </th>
              <th className="fw-normal pb-2 pt-0 align-top text-nowrap">
                
              </th>
              <th className="fw-normal pb-2 pt-0 align-top text-center">
                
              </th>
              <th className="fw-normal pb-2 pt-0 align-top text-center">
               
              </th>
              <th
                className="fw-normal pb-2 pt-0 align-top text-center"
                style={{ width: "80px" }}
              >
                <div>
                  <div
                    className={`white-border-box c-font f-12 p-0 text-center custom-datepicker max-width75 custom-timepicker h32wauto custom-timepicker ${
                      readOnlyMode ? "d-none" : ""
                    }`}
                  >
                    <DatePicker
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      showTimeSelect
                      locale={getDatePickerLocale()}
                      className="text-center"
                      onChange={(date) => {
                        setChecklists(
                          checklists.map((x) => {
                            if (x.room_id == room_id && x.description != "") {
                              x.slot = date;
                            }
                            return x;
                          })
                        );
                      }}
                      onCalendarClose={handleCalendarClose}
                      onCalendarOpen={handleCalendarOpen}
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      placeholderText={`${_l("l_time_placeholder")}`}
                      dateFormat="h:mm aa"
                      readOnly={isReadOnly}
                      calendarStartDay={1}
                    />
                  </div>
                </div>
              </th>
              <th className="fw-normal pb-2 pt-0 align-top text-center">
                <th className="d-flex flex-column p-0">
                  <tr>
                    <label for="" className={`${readOnlyMode ? "d-none" : ""}`}>
                      <input
                        className="form-check-input "
                        type="checkbox"
                        disabled={isReadOnly}
                        checked={bulkSelectionChecked("mon")}
                        onChange={(e) => {
                          setChecklists(
                            checklists.map((x) => {
                              if (
                                x.room_id == room_id &&
                                x.description != "" &&
                                !disableWeekdaysSelection(x.days)
                              ) {
                                x.days = { ...x.days, mon: e.target.checked };
                              }
                              return x;
                            })
                          );
                        }}
                      />
                    </label>
                  </tr>
                </th>
              </th>
              <th className="fw-normal pb-2 pt-0 align-top text-center">
                <th className="d-flex flex-column p-0">
                  <tr>
                    <label for="" className={`${readOnlyMode ? "d-none" : ""}`}>
                      <input
                        className="form-check-input "
                        type="checkbox"
                        disabled={isReadOnly}
                        checked={bulkSelectionChecked("tue")}
                        onChange={(e) => {
                          setChecklists(
                            checklists.map((x) => {
                              if (
                                x.room_id == room_id &&
                                x.description != "" &&
                                !disableWeekdaysSelection(x.days)
                              ) {
                                x.days = { ...x.days, tue: e.target.checked };
                              }
                              return x;
                            })
                          );
                        }}
                      />
                    </label>
                  </tr>
                </th>
              </th>
              <th className="fw-normal pb-2 pt-0 align-top text-center">
                <th className="d-flex flex-column p-0">
                  <tr>
                    <label for="" className={`${readOnlyMode ? "d-none" : ""}`}>
                      <input
                        className="form-check-input "
                        type="checkbox"
                        disabled={isReadOnly}
                        checked={bulkSelectionChecked("wed")}
                        onChange={(e) => {
                          setChecklists(
                            checklists.map((x) => {
                              if (
                                x.room_id == room_id &&
                                x.description != "" &&
                                !disableWeekdaysSelection(x.days)
                              ) {
                                x.days = { ...x.days, wed: e.target.checked };
                              }
                              return x;
                            })
                          );
                        }}
                      />
                    </label>
                  </tr>
                </th>
              </th>
              <th className="fw-normal pb-2 pt-0 align-top text-center">
                <th className="d-flex flex-column p-0">
                  <tr>
                    <label for="" className={`${readOnlyMode ? "d-none" : ""}`}>
                      <input
                        className="form-check-input "
                        type="checkbox"
                        disabled={isReadOnly}
                        checked={bulkSelectionChecked("thu")}
                        onChange={(e) => {
                          setChecklists(
                            checklists.map((x) => {
                              if (
                                x.room_id == room_id &&
                                x.description != "" &&
                                !disableWeekdaysSelection(x.days)
                              ) {
                                x.days = { ...x.days, thu: e.target.checked };
                              }
                              return x;
                            })
                          );
                        }}
                      />
                    </label>
                  </tr>
                </th>
              </th>
              <th className="fw-normal pb-2 pt-0 align-top text-center">
                <th className="d-flex flex-column p-0">
                  <tr>
                    <label for="" className={`${readOnlyMode ? "d-none" : ""}`}>
                      <input
                        className="form-check-input "
                        type="checkbox"
                        disabled={isReadOnly}
                        checked={bulkSelectionChecked("fri")}
                        onChange={(e) => {
                          setChecklists(
                            checklists.map((x) => {
                              if (
                                x.room_id == room_id &&
                                x.description != "" &&
                                !disableWeekdaysSelection(x.days)
                              ) {
                                x.days = { ...x.days, fri: e.target.checked };
                              }
                              return x;
                            })
                          );
                        }}
                      />
                    </label>
                  </tr>
                </th>
              </th>
              <th className="fw-normal pb-2 pt-0 align-top text-center">
                <th className="d-flex flex-column p-0">
                  <tr>
                    <label for="" className={`${readOnlyMode ? "d-none" : ""}`}>
                      <input
                        className="form-check-input "
                        type="checkbox"
                        disabled={isReadOnly}
                        checked={bulkSelectionChecked("sat")}
                        onChange={(e) => {
                          setChecklists(
                            checklists.map((x) => {
                              if (
                                x.room_id == room_id &&
                                x.description != "" &&
                                !disableWeekdaysSelection(x.days)
                              ) {
                                x.days = { ...x.days, sat: e.target.checked };
                              }
                              return x;
                            })
                          );
                        }}
                      />
                    </label>
                  </tr>
                </th>
              </th>
              <th className="fw-normal pb-2 pt-0 align-top text-center">
                <th className="d-flex flex-column p-0">
                  <tr>
                    <label for="" className={`${readOnlyMode ? "d-none" : ""}`}>
                      <input
                        className="form-check-input "
                        type="checkbox"
                        disabled={isReadOnly}
                        checked={bulkSelectionChecked("sun")}
                        onChange={(e) => {
                          setChecklists(
                            checklists.map((x) => {
                              if (
                                x.room_id == room_id &&
                                x.description != "" &&
                                !disableWeekdaysSelection(x.days)
                              ) {
                                x.days = { ...x.days, sun: e.target.checked };
                              }
                              return x;
                            })
                          );
                        }}
                      />
                    </label>
                  </tr>
                </th>
              </th>
              <th className="fw-normal pb-2 pt-0 align-top text-center"></th>
              <th className="fw-normal pb-2 pt-0 align-top text-center">
                <th className="d-flex flex-column p-0">
                  <tr>
                    <label for="" className={`${readOnlyMode ? "d-none" : ""}`}>
                      <input
                        className="form-check-input "
                        type="checkbox"
                        disabled={isReadOnly}
                        checked={bulkSelectionChecked("monthly")}
                        onChange={(e) => {
                          setChecklists(
                            checklists.map((x) => {
                              if (
                                x.room_id == room_id &&
                                x.description != "" &&
                                !disableFrequencySelection(x.days, "monthly")
                              ) {
                                x.days = {
                                  ...x.days,
                                  monthly: e.target.checked,
                                };
                              }
                              return x;
                            })
                          );
                        }}
                      />
                    </label>
                  </tr>
                </th>
              </th>
              <th className="fw-normal pb-2 pt-0 align-top text-center">
                <th className="d-flex flex-column p-0">
                  <tr>
                    <label for="" className={`${readOnlyMode ? "d-none" : ""}`}>
                      <input
                        className="form-check-input "
                        type="checkbox"
                        disabled={isReadOnly}
                        checked={bulkSelectionChecked("quarterly")}
                        onChange={(e) => {
                          setChecklists(
                            checklists.map((x) => {
                              if (
                                x.room_id == room_id &&
                                x.description != "" &&
                                !disableFrequencySelection(x.days, "quarterly")
                              ) {
                                x.days = {
                                  ...x.days,
                                  quarterly: e.target.checked,
                                };
                              }
                              return x;
                            })
                          );
                        }}
                      />
                    </label>
                  </tr>
                </th>
              </th>
              <th className="fw-normal pb-2 pt-0 align-top text-center">
                <th className="d-flex flex-column p-0">
                  <tr>
                    <label for="" className={`${readOnlyMode ? "d-none" : ""}`}>
                      <input
                        className="form-check-input "
                        type="checkbox"
                        disabled={isReadOnly}
                        checked={bulkSelectionChecked("half_yearly")}
                        onChange={(e) => {
                          setChecklists(
                            checklists.map((x) => {
                              if (
                                x.room_id == room_id &&
                                x.description != "" &&
                                !disableFrequencySelection(
                                  x.days,
                                  "half_yearly"
                                )
                              ) {
                                x.days = {
                                  ...x.days,
                                  half_yearly: e.target.checked,
                                };
                              }
                              return x;
                            })
                          );
                        }}
                      />
                    </label>
                  </tr>
                </th>
              </th>
              <th className="fw-normal pb-2 pt-0 align-top text-center">
                <th className="d-flex flex-column p-0">
                  <tr>
                    <label for="" className={`${readOnlyMode ? "d-none" : ""}`}>
                      <input
                        className="form-check-input "
                        type="checkbox"
                        disabled={isReadOnly}
                        checked={bulkSelectionChecked("yearly")}
                        onChange={(e) => {
                          setChecklists(
                            checklists.map((x) => {
                              if (
                                x.room_id == room_id &&
                                x.description != "" &&
                                !disableFrequencySelection(x.days, "yearly")
                              ) {
                                x.days = {
                                  ...x.days,
                                  yearly: e.target.checked,
                                };
                              }
                              return x;
                            })
                          );
                        }}
                      />
                    </label>
                  </tr>
                </th>
              </th>
              <th className="fw-normal pb-2 pt-0 align-middle text-center">
                <div
                  className={`text-center ${
                    readOnlyMode ? "d-none" : ""
                  }`}
                >
                  <a
                    onClick={() => {
                      bulkDeleteOperation()
                    }}
                  >
                    <Trash size={16} className="c-icons" weight="light" />
                  </a>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="bgOfficelisting lightthemebgwhite">
            {checklists.length ? (
              checklists.map((checklist, index) => {
                let totalEuipments = 0;
                checklist.equipment_detail && checklist.equipment_detail.length > 0 && checklist.equipment_detail.map((item, index) =>{
                  totalEuipments = Number(totalEuipments) + Number(item.equipment_quantity)
                })
                if (
                  checklist.room_id == room_id &&
                  type_of_space_item_id == checklist.type_of_space_item_id
                ) {
                  sr_no++;
                  return (
                    <tr key={index}>
                      {!isReadOnly ? (
                        <td className="fw-normal py-2 text-center">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            checked={
                              bulkActionsCheckboxes.findIndex(
                                (x) =>
                                  x.description &&
                                  x.description.trim().toLowerCase() ==
                                    checklist.description
                                      .trim()
                                      .toLowerCase() &&
                                  x.room_id == room_id &&
                                  x.type_of_space_item_id ==
                                    type_of_space_item_id
                              ) !== -1
                            }
                            onChange={(e) => {
                              handleBulkselection(
                                e.target.checked,
                                index,
                                checklist
                              );
                            }}
                            disabled={isReadOnly}
                          />
                        </td>
                      ) : (
                        <></>
                      )}
                      <td className="">{sr_no}</td>
                      <td
                        className="text-truncate max-width-100 for-accessible"
                        data-tip={checklist.description}
                        data-effect="solid"
                        data-class="tooltip-main"
                        data-place="top"
                      >
                        <input
                          className="form-control pe-20pximp p-10 text-truncate for-accessible "
                          placeholder={`${_l("l_item_name")}`}
                          value={checklist.description}
                          onPaste={(e) => {
                            bulkEnterChecklistOnPaste(e.clipboardData.getData('text'), checklist,index);
                            e.preventDefault();
                          }}
                          onChange={(e) => {
                            setChecklists(
                              Object.values({
                                ...checklists,
                                [index]: {
                                  ...checklist,
                                  description: e.target.value,
                                  // id : checklist.id == 0 ? crypto.randomUUID() : checklist.id
                               },
                              })
                            );
                          }}
                          onBlur={(e) => {
                            appendNewSlot(e);
                          }}
                          disabled={isReadOnly}
                        />
                      </td>
                      <td>
                        <Form.Check
                          type="switch"
                          id=""
                          label=""
                          checked={checklist.photo_required > 0}
                          className="with-no-label-space d-flex justify-content-center m-auto"
                          disabled={readOnlyMode}
                          onChange={(e) => {
                            setChecklists(
                              Object.values({
                                ...checklists,
                                [index]: {
                                  ...checklist,
                                  photo_required: e.target.checked ? 1 : 0,
                                },
                              })
                            );
                          }}
                        />
                      </td>
                      <td className="py-0 text-center comman_action_icon ">
                        <div className="white-border-box d-flex align-items-center h32w32 action_icon with_bg m-auto no-hover">
                          <a
                            className="priority-checkbox text_wrap position-relative"
                            data-tip={_l("l_priority")}
                            data-effect="solid"
                            data-className="tooltip-main"
                          >
                            <input
                              type="checkbox"
                              className="absolute-input"
                              checked={checklist.priority > 0}
                              disabled={readOnlyMode || !checklist.description}
                              onChange={(e) => {
                                setChecklists(
                                  Object.values({
                                    ...checklists,
                                    [index]: {
                                      ...checklist,
                                      priority: e.target.checked ? 1 : 0,
                                    },
                                  })
                                );
                              }}
                            />
                            <div className="check_box">
                              <HighPriorityActiveIcon className="HW18 check_span_img active" />
                              <HighPriorityIcon className="HW18 check_span_img inactive opacity-50 d-block" />
                            </div>
                          </a>
                        </div>
                      </td>
                      <td
                        className={`text-center py-0 ${
                          checklist.description
                            ? "for-accessible"
                            : "for-disabled"
                        }`}
                      >
                        <a
                          key={sr_no}
                          className="comman_action_icon  d-flex align-items-center justify-content-center"
                          type="button"
                          id={`minified_comment_${sr_no}`}
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-auto-close="outside"
                          onClick={() => {
                            setToggle(!toggle);
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <div className="action_icon with_bg h32w32 on-hover-active-toggle-img light-theme-img position-relative no-hover">
                              <ChatCircle
                                size={18}
                                className="c-icons"
                                weight="light"
                              />
                            </div>
                            <span
                              className={`c-font f-12 d-flex justify-content-center flex-grow-1 ps-2 text-on-hover-active-green number-counter-toggle`}
                              data-cmt-cnt-id={sr_no}
                            >
                              {checklist.item_comments &&
                              checklist.item_comments.length
                                ? checklist.item_comments.length
                                : ""}
                            </span>
                          </div>
                        </a>
                        <ScheduleComments
                          toggle={toggle}
                          readOnlyMode={readOnlyMode}
                          addEditSlotComment={(comment, files, comment_id) => {
                            addEditSlotComment(
                              index,
                              comment,
                              files,
                              comment_id
                            );
                          }}
                          deleteSlotCommnet={(comment_id) => {
                            confirmDeleteComment(index, comment_id);
                          }}
                          slotComments={checklist.item_comments}
                        />
                      </td>
                      <td
                        className={`text-center py-0 ${checklist.description
                            ? "for-accessible"
                            : "for-disabled"
                          }`}
                      >
                        <UncontrolledDropdown className={`comman_action_icon  d-flex align-items-center justify-content-center`}>
                          <div className="d-flex align-items-center">
                            <DropdownToggle tag={'div'} className="action_icon with_bg h32w32 on-hover-active-toggle-img light-theme-img position-relative no-hover ">
                              <a href="#/"
                                className={`d-flex on-hover-active-toggle-img h32w32 with_bg action_icon me-10px 'activeicon'`}
                                type="button"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="outside"
                                aria-expanded="false"
                                data-tip={_l("l_equipment")}
                                data-effect="solid"
                                data-delay-show='1000'
                                data-class="tooltip-main"
                                onClick={() => seteuipmentModals(index)}
                              >
                                <Wrench
                                  size={18}
                                  className="c-icons"
                                  weight="light"
                                />
                              </a>
                              <ReactTooltip />
                            </DropdownToggle>
                            <span
                              className={`c-font f-12 d-flex justify-content-center flex-grow-1 ps-2 text-on-hover-active-green number-counter-toggle`}>
                              {
                                totalEuipments ? totalEuipments :
                                checklist.total_equipment ? checklist.total_equipment : 0
                              }
                            </span>
                            <div toggle={false} className="px-0">
                              {euipmentModals === index ?
                                <UnControlMainFiltersDropDown
                                  type={"isCreateTaskCkecklist"}
                                  data={checklist}
                                  addEuipmentchecklists={
                                    addEuipmentchecklists
                                  }
                                  is_from_schedule={true}
                                />
                                : <></>}
                            </div>
                          </div>
                        </UncontrolledDropdown>
                      </td>
                      <td className="py-0">
                        <div className="white-border-box c-font f-12 p-0 text-center custom-datepicker max-width75 custom-timepicker h32wauto">
                          <DatePicker
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            className="text-center"
                            selected={
                              checklist.slot instanceof Date &&
                              !isNaN(checklist.slot)
                                ? checklist.slot
                                : ""
                            }
                            showTimeSelect
                            onChange={(date) => {
                              if (date instanceof Date && !isNaN(date)) {
                                setChecklists(
                                  Object.values({
                                    ...checklists,
                                    [index]: { ...checklist, slot: date },
                                  })
                                );
                              }
                            }}
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            placeholderText={`${_l("l_time_placeholder")}`}
                            dateFormat="h:mm aa"
                            readOnly={isReadOnly || checklist.description == ""}
                            calendarStartDay={1}
                          />
                        </div>
                      </td>
                      <td className="text-center ">
                        <label for="" className="">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            checked={
                              checklist.days && checklist.days.mon
                                ? checklist.days.mon
                                : false
                            }
                            onChange={(e) => {
                              selectChecklistItemFrequency(
                                checklist,
                                index,
                                "mon",
                                e.target.checked
                              );
                            }}
                            disabled={
                              isReadOnly ||
                              disableWeekdaysSelection(
                                checklist ? checklist.days : {}
                              ) ||
                              checklist.description == ""
                            }
                          />
                        </label>
                      </td>
                      <td className="text-center ">
                        <label for="" className="">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            checked={
                              checklist.days && checklist.days.tue
                                ? checklist.days.tue
                                : false
                            }
                            onChange={(e) => {
                              selectChecklistItemFrequency(
                                checklist,
                                index,
                                "tue",
                                e.target.checked
                              );
                            }}
                            disabled={
                              isReadOnly ||
                              disableWeekdaysSelection(
                                checklist ? checklist.days : {}
                              ) ||
                              checklist.description == ""
                            }
                          />
                        </label>
                      </td>
                      <td className="text-center ">
                        <label for="" className="">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            checked={
                              checklist.days && checklist.days.wed
                                ? checklist.days.wed
                                : false
                            }
                            onChange={(e) => {
                              selectChecklistItemFrequency(
                                checklist,
                                index,
                                "wed",
                                e.target.checked
                              );
                            }}
                            disabled={
                              isReadOnly ||
                              disableWeekdaysSelection(
                                checklist ? checklist.days : {}
                              ) ||
                              checklist.description == ""
                            }
                          />
                        </label>
                      </td>
                      <td className="text-center ">
                        <label for="" className="">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            checked={
                              checklist.days && checklist.days.thu
                                ? checklist.days.thu
                                : false
                            }
                            onChange={(e) => {
                              selectChecklistItemFrequency(
                                checklist,
                                index,
                                "thu",
                                e.target.checked
                              );
                            }}
                            disabled={
                              isReadOnly ||
                              disableWeekdaysSelection(
                                checklist ? checklist.days : {}
                              ) ||
                              checklist.description == ""
                            }
                          />
                        </label>
                      </td>
                      <td className="text-center ">
                        <label for="" className="">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            checked={
                              checklist.days && checklist.days.fri
                                ? checklist.days.fri
                                : false
                            }
                            onChange={(e) => {
                              selectChecklistItemFrequency(
                                checklist,
                                index,
                                "fri",
                                e.target.checked
                              );
                            }}
                            disabled={
                              isReadOnly ||
                              disableWeekdaysSelection(
                                checklist ? checklist.days : {}
                              ) ||
                              checklist.description == ""
                            }
                          />
                        </label>
                      </td>
                      <td className="text-center ">
                        <label for="" className="">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            checked={
                              checklist.days && checklist.days.sat
                                ? checklist.days.sat
                                : false
                            }
                            onChange={(e) => {
                              selectChecklistItemFrequency(
                                checklist,
                                index,
                                "sat",
                                e.target.checked
                              );
                            }}
                            disabled={
                              isReadOnly ||
                              disableWeekdaysSelection(
                                checklist ? checklist.days : {}
                              ) ||
                              checklist.description == ""
                            }
                          />
                        </label>
                      </td>
                      <td className="text-center ">
                        <label for="" className="">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            checked={
                              checklist && checklist.days && checklist.days.sun
                                ? checklist.days.sun
                                : false
                            }
                            onChange={(e) => {
                              selectChecklistItemFrequency(
                                checklist,
                                index,
                                "sun",
                                e.target.checked
                              );
                            }}
                            disabled={
                              isReadOnly ||
                              disableWeekdaysSelection(
                                checklist ? checklist.days : {}
                              ) ||
                              checklist.description == ""
                            }
                          />
                        </label>
                      </td>
                      <td className="text-center "></td>
                      <td className="text-center ">
                        <label for="" className="">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            checked={
                              checklist &&
                              checklist.days &&
                              checklist.days.monthly
                                ? checklist.days.monthly
                                : false
                            }
                            onChange={(e) => {
                              selectChecklistItemFrequency(
                                checklist,
                                index,
                                "monthly",
                                e.target.checked
                              );
                            }}
                            disabled={
                              isReadOnly ||
                              disableFrequencySelection(
                                checklist ? checklist.days : {},
                                "monthly"
                              ) ||
                              checklist.description == ""
                            }
                          />
                        </label>
                      </td>
                      <td className="text-center ">
                        <label for="" className="">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            checked={
                              checklist &&
                              checklist.days &&
                              checklist.days.quarterly
                                ? checklist.days.quarterly
                                : false
                            }
                            onChange={(e) => {
                              selectChecklistItemFrequency(
                                checklist,
                                index,
                                "quarterly",
                                e.target.checked
                              );
                            }}
                            disabled={
                              isReadOnly ||
                              disableFrequencySelection(
                                checklist ? checklist.days : {},
                                "quarterly"
                              ) ||
                              checklist.description == ""
                            }
                          />
                        </label>
                      </td>
                      <td className="text-center ">
                        <label for="" className="">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            checked={
                              checklist &&
                              checklist.days &&
                              checklist.days.half_yearly
                                ? checklist.days.half_yearly
                                : false
                            }
                            onChange={(e) => {
                              selectChecklistItemFrequency(
                                checklist,
                                index,
                                "half_yearly",
                                e.target.checked
                              );
                            }}
                            disabled={
                              isReadOnly ||
                              disableFrequencySelection(
                                checklist ? checklist.days : {},
                                "half_yearly"
                              ) ||
                              checklist.description == ""
                            }
                          />
                        </label>
                      </td>
                      <td className="text-center ">
                        <label for="" className="">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            checked={
                              checklist &&
                              checklist.days &&
                              checklist.days.yearly
                                ? checklist.days.yearly
                                : false
                            }
                            onChange={(e) => {
                              selectChecklistItemFrequency(
                                checklist,
                                index,
                                "yearly",
                                e.target.checked
                              );
                            }}
                            disabled={
                              isReadOnly ||
                              disableFrequencySelection(
                                checklist ? checklist.days : {},
                                "yearly"
                              ) ||
                              checklist.description == ""
                            }
                          />
                        </label>
                      </td>
                      <td className="text-center comman_action_icon">
                        <a className={`action_icon with_bg h32w32 text-center ${
                          readOnlyMode || checklist.description == ""
                            ? "d-none"
                            : ""
                        }`}
                          onClick={() => {
                            deleteChecklist(index);
                          }}
                        >
                          <Trash size={16} className="c-icons" weight="light" />
                        </a>
                      </td>
                      <ReactTooltip />
                    </tr>
                  );
                }
              })
            ) : (
              <td colspan="17">
                <CommanPlaceholder
                  imgType="todo-task"
                  placeholderText={_l("l_no_checklist")}
                />
              </td>
            )}
            <ReactTooltip />
          </tbody>
        </table>
      </div>
    </>
  );
};

export default GeneralItemTable;
