import React, { useEffect, useState } from 'react'
import { Button, FormControl, Spinner } from 'react-bootstrap'
import { _l } from '../hooks/utilities'
import { ArrowRight, CaretDoubleDown, Envelope, PhoneCall, UserCircleGear } from 'phosphor-react'
import { MentionsInput, Mention } from "react-mentions";
import Form from "react-bootstrap/Form";
import { commanoffcanvasisOpen, getTaskList, setLeftPanelTab } from '../actions/customer';
import { useDispatch, useSelector } from 'react-redux';
import customerServices from '../services/customer-services';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import TaskCard from '../components/TaskCard';

const NewThemeDashboard = () => {

  const {
    taskList,
  } = useSelector((state) => state.customer);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [emailSyncStatus, setEmailSyncStatus] = useState(0)

  useEffect(() => {
    const checkEmailSync = async () => {
      try {
        const response = await customerServices.getMailSettings();
        setEmailSyncStatus(response.status)
      } catch (e) {
        console.error(e);
      }
    };
    checkEmailSync()
    dispatch(getTaskList("", "", 0, moment(new Date()).format("YYYY-MM-DD")))
  }, [])

  const handleChangeTab = (tab, navigation) => {
    dispatch(commanoffcanvasisOpen(false));
    dispatch(setLeftPanelTab(tab));
    if (navigation) {
      navigate(navigation);
    }
  }
  
  return (
    <div className='schedule-content-wrapper h-100 d-flex flex-column px-0 pb-0 radius_3 pt-10px  d-flex flex-column'>
        <div className='comman-main-box custom-accordian-main res-padding-right0 overflow-auto flex-grow-1 h-100 task-list-accordian   '>
      <div className='d-flex flex-column flex-grow-1 backdroplighttheme daily-schedule-main w-800px canimation'>
        {/* cardAnimation this class need to add on above div for transform */}
          <div className='position-sticky top-0 start-0 z-2 bgpatternCommanImg'>
            <div className='d-flex align-items-center justify-content-between '>
              <div className='c-font f-24 fw-semibold'>Hello James</div>
              <div className='d-flex align-items-center gap-2 comman_action_icon'>
                <Button variant="white-05-solid" className='h32wauto'
                  data-tip={_l("l_export_pdf")}
                  data-effect="solid"
                  data-class="tooltip-main"
                  data-place="top">
                  <PhoneCall size="18" className="c-icons" />
                  <span className="ms-10px ps-0">{_l("l_setup_my_number")}</span>
                </Button>
                {
                  emailSyncStatus
                  ? <></>
                    : <Button variant="white-05-solid" className='h32wauto' onClick={() => {handleChangeTab("my-email", "/MyEmail");}}
                      data-tip={_l("l_export_pdf")}
                      data-effect="solid"
                      data-class="tooltip-main"
                      data-place="top">
                      <Envelope size="18" className="c-icons" />
                      <span className="ms-10px ps-0">{_l("l_setup_my_email")}</span>
                    </Button>
                }
              </div>
            </div>
            <div className='audiocallbg p12px my-4'>
            <div className='align-items-center btnbluebg chat-comment-box comment-input-box d-flex flex-grow-1 p-10  position-sticky top-0 start-0 z-2 lightthemebgtranspaent light-theme-border'>
            <div className='h32w32 comman-image-box with-bg rounded-circle'>
              <div className='comman-bg-img h-100 w-100 bg-style-cover rounded-circle' style={{backgroundImage:`url(https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`}}></div>
            </div>
            <Form.Control
              className='bg-transparent border-0 form-control p-0 ps-3'
              placeholder={`${_l("l_write_message_placeholder")}`}
              type="text"
              name=""/>
            <div className={`d-flex align-items-center comman_action_icon`} > 
                
                    <a href="#/" className={`action_icon with_bg  h32w32 me-10px`} 
                      data-tip={_l("l_send")}
                      data-effect="solid"
                      data-delay-show='1000'
                      data-class="tooltip-main">
                      <ArrowRight size={16} weight="light" className="c-icons" />
                    </a>
                
                    <a href="#/" className={`action_icon with_bg  h32w32`} 
                      data-tip={_l("l_send")}
                      data-effect="solid"
                      data-delay-show='1000'
                      data-class="tooltip-main">
                      <PhoneCall size={16} weight="light" className="c-icons" />
                    </a>
            </div>
          </div>
          </div>
          </div>
          
          <div className='card-list-box accordion-body'>
            {/* {
              taskList
              .map((task, index) => {
                return (
                  <React.Fragment key={index}>
                    <div>
                      <a href='#/' className='task-list-card p25 list-view schedule-card-main document-list-card overflow-visible'>
                        <div className='task-list-wrapper '>
                          <div className='d-flex w-100 align-items-start'>
                            <div className='d-flex task-list-detail'>
                              <div className='task-left flex-grow-1 width100minus160 '>
                                <div className='d-flex align-items-center w-100 task-left-part-toggle text-truncate'>
                                  <div className='flex-wrap d-flex  w-100 align-items-center comman_action_icon'>
                                    <div className=' h32w32 '>
                                      <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{ backgroundImage: `url(${task.creator_profile_image})` }}></div>
                                    </div>
                                    <div className='d-flex flex-column w100minus100per task-list-name text-truncate'>
                                      <div className='fw-semibold mb-1 text-truncate w-100'>{task.name}</div>
                                      <div class="c-font color-white-60 f-10 lh-lg">{_l("l_date")} <span class=" p-0 ">{moment(task.dateadded).format(("YYYY-MM-DD HH:mm a"))}</span></div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                              <div className='d-flex align-items-center task-bottom-part-full '>
                                <div className='align-items-center comman_action_icon d-flex gap10 task-right-static-options comman_action_icon'>
                                  <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                                    <UserCircleGear size={18} weight="light" className="c-icons m-0" />
                                    <span className='c-font f-12 color-white-60 text-uppercase ps-0 ms-10px'>{_l("l_task")}</span>
                                  </a>
                                  <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                                    <span
                                       className={`c-font f-12 text-uppercase ps-0 
                                        ${ task.is_requested == 1
                                            ? "color-orange"
                                            : task.status == 1
                                              ? "color-white-60"
                                              : task.status == 4
                                                ? "in-progress-blue"
                                                : "color-green"
                                        }`}
                                      >
                                        { task.is_requested == 1
                                            ? _l("l_request")
                                            : task.status == 1
                                              ? _l("l_todo_kpi_name")
                                              : task.status == 4
                                                ? _l("l_ongoing_kpi")
                                                : _l("l_completed")
                                        }
                                      </span>
                                    <span class={`c-dots  c-10 rounded-circle ms-10px
                                      ${ task.is_requested == 1
                                        ? "bg-warning"
                                        : task.status == 1
                                          ? "in-progress-white"
                                          : task.status == 4
                                            ? "in-progress-blue"
                                            : "done"
                                      }`}></span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </React.Fragment>
                );
              })
            } */}
            
            <div>
              <a href='#/' className='task-list-card p25 list-view schedule-card-main document-list-card overflow-visible'>
                <div className='task-list-wrapper '>
                  <div className='d-flex w-100 align-items-start'>
                    <div className='d-flex task-list-detail'>
                      <div className='task-left flex-grow-1 width100minus160 '>
                        <div className='d-flex align-items-center w-100 task-left-part-toggle text-truncate'>
                            <div className='flex-wrap d-flex  w-100 align-items-center comman_action_icon'>
                              <div className=' h32w32 '>
                                  <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{backgroundImage:`url(https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`}}></div>
                              </div>
                              <div className='d-flex flex-column w100minus100per task-list-name text-truncate'>
                                <div className='fw-semibold mb-1 text-truncate w-100'>Microsoft email invoice  for @James</div>
                                <div class="c-font color-white-60 f-10 lh-lg">{_l("l_date")}<span class=" p-0 ">28/03/2023 10:45 am</span></div>
                              </div>
                            </div>
                      
                        </div>
                      </div>
                      <div className='d-flex align-items-center task-bottom-part-full '>
                        <div className='align-items-center comman_action_icon d-flex gap10 task-right-static-options comman_action_icon'>
                          <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                            <UserCircleGear size={18} weight="light" className="c-icons m-0" />
                            <span className='c-font f-12 color-white-60 text-uppercase ps-0 ms-10px'>{_l("l_task")}</span>
                          </a>
                          <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                            <span className='c-font f-12 text-uppercase color-green ps-0'>{_l("l_completed")}</span>
                            <span class="c-dots  c-10 rounded-circle ms-10px done"></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div>
              <a href='#/' className='task-list-card p25 list-view schedule-card-main document-list-card overflow-visible'>
                <div className='task-list-wrapper '>
                  <div className='d-flex w-100 align-items-start'>
                    <div className='d-flex task-list-detail'>
                      <div className='task-left flex-grow-1 width100minus160 '>
                        <div className='d-flex align-items-center w-100 task-left-part-toggle text-truncate'>
                            <div className='flex-wrap d-flex  w-100 align-items-center comman_action_icon'>
                              <div className=' h32w32 '>
                                  <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{backgroundImage:`url(https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`}}></div>
                              </div>
                              <div className='d-flex flex-column w100minus100per task-list-name text-truncate'>
                                <div className='fw-semibold mb-1 text-truncate w-100'>Microsoft email invoice  for @James</div>
                                <div class="c-font color-white-60 f-10 lh-lg">{_l("l_date")}<span class=" p-0 ">28/03/2023 10:45 am</span></div>
                              </div>
                            </div>
                      
                        </div>
                      </div>
                      <div className='d-flex align-items-center task-bottom-part-full '>
                        <div className='align-items-center comman_action_icon d-flex gap10 task-right-static-options comman_action_icon'>
                          <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                            <UserCircleGear size={18} weight="light" className="c-icons m-0" />
                            <span className='c-font f-12 color-white-60 text-uppercase ps-0 ms-10px'>{_l("l_task")}</span>
                          </a>
                          <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                            <span className='c-font f-12 text-uppercase color-orange ps-0'>{_l("l_request")}</span>
                            <span class="c-dots  c-10 rounded-circle ms-10px bg-warning"></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div>
              <a href='#/' className='task-list-card p25 list-view schedule-card-main document-list-card overflow-visible'>
                <div className='task-list-wrapper '>
                  <div className='d-flex w-100 align-items-start'>
                    <div className='d-flex task-list-detail'>
                      <div className='task-left flex-grow-1 width100minus160 '>
                        <div className='d-flex align-items-center w-100 task-left-part-toggle text-truncate'>
                            <div className='flex-wrap d-flex  w-100 align-items-center comman_action_icon'>
                              <div className=' h32w32 '>
                                  <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{backgroundImage:`url(https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`}}></div>
                              </div>
                              <div className='d-flex flex-column w100minus100per task-list-name text-truncate'>
                                <div className='fw-semibold mb-1 text-truncate w-100'>Microsoft email invoice  for @James</div>
                                <div class="c-font color-white-60 f-10 lh-lg">{_l("l_date")}<span class=" p-0 ">28/03/2023 10:45 am</span></div>
                              </div>
                            </div>
                      
                        </div>
                      </div>
                      <div className='d-flex align-items-center task-bottom-part-full '>
                        <div className='align-items-center comman_action_icon d-flex gap10 task-right-static-options comman_action_icon'>
                          <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                            <UserCircleGear size={18} weight="light" className="c-icons m-0" />
                            <span className='c-font f-12 color-white-60 text-uppercase ps-0 ms-10px'>{_l("l_task")}</span>
                          </a>
                          <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                            <span className='c-font f-12 color-white-60 text-uppercase ps-0 ms-10px ps-0'>{_l("l_todo_kpi_name")}</span>
                            <span class="c-dots  c-10 rounded-circle ms-10px in-progress-white"></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div>
              <a href='#/' className='task-list-card p25 list-view schedule-card-main document-list-card overflow-visible'>
                <div className='task-list-wrapper '>
                  <div className='d-flex w-100 align-items-start'>
                    <div className='d-flex task-list-detail'>
                      <div className='task-left flex-grow-1 width100minus160 '>
                        <div className='d-flex align-items-center w-100 task-left-part-toggle text-truncate'>
                            <div className='flex-wrap d-flex  w-100 align-items-center comman_action_icon'>
                              <div className=' h32w32 '>
                                  <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{backgroundImage:`url(https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`}}></div>
                              </div>
                              <div className='d-flex flex-column w100minus100per task-list-name text-truncate'>
                                <div className='fw-semibold mb-1 text-truncate w-100'>Microsoft email invoice  for @James</div>
                                <div class="c-font color-white-60 f-10 lh-lg">{_l("l_date")}<span class=" p-0 ">28/03/2023 10:45 am</span></div>
                              </div>
                            </div>
                      
                        </div>
                      </div>
                      <div className='d-flex align-items-center task-bottom-part-full '>
                        <div className='align-items-center comman_action_icon d-flex gap10 task-right-static-options comman_action_icon'>
                          <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                            <UserCircleGear size={18} weight="light" className="c-icons m-0" />
                            <span className='c-font f-12 color-white-60 text-uppercase ps-0 ms-10px'>{_l("l_task")}</span>
                          </a>
                          <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                            <span className='c-font f-12 text-uppercase color-blue ps-0'>{_l("l_ongoing_kpi")}</span>
                            <span class="c-dots  c-10 rounded-circle ms-10px in-progress-blue"></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div>
              <a href='#/' className='task-list-card p25 list-view schedule-card-main document-list-card overflow-visible'>
                <div className='task-list-wrapper '>
                  <div className='d-flex w-100 align-items-start'>
                    <div className='d-flex task-list-detail'>
                      <div className='task-left flex-grow-1 width100minus160 '>
                        <div className='d-flex align-items-center w-100 task-left-part-toggle text-truncate'>
                            <div className='flex-wrap d-flex  w-100 align-items-center comman_action_icon'>
                              <div className=' h32w32 '>
                                  <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{backgroundImage:`url(https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`}}></div>
                              </div>
                              <div className='d-flex flex-column w100minus100per task-list-name text-truncate'>
                                <div className='fw-semibold mb-1 text-truncate w-100'>Microsoft email invoice  for @James</div>
                                <div class="c-font color-white-60 f-10 lh-lg">{_l("l_date")}<span class=" p-0 ">28/03/2023 10:45 am</span></div>
                              </div>
                            </div>
                      
                        </div>
                      </div>
                      <div className='d-flex align-items-center task-bottom-part-full '>
                        <div className='align-items-center comman_action_icon d-flex gap10 task-right-static-options comman_action_icon'>
                          <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                            <UserCircleGear size={18} weight="light" className="c-icons m-0" />
                            <span className='c-font f-12 color-white-60 text-uppercase ps-0 ms-10px'>{_l("l_task")}</span>
                          </a>
                          <a href="#/" className='action_icon h32wauto d-flex align-items-center justify-content-center calendarpadding h32wauto with_bg min-width100'>
                            <span className='c-font f-12 text-uppercase color-green ps-0'>{_l("l_completed")}</span>
                            <span class="c-dots  c-10 rounded-circle ms-10px done"></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            {/* <a href="#/" className="btn btn-white-03 w-100 view-more-btn">
              <div className="d-flex align-item-center justify-content-center">
                <span className="title-fonts c-font f-12 ms-2">
                  {_l("l_view_more_btn")}
                </span>
                <CaretDoubleDown size={12} className="c-icons mx-2" weight="light" />
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2`}
                  aria-hidden="true"
                />
              </div>
            </a> */}

          </div>
        </div>
      </div>
    </div>
  )
}

export default NewThemeDashboard