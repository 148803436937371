import React, { useEffect, useState } from "react";
import { ArrowRight  ,Plus ,EnvelopeSimple,Phone,X } from "phosphor-react";
import userPlaceHolder from "../../assets/images/user-placeholder.jpg";
import XIcon from "../../assets/icons/XIcon";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import CustomerServices from "../../services/customer-services";
import { _l } from "../../hooks/utilities";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { setCreateScheduledetails, setProject } from "../../actions/customer";
import { showMessage } from "../../actions/messages";
import { useDispatch, useSelector } from "react-redux";
import CategoryProviderList from "../Modals/CategoryProviderList";
import CommanLoader from "../Loader/CommanLoader";
import {
  updateProjectTopics,
  getDefaultTopics,
  setFilterBenchmarkCategory,
} from "../../actions/customer";
import InviteProviderEmail from "./InviteProviderEmail";
import ReactTooltip from "react-tooltip";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
const SelectProviderCategory = ({
  show,
  handleClose,
  handleShow,
  operatorList = null,
  category = null,
  categoryId = null,
  handleProvider,
  categoryListing = null,
  isCreateBenchmark = false, pageName = ''
}) => {
  const [defaultTopics, setDefaultTopics] = useState([]);
  const [operators, setOperators] = useState([]);
  const [newOperators, setNewOperators] = useState([]);

  const [addInviteProvider, setAddInviteProvider] = useState("");
  const [intialProviderEmail, setIntialProviderEmail] = useState(true);
  const [providerCategory, setProviderCategory] = useState("");
  const [addedClientId, setAddedClientId] = useState("");
  const [categoryTitle, setCategoryTitle] = useState("");
  const [selectedSchedule, setSelectedSchedule] = useState({});
  const [showAddProviderCategory, setShowAddProviderCategory] = useState(false);
  const [selectedTab, setSelectedTab] = useState("existing");
  const [emailAlreadyExist, setEmailAlreadyExist] = useState(false);
  const [defaultCategory, setDefaultCategory] = useState(0);
  const [countryCode, setCountryCode] = useState({
    value: "+33",
    label: "+33",
  });
  const [mobileNumber, setMobileNumber] = useState("");
  const [providerName, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [countryCodes, setCountryCodes] = useState([]);
  const [alreadyExistOpeator, setAlreadyExistOpeator] = useState(false);
  const [disableExistingTab, setDisableExistingTab] = useState(false);
  const [spaceClientAdded, setSpaceClientAdded] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedBenchmarkOperator, setSelectedBenchmarkOperator] = useState([]);
  const isClientExist = pageName != 'premiumSubscription' ? localStorage.getItem("operatorClientId") == localStorage.getItem('client_id') ? 0 : 1 : 0
  const userType = localStorage.getItem("user_type");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userOperatorForm =
    (((selectedTab == "new" || defaultCategory == 0) &&
      isCreateBenchmark == true) ||
      (isCreateBenchmark == false && operators.length == 0)) &&
    (providerCategory > 0 || categoryId) && isClientExist == 0;
  const userContactForm =
    selectedTab == "new" && (providerCategory > 0 || categoryId);
  const formShow = userType == "contact" ? userContactForm : userOperatorForm;
  const projectId = useSelector((state) => state.customer.selectedProject);

  const userOperatorList =
    (((selectedTab == "existing" ||
      defaultCategory == 0 ||
      userType == "operator") &&
      isCreateBenchmark == true) ||
      isCreateBenchmark == false) &&
    operators &&
    operators.length;

  const userContactOperatorList = isCreateBenchmark
    ? (selectedTab == "existing" ||
      (selectedTab == "new" && newOperators.length > 0)) &&
    (providerCategory > 0 || categoryId)
    : selectedTab == "existing" && (providerCategory > 0 || categoryId);

  let operatListShow =
    userType == "contact" ? userContactOperatorList : userOperatorList;

  const operatorInnerForm =
    ((emailAlreadyExist || defaultCategory == 0) &&
      isCreateBenchmark == true) ||
    (isCreateBenchmark == false && operators.length == 0) && isClientExist == 0;
  const contactInnerForm =
    selectedTab == "new" && (providerCategory > 0 || categoryId);
  let innerFormShow =
    userType == "contact" ? contactInnerForm : operatorInnerForm;
  const getOperators = isCreateBenchmark
    ? selectedTab == "existing" || (userType == "operator" && isClientExist == 1)
      ? operators
      : newOperators
    : operators;

  useEffect(() => {
    if (categoryListing) {
      setCategoryTitle("");
      setProviderCategory("");
      setOperators([]);
      setNewOperators([]);
      setSelectedSchedule({});
      setAddInviteProvider("");
      setMobileNumber("");
      setName("");
      setCountryCode({ value: "+33", label: "+33" });
      setSelectedTab("existing");
      setDefaultCategory(0);
      setCompanyName("");
      setAddedClientId(0);
      setEmailAlreadyExist(false);
      setAlreadyExistOpeator(false);
      setDisableExistingTab(false);
      setSpaceClientAdded(false)
      setSelectedBenchmarkOperator([])
      let defaultCategory = categoryListing
        .filter((category) => category.is_default == 1)
        .sort((a, b) =>
          a.label.toLowerCase() > b.label.toLowerCase()
            ? 1
            : b.label.toLowerCase() > a.label.toLowerCase()
              ? -1
              : 0
        );
      let customCategory = categoryListing
        .filter((category) => category.is_default != 1)
        .sort((a, b) =>
          a.label.toLowerCase() > b.label.toLowerCase()
            ? 1
            : b.label.toLowerCase() > a.label.toLowerCase()
              ? -1
              : 0
        );
      setDefaultTopics(defaultCategory.concat(customCategory));
    }
    if (show) {
      setSelectedSchedule({});
      CustomerServices.getCountryCodes().then((res) => {
        if (res.status == "1") {
          setCountryCodes(
            res.data.map((code) => {
              return {
                value: code.code_value,
                label: code.code_value,
              };
            })
          );
        }
      });
    }
  }, [show]);
  useEffect(() => {
    setOperators([]);
    setNewOperators([]);
    setIntialProviderEmail(true);
    if (operatorList && operatorList.length) {
      setOperators(operatorList);
    }
  }, [operatorList]);
  useEffect(() => {
    const pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
    if (addInviteProvider && pattern.test(addInviteProvider)) {
      emailCheckProvider();
    } else {
      setEmailAlreadyExist(false);
      setName("");
      setMobileNumber("");
      setCountryCode({ value: "+33", label: "+33" });
      // setAddedClientId(0)
      setSelectedSchedule({});
      setCompanyName("");
      setAlreadyExistOpeator(false);
    }
  }, [addInviteProvider]);
  const handleInviteProvider = () => {
    const pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
    if (
      addInviteProvider &&
      addInviteProvider != "" &&
      pattern.test(addInviteProvider)
    ) {
      CustomerServices.addProviderToCategory(
        addInviteProvider,
        categoryListing ? providerCategory : categoryId,
        projectId
      ).then((res) => {
        if (res.status == "1" && res.data) {
          if (categoryId) {
            handleProvider({
              profile_url: localStorage.getItem("contact_image"),
              full_name: addInviteProvider,
            });
          } else {
            setOperators((operators) => [
              ...operators,
              {
                cp_id: res.data.provider_id,
                email: addInviteProvider,
                profile_url: localStorage.getItem("contact_image"),
              },
            ]);
          }
          setAddInviteProvider("");
        } 
      });
    } 
  };
  const getOperatorsList = (categoryId) => {
    CustomerServices.getOperatorsByCategory(projectId, categoryId, 1, 1).then(
      (res) => {
        setShowLoader(false)
        if (res.status && res.data && res.data.length) {
          setOperators(res.data);
          setSelectedTab("existing")
        } else if (res.status) {
          // if (!isCreateBenchmark) {
          setSelectedTab("new");
          setDisableExistingTab(true);
          // }
          setOperators([]);
        }
      }
    );
  };
  const changeSchedule = (operator) => {
    if (Object.keys(selectedSchedule).length) {
      setSelectedSchedule(
        operator.email == selectedSchedule.email ? {} : operator
      );
    } else {
      setSelectedSchedule(operator);
    }
  };
  const AddProvider = () => {
    const pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
    let User = userType == "contact" ? "provider" : "client";
    if (!pattern.test(addInviteProvider)) {
      dispatch(
        showMessage(
          "unsucess",
          _l('l_error'),
          _l('l_please_enter_valid') + ' ' + User + ' ' + _l('l_email')
        )
      );
      return false;
    } else if (
      providerName == "" &&
      emailAlreadyExist &&
      alreadyExistOpeator == false
    ) {
      dispatch(
        showMessage("unsucess", _l('l_error'), _l('l_please_enter') + ' ' + User + ' ' + _l('l_name'))
      );
      return false;
    } else if (
      countryCode == "" &&
      emailAlreadyExist &&
      alreadyExistOpeator == false
    ) {
      dispatch(
        showMessage("unsucess", _l('l_error'), _l('l_please_select_the_country_code'))
      );
      return false;
    } else if (
      companyName == "" &&
      emailAlreadyExist &&
      userType == "operator" &&
      alreadyExistOpeator == false
    ) {
      dispatch(
        showMessage(
          "unsucess",
          _l('l_error'),
          _l('l_please_enter_the') + ' ' + User + ' ' + _l('l_company_name')
        )
      );
      return false;
    } else if (
      mobileNumber == "" &&
      emailAlreadyExist &&
      alreadyExistOpeator == false
    ) {
      dispatch(
        showMessage(
          "unsucess",
          _l('l_error'),
          _l('l_please_enter') + ' ' + User + ' ' + _l('l_mobile_number')
        )
      );
      return false;
    } else {
      if (emailAlreadyExist) {
        if (userType == "operator") {
          // if (addedClientId == '') {
          addClient();
          // } else {
          //   if (!isCreateBenchmark) {
          //     setSelectedSchedule({
          //       cp_id: addedClientId,
          //       email: addInviteProvider,
          //       profile_url: localStorage.getItem('contact_image'),
          //       mobile: countryCode.value + ' ' + mobileNumber
          //     })
          //     setOperators([{
          //       cp_id: addedClientId,
          //       email: addInviteProvider,
          //       profile_url: localStorage.getItem('contact_image'),
          //       mobile: mobileNumber
          //     }]);
          //   }

          //   setAddInviteProvider('')
          //   setMobileNumber('')
          //   setName('')
          //   setCountryCode({ value: '+33', label: '+33' })
          //   // setCompanyName('')

          //   // setDefaultCategory(1)
          //   setSelectedTab('existing')

          // }
        } else {
          addProviderAPI();
        }
      }
    }
    // else {
    //   CustomerServices.emailCheckInvitees(addInviteProvider, categoryListing ? providerCategory : categoryId, projectId).then((res) => {
    //     if (res.status == '1') {
    //       if ((!!res.data) && (res.data.constructor === Object)) {
    //         setName(res.data.fullname)
    //         setMobileNumber(res.data.phonenumber)
    //       }
    //       setEmailAlreadyExist(true)
    //     } else {
    //       dispatch(showMessage('unsucess', 'Error', res.message ? res.message : 'Something went wrong'));
    //       setAddInviteProvider('')
    //       setSelectedTab('existing')
    //     }
    //   });
    // }
  };
  const addProviderAPI = () => {
    let mobileTemp = countryCode.value + " " + mobileNumber.trim();
    CustomerServices.addProviderToCategory(
      addInviteProvider,
      categoryListing ? providerCategory : categoryId,
      projectId,
      providerName,
      mobileTemp
    ).then((res) => {
      if (res.status == "1" && res.data) {
        if (categoryId) {
          handleProvider({
            cp_id: res.data.provider_id,
            email: addInviteProvider,
            profile_url: localStorage.getItem("contact_image"),
            full_name: addInviteProvider,
            mobile: mobileNumber,
          });
        }
        if (isCreateBenchmark == true) {
          setNewOperators([
            ...newOperators,
            {
              cp_id: res.data.provider_id,
              email: addInviteProvider,
              full_name: providerName,
              alreadyExist: res.data.length == 0 ? false : true,
              profile_url: localStorage.getItem("contact_image"),
              mobile: mobileTemp,
            },
          ]);
          if (userType == 'contact') {
            setSelectedBenchmarkOperator([...selectedBenchmarkOperator, addInviteProvider])
          }
          // if (defaultCategory == 1) {
          //   setSelectedTab('existing')
          // }
        } else {
          setOperators([
            {
              cp_id: res.data.provider_id,
              email: addInviteProvider,
              profile_url: localStorage.getItem("contact_image"),
              mobile: mobileNumber,
            },
          ]);
          setSelectedSchedule({
            cp_id: res.data.provider_id,
            email: addInviteProvider,
            profile_url: localStorage.getItem("contact_image"),
            mobile: mobileNumber,
          });
          redirectToBenchmark();
        }

        setAddInviteProvider("");
        setMobileNumber("");
        setName("");
        setCountryCode({ value: "+33", label: "+33" });
        // setDefaultCategory(1)

        // dispatch(showMessage('sucess', 'Succes', 'Invite successfully'));
      } else {
        dispatch(
          showMessage(
            "unsucess",
            _l('l_error'),
            res.message ? res.message : _l('l_something_went_wrong')
          )
        );
      }
    });
  };
  const emailCheckProvider = () => {
    CustomerServices.emailCheckInvitees(
      addInviteProvider,
      categoryListing ? providerCategory : categoryId,
      projectId
    ).then((res) => {
      if (res.status == "1") {
        setAddedClientId("");
        if (!!res.data && res.data.constructor === Object) {
          setName(res.data.fullname ? res.data.fullname : "");
          if (res.data.phonenumber) {
            let extractedCountryCode = countryCodes.filter(
              (x) => res.data.phonenumber.split(" ")[0] == x.value
            );

            if (extractedCountryCode.length) {
              setCountryCode(extractedCountryCode[0]);
            } else {
              setCountryCode({ value: "+33", label: "+33" });
            }
            const phoneNumber = res.data.phonenumber.split(" ")[1]
              ? res.data.phonenumber.split(" ")[1]
              : res.data.phonenumber;
            setMobileNumber(phoneNumber.trim());
          }
          // setMobileNumber(res.data.phonenumber ? res.data.phonenumber : '')
          setAlreadyExistOpeator(true);
          if (userType == "operator") {
            setCompanyName(res.data.company ? res.data.company : "");
            setAddedClientId(res.data.userid);
            if (isCreateBenchmark == false) {
              setSelectedSchedule({ email: res.data.email });
            }
          } else {
            if (isCreateBenchmark == false) {
              setSelectedSchedule({ email: addInviteProvider });
            }
          }
        }
        setEmailAlreadyExist(true);
      } else {
        // dispatch(showMessage('unsucess', 'Error', res.message ? res.message : 'Something went wrong'));
        // setAddInviteProvider('')
        if (alreadyExistOpeator) {
          setName("");
          setCountryCode({ value: "+33", label: "+33" });
          setMobileNumber("");
          setCompanyName("");
          // setAlreadyExistOpeator(false)
        }
        setAlreadyExistOpeator(false);
        if (userType == "operator") {
          setSelectedTab("new");

        } else {
          if (isCreateBenchmark == false) {
            setSelectedSchedule({ email: addInviteProvider });
          } else {
            // setSelectedTab('existing')
          }
        }
        setEmailAlreadyExist(true);
      }
    });
  };
  const addClient = () => {
    let mobileTemp = countryCode.value + " " + mobileNumber.trim();
    CustomerServices.addClientToSpace(
      companyName,
      providerName,
      addInviteProvider,
      mobileTemp,
      projectId
    ).then((res) => {
      if (res.status == "1" && res.data) {
        // if (userType == "operator") {
        let spaces = JSON.parse(localStorage.getItem('spaces'));
        let currentSpaceIndex = spaces.findIndex(w => w.project_id == projectId);
        if(currentSpaceIndex > -1)
        {
          spaces[currentSpaceIndex].client_id = res.data.client_id;
          spaces[currentSpaceIndex].contact_id = res.data.contact_id;
          localStorage.setItem('spaces', JSON.stringify(spaces));
        }   
        localStorage.setItem(
          "operatorClientId",
          res.data.client_id
            ? res.data.client_id
            : 0
        );
        localStorage.setItem("spaceClientDetails", JSON.stringify([{ cp_id: res.data.client_id ? res.data.client_id : 0, mobile: mobileNumber ? mobileNumber : 0, email: addInviteProvider != '' ? addInviteProvider : '', profile_url: localStorage.getItem('contact_image') ? localStorage.getItem('contact_image') : '' }]));
        // }

        setOperators([
          {
            cp_id: res.data.provider_id,
            email: addInviteProvider,
            profile_url: localStorage.getItem("contact_image"),
            mobile: countryCode.value + " " + mobileNumber,
          },
        ]);
        // setAddedClientId(res.data.client_id)
        if (isCreateBenchmark == false) {
          setSelectedSchedule({
            cp_id: res.data.client_id,
            email: addInviteProvider,
            profile_url: localStorage.getItem("contact_image"),
            mobile: countryCode.value + " " + mobileNumber,
          });
          // setProviderCategory(res.data.client_id)
        }
        // setSelectedSchedule({
        //   cp_id: res.data.client_id,
        //   email: addInviteProvider,
        //   profile_url: localStorage.getItem('contact_image'),
        //   mobile: countryCode.value + ' ' + mobileNumber
        // })
        // setProviderCategory(res.data.client_id)

        setAddedClientId(res.data.client_id);
        redirectToBenchmark(res.data.client_id ? res.data.client_id : 0);
        setAddInviteProvider("");
        setMobileNumber("");
        setName("");
        setCountryCode({ value: "+33", label: "+33" });
        // setDefaultCategory(1)
        setSelectedTab("existing");
        // if (isCreateBenchmark) {
        //   dispatch(showMessage("sucess", "Succes", "Invite successfully"));
        // }
      } else {
        dispatch(
          showMessage(
            "unsucess",
            _l('l_error'),
            res.message ? res.message : _l('l_something_went_wrong')
          )
        );
      }
    });
  };
  const redirectToBenchmark = (addedClientId) => {
    if(!addedClientId && Object.keys(selectedSchedule).length == 0 && !isCreateBenchmark)
    {
      dispatch(showMessage("unsucess",
      _l('l_error'),
      `${_l('l_please_enter_valid')} ${_l('l_details')}`));
      return;
    }
    dispatch(setFilterBenchmarkCategory(providerCategory));
    if (window.location.pathname === "/schedules") {
      handleClose();
      dispatch(
        setCreateScheduledetails({
          provider_id: providerCategory,
          provider_name: categoryTitle,
          schedule:
            isCreateBenchmark == false && userType == "operator"
              ? Object.keys(selectedSchedule).length == 0
                ? { email: localStorage.getItem("email") }
                : selectedSchedule
              : selectedSchedule,
          client_id:
            userType == "operator"
              ? addedClientId > 0
                ? addedClientId
                : 0
              : localStorage.getItem("client_id"),
          company: isCreateBenchmark ? companyName : "",
          benchmarkOperator: selectedTab != 'Myr_providers' ? selectedBenchmarkOperator : [],
          proposal_to_all: selectedTab == 'Myr_providers' ? 1 : 0
        })
      );
    }
    navigate("/schedules", {
      state: {
        benchmark: isCreateBenchmark,
        provider_id: providerCategory,
        provider_name: categoryTitle,
        schedule:
          isCreateBenchmark == false && userType == "operator"
            ? Object.keys(selectedSchedule).length == 0
              ? { email: localStorage.getItem("email") }
              : selectedSchedule
            : selectedSchedule,
        client_id:
          userType == "operator"
            ? addedClientId > 0
              ? addedClientId
              : 0
            : localStorage.getItem("client_id"),
        company: isCreateBenchmark ? companyName : "",
        benchmarkOperator: selectedTab != 'Myr_providers' ? selectedBenchmarkOperator : [],
        proposal_to_all: selectedTab == 'Myr_providers' ? 1 : 0
      },
    });
    localStorage.setItem("selectedOffice", projectId);
    dispatch(setProject(projectId));
  };
  const setBenchmarkOperator = (selectedEmail) => {
    let checkedOperator = selectedBenchmarkOperator.filter((email) => email == selectedEmail)
    setSelectedBenchmarkOperator(checkedOperator.length ? selectedBenchmarkOperator.filter((benchmarkOperator) => benchmarkOperator != selectedEmail) : [...selectedBenchmarkOperator, selectedEmail])

  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="custom-modal-style"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >

      <Modal.Header closeButton>
        <Modal.Title>
          {_l('l_create')} {isCreateBenchmark ? _l('l_benchmark') : _l('l_schedule')}
        </Modal.Title>
      </Modal.Header>


      <Modal.Body className=" pt-0">
        <div className="form-wrapper-main">
          <div className="row">
            <Form.Group className="col-12 c-input-box pb-3 position-relative">
              <div className="comment-input-box ">
                <Form.Label className="form-control  border-0 bg-transparent py-0 ps-0 title-fonts color-white-80 position-relative">
                  {category ? category : ""}
                  {categoryId == null && defaultTopics ? (
                    <Select
                      className="custom-select-menu with-filter-select m-0"
                      classNamePrefix="react-select"
                      placeholder={`${_l('l_select_category')}`}
                      options={defaultTopics}
                      // menuIsOpen={true}
                      onChange={(value) => {
                        if (providerCategory != value.value) {
                          setOperators([]);
                        }
                        setProviderCategory(value.value);
                        setCategoryTitle(value.label);
                        if (userType == "operator") {
                          setSelectedTab("new");
                          if (isClientExist == 1 && localStorage.getItem('spaceClientDetails') && JSON.parse(localStorage.getItem('spaceClientDetails'))) {
                            setOperators(JSON.parse(localStorage.getItem('spaceClientDetails')));
                            setSelectedBenchmarkOperator()
                            setAddedClientId(JSON.parse(localStorage.getItem('spaceClientDetails'))[0]['cp_id'] ? JSON.parse(localStorage.getItem('spaceClientDetails'))[0]['cp_id'] : 0)
                            setSelectedBenchmarkOperator(JSON.parse(localStorage.getItem('spaceClientDetails')))
                            setSpaceClientAdded(true)
                          } else {
                            setSelectedBenchmarkOperator([])
                            setAddedClientId(0)
                            setSpaceClientAdded(false)
                          }

                        } else {

                          setDefaultCategory(value.is_default);
                          if (providerCategory != value.value) {
                            setShowLoader(true)
                            getOperatorsList(value.value);
                          }

                          setDisableExistingTab(false);
                          setSelectedBenchmarkOperator([])
                        }

                        setSelectedSchedule({});

                        setIntialProviderEmail((pre) => false);
                        setAddInviteProvider("");
                        setMobileNumber("");
                        setName("");
                        setCountryCode({ value: "+33", label: "+33" });
                      }}
                      value={
                        providerCategory != ""
                          ? {
                            value: providerCategory,
                            label: categoryTitle,
                          }
                          : ""
                      }
                      placeHolder={`${_l('l_select_provider_category')}`}
                    />
                  ) : (
                    <></>
                  )}
                  <a href="#/"
                    className={`send-arrow-btn  hw-35 with_position_right_zero ${categoryId != null ? "d-none" : ""
                      }`}
                    onClick={() => {
                      if (categoryId == null) {
                        setShowAddProviderCategory(true);
                      }

                      // if (categoryListing == null) {
                      //   setIntialProviderEmail((pre) => false)
                      // }
                    }}
                  >
                    <Plus size={18} className="c-icons" weight="light" />
                    <div
                      className={`img-width-18 ${!intialProviderEmail && categoryListing == null
                        ? "d-none"
                        : "d-none"
                        }`}
                      onClick={() => {
                        setIntialProviderEmail((pre) => true);
                        setAddInviteProvider("");
                      }}
                    >
                      <X size={18} className="c-icons" weight="light" />
                    </div>
                  </a>
                </Form.Label>
              </div>
              <div class={`d-flex mt-3 ${userType == 'operator' ? 'd-none' : ''}`}>
                <div className="flex-grow-1">{_l('l_provider')}</div>
                <div className="flex-grow-1">
                  <div className="d-flex">
                    <div className="w-50">
                      <Form.Check
                        type="radio"
                        id={`default`}
                        label={_l('l_existing')}
                        checked={selectedTab == "existing" ? true : false}
                        name="default_radio"
                        onChange={() => {
                          setSelectedTab("existing");
                          setSelectedBenchmarkOperator([])
                          setSelectedSchedule({});
                        }}
                        disabled={disableExistingTab}
                      />
                    </div>
                    <div className="w-50">
                      <Form.Check
                        type="radio"
                        id={`new`}
                        label={_l('l_add_new')}
                        name="new"
                        onChange={() => {
                          setSelectedTab("new");
                          setSelectedBenchmarkOperator([])
                          setSelectedSchedule({});
                        }}
                        checked={selectedTab == "new" ? true : false}
                      />
                    </div>
                    <div className={`w-50 ${userType == 'contact' && isCreateBenchmark ? 'd-none' : 'd-none'}`}>
                      <Form.Check
                        type="radio"
                        id={`Myr_providers`}
                        label={_l('l_my_providers')}
                        name=""
                        onChange={() => {
                        }}
                        checked={selectedTab == "Myr_providers" ? true : false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form.Group>
            
              <div className="provider-listing-wrapper">
                <div className="row">
                  <div className="col-12 overflowscroll">
                    {operatListShow ? (
                      getOperators.map((operator, index) => {
                        let isChecked = selectedBenchmarkOperator.filter((benchmarkOperatorEmail) => benchmarkOperatorEmail == operator.email)
                        // ${!isCreateBenchmark? operator.email == selectedSchedule.email ? " bg-white-03": "": "" }
                        let isCheckedOperator = isClientExist == 1 && isCreateBenchmark ? true : isClientExist == 1 && !isCreateBenchmark ? spaceClientAdded : false

                        return (
                          <div
                            className={`row border-bottom py-3 `}
                            key={index} >
                            {
                              isCreateBenchmark || (isClientExist == 1 && userType == 'operator') ?
                                <div className={`col-1 d-flex align-items-center justify-content-end ${userType == 'contact' || (isClientExist == 1 && userType == 'operator') ? '' : 'd-none'}`}>
                                  <Form.Check
                                    className=""
                                    type="checkbox"
                                    checked={userType == 'contact' ? isChecked.length ? true : false : isCheckedOperator}
                                    onChange={() => {
                                      if (isCreateBenchmark && userType == 'contact') {
                                        setBenchmarkOperator(operator.email)
                                      } else if (isClientExist == 1 && !isCreateBenchmark && userType == 'operator') {
                                        setAddedClientId(spaceClientAdded ? 0 : localStorage.getItem('spaceClientDetails') && JSON.parse(localStorage.getItem('spaceClientDetails')) && JSON.parse(localStorage.getItem('spaceClientDetails'))[0]['cp_id'] ? JSON.parse(localStorage.getItem('spaceClientDetails'))[0]['cp_id'] : 0)
                                        setSpaceClientAdded(!spaceClientAdded)

                                      }
                                    }}
                                    disabled={userType == 'contact' ? false : isClientExist == 1 && isCreateBenchmark ? true : false}
                                  />
                                </div>
                                :
                                <div className={`col-1 d-flex align-items-center justify-content-end ${userType == 'contact' ? '' : 'd-none'}`}>
                                  <Form.Check
                                    className=""
                                    type="radio"
                                    checked={Object.keys(selectedSchedule).length > 0 && operator.email == selectedSchedule.email ? true : false}
                                    onClick={() => {
                                      changeSchedule(operator);
                                    }}
                                  />
                                </div>
                            }


                            <div className="col-1 ps-0">
                              <div className="h25w25 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-1">
                                <div
                                  className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                  style={{
                                    backgroundImage: `url('${operator.profile_url
                                      ? operator.profile_url
                                      : userPlaceHolder
                                      }')`,
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div className="col-5 ps-0 with_separator_10 d-flex align-items-center"
                              data-tip={operator.email}
                              data-effect="solid"
                              data-delay-show='1000'
                              data-class="tooltip-main"

                            >
                              <div className="d-flex align-items-center w-100">
                                <div className="c-list-icon">
                                  <div className=" comman-round-box with-bg ">
                                  <EnvelopeSimple size={18} className="c-icons" weight="light" />
                                  </div>
                                </div>
                                <div className="c-list-detail c-font f-13 color-white-60 w100minus20 text-truncate px-2"
                                  data-tip={operator.email ? operator.email : ""}
                                  data-effect="solid"
                                  data-delay-show='1000'
                                  data-class="tooltip-main">
                                  {operator.email ? operator.email : ""}
                                </div>
                                <ReactTooltip />
                              </div>
                            </div>

                            <div className="col-4 d-flex align-items-center">
                              <div className="d-flex align-items-center w-100">
                                <div className={`c-list-icon ${operator.alreadyExist ? "d-none" : ""}`}>
                                  <div className=" comman-round-box with-bg ">
                                  <Phone size={18} className="c-icons" weight="light" />
                                  </div>
                                </div>
                                <div className="c-list-detail c-font f-13 color-white-60 w100minus20 text-truncate px-2"
                                  data-tip={operator.alreadyExist ? operator.full_name : operator.mobile}
                                  data-effect="solid"
                                  data-delay-show='1000'
                                  data-class="tooltip-main"
                                >
                                  { operator.alreadyExist ? operator.full_name : operator.mobile}
                                </div>
                              </div>
                            </div>
                            <div className="col-4 d-flex align-items-center d-none">
                              <div className="c-list-detail c-font f-13 color-white-60 w100minus20 text-truncate px-2"
                                data-tip={operator.full_name ? operator.full_name : "-"}
                                data-effect="solid"
                                data-delay-show='1000'
                                data-class="tooltip-main"
                              >
                                {operator.full_name ? operator.full_name : "-"}
                              </div>
                            </div>
                            {/* d-flex class remove for display none */}
                            <a href="#/" className="col-1 d-none">
                            <X size={16} className="c-icons" weight="light" />
                            </a>

                          </div>

                        );
                      })
                    ) : (
                      <>
                        {selectedTab == "existing" ? (
                          <CommanPlaceholder imgType="no-user" mainSpacing="d-none" placeholderText = {_l('l_no_operator')} />
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>

            
            <div className={`custom-class ${formShow ? "" : "d-none"}`}>
              <Form.Group className={`col-12 pp-3 mb-3`}>
                <div className="comment-input-box ">
                  <Form.Control
                    className=" "
                    placeholder={` ${userType == "contact" ? _l('l_provider') :  _l('l_client')
                      } ${_l('l_email')}`}
                    value={addInviteProvider}
                    onChange={(e) => {
                      setAddInviteProvider(e.target.value);
                    }}
                  />
                  <a href="#/"
                    className={`send-arrow-btn ${userType == "contact" && isCreateBenchmark ? "" : "d-none"
                      }`}
                    onClick={() => {
                      if (isCreateBenchmark) {
                        AddProvider();
                      }
                    }}
                  >
                    <ArrowRight size={18} className="c-icons" weight="light" />
                  </a>
                </div>
              </Form.Group>
              <Form.Group
                className={`col-12 pp-3 mb-3 ${innerFormShow ? "" : "d-none"} `}
              >
                <div className="comment-input-box ">
                  <Form.Control
                    className=" "
                    placeholder={` ${userType == "contact" ? _l('l_provider') : _l('l_client')
                      }  ${_l("l_name")}`}
                    value={providerName}
                    onChange={(e) => {
                      const reg = new RegExp("^[A-Za-z0-9 ]+$");
                      const inputValue = e.target.value;
                      if (reg.test(inputValue) || inputValue == "")
                        setName(e.target.value);
                    }}
                    disabled={alreadyExistOpeator}
                  />
                  {/* <a href="#/" className="send-arrow-btn" onClick={() => {
                    if ((selectedTab == 'new' || defaultCategory == 0) && (providerCategory > 0 || categoryId)) {
                      AddProvider()
                    }
                  }}>
                    <ArrowRightIcon
                      className="HW18 lt-black" />
                  </a> */}
                </div>
              </Form.Group>
              <Form.Group
                className={`col-12 pp-3 mb-3 ${userType == "contact" ? "d-none" : ""
                  } ${((emailAlreadyExist || defaultCategory == 0) &&
                    isCreateBenchmark == true &&
                    userType == "operator") ||
                    (isCreateBenchmark == false && operators.length == 0)
                    ? ""
                    : "d-none"
                  } `}
              >
                <div className="comment-input-box ">
                  <Form.Control
                    className=" "
                    placeholder={` ${userType == "contact" ? _l('l_provider') : _l('l_client')
                      } ${_l('l_company_name')}`}
                    value={companyName}
                    onChange={(e) => {
                      const reg = new RegExp("^[A-Za-z0-9 ]+$");
                      const inputValue = e.target.value;
                      if (reg.test(inputValue) || inputValue == "")
                        setCompanyName(e.target.value);
                    }}
                    disabled={alreadyExistOpeator}
                  />
                  {/* <a href="#/" className="send-arrow-btn" onClick={() => {
                    if ((selectedTab == 'new' || defaultCategory == 0) && (providerCategory > 0 || categoryId)) {
                      AddProvider()
                    }
                  }}>
                    <ArrowRightIcon
                      className="HW18 lt-black" />
                  </a> */}
                </div>
              </Form.Group>
              <div className={`col-12 mb-3  ${innerFormShow ? "" : "d-none"}`}>
                <div className="row">
                  <div className="  col-3 col-md-3  col-xl-3 position-relative">
                    <Select
                      className="custom-select-menu"
                      classNamePrefix="react-select"
                      options={countryCodes}
                      placeHolderText="+33"
                      value={countryCode}
                      onChange={(e) => {
                        setCountryCode({ value: e.value, label: e.label });
                      }}
                      isDisabled={alreadyExistOpeator}
                    />
                  </div>
                  <Form.Group className={`col-9 col-md-9 col-xl-9 ps-md-0`}>
                    <div className="comment-input-box ">
                      <Form.Control
                        className=" pe-0"
                        placeholder={` ${userType == "contact" ? _l('l_provider') : _l('l_client')
                          }  ${_l('l_phone_number')}`}
                        value={mobileNumber}
                        onChange={(e) => {
                          const reg = new RegExp("^[0-9+ ]+$");
                          const inputValue = e.target.value;
                          if (reg.test(inputValue) || inputValue == "")
                            setMobileNumber(inputValue);
                        }}
                        disabled={alreadyExistOpeator}
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
            <Form.Group className="col-6 pp-3 d-none">
              <div className="comment-input-box row">
                <div className="col-4">
                  <select
                    className="input_select custom_selection_single form-control"
                    name="pcode"
                  >
                    <option value="1">{_l('l_select_country_code')}</option>
                    <option value="2">{_l('l_select_country_code')}</option>
                    {/*{   countryCode.length ? 
                              ( countryCode.map((code,index) => <option value={code.code_value} key={index}   >{code.code_value}</option> ) ) : ''
                          }*/}
                  </select>
                </div>
                <div className="col-8">
                  <Form.Control
                    className=" height-45 border-0"
                    placeholder={`${_l('l_please_enter_email')}`}
                  />
                </div>
              </div>
            </Form.Group>
          </div>
          <ReactTooltip />
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-end">
        <Button variant="secondary" size="sm" onClick={handleClose}>
          {_l('l_close')}
        </Button>
        <Button
          variant="primary" size="sm"
          className={`${(selectedTab == "new" || defaultCategory == 0) &&
            (providerCategory > 0 || categoryId > 0)
            ? "d-none"
            : "d-none"
            } `}
        >
          {_l('l_add_provider')}
        </Button>
        <Button
          variant="primary" size="sm"
          className={`${(categoryListing &&
            (providerCategory > 0 || categoryId > 0) &&
            isCreateBenchmark &&
            (((addedClientId > 0 || innerFormShow || isClientExist == 1)) ||
              (selectedBenchmarkOperator.length > 0))) ||
            (isCreateBenchmark == false &&
              providerCategory > 0) ||
            (isCreateBenchmark == false &&
              Object.keys(selectedSchedule).length > 0) || (selectedTab == 'Myr_providers' && providerCategory > 0)
            ? ""
            : "d-none"
            }`}
          onClick={() => {
            if (categoryListing && (providerCategory > 0 || categoryId > 0)) {
              if ((addInviteProvider != "" && isCreateBenchmark == false) || (userType == "operator" && innerFormShow && isCreateBenchmark)) {
                AddProvider();
              } else {
                redirectToBenchmark(addedClientId > 0 ? addedClientId : 0);
              }
            }
          }}
        >
          {_l('l_create')} {isCreateBenchmark ? _l('l_benchmark') : _l('l_schedule')}
        </Button>
      </Modal.Footer>

      {
        showAddProviderCategory ? (
          <CategoryProviderList
            handleClose={() => {
              setShowAddProviderCategory(false);
            }}
            projectId={projectId}
            show={showAddProviderCategory}
            handleAddCategory={(category) => {
              // dispatch(updateProjectTopics({ filter_key: category.provider_id, title: category.name, custom_keys: [] }, 'provider_task'))
              setProviderCategory(category.provider_id);
              setCategoryTitle(category.name);
              setDefaultTopics([
                ...defaultTopics,
                { value: category.provider_id, label: category.name },
              ]);
              getOperatorsList(category.provider_id);
              dispatch(getDefaultTopics(projectId));
            }}
          />

        ) : (
          <></>
        )
      }
      <ReactTooltip />
    </Modal >
  );
};

export default SelectProviderCategory;
