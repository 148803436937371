import React, { useEffect, useState } from "react";
import { _l } from "../../../hooks/utilities";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarBlank,DotsThreeVertical, ChatCircle} from "phosphor-react";
import DeleteDropdown from "../../Dropdowns/DeleteDropdown";

const InvoiceWalletCard = () => {
    const [startDate, setStartDate] = useState(new Date());
  return (
    
    <>
      <div className="task-list-card list-view invoice-wallet-card">
        <div className="task-list-wrapper">
          <div className="d-flex w-100 align-items-start">
            <div className="d-flex task-list-detail">
              <div className="task-left flex-grow-1 width100minus160">
                <div className="d-flex align-items-center flex-grow-1 text-truncate">
                  <div className="task-top-part text-truncate flex-grow-1">
                    <div className="d-flex align-items-center w-100">
                      <div className="task-creator-img">
                        <div className="comman-image-box h20w20 rounded-circle">
                          <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{
                                backgroundImage: `url('https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80')`,
                              }}></div>
                        </div>
                      </div>
                      <a href="#/" className="max-w300 task-list-name text-truncate">Cleaning - bfsfsbff</a>
                    </div>
                  </div>
                  <div className="task-mid-part  comman_action_icon ">
                      <a href="#/" class="badge  badge-white rounded-pill me-2"><span class=" p-0">{_l('l_one_time')}</span></a>
                    
                  </div>
                </div>
                <div className="d-flex align-items-center task-bottom-part-full">
                  <div className="task-right-static-options">
                    <div className="d-flex align-items-center">
                    <div className="white-border-box p-0 d-flex calendar-input-small">
                        <a href="#/" className="custom-datepicker datepicker-input w-90 m-auto ms-1 c-font">
                          <div className="d-flex align-items-center">
                            <div className="h20w20"><CalendarBlank size={18} weight="light" className="c-icons" /></div>
                            <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={startDate}
                                    onChange={(date) => {
                                      setStartDate(date);
                                    }}
                                    placeholderText={`${_l(
                                      "l_select_date"
                                    )}`}
                                    calendarStartDay={1}
                                  ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                            </div>
                          </a>
                        </div>
                        <div className="color-white-80 c-font f-12 d-flex text-nowrap">
                          <div className=" text-nowrap with_separator_10"> {_l('l_sid')}   : #123</div>
                          <div className=" px-2 text-nowrap ">{_l('l_eid')}  : #123</div>
                        </div>
                        <div className="d-flex align-items-center radius_3 badge-white invoice-amount">
                          <span className=" color-green c-font f-14 ps-1 fw-semibold text-center title-fonts">€</span>
                          <input className="bg-transparent m_w50 color-green c-font f-14 fw-semibold text-center title-fonts border-0 "  type="text"
                            data-tip={`${_l("l_amount")}`}
                            data-effect="solid"
                            data-delay-show='1000'
                            value ="500"
                            data-class="tooltip-main" />
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              <div className="task-assign-section">
                <div className="d-flex align-items-center justify-content-end">
                  <div className="dropdown h20w20  ">
                    <a href="#/" type="button" className="line-height-0 light-theme-img"
                    id="ActiveOfficeOptionsDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <DotsThreeVertical size={18} weight="light" className="c-icons" />
                    </a>
                    <DeleteDropdown
                                            edit={false}
                                            handleDelete={() => {}}
                                        />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default InvoiceWalletCard