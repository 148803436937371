import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTaskList,
  leftPanelFilters,
  getTopicTasks,
  projectTaskCounts, 
  setTaskTypeFilter, 
  ProductTour, 
  getSelectedTask, 
  taskBulkAction, 
  getTaskCount, 
  getSpaceFloorList,
  getMentionList,
  updatedTaskList,
  setAllCheckedTask,
  setTasklistLoader,
  SetSelectedTask,
  commanoffcanvasisOpen
} from "../actions/customer";
import { X, CaretDoubleDown, DotsThreeVertical } from "phosphor-react";
import CommanOffCanvas from "./Offcanvas/CommanOffCanvas";
import TaskCard from "./TaskCard";
import jquery, { get } from "jquery";
import Accordion from "react-bootstrap/Accordion";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import { HideDropdown, _l, showLeftPanel } from "../hooks/utilities";
import Form from 'react-bootstrap/Form';
import CommanLoader from "./Loader/CommanLoader";
import 'intro.js/introjs.css';
import { Steps } from 'intro.js-react';
import ActionDropdown from "./Dropdowns/ActionDropdown";
import { showMessage, showConfirmation } from "../actions/messages";
import customerServices from "../services/customer-services";
import CustomerServices from "../services/customer-services";
import { contactintrostep, Staffintrosteps } from "../components/IntroComponents/IntroSteps";
import CommanPlaceholder from "./Placeholder/CommanPlaceholder";
import outlookCalendar from "../services/outlook-calendar";
import { getFloorDetails } from "../actions/benchmark-actions/benchmark-actions";
import CreateTaskOffCanvasNew from "./Offcanvas/CreateTaskOffCanvasNew";
const Tasks = ({ projectId, pageName, kanbanView = false }) => {
  const {
    calendarData,
    extraCountTask,
    taskList,
    taskFilters,
    activeTaskStatusFilters,
    isAppliedUnreadComment, taskTypeFilter,
    globalSearchString,
    getSelectedCalendarDate,
    globalView,
    pinDelayfilter,
    getCheckedTask,
    tasklistLoader,
    dateView,
    createTaskModalIsopen,
    commanoffcanvasIsOpen
  } = useSelector((state) => state.customer);
  const isProductTourGuide = useSelector((state) => state.customer.productTour);
  const dispatch = useDispatch();
  const [showDocumentCanvas, setShowDocumentCanvas] = useState(false);
  const [checkedTask, setCheckedTask] = useState([]);

  const [commonModalData, setCommonModalData] = useState();
  const [ProductTourTask, setProductTourTask] = useState(false);
  const mentionsList = useSelector((state) => state.customer.mentionList);
  const [mentionList, setMentionList] = useState([]);
  const [todoTasks, setTodoTasks] = useState([]);
  const [inProgressTasks, setInProgressTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [requestTasks, setRequestTasks] = useState([]);
  const [taskType, setTaskType] = useState('2');
  //type 2 means all task
  const [todoPage, setTodoPage] = useState(1);
  const [inProgressPage, setInProgressPage] = useState(1);
  const [completedPage, setCompletedPage] = useState(1);
  const [requestPage, setRequestPage] = useState(1);
  const [liteversionPage, setLiteversionPage] = useState(1);

  const [selectedTaskId, setSelectedTaskId] = useState(0);
  const [prevCalendarObject, setPrevCalendarObject] = useState("");
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showReqestedTaskModal, setShowReqestedTaskModal] = useState(false);
  const [IsAttachmentCountUpdate, setIsAttachmentCountUpdate] = useState(false);
  const [convertTaskId, setConvertTaskId] = useState(0);
  const [leftPanelActiveFilters, setLeftActivePanelFilters] = useState([]);
  const [taskStaus, setTaskStaus] = useState("");
  const [imageCount, setImageCount] = useState(0);
  const [taskCountStausWise, setTaskCountStausWise] = useState({});
  const [toastHeader, setToastHeader] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [toastClass, setToastClass] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [defaultActiveKeys, setDefaultActiveKeys] = useState([]);
  const [taskSearchString, setTaskSearchString] = useState("");
  const [initialMount, setInitialMount] = useState(true);
  const [loder, setLoder] = useState({
    todoLoder: false,
    inProgressLoder: false,
    doneLoder: false,
    requestLoder: false,
    liteversionLoder: false,
  });
  const client_id = localStorage.getItem("client_id");
  const contact_id = localStorage.getItem("contact_id");
  const defaultTopics = useSelector((state) => state.customer.defaultTopics);
  const topicData = useSelector((state) => state.customer.topicData);
  const handleClose = () => setShowReqestedTaskModal(false);
  const handleShow = () => setShowReqestedTaskModal(true);
  const [searchTaskShow, setsearchTaskShow] = useState();
  const selectedTask = useSelector((state) => state.customer.selectedTask);
  const [searchTaskCLose, setsearchTaskCLose] = useState(false);
  const [task_type, setTask_type] = useState('');
  const [IsKanbanView, setIsKanbanView] = useState(kanbanView);
  const [taskPinned, setTaskPinned] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [meetingUrl, setMeetingUrl] = useState("");
  const [callUserList, setCallUserList] = useState([]);
  const [taskPage, setTaskPage] = useState(1);
  const [showTaskList, setshowTaskList] = useState(true);
  const [taskTitle, setTaskTitle] = useState("");
  const [checklistItems, setChecklistItems] = useState([]);
  const [liteversionTasklist, setliteversionTasklist] = useState([])
  const [bulkActionAllTask, setBulkActionAllTask] = useState({
    todo: false,
    inProgress: false,
    done: false,
    request: false
  })
  const userType = localStorage.getItem("user_type");
  const [selectedTaskIdforIconToggle, setSelectedTaskIdforIconToggle] = useState(0);
  const [showDefaultIcons, setShowDefaultIcons] = useState([]); 


  const checklistStateData = useSelector(
    (state) => state.customer.taskChecklist
  );
const [taskId ,setTaskId] = useState()
  useEffect(() => {
    let checkedList = checklistStateData &&  checklistStateData.filter((data) => data.finished == 1 || data.finished == "true");
    let checkedDropDownData = taskList && taskList.filter((data)=>data.id == taskId)
    let tasklistNew = taskList;
    if (checklistStateData && checklistStateData !== undefined) {
    if (selectedTask && tasklistNew) {
      let index = tasklistNew.findIndex((data)=> selectedTask.id == data.id);

      if (index > -1) {
        tasklistNew[index].checklist_progress = Math.ceil((checkedList.length * 100) / checklistStateData.length) || 0 ;
        dispatch(updatedTaskList(tasklistNew));
      }
    }
    if (checkedDropDownData) {
      let index = tasklistNew.findIndex((data)=> taskId == data.id  );
      if (index > -1) {
        tasklistNew[index].checklist_progress = Math.ceil((checkedList.length * 100) / checklistStateData.length) || 0 ;
        dispatch(updatedTaskList(tasklistNew));
      }
    }}
  }, [checklistStateData])

  useEffect(() => {
    if(typeof globalSearchString !== 'undefined') {
      setTaskSearchString(globalSearchString);
    }
  }, [globalSearchString]);

  useEffect(() => {
    setIsKanbanView(kanbanView)
  }, [kanbanView])
  

  useEffect(() => {
    if (isProductTourGuide) {
      setProductTourTask(true);
      setEnabled(true);
      jquery("body").addClass("intro-js");
    } else {
      setProductTourTask(false);
      setEnabled(false);
      jquery("body").removeClass("intro-js");
    }
  }, [isProductTourGuide, contactintrostep]);
  useEffect(() => {
      if (!showTaskModal) {
        setShowTaskModal(false);
      }

      if (taskStaus != "") {
        setTodoPage(1);
        setInProgressPage(1);
        setCompletedPage(1);
        setRequestPage(1);
        setLiteversionPage(1)
        setTaskStaus("");
      }

      setPrevCalendarObject(
        getSelectedCalendarDate
      );
      if (prevCalendarObject && prevCalendarObject !== getSelectedCalendarDate) {
        setTodoTasks([]);
        setInProgressTasks([]);
        setCompletedTasks([]);
        setRequestTasks([]);
        setliteversionTasklist([])
      }
      if (localStorage.getItem('is_task_delete')) {
        localStorage.removeItem('is_task_delete')
      } else {
        // initial taskList API Call form here so comment API call
    const savedDate = localStorage.getItem("selectedDate");
      }
    // }
  }, [JSON.stringify(activeTaskStatusFilters)]);
  
  

  useEffect(() => {
    if (taskStaus != "") {
      if (taskStaus == 1) setTodoPage(todoPage);
      else if (taskStaus == 4) setInProgressPage(inProgressPage);
      else if (taskStaus == 5) setCompletedPage(completedPage);
      else if (taskStaus == 0) setRequestPage(requestPage);
    }
    if (localStorage.getItem("request_task") == 1) {
      localStorage.removeItem("request_task");
      setTodoPage(todoPage);
    }

    // << -- code commented bcz it clear task list after task list API response  -- >>
    setTodoTasks([]);
    setInProgressTasks([]);
    setCompletedTasks([]);
    setRequestTasks([]);
    setliteversionTasklist([])
    setLoder({
      todoLoder: false,
      inProgressLoder: false,
      doneLoder: false,
    });
    getProjectTasks();
  }, [
    JSON.stringify(taskList),
    taskSearchString,
    calendarData,
    isAppliedUnreadComment, taskTypeFilter ,taskPinned,pinDelayfilter,globalView
  ]);

  // useEffect(() => {

  //   // this code commented because of shard task note open

  //   // setShowTaskModal(false);
  //   // dispatch(SetSelectedTask());
  //   // dispatch(commanoffcanvasisOpen(false));
  //   // setSelectedTaskId(0);
  //   // showLeftPanel();
  // }, [globalView])
  

  useEffect(() => {
    jquery("#filter-show-list").on("click", function (event) {
      jquery("#show-filter-list").toggleClass("block");
    });
    setInitialMount(false);
  }, []);

  useEffect(()=>{
    dispatch(getSpaceFloorList());
    dispatch(getMentionList(0,"task",0));
    dispatch(getFloorDetails(projectId));
  },[projectId])

  useEffect(() => {
    const savedDate = localStorage.getItem("selectedDate");
    if (taskFilters && taskFilters.length) {
      setTodoPage(1);
      setInProgressPage(1);
      setCompletedPage(1);
      setRequestPage(1);
      setLiteversionPage(1)
      setLoder({
        todoLoder: false,
        inProgressLoder: false,
        doneLoder: false,
        requestLoder: false,
        liteversionLoder : false
      });
      setLeftActivePanelFilters(taskFilters);
      // if (projectId > 0) {
      // dispatch(setTasklistLoader(true))
      // dispatch(
      //   getTaskList(
      //     client_id,
      //     contact_id,
      //     projectId,
      //     moment(savedDate).format("YYYY-MM-DD"),
      //     activeTaskStatusFilters.length ? 2 : 1,
      //     activeTaskStatusFilters,
      //     taskFilters,
      //     "",
      //     0,
      //     1,
      //     "",
      //     "",
      //   )
      // );
      // }
    } else if ( taskFilters && !taskFilters.length) {
      
    }
  }, [JSON.stringify(taskFilters),JSON.stringify(activeTaskStatusFilters) ,projectId , getSelectedCalendarDate]);

  useEffect(() => {
    if (topicData && !leftPanelActiveFilters.length) {
      let filters = [];
      topicData.topics &&
        topicData.topics.forEach((topic) => {
          topic.categories.forEach((category) => {
            filters.push({
              main_key: topic.main_key,
              filter_key: category.filter_key,
              title: category.title,
            });
          });
        });
      
    }
  }, [topicData, JSON.stringify(leftPanelActiveFilters)]);

  useEffect(() => {
    if (IsAttachmentCountUpdate) {
      if (selectedTask.status == "1") {
        setTodoTasks((todoTasks) =>
          todoTasks.map((obj) => {
            if (obj && obj.id == selectedTask.id) {
              let tempImageCount = imageCount ? imageCount : 1;
              setImageCount(0);
              return {
                ...obj,
                attachments_count: obj.attachments_count + tempImageCount,
              };
            }
            return obj;
          })
        );
      } else if (selectedTask.status == "4") {
        setInProgressTasks((inProgressTasks) =>
          inProgressTasks.map((obj) => {
            if (obj && obj.id == selectedTask.id) {
              let tempImageCount = imageCount ? imageCount : 1;
              setImageCount(0);
              return {
                ...obj,
                attachments_count: obj.attachments_count + tempImageCount,
              };
            }
            return obj;
          })
        );
      } else if (selectedTask.status == "5") {
        setCompletedTasks((completedTasks) =>
          completedTasks.map((obj) => {
            if (obj && obj.id == selectedTask.id) {
              let tempImageCount = imageCount ? imageCount : 1;
              setImageCount(0);
              return {
                ...obj,
                attachments_count: obj.attachments_count + tempImageCount,
              };
            }
            return obj;
          })
        );
      }
      setIsAttachmentCountUpdate(false);
    }
  }, [IsAttachmentCountUpdate, todoTasks, inProgressTasks, completedTasks]);
 

  useEffect(() => {
    setTaskType(2)
    if (taskTypeFilter && taskTypeFilter != '') {
      setTaskType(taskTypeFilter == 'requestTask' ? 1 : taskTypeFilter == 'Task' ? 0 : 2)
    }
  }, [taskTypeFilter])

  useEffect(() => {
    if (selectedTask) {
     
      setTask_type(selectedTask.is_requested && selectedTask.is_requested == 1 ? 'request-task' : 'task')
      setCommonModalData(selectedTask)
      setSelectedTaskId(selectedTask.task_id || selectedTask.id)
      // setSelectedTaskId( selectedTask.id)
      if (selectedTask.is_requested == 1) {
        handleShow();
      }
      if(selectedTask.task_id || selectedTask.id)
      {
        setShowTaskModal(true);
      }
    }else{
      setShowTaskModal(false)
      setSelectedTaskId(0)
    }
    jquery(document).ready(function () {
      jquery("input").change(function () {
        // setUnsavedChanges(true);
      });
    });

    return () => {
      jquery(document).unbind("ready");
    };
  }, [selectedTask]);
  
  useEffect(() => {
    if (mentionsList) {
      setMentionList(
        mentionsList.map((name) => {
          return {
            display: name.name,
            id: name.id,
            image: name.profile_image || "",
          };
        })
      );
    }
  }, [mentionsList]);
  const getProjectTasks = () => {
    var taskUnreadCmtCntAll = 0;
    if (taskList && taskList.length) {
      let tempTaskList = taskList.slice();
      if(activeTaskStatusFilters && activeTaskStatusFilters.length)
      {
        tempTaskList.sort((a, b) => moment(a.startdate).isAfter(moment(b.startdate)) ? 1 : -1)
      }
      let todoCount = 0;
      let inProcessCount = 0;
      let doneCount = 0;
      let requestCount = 0;
      let keyArray = [];
      setTodoPage(1);
      setInProgressPage(1);
      setCompletedPage(1);
      setRequestPage(1);
      setLiteversionPage(1);
      if (isAppliedUnreadComment == true || (taskTypeFilter && taskTypeFilter != '') || pinDelayfilter.length > 0) {
        let tempTaskType = taskTypeFilter != 'all' ? taskTypeFilter == 'requestTask' ? 1 : 0 : 'all'
        tempTaskList = taskList.filter(
          (task) => 
          (pinDelayfilter.includes("pinned") && pinDelayfilter.includes("overdue") )&& (taskFilters.length == 0 || taskFilters.some((key) => key.main_key == "date_range_filter")) ?
              task.pinned == 1 || task.unread_delay_comment_count > 0
              : ((pinDelayfilter.includes("pinned")) && (taskFilters.length == 0 || taskFilters.some((key) => key.main_key == "date_range_filter") )) ?
                task.pinned == 1
                : ((pinDelayfilter.includes("overdue")) && (taskFilters.length == 0 || taskFilters.some((key) => key.main_key == "date_range_filter"))) ?
                  task.unread_delay_comment_count > 0 
                  // :isAppliedUnreadComment == true ? 
                  // task.unread_comment_count > 0 
                  : task
        ); //Added flag waiting_for_response to prevent the task from diappearing from list as and when comment opened
      }
      setliteversionTasklist(orderTask(tempTaskList));
      let requestTask = []
      let todoTask = []
      let inprogressTask = []
      let doneTask = []
      tempTaskList
        .filter((task) =>
          (task.name || "")
            .toLowerCase()
            .includes(taskSearchString.toLowerCase())
        )
        .map((task) => {
          if (task.unread_comment_count > 0) {
            taskUnreadCmtCntAll++;
          }
          if (task.is_requested == 1) {
            requestTask.push(task)
            // setRequestTasks((requestTasks) => [...requestTasks, task]);
            requestCount++;
          } else {
            switch (task.status) {
              case "1":
                todoTask.push(task)
                // setTodoTasks((todoTasks) => [...todoTasks, task]);
                todoCount++;
                break;
              case "4":
                inprogressTask.push(task)
               
                inProcessCount++;
                break;
              case "5":
                doneTask.push(task)
               
                doneCount++;
                break;
              default:
                break;
            }
          }
        });
      setRequestTasks(orderTask(requestTask))
      setTodoTasks(orderTask(todoTask))
      setInProgressTasks(orderTask(inprogressTask))
      setCompletedTasks(orderTask(doneTask))
     

      if (requestCount > 0) {
        keyArray.push("0");
      }
      if (todoCount > 0) {
        keyArray.push("1");
      }

      if (inProcessCount > 0) {
        keyArray.push("2");
      }

      if (doneCount > 0) {
        keyArray.push("3");
      }

      setDefaultActiveKeys(keyArray);
    }
    if (extraCountTask && Object.keys(extraCountTask).length > 0) {
      setTaskCountStausWise(extraCountTask);
    }
    // dispatch(setTaskUnreadCmtCntAll(taskUnreadCmtCntAll));
  };

  const getMeetingLink = (TaskId, calendar_id = "") => {
 
    outlookCalendar.getMeetingLink(TaskId, projectId, calendar_id).then((res) => {
      if (res.status == 1) {
        setMeetingUrl(res.data.meetingUrl);
      } else {
        setMeetingUrl("");
      }
    });
};

  const handleSelectedTask = (
    taskId = 0,
    show = "",
    isRequestedTask = null,
    convertRequestTaskId = 0
  ) => {

    getMeetingLink(taskId);
    setShowTaskModal(false);
    setSelectedTaskId(taskId); 
    setConvertTaskId(convertRequestTaskId);
    localStorage.setItem("SelectedTask", taskId);
    localStorage.setItem(
      "TaskType",
      isRequestedTask && isRequestedTask != null ? isRequestedTask : 0
    );
    if (isRequestedTask || taskId) {

      setShowTaskModal(show);
    }
  };

  const orderTask = (taskArr) => {
    let pinTasks = [];
    let priorityTask = [];
    let otherTask = [];
    let unreadTask = [];
    taskArr.forEach((task) => {
      if(task.unread_comment_count > 0){
        unreadTask.push(task)
      } else if (task.pinned == 1) {
        pinTasks.push(task)
      } else if (task.priority == '3') {
        priorityTask.push(task)
      } else {
        otherTask.push(task)
      }
    })
    return unreadTask.concat( priorityTask, pinTasks, otherTask)
  }
  
  const scroll = (status) => {
    const savedDate = localStorage.getItem("selectedDate");
    let page;
    let taskStaus = status;
    if (status == 0) {
      page = requestPage + 1;
      setRequestPage(page);
    } else if (status == 1) {
      page = todoPage + 1;
      setTodoPage(page);
    } else if (status == 4) {
      page = inProgressPage + 1;
      setInProgressPage(page);
    } else if(status == "all") {
      page = liteversionPage + 1;
      setLiteversionPage(page)
      setTaskPage(page);
    } else {
      page = completedPage + 1;
      setCompletedPage(page);
    }
    setTaskStaus(status);
    taskStaus = [status];
    var isDateActive = 1;
    if (!jquery("#active_week_date").hasClass("c-gradinet-active-border")) {
      isDateActive = 0;
    }
    if (status == 0) {
      setLoder({ ...loder, ["requestLoder"]: false });
    } else if (status == 1) {
      setLoder({ ...loder, ["todoLoder"]: false });
    } else if (status == 4) {
      setLoder({ ...loder, ["inProgressLoder"]: false });
    } else if (status == 5) {
      setLoder({ ...loder, ["doneLoder"]: false });
    } else if (status == "all"){
      setLoder({ ...loder, ["liteversionLoder"]: false });
    }
  };

  const handleAccordianExpansion = (key) => {
    const index = defaultActiveKeys.indexOf(key);
    if (index === -1) {
      setDefaultActiveKeys(defaultActiveKeys.concat(key));
    } else {
      setDefaultActiveKeys(
        defaultActiveKeys.filter((selected) => selected !== key)
      );
    }
  };
  const updateAttchmentChecklistCount = (taskid, imageCount = 0) => {
    setImageCount(imageCount ? imageCount : 0);
    if (selectedTask) {
      setIsAttachmentCountUpdate(true);
    }
  };

  const updateTaskSearchString = () => {
    var string = jquery("#task-search-string").val();
    setTaskSearchString(string);
    getProjectTasks();
  };
  function taskCountToggle(id) {
    jquery("#" + id + "_count_display").addClass("d-none");
    if (
      jquery("#" + id + "_count")
        .parent()
        .hasClass("show")
    ) {
      jquery("#" + id + "_count_display").removeClass("d-none");
    }
  }
  const taskDelete = () => {
    setToastClass("success");
    setToastHeader(_l("l_success"));
    setToastMessage(_l("l_task_sucessfully_removed"));
    setShowToast(true);
  };

  const Email = localStorage.getItem("email");
  const Password = localStorage.getItem("password");
  const [enabled, setEnabled] = useState(false);
  const [initialStep, setInitialStep] = useState(0);
  const onExit = () => {
    setEnabled(false)
  }
  const getTask = (id, is_requested = null) => {
    if (!selectedTask || selectedTask.id !== id) {
      dispatch(
        getSelectedTask(
          localStorage.getItem("selectedOffice"),
          id,
          is_requested
        )
      );
    }
  };
  const taskCheckUncheck = (task_id) => {
    const taskPosition = checkedTask.findIndex(x => x == task_id)
    if (taskPosition === -1) {
      // checkedTask.push(task_id);
      setCheckedTask([...checkedTask, task_id])
    }
    else {
      setCheckedTask(checkedTask.filter((taskId) => taskId != task_id))
    }
  }
  const [filterTask, setFilterTask] = useState([]);
  useEffect(() => {
    if (checkedTask.length > 0) {
      let Arr = [];
      for (let i = 0; i < checkedTask.length; i++) {
        for (let j = 0; j < taskList.length; j++) {
          if (checkedTask[i] == taskList[j].id) {
            Arr.push(taskList[j])
          }
        }
      }
      setFilterTask(Arr);
    }
    dispatch(setAllCheckedTask(checkedTask));
  }, [checkedTask])

  useEffect(() => {
    if (getCheckedTask) {
      setCheckedTask(getCheckedTask);
    }
  },[getCheckedTask])

  const handleTaskBulkAction = (is_delete = 0, status, actionType, files) => {
    if (checkedTask && checkedTask.length == 0) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_select_task"))
      );
      return false
    }else if(actionType == "set_assignee" || actionType == "comment") {
      customerServices.taskBulkAction(checkedTask, actionType, status, files).then((res) => {
        if (res.status) {
          setCheckedTask([])
          setBulkActionAllTask({
            todo: false,
            inProgress: false,
            done: false,
            request: false
          })
          dispatch(
            projectTaskCounts(
              projectId,
              taskFilters
                ? taskFilters
                : [],
              "",
            )
          );
          dispatch(
            getTaskCount(
              0,
              0,
              localStorage.getItem("selectedOffice"),
              moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD"),
              taskFilters
                ? taskFilters
                : [],
              "",
              activeTaskStatusFilters ? activeTaskStatusFilters : []
            )
          );
        }
        dispatch(showMessage(res.status ? "sucess" : "unsucess", res.status ? _l("l_success") : _l("l_error"), res.message ? res.message : ''))
      })
    }else if(actionType == "set_due_date"){
      dispatch(
        showConfirmation(
          _l("l_are_you_sure"),
          _l("l_you_want_to_change_due_date_of_selected_tasks"),
          _l("l_yes"),
          _l("l_no"),
          undefined,
          () => {
            customerServices.taskBulkAction(checkedTask, actionType, status).then((res) => {
              if (res.status) {
                dispatch(
                  projectTaskCounts(
                    projectId,
                    taskFilters
                      ? taskFilters
                      : [],
                    "",
                  )
                );
                dispatch(
                  getTaskCount(
                    0,
                    0,
                    localStorage.getItem("selectedOffice"),
                    moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD"),
                    taskFilters
                      ? taskFilters
                      : [],
                    "",
                    activeTaskStatusFilters ? activeTaskStatusFilters : []
                  )
                );
                setCheckedTask([])
                setBulkActionAllTask({
                  todo: false,
                  inProgress: false,
                  done: false,
                  request: false
                })
                dispatch(taskBulkAction(checkedTask, is_delete ? 'delete_task' : status == 'task_convert_to_request' ? 'task_convert_to_request' : 'status_change', is_delete ? '' : status == 'task_convert_to_request' ? 'task_convert_to_request' : status))
              }

              dispatch(
                showMessage(res.status ? "sucess" : "unsucess", res.status ? _l("l_success") : _l("l_error"), res.message ? res.message : '')
              );

            })

          }
        )
      );
    } else {
      dispatch(
        showConfirmation(
          _l("l_are_you_sure"),
          `${is_delete ? _l("l_you_want_tot_delete_this_task") : _l("l_you_want_to_change_task_status")}`,
          _l("l_yes"),
          _l("l_no"),
          undefined,
          () => {
            customerServices.taskBulkAction(checkedTask, is_delete ? 'delete_task' : status == 'task_convert_to_request' ? 'task_convert_to_request' : 'status_change', is_delete ? '' : status == 'task_convert_to_request' ? '' : status).then((res) => {
              if (res.status) {
                dispatch(
                  projectTaskCounts(
                    projectId,
                    taskFilters
                      ? taskFilters
                      : [],
                    "",
                  )
                );
                dispatch(
                  getTaskCount(
                    0,
                    0,
                    localStorage.getItem("selectedOffice"),
                    moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD"),
                    taskFilters
                      ? taskFilters
                      : [],
                    "",
                    activeTaskStatusFilters ? activeTaskStatusFilters : []
                  )
                );
                setCheckedTask([])
                setBulkActionAllTask({
                  todo: false,
                  inProgress: false,
                  done: false,
                  request: false
                })
                dispatch(taskBulkAction(checkedTask, is_delete ? 'delete_task' : status == 'task_convert_to_request' ? 'task_convert_to_request' : 'status_change', is_delete ? '' : status == 'task_convert_to_request' ? 'task_convert_to_request' : status))
              }

              dispatch(
                showMessage(res.status ? "sucess" : "unsucess", res.status ? _l("l_success") : _l("l_error"), res.message ? res.message : '')
              );

            })

          }
        )
      );
    }
  }
  const handleAllTaskBulkAction = (is_checked, taskType) => {
    let selectedBulkIds = checkedTask.length ? checkedTask : []
    let task_list = taskType == 'requestTasks' ? requestTasks : taskType == 'todoTasks' ? todoTasks : taskType == 'inProgressTasks' ? inProgressTasks : completedTasks
    // let status = taskType == 'requestTasks' ? 0 : taskType == 'todoTasks' ? 1 : taskType == 'inProgressTasks' ? 4 : 5

    task_list.forEach((task) => {
      if (is_checked && !selectedBulkIds.includes(task.id) && task.is_allowed_to_edit == 1) {
        selectedBulkIds.push(task.id)
      } else if (selectedBulkIds.includes(task.id) && task.is_allowed_to_edit == 1) {
        let index = checkedTask.findIndex(x => x == task.id)
        if (index != -1) {
          selectedBulkIds.splice(index, 1);
        }

      }
    })
    setCheckedTask(selectedBulkIds)

    
  }
  
  useEffect(() => {
    CustomerServices.getContactsByClient().then((res) => {
      if(res.status){
        setContacts(res.data);
      }
    });
  }, [])

  useEffect(() => {
  }, [userType ,localStorage.getItem("selectedOffice")])

  const handleToggleIcons = (isTaskPinned,taskAssignee,taskAssigneePills,taskPriority,comments_count,rating,setTaskCardIconToggle,taskCardIconToggle) =>{
    if(taskCardIconToggle){
      let tempDefaultIcons = []

      if (isTaskPinned) {
        tempDefaultIcons.push("pin")
      }
      if (taskAssignee.length > 0) {
        tempDefaultIcons.push("peopels")
      }
      if (taskAssigneePills.length > 0) {
        tempDefaultIcons.push("pills")
      }
      if (taskPriority == 3) {
        tempDefaultIcons.push("priority")
      }
      if(comments_count > 0){
        tempDefaultIcons.push("comment")
      }
      if(rating && rating > 0){
        tempDefaultIcons.push("star")
      }   
      if(pageName == "LiteVersion"){
        setShowDefaultIcons(tempDefaultIcons)
      }else{
        setShowDefaultIcons(["calendar","assigneTo","pin","peopels","pills","priority","cube","map","status","comment","star",,"delete","share","call", "template"])
      }
      setTaskCardIconToggle(false)
    }else{
      let tempDefaultIcons = ["calendar","assigneTo","pin","peopels","pills","priority","cube","map","status","comment","star",,"delete","share","call", "template"]
      if(pageName == "LiteVersion"){
        setShowDefaultIcons(tempDefaultIcons)
      }else{
        setShowDefaultIcons(["calendar","assigneTo","pin","peopels","pills","priority","cube","map","status","comment","star",,"delete","share","call", "template"])
      }
      setTaskCardIconToggle(true)
    }

  }

  return (
    <>
      <Steps
        enabled={enabled}
        steps={
          userType == "staff" ? Staffintrosteps : contactintrostep}
        initialStep={initialStep}
        onExit={onExit}
        options={{
          hideNext: false,
          exitOnOverlayClick: false,
          scrollToElement: true,

        }}
        onBeforeChange={nextStepIndex => {

          if (nextStepIndex === 0) {
            console.log("0");
            // jquery('.intro-office-list').addClass('expanded');
          }
          if (nextStepIndex === 1) {
            console.log("1");

            // jquery('.intro-office-list').addClass('expanded');
          }
          if (nextStepIndex === 2) {
            console.log("2");
           
            // jquery('.provider-list').addClass('expanded');
            jquery('.provider-list').css({ transform: "translateX(0px)", opacity: "1" });
           
          }
          if (nextStepIndex === 3) {
            jquery('.provider-list').css({ transform: "translateX(0px)", opacity: "1" });
          }
          if (nextStepIndex === 4) {
          }
          if (nextStepIndex === 12) {
          }
          if (nextStepIndex === 13) {
          }
          if (nextStepIndex === 14) {
            jquery('#BottomMenushow').addClass("show-menu-link");
            jquery('#BottomMenushow').css({ transform: " scale(1)" });
            jquery('.rotate-image').addClass("plus-rotate-image");
          }
          if (nextStepIndex === 15) {
            jquery('#BottomMenushow').addClass("show-menu-link");
            jquery('#BottomMenushow').css({ transform: " scale(1)" });
            jquery('.rotate-image').addClass("plus-rotate-image");
          }
          if (nextStepIndex === 16) {
            jquery('#BottomMenushow').addClass("show-menu-link");
            jquery('#BottomMenushow').css({ transform: " scale(1)" });
            jquery('.rotate-image').addClass("plus-rotate-image");
          }
          if (nextStepIndex === 17) {
            jquery('#BottomMenushow').addClass("show-menu-link");
            jquery('#BottomMenushow').css({ transform: " scale(1)" });
            jquery('.rotate-image').addClass("plus-rotate-image");
          }
          if (nextStepIndex === 18) {
            jquery('#BottomMenushow').addClass("show-menu-link");
            jquery('#BottomMenushow').removeAttr("style");
            jquery('.rotate-image').addClass("plus-rotate-image");
          }
          if (nextStepIndex === 19) {
            jquery('#BottomMenushow').removeClass("show-menu-link");
            jquery('#BottomMenushow').removeAttr("style");
            jquery('.rotate-image').removeClass("plus-rotate-image");
          }
          if (nextStepIndex === 20) {
            jquery('#BottomMenushow').removeClass("show-menu-link");
            jquery('#BottomMenushow').removeAttr("style");
            jquery('.rotate-image').removeClass("plus-rotate-image");
          }
        }
        }
        onChange={nextStepIndex => {
          if (nextStepIndex === 0) {
            handleSelectedTask(
              0,
              true,
              0,
              0
            );
            getTask(0, 0);
          }
          if (nextStepIndex === 19) {
            handleSelectedTask(
              252567,
              true,
              0,
              0
            );
            getTask(252567, 0);
          }
          if (nextStepIndex === 20) {
            handleSelectedTask(
              0,
              true,
              0,
              0
            );
            getTask(0, 0);
          }
        }}
        onBeforeExit={nextStepIndex => {
          setProductTourTask(false);
          setEnabled(false);
          dispatch(
            ProductTour(false)
          );
          jquery("body").removeClass("intro-js");
        }
        }
        refresh={nextStepIndex => {
          if (nextStepIndex === 0) {
          }
        }}
      />
      { pageName != "dashboardnew" && globalView == "kanbanView"? 
        // isLoadingData("task_list")
          <div
          id="liteversion_view"
          className={`comman-main-box custom-accordian-main res-padding-right0 h-100 task-list-accordian px-0 ${globalView == "kanbanView" ? "pb-0 kanban-view overflow-hidden horizontal-scroll":""} ${showTaskModal || showReqestedTaskModal ? "list-view-toggle" : ""
            } ${IsKanbanView ? "" : ""}`}
        >

          <Accordion
            className="task-list-accordian-main with_checkbox_absolute mt-0"
            onSelect={(eventKey) => {
              handleAccordianExpansion(eventKey);
            }}
            activeKey={defaultActiveKeys}
          >
            <div className="pill-wrapper with-right-pos d-none" id="">
              
              <div className="pill-wrappe-modal-overlay"></div>
              <div id="" className="">
                <div
                  className="d-flex align-items-center filter-lists-menu min-width-190 justify-content-end pb-2"

               
                >
                  
                  {
                    taskType == 0 || taskType == 1 ?
                      <div className="badge w-auto color-white-80 bg-white-05  radius_3 me-2">
                        <div className="d-flex align-items-center">

                          <span className="color-white">
                            {taskType ? _l('l_request_task') : _l('l_task')}
                          </span>
                          <a href="#/"
                            className="ps-1"
                            onClick={() => {
                              dispatch(setTaskTypeFilter(_l('l_all')))
                            }}
                          >
                            <X size={14} weight="light" className="c-icons" />
                          </a>
                        </div>
                      </div>
                      : <></>
                  }

                  {leftPanelActiveFilters.length ? (
                    leftPanelActiveFilters.map((value, index) => {
                      if (value.main_key == "date_range_filter") {
                        let displayFilterKey = value.title;
                        return (
                          <div
                            key={index}
                            className="badge c-font f-12 w-auto color-white-80 bg-white-05  radius_3 ms-2 comman-round-box active py-1 d-none"
                          >
                            <div className="d-flex align-items-center">
                              

                              <span className="color-white">
                                {displayFilterKey}
                              </span>
                              <a href="#/"
                                className="ps-1"
                                onClick={() => {
                                  dispatch(
                                    leftPanelFilters({
                                      main_key: value.main_key,
                                      filter_key: value.filter_key,
                                      title: value.title,
                                      main_sub_key: value.main_sub_key,
                                    })
                                  );
                                  dispatch(
                                    projectTaskCounts(projectId, taskFilters, {
                                      main_key: value.main_key,
                                      filter_key: value.filter_key,
                                      title: value.title,
                                    })
                                  );
                                  dispatch(
                                    projectTaskCounts(
                                      projectId,
                                      taskFilters,
                                      '')
                                  );
                                  dispatch(getTopicTasks(projectId,"",0,localStorage.getItem('selectedDate'),taskFilters));
                                  
                                }}
                              >
                                <X size={14} className="c-icons" weight="light" />
                              </a>
                            </div>
                          </div>
                        );
                      }
                    })
                  ) : (
                    <></>
                  )}
                  <div className={`comman_action_icon ms-2 pe-1 dropdown d-none ${showTaskModal || showReqestedTaskModal ? "d-none" : ""}`}>
                  <a href="#/"
                      type="button"
                      className={`line-height-0 light-theme-img action_icon h28w28 `}
                      id="viewDropdwon"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-auto-close="outside"
                      
                    >
                      <DotsThreeVertical
                        size={16}
                        weight="light"
                        className="c-icons"
                      />
                    </a>
                    <ActionDropdown widthClass="w-170" 
                      actions={[
                        {
                          actionName: _l("l_list_view"),
                          actionIcon: "ListBulletsIcon",
                          actionHandler: () => {
                            setIsKanbanView(false);
                            HideDropdown();
                          },
                        },
                        {
                          actionName: _l("l_kanban_view"),
                          actionIcon: "Kanban",
                          actionHandler: () => {
                            setIsKanbanView(true);
                            HideDropdown();
                          },
                        },
                      ]}
                      
                      actionStausChange = {handleTaskBulkAction}
                      IsKanbanView={IsKanbanView}

                    />
                  </div>
                  <Form.Check
                    className={`line-height-0 ms-2 d-none ${showTaskModal || showReqestedTaskModal ? "d-none" : ""}`}
                    type="switch"
                    data-tip={`${_l("l_kanban_list_view")}`}
                    data-effect="solid"
                    data-delay-show='1000'
                    data-class="tooltip-main"
                    data-place="left"
                    onChange={() => {
                      setIsKanbanView(!IsKanbanView);
                    }}
                  />
                  
                </div>
              </div>
            </div>
            <Accordion.Item eventKey="0" className="with-pb-15">
              <div className="accordian-header-box d-flex align-items-center justify-content-between">
              <div className="modal-overlay3"></div>
                <div className="checkbox_accordian">
                {
                          requestTasks && requestTasks.length ?
                            <span className=" checkbox_absolute" >
                              <Form.Check type="checkbox" checked={bulkActionAllTask.request ? true : false} onChange={(e) => {
                                handleAllTaskBulkAction(e.target.checked, 'requestTasks')
                                setBulkActionAllTask({ ...bulkActionAllTask, ['request']: e.target.checked })
                              }} />
                            </span> : <></>
                        }
               
                <Accordion.Header
                  className={`active orange commanTaskCount w-auto ${requestTasks.length == 0 ? "radius3px" : ""} ` }
                  id="request"
                >
                  <div className="accordion-header-wrapper w-auto ">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="left-task-header">
                        <div className="d-flex align-items-center  fw-semibold title-fonts c-font f-13">

                        
                          <span className="c-dots  c-7 bg-warning rounded-circle me-3"></span>
                          <span className="task-title me-2">{_l("l_request")}</span>
                          <span className="" id="request_count_display">
                           

                            {requestTasks && requestTasks.length
                              ? "[" + requestTasks.length + "]"
                              : "[0]"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Header>
                </div>

                {showTaskModal ? <div className="threedotbtn"></div> : <></>}
              </div>
              <Accordion.Body className="taskCountToggle" id="request_count">
                {requestTasks.length ? (
                  <>
                    {requestTasks.sort((a,b)=>{
                        return b.unread_comment_count - a.unread_comment_count;
                      })
                      .slice(
                        0,
                        requestPage > 1 ? (requestPage - 1) * 10 + 3 : requestPage * 3
                      )
                      .map((task, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div
                              key={index}
                              className=""
                           
                            >
                              <TaskCard
                                key={task.id}
                                task={task}
                                active={selectedTaskId == task.id}
                                project_id={projectId}
                                handler={handleSelectedTask}
                                pageName='LiteVersion'
                                taskCheckUncheck={taskCheckUncheck}
                                checkedTask={checkedTask}
                                taskPinned={taskPinned}
                                setTaskPinned={setTaskPinned}
                                contacts={contacts}
                                setTaskId={setTaskId}
                                setCallUserList={setCallUserList}
                                setSelectedTaskIdforIconToggle={setSelectedTaskIdforIconToggle}
                                selectedTaskIdforIconToggle={selectedTaskIdforIconToggle}
                                showDefaultIcons={showDefaultIcons}
                                setShowDefaultIcons={setShowDefaultIcons}
                                handleToggleIcons={handleToggleIcons}
                              // getTaskDetails = {handleTask}
                              />
                            </div>
                          </React.Fragment>
                        );
                      })}
                    
                    {(requestTasks.length >= (requestPage - 1) * 10 + 3 &&
                      requestTasks.length != (requestPage - 1) * 10 + 3) ||
                      loder.requestLoder ? (
                      <a href="#/"
                        className="btn btn-white-03 w-100 view-more-btn"
                        onClick={() => {
                          setSelectedTaskIdforIconToggle(0)
                          setLoder({ ...loder, ["requestLoder"]: true });
                          scroll(0);
                        }}
                      >
                        <div className="d-flex align-item-center justify-content-center">
                          <span className=" title-fonts c-font f-12 ms-2">
                            {_l("l_view_more_btn")} (
                            {requestPage > 1
                              ? requestTasks.length - ((requestPage - 1) * 10 + 3)
                              : requestTasks.length - 3}
                            )
                          </span>
                          <CaretDoubleDown size={12} className="c-icons mx-2" weight="light" />
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            className={`ms-2 ${loder.requestLoder ? "opacity-1" : "opacity-0"
                              }`}
                            aria-hidden="true"
                          />
                        </div>
                      </a>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    <CommanPlaceholder imgType="request" placeholderText = {_l("l_no_request_task")} />
                  </>
                )}
              </Accordion.Body>
              
            </Accordion.Item>
            <Accordion.Item eventKey="1" className="with-pb-15">
              <div className="accordian-header-box d-flex align-items-center justify-content-between">
              <div className="modal-overlay3"></div>
                <div className="checkbox_accordian">
                {
                  todoTasks && todoTasks.length ?
                    <span className="checkbox_absolute" >
                      <Form.Check type="checkbox" checked={bulkActionAllTask.todo ? true : false} onChange={(e) => {
                        handleAllTaskBulkAction(e.target.checked, 'todoTasks')
                        setBulkActionAllTask({ ...bulkActionAllTask, ['todo']: e.target.checked })
                      }} />
                    </span>
                    : <></>
                }
                  <Accordion.Header
                    className={`active to-do commanTaskCount w-auto  ${todoTasks.length == 0 ? "radius3px" : ""}`}
                    id="todo"
                 
                  >
                    <div className="accordion-header-wrapper w-auto ">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="left-task-header">
                          <div className="d-flex align-items-center  fw-semibold title-fonts c-font f-13">
                            <span className="c-dots  c-7 in-progress-white rounded-circle me-3"></span>
                            <span className="task-title me-2">{_l("l_todo")}</span>
                            <span className="" id="todo_count_display">
                             

                              {todoTasks && todoTasks.length
                                ? "[" + todoTasks.length + "]"
                                : "[0]"}
                            </span>

                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Header>
                </div>
                

                {showTaskModal ? <div className="threedotbtn"></div> : <></>}
              </div >
              <Accordion.Body className="taskCountToggle" id="todo_count">
                {todoTasks.length ? (
                  <>
                    {todoTasks.sort((a,b)=>{
                        return b.unread_comment_count - a.unread_comment_count;
                      }).slice(
                        0,
                        todoPage > 1 ? (todoPage - 1) * 10 + 3 : todoPage * 3
                      )
                      .map((task, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div
                              key={index}
                              className=""
                            
                            >
                              <TaskCard
                                key={task.id}
                                task={task}
                                active={selectedTaskId == task.id}
                                project_id={projectId}
                                handler={handleSelectedTask}
                                pageName='LiteVersion'
                                taskCheckUncheck={taskCheckUncheck}
                                checkedTask={checkedTask}
                                taskPinned={taskPinned}
                                setTaskPinned={setTaskPinned}
                                contacts={contacts}
                                setTaskId={setTaskId}
                                setCallUserList={setCallUserList}
                                setSelectedTaskIdforIconToggle={setSelectedTaskIdforIconToggle}
                                selectedTaskIdforIconToggle={selectedTaskIdforIconToggle}
                                showDefaultIcons={showDefaultIcons}
                                setShowDefaultIcons={setShowDefaultIcons}
                                handleToggleIcons={handleToggleIcons}
                              // getTaskDetails = {handleTask}
                              />
                            </div>
                          </React.Fragment>
                        );
                      })}
                    {(todoTasks.length >= (todoPage - 1) * 10 + 3 &&
                todoTasks.length != (todoPage - 1) * 10 + 3) ||
                loder.todoLoder ? (
                <a href="#/"
                  className="btn btn-white-03 w-100 view-more-btn"
                  onClick={() => {
                    setSelectedTaskIdforIconToggle(0)
                    setLoder({ ...loder, ["todoLoder"]: true });
                    scroll(1);
                  }}
                >
                  <div className="d-flex align-item-center justify-content-center">
                    <span className=" title-fonts c-font f-12 ms-2">
                      {_l("l_view_more_btn")} (
                      {todoPage > 1
                        ? todoTasks.length - ((todoPage - 1) * 10 + 3)
                        : todoTasks.length - 3}
                      )
                    </span>
                    <CaretDoubleDown size={12} className="c-icons mx-2" weight="light" />
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      className={`ms-2 ${loder.todoLoder ? "opacity-1" : "opacity-0"
                        }`}
                      aria-hidden="true"
                    />
                  </div>
                </a>
              ) : (
                <></>
              )}
                  </>
                ) : (
                  <>
                    <CommanPlaceholder imgType="todo-task" placeholderText = {_l("l_no_todo_task")} />
                  </>
                )}
              </Accordion.Body>
              
            </Accordion.Item >
            <Accordion.Item eventKey="2" className="with-pb-15">
              <div className="accordian-header-box d-flex align-items-center justify-content-between">
              <div className="modal-overlay3"></div>
                <div className="checkbox_accordian">
                {
                  inProgressTasks && inProgressTasks.length ?
                    <span className="checkbox_absolute" >
                      <Form.Check type="checkbox" checked={bulkActionAllTask.inProgress ? true : false} onChange={(e) => {
                        handleAllTaskBulkAction(e.target.checked, 'inProgressTasks')
                        setBulkActionAllTask({ ...bulkActionAllTask, ['inProgress']: e.target.checked })
                      }} />
                    </span>
                    : <></>
                }
                  <Accordion.Header
                    className={`active  in-progress commanTaskCount w-auto ${inProgressTasks.length == 0 ? "radius3px" : ""}`}
                    id="inprogress"
                  // onClick={() => taskCountToggle("inprogress")}
                  >
                    <div className="accordion-header-wrapper w-auto">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="left-task-header">
                          <div className="d-flex align-items-center  fw-semibold title-fonts c-font f-13">
                            <span className="c-dots c-7 rounded-circle in-progress-blue me-3"></span>
                            <span className="task-title me-2">
                              {_l("l_inprogress")}
                            </span>
                            <span className="" id="inprogress_count_display">
                             

                              {inProgressTasks && inProgressTasks.length
                                ? "[" + inProgressTasks.length + "]"
                                : "[0]"}
                            </span>

                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Header>
                </div>
                

              </div >
              <Accordion.Body className="" id="inprogress_count">
                {inProgressTasks.length ? (
                  <>
                    {inProgressTasks.sort((a,b)=>{
                        return b.unread_comment_count - a.unread_comment_count;
                      })
                      .slice(
                        0,
                        inProgressPage > 1
                          ? (inProgressPage - 1) * 10 + 3
                          : inProgressPage * 3
                      )
                      .map((task, index) => {
                        return (
                          <div key={index} className="">
                            <TaskCard
                              key={task.id}
                              task={task}
                              active={selectedTaskId === task.id}
                              project_id={projectId}
                              handler={handleSelectedTask}
                              pageName='LiteVersion'
                              taskCheckUncheck={taskCheckUncheck}
                              checkedTask={checkedTask}
                              taskPinned={taskPinned}
                              setTaskPinned={setTaskPinned}
                              contacts={contacts}
                              setTaskId={setTaskId}
                              setCallUserList={setCallUserList}
                              setSelectedTaskIdforIconToggle={setSelectedTaskIdforIconToggle}
                              selectedTaskIdforIconToggle={selectedTaskIdforIconToggle}
                              showDefaultIcons={showDefaultIcons}
                              setShowDefaultIcons={setShowDefaultIcons}
                              handleToggleIcons={handleToggleIcons}
                            // getTaskDetails = {handleTask}
                            />
                          </div>
                        );
                      })}
                   
                {(inProgressTasks.length >= (inProgressPage - 1) * 10 + 3 &&
                inProgressTasks.length != (inProgressPage - 1) * 10 + 3) ||
                loder.inProgressLoder ? (
                <a href="#/"
                  className="btn btn-white-03 w-100 view-more-btn"
                  onClick={() => {
                    setSelectedTaskIdforIconToggle(0)
                    setLoder({ ...loder, ["inProgressLoder"]: true });
                    scroll(4);
                  }}
                >
                  <div className="d-flex align-item-center justify-content-center">
                    <span className="title-fonts c-font f-12 ms-2">
                      {_l("l_view_more_btn")} (
                      {inProgressPage > 1
                        ? inProgressTasks.length -
                        ((inProgressPage - 1) * 10 + 3)
                        : inProgressTasks.length - 3}
                      )
                    </span>
                    <CaretDoubleDown size={12} className="c-icons mx-2" weight="light" />
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      className={`ms-2 ${loder.todoLoder ? "opacity-1" : "opacity-0"
                        }`}
                      aria-hidden="true"
                    />
                  </div>
                </a>
              ) : (
                <></>
              )}
                    
                  </>
                ) : (
                  <>
                    <CommanPlaceholder imgType="inprogress-task" placeholderText = {_l("l_no_inprogress_task")} />
                  </>
                )}
              </Accordion.Body>
              
            </Accordion.Item >
            <Accordion.Item eventKey="3" className="">
              <div className="accordian-header-box d-flex align-items-center justify-content-between">
              <div className="modal-overlay3"></div>
                <div className="checkbox_accordian">
                {
                  completedTasks && completedTasks.length ?
                    <span className="checkbox_absolute" >
                      <Form.Check type="checkbox" checked={bulkActionAllTask.done ? true : false} onChange={(e) => {
                        handleAllTaskBulkAction(e.target.checked, 'completedTasks');
                        setBulkActionAllTask({ ...bulkActionAllTask, ['done']: e.target.checked })
                      }} />
                    </span>
                    : <></>
                }
                  <Accordion.Header
                    className={`active  done w-auto  ${completedTasks.length == 0 ? "radius3px" : ""}`}
                  // onClick={() => taskCountToggle("done")}
                  >
                    <div className="accordion-header-wrapper w-auto">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="left-task-header">
                          <div className="d-flex align-items-center  fw-semibold title-fonts c-font f-13">
                            <span className="c-dots  c-7 done rounded-circle me-3"></span>
                            <span className="task-title me-2">{_l("l_done")}</span>
                            <span className="" id="done_count_display">
                             
                              {completedTasks && completedTasks.length
                                ? "[" + completedTasks.length + "]"
                                : "[0]"}
                            </span>

                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Header>
                </div>
                

              </div >
              <Accordion.Body className="" id="done_count">
                {completedTasks.length ? (
                  <>
                    {completedTasks.sort((a,b)=>{
                        return b.unread_comment_count - a.unread_comment_count;
                      })
                      .slice(
                        0,
                        completedPage > 1
                          ? (completedPage - 1) * 10 + 3
                          : completedPage * 3
                      )
                      .map((task, index) => {
                        return (
                          <div key={index} className="card-list-box">
                            <TaskCard
                              key={task.id}
                              task={task}
                              active={selectedTaskId === task.id}
                              project_id={projectId}
                              handler={handleSelectedTask}
                              pageName='LiteVersion'
                              taskCheckUncheck={taskCheckUncheck}
                              checkedTask={checkedTask}
                              taskPinned={taskPinned}
                              setTaskPinned={setTaskPinned}
                              contacts={contacts}
                              setTaskId={setTaskId}
                              setCallUserList={setCallUserList}
                              setSelectedTaskIdforIconToggle={setSelectedTaskIdforIconToggle}
                              selectedTaskIdforIconToggle={selectedTaskIdforIconToggle}
                              showDefaultIcons={showDefaultIcons}
                              setShowDefaultIcons={setShowDefaultIcons}
                              handleToggleIcons={handleToggleIcons}
                            // getTaskDetails = {handleTask}
                            />
                          </div>
                        );
                      })}
                   
{(completedTasks.length >= (completedPage - 1) * 10 + 3 &&
                completedTasks.length != (completedPage - 1) * 10 + 3) ||
                loder.doneLoder ? (
                <a href="#/"
                  className="btn btn-white-03 w-100 view-more-btn"
                  onClick={() => {
                    setSelectedTaskIdforIconToggle(0)
                    setLoder({ ...loder, ["doneLoder"]: true });
                    scroll(5);
                  }}
                >
                  <div className="d-flex align-item-center justify-content-center">
                    <span className=" title-fonts c-font f-12 ms-2">
                      {_l("l_view_more_btn")} (
                      {completedPage > 1
                        ? completedTasks.length -
                        ((completedPage - 1) * 10 + 3)
                        : completedTasks.length - 3}
                      )
                    </span>
                    <CaretDoubleDown size={12} className="c-icons mx-2" weight="light" />
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      className={`ms-2 ${loder.todoLoder ? "opacity-1" : "opacity-0"
                        }`}
                      aria-hidden="true"
                    />
                  </div>
                </a>
              ) : (
                <></>
              )}
                    
                  </>
                ) : (
                  <>
                    <CommanPlaceholder imgType="NoDoneTask-task" placeholderText = {_l("l_no_done_task")} />
                  </>
                )}
              </Accordion.Body>
              
            </Accordion.Item >
          </Accordion >

          {
            showTaskModal ? <CommanOffCanvas
              show={showTaskModal}
              handleClose={() => {
                setShowTaskModal(false);
                // setIsRequestTask()
                handleClose();
                setCommonModalData();
                setSelectedTaskId(0);
                setTask_type('')
              }}
              pinHandler={(id, type, flag) => {
                // handlePinUnPin(id, type, flag);
              }}
              data={commonModalData}
              docType={task_type}
              setSelectedId={setSelectedTaskId}
              selectedTaskId={selectedTaskId}
              IsKanbanView={IsKanbanView}
              meetingUrl={meetingUrl}
              handleParentComponentAction={(type, taskData) => {
                if (task_type == 'task') {
                  if (type == 'attachmentCountUpdate') {
                    updateAttchmentChecklistCount(taskData.task_id, taskData.flag)
                    return;
                  } else if (type == 'handleSelectedTask') {
                    handleSelectedTask(taskData.task_id, taskData.flag)
                    return;
                  } else if (type == 'convertedTaskAction') {
                    handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
                    return;
                  }
                } else if (task_type == 'request-task') {

                  if (type == 'convertedTaskAction') {
                    handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
                    return;
                  }
                }

              }}
              contacts={contacts}
              callUserList={callUserList}
            /> : <></>
          }
        </div > 
        :
        <> 
        {/* ----------------------------------------------------------------------- liteversionview start ----------------------------------------------------------------------- */}

       {/* { isLoadingData("task_list") ?  */}
       
        <div id="list_view" className={`schedule-content-wrapper h-100 d-flex flex-column px-0 pb-0 radius_3 pt-10px  d-flex flex-column  ${checklistItems.length > 0 ? "" : ""}  ${showTaskModal || showReqestedTaskModal ? "list-view-toggle w-auto" : ""
               // ${showTaskList ? "" : "comman-table-thead-bg"} 
           }`}>
        <div id="list_view" className={` d-flex flex-column h-100 flex-grow-1 backdroplighttheme  ${createTaskModalIsopen && globalView == "liteVersionView" ? "" : ""}`}>
        { createTaskModalIsopen ?
        // <CreateTaskOffCanvasNew 
        //          taskTitle={taskTitle}
        //          setTaskTitle={setTaskTitle}
        //          setshowTaskList={setshowTaskList}
        //          pageName={pageName}
        //          isFromLiteVersion={true}
        //          isRequest={false}
        //          handleClose={(taskId) => {
        //            if (taskId) {
        //              setSelectedTaskId(taskId)
        //              dispatch(
        //                getSelectedTask(
        //                  localStorage.getItem("selectedOffice"),
        //                  taskId
        //                )
        //              );
        //            setShowTaskModal(true);
        //              // setShowEditRequest(true);
        //            }
        //          }}
        //          />
        <></>
                  : <></>}
                <div
                  id="liteversion_view"
                  className={`comman-main-box custom-accordian-main res-padding-right0 overflow-auto flex-grow-1 h-100 task-list-accordian ${globalView == "kanbanView" ? "pb-0 kanban-view overflow-hidden horizontal-scroll" : ""} ${showTaskModal || showReqestedTaskModal ? "list-view-toggle" : ""
                    } ${IsKanbanView ? "" : ""}`}
                >
                  <Accordion
                    className={`task-list-accordian-main with_checkbox_absolute mt-0 w-800px my-0 padding-bottom-60px ${commanoffcanvasIsOpen ? "" : "daily-schedule-main" }`}
                    onSelect={(eventKey) => {
                      handleAccordianExpansion(eventKey);
                    }}
                    activeKey={defaultActiveKeys}
                  >
                    <div className="pill-wrapper with-right-pos d-none" id="">
                      <div className="pill-wrappe-modal-overlay"></div>
                      <div id="" className="">
                        <div
                          className="d-flex align-items-center filter-lists-menu min-width-190 justify-content-end pb-2"

                        
                        >
                          
                          {
                            taskType == 0 || taskType == 1 ?
                              <div className="badge w-auto color-white-80 bg-white-05  radius_3 me-2">
                                <div className="d-flex align-items-center">

                                  <span className="color-white">
                                    {taskType ? _l('l_request_task') : _l('l_task')}
                                  </span>
                                  <a href="#/"
                                    className="ps-1"
                                    onClick={() => {
                                      dispatch(setTaskTypeFilter(_l('l_all')))
                                    }}
                                  >
                                    <X size={14} weight="light" className="c-icons" />
                                  </a>
                                </div>
                              </div>
                              : <></>
                          }

                          {leftPanelActiveFilters.length ? (
                            leftPanelActiveFilters.map((value, index) => {
                              if (value.main_key == "date_range_filter") {
                                let displayFilterKey = value.title;
                                return (
                                  <div
                                    key={index}
                                    className="badge c-font f-12 w-auto color-white-80 bg-white-05  radius_3 ms-2 comman-round-box active py-1 d-none"
                                  >
                                    <div className="d-flex align-items-center">
                                     

                                      <span className="color-white">
                                        {displayFilterKey}
                                      </span>
                                      <a href="#/"
                                        className="ps-1"
                                        onClick={() => {
                                          dispatch(
                                            leftPanelFilters({
                                              main_key: value.main_key,
                                              filter_key: value.filter_key,
                                              title: value.title,
                                              main_sub_key: value.main_sub_key,
                                            })
                                          );
                                          dispatch(
                                            projectTaskCounts(projectId, taskFilters, {
                                              main_key: value.main_key,
                                              filter_key: value.filter_key,
                                              title: value.title,
                                            })
                                          );
                                          dispatch(
                                            projectTaskCounts(
                                              projectId,
                                              taskFilters,
                                              '')
                                          );
                                          dispatch(getTopicTasks(projectId, "", 0, localStorage.getItem('selectedDate'), taskFilters));
                                          
                                        }}
                                      >
                                        <X size={14} className="c-icons" weight="light" />
                                      </a>
                                    </div>
                                  </div>
                                );
                              }
                            })
                          ) : (
                            <></>
                          )}
                          <div className={`comman_action_icon ms-2 pe-1 dropdown d-none ${showTaskModal || showReqestedTaskModal ? "d-none" : ""}`}>
                            <a href="#/"
                              type="button"
                              className={`line-height-0 light-theme-img action_icon h28w28 `}
                              id="viewDropdwon"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              data-bs-auto-close="outside"
                            
                            >
                              <DotsThreeVertical
                                size={16}
                                weight="light"
                                className="c-icons"
                              />
                            </a>
                            <ActionDropdown widthClass="w-170"
                              actions={[
                                {
                                  actionName: _l("l_list_view"),
                                  actionIcon: "ListBulletsIcon",
                                  actionHandler: () => {
                                    setIsKanbanView(false);
                                    HideDropdown();
                                  },
                                },
                                {
                                  actionName: _l("l_kanban_view"),
                                  actionIcon: "Kanban",
                                  actionHandler: () => {
                                    setIsKanbanView(true);
                                    HideDropdown();
                                  },
                                },
                              ]}

                              actionStausChange={handleTaskBulkAction}
                              IsKanbanView={IsKanbanView}

                            />
                          </div>
                          <Form.Check
                            className={`line-height-0 ms-2 d-none ${showTaskModal || showReqestedTaskModal ? "d-none" : ""}`}
                            type="switch"
                            data-tip={`${_l("l_kanban_list_view")}`}
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main"
                            data-place="left"
                            onChange={() => {
                              setIsKanbanView(!IsKanbanView);
                            }}
                          />
                         
                        </div>
                      </div>
                    </div>
                    <Accordion.Item eventKey="0" className="with-pb-15">
                      <div className="accordian-header-box d-flex align-items-center justify-content-between bgpatternCommanImg">
                        <div className="modal-overlay3"></div>
                        <div className="checkbox_accordian">
                          {
                            requestTasks && requestTasks.length ?
                              <span className=" checkbox_absolute" >
                                <Form.Check type="checkbox" checked={bulkActionAllTask.request ? true : false} onChange={(e) => {
                                  handleAllTaskBulkAction(e.target.checked, 'requestTasks')
                                  setBulkActionAllTask({ ...bulkActionAllTask, ['request']: e.target.checked })
                                }} />
                              </span> : <></>
                          }

                          <Accordion.Header
                            className={`active orange commanTaskCount w-auto  ${requestTasks.length == 0 ? "radius3px" : ""}`}
                            id="request"
                          // onClick={() => taskCountToggle("todo")}
                          >
                            <div className="accordion-header-wrapper w-auto ">
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="left-task-header">
                                  <div className="d-flex align-items-center  fw-semibold title-fonts c-font f-13">


                                    <span className="c-dots  c-7 bg-warning rounded-circle me-3"></span>
                                    <span className="task-title me-2">{_l("l_request")}</span>
                                    <span className="" id="request_count_display">
                                      

                                      {requestTasks && requestTasks.length
                                        ? "[" + requestTasks.length + "]"
                                        : "[0]"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Accordion.Header>
                        </div>

                        {showTaskModal ? <div className="threedotbtn"></div> : <></>}
                      </div>
                      <Accordion.Body className="taskCountToggle" id="request_count">
                        {requestTasks.length ? (
                          <>
                            {requestTasks.sort((a, b) => {
                              return b.unread_comment_count - a.unread_comment_count;
                            })
                              .slice(
                                0,
                                requestPage > 1 ? (requestPage - 1) * 10 + 3 : requestPage * 3
                              )
                              .map((task, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <div
                                      key={index}
                                      className=""
                                    // onClick={(e) => {
                                    //   handleSelectedTask(task.id);
                                    // }}
                                    >
                                      <TaskCard
                                        key={task.id}
                                        task={task}
                                        active={selectedTaskId == task.id}
                                        project_id={projectId}
                                        handler={handleSelectedTask}
                                        pageName='LiteVersion'
                                        taskCheckUncheck={taskCheckUncheck}
                                        checkedTask={checkedTask}
                                        taskPinned={taskPinned}
                                        setTaskPinned={setTaskPinned}
                                        contacts={contacts}
                                        setTaskId={setTaskId}
                                        setCallUserList={setCallUserList}
                                        setSelectedTaskIdforIconToggle={setSelectedTaskIdforIconToggle}
                                        selectedTaskIdforIconToggle={selectedTaskIdforIconToggle}
                                        showDefaultIcons={showDefaultIcons}
                                        setShowDefaultIcons={setShowDefaultIcons}
                                        handleToggleIcons={handleToggleIcons}
                                      // getTaskDetails = {handleTask}
                                      />
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                            {/* todoTasks.length &&
                calendarData &&
                calendarData[3].todo_count !== todoTasks.length */}
                          </>
                        ) : (
                          <>
                            <CommanPlaceholder imgType="request" placeholderText={_l("l_no_request_task")} />
                          </>
                        )}
                      </Accordion.Body>
                      {(requestTasks.length >= (requestPage - 1) * 10 + 3 &&
                        requestTasks.length != (requestPage - 1) * 10 + 3) ||
                        loder.requestLoder ? (
                        <a href="#/"
                          className="btn btn-white-03 w-100 view-more-btn"
                          onClick={() => {
                            setSelectedTaskIdforIconToggle(0)
                            setLoder({ ...loder, ["requestLoder"]: true });
                            scroll(0);
                          }}
                        >
                          <div className="d-flex align-item-center justify-content-center">
                            <span className=" title-fonts c-font f-12 ms-2">
                              {_l("l_view_more_btn")} (
                              {requestPage > 1
                                ? requestTasks.length - ((requestPage - 1) * 10 + 3)
                                : requestTasks.length - 3}
                              )
                            </span>
                            <CaretDoubleDown size={12} className="c-icons mx-2" weight="light" />
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              className={`ms-2 ${loder.requestLoder ? "opacity-1" : "opacity-0"
                                }`}
                              aria-hidden="true"
                            />
                          </div>
                        </a>
                      ) : (
                        <></>
                      )}
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className="with-pb-15">
                      <div className="accordian-header-box d-flex align-items-center justify-content-between bgpatternCommanImg">
                      <div className="modal-overlay3"></div>
                        <div className="checkbox_accordian">
                          {
                            todoTasks && todoTasks.length ?
                              <span className="checkbox_absolute" >
                                <Form.Check type="checkbox" checked={bulkActionAllTask.todo ? true : false} onChange={(e) => {
                                  handleAllTaskBulkAction(e.target.checked, 'todoTasks')
                                  setBulkActionAllTask({ ...bulkActionAllTask, ['todo']: e.target.checked })
                                }} />
                              </span>
                              : <></>
                          }
                          <Accordion.Header
                            className={`active to-do commanTaskCount w-auto ${todoTasks.length == 0 ? "radius3px" : ""}`}
                            id="todo"
                          // onClick={() => taskCountToggle("todo")}
                          >
                            <div className="accordion-header-wrapper w-auto ">
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="left-task-header abc">
                                  <div className="d-flex align-items-center  fw-semibold title-fonts c-font f-13">
                                    <span className="c-dots  c-7 in-progress-white rounded-circle me-3"></span>
                                    <span className="task-title me-2">{_l("l_todo_kpi_name")}</span>
                                    <span className="" id="todo_count_display">
                                      {/* {calendarData &&
                            calendarData[3].todo_count >= 0 &&
                            "[" + calendarData[3].todo_count + "]"} */}

                                      {todoTasks && todoTasks.length
                                        ? "[" + todoTasks.length + "]"
                                        : "[0]"}
                                    </span>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </Accordion.Header>
                        </div>


                        {showTaskModal ? <div className="threedotbtn"></div> : <></>}
                      </div >
                      <Accordion.Body className="taskCountToggle" id="todo_count">
                        {todoTasks.length ? (
                          <>
                            {todoTasks.sort((a, b) => {
                              return b.unread_comment_count - a.unread_comment_count;
                            }).slice(
                              0,
                              todoPage > 1 ? (todoPage - 1) * 10 + 3 : todoPage * 3
                            )
                              .map((task, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <div
                                      key={index}
                                      className=""
                                    // onClick={(e) => {
                                    //   handleSelectedTask(task.id);
                                    // }}
                                    >
                                      <TaskCard
                                        key={task.id}
                                        task={task}
                                        active={selectedTaskId == task.id}
                                        project_id={projectId}
                                        handler={handleSelectedTask}
                                        pageName='LiteVersion'
                                        taskCheckUncheck={taskCheckUncheck}
                                        checkedTask={checkedTask}
                                        taskPinned={taskPinned}
                                        setTaskPinned={setTaskPinned}
                                        contacts={contacts}
                                        setTaskId={setTaskId}
                                        setCallUserList={setCallUserList}
                                        setSelectedTaskIdforIconToggle={setSelectedTaskIdforIconToggle}
                                        selectedTaskIdforIconToggle={selectedTaskIdforIconToggle}
                                        showDefaultIcons={showDefaultIcons}
                                        setShowDefaultIcons={setShowDefaultIcons}
                                        handleToggleIcons={handleToggleIcons}
                                      // getTaskDetails = {handleTask}
                                      />
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                            {/* todoTasks.length &&
                calendarData &&
                calendarData[3].todo_count !== todoTasks.length */}
                            
                          </>
                        ) : (
                          <>
                            <CommanPlaceholder imgType="todo-task" placeholderText={_l("l_no_todo_task")} />
                          </>
                        )}
                      </Accordion.Body>
                      {(todoTasks.length >= (todoPage - 1) * 10 + 3 &&
                              todoTasks.length != (todoPage - 1) * 10 + 3) ||
                              loder.todoLoder ? (
                              <a href="#/"
                                className="btn btn-white-03 w-100 view-more-btn"
                                onClick={() => {
                                  setSelectedTaskIdforIconToggle(0)
                                  setLoder({ ...loder, ["todoLoder"]: true });
                                  scroll(1);
                                }}
                              >
                                <div className="d-flex align-item-center justify-content-center">
                                  <span className=" title-fonts c-font f-12 ms-2">
                                    {_l("l_view_more_btn")} (
                                    {todoPage > 1
                                      ? todoTasks.length - ((todoPage - 1) * 10 + 3)
                                      : todoTasks.length - 3}
                                    )
                                  </span>
                                  <CaretDoubleDown size={12} className="c-icons mx-2" weight="light" />
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    className={`ms-2 ${loder.todoLoder ? "opacity-1" : "opacity-0"
                                      }`}
                                    aria-hidden="true"
                                  />
                                </div>
                              </a>
                            ) : (
                              <></>
                            )}
                    </Accordion.Item >
                    <Accordion.Item eventKey="2" className="with-pb-15">
                      <div className="accordian-header-box d-flex align-items-center justify-content-between bgpatternCommanImg">
                      <div className="modal-overlay3"></div>
                        <div className="checkbox_accordian">
                          {
                            inProgressTasks && inProgressTasks.length ?
                              <span className="checkbox_absolute" >
                                <Form.Check type="checkbox" checked={bulkActionAllTask.inProgress ? true : false} onChange={(e) => {
                                  handleAllTaskBulkAction(e.target.checked, 'inProgressTasks')
                                  setBulkActionAllTask({ ...bulkActionAllTask, ['inProgress']: e.target.checked })
                                }} />
                              </span>
                              : <></>
                          }
                          <Accordion.Header
                            className={`active  in-progress commanTaskCount w-auto ${inProgressTasks.length == 0 ? "radius3px" : ""}`}
                            id="inprogress"
                          // onClick={() => taskCountToggle("inprogress")}
                          >
                            <div className="accordion-header-wrapper w-auto">
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="left-task-header">
                                  <div className="d-flex align-items-center  fw-semibold title-fonts c-font f-13">
                                    <span className="c-dots c-7 rounded-circle in-progress-blue me-3"></span>
                                    <span className="task-title me-2">
                                      {_l("l_ongoing_kpi")}
                                    </span>
                                    <span className="" id="inprogress_count_display">
                                      {/* {calendarData &&
                            calendarData[3].inprogress_count >= 0 &&
                            "[" + calendarData[3].inprogress_count + "]"} */}

                                      {inProgressTasks && inProgressTasks.length
                                        ? "[" + inProgressTasks.length + "]"
                                        : "[0]"}
                                    </span>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </Accordion.Header>
                        </div>


                      </div >
                      <Accordion.Body className="" id="inprogress_count">
                        {inProgressTasks.length ? (
                          <>
                            {inProgressTasks.sort((a, b) => {
                              return b.unread_comment_count - a.unread_comment_count;
                            })
                              .slice(
                                0,
                                inProgressPage > 1
                                  ? (inProgressPage - 1) * 10 + 3
                                  : inProgressPage * 3
                              )
                              .map((task, index) => {
                                return (
                                  <div key={index} className="">
                                    <TaskCard
                                      key={task.id}
                                      task={task}
                                      active={selectedTaskId === task.id}
                                      project_id={projectId}
                                      handler={handleSelectedTask}
                                      pageName='LiteVersion'
                                      taskCheckUncheck={taskCheckUncheck}
                                      checkedTask={checkedTask}
                                      taskPinned={taskPinned}
                                      setTaskPinned={setTaskPinned}
                                      contacts={contacts}
                                      setTaskId={setTaskId}
                                      setCallUserList={setCallUserList}
                                      setSelectedTaskIdforIconToggle={setSelectedTaskIdforIconToggle}
                                      selectedTaskIdforIconToggle={selectedTaskIdforIconToggle}
                                      showDefaultIcons={showDefaultIcons}
                                      setShowDefaultIcons={setShowDefaultIcons}
                                      handleToggleIcons={handleToggleIcons}
                                    // getTaskDetails = {handleTask}
                                    />
                                  </div>
                                );
                              })}
                            {/* inProgressTasks.length &&
                calendarData &&
                calendarData[3].inprogress_count !==
                  inProgressTasks.length */}

                          </>
                        ) : (
                          <>
                            <CommanPlaceholder imgType="inprogress-task" placeholderText={_l("l_no_inprogress_task")} />
                          </>
                        )}
                      </Accordion.Body>
                      {(inProgressTasks.length >= (inProgressPage - 1) * 10 + 3 &&
                        inProgressTasks.length != (inProgressPage - 1) * 10 + 3) ||
                        loder.inProgressLoder ? (
                        <a href="#/"
                          className="btn btn-white-03 w-100 view-more-btn"
                          onClick={() => {
                            setSelectedTaskIdforIconToggle(0)
                            setLoder({ ...loder, ["inProgressLoder"]: true });
                            scroll(4);
                          }}
                        >
                          <div className="d-flex align-item-center justify-content-center">
                            <span className="title-fonts c-font f-12 ms-2">
                              {_l("l_view_more_btn")} (
                              {inProgressPage > 1
                                ? inProgressTasks.length -
                                ((inProgressPage - 1) * 10 + 3)
                                : inProgressTasks.length - 3}
                              )
                            </span>
                            <CaretDoubleDown size={12} className="c-icons mx-2" weight="light" />
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              className={`ms-2 ${loder.todoLoder ? "opacity-1" : "opacity-0"
                                }`}
                              aria-hidden="true"
                            />
                          </div>
                        </a>
                      ) : (
                        <></>
                      )}
                    </Accordion.Item >
                    <Accordion.Item eventKey="3" className="">
                      <div className="accordian-header-box d-flex align-items-center justify-content-between bgpatternCommanImg">
                      <div className="modal-overlay3"></div>
                        <div className="checkbox_accordian">
                          {
                            completedTasks && completedTasks.length ?
                              <span className="checkbox_absolute" >
                                <Form.Check type="checkbox" checked={bulkActionAllTask.done ? true : false} onChange={(e) => {
                                  handleAllTaskBulkAction(e.target.checked, 'completedTasks');
                                  setBulkActionAllTask({ ...bulkActionAllTask, ['done']: e.target.checked })
                                }} />
                              </span>
                              : <></>
                          }
                          <Accordion.Header
                            className={`active  done w-auto  ${completedTasks.length == 0 ? "radius3px" : ""}`}
                          // onClick={() => taskCountToggle("done")}
                          >
                            <div className="accordion-header-wrapper w-auto">
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="left-task-header">
                                  <div className="d-flex align-items-center  fw-semibold title-fonts c-font f-13">
                                    <span className="c-dots  c-7 done rounded-circle me-3"></span>
                                    <span className="task-title me-2">{_l("l_done")}</span>
                                    <span className="" id="done_count_display">
                                      {/* {calendarData &&
                            calendarData[3].completed_count >= 0 &&
                            "[" + calendarData[3].completed_count + "]"} */}
                                      {completedTasks && completedTasks.length
                                        ? "[" + completedTasks.length + "]"
                                        : "[0]"}
                                    </span>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </Accordion.Header>
                        </div>


                      </div >
                      <Accordion.Body className="" id="done_count">
                        {completedTasks.length ? (
                          <>
                            {completedTasks.sort((a, b) => {
                              return b.unread_comment_count - a.unread_comment_count;
                            })
                              .slice(
                                0,
                                completedPage > 1
                                  ? (completedPage - 1) * 10 + 3
                                  : completedPage * 3
                              )
                              .map((task, index) => {
                                return (
                                  <div key={index} className="card-list-box">
                                    <TaskCard
                                      key={task.id}
                                      task={task}
                                      active={selectedTaskId === task.id}
                                      project_id={projectId}
                                      handler={handleSelectedTask}
                                      pageName='LiteVersion'
                                      taskCheckUncheck={taskCheckUncheck}
                                      checkedTask={checkedTask}
                                      taskPinned={taskPinned}
                                      setTaskPinned={setTaskPinned}
                                      contacts={contacts}
                                      setTaskId={setTaskId}
                                      setCallUserList={setCallUserList}
                                      setSelectedTaskIdforIconToggle={setSelectedTaskIdforIconToggle}
                                      selectedTaskIdforIconToggle={selectedTaskIdforIconToggle}
                                      showDefaultIcons={showDefaultIcons}
                                      setShowDefaultIcons={setShowDefaultIcons}
                                      handleToggleIcons={handleToggleIcons}
                                    // getTaskDetails = {handleTask}
                                    />
                                  </div>
                                );
                              })}
                            {/* completedTasks.length &&
                calendarData &&
                calendarData[3].completed_count !== completedTasks.length */}

                          </>
                        ) : (
                          <>
                            <CommanPlaceholder imgType="NoDoneTask-task" placeholderText={_l("l_no_done_task")} />
                          </>
                        )}
                      </Accordion.Body>
                      {(completedTasks.length >= (completedPage - 1) * 10 + 3 &&
                        completedTasks.length != (completedPage - 1) * 10 + 3) ||
                        loder.doneLoder ? (
                        <a href="#/"
                          className="btn btn-white-03 w-100 view-more-btn"
                          onClick={() => {
                            setSelectedTaskIdforIconToggle(0)
                            setLoder({ ...loder, ["doneLoder"]: true });
                            scroll(5);
                          }}
                        >
                          <div className="d-flex align-item-center justify-content-center">
                            <span className=" title-fonts c-font f-12 ms-2">
                              {_l("l_view_more_btn")} (
                              {completedPage > 1
                                ? completedTasks.length -
                                ((completedPage - 1) * 10 + 3)
                                : completedTasks.length - 3}
                              )
                            </span>
                            <CaretDoubleDown size={12} className="c-icons mx-2" weight="light" />
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              className={`ms-2 ${loder.todoLoder ? "opacity-1" : "opacity-0"
                                }`}
                              aria-hidden="true"
                            />
                          </div>
                        </a>
                      ) : (
                        <></>
                      )}
                    </Accordion.Item >
                  </Accordion >
                </div>
                     
                     
                     
           </div> 
           </div>  

           {showTaskModal ? <CommanOffCanvas
             show={showTaskModal}
             handleClose={() => {
               setShowTaskModal(false);
               // setIsRequestTask()
               handleClose();
               setCommonModalData();
               setSelectedTaskId(0);
               setTask_type('')
             }}
             pinHandler={(id, type, flag) => {
               // handlePinUnPin(id, type, flag);
             }}
             data={commonModalData}
             docType={task_type}
             setSelectedId={setSelectedTaskId}
             selectedTaskId={selectedTaskId}
             IsKanbanView={IsKanbanView}
             meetingUrl={meetingUrl}
             handleParentComponentAction={(type, taskData) => {
               if (task_type == 'task') {
                 if (type == 'attachmentCountUpdate') {
                   updateAttchmentChecklistCount(taskData.task_id, taskData.flag)
                   return;
                 } else if (type == 'handleSelectedTask') {
                   handleSelectedTask(taskData.task_id, taskData.flag)
                   return;
                 } else if (type == 'convertedTaskAction') {
                   handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
                   return;
                 }
               } else if (task_type == 'request-task') {

                 if (type == 'convertedTaskAction') {
                   handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
                   return;
                 }
               }

             }}
             pageName={"Liteversion"}
             contacts={contacts}
             callUserList={callUserList}
           /> : <></>
         }
       {/*  ----------------------------------------------------------------------- liteversionview end  -----------------------------------------------------------------------  */}
       </>
          
      }
    </>
  );
};

export default Tasks;
