import React, { useEffect, useState } from "react";
import {
  _l,
  checklistItem,
  checklistItemFormat,
  generateButtonText,
  getClientIdByProject,
  isSameArrays,
  showError,
  showSuccess,
  updateSpaceDetailInLocalStorage,
  validateEmail,
} from "../../../hooks/utilities";
import OnboardingHeader from "../../SpaceOnBoarding/OnboardingHeader";
import OnboardingFooter from "../../SpaceOnBoarding/OnboardingFooter";
import ProviderCategoryList from "./ProviderCategoryList";
import ProviderSources from "./ProviderSources";
import SelectProvider from "./SelectProvider";
import BasicDetails from "./BasicDetails";
import { FloorPlanSummary } from "../../SpaceOnBoarding/SpaceDetails/FloorPlanSummary";
import { useDispatch, useSelector } from "react-redux";
import {
  getFloorDetails,
  setFloorPlanning,
} from "../../../actions/benchmark-actions/benchmark-actions";
import ChecklistCreation from "./ChecklistCreation";
import Accessories from "./Accessories";
import ProposalSendingLoader from "./ProposalSendingLoader";
import { OnboardingFlowFinalStep } from "../../SpaceOnBoarding/SpaceDetails/OnboardingFlowFinalStep";
import ProposalList from "./ProposalList";
import ProviderType from "./ProviderType";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  showConfirmation,
  showMessage,
  toastCloseAction,
} from "../../../actions/messages";
import CustomerServices from "../../../services/customer-services";
import {
  commanoffcanvasisOpenfromCalendar,
  getBenchmarkDetail,
  getBenchmarkWeekSchedule,
  getCustomerSpaces,
  getDefaultTopics,
  getMentionList,
  getTaskChecklist,
  isCreareTaskDefaultTopics,
  selectJobProposalPage,
  setCategoryNotificationCounts,
  setProject,
  setSpaceNotificationCount,
  setVirtualSpaceNotificationCount,
} from "../../../actions/customer";
import BenchmarkServices from "../../../services/benchmark-services";
import moment from "moment";
import CreateScheduleNew from "../../Offcanvas/NewSchedule/CreateScheduleNew";
import ScheduleSummary from "../../Offcanvas/NewSchedule/ScheduleSummary";
import useScheduleSteps from "../../../hooks/useScheduleSteps";
import CommanLoader from "../../Loader/CommanLoader";
import { ChatCircle, Info } from "phosphor-react";
import DocumentsServices from "../../../services/documents-services";
import Fullcalendar from "../../../components/Fullcalendar";
import AddProposalAmount from "../../Offcanvas/NewSchedule/AddProposalAmount";
import { CUSTOM_ROLES, DEFAULT_ADDRESS, DEFAULT_REGION_ID, DEFAULT_REGION_NAME, ACKNOWLEDGEMENT_STEPS } from "../../../constants/constants";
import ChecklistComponent from "../../TaskModalComponents/ChecklistComponent";
import CommentSection from "../../Comments/CommentSection";
import Address from "../../SpaceOnBoarding/SpaceDetails/Address";
import ExternalBasicDetails from "../ExternalBenchmark/ExternalBasicDetails";
import RegisterClient from "../ExternalBenchmark/RegisterClient";
import AuthServices from "../../../services/auth-services";
import { login } from "../../../actions/auth";
import { Button } from "react-bootstrap";
import SpaceIBANConfiguration from "../../Modals/SpaceIBANConfiguration";
import FullScreenCommentView from "./FullScreenCommentView";
import jquery from "jquery";
import CategoryAndProviderBulkImport from "../../Modals/CategoryAndProviderBulkImport";
import AcknowledgeScreen from "../../Modals/OnboardingReusableComponents/AcknowledgeScreen";
import BenchmarkPerUnit from "./BenchmarkPerUnit";
import { readFromDB, writeToDB } from "../../../hooks/indexed_db_helpers";
import OperatorForm from "../../RegistrationFlow/OperatorForm";
import CommanOffCanvas from "../../Offcanvas/CommanOffCanvas";
import UploadDocumentBenchmark from "./UploadDocumentBenchmark";
import benchmarkServices from "../../../services/benchmark-services";

let scheduleCopyCreated;

let NO_BACK_BUTTON_STEPS = [
  "provider-type",
  "congratulation-screen",
  "sending-proposal-loader",
];
let NO_NEXT_BUTTON_STEPS = [
  "provider-type",
  "congratulation-screen",
  "sending-proposal-loader",
  "proposal-amount",
  "proposal-listing",
];
const NO_HEADER_STEPS = ["checklist-creation", "proposal-listing", "sending-proposal-loader", "congratulation-screen"];
const NO_FOOTER_STEPS = ["checklist-creation"];

let stepsArray = [];

let isDataUpdated = false;

const ConnectProvider = () => {
  const navigate = useNavigate();

  const viewPage = window.location.pathname;
  const [searchParams, setSearchParams] = useSearchParams();
  
  let type = searchParams.get("provider-type");
  let spaceId = searchParams.get("space");
  const clientId = getClientIdByProject(spaceId);
  const benchmarkId = searchParams.get("id");
  const isExternal = searchParams.get("is_external");
  const dashboardBenchmarkId = !isNaN(Number(searchParams.get("dashboard-benchmark-id"))) && Number(searchParams.get("dashboard-benchmark-id")) ? Number(searchParams.get("dashboard-benchmark-id")) : 0;

  const { floorDetails, checklistClipboard } = useSelector(
    (state) => state.benchmarkReducer
  );
  const { benchmarkSchedule, mentionList, benchmarkDetail, isLoadingBenchmarkInfo, defaultTopics, 
    isCreatesTaskDefaultTopics } = useSelector(
    (state) => state.customer
  );

  const [step, setStep] = useState("");
  const [providerData, setProviderData] = useState({
    id: 0,
    type,
    categoryId: 0,
    categoryName: "",
    sources: [],
    isCustomCategory: false,
    unKnownBudget: false,
    budget: 0,
    unKnownStartDate: false,
    startDate: "",
    endDate: "",
    contracts: "",
    contract_previews: [],
    documents: "",
    document_previews: [],
    description: "",
    total_myr_providers: "",
    total_myr_providers_data: [],
    invited_providers: [],
    invite_all_myr_providers: false,
    master_id: 0,
    invited_customers: [],
  });

  const [spaceDetail, setSpaceDetail] = useState({
    address: DEFAULT_ADDRESS,
    title: "",
    region_id: DEFAULT_REGION_ID,
    office_id: "",
    superfice: 1000,
    number_of_desk: 10,
    country_id: "",
    is_edit: "",
    logo: "",
    is_virtual_space: 0,
    space_type: "",
    space_logo: "",
    floor_count: 1,
    space_id: 0,
    id: 0,
    floors: [],
    region_name: DEFAULT_REGION_NAME,
  });
  const [contactDetail, setContactDetail] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    country_code: "+33",
    password: "",
    company_name: "",
    address: "",
    staff_id: "",
    contact_id: "",
    client_id: "",
    project_id: "",
    is_external: "",
    full_name: "",
    termsAndConditions: true,
  });

  const [checklists, setChecklists] = useState([]);
  const [loadingNextStep, setLoadingNextStep] = useState(false);
  const [providerList, setProviderList] = useState();
  const [invitedProviders, setInvitedProviders] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState();
  const [accessories, setAccessories] = useState([]);
  const [defaultAccessories, setDefaultAccessories] = useState([]);
  const [readOnlyMode, setReadOnlyMode] = useState(false);
  const [assignmentLoader, setAssignmentLoader] = useState(false);
  const [loadingComments, setLoadingComments] = useState(false);
  const [commentsList, setCommentsList] = useState([]);
  const [commentCount, setCommentCount] = useState(
    benchmarkDetail ? benchmarkDetail.total_comments : 0
  );
  const [unreadCount, setUnreadCount] = useState(
    benchmarkDetail ? benchmarkDetail.unread_comment_count : 0
  );
  const [templatesList, setTemplatesList] = useState([]);
  const [headerTitleText, setHeaderTitleText] = useState("");
  const [headerDescriptionText, setHeaderDescriptionText] = useState(
    "l_explore_feature_description"
  );
  const [statusClass, setStatusClass] = useState("");
  const [statusText, setStatusText] = useState("");
  const [previewChecklistSummary, setPreviewChecklistSummary] = useState(false);
  const [nextButtonText, setNextButtonText] = useState("l_next");
  const [backButtonText, setBackButtonText] = useState("");
  const [skipButtonText, setSkipButtonText] = useState("");
  const [showCommentSection, setShowCommentSection] = useState(false);
  const [regionList, setRegionList] = useState([]);
  const [coOrdinates, setCoOrdinates] = useState({
    lat: 48.83888,
    lng: 2.38062,
  });
  const [addNewFloorLoader, setAddNewFloorLoader] = useState(false);
  const [showAccessories, setShowAccessories] = useState(false);
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [recipientName, setRecipientName] = useState("");
  const [recipientImage, setRecipientImage] = useState("");
  const [allowToAccessCalendarPage, setAllowToAccessCalendarPage] =
    useState(false);
  const [uploadBulkCategory, setUploadBulkCategory] = useState(false);
  const [showAcknowledgeScreen, setShowAcknowledgeScreen] = useState(false);
  const [showexternalUserWelcomeMessage, setShowExternalUserWelcomeMessage] = useState(false);
  const [unitsList, setUnitsList] = useState([{
      id: 0,
      description: "",
      monthly: false,
      half_yearly: false,
      yearly: false,
      quarterly: false,
      key: crypto.randomUUID(),
  }]);
  const [customFields, setCustomFields] = useState([]);
  const [jobDetails, setJobDetails] = useState({});
  const [taskData, setTaskData] = useState({});
  const [imagePreviews, setImagePreviews] = useState({
    l_documets: { key: "document", previewArray: [{ url: "" }] },
  });

  const { operatorAvailability, calculationsCounts, assignedChecklistItems } =
    useScheduleSteps();

  const location = useLocation();
  const dispatch = useDispatch();

  let spaceData = localStorage.getItem("spaces");
  spaceData = spaceData ? JSON.parse(spaceData) : [];
  let spaceDetails = spaceData.find((w) =>  w.project_id && w.project_id == spaceId);

  const userType = localStorage.getItem("user_type");
  const contact_id = localStorage.getItem("contact_id") || 0;
  const staff_id = localStorage.getItem("staff_id") || 0;
  const contact_image = localStorage.getItem("contact_image");
  const full_name = localStorage.getItem("full_name");
  let selectedOffice = localStorage.getItem("selectedOffice") ? localStorage.getItem("selectedOffice") : spaceId ;
  const client_id = localStorage.getItem("client_id");
  const contact_role = localStorage.getItem("contact_role");
  const token = localStorage.getItem("accessToken");

  userType == "contact" && CUSTOM_ROLES.includes(contact_role);
  let rel_id =
    benchmarkDetail && benchmarkDetail.proposal_id
      ? benchmarkDetail.proposal_id
      : benchmarkDetail
      ? benchmarkDetail.id
      : 0;
  let rel_type =
    userType == "operator" && benchmarkDetail && benchmarkDetail.proposal_id
      ? "proposal"
      : "schedule";

  const checkforReadOnlyMode = () => {
    if (!benchmarkDetail || !Object.keys(benchmarkDetail).length) {
      return false;
    }

    if (!benchmarkId) {
      setReadOnlyMode(false);
      return false;
    }

    if (userType === "operator") {
      if (
        benchmarkDetail &&
        benchmarkDetail.is_allowed_to_edit > 0 &&
        benchmarkDetail.is_allowed_to_copy == 0
      ) {
        setReadOnlyMode(false);
      } else {
        setReadOnlyMode(true);
      }
    } else if (["contact", "staff"].includes(userType)) {
      if (benchmarkDetail) {
        if (benchmarkDetail.is_assigneed != 0 && benchmarkDetail.status != 1) {
          setReadOnlyMode(true);
        }
        if (
          benchmarkDetail.is_allowed_to_edit > 0 &&
          benchmarkDetail.is_allowed_to_copy == 0 &&
          benchmarkDetail.status != 2
        ) {
          setReadOnlyMode(false);
        } else if (
          benchmarkDetail.is_allowed_to_copy == 1 &&
          benchmarkDetail.is_allowed_to_edit == 1
        ) {
          setReadOnlyMode(true);
        } else if (benchmarkDetail.is_allowed_to_edit == 0) {
          setReadOnlyMode(true);
        }
      }
    }
  };

  const validateStep = () => {
    if (readOnlyMode) {
      return true;
    }
    let stepValidated = true;
    switch (step) {
      case "provider-type":
        if (!providerData.type || !providerData.type.trim().length) {
          showError("l_please_select_the_provider");
          stepValidated = false;
        }
        break;
      case "provider-category":
        if (
          !providerData.categoryId &&
          !providerData.categoryName.trim().length
        ) {
          showError("l_please_select_category");
          stepValidated = false;
        }
        break;
      case "provider-source":
        if (
          !providerData.sources.length &&
          providerData.type !== "invite_provider"
        ) {
          dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              `${_l("l_please_select")} ${_l("l_source")}`
            )
          );
          stepValidated = false;
        }
        break;
      case "select-provider":
        if (providerData.type === "invite_provider") {
          return true;
        }
        if (!providerData.invited_providers.length) {
          if (userType === "operator") {
            const email = localStorage.getItem("email");
            setProviderData({
              ...providerData,
              invited_providers: [{ email }],
            });
          } else {
            if (!providerData.invite_all_myr_providers) {
              showError("l_please_select_the_provider");
              stepValidated = false;
            }
          }
        }
        break;
      case "basic-details":
        if (
          !providerData.unKnownBudget &&
          (isNaN(providerData.budget) || providerData.budget <= 0)
        ) {
          dispatch(
            showMessage(
              "unsucess",
              "l_error",
              `${_l("l_please_add")} ${_l("l_budget")}`
            )
          );
          stepValidated = false;
        } else if (!providerData.unKnownStartDate && !providerData.startDate) {
          showError("l_please_select_valid_start_date");
          stepValidated = false;
        }
        break;
      default:
        break;
    }
    return stepValidated;
  };

  const existsProposals = () => {
    if (
      !benchmarkDetail ||
      !benchmarkDetail.proposal_list ||
      !benchmarkDetail.proposal_list.length ||
      !benchmarkDetail.proposal_list[0].proposals.length
    ) {
      return false;
    } else {
      return true;
    }
  };

  const isProviderDataUpdated = () => {
    let updated = false;
    if (providerData.budget != benchmarkDetail.max_budget) {
      updated = true;
    } else if (providerData.startDate != benchmarkDetail.startdate) {
      updated = true;
    }
    return updated;
  };

  const isBenchmarkAccessoryUpdated = (updatedAccessories) => {
    let flag = false;
    const currentAccessories = benchmarkDetail.accessories;
    if (!currentAccessories) {
      flag = true;
    } else if (currentAccessories.length !== updatedAccessories.length) {
      flag = true;
    } else {
      updatedAccessories.forEach((accessory) => {
        if (
          !currentAccessories.find(
            (w) =>
              w.description == accessory.description &&
              w.quantity == accessory.quantity
          )
        ) {
          flag = true;
        }
      });
    }
    return flag;
  };

  const setButtonsForGenerate = () => {
    if (type === "external-benchmark" || dashboardBenchmarkId > 0) {
      return false;
    }
    let status =
      benchmarkDetail && benchmarkDetail.status ? benchmarkDetail.status : 0;
    if (!isDataUpdated && status != 1) {
      setNextButtonText("l_next");
      setSkipButtonText("");
    } else if (status == 4) {
      setNextButtonText(generateButtonText(status));
      setSkipButtonText("");
    } else {
      setNextButtonText(generateButtonText(status));
      // setSkipButtonText("l_save_as_draft");
    }
  };

  const generateSchedule = () => {
    if (
      !benchmarkSchedule ||
      !allValidChecklists(benchmarkSchedule.week_schedule_details)
    ) {
      return false;
    }
    if (benchmarkDetail.is_allowed_to_edit == 1) {
      CustomerServices.addEditBenchmarkStep3(
        selectedOffice,
        providerData.id,
        providerData.startDate,
        Number(providerData.unKnownStartDate),
        providerData.categoryId,
        [],
        1,
        clientId,
        1,
        0
      ).then(() => {dispatch(getBenchmarkDetail(providerData.id));});
      // isDataUpdated = false;
      return true;
    }
  };

  const saveSpaceAddress = async (changeStep) => {
    if(spaceDetail && spaceDetails && spaceDetail.id <= 0 && spaceDetails.id <= 0)
    {
      showError("l_invalid_params");
    }
    if (!spaceDetail.address) {
      showError("l_address_required");
      return false;
    } else if (!spaceDetail.region_id && !spaceDetail.region_name) {
      showError("l_please_select_the_region");
      return false;
    } else {
      try {
        const response = await CustomerServices.addEditSpaces(
          spaceDetail.address,
          spaceDetail.title.trim() || spaceDetail.address,
          spaceDetail.region_id,
          spaceDetail.superfice,
          spaceDetail.number_of_desk,
          spaceDetails ? spaceDetails.id : spaceDetail.id,
          0,
          { value: spaceDetail.space_type },
          [],
          spaceDetails ? spaceDetails.id : spaceDetail.id,
          0,
          0,
          0,
          spaceDetail.logo || spaceDetail.space_logo,
          [],
          spaceDetail.floor_count,
          spaceDetail.region_name,
          coOrdinates.lat,
          coOrdinates.lng
        );
        updateSpaceDetailInLocalStorage(response.data.project_id, {office_title: spaceDetail.title, postal_address: spaceDetail.address, number_of_desk: spaceDetail.number_of_desk, superficies: spaceDetail.superfice, floor_count: spaceDetail.floor_count});
        if (response.status == 1) {
          setContactDetail({ ...contactDetail, address: spaceDetail.address });
          dispatch(getCustomerSpaces(client_id, contact_id));
          dispatch(getFloorDetails(response.data.project_id));
          if (changeStep) {
            setStep("external-basic-details");
          }
        } else {
          showError(response.message);
        }
      } catch (e) {
        showError("l_something_went_wrong");
        console.error(e);
      }
    }
  };

  const validExternalBasicDetails = () => {
    let flag = true;
    if (!providerData.budget && !providerData.unKnownBudget) {
      showError("l_please_add_budget");
      flag = false;
    } else if (!providerData.startDate && !providerData.unKnownStartDate) {
      showError("l_please_select_the_start_date");
      flag = false;
    } else if (!spaceDetail.title && !spaceDetail.address) {
      showError("l_please_add_space_title");
      flag = false;
    } else if (!benchmarkSchedule || !benchmarkSchedule.week_schedule_details) {
      showError("l_please_add_checklist_item_and_description");
      flag = false;
    } else if (!allValidChecklists(benchmarkSchedule.week_schedule_details)) {
      flag = false;
    }
    return flag;
  };

  const handleStepChange = async (direction, skipButtonClicked = false) => {
    setLoadingNextStep(true);
    const stepIndex = stepsArray.findIndex((x) => x === step);
    if (direction > -1 && validateStep()) {
      switch (step) {
        case "provider-category":
          if (
            providerData.categoryId == 0 &&
            providerData.categoryName.trim().length
          ) {
            const response = await CustomerServices.addCategory(
              providerData.categoryName,
              selectedOffice,
              0
            )
            if (response.status) {
              dispatch(getDefaultTopics(selectedOffice));
              setProviderData({
                ...providerData,
                categoryId: response.data.category_id,
                categoryName: response.data.category_name,
                isCustomCategory: true,
              });
              if (providerData.type === "add_provider") {
                navigate(`/explore-features?setting-menu=providers&section=my-providers&category=${providerData.categoryId}&space=${selectedOffice}`);
              }
            } else {
              showError("l_something_went_wrong");
            }
          }
          if (type === "external-benchmark") {
            setStep("space-address");
            setLoadingNextStep(false);
            return true;
          } else if (providerData.type === "invite_provider") {
            setStep("select-provider");
            setLoadingNextStep(false);
            return true;
          } else if (providerData.id > 0) {
            setStep(
              userType === "operator" ? "basic-details" : "select-provider"
            );
            setLoadingNextStep(false);
            return true;
          } else {
            setStep(stepsArray[stepIndex + 1]);
          }
          break;
        case "basic-details":
          if (providerData.type === "proposal") {
            setStep(
              !Number(benchmarkDetail.recursive)
                ? "proposal-amount"
                : "floor-listing"
            );
            setLoadingNextStep(false);
            return true;
          } else if (!readOnlyMode) {
            setLoadingNextStep(true);
            let isUpdated = isProviderDataUpdated();
            isDataUpdated = isUpdated; //Maintain this value to global variable
            setAssignmentLoader(true);

            CustomerServices.addEditBenchmarkStep1(
              selectedOffice,
              new Date(providerData.startDate),
              [],
              providerData.needToHire,
              providerData.furnished,
              providerData.categoryId,
              providerData.documents,
              providerData.master_id > 0 ? 0 : providerData.id,
              providerData.description,
              providerData.budget,
              providerData.unKnownStartDate,
              providerData.unKnownBudget,
              undefined,
              providerData.invited_providers.length
                ? providerData.invited_providers[0].email
                : "",
              providerData.contracts,
              new Date(providerData.endDate),
              clientId,
              providerData.invited_providers.map((x) => x.email),
              true,
              (providerData.master_id &&
                benchmarkDetail.open_schedule_modal == 1) ||
                (providerData.master_id == 0 && providerData.type) ===
                  "schedule"
                ? 1
                : 0,
              providerData.master_id,
              providerData.invite_all_myr_providers ? 1 : 0,
              isUpdated ? 1 : 0,
              customFields,
              dashboardBenchmarkId
            )
              .then((response) => {
                if (response.status) {
                  let id = Number(benchmarkId);
                  if (!id) {
                    searchParams.set("id", response.data.benchmark_id);
                    localStorage.setItem("benchmark_id",response.data.benchmark_id)
                    setProviderData({
                      ...providerData,
                      id: response.data.benchmark_id,
                    });
                    setSearchParams(searchParams);
                    dispatch(
                      getBenchmarkDetail(response.data.benchmark_id, clientId)
                    );
                    if (providerData.master_id > 0) {
                      dispatch(
                        getBenchmarkWeekSchedule(response.data.benchmark_id)
                      );
                    }
                  } else {
                    dispatch(getBenchmarkDetail(id, clientId));
                  }
                  if (type == "schedule" && !readOnlyMode) {
                    setButtonsForGenerate();
                  }
                }
                else {
                  showError(response.message);
                }
              })
              .catch(() => {
                showError("l_something_went_wrong");
              })
              .finally(() => {
                setAssignmentLoader(false);
                setLoadingNextStep(false);
              });
          } else {
            setLoadingNextStep(false);
          }
          if (userType == "operator" && providerData.unKnownStartDate) {
            if (providerData.unKnownStartDate) {
              stepsArray = [
                "provider-category",
                "basic-details",
                "floor-listing",
                "checklist-creation",
              ];
            } else {
              stepsArray = [
                "provider-category",
                "basic-details",
                "floor-listing",
                "checklist-creation",
                "assignment-screen",
                "summary-screen",
                "task-summary-screen",
              ];
            }
          }
          setStep(
            benchmarkDetail.recursive && !Number(benchmarkDetail.recursive)
              ? "request-checklists"
              : "floor-listing"
          );
          return true;
        case "floor-listing":
          if (
            providerData.type === "benchmark" ||
            providerData.type === "proposal"
          ) {
            if (!readOnlyMode && benchmarkDetail.added_checklist != 0) {
              setButtonsForGenerate();
            }
            if (benchmarkDetail.added_checklist == 0) {
              showError("l_add_checklist_items");
              setLoadingNextStep(false);

              return false;              
            } else {              
              setStep("accessories");
              setLoadingNextStep(false);
            }
            return true;
          } else {
            if (!readOnlyMode && !skipButtonClicked && !generateSchedule()) {
              setLoadingNextStep(false);
              return false;
            } else if (skipButtonClicked) {
              navigate(`/explore-features?setting-menu=providers&category=${providerData.categoryId}&section=${providerData.type}&space=${selectedOffice}`);
            }
            if (userType === "operator" && !providerData.unKnownStartDate) {
              dispatch(getBenchmarkDetail(providerData.id, clientId));
              setStep("assignment-screen");
              setLoadingNextStep(false);
              if (!stepsArray.includes("assignment-screen")) {
                stepsArray = stepsArray.concat([
                  "assignment-screen",
                  "summary-screen",
                  "task-summary-screen",
                ]);
              }
              return true;
            } else if (Number(providerData.unKnownStartDate)) {
              // Number funciton will return 1 for true and 0 for false, it is used to avoid issues due to string "1" and string "0"
              if (readOnlyMode) {
                dispatch(
                  showMessage(
                    "unsuccess",
                    _l("l_information"),
                    _l("l_start_date_not_added")
                  )
                );
                navigate(`/explore-features?setting-menu=providers&section=${providerData.type}&category=${providerData.categoryId}&space=${selectedOffice}`);
              } else if (!skipButtonClicked) {
                dispatch(
                  showConfirmation(
                    _l("l_confirm"),
                    _l("l_add_start_date_to_continue_assignment"),
                    _l("l_add"),
                    _l("l_later"),
                    () => navigate(`/explore-features?setting-menu=providers&section=${providerData.type}&category=${providerData.categoryId}&space=${selectedOffice}`),
                    () => {
                      setStep("basic-details");
                      setLoadingNextStep(false);
                      dispatch(toastCloseAction());
                    }
                  )
                );
              } else {
                navigate(`/explore-features?setting-menu=providers&section=${providerData.type}&category=${providerData.categoryId}&space=${selectedOffice}`);
              }
            } else if (["contact", "staff"].includes(userType)) {
              if (
                benchmarkDetail.is_assigneed > 0 &&
                benchmarkDetail.status != 1
              ) {
                setStep("task-summary-screen");
                setLoadingNextStep(false);
                return;
              } else {
                if (isDataUpdated) {
                  if (!skipButtonClicked && benchmarkDetail.status == 1) {
                    setShowAcknowledgeScreen(true);
                  } else {
                    dispatch(
                      showMessage(
                        "success",
                        _l("success"),
                        _l("l_data_updated")
                      )
                    );
                    navigate(`/explore-features?setting-menu=providers&section=${providerData.type}&category=${providerData.categoryId}&space=${selectedOffice}`);
                  }
                } else {
                  navigate(`/explore-features?setting-menu=providers&section=${providerData.type}&category=${providerData.categoryId}&space=${selectedOffice}`);
                }
              }
            }
          }
          break;
        case "accessories":
          if (providerData.type === "proposal") {
            setStep("proposal-amount");
            setLoadingNextStep(false);
            return true;
          }
          else if(dashboardBenchmarkId > 0) {
            setStep("benchmark-units");
          }
          // break;
        case "benchmark-units":
          // if(unitsList.length && !unitsList.filter(obj => ['monthly', 'quarterly', 'yearly', 'half_yearly'].every(key => obj[key])).length)
          // {
          //   setLoadingNextStep(false);
          //   showError("l_please_select_bpu_frequency");
          //   return false;
          // }
          if (
            !skipButtonClicked &&
            (!benchmarkSchedule ||
              !allValidChecklists(benchmarkSchedule.week_schedule_details))
          ) {
            setLoadingNextStep(false);
            return false;
          }
          if (skipButtonClicked) {
            navigate(`/explore-features?setting-menu=providers&section=${providerData.type}&category=${providerData.categoryId}&space=${selectedOffice}`);
          }
          let validAccessories = accessories.filter(
            (x) => Number(x.quantity) > 0
          );
          const isAccessoryUpdated =
            isBenchmarkAccessoryUpdated(validAccessories);

          if (
            !readOnlyMode &&
            (type !== "external-benchmark" || validAccessories.length)
          ) {
            setAssignmentLoader(true);
            CustomerServices.addEditBenchmarkAccessories(
              selectedOffice,
              providerData.id,
              validAccessories,
              new Date(providerData.startDate),
              providerData.unKnownStartDate,
              providerData.categoryId,
              clientId,
              1,
              2,
              isAccessoryUpdated ? 1 : 0,
              !skipButtonClicked && type !== "external-benchmark" && !dashboardBenchmarkId ? 1 : 0
            ).then(async(response) => {
              if(unitsList.length)
              {
                BenchmarkServices.addBenchmarkUnitItems(benchmarkDetail.project_id, benchmarkDetail.id, unitsList);
              }
              if(dashboardBenchmarkId > 0)
              {
                navigate(`/create-dpgf-benchmark?dashboard-benchmark-id=${dashboardBenchmarkId}`);
                return;
              }
              if (response.status) {
                isDataUpdated = false;
                if (type === "external-benchmark") {
                  dispatch(getBenchmarkDetail(providerData.id, clientId));
                  setStep(token ? "proposal-listing" : "client-info");
                  setLoadingNextStep(false);
                  return true;
                } else if (skipButtonClicked) {
                  navigate(`/explore-features?setting-menu=providers&section=${providerData.type}&category=${providerData.categoryId}&space=${selectedOffice}`);
                } else if (!existsProposals()) {
                  setStep("sending-proposal-loader");
                } else {
                  stepsArray = stepsArray.filter(
                    (x) =>
                      x !== "congratulation-screen" &&
                      x !== "sending-proposal-loader"
                  );
                  dispatch(getBenchmarkDetail(providerData.id, clientId));
                  setStep("proposal-listing");
                  setLoadingNextStep(false);
                  return true;
                }
              }
            }).finally(() => {
              setAssignmentLoader(false);
            });
          } else {
            dispatch(getBenchmarkDetail(providerData.id, clientId));
            setStep(token ? "proposal-listing" : "client-info");
            setLoadingNextStep(false);
            return true;
          }
          break;
        case "proposal-listing":
          if (
            type === "external-benchmark" ||
            (existsProposals() &&
              benchmarkDetail.proposal_list[0].proposals.find(
                (w) => w.status == 3
              ))
          ) {
            getScheduleComments();
            setStep("chat-screen");
            setLoadingNextStep(false);
            return;
          } else {
            navigate(`/schedules`);
          }
          break;
        case "summary-screen":
          if (operatorAvailability && operatorAvailability.length) {
            let validation = true;
            const remainingAssignmentItems =
              calculationsCounts && calculationsCounts.headerCounts
                ? Object.values(calculationsCounts.headerCounts).reduce(
                    (a, b) => a + b
                  )
                : 0;
            /**Commenting temporary as per client requirement */
            // if (remainingAssignmentItems > 0) {
            //   dispatch(
            //     showMessage(
            //       "unsucess",
            //       _l("l_error"),
            //       `${_l("l_assign_all_available_slots")}`
            //     )
            //   );
            //   setLoadingNextStep(false);
            //   return false;
            // }
            operatorAvailability.forEach((x, index) => {
              if (
                x.assignee_id <= 0 &&
                x.assigned_items &&
                x.assigned_items.length
              ) {
                validation = false;
                dispatch(
                  showMessage(
                    "unsucess",
                    _l("l_error"),
                    `${_l("l_please_select_assignees")} ${index + 1} `
                  )
                );
                setLoadingNextStep(false);
              }
            });
            if (validation) {
              setLoadingNextStep(true);
              setAssignmentLoader(true);
              let availability = [];
              const assignees = operatorAvailability.filter((x) => {
                if (x.assigned_items.length) {
                  const availabilityObject = {
                    id: x.id,
                    assignee_id: x.assignee_id,
                    assignee_name: x.assignee_name,
                    task_title: x.task_title,
                    available_from: moment(x.available_from).format("HH:mm"),
                    days: {},
                    time_slot: {},
                  };
                  Object.keys(x.days).forEach((day_name) => {
                    availabilityObject.days[day_name] = x.days[day_name];
                    if (x.time_slot[day_name] == "") {
                      availabilityObject.time_slot[day_name] = "";
                    }
                    if (
                      ![
                        "monthly",
                        "quarterly",
                        "half_yearly",
                        "yearly",
                      ].includes(day_name)
                    ) {
                      availabilityObject.time_slot[day_name] = x.time_slot[
                        day_name
                      ]
                        ? moment(x.time_slot[day_name]).format("HH:mm")
                        : "";
                    } else {
                      availabilityObject.time_slot[day_name] = x.time_slot[
                        day_name
                      ]
                        ? moment(x.time_slot[day_name] || new Date()).format(
                            "YYYY-MM-DD HH:mm"
                          )
                        : "";
                    }
                  });
                  availability.push(availabilityObject);
                  return {
                    id: x.id,
                    assignee_id: x.assignee_id,
                    item: x.assigned_items.map((x) => x.id),
                    days: x.days,
                  };
                }
              });
              if (!availability.length) {
                showError("l_no_items_selected_for_asignment");
                setLoadingNextStep(false);
                setAssignmentLoader(false);
                return false;
              }
              const assigned_items = [];
              assignedChecklistItems.forEach((item) => {
                if (item.assigned_days) {
                  let slotDetail = { item_id: item.id, days: {}, priority: 0 };
                  Object.keys(item.days).forEach((day_name) => {
                    let operatorSlotIndex =
                      item.assigned_days[day_name] == undefined
                        ? -1
                        : item.assigned_days[day_name];
                    if (
                      operatorSlotIndex !== undefined &&
                      operatorSlotIndex !== -1
                    ) {
                      slotDetail.days[day_name] =
                        operatorAvailability[operatorSlotIndex].assignee_id;
                    } else if (operatorSlotIndex === -1) {
                      slotDetail.days[day_name] = 0;
                    }
                  });
                  slotDetail.priority = item.priority;
                  assigned_items.push(slotDetail);
                }
              });
              BenchmarkServices.setScheduleSlotAssign(
                providerData.id,
                availability,
                assignees,
                assigned_items
              )
                .then((res) => {
                  if (res.status) {
                    setStep("task-summary-screen");
                    dispatch(getBenchmarkWeekSchedule(providerData.id));
                    dispatch(getBenchmarkDetail(providerData.id));
                  }
                })
                .catch((e) => {
                  console.log(e);
                  navigate("/");
                })
                .finally(() => {
                  setLoadingNextStep(false);
                  setAssignmentLoader(false);
                });
            }
          } else {
            dispatch(
              showMessage(
                "unsucess",
                _l("l_error"),
                `${_l("l_please_add_checklist_items")}`
              )
            );
          }
          break;
        case "task-summary-screen":
          getScheduleComments();
          setStep("chat-screen");
          setLoadingNextStep("false");
          break;
        case "proposal-amount":
          if (benchmarkDetail.proposal_status == 3) {
            let currentProposal =
              benchmarkDetail.proposal_list[0].proposals.find(
                (w) => w.proposal_id == benchmarkDetail.proposal_id
              );
            if (benchmarkDetail.operator_hash) {
              let element = document.getElementById("open-subscribe-now-modal");
              if (element) {
                element.click();
                setLoadingNextStep(false);
                return;
              }
            } else {
              getScheduleComments();
              setStep("chat-screen");
            }
          }
          setLoadingNextStep(false);
          break;
        case "space-address":
          saveSpaceAddress(true);
          break;
        case "external-basic-details":
          if (validExternalBasicDetails()) {
            saveSpaceAddress();
            saveBenchmark();
            if (token) {
              setStep("accessories");
            } else {
              setStep(skipButtonClicked ? "accessories" : "client-info");
            }
            setShowAccessories(skipButtonClicked);
            setLoadingNextStep(false);
            return true;
          }
          break;
        case "client-info":
          await createClientProfile();
          break;
        case "chat-screen":
          if (benchmarkDetail.generated_schedule_id > 0) {
            setProviderData({ ...providerData, id: 0 });
            dispatch(
              getBenchmarkDetail(
                benchmarkDetail.generated_schedule_id,
                client_id
              )
            );
            dispatch(
              getBenchmarkWeekSchedule(
                benchmarkDetail.generated_schedule_id,
                client_id
              )
            );
            navigate(
              `/connect-provider?space=${selectedOffice}&provider-type=schedule&id=${benchmarkDetail.generated_schedule_id}`
            );
            setStep("task-summary-screen");
          } else {
            if (userType === "operator") {
              setStep("assignment-screen");
            } else if (["contact", "staff"].includes(userType)) {
              setStep("task-summary-screen");
            }
          }
          break;
        case "select-provider":
          if (providerData.type === "invite_provider") {
            if(localStorage.getItem("is_registered") == 1){
              navigate("/aichat");
            }else{
              navigate(`/explore-features?setting-menu=providers&section=${providerData.type}&category=${providerData.categoryId}&space=${selectedOffice}`);
            }
            return;
          } else if(providerData.type === "benchmark")
          {
            // setStep("basic-details");
            setStep("file-uploads");
            break;
          }
        case "provider-source":
          if(benchmarkDetail && benchmarkDetail.is_copy_from_schedule > 0)
          {
            setStep("select-provider");
          }
          else {
            setStep(stepsArray[stepIndex + 1]);
          }
          // setStep("basic-details");
          setLoadingNextStep(false);
          break;
        case "view-job":
          let requirements = JSON.parse(JSON.stringify(jobDetails));
          let validSlots = requirements.slots.filter((slot) => moment(slot.start_time).isValid() && moment(slot.end_time).isValid() && Object.keys(slot.slots).some((day) => Number(slot.slots[day])));
          if(!validSlots || !validSlots.length)
          {
            showError("l_please_select_timeslot");
            return;
          }
          requirements.slots.forEach((slot) => {
            slot.start_time = moment(slot.start_time).isValid() ? moment(slot.start_time).format("HH:mm:ss") : "";
            slot.end_time = moment(slot.end_time).isValid() ? moment(slot.end_time).format("HH:mm:ss") : "";
            slot.hourly_rate = !isNaN(Number(slot.rate)) && Number(slot.rate) ? Number(slot.rate) : 0;
            Object.keys(slot.slots).forEach((day) => {
              slot.slots[day] = Number(slot.slots[day]);
            });
          });
          setAssignmentLoader(true);
          BenchmarkServices.updateJobOperatorRequirement(benchmarkDetail.project_id, benchmarkDetail.requirement_id, requirements).then((response) => {
            if(response.status)
            {
              setStep("proposal-listing");
              dispatch(getBenchmarkDetail);
              dispatch(getBenchmarkDetail(benchmarkDetail.id));
            }else{
              showError(response.message)
            }
          }).finally(() => {
            setAssignmentLoader(false);
          });
          break;
        case "file-uploads":
          if (skipButtonClicked) {
            setStep(stepsArray[stepIndex + 1]);
            setLoadingNextStep(false);
          } else if (imagePreviews["l_documets"].files && imagePreviews["l_documets"].files.length) {
            setLoadingNextStep(true)
            try {
              benchmarkServices
                .addBasicDetailsWithAttachments(
                  selectedOffice,
                  providerData.categoryId,
                  providerData.invited_providers.map((x) => x.email),
                  imagePreviews["l_documets"].files
                )
                .then((res) => {
                  if (res && res.status) {
                    let id =
                      !providerData.id || providerData.master_id > 0
                        ? Number(res.data.schedule_id)
                        : providerData.id;
                    dispatch(getBenchmarkDetail(id, clientId));
                    if (searchParams.get("id") <= 0) {
                      dispatch(getBenchmarkWeekSchedule(id));
                      searchParams.set("id", id);
                      setSearchParams(searchParams);
                      setProviderData({ ...providerData, id, master_id: 0 });
                      setIdToLocalSpaceData(id);
                      // dispatch(getCustomerSpaces(clientId, contact_id));
                    }
                    setLoadingNextStep(false);
                    setStep(stepsArray[stepIndex + 1]);
                  } else {
                    setLoadingNextStep(false)
                  }
                });
            } catch (error) {
              console.log(error)
            }
          } else {
            showError("l_upload_additional_document")
          }
          // setStep(stepsArray[stepIndex + 1]);
          break
          default:
          setStep(stepsArray[stepIndex + 1]);
          break;
      }
    } else if (direction < 0) {
      if (stepIndex === 0) {
        if(["benchmark", "external-benchmark"].includes(providerData.type) && location.pathname == "/connect")
        {
          searchParams.set("direction", "back");
          setSearchParams(searchParams);
        }
        else if (["external-benchmark"].includes(providerData.type))
        {
          window.location.href = "/?page=connect&register-type=1";
        }
        else if (location.pathname.includes("connect-provider")) {
          if (userType === "operator" && !spaceData) {
            navigate("/proposals", { state: { from: viewPage } });
          } else {
            navigate(`/explore-features?setting-menu=providers&section=${providerData.type}&category=${providerData.categoryId}&space=${selectedOffice}`);
          }
        } else if (userType === "operator") {
          setStep("provider-type");
        }
      } else {
        switch (step) {
          case "assignment-screen":
            setStep("floor-listing");
            break;
          case "accessories":
            if (type === "external-benchmark") {
              setStep("external-basic-details");
            } else {
              setStep("floor-listing");
            }
            break;
          case "proposal-listing":
            if (type === "external-benchmark") {
              setStep("accessories");
            } else {
              setButtonsForGenerate();
              setStep(stepsArray[stepIndex - 1]);
            }
            break;
          case "external-basic-details":
            setStep("space-address");
            break;
          case "benchmark-units":
            setStep("accessories");
            break;
          case "client-info":
            if (showAccessories) {
              setStep("accessories");
            } else {
              setStep("external-basic-details");
            }
            break;
          case "chat-screen":
            if (userType === "operator") {
              if (type === "proposal") {
                setStep("proposal-amount");
              } else {
                if (benchmarkDetail.parent_id > 0) {
                  setProviderData({ ...providerData, id: 0 });
                  dispatch(
                    getBenchmarkDetail(benchmarkDetail.parent_id, client_id)
                  );
                  dispatch(getBenchmarkWeekSchedule(benchmarkDetail.parent_id));
                  navigate(
                    `/connect-provider?space=${selectedOffice}&provider-type=proposal&id=${benchmarkDetail.parent_id}`
                  );
                } else {
                  setStep("floor-listing");
                  setLoadingNextStep(false);
                  return;
                }
              }
            } else if (["contact", "staff"].includes(userType)) {
              setStep(
                type === "schedule" ? "floor-listing" : "proposal-listing"
              );
            }
            break;
          case "task-summary-screen":
            setStep(
              userType === "operator" ? "summary-screen" : "floor-listing"
            );
            break;
          case "space-address":
            setStep("provider-category");
            break;
          case "view-job":
            setStep("proposal-listing");
            break;
          case "select-provider":
            if (benchmarkDetail && benchmarkDetail.is_copy_from_schedule > 0) {
              setStep("provider-source");
            }
            else {
              setStep(stepsArray[stepIndex - 1]);
            }
            break;
          case "basic-details":
            if (benchmarkDetail && benchmarkDetail.is_copy_from_schedule > 0) {
              setStep("select-provider");
            }
            else {
              setStep(stepsArray[stepIndex - 1]);
            }  
          default:
            setStep(stepsArray[stepIndex - 1]);
            break;
        }
      }
    }
    if (step !== "file-uploads") {
      setLoadingNextStep(false);
    }
  };

  const getSpaceItemsIdByFloor = (items, id) => {
    let itemIds = [];
    items.forEach((x) => {
      if (x.floor_id == id) {
        itemIds.push(x);
      }
    });
    return itemIds;
  };

  const setFloorwiseChecklistData = (floor) => {
    let typeOfSpaceData = floorDetails.type_of_space_items.filter(
      (w) => w.floor_id == floor.id
    );
    let existingChecklistArray = [];

    const defaultSpace = floorDetails.type_of_space.find(
      (w) => w.is_default == 1
    );
    typeOfSpaceData = [
      {
        id: 0,
        room_id: defaultSpace.id,
        title: defaultSpace.title,
        room_title: defaultSpace.title,
        items: [],
        is_default: 1,
      },
    ].concat(typeOfSpaceData);

    if (
      benchmarkSchedule &&
      benchmarkSchedule.week_schedule_details &&
      benchmarkSchedule.week_schedule_details.length
    ) {
      benchmarkSchedule.week_schedule_details.forEach((checklist) => {
        let typeOfSpace = typeOfSpaceData.find(
          (w) => w.room_id == checklist.room_id && (w.is_default == 1 || w.id == checklist.type_of_space_item_id)
        );

        if (
          typeOfSpace &&
          (typeOfSpace.is_default == 1 ||
            typeOfSpace.id == checklist.type_of_space_item_id)
        ) {
          existingChecklistArray.push({
            ...checklist,
            spaceTitle: typeOfSpace.room_title,
            description: checklist.item_name,
            slot:
              moment(checklist.item_slot).isValid() ||
              checklist.item_slot instanceof Date
                ? checklist.item_slot
                : new Date(`01-01-2000 ${checklist.item_slot}`),
            type_of_space_items: checklist.type_of_space_items,
            type_of_space_item_id: checklist.type_of_space_item_id,
            is_default:
              defaultSpace.id == checklist.room_id ||
              checklist.type_of_space_item_id == 0
                ? 1
                : 0,
          });
        }
      });
    }

    if (checklistClipboard && checklistClipboard.length) {
      checklistClipboard.forEach((x) => {
        if (x.floor_id == floor.id) {
          existingChecklistArray.push(x);
        }
      });
    }

    existingChecklistArray = existingChecklistArray.filter(
      (obj, index, self) =>
        index ===
        self.findIndex(
          (o) =>
            obj.room_id == o.room_id &&
            obj.type_of_space_item_id == o.type_of_space_item_id &&
            obj.description == o.description
        )
    );

    if (!readOnlyMode) {
      typeOfSpaceData.forEach((type_of_space) => {
        existingChecklistArray.push({
          ...checklistItem,
          description: "",
          is_default: type_of_space.is_default || 0,
          room_id: type_of_space.room_id,
          spaceTitle: type_of_space.room_title,
          type_of_space_item_id: type_of_space.id,
          type_of_space_items: [],
        });
      });
    }

    existingChecklistArray.sort((a, b) => b.is_default - a.is_default);
    setChecklists(existingChecklistArray);
    setStep("checklist-creation");
    setSelectedFloor(floor);
    dispatch(isCreareTaskDefaultTopics(defaultTopics));
  };

  const hasValidTypeOfSpaces = (spaceId, spaceItemId) => {
    let flag = true;
    const space = floorDetails.type_of_space
      ? floorDetails.type_of_space.find((x) => x.id == spaceId)
      : undefined;
    if (space && space.is_default == 1) {
      return flag;
    }
    if (spaceItemId > 0) {
      return flag;
    } else {
      return false;
    }
  };

  const getFloorNameFromId = (id) => {
    let floor = floorDetails.floor.find((w) => w.id == id);
    return floor ? floor.title : "";
  };

  const getTypeOfSpaceDataFromId = (id, key) => {
    let value = "";
    if (id == 0) {
      if (key == "floor_id") {
        return 0;
      } else {
        return "General Area";
      }
    }
    floorDetails.type_of_space.forEach((space) => {
      space.details.forEach((w) => {
        if (w.id == id) {
          value = w[key];
        }
      });
    });
    return value;
  };

  const allValidChecklists = (checklists) => {
    let checklistArray = checklists.slice();
    let flag = true;
    let typeOfSpaceItemId = -1;
    let floorId = 0;

    if (checklistArray) {
      checklistArray.reverse().forEach((checklist) => {
        if (
          checklist.days &&
          Object.values(checklist.days).findIndex((x) => x) === -1
        ) {
          typeOfSpaceItemId = checklist.type_of_space_item_id;
          floorId = getTypeOfSpaceDataFromId(typeOfSpaceItemId, "floor_id");
          flag = false;
        }
      });
    }
    if (!flag && typeOfSpaceItemId >= 0) {
      dispatch(
        showMessage(
          "unsuccess",
          _l("l_error"),
          `${_l(
            "l_missing_frequency_in_checklist_for_space"
          )} ${getTypeOfSpaceDataFromId(typeOfSpaceItemId, "title")} ${
            floorId == 0
              ? ""
              : `, ${_l(
                  "l_floor_system_generated_title"
                )}: ${getFloorNameFromId(floorId)}`
          }`
        )
      );
      return false;
    }
    if (!checklistArray.find((w) => w.item_name && w.item_name.trim().length)) {
      showError("l_please_add_checklist_item_and_description");
      return false;
    }
    return flag;
  };

  const isChecklistItemUpdated = (latestChecklists) => {
    let updated = false;
    if (latestChecklists.find((w) => (w.id == 0 && w.item_name) || (w.is_new == 1))) {
      updated = true;
    } else if (benchmarkSchedule && benchmarkSchedule.week_schedule_details) {
      let previousChecklistArray = benchmarkSchedule.week_schedule_details;
      latestChecklists.forEach((checklist) => {
        let previousChecklist = previousChecklistArray.find(
          (w) => w.id == checklist.id
        );
        if (previousChecklist) {
          let totalPreviousEquipments = 0;
          let totalCurrentEquipments = 0;
          previousChecklistArray.map((w) => 
          w.equipment_detail && w.equipment_detail.length > 0 && w.equipment_detail.map((equip) =>
            totalPreviousEquipments = totalPreviousEquipments + equip.quantity
          )
        )
        latestChecklists.map((w) => 
          w.equipment_detail && w.equipment_detail.length > 0 && w.equipment_detail.map((equip) =>
            totalCurrentEquipments = totalCurrentEquipments + equip.quantity
          )
        )
          if (
            previousChecklist.item_name.trim().toLowerCase() !==
              checklist.item_name.trim().toLowerCase() ||
            previousChecklist.priority != checklist.priority ||
            previousChecklist.photo_required != checklist.photo_required ||
            totalPreviousEquipments !== totalCurrentEquipments
          ) {
            updated = true;
          } else {
            let previousChecklistDays = Object.keys(
              previousChecklist.days
            ).filter((w) => previousChecklist.days[w]);
            let currentChecklistDays = Object.keys(checklist.days).filter(
              (w) => checklist.days[w]
            );
            if (!isSameArrays(previousChecklistDays, currentChecklistDays)) {
              updated = true;
            }
          }
        }
      });
    }
    return updated;
  };

  const saveFloorChecklistItems = (formattedChecklistArray) => {
    const isChecklistUpdated = isChecklistItemUpdated(formattedChecklistArray);

    isDataUpdated = isChecklistUpdated; //save to global variable
    if (formattedChecklistArray.length) {
      setAssignmentLoader(true);
      BenchmarkServices.addBenchmarkChecklists(
        providerData.id,
        selectedOffice,
        formattedChecklistArray,
        selectedFloor.id,
        isChecklistUpdated ? 1 : 0
      )
        .then((response) => {
          if (response.status) {
            dispatch(getBenchmarkWeekSchedule(providerData.id));
            dispatch(getBenchmarkDetail(providerData.id));
            setStep(
              type === "external-benchmark"
                ? "external-basic-details"
                : "floor-listing"
            );
            if (type === "schedule") {
              setButtonsForGenerate();
            }
            dispatch(isCreareTaskDefaultTopics(isCreatesTaskDefaultTopics, 'isFromCreateTask'));
          } else {
            showError("l_something_went_wrong");
          }
          checklists.forEach((item)=>{
            if (item.is_new && item.is_new > 0) {
              item.is_new = 0;
            }
          })
        })
        .finally(() => {
          setAssignmentLoader(false);
        });
    } else {
      setStep(
        type === "external-benchmark"
          ? "external-basic-details"
          : "floor-listing"
      );
    }
  };

  const handleChecklistModalClose = (floorChecklists, confirmSave) => {
    if (previewChecklistSummary) {
      setPreviewChecklistSummary(false);
      setStep("proposal-amount");
      return true;
    }
    let formattedChecklistArray = [];
    floorChecklists.forEach((checklist) => {
      if (
        checklist.description &&
        hasValidTypeOfSpaces(checklist.room_id, checklist.type_of_space_item_id)
      ) {
        formattedChecklistArray.push({
          ...checklistItemFormat,
          id: checklist.id || 0,
          slot: checklist.slot,
          slot_title: checklist.slot_title,
          room_id: checklist.room_id,
          floor_id: selectedFloor.id,
          item_name: checklist.description,
          checklist: [
            {
              name: checklist.description,
              photo: 0,
              selected: 1,
              addedFromTemplate: false,
              unique_id: checklist.id,
              is_default_checklist: false,
              tasks_per_month: 1,
            },
          ],
          days: checklist.days,
          priority: checklist.priority,
          comments: checklist.item_comments,
          photo_required: checklist.photo_required,
          type_of_space_items: checklist.type_of_space_items,
          type_of_space_item_id: checklist.type_of_space_item_id,
          equipment_detail : checklist.equipment_detail,
          is_new : checklist.is_new ? checklist.is_new : 0
        });
      }
    });
    if(confirmSave && !allValidChecklists(formattedChecklistArray))
    {
      return false;
    }
    if (readOnlyMode || !isChecklistItemUpdated(formattedChecklistArray)) {
      setStep(
        type === "external-benchmark"
          ? "external-basic-details"
          : "floor-listing"
      );
      return true;
    }
    if (confirmSave) {
      saveFloorChecklistItems(formattedChecklistArray);
    } else {
      dispatch(
        showConfirmation(
          _l("l_confirm"),
          _l("l_save_checklists_changes_confirmation"),
          _l("l_save"),
          _l("l_cancel"),
          () => {
            setStep(
              type === "external-benchmark"
                ? "external-basic-details"
                : "floor-listing"
            );
          },
          () => {
            dispatch(toastCloseAction());
            saveFloorChecklistItems(formattedChecklistArray);
          }
        )
      );
    }
  };

  const getScheduleComments = async () => {
    let master_id = benchmarkDetail.id;
    try {
      if (existsProposals()) {
        let proposal = benchmarkDetail.proposal_list[0].proposals.find(
          (w) => w.status == 3
        );
        if (proposal) {
          rel_id = proposal.proposal_id;
          rel_type = "proposal";
          master_id = benchmarkDetail.parent_id || benchmarkDetail.id;
        }
      } else if (benchmarkDetail.schedule_proposal_id > 0) {
        rel_id = benchmarkDetail.schedule_proposal_id;
        rel_type = "proposal";
        master_id = benchmarkDetail.parent_id;
      }
      setLoadingComments(true);
      dispatch(getMentionList(rel_id, rel_type));
      const response = await DocumentsServices.getComments(
        rel_id,
        rel_type,
        undefined,
        undefined,
        1,
        master_id
      );
      if (response.data) {
        if (unreadCount > 0) {
          if (
            localStorage.getItem("selectedVirtualSpace") &&
            JSON.parse(localStorage.getItem("selectedVirtualSpace"))
          ) {
            dispatch(
              setVirtualSpaceNotificationCount(
                selectedOffice,
                Number(benchmarkDetail.unread_comment_count),
                -1
              )
            );
          } else {
            dispatch(
              setSpaceNotificationCount(
                selectedOffice,
                Number(benchmarkDetail.unread_comment_count),
                -1
              )
            );
          }
          dispatch(
            setCategoryNotificationCounts(
              "providers",
              benchmarkDetail.provider_id,
              type != "schedule" ? "benchmark_count" : "schedule_count",
              Number(benchmarkDetail.unread_comment_count),
              -1
            )
          );
        }
        let formattedComments = response.data.map((comment) => {
          if (comment.attachments) {
            comment.attachments.forEach((w) => {
              w.file_name = w.file_url;
            });
          }
          return {
            ...comment,
            profile_url: comment.addedfrom_details.profile_url,
            full_name: comment.addedfrom_details.full_name,
            date: moment(comment.dateadded).fromNow(),
          };
        });
        formattedComments.sort((a, b) => Number(a.id) - Number(b.id));
        setCommentsList(formattedComments);
        setUnreadCount(0);
        dispatch(commanoffcanvasisOpenfromCalendar(true));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingComments(false);
    }
  };

  const handleAddComment = async (commentData) => {
    const { content: comment, files, editCommentId: id } = commentData;
    let master_id = benchmarkDetail.id;

    if (isAcceptedAnyProposal()) {
      let proposal = benchmarkDetail.proposal_list[0].proposals.find(
        (w) => w.status == 3
      );
      if (proposal) {
        rel_id = proposal.proposal_id;
        rel_type = "proposal";
      }
    } else if (benchmarkDetail.schedule_proposal_id > 0) {
      rel_id = benchmarkDetail.schedule_proposal_id;
      rel_type = "proposal";
      master_id = benchmarkDetail.parent_id;
    }
    try {
      if ((comment && comment.trim().length) || files) {
        const response = await DocumentsServices.addDocumentComment(
          rel_type,
          rel_id,
          comment,
          undefined,
          id,
          files,
          master_id
        );
        if (id > 0) {
          const commentIndex = commentsList.findIndex((w) => w.id == id);
          setCommentsList(
            Object.values({
              ...commentsList,
              [commentIndex]: {
                ...commentsList[commentIndex],
                content: comment,
              },
            })
          );
        } else {
          if (response.data.comment_id) {
            let attachments = [];

            if (files) {
              Array.from(files).map((file) => {
                attachments.push({
                  file_url: URL.createObjectURL(file),
                  file_name: file.name,
                });
              });
            }
            setCommentCount(commentsList.length + 1);
            setCommentsList(
              commentsList.concat([
                {
                  content: comment,
                  full_name,
                  contact_id,
                  staffid: staff_id,
                  profile_url: contact_image,
                  id: response.data.comment_id,
                  attachments,
                  is_allowed_to_edit: 1,
                },
              ])
            );
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const deleteComment = (id) => {
    try {
      dispatch(
        showConfirmation(
          _l("l_confirm"),
          _l("l_you_want_to_delete_this_comment"),
          _l("l_yes"),
          _l("l_no"),
          () => {},
          async () => {
            const response = await DocumentsServices.removeComment(
              benchmarkDetail.id,
              type,
              id
            );
            if (response) {
              dispatch(toastCloseAction());
              setCommentsList(commentsList.filter((w) => w.id != id));
            }
          }
        )
      );
    } catch (e) {
      console.error(e);
    }
  };

  const getChecklistTemplates = async () => {
    try {
      const response = await BenchmarkServices.getChecklistTemplate(
        selectedOffice,
        benchmarkDetail.provider_id
      );
      if (response.status) {
        setTemplatesList(response.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getBenchmarkStatusText = (benchmark) => {
    if (benchmark.is_schedule == 0) {
      if (userType == "operator") {
        switch (benchmark.proposal_status) {
          case "2":
            setStatusClass("badge-red");
            setStatusText(_l("l_rejected"));
            break;
          case "3":
            setStatusClass("done");
            setStatusText(_l("l_accepted"));
            break;
          case "4":
            setStatusClass("done");
            if (
              benchmark.operator_total == 0 ||
              benchmark.operator_total == null
            ) {
              setStatusText(_l("l_received"));
            } else {
              setStatusClass("badge-warning");
              setStatusText(_l("l_pending"));
            }
            break;
          default:
            break;
        }
      } else {
        if (benchmark.status == 1) {
          setStatusClass("badge-warning");
          setStatusText(_l("l_draft"));
        } else if (benchmark.is_opened == 1) {
          setStatusClass("");
          setStatusText(_l("l_open"));
        } else {
          setStatusClass("badge-red");
          setStatusText(_l("l_close"));
        }
      }
    } else {
      if (benchmark.status == 3 || benchmark.status == 4) {
        if (!benchmark.startdate) {
          setStatusClass("badge-warning");
          setStatusText(_l("l_waiting_for_start_date"));
        } else if (benchmark.is_assigneed == 0) {
          setStatusClass("badge-warning");
          setStatusText(_l("l_waiting_for_assignee"));
        } else {
          setStatusClass("");
          setStatusText(_l(benchmark.status_text));
        }
      } else if (benchmark.status == 1) {
        setStatusClass("badge-warning");
        setStatusText(_l("l_draft"));
      } else {
        setStatusClass("");
        setStatusText(benchmark.status_text);
      }
    }
  };

  const getChecklistCountBySpaceItem = (id) => {
    let count = 0;
    if (benchmarkSchedule && benchmarkSchedule.week_schedule_details) {
      count = benchmarkSchedule.week_schedule_details.filter(
        (w) => w.type_of_space_item_id == id
      ).length;
    }
    return count;
  };

  const getFloorwiseChecklistCount = (id) => {
    let count = 0;

    if (benchmarkSchedule && benchmarkSchedule.week_schedule_details) {
      let typeOfSpaceItems = [];
      floorDetails.type_of_space.forEach((space) => {
        space.details.forEach((spaceItem) => {
          if (spaceItem.floor_id == id) {
            typeOfSpaceItems.push(spaceItem.id);
          }
        });
      });
      benchmarkSchedule.week_schedule_details.forEach((checklist) => {
        if (
          typeOfSpaceItems.includes(checklist.type_of_space_item_id) ||
          checklist.type_of_space_item_id == 0
        ) {
          count++;
        }
      });
    }

    return count;
  };

  const getFloorSuperficy = (id) => {
    let superficy = 0;
    let floor = floorDetails.floor.find((w) => w.id == id);
    if (floor) {
      superficy = floor.superficy;
    }
    return superficy;
  };

  const addEditFloor = async (direction) => {
    let currentFloorList = floorDetails.floor.slice();
    if (direction == -1) {
      if (floorDetails.floor.length > 1) {
        let lastFloor = currentFloorList.pop();
        dispatch(
          showConfirmation(
            _l("l_are_you_sure"),
            `${_l("l_delete_floor_confirmation")} ${lastFloor.title} ?`,
            _l("l_delete"),
            _l("l_no"),
            () => {},
            async () => {
              if (lastFloor.id > 0) {
                try {
                  const response = await CustomerServices.deleteFloorItems(
                    selectedOffice,
                    "floor",
                    [lastFloor.id],
                    "floor_id"
                  );
                  if (response.status == 1) {
                    dispatch(
                      setFloorPlanning(
                        response.data.type_of_space,
                        response.data.floor
                      )
                    );
                    setSpaceDetail({
                      ...spaceDetail,
                      floor_count: response.data.floor.length,
                    });
                  }
                } catch (e) {
                  console.error(e);
                } finally {
                  dispatch(toastCloseAction());
                }
              }
            }
          )
        );
      }
    } else if (!addNewFloorLoader) {
      setAddNewFloorLoader(true);
      try {
        const response = await CustomerServices.addFloorItem(
          selectedOffice,
          1,
          "",
          "floor",
          0,
          currentFloorList.length
        );
        if (response.status) {
          dispatch(
            setFloorPlanning(response.data.type_of_space, response.data.floor)
          );
          setSpaceDetail({
            ...spaceDetail,
            floor_count: response.data.floor.length,
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        setAddNewFloorLoader(false);
      }
    }
  };

  const setIdToLocalSpaceData = (id) => {
    spaceData.forEach((w) => {
      if (w.project_id === selectedOffice) {
        w.schedule_id = id;
        w.is_external = 1;
      }
    });
    localStorage.setItem("spaces", JSON.stringify(spaceData));
  };

  const saveBenchmark = async (reloadChecklists) => {
    let flag = false;
    let id = !isNaN(Number(benchmarkId)) ? Number(benchmarkId) : providerData.id;
    try {
      setAssignmentLoader(true);
      const response = await CustomerServices.addEditBenchmarkStep1(
        selectedOffice,
        new Date(providerData.startDate),
        [],
        providerData.needToHire,
        providerData.furnished,
        providerData.categoryId,
        providerData.documents,
        providerData.master_id > 0 ? 0 : id,
        providerData.description,
        providerData.budget,
        providerData.unKnownStartDate,
        providerData.unKnownBudget,
        undefined,
        providerData.invited_providers.length
          ? providerData.invited_providers[0].email
          : "",
        providerData.contracts,
        new Date(providerData.endDate),
        clientId,
        providerData.invited_providers.map((x) => x.email),
        true,
        (providerData.master_id && benchmarkDetail.open_schedule_modal == 1) ||
          (providerData.master_id == 0 && providerData.type) === "schedule"
          ? 1
          : 0,
        providerData.master_id,
        providerData.invite_all_myr_providers ? 1 : 0,
        0,
        customFields,
        dashboardBenchmarkId
      );
      if (response.status) {
        let id =
          !providerData.id || providerData.master_id > 0
            ? Number(response.data.benchmark_id)
            : providerData.id;
        dispatch(getBenchmarkDetail(id, clientId));
        if (searchParams.get("id") <= 0) {
          dispatch(getBenchmarkWeekSchedule(id));
          searchParams.set("id", id);
          setSearchParams(searchParams);
          setProviderData({ ...providerData, id, master_id: 0 });
          setIdToLocalSpaceData(id);
          // dispatch(getCustomerSpaces(clientId, contact_id));
        }
        flag = true;
      }
    } catch (e) {
      showError("l_something_went_wrong");
    } finally {
      setAssignmentLoader(false);
      setLoadingNextStep(false);
      return flag;
    }
  };

  const setExternalFloorChecklist = async (floor) => {
    if (!providerData.id) {
      if (!providerData.budget && !providerData.unKnownBudget) {
        showError("l_please_add_budget");
      } else if (!providerData.startDate && !providerData.unKnownStartDate) {
        showError("l_please_select_the_start_date");
      } else {
        try {
          const isSaved = await saveBenchmark();
          if (isSaved) {
            setFloorwiseChecklistData(floor);
          } else {
            showError("l_something_went_wrong");
          }
        } catch (e) {
          console.error(e);
        }
      }
    } else {
      setFloorwiseChecklistData(floor);
    }
  };

  const valiadateClientDetails = () => {
    let flag = true;
    if (!contactDetail.first_name || !contactDetail.first_name.trim().length) {
      showError("l_please_enter_contact_name");
      flag = false;
    } else if (!contactDetail.country_code) {
      showError("l_please_select_the_country_code");
      flag = false;
    } else if (
      !contactDetail.mobile_number ||
      !contactDetail.mobile_number.trim().length
    ) {
      showError("l_phone_number_cannot_be_empty");
      flag = false;
    } else if (!validateEmail(contactDetail.email)) {
      showError("l_please_enter_valid_email");
      flag = false;
    } else if (
      !contactDetail.company_name ||
      !contactDetail.company_name.trim().length
    ) {
      showError("l_please_enter_company_name");
      flag = false;
    } else if (!contactDetail.address || !contactDetail.address.trim().length) {
      showError("l_please_enter_address");
      flag = false;
    } else if (
      !contactDetail.password ||
      !contactDetail.password.trim().length
    ) {
      showError("l_please_enter_password");
      flag = false;
    } else if (!contactDetail.termsAndConditions) {
      showError("l_please_accept_terms_and_conditions");
      flag = false;
    }
    return flag;
  };

  const createClientProfile = async () => {
    
    if (valiadateClientDetails()) {
      try {
        setShowExternalUserWelcomeMessage(true);
        dispatch(getCustomerSpaces(client_id, contact_id));
        const response = await AuthServices.createContactProfile({
          ...contactDetail,
          first_name: contactDetail.first_name.split(" "),
          last_name: contactDetail.first_name.split(" ").slice(1),
          mobile_number: `${contactDetail.country_code}${contactDetail.mobile_number}`,
          project_id: selectedOffice,
        });
        if (response.status) {
          dispatch(getBenchmarkDetail(providerData.id));
          dispatch(login(contactDetail.email, contactDetail.password)).then(
            () => {
              dispatch(setProject(benchmarkDetail.project_id));
              localStorage.setItem("selectedOffice", benchmarkDetail.project_id);
              navigate(`/aichat?plan=customer_plan_1`);
            }
          );
        }
      } catch (e) {
        showError("l_something_went_wrong");
        console.error(e);
      } finally {
        setShowExternalUserWelcomeMessage(true);
      }
    }
  };

  const isPendingBenchmarkForCategory = (categoryId) => {
    let flag = false;
    if(dashboardBenchmarkId > 0)
    {
      return flag;
    }
    if(spaceDetails.pending_benchmarks && spaceDetails.pending_benchmarks.length)
    {
      let selectedCategoryBenchmark = spaceDetails.pending_benchmarks.find((w) => w.provider_id == categoryId);
      if(selectedCategoryBenchmark)
      {
        flag = true;
        searchParams.set("id", selectedCategoryBenchmark.id);
        setSearchParams(searchParams);
        dispatch(getBenchmarkDetail(selectedCategoryBenchmark.id));
      }
    }
    return flag;
  };

  const setStepWiseFooterButtonText = (step) => {
    switch (step) {
      case "provider-type":
        setHeaderTitleText("l_provider_type_title");
        setHeaderDescriptionText("l_provider_type_subtitle");
        setBackButtonText("");
        setNextButtonText("l_next");
        setSkipButtonText("");
        break;
      case "provider-category":
        setHeaderTitleText("l_add_new_provider");
        setHeaderDescriptionText("l_provider_category_subtitle");
        if (providerData.type === "add_provider") {
          setBackButtonText("");
          setNextButtonText("l_add");
          setSkipButtonText("");
        } else if (
          ["invite_provider"].includes(providerData.type) ||
          ["external-benchmark"].includes(type)
        ) {
          setBackButtonText("l_back");
          setNextButtonText("l_next");
          setSkipButtonText("");
        } else {
          setBackButtonText("l_back");
          setNextButtonText("l_next");
          setSkipButtonText("");
        }
        break;
      case "provider-source":
        if(providerData.id <= 0 && isPendingBenchmarkForCategory(providerData.categoryId) && ["benchmark", "external-benchmark"].includes(type))
        {
          setStep("proposal-listing");
        }
        setHeaderTitleText("l_provider_source_title");
        setHeaderDescriptionText("l_provider_source_subtitle");
        setBackButtonText("l_back");
        setNextButtonText("l_next");
        setSkipButtonText("");
        break;
      case "select-provider":
        setHeaderTitleText("l_select_provider_title");
        setHeaderDescriptionText("l_select_provider_subtitle");
        setBackButtonText("l_back");
        setNextButtonText("l_next");
        setSkipButtonText("");
        break;
      case "file-uploads":
        setHeaderTitleText("l_upload_documents");
        setHeaderDescriptionText("l_upload_document_subtitle");
        setBackButtonText("l_back");
        setNextButtonText("l_next");
        setSkipButtonText("l_skip");
        break;
      case "basic-details":
        setHeaderTitleText("l_basic_details_title");
        setHeaderDescriptionText("l_basic_details_subtitle");
        setBackButtonText(providerData.id > 0 && benchmarkDetail && benchmarkDetail.is_copy_from_schedule <= 0 ? "" : "l_back");
        setNextButtonText(providerData.id > 0 ? "l_floor_options" : "l_create");
        setSkipButtonText("");
        break;
      case "floor-listing":
        let benchmark_id = localStorage.getItem("benchmark_id") ? localStorage.getItem("benchmark_id") : 0
        if(providerData.id <= 0 && benchmark_id < 0)
        {
          setStep("basic-details");
          return;
        }
        localStorage.removeItem("benchmark_id")
        setHeaderTitleText("l_floor_listing_title");
        setHeaderDescriptionText("l_floor_listing_subtitle");
        if (userType === "operator") {
          if (readOnlyMode) {
            setSkipButtonText("");
            setBackButtonText("l_basic_details");
            setNextButtonText(
              type === "proposal" ? "l_next" : "l_go_to_assignment"
            );
          } else {
            setBackButtonText("l_basic_details");
            setNextButtonText("l_generate_and_assign");
            // setSkipButtonText("l_save_as_draft");
          }
        } else if (["contact", "staff"].includes(userType)) {
          setSkipButtonText("");
          if (readOnlyMode) {
            setBackButtonText("l_view_details");
            if (["benchmark", "external-benchmark"].includes(type)) {
              setNextButtonText("l_next");
            } else {
              setNextButtonText(
                ![3, 4].includes(Number(benchmarkDetail.status)) || benchmarkDetail.is_assigneed == 0
                  ? "l_next"
                  : "l_go_to_calendar"
              );
            }
          } else {
            switch (type) {
              case "benchmark":
                setBackButtonText("l_view_details");
                setNextButtonText("l_next");
                break;
              case "schedule":
                if (benchmarkDetail.is_assigneed == "0" && !readOnlyMode) {
                  setBackButtonText("l_view_details");
                  setNextButtonText("l_next");
                } else {
                  setBackButtonText("l_view_details");
                  setNextButtonText("l_go_to_calendar");
                }
                break;
              case "external-benchmark":
                setBackButtonText("l_back");
                setNextButtonText("l_next");
                break;
            }
          }
        }
        break;
      case "request-checklists":
        setHeaderTitleText("l_request_checklists_title");
        setHeaderDescriptionText("l_request_checklists_subtitle");
        setSkipButtonText("");
        setBackButtonText("l_view_details");
        setNextButtonText("l_next");
        break;
      case "benchmark-units":
        setHeaderTitleText("l_benchmark_units_header");
        setHeaderDescriptionText("l_benchmark_units_description");
        if (readOnlyMode) {
          setBackButtonText("l_view_accessories");
          setNextButtonText("l_view_proposals");
        } else {
          setBackButtonText("l_view_accessories");
          setNextButtonText(
            existsProposals()
              ? "l_view_proposals"
              : benchmarkDetail.status == 1 && type !== "external-benchmark" && !dashboardBenchmarkId
              ? "l_generate"
              : "l_next"
          );
        }
        break;
      case "accessories":
        setHeaderTitleText("l_accessories_title");
        setHeaderDescriptionText("l_accessories_subtitle");
        if (userType === "operator") {
          setBackButtonText("l_view_checklists");
          setNextButtonText("l_view_summary");
          setSkipButtonText("");
        } else if (["contact", "staff"].includes(userType)) {
          setSkipButtonText("");
          setBackButtonText("l_back");
          setNextButtonText("l_generate");
        }
        break;
      case "proposal-listing":
        setHeaderTitleText("l_proposal_listing_title");
        setHeaderDescriptionText("l_proposal_listing_subtitle");
        setBackButtonText("l_back");
        setNextButtonText(isAcceptedAnyProposal() ? "l_go_to_chat_screen" : "");
        setSkipButtonText("");
        if (benchmarkDetail.generated_schedule_id > 0) {
          isTaskGenerationCompleted(benchmarkDetail.generated_schedule_id);
        }
        break;
      case "space-address":
        spaceDetails = spaceData.find((w) => w.project_id == spaceId);
        if(spaceDetails)
        {
          setSpaceDetail({
            ...spaceDetail,
            region_id: spaceDetails.region_id,
            address: spaceDetails.postal_address,
            number_of_desk:
              spaceDetails.number_of_desk > 0 ? spaceDetails.number_of_desk : 10,
            superfice:
              spaceDetails.superficies > 0 ? spaceDetails.superficies : 1000,
            floor_count:
              spaceDetails.floor_count > 0 ? spaceDetails.floor_count : 1,
          });
        }
        setHeaderTitleText("l_space_address_title");
        setHeaderDescriptionText("l_space_address_subtitle");
        setBackButtonText("l_back");
        setNextButtonText("l_next");
        setSkipButtonText("");
        break;
      case "external-basic-details":
        setHeaderTitleText("l_external_basic_details_title");
        setHeaderDescriptionText("l_external_basic_details_subtitle");
        setBackButtonText("l_back");
        if (token) {
          setNextButtonText("l_next");
          setSkipButtonText("");
        } else {
          setNextButtonText("l_create_profile_and_get_proposal");
          setSkipButtonText(
            benchmarkDetail && benchmarkDetail.accessories && benchmarkDetail.accessories.length ? "l_view_accessories" : "l_add_accessories"
          );
        }
        break;
      case "client-info":
        setHeaderTitleText("l_client_info_title");
        setHeaderDescriptionText("l_client_info_subtitle");
        setBackButtonText("l_back");
        setSkipButtonText("");
        setNextButtonText("l_create_profile_and_get_proposal");
        break;
      case "chat-screen":
        setHeaderTitleText("l_chat_screen_title");
        setHeaderDescriptionText("l_chat_screen_subtitle");
        if (userType === "operator") {
          setRecipientName(benchmarkDetail.creator_details.full_name);
          setRecipientImage(benchmarkDetail.creator_details.profile_image_url);
          setBackButtonText("l_view_summary");
          setSkipButtonText("");
          setNextButtonText(
            type === "schedule" ||
              (type === "proposal" &&
                benchmarkDetail.proposal_status == 3 &&
                benchmarkDetail.generated_schedule_id > 0)
              ? "l_go_to_assignments"
              : ""
          );
        } else if (["contact", "staff"].includes(userType)) {
          if (isAcceptedAnyProposal()) {
            const proposal = benchmarkDetail.proposal_list[0].proposals.find(
              (w) => w.status == 3
            );
            setRecipientName(proposal.user_name);
            setRecipientImage(proposal.user_image);
          } else if (type === "schedule") {
            //As of now, schedule will only have one operator, so can be accessed from index 0
            setRecipientName(benchmarkDetail.operator_details[0].full_name);
            setRecipientImage(benchmarkDetail.operator_details[0].profile_url);
          }
          setBackButtonText(
            ["benchmark", "external-benchmark"].includes(type)
              ? "l_view_proposals"
              : "l_view_checklists"
          );
          setSkipButtonText("");
          let nextButtonContent =
            (["benchmark", "external-benchmark"].includes(type) &&
              allowToAccessCalendarPage) ||
            (type === "schedule" && benchmarkDetail.is_assigneed != "0")
              ? "l_go_to_calendar"
              : "";
          setNextButtonText(nextButtonContent);
        }
        break;
      case "summary-screen":
        setHeaderTitleText("l_summary_screen_title");
        setHeaderDescriptionText("l_summary_screen_subtitle");
        setBackButtonText("l_go_to_assignment");
        setSkipButtonText("");
        setNextButtonText("l_assign_tasks");
        break;
      case "task-summary-screen":
        setHeaderTitleText("l_calendar_screen_for_generated_tasks_title");
        setHeaderDescriptionText(
          "l_calendar_screen_for_generated_tasks_subtitle"
        );
        setSkipButtonText("");
        if (userType === "operator") {
          setBackButtonText("l_view_assignment_summary");
          setNextButtonText("");
        } else if (["contact", "staff"].includes(userType)) {
          setBackButtonText("l_view_checklists");
          setNextButtonText("");
        }
        break;
      case "proposal-amount":
        setHeaderTitleText("l_proposal");
        setHeaderDescriptionText("");
        setSkipButtonText("");
        setBackButtonText("");
        setNextButtonText(
          benchmarkDetail.proposal_status == 3 ? "l_go_to_chat_screen" : ""
        );
        break;
      case "assignment-screen":
        setHeaderTitleText("l_assignment_screen_title");
        setHeaderDescriptionText("l_assignment_screen_subtitle");
        setSkipButtonText("");
        setBackButtonText("l_floor_options");
        setNextButtonText("l_view_assignment_summary");
        break;
      case "sending-proposal-loader":
      case "congratulation-screen":
        setSkipButtonText("");
        setBackButtonText("");
        setNextButtonText("");
        break;
      default:
        break;
    }
  };

  const isAcceptedAnyProposal = () => {
    let flag = true;
    if (!existsProposals()) {
      flag = false;
    } else if (
      !benchmarkDetail.proposal_list[0].proposals.find((w) => w.status == 3)
    ) {
      flag = false;
    }
    return flag;
  };

  const isTaskGenerationCompleted = async (scheduleId) => {
    try {
      const response = await CustomerServices.getBenchmarkDetail(
        scheduleId,
        clientId
      );
      if (response.status && response.data.is_assigneed > 0) {
        setAllowToAccessCalendarPage(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const subscribeForProvider = async () => {
    try {
      setAssignmentLoader(true);
      const email = localStorage.getItem("email");

      const subscriptionDetail = await DocumentsServices.getSubscriptionDetail(
        0,
        0,
        benchmarkDetail.operator_hash
      );
      const paymentMethod = subscriptionDetail.data.payment_mode;

      const response = await DocumentsServices.subscribe(
        benchmarkDetail.operator_hash,
        "",
        email,
        1,
        benchmarkDetail.project_id,
        1,
        client_id,
        0,
        0,
        paymentMethod
      );

      if (response.status) {
        if (response.data.site) {
          window.location.href = response.data.site;
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setAssignmentLoader(false);
      dispatch(getBenchmarkDetail(benchmarkDetail.id));
    }
  };

  const updateDefaultAccessories = () => {
    CustomerServices.getDefaultAccessories().then((res) => {
      if (res.status && res.data && res.data.length) {
        let defaultAccessoriesList = res.data.map((item) => {
          return {
            id: item.id,
            description: item.description,
            quantity: 0,
            checked: false,
            is_default: item.is_default > 0
          };
        });
        defaultAccessoriesList.sort((a, b) =>
          a.description.localeCompare(b.description)
        );
        setDefaultAccessories(defaultAccessoriesList);
      }
    });
  };

  useEffect(() => {
    scheduleCopyCreated = false;
    if (
      (location.pathname.includes("connect-provider") && !type) ||
      (!["proposal", "external-benchmark"].includes(type) &&
        (!spaceId || isNaN(spaceId) || !spaceData.length || !spaceDetails) &&
        !isExternal)
    ) {
      navigate(
        location.state && location.state.from ? location.state.from : "/"
      );
    }
    if (isExternal) {
      searchParams.delete("is_external");
      setSearchParams(searchParams);
    }
    if (spaceId > 0 && spaceId != selectedOffice) {
      selectedOffice = spaceId;
      localStorage.setItem("selectedOffice", spaceId);
      dispatch(setProject(spaceId));
    }
    if (spaceId > 0) {
      dispatch(getFloorDetails(spaceId));
    } else if (selectedOffice > 0) {
      dispatch(getFloorDetails(selectedOffice));
    }
    if (!isNaN(benchmarkId) && Number(benchmarkId) > 0) {
      dispatch(getBenchmarkDetail(benchmarkId, clientId));
      dispatch(getBenchmarkWeekSchedule(benchmarkId, ""));
    } else {
      dispatch(getBenchmarkDetail(0, 0, "all", ""));
      dispatch(getBenchmarkWeekSchedule(0, "all"));
    }

    if (
      location.pathname.includes("connect-provider") ||
      type === "invite_provider"
    ) {
      //Check for all required params
      if (
        (userType == "operator" &&
          !["proposal", "schedule", "add_provider"].includes(type)) ||
        (userType != "operator" &&
          ![
            "benchmark",
            "schedule",
            "invite_provider",
            "add_provider",
          ].includes(type))
      ) {
        navigate(
          location.state && location.state.from ? location.state.from : "/"
        );
      }
      NO_BACK_BUTTON_STEPS.push("provider-category");
      setStep("provider-category");
      const categoryId = searchParams.get("categoryId");
      let categoryName = searchParams.get("categoryName");
      const email = searchParams.get("email");
      if (
        !isNaN(categoryId) &&
        Number(categoryId) > 0 &&
        categoryName.trim().length
      ) {
        categoryName = decodeURIComponent(categoryName);
        setProviderData({
          ...providerData,
          categoryId,
          categoryName,
          type,
        });
        searchParams.delete("categoryId");
        searchParams.delete("categoryName");
        setSearchParams(searchParams);
        if (["benchmark", "schedule"].includes(type)) {
          setStep("provider-source");
        } else if (
          (email && email.trim().length) ||
          categoryId ||
          categoryName
        ) {
          setStep("select-provider");
        }
      } else {
        setProviderData({
          ...providerData,
          type,
        });
      }
    }

    if (type === "external-benchmark") {
      if (spaceDetails) {
        setSpaceDetail({
          ...spaceDetail,
          address: spaceDetails.postal_address,
          number_of_desk:
            spaceDetails.number_of_desk > 0 ? spaceDetails.number_of_desk : 10,
          superfice:
            spaceDetails.superficies > 0 ? spaceDetails.superficies : 1000,
          floor_count:
            spaceDetails.floor_count > 0 ? spaceDetails.floor_count : 1,
        });
      }
      CustomerServices.getRegionList()
        .then((res) => {
          if (res.status == 1 && res.data) {
            setRegionList(
              res.data.map((data) => {
                return {
                  label: data.name,
                  value: data.id,
                };
              })
            );
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }

    if (spaceId == 0) {
      dispatch(selectJobProposalPage(true));
    }
    updateDefaultAccessories();

    return () => {
      dispatch(getBenchmarkDetail(0, 0, "all", ""));
      dispatch(getBenchmarkWeekSchedule(0, "all"));
    };
  }, []);

  useEffect(() => {

    const getProviderListByCategory = async() => {
      try
      {
        const idbCategoryProviderList = await readFromDB("providers_list_by_categories", `project_${selectedOffice}_category_${providerData.categoryId}`, "category_key");
        if(idbCategoryProviderList && Object.keys(idbCategoryProviderList).length)
        {
          setProviderData({
            ...providerData,
            total_myr_providers: idbCategoryProviderList.myr_providers.length,
            total_myr_providers_data: idbCategoryProviderList.myr_providers,
            invited_providers:
              !providerData.id && providerData.invite_all_myr_providers
                ? idbCategoryProviderList.myr_providers.sort((a, b) => b.score - a.score).slice(0, 5)
                : providerData.invited_providers,
          });
          setProviderList(idbCategoryProviderList.providers_list);
          setLoadingNextStep(false);
          setAssignmentLoader(false);
        }

        const response = await BenchmarkServices.getOperatorsByCategory(
          selectedOffice,
          providerData.categoryId,
          1
        );
        
        if (response.status == 1) {
          let allProviderList = response.data;
          allProviderList.sort((a, b) =>
            a.fullname.localeCompare(b.fullname)
          );
          let myrProviders = response.extra.total_myr_providers_data.filter(
            (w) =>
              !allProviderList.find(
                (z) => z.staffid == w.staffid && z.email == w.email
              )
          ).map((w) => {return {...w, from_myr_network: 1}});
          myrProviders.sort((a, b) => a.fullname.localeCompare(b.fullname));
          setProviderData({
            ...providerData,
            total_myr_providers: myrProviders.length,
            total_myr_providers_data: myrProviders,
            invited_providers:
              !providerData.id && providerData.invite_all_myr_providers
                ? myrProviders.sort((a, b) => b.score - a.score).slice(0, 5)
                : providerData.invited_providers,
          });
          let visibleProviderList = [];
          providerData.sources.forEach((source) => {
            switch(source)
            {
              case "invite_provider":
                visibleProviderList = visibleProviderList.concat(response.data.filter((w) => w.is_existing == 0));
                break;
              case "choose_from_past":
                visibleProviderList = visibleProviderList.concat(response.data.filter((w) => w.is_existing == 1));
                break;
              case "myr_network":
                visibleProviderList = visibleProviderList.concat(myrProviders);
                break;
            }
          });
          setProviderList(visibleProviderList);
          writeToDB("providers_list_by_categories", `project_${selectedOffice}_category_${providerData.categoryId}`, {myr_providers: myrProviders, providers_list: visibleProviderList, category_key: `project_${selectedOffice}_category_${providerData.categoryId}`}, "category_key");
        } else {
          showError("l_something_went_wrong");
        }
      }
      catch(e)
      {
        showError("l_something_went_wrong");
        console.error(e);
      }
      finally {
        setLoadingNextStep(false);
        setAssignmentLoader(false);
      }
    }

    if (step === "select-provider") {
      if (
        !providerData.isCustomCategory &&
        // !providerList.length &&
        userType !== "operator"
      ) {
        setLoadingNextStep(true);
        setAssignmentLoader(true);
        getProviderListByCategory();
      } else if (providerList && !providerData.id) {
        let updatedProviderList = providerData.invited_providers.slice();
        if (!providerData.invite_all_myr_providers) {
          updatedProviderList = providerData.invited_providers.filter(
            (w) =>
              !providerData.total_myr_providers_data.find(
                (z) => z.email.trim() == w.email.trim()
              )
          );
        }
        else if(benchmarkDetail && benchmarkDetail.is_copy_from_schedule > 0)
        {
          //To be implemented...
        }
        setProviderData({
          ...providerData,
          invited_providers: updatedProviderList,
        });
      }
    } else if (step === "congratulation-screen") {
      dispatch(getBenchmarkDetail(providerData.id, clientId));
      setTimeout(() => {
        setStep("proposal-listing");
        stepsArray = stepsArray.filter(
          (x) =>
            x !== "congratulation-screen" && x !== "sending-proposal-loader"
        );
      }, 1000);
    } else if (step === "proposal-listing") {
      dispatch(getMentionList(providerData.id, "schedule"));
    } else if (
      step === "floor-listing" &&
      providerData.master_id > 0 &&
      !scheduleCopyCreated
    ) {
      saveBenchmark();
      scheduleCopyCreated = true;
    }
    setStepWiseFooterButtonText(step);

    switch(step)
    {
      case "view-job":
        setNextButtonText("l_update");
        setBackButtonText("l_back");
        break;
    }

  }, [step]);

  useEffect(() => {
    if (type) {
      switch (type) {
        case "benchmark":
          stepsArray =
            benchmarkId > 0
              ? [
                  "provider-category",
                  "select-provider",
                  "basic-details",
                  "floor-listing",
                  "checklist-creation",
                  "accessories",
                  "benchmark-units",
                  "proposal-listing",
                  "chat-screen",
                ]
              : [
                  "provider-type",
                  "provider-category",
                  "provider-source",
                  "select-provider",
                  "file-uploads",
                  "basic-details",
                  "floor-listing",
                  "checklist-creation",
                  "accessories",
                  "benchmark-units",
                  "sending-proposal-loader",
                  "congratulation-screen",
                  "proposal-listing",
                  "chat-screen",
                ];
          break;
        case "proposal":
          stepsArray = [
            "provider-category",
            "basic-details",
            "floor-listing",
            "checklist-creation",
            "accessories",
            "proposal-amount",
            "chat-screen",
          ];
          let spaceClass = document.querySelector(
            `[data-element="${selectedOffice}"]`
          );
          if (spaceClass) {
            spaceClass.classList.remove("active");
          }
          break;
        case "schedule":
          if (userType === "operator") {
            stepsArray =
              benchmarkId > 0
                ? [
                    "provider-category",
                    "basic-details",
                    "floor-listing",
                    "checklist-creation",
                    "assignment-screen",
                    "summary-screen",
                    "task-summary-screen",
                    "chat-screen",
                  ]
                : [
                    "provider-category",
                    "select-provider",
                    "basic-details",
                    "floor-listing",
                    "checklist-creation",
                    "assignment-screen",
                    "summary-screen",
                    "task-summary-screen",
                    "chat-screen",
                  ];
            if (
              spaceDetails &&
              ((spaceDetails.is_added_from_operator != 0 &&
                spaceDetails.client_id != client_id) ||
                spaceDetails.is_added_from_operator == 0)
            ) {
              stepsArray = stepsArray.filter(
                (x) => !["provider-source", "select-provider"].includes(x)
              );
            } else {
              BenchmarkServices.getInvitedCustomerList(selectedOffice)
                .then((res) => {
                  if (res.status && res.data) {
                    if (res.data.length) {
                      setProviderData({
                        ...providerData,
                        invited_customers: res.data.map((w) => {
                          return { ...w, invitee_id: w.id };
                        }),
                      });
                    }
                  }
                })
                .catch((e) => {
                  console.error(e);
                });
            }
          } else {
            stepsArray =
              benchmarkId > 0
                ? [
                    "provider-category",
                    "select-provider",
                    "basic-details",
                    "floor-listing",
                    "checklist-creation",
                    "task-summary-screen",
                    "chat-screen",
                  ]
                : [
                    "provider-type",
                    "provider-category",
                    "provider-source",
                    "select-provider",
                    "file-uploads",
                    "basic-details",
                    "floor-listing",
                    "checklist-creation",
                    "chat-screen",
                  ];
          }
          break;
        case "invite_provider":
          if (userType === "operator") {
            BenchmarkServices.getInvitedCustomerList(selectedOffice)
              .then((res) => {
                if (res.status && res.data) {
                  if (res.data.length) {
                    setProviderData({
                      ...providerData,
                      invited_customers: res.data.map((w) => {
                        return { ...w, invitee_id: w.id };
                      }),
                    });
                  }
                  stepsArray = ["select-provider"];
                  setStep("select-provider");
                }
              })
              .catch((e) => {
                console.error(e);
              });
          } else {
            stepsArray = ["provider-category", "select-provider"];
          }
          break;
        case "external-benchmark":
          stepsArray = token
            ? [
                "provider-category",
                "space-address",
                "external-basic-details",
                "accessories",
                "proposal-listing",
                "chat-screen",
              ]
            : [
                "provider-category",
                "space-address",
                "external-basic-details",
                "accessories",
                "client-info",
                "proposal-listing",
                "chat-screen",
              ];
          setStep("provider-category");
          NO_BACK_BUTTON_STEPS.push("provider-category");
          break;
        case "add_provider":
          stepsArray = ["provider-category"];
          setStep("provider-category");
          NO_BACK_BUTTON_STEPS.push("provider-category");
          break;
        default:
          break;
      }

      const titleElement = document.getElementById("header-page-title");
      const addressElement = document.getElementById("header-page-address");
      if (titleElement && addressElement) {
        titleElement.textContent = type.replace(/^./, (str) =>
          str.toUpperCase()
        ); //To capitalize the first letter
        addressElement.textContent = spaceDetails
          ? spaceDetails.postal_address.replace(/^./, (str) =>
              str.toUpperCase()
            )
          : "";
      }
    } else {
      setStep("provider-type");
    }
    if (location.pathname.includes("connect-provider")) {
      stepsArray = stepsArray.filter((x) => x !== "provider-type");
    }
  }, [type]);

  useEffect(() => {
    let element = document.querySelector("body");
    if (showCommentSection) {
      if (element) {
        element.classList.add("with-right-penel");
      }
    }
    const handleOutsideClick = (event) => {
      const container = jquery(".custom-modal-style");
      if (
        !container.is(event.target) &&
        container.has(event.target).length === 0
      ) {
        setShowCommentSection(false);
        element.classList.remove("with-right-penel");
      }
    };
    window.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [showCommentSection]);

  useEffect(() => {
    checkforReadOnlyMode();
    if (benchmarkDetail) {
      if (benchmarkDetail.total_comments && !commentCount) {
        setCommentCount(benchmarkDetail.total_comments);
      }
      if (benchmarkDetail.id > 0) {
        let copy = searchParams.get("copy");
        let recordId = benchmarkDetail.id;

        if (
          copy &&
          benchmarkDetail.is_allowed_to_copy == 1 &&
          benchmarkDetail.is_allowed_to_edit == 1
        ) {
          searchParams.delete("copy");
          searchParams.set("id", 0);
          setSearchParams(searchParams);
          dispatch(
            getBenchmarkDetail(
              benchmarkDetail.id,
              0,
              "edit",
              "master_id",
              benchmarkDetail.id
            )
          );
          dispatch(
            getBenchmarkDetail(
              benchmarkDetail.id,
              0,
              "edit",
              "is_allowed_to_copy",
              0
            )
          );
          stepsArray = stepsArray.filter((w) => w !== "provider-source");
          recordId = 0;
        }
        if (!templatesList.length && localStorage.getItem("is_registered") != 1) {
          getChecklistTemplates();
        }
        if (!providerData.id || providerData.id != benchmarkDetail.id) {
          let documentType = "";
          if (benchmarkDetail.proposal_id > 0) {
            documentType = "proposal";
          }
          if (
            benchmarkDetail.open_schedule_modal == 1 &&
            benchmarkDetail.master_id > 0
          ) {
            documentType = "schedule";
          } else if (
            benchmarkDetail.open_schedule_modal == 0 &&
            benchmarkDetail.master_id > 0
          ) {
            documentType = "benchmark";
          } else {
            documentType =
              benchmarkDetail.is_schedule > 0 ? "schedule" : "benchmark";
          }
          setProviderData({
            ...providerData,
            budget: Number(benchmarkDetail.max_budget),
            contracts: benchmarkDetail.attachments,
            contractsPreview: [],
            description: benchmarkDetail.basic_information,
            documents: benchmarkDetail.attachments,
            documentsPreviews: [],
            endDate: benchmarkDetail.contract_end_date,
            startDate: benchmarkDetail.startdate,
            unKnownBudget: benchmarkDetail.budget_is_empty > 0,
            unKnownStartDate: benchmarkDetail.start_date_is_empty > 0,
            id: recordId,
            categoryId: benchmarkDetail.provider_id,
            categoryName: benchmarkDetail.category,
            type: documentType,
            invited_providers: benchmarkDetail.operator_details,
            sources: benchmarkDetail && benchmarkDetail.is_copy_from_schedule > 0 ? [] : ["choose_from_past"],
            master_id: benchmarkDetail.master_id,
            needToHire: benchmarkDetail.need_to_hire_people > 0,
            furnished: benchmarkDetail.is_furnished > 0,
          });
          if (!Number(benchmarkDetail.recursive)) {
            dispatch(
              getTaskChecklist(
                benchmarkDetail.request_id,
                benchmarkDetail.project_id,
                false
              )
            );
            stepsArray = stepsArray.filter(
              (x) =>
                ![
                  "checklist-creation",
                  "floor-listing",
                  "accessories",
                ].includes(x)
            );
            if (["contact", "staff"].includes(userType)) {
              let stepIndex =
                stepsArray.findIndex((w) => w == "basic-details") + 1;
              stepsArray.splice(stepIndex, 0, "request-checklists");
            }
          }
          let proposal = existsProposals()
            ? benchmarkDetail.proposal_list[0].proposals.find(
                (w) => w.status == 3
              )
            : "";
          if (proposal) {
            NO_NEXT_BUTTON_STEPS = NO_NEXT_BUTTON_STEPS.filter(
              (w) => w !== "proposal-listing"
            ).concat("chat-screen");
            getScheduleComments();
          }
          if (benchmarkDetail.proposal_id > 0) {
            if (benchmarkDetail.proposal_status == 3) {
              NO_NEXT_BUTTON_STEPS = NO_NEXT_BUTTON_STEPS.filter(
                (w) => w !== "proposal-amount"
              );
              stepsArray.push("chat-screen");
            }
            setStep("proposal-amount");
          } else {
            if (!benchmarkDetail.startdate || benchmarkDetail.status == 1) {
              setStep("floor-listing");
              stepsArray = [
                "provider-category",
                "file-uploads",
                "basic-details",
                "floor-listing",
                "checklist-creation",
              ];
            } else if (benchmarkDetail.added_checklist == 0) {
              setStep("floor-listing");
              if (!readOnlyMode) {
                setButtonsForGenerate();
              }
            } else {
              setStep("assignment-screen");
            }
          }
          if (userType === "operator") {
            if(benchmarkDetail.requirement_id > 0)
            {
              setStep("proposal-listing");
            } else if (benchmarkDetail.proposal_id > 0) {
              if (benchmarkDetail.proposal_status == 3) {
                NO_NEXT_BUTTON_STEPS = NO_NEXT_BUTTON_STEPS.filter(
                  (w) => w !== "proposal-amount"
                );
                stepsArray.push("chat-screen");
              }
              setStep("proposal-amount");
            } else {
              if (benchmarkDetail.master_id > 0) {
                setStep("floor-listing");
              } else if (
                !benchmarkDetail.startdate ||
                benchmarkDetail.status == 1
              ) {
                setStep("floor-listing");
                stepsArray = [
                  "provider-category",
                  "basic-details",
                  "floor-listing",
                  "checklist-creation",
                ];
              } else if (benchmarkDetail.added_checklist == 0) {
                setStep("floor-listing");
                if (!readOnlyMode) {
                  setButtonsForGenerate();
                }
              } else {
                setStep("assignment-screen");
              }
            }
          } else if (["contact", "staff"].includes(userType)) {
            let proposal = existsProposals()
              ? benchmarkDetail.proposal_list[0].proposals.find(
                  (w) => w.status == 3
                )
              : "";
            if (proposal) {
              NO_NEXT_BUTTON_STEPS = NO_NEXT_BUTTON_STEPS.filter(
                (w) => w !== "proposal-listing"
              ).concat("chat-screen");
              getScheduleComments();
            }
            if (type === "external-benchmark") {
              if (token) {
                setStep("proposal-listing");
              } else {
                setStep(
                  existsProposals()
                    ? "proposal-listing"
                    : "external-basic-details"
                );
              }
            } else if (benchmarkDetail.master_id > 0) {
              setStep("floor-listing");
            } else if (
              benchmarkDetail.is_assigneed != 0 &&
              benchmarkDetail.status != 1
            ) {
              setStep("task-summary-screen");
            } else if (type !== "schedule" && existsProposals()) {
              setStep("proposal-listing");
            } else if (
              !existsProposals() &&
              ["benchmark", "proposal"].includes(type) &&
              benchmarkDetail.recursive == 0 && benchmarkDetail.is_copy_from_schedule <= 0
            ) {
              setStep("request-checklists");
            } else {
              if(benchmarkDetail && benchmarkDetail.is_copy_from_schedule > 0)
              {
                setStep("provider-source")
              }
              else {
                // setStep("basic-details");
              }
            }
          }
          if (
            !readOnlyMode &&
            benchmarkDetail.status == 1 &&
            type === "schedule"
          ) {
            setButtonsForGenerate();
          }
        }
        const titleElement = document.getElementById("header-page-title");
        const addressElement = document.getElementById("header-page-address");
        if (titleElement && addressElement) {
          titleElement.textContent = type.replace(/^./, (str) =>
            str.toUpperCase()
          ); //To capitalize the first letter
          addressElement.textContent = benchmarkDetail
            ? benchmarkDetail.address.replace(/^./, (str) => str.toUpperCase())
            : "";
        }
        getBenchmarkStatusText(benchmarkDetail);
      }
      if(benchmarkDetail.unit_task && benchmarkDetail.unit_task.length)
      {
        setUnitsList(benchmarkDetail.unit_task.map((unit) => {
          unit.description = unit.name;
          return unit;
        }));
      }
      if(benchmarkDetail.requirement_details && benchmarkDetail.requirement_details.length)
      {
        setJobDetails({
          ...benchmarkDetail.requirement_details[0],
          mission_address: benchmarkDetail.requirement_details[0].address,
          service_start_date: moment(benchmarkDetail.requirement_details[0].start_date).format(
            "YYYY-MM-DD"
          ),
          service_end_date: moment(benchmarkDetail.requirement_details[0].end_date).format(
            "YYYY-MM-DD"
          ),
          unknown_end_date: Number(benchmarkDetail.requirement_details[0].is_empty_end_date),
          slots: benchmarkDetail.requirement_details[0].details.map((slotInfo) => {
            return {
              ...slotInfo,
              start_time: new Date(`${moment().format("YYYY-MM-DD")} ${slotInfo.start_time}`),
              end_time: new Date(`${moment().format("YYYY-MM-DD")} ${slotInfo.end_time}`),
              rate: !isNaN(Number(slotInfo.hourly_rate)) && Number(slotInfo.hourly_rate) ? Number(slotInfo.hourly_rate) : 0,
              slots:
              {
                mon: slotInfo.mon > 0,
                tue: slotInfo.tue > 0,
                wed: slotInfo.wed > 0,
                thu: slotInfo.thu > 0,
                fri: slotInfo.fri > 0,
                sat: slotInfo.sat > 0,
                sun: slotInfo.sun > 0
              }
            }
          }),
        });
      }
    }
    //infinite call this useeffect so remove JSON.Stringify
  }, [(benchmarkDetail)]);

  useEffect(() => {
      setAssignmentLoader(isLoadingBenchmarkInfo);
  }, [isLoadingBenchmarkInfo]);
  

  return (
    <div
      className={`onboarding-wrapper-main d-flex onboarding-with-bg ${
        [
          "select-provider",
          "assignment-screen",
          "summary-screen",
          "proposal-listing",
          "proposal-amount",
        ].includes(step) || type === "external-benchmark"
          ? "with-modified-width"
          : ""
      } ${showCommentSection ? "m-auto" : "m-auto"} h-100 flex-column onboarding-with-bg ${
        step === "checklist-creation" ? "w-100" : ""
      }`}
    >
      {/*mx35px*/}
      { showAcknowledgeScreen ? (
        <OnboardingFlowFinalStep
          congratulationMessage={"l_schedule_created"}
          destination={`/explore-features?setting-menu=providers&section=${providerData.type}&category=${providerData.categoryId}&space=${selectedOffice}`}
          navigation={true}
        />
      ) : showexternalUserWelcomeMessage
      ?
      <div className="absoluteLoader calendar-modal-color">
        <AcknowledgeScreen
          afterDismissAction={() => {}}
          delay={700000} //High Delay
          steps={ACKNOWLEDGEMENT_STEPS["customer_plan_1"]}
          description={
            "l_feature_allows_experience_entirely_new_way_interacting"
          }
          title={"l_onboarding_acknowledgement_header_title"}
        />
      </div> : (
        <React.Fragment>
          {!NO_HEADER_STEPS.includes(step) ? (
            <OnboardingHeader
              description={headerDescriptionText}
              title={headerTitleText}
              titlePrefix={
                benchmarkDetail && benchmarkDetail.id
                  ? // !["proposal-amount"].includes(step)
                    `- #${benchmarkDetail.id} - ${benchmarkDetail.category}`
                  : ""
              }
              statusClass={statusClass}
              status={statusText}
              backButtonText={backButtonText}
              backBtnHandler={() => handleStepChange(-1)}
              currentStep={
                step === "provider-type"
                  ? 0
                  : stepsArray.findIndex((x) => x == step) + 1
              }
              hideBackButton={!backButtonText.length}
              loadingNextStep={loadingNextStep}
              nextBtnHandler={() => handleStepChange(1)}
              nextButtonText={nextButtonText}
              skipButtonHandler={() => handleStepChange(1, true)}
              skipButtonText={skipButtonText}
              hideSkipButton={false}
              from={"connect-provider"}
              totalSteps={stepsArray.length}
            />
          ) : (
            <React.Fragment></React.Fragment>
          )}
          <div
            className={`onboarding-content-scroll-wrapper overflow-hiiden-auto d-flex flex-column  ${
              step === "checklist-creation" ? "h-100" : ""
            } ${
              !["select-provider", "checklist-creation"].includes(step)
                ? ""
                : ""
            }`}
          >
            {step === "provider-type" ? (
              <div className="d-flex justify-content-center flex-wrap">
                <ProviderType
                  spaceDetails={spaceDetails}
                  providerData={providerData}
                  setProviderData={setProviderData}
                  setStep={setStep}
                />
              </div>
            ) : step === "provider-category" ? (
              <ProviderCategoryList
                providerData={providerData}
                setProviderData={setProviderData}
                setStep={setStep}
                setUploadBulkCategory={setUploadBulkCategory}
              />
            ) : step === "provider-source" ? (
              <div className="d-flex justify-content-center flex-wrap">
                <ProviderSources
                  providerData={providerData}
                  setProviderData={setProviderData}
                />
              </div>
            ) : step === "select-provider" ? (
              <SelectProvider
                providerData={providerData}
                setProviderData={setProviderData}
                providerList={providerList}
                invitedProviders={invitedProviders}
                setInvitedProviders={setInvitedProviders}
                setStep={setStep}
              />
            ) : step === "file-uploads" ? (
              <>
              <UploadDocumentBenchmark 
              imagePreviews = {imagePreviews}
              setImagePreviews={setImagePreviews}
              />
              </>
            ) : step === "basic-details" ? (
              <BasicDetails
                providerData={providerData}
                setProviderData={setProviderData}
                readOnlyMode={readOnlyMode}
                customFields={customFields}
                setCustomFields={setCustomFields}
              />
            ) : step === "floor-listing" ? (
              <FloorPlanSummary
                kpiData={[
                  [
                    { field: "l_superficy", value: getFloorSuperficy },
                    {
                      field: "l_checklist_items",
                      value: getFloorwiseChecklistCount,
                    },
                  ],
                ]}
                hideFloorDetails={true}
                getTypeOfSpaceCounts={getChecklistCountBySpaceItem}
                onFloorSelect={(floor) => setFloorwiseChecklistData(floor)}
              />
            ) : step === "request-checklists" && benchmarkDetail.id > 0 ? (
              <ChecklistComponent
                noModalSpace={true}
                task_id={benchmarkDetail.request_id}
                projectId={benchmarkDetail.project_id}
                step={`request-checklists`}
                setChecklistFilter={()=>{}}
              />
            ) : step === "checklist-creation" ? (
              <ChecklistCreation
                readOnlyMode={readOnlyMode}
                providerData={providerData}
                checklists={checklists}
                setChecklists={setChecklists}
                onClose={(floorChecklists) =>
                  handleChecklistModalClose(floorChecklists)
                }
                onSave={(floorChecklists) =>
                  handleChecklistModalClose(floorChecklists, true)
                }
                floor={selectedFloor}
                setFloor={setSelectedFloor}
                templates={templatesList}
                setTemplates={setTemplatesList}
              />
            ) : step === "accessories" ? (
              <Accessories
                providerData={providerData}
                benchmarkAccesories={accessories}
                defaultAccessories={defaultAccessories}
                updateDefaultAccessories={updateDefaultAccessories}
                setDefaultAccessories={setDefaultAccessories}
                setBenchmarkAccesories={setAccessories}
                readOnlyMode={readOnlyMode}
                setButtonsForGenerate={() => {
                  isDataUpdated = true;
                  setButtonsForGenerate();
                }}
              />
            ) : step === "benchmark-units" ? (
              <BenchmarkPerUnit setUnitsList={setUnitsList} unitsList={unitsList}/>
            ) 
            : step === "sending-proposal-loader" ? (
              <ProposalSendingLoader setStep={setStep} />
            ) : step === "congratulation-screen" ? (
              <OnboardingFlowFinalStep
                congratulationMessage={`l_${type}_created`}
                navigation={""}
              />
            ) : step === "proposal-listing" ? (
              <ProposalList
                mentionList={mentionList}
                setStep={setStep}
                getComments={() => getScheduleComments()}
              />
            ) : step === "space-address" ? (
              <Address
                coOrdinates={coOrdinates}
                regionList={regionList}
                setCoOrdinates={setCoOrdinates}
                setRegionList={setRegionList}
                setSpaceDetail={setSpaceDetail}
                spaceDetail={spaceDetail}
              />
            ) : step === "external-basic-details" ? (
              <ExternalBasicDetails
                setSpaceDetail={setSpaceDetail}
                spaceDetail={spaceDetail}
                providerData={providerData}
                setProviderData={setProviderData}
                addEditFloor={addEditFloor}
                onFloorSelect={setExternalFloorChecklist}
              />
            ) : step === "client-info" ? (
              <RegisterClient
                contactDetail={contactDetail}
                setContactDetail={setContactDetail}
              />
            ) : step === "chat-screen" ? (
              <FullScreenCommentView
                commentsList={commentsList}
                deleteComment={deleteComment}
                handleAddComment={handleAddComment}
                loadingComments={loadingComments}
                mentionList={mentionList}
                setShowCommentSection={() => {}}
                showCommentSection={true}
                type={"benchmark"}
                recipientImage={recipientImage}
                recipientName={recipientName}
              />
            ) : step === "view-job" ?
            (
              <OperatorForm
                contract={{}}
                contractAttachments={jobDetails.attachments.map((contract) => {return {name: contract.file_name, type: "", url: contract.file_url}})}
                formData={jobDetails}
                setContract={() => {}}
                setContractAttachments={() => {}}
                setFormData={setJobDetails}
              />
            )
            : (
              <></>
            )}
            <React.Fragment>
              {type !== "external-benchmark" ? (
                <React.Fragment>
                  <div
                    className={`${
                      benchmarkDetail &&
                      benchmarkDetail.id &&
                      step === "assignment-screen"
                        ? "h-100"
                        : "d-none"
                    }`}
                  >
                    {benchmarkDetail && benchmarkDetail.id ? (
                      <CreateScheduleNew
                        hideFooter={true}
                        handleClose={() => {}}
                        handleStep={() => {}}
                        readOnlyMode={false}
                        selectedTab={""}
                        step={step}
                        setTaskData={setTaskData}
                        iDistributeChecklist={true}
                      />
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                  <div
                    className={`${
                      benchmarkDetail &&
                      benchmarkDetail.id &&
                      step === "summary-screen"
                        ? "h-100"
                        : "d-none"
                    }`}
                  >
                    {benchmarkDetail && benchmarkDetail.id ? (
                      <ScheduleSummary
                        hideFooter={true}
                        handleClose={() => {}}
                        handleStep={() => {}}
                        selectedTab={""}
                        step={step}
                      />
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                  {benchmarkDetail &&
                  benchmarkDetail.id &&
                  step === "task-summary-screen" ? (
                    <div
                      className={`${
                        benchmarkDetail &&
                        benchmarkDetail.id &&
                        step === "task-summary-screen"
                          ? "h-100"
                          : "d-none"
                      }`}
                    >
                      {benchmarkDetail && benchmarkDetail.id ? (
                        <Fullcalendar />
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </div>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                  <div
                    className={`${
                      step === "proposal-amount" ? "h-100" : "d-none"
                    }`}
                  >
                    {benchmarkDetail && benchmarkDetail.id && benchmarkDetail.requirement_id <= 0 ? (
                      <AddProposalAmount
                        onFloorSelect={(floor) => {
                          setFloorwiseChecklistData(floor);
                          setPreviewChecklistSummary(true);
                        }}
                      />
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                  {benchmarkDetail &&
                  type !== "schedule" &&
                  benchmarkDetail.id > 0 &&
                  ![
                    "checklist-creation",
                    "chat-screen",
                    "proposal-amount",
                    "proposal-listing",
                    "task-summary-screen",
                  ].includes(step) ? (
                    <div
                      className={`comman_action_icon bottom-fix-button dropdown z-index-4`}
                    >
                      {step === "assignment-screen" ? (
                        <a href="#/"
                          className="action_icon with_bg position-relative w55 h46 radius_3"
                          onClick={() => {
                            setStep("floor-listing");
                          }}
                          data-tip={_l("l_schedule_details")}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                          id="ActiveOfficeOptionsDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-auto-close="outside"
                        >
                          <div className="d-flex align-items-center justify-content-center w-100">
                            <Info
                              size={18}
                              className="c-icons m-0"
                              weight="light"
                            />
                          </div>
                        </a>
                      ) : (
                        <React.Fragment>
                          <a href="#/"
                            className="action_icon with_bg position-relative w55 h46 radius_3"
                            onClick={() => {
                              getScheduleComments();
                              setStep("chat-screen");
                            }}
                            // data-tip={_l("l_add_comments")}
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main"
                            id="ActiveOfficeOptionsDropdown"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            data-bs-auto-close="outside"
                          >
                            {unreadCount > 0 ? (
                              <span
                                className=" rounded-pill badge-custom d-flex"
                                style={{
                                  position: "absolute",
                                  top: "-10px",
                                  right: "-10px",
                                  height: "20px",
                                  width: "20px",
                                  padding: "0px",
                                }}
                              >
                                {unreadCount}
                              </span>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}
                            <div className="d-flex align-items-center justify-content-center w-100">
                              <ChatCircle
                                size={18}
                                className="c-icons m-0"
                                weight="light"
                              />
                              <span className="c-font f-12 title-fonts fw-semibold">
                                {commentCount}
                              </span>
                            </div>
                          </a>
                          <CommentSection
                            showCloseIcon={true}
                            isReadOnly={
                              benchmarkDetail.proposal_id > 0 &&
                              benchmarkDetail.proposal_status == 2 &&
                              readOnlyMode
                            }
                            comment={""}
                            commentList={commentsList}
                            data={""}
                            handleAddOrEditComment={handleAddComment}
                            handleClose={() => {
                              setShowCommentSection(false);
                            }}
                            handleDeleteComment={deleteComment}
                            handleSearchComment={() => {}}
                            loading={loadingComments}
                            mentionList={mentionList.map((name) => {
                              let id =
                                name.is_team && name.is_team == 1
                                  ? "-" + name.id
                                  : name.id;
                              return {
                                display: name.name,
                                id: id,
                                image: name.profile_image || "",
                                isteam: name.is_team ? 1 : 0,
                              };
                            })}
                            show={showCommentSection}
                            docType={type}
                          />
                        </React.Fragment>
                      )}
                    </div>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </React.Fragment>
          </div>
          {!NO_FOOTER_STEPS.includes(step) ? (
            <OnboardingFooter
              backButtonText={backButtonText}
              backBtnHandler={() => handleStepChange(-1)}
              // currentStep={
              //   step === "provider-type"
              //     ? 0
              //     : stepsArray.findIndex((x) => x == step) + 1
              // }
              hideBackButton={!backButtonText.length}
              loadingNextStep={loadingNextStep}
              nextBtnHandler={() => handleStepChange(1)}
              nextButtonText={nextButtonText}
              skipButtonHandler={() => handleStepChange(1, true)}
              skipButtonText={skipButtonText}
              totalSteps={stepsArray.length}
               hideSkipButton={["external-basic-details","file-uploads"].find(x => x === step)}
            />
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </React.Fragment>
      )}
      <div>
        <Button
          className="d-none"
          id="wallet_configuration_form"
          onClick={() => {
            setShowWalletModal(true);
          }}
        ></Button>
        {showWalletModal ? (
          <SpaceIBANConfiguration
            handleClose={() => {
              setShowWalletModal(false);
            }}
            show={showWalletModal}
          />
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </div>
      {uploadBulkCategory ? (
        <CategoryAndProviderBulkImport
          handleClose={() => {
            setUploadBulkCategory(false);
          }}
          show={uploadBulkCategory}
          setLoader={setAssignmentLoader}
          redirect={true}
          selectedOffice={selectedOffice}
          setShow={setUploadBulkCategory}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {taskData && taskData.id > 0 ? (
        <CommanOffCanvas
          show={true}
          handleClose={() => {
            setTaskData({});
          }}
          pinHandler={(id, type, flag) => {
            // handlePinUnPin(id, type, flag);
          }}
          data={taskData}
          docType={"task"}
          setSelectedId={() => {}}
          selectedTaskId={taskData.id}
          IsKanbanView={false}
          meetingUrl={""}
          handleParentComponentAction={(type, taskData) => {}}
          pageName={"editScheduleChecklist"}
          isFullScreen={true}
          contacts={[]}
          callUserList={[]}
          isExternalWithNoLeftPanel={true}
        />
      ) :
        <></>
      }
    </div>
  );
};

export default ConnectProvider;
