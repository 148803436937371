import React from 'react';
// import CSS from '../css/icon_main.css';

function RequestTaskIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
			<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW} fill-transparent`}>
			<path d="M12.5 3.125H15.625C15.7908 3.125 15.9497 3.19085 16.0669 3.30806C16.1842 3.42527 16.25 3.58424 16.25 3.75V16.875C16.25 17.0408 16.1842 17.1997 16.0669 17.3169C15.9497 17.4342 15.7908 17.5 15.625 17.5H4.375C4.20924 17.5 4.05027 17.4342 3.93306 17.3169C3.81585 17.1997 3.75 17.0408 3.75 16.875V3.75C3.75 3.58424 3.81585 3.42527 3.93306 3.30806C4.05027 3.19085 4.20924 3.125 4.375 3.125H7.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M10.0195 14.5522C10.2784 14.5522 10.4883 14.3424 10.4883 14.0835C10.4883 13.8246 10.2784 13.6147 10.0195 13.6147C9.76065 13.6147 9.55078 13.8246 9.55078 14.0835C9.55078 14.3424 9.76065 14.5522 10.0195 14.5522Z" />
			<path d="M10.0195 12.5433V12.0522C10.3595 12.0522 10.6918 11.9514 10.9744 11.7626C11.2571 11.5737 11.4774 11.3053 11.6074 10.9912C11.7375 10.6772 11.7716 10.3316 11.7053 9.99819C11.6389 9.66478 11.4752 9.35853 11.2349 9.11816C10.9945 8.87779 10.6882 8.71409 10.3548 8.64777C10.0214 8.58145 9.67585 8.61549 9.36179 8.74558C9.04773 8.87567 8.7793 9.09596 8.59044 9.37861C8.40158 9.66126 8.30078 9.99356 8.30078 10.3335" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M6.875 5.625V5C6.875 4.1712 7.20424 3.37634 7.79029 2.79029C8.37634 2.20424 9.1712 1.875 10 1.875C10.8288 1.875 11.6237 2.20424 12.2097 2.79029C12.7958 3.37634 13.125 4.1712 13.125 5V5.625H6.875Z" strokeLinecap="round" strokeLinejoin="round"/>
			</svg>
		</React.Fragment>
	)
}

export default RequestTaskIcon