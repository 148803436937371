import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { CalendarBlank, GearSix, PencilLine } from "phosphor-react";
import DatePicker from "react-datepicker";
import { useDispatch, } from "react-redux";
import CustomerServices from "../../services/customer-services";
import moment from "moment";
import { showMessage } from "../../actions/messages";
import benchmarkServices from "../../services/benchmark-services";
import Spinner from "react-bootstrap/Spinner";
import { _l } from "../../hooks/utilities";
import AddEmailTemplatesModalNew from "./AddEmailTemplatesModalNew";

  const AddressModal = ({ show , modalKey, handleClose , handleInput  = (address) => {}, data }) => {
    const [billingAddress, setBillingAddress] = useState({
      billing_street: "",
      billing_city: "",
      billing_state : "",
      billing_zip: "",
      billing_country: null,
    });
    const mainModalKey = modalKey == "billing_address" ? "billing"  : "shipping"
    const dispatch = useDispatch();
    const[checked, setChecked] = useState(false)
    const validateInputFields = () => {
      let validationSuccess = true;
  
      const { billing_street, billing_city , billing_state , billing_zip , billing_country } = billingAddress;
      let errMessage; 
      
      if (billing_country == null) {
        errMessage = 'Country is required';
        validationSuccess = false 
      }
      if (!billing_zip.trim()) {
        errMessage = 'Zip Code is required';
        validationSuccess = false 
      }
      if (!billing_state.trim()) {
        errMessage = 'State Name is required';
        validationSuccess = false 
      }
      if (!billing_city.trim()) {
        errMessage = 'City Name is required';
        validationSuccess = false 
      }
      if (!billing_street.trim()) {
        errMessage = 'Street Name is required';
        validationSuccess = false 
      }
      if (!validationSuccess) {
        dispatch(
          showMessage("unsucess", _l("l_error"), `${errMessage}`)
        );
      }
      return validationSuccess;
    };

    const hideModal = () => {
      if (validateInputFields()) {
        handleInput(
          {
            [`${mainModalKey}_street`]: billingAddress.billing_street,
            [`${mainModalKey}_city`]: billingAddress.billing_city,
            [`${mainModalKey}_state`] : billingAddress.billing_state,
            [`${mainModalKey}_zip`]: billingAddress.billing_zip,
            [`${mainModalKey}_country`]: billingAddress.billing_country,
          },
          {
            [`shipping_street`]: billingAddress.billing_street,
            [`shipping_city`]: billingAddress.billing_city,
            [`shipping_state`] : billingAddress.billing_state,
            [`shipping_zip`]: billingAddress.billing_zip,
            [`shipping_country`]: billingAddress.billing_country,
          },
          checked)
      }
    }

    const [countryList, setCountryList] = useState([]);
    useEffect(() => {
      CustomerServices.getCountryCodes(true).then((res) => {
        if (res.status == "1") {
          setCountryList(
            res.data.map((code) => {
              return {
                value: code.id,
                label: code.long_name,
                short_code: code.iso2,
              };
            })
          );
        }
      });
      if (data !== null) {
        const addresData = {
          billing_street: data.billing_street || data.shipping_street,
          billing_city: data.billing_city || data.shipping_city,
          billing_state : data.billing_state || data.shipping_state,
          billing_zip: data.billing_zip || data.shipping_zip,
          billing_country: data.billing_country || data.shipping_country,
        }
        setBillingAddress(addresData)
      }  
  }, []);

    return (
      <Modal
        show={show}
        onHide={hideModal}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        size="md"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>{_l("l_create_estimate")}</Modal.Title> 
        </Modal.Header>
        <Modal.Body className="py-0">
          <div className="form-wrapper-main">
            <div className="row m-0 mb-3 border-bottom">
              <Form.Group className="col-xl-12 c-input-box position-relative mb-3">
              <div className="d-flex flex-wrap flex-column">
              <Form.Label className="input-label no-asterisk  d-flex align-items-center">
                {_l("l_street")}
              </Form.Label>
              <Form.Control
                  as="textarea"
                  placeholder={_l("l_street")}
                  rows={2}
                  value={billingAddress.billing_street}
                  onChange={(e) => {
                    setBillingAddress({ ...billingAddress, billing_street: e.target.value });
                  }}
                />
              </div>
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box position-relative mb-3 ">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_city")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("France")}
                  aria-label="france"
                  aria-describedby="basic-addon1"
                  value={billingAddress.billing_city}
                  onChange={(e) => {
                    setBillingAddress({ ...billingAddress, billing_city: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box position-relative mb-3 ">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_state")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("")}
                  aria-label=""
                  aria-describedby="basic-addon1"
                  value={billingAddress.billing_state}
                  onChange={(e) => {
                    setBillingAddress({ ...billingAddress, billing_state: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box position-relative mb-3 ">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_zip_code")}
                </Form.Label>
                <Form.Control
                  placeholder={("93160")}
                  aria-label=""
                  aria-describedby="basic-addon1"
                  value={billingAddress.billing_zip}
                  onChange={(e) => {
                    setBillingAddress({ ...billingAddress, billing_zip: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box position-relative mb-3 ">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_country")}
                </Form.Label>
                  <div className="d-flex">
                    <Select
                      className="custom-select-menu flex-grow-1"
                      placeholder={_l("l_please_select_country")}
                      options={countryList}
                      classNamePrefix="react-select"
                      value = { countryList && countryList.find(
                              (w) => w.label == billingAddress.billing_country
                            )
                      }
                      // value={billingAddress.billing_country}
                      onChange={(e) => {
                        setBillingAddress({
                          ...billingAddress,
                          billing_country: e.label,
                        });
                      }}
                    />
                  </div>
              </Form.Group>
          { modalKey == "billing_address"
            ? <Form.Group className="col-xl-12 c-input-box position-relative mb-3">
                <td className="comman_action_icon max-width-100 text-truncate  pe-1">
                  <Form.Check
                    type="checkbox"
                    className="true"
                    checked={checked}
                    onChange={(e) => {
                      setChecked(!checked);
                    }}
                  />
                </td>
                <td className="max-width-100 text-truncate border-bottom text-center title-fonts">{_l("l_same_shipping_address")}</td>
              </Form.Group>
            : <></>
          }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={hideModal} >
            {_l("l_cancel")}
          </Button>
          <Button
            variant="primary" size="sm"
            onClick={() => {
              hideModal()
            }}
          >
            {_l("l_confirm")}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

const AddEstimateModal = ({ show, handleClose }) => {

  const estimateTypes = [
    {
      value: "custom",
      label: _l("l_custom"),
    },
    {
      value: "benchmark",
      label: _l("l_benchmark"),
    },
    {
      value: "premium_membership",
      label: _l("l_premium_membership"),
    },
    {
      value: "operator_premium",
      label: _l("l_operator_premium_membership"),
    }
  ]

  const paymentModes = [
    {
      value: "gocardless",
      label: _l("GoCardless"),
    },
    {
      value: "stripe",
      label: _l("Stripe"),
    },
  ]

  const languageOptions = [
    { value: "EN", label: _l("l_english") },
    { value: "FR", label: _l("l_french") },
  ];
  const staffId = localStorage.getItem("staff_id") ? localStorage.getItem("staff_id") : null
  const spaces = JSON.parse(localStorage.getItem("spaces"));
  const projectId = localStorage.getItem("isLiteVersion") == "true" ? 0 : localStorage.getItem("selectedOffice");
  const selectedSpace = spaces.filter((item) => item.project_id == projectId);
  const spaceClientId = selectedSpace && selectedSpace.client_id

  const initialInput = {
    customer_client_id: null,
    project_id: projectId,
    staff_id: staffId,
    customer_company_name: "",
    estimateno: 0,
    start_date: null,
    end_date: null,
    estimate_type: estimateTypes[0],
    payment_mode: paymentModes[0],
    recurring: null,
    name: "",
    billing_address: null,
    shipping_address: null,
    show_shipping_on_estimate : 0,
    items: [],
    address: "",
    estimate_number: null,
    estimate_subtotal: 0,
    discount_total: 0,
    estimate_total: 0,
    tax_amount_total: 0,
    adjustment: 0,
    client_note: "",
    terms: "",
  };
 
  const dispatch = useDispatch();

  const [customers, setCustomers] = useState([]);
  const [taxFeild, setTaxFeild] = useState([]);
  const [estimateinput, setEstimateInput] = useState(initialInput);
  const [estimateItems, setEstimateItems] = useState([{
    description: "",
    long_description: "",
    order: 0,
    qty: 0,
    rate: 0,
    taxname: null,
    taxtnamewrapper: null,
    item_total: 0,
    tax_amount: 0
  },]);
  const [termText, setTermText] = useState("");
  const [countryCodes, setCountryCodes] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [addressModal, setAddressModal] = useState(false);
  const [addressModalKey, setAddressModalKey] = useState("");
  const [emailModal, setEmailModal] = useState(false);
  const [loader, setLoader] = useState(false);
  
  const excludedDate = (date) => {
    const day = Number(moment(date).format("D"));
      return ![29,30,31].includes(day);
  }

  useEffect(() => {
    let defaultSpace = estimateinput.customer_client_id;
    let estimateNumber = estimateinput.estimate_number;
    let termsText = estimateinput.terms

    CustomerServices.getCountryCodes(true).then((res) => {
      if (res.status == "1") {
        const phoneNumberCodes = res.data.filter(
          (x) => x.is_calling_country == 1
        );
        setCountryCodes(
          phoneNumberCodes.map((code) => {
            return {
              value: code.code_value,
              label: code.code_value,
            };
          })
        );
        setCountryList(
          res.data.map((code) => {
            return {
              value: code.id,
              label: code.long_name,
              short_code: code.iso2,
            };
          })
        );
      }
    });

    CustomerServices.getCustomerEstimate(projectId).then((res) => {
      if (res.status == "1") {
        const response = res.data
        setCustomers(
          response.map((customer) => {
            let defaultSpace= null
            if (customer.userid == spaceClientId) {
              defaultSpace = {
               value : customer.userid,
               label : customer.company
              }
            }
            return {
              value: customer.userid,
              label: customer.company,
            };
          })
        )
        setEstimateInput({...estimateinput, estimate_number : estimateNumber, terms: termsText, customer_client_id : defaultSpace})
        // setEstimateInput({...estimateinput, customer_client_id : defaultSpace})
      }
    })

    CustomerServices.getAllTaxName().then((res) => {
      if (res.status == "1") {
        const response = res.data
        const taxFieldWrapper = response.map((tax) => {
          return {
            value: tax.id,
            label: `${tax.name} ${tax.taxrate}%`,
            taxrate: parseFloat(tax.taxrate)
          };
        })
        setTaxFeild(taxFieldWrapper)
        const items = [...estimateItems]
        items[0] = {...items[0], taxname : taxFieldWrapper[0].value, taxtnamewrapper: taxFieldWrapper[0] }
        setEstimateItems(items)
      }
    })

    CustomerServices.getEstimateTerms(staffId).then((res) => {
      if (res.status == "1") {
        const response = res.data
        estimateNumber = `${response.prefix} ${response.estimate}`;
        termsText = response.terms
        setEstimateInput({...estimateinput, estimate_number : estimateNumber, terms: termsText, customer_client_id : defaultSpace})
        // setEstimateInput({...estimateinput, estimate_number : `${response.prefix} ${response.estimate}`, terms: response.terms, customer_client_id : defaultSpace})
        // setTermText(response.terms)
      }
    })

  }, []);


  const validateInputFields = () => {
    let validationSuccess = true;

    const  {
      customer_client_id,
      project_id,
      staff_id,
      start_date, 
      end_date,
      customer_company_name,
      estimate_type,
      payment_mode,
      recurring, 
      billing_address, 
      show_shipping_on_estimate ,
      items, 
      address,
      estimate_subtotal, 
      discount_total, 
      estimate_total,
      adjustment, 
      terms
    } = estimateinput
    let errMessage; 
    
    if (!terms.trim()) {
      errMessage = 'Please Aggree our terms and conditons';
      validationSuccess = false 
    }
    if (items.length == 0) {
      errMessage = 'Please enter at least one item';
      validationSuccess = false 
    }
    // if (!address.trim()) {
    //   errMessage = 'Address is required';
    //   validationSuccess = false 
    // }
    // if (billing_address == null) {
    //   errMessage = 'Please Enter Billing Address.';
    //   validationSuccess = false 
    // }
    if (recurring == null) {
      errMessage = 'Please select if Estimate is recurring or not.';
      validationSuccess = false 
    }
    if (start_date == null) {
      errMessage = 'Please select if Estimate is recurring or not.';
      validationSuccess = false 
    }
    if (start_date == null) {
      errMessage = 'Start Date is required';
      validationSuccess = false 
    }
    // if (!customer_company_name.trim()) {
    //   errMessage = 'Company Name is required';
    //   validationSuccess = false 
    // }
    if (customer_client_id == null) {
      errMessage = 'Please Select Customer';
      validationSuccess = false 
    }

    if (!validationSuccess) {
      dispatch(
        showMessage("unsucess", _l("l_error"), `${errMessage}`)
      );
    }
    return validationSuccess;
  };

  const createNewEstimate = (saveAndSend, sendEmailData = {}) => {
    if (validateInputFields()) {
      setLoader(saveAndSend ? "" : "create")
      benchmarkServices.createEstimate(estimateinput, saveAndSend, sendEmailData).then((res) => {
        if (res.status) {
          dispatch(showMessage("sucess", _l("l_success"), _l("l_estimate_created")));
          setTimeout(() => {
            handleClose({
              hash: res.data.estimate_hash,
              type:"estimate"
            })
          }, 1000);
          setLoader(false)
        }
        else {
          setLoader(false)
          dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
        }
      });
    }
  };

  const addNewItemValidation = (index) => {
    let validationSuccess = true;
    let errMessage;
    const {qty, description} = estimateItems[index]

    if (qty == 0) {
      errMessage = 'Quantity of item should be greater than 0';
      validationSuccess = false 
    }

    if (!description.trim()) {
      errMessage = 'Description is required';
      validationSuccess = false 
    }

    if (!validationSuccess) {
      dispatch(
        showMessage("unsucess", _l("l_error"), `${errMessage}`)
      );
    }
    return validationSuccess;
  }

  useEffect(() => {
    let estimateTotal = 0;
    let taxSum = 0;
    let estimateSubtotal = 0;
    estimateItems.length && estimateItems.map((item, index) => {
      taxSum += item.tax_amount
      estimateSubtotal += item.item_total
      estimateTotal = estimateSubtotal + taxSum
    })
    setEstimateInput({...estimateinput, estimate_subtotal: estimateSubtotal.toFixed(2), estimate_total: (estimateTotal - estimateinput.discount_total).toFixed(2), tax_amount_total: taxSum.toFixed(2)})
  }, [estimateItems])

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style w1300px"
        aria-labelledby="contained-modal-title-vcenter"
        size="xl"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>{_l("l_create_estimate")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div className="form-wrapper-main">
            <div className="row m-0 mb-3 border-bottom">
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3 g-0 z-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_customer")}
                </Form.Label>
                  <div className="d-flex">
                    <Select
                      className="custom-select-menu flex-grow-1"
                      placeholder={_l("l_select")}
                      options={customers}
                      value={estimateinput.customer_client_id}
                      onChange={(e) => {
                        setEstimateInput({ ...estimateinput, customer_client_id: e });
                      }}
                      classNamePrefix="react-select"
                    />
                  </div>
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3 g-0">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_project")}
                </Form.Label>
                  {/* <div className="d-flex"> */}
                    <Form.Control
                      type="text"
                      defaultValue={selectedSpace.office_title}
                      disabled={true}
                    />
                  {/* </div> */}
              </Form.Group>
              {/* <Form.Group className="col-xl-2 c-input-box position-relative mb-3 d-flex flex-column">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_bill_to")}
                </Form.Label>
                  <div className="d-flex flex-grow-1">
                    <a href="#/"
                      className="form-control d-flex"
                     
                    >
                      <Plus size={16} className="c-icons" weight="light" />
                    </a>
                  </div>
              </Form.Group> */}
              {/* <Form.Group className="col-xl-2 c-input-box position-relative mb-3 d-flex flex-column">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_ship_to")}
                </Form.Label>
                  <div className="d-flex flex-grow-1">
                    <a href="#/"
                      className="form-control d-flex"
                    >
                      <Plus size={16} className="c-icons" weight="light" />
                    </a>
                  </div>
              </Form.Group> */}
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3 g-0 ">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_custom_company_name")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_name")}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={estimateinput.customer_company_name}
                  onChange={(e) => {
                    setEstimateInput({ ...estimateinput, customer_company_name: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3 g-0 ">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_estimate_number")}
                </Form.Label>
                <Form.Control
                  placeholder={_l(estimateinput.estimate_number)}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={estimateinput.estimate_number}
                  disabled={true}
                />
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box pb-4 position-relative">
                <Form.Label className="input-label no-asterisk">
                  {_l("l_start_date")}
                </Form.Label>
                <div className="custom-datepicker WithFixWidthAndSelectYear react-datepicker-wrapper-w-100" data-bs-toggle="tooltip">
                  <div className="d-flex align-items-center form-control  color-white-60 dropdown-center dropdown">
                    <CalendarBlank size={18} weight="light" className="c-icons opacity-60 m-0 me-2"/>
                    <DatePicker
                      portalId="custom_datepicker"
                      calendarClassName="custom-datepicker"
                      placeholderText={`${_l( "l_start_date_placeholder" )}`}
                      dateFormat="dd/MM/yyyy"
                      selectsStart
                      calendarStartDay={1}
                      minDate={new Date()}
                      selected={estimateinput.start_date}
                      onChange={(date) => {
                        setEstimateInput({
                          ...estimateinput,
                          start_date: date,
                        });
                      }}
                      filterDate={excludedDate}
                      // excludeDates={[new Date()]}
                    ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box pb-4 position-relative">
                <Form.Label className="input-label no-asterisk">
                  {_l("l_end_date")}
                </Form.Label>
                <div className="custom-datepicker WithFixWidthAndSelectYear react-datepicker-wrapper-w-100" data-bs-toggle="tooltip">
                  <div className="d-flex align-items-center form-control  color-white-60 dropdown-center dropdown">
                    <CalendarBlank size={18} weight="light" className="c-icons opacity-60 m-0 me-2"/>
                    <DatePicker
                      portalId="custom_datepicker"
                      calendarClassName="custom-datepicker"
                      placeholderText={`${_l( "l_end_date_placeholder" )}`}
                      dateFormat="dd/MM/yyyy"
                      selectsStart
                      calendarStartDay={1}
                      selected={estimateinput.end_date}
                      onChange={(date) => {
                        setEstimateInput({
                          ...estimateinput,
                          end_date: date,
                        });
                      }}
                    ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3 g-0 ">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_estimate_type")}
                </Form.Label>
                  <div className="d-flex">
                    <Select
                      className="custom-select-menu flex-grow-1"
                      placeholder={_l("l_select")}
                      options={estimateTypes}
                      value={estimateinput.estimate_type}
                      onChange={(e) => {
                        setEstimateInput({ ...estimateinput, estimate_type: e });
                      }}
                      classNamePrefix="react-select"
                    />
                  </div>
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3 g-0 ">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_payment_mode")}
                </Form.Label>
                  <div className="d-flex">
                    <Select
                      className="custom-select-menu flex-grow-1"
                      placeholder={_l("l_select")}
                      options={paymentModes}
                      value={estimateinput.payment_mode}
                      onChange={(e) => {
                        setEstimateInput({ ...estimateinput, payment_mode: e });
                      }}
                      classNamePrefix="react-select"
                    />
                  </div>
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3 g-0 ">
              {/* <div className="col-xl-4 c-input-box position-relative mb-3"> */}
                <Form.Label className="input-label no-asterisk ">
                  <div className="input-label no-asterisk  form-label mb-0 me-10px">{_l("l_recurring")}</div>
                </Form.Label>
                <div className="d-flex mt-3">
                    <Form.Label className="form-check-label text_wrap  mb-0 ps-0">
                      <Form.Check
                        inline
                        name="recurring"
                        label="Yes"
                        type="radio"
                        value={1}
                        onChange={(e) => {
                          setEstimateInput({ ...estimateinput, recurring: e.target.value });
                        }}
                      />
                    </Form.Label>
                    <Form.Label className="form-check-label text_wrap mb-0">
                      <Form.Check
                        inline
                        name="recurring"
                        label="No"
                        type="radio"
                        value={0}
                        onChange={(e) => {
                          setEstimateInput({ ...estimateinput, recurring: e.target.value });
                        }}
                      />
                    </Form.Label>
                </div>
              {/* </div> */}
              </Form.Group>
            </div>
            
            <div className="row border-bottom">
            <Form.Group className="col-xl-4 c-input-box position-relative mb-3 ">
            <div className="d-flex align-items-center mb-2 comman_action_icon">
                  <Form.Label className="input-label no-asterisk mb-0">
                    {_l("l_bill_to")}
                  </Form.Label>
                  <a
                    href="#/"
                    className="action_icon h20w20 with_bg"
                    onClick={() => { 
                      setAddressModal(true)
                      setAddressModalKey("billing_address")
                    }}
                  >
                    <PencilLine size={14} className="c-icons" weight="light" />
                  </a>
                </div>
               <div className="d-flex flex-column gap-1 title-fonts">
                <div>{!estimateinput.billing_address || estimateinput.billing_address == null ? _l("l_street") : estimateinput.billing_address.billing_street}</div>
                <div>{!estimateinput.billing_address || estimateinput.billing_address == null ? _l("l_city") : estimateinput.billing_address.billing_city}</div>
                <div>{!estimateinput.billing_address || estimateinput.billing_address == null ? _l("l_state") : estimateinput.billing_address.billing_state}</div>
                <div>{!estimateinput.billing_address || estimateinput.billing_address == null ? _l("l_zip_code") : estimateinput.billing_address.billing_zip}</div>
                <div>{!estimateinput.billing_address || estimateinput.billing_address == null ? _l("l_country") : estimateinput.billing_address.billing_country}</div>
               </div>
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3 ">
                <div className="d-flex align-items-center mb-2 comman_action_icon">
                  <Form.Label className="input-label no-asterisk mb-0">
                    {_l("l_ship_to")}
                  </Form.Label>
                  <a 
                    href="#/"
                    className="action_icon h20w20 with_bg"
                    onClick={() => { 
                      setAddressModal(true)
                      setAddressModalKey("shipping_address")
                    }}
                  >
                    <PencilLine size={14} className="c-icons" weight="light" />
                  </a>
                </div>
                <div className="d-flex flex-column gap-1 title-fonts">
                <div>{!estimateinput.shipping_address || estimateinput.shipping_address == null ? _l("l_street") : estimateinput.shipping_address.shipping_street}</div>
                <div>{!estimateinput.shipping_address || estimateinput.shipping_address == null ? _l("l_city") : estimateinput.shipping_address.shipping_city}</div>
                <div>{!estimateinput.shipping_address || estimateinput.shipping_address == null ? _l("l_state") : estimateinput.shipping_address.shipping_state}</div>
                <div>{!estimateinput.shipping_address || estimateinput.shipping_address == null ? _l("l_zip_code") : estimateinput.shipping_address.shipping_zip}</div>
                <div>{!estimateinput.shipping_address || estimateinput.shipping_address == null ? _l("l_country") : estimateinput.shipping_address.shipping_country}</div>
               </div>
              </Form.Group>
            <Form.Group className="col-xl-4 c-input-box position-relative mb-3  pb-3 border-left">
              <div className="d-flex flex-wrap flex-column">
              <Form.Label className="input-label no-asterisk  d-flex align-items-center">
                {_l("l_address")}
              </Form.Label>
              <Form.Control
                  as="textarea"
                  placeholder={_l("l_address")}
                  rows={4}
                  value={estimateinput.address}
                  onChange={(e) => {
                    setEstimateInput({ ...estimateinput, address: e.target.value });
                  }}
                />
              </div>
            </Form.Group>
            </div>

            <div className="row m-0 border-bottom">
              <div className=" d-flex flex-column pt-3 p-0">
                <div className="comman-content-scroll-wrapper">
                  <div className="comman-content-scroll">
                    <div className="comman-data-table GeneralItemTable">
                      <table className="dataTable comman-table smaller-table">
                        <thead className="bg-transparent">
                          <tr className="bg-white-03">
                            <th>{_l("l_item")}</th>
                            <th>{_l("l_description")}</th>
                            {/* <th>{_l("l_item_image")}</th>
                            <th>{_l("l_key_data")}</th>
                            <th>{_l("l_type_of_cost")}</th> */}
                            <th>{_l("l_qty")}</th>
                            <th>{_l("l_rate")}</th>
                            <th>{_l("l_tax")} (%)</th>
                            <th>{_l("l_amount")}</th>
                            <th className="text-center"><GearSix size={18} weight="light" className="c-icons"/></th>
                          </tr>
                        </thead>
                        <tbody>

                        {
                          estimateItems.length && estimateItems.map((item, index) => {
                            return (
                              <tr className="">
                                <td className="max-width-100 text-truncate border-bottom">
                                  <div className="color-white-60 c-input-box position-relative">
                                    <Form.Control
                                      as="textarea"
                                      placeholder={_l("l_description")}
                                      rows={1}
                                      className="inputh30 pb-1 ps-2 pt-1"
                                      value={estimateItems[index].description}
                                      onChange={(e) => {
                                        const items = [...estimateItems]
                                        items[index] = {...items[index], description : e.target.value}
                                        setEstimateItems(items)
                                      }}
                                      onBlur={() => setEstimateInput({ ...estimateinput, items: estimateItems })}
                                    />
                                  </div>
                                </td>
                                <td className="max-width-100 text-truncate border-bottom">
                                  <div className="color-white-60 c-input-box position-relative">
                                    <Form.Control 
                                      as="textarea" 
                                      placeholder={_l("l_description")} 
                                      rows={1} 
                                      className="inputh30 pb-1 ps-2 pt-1"
                                      value={estimateItems[index].long_description}
                                      onChange={(e) => {
                                        const items = [...estimateItems]
                                        items[index] = {...items[index], long_description : e.target.value}
                                        setEstimateItems(items)
                                      }}
                                      onBlur={() => setEstimateInput({ ...estimateinput, items: estimateItems })}
                                    />
                                  </div>
                                </td>

                                {/* item image, key data, type of cost hide -- start
                                <td className="max-width-100 text-truncate border-bottom">
                                  <div className="color-white-60 c-input-box position-relative">
                                  <Form.Control placeholder={_l("l_item_image")} aria-label="Username" value="" disabled={true} className="inputh30 pb-1 ps-2 pt-1"/>
                                  </div>
                                </td>
                                <td className="max-width-100  border-bottom">
                                  <div className="color-white-60 c-input-box position-relative">
                                  <div className="d-flex">
                                    <Select placeholder={_l("l_select")}
                                      className="custom-select-menu flex-grow-1 h_30px"
                                      options={""}
                                      value="estimateinput.source}"
                                      classNamePrefix="react-select"
                                      disabled={true}
                                    />
                                  </div>
                                  </div>
                                </td>
                                <td className="max-width-100  border-bottom">
                                  <div className="color-white-60 c-input-box position-relative">
                                  <div className="d-flex">
                                    <Select
                                      placeholder={_l("l_select")}
                                      className="custom-select-menu flex-grow-1 h_30px"
                                      options={""}
                                      value="estimateinput.source}"
                                      classNamePrefix="react-select"
                                      disabled={true}
                                    />
                                  </div>
                                  </div>
                                </td> 
                                item image, key data, type of cost hide -- end
                                */}
                                <td className="max-width-100 text-truncate border-bottom">
                                  <div className="color-white-60 c-input-box position-relative">
                                  <Form.Control
                                    type="number" 
                                    placeholder={_l("l_quantity")} 
                                    aria-label="Username" 
                                    className="inputh30 pb-1 ps-2 pt-1"
                                    min="0"
                                    value={estimateItems[index].qty}
                                    onChange={(e) => {
                                      const items = [...estimateItems]
                                      let amount = 0
                                      if (items[index].taxtnamewrapper && items[index].rate > 0) {
                                        amount =  items[index].rate * e.target.value
                                        let percentage = items[index].taxtnamewrapper.taxrate
                                        // amount = total + percentage / 100 * total 
                                      }
                                      items[index] = {...items[index], qty : e.target.value, item_total : amount}
                                      setEstimateItems(items)
                                    }}
                                    onBlur={() => setEstimateInput({ ...estimateinput, items: estimateItems})}
                                  />
                                  </div>
                                </td>
                                <td className="max-width-100 text-truncate border-bottom">
                                  <div className="color-white-60 c-input-box position-relative">
                                  <Form.Control 
                                    type="number"
                                    placeholder={_l("l_rate")} 
                                    aria-label="Username" 
                                    className="inputh30 pb-1 ps-2 pt-1"
                                    min="0"
                                    value={estimateItems[index].rate}
                                    onChange={(e) => {
                                      const items = [...estimateItems]
                                      let amount = 0
                                      let taxAmount = 0
                                      if (items[index].taxtnamewrapper) {
                                        amount =  items[index].qty * e.target.value
                                        let percentage = items[index].taxtnamewrapper.taxrate
                                        taxAmount = (e.target.value * percentage / 100) *  items[index].qty
                                        // amount = total + percentage / 100 * total 
                                      }
                                      items[index] = {...items[index], rate : e.target.value, item_total : amount, tax_amount : taxAmount}
                                      setEstimateItems(items)
                                    }}
                                    onBlur={() => setEstimateInput({ ...estimateinput, items: estimateItems })}
                                  />
                                  </div>
                                </td>
                                <td className="max-width-100 z-3 border-bottom">
                                  <div className="color-white-60 c-input-box position-relative">
                                  <div className="d-flex">
                                    <Select
                                      placeholder={_l("l_select")}
                                      className="custom-select-menu flex-grow-1 h_30px"
                                      options={taxFeild}
                                      classNamePrefix="react-select"
                                      value={estimateItems[index].taxname}
                                      onChange={(e) => {
                                        const items = [...estimateItems]
                                        let amount = 0
                                        let taxAmount = 0
                                        if (items[index].rate > 0) {
                                          let total =  items[index].qty * items[index].rate
                                          let percentage = e.taxrate
                                          taxAmount = (items[index].rate * e.taxrate / 100) * items[index].qty
                                          amount = total + percentage / 100 * total 
                                        }
                                        items[index] = {...items[index], taxname : e.value, taxtnamewrapper: e, item_total : amount, tax_amount : taxAmount}
                                        setEstimateItems(items)
                                        setEstimateInput({ ...estimateinput, items: items })
                                      }}
                                    />
                                  </div>
                                  </div>
                                </td>
                                <td  className="max-width-100 text-truncate border-bottom text-center title-fonts">
                                  {
                                    estimateItems[index].item_total
                                  }
                                </td>
                                <td className="comman_action_icon max-width-100 text-truncate border-bottom text-center ">
                                  <Button 
                                    size="sm"
                                    onClick={() => {
                                      if(addNewItemValidation(index)){
                                        const newItem = estimateItems.concat([{
                                          description: "",
                                          long_description: "",
                                          order: 0,
                                          qty: 0,
                                          rate: 0,
                                          taxtnamewrapper: taxFeild[0],
                                          taxname: taxFeild[0].value,
                                          item_total: 0,
                                          tax_amount: 0
                                        }])
                                        setEstimateItems(newItem);
                                      }
                                    }}
                                  >
                                    {_l("l_add_item")}
                                  </Button>
                                  {/* <a href="#/" className="action_icon with_bg"> <Check size={18} weight="light" className="c-icons" /> </a> */}
                                </td>
                              </tr>
                            )
                          }) 
                        }
                                  
                        <tr className="">
                          <td colSpan={6} className="text-end border-bottom pe-5  ">
                            {_l("l_sub_total")}
                          </td>
                          <td className="border-bottom  color-white fw-semibold text-end">
                            { estimateinput.estimate_subtotal}
                          </td>
                        </tr>
                        <tr className="">
                          <td colSpan={6} className="text-end border-bottom  pe-0">
                            <Form.Group className=" c-input-box position-relative d-flex align-items-center justify-content-end">
                              <Form.Label className="input-label no-asterisk mb-0">
                                {taxFeild.length > 0 && taxFeild[0].label}
                              </Form.Label>
                              <div className="d-flex  ">
                                <Form.Control
                                  type="number"
                                  placeholder={estimateinput.tax_amount_total}
                                  className="inputh30 pb-1 ps-2 pt-1"
                                  value={estimateinput.tax_amount_total}
                                  disabled
                                />
                              </div>
                            </Form.Group>
                          </td>
                          <td className="border-bottom  color-white fw-semibold text-end">
                            {estimateinput.tax_amount_total}
                          </td>
                        </tr>
                        <tr className="">
                          <td colSpan={6} className="text-end border-bottom  pe-0">
                            <Form.Group className=" c-input-box position-relative d-flex align-items-center justify-content-end">
                              <Form.Label className="input-label no-asterisk mb-0">
                                {`${_l("l_discount")}`}
                                <span className="c-font f-10 ps-10px color-white">{_l("l_fixed_amount")}</span>
                              </Form.Label>
                              <div className="d-flex gap-2 ">
                              <Form.Control
                                type="number"
                                placeholder={_l("l_discount")}
                                aria-describedby="basic-addon1"
                                className="inputh30 pb-1 ps-2 pt-1"
                                value={estimateinput.discount_total}
                                onChange={(e) => {
                                  setEstimateInput({ ...estimateinput, discount_total: e.target.value , estimate_total: (parseFloat(estimateinput.estimate_subtotal) + parseFloat(estimateinput.tax_amount_total) - e.target.value).toFixed(2)});
                                }}
                              />
                              </div>
                            </Form.Group>
                          </td>
                          <td className="border-bottom  color-white fw-semibold text-end">
                            {estimateinput.discount_total}
                          </td>
                        </tr>
                        <tr className="">
                          <td colSpan={6} className="text-end color-green pe-5">
                            {_l("l_total")}
                          </td>
                          <td className="fw-semibold  color-green text-end title-fonts">
                           {`€${estimateinput.estimate_total}`}
                          </td>
                        </tr>
                        </tbody>
                      </table>
                      <div className="border-top c-input-box pb-10px pt-10px terms-condition">
                        <div className=" fw-semibold pb-2">{_l("l_client_note")}</div>
                        <Form.Control
                        as="textarea"
                        placeholder={_l("l_description")}
                        rows={2}
                        value={estimateinput.client_note}
                        onChange={(e) => {
                          setEstimateInput({ ...estimateinput, client_note: e.target.value });
                        }}
                      />
                      </div>
                      <div className="terms-condition">
                        <div className=" fw-semibold pb-2">{_l("l_terms_conditions")}</div>
                        <div className=" pb-3 d-flex ">
                          {/* <Form.Check
                            type="checkbox"
                            className="true"
                            checked={terms}
                            onChange={(e) => {
                              setTerms(!terms);
                              if (estimateinput.terms == "") {
                                setEstimateInput({...estimateinput, terms: termText})
                              }
                            }}
                          /> */}
                          <Form.Control
                            as="textarea"
                            placeholder={_l("l_terms_conditions")}
                            rows={2}
                            value={estimateinput.terms}
                            onChange={(e) => {
                              setEstimateInput({...estimateinput, terms: e.target.value})
                            }}
                          />
                          {/* <div className='c-font color-white-60 f-12 flex-grow-1 ms-2  w100minus100per '>{termText}</div> */}
                          {/* <span >{terms}</span> */}
                          {/* {ReactHtmlParser(invoiceTerms)|| _l("l_invoice_terms_condition")} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            {_l("l_cancel")}
          </Button>
          <Button
            variant="primary" size="sm"
            onClick={() => {
              if (validateInputFields()) {
                setEmailModal(true)
              }
            }}
            disabled={!loader == "" ? 1 : 0}
          >
            { loader == "saveAndSend" ? _l('l_please_wait') : _l("l_save_and_send") }
            { loader == "saveAndSend" ?
              <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2 `}
                  aria-hidden="true"
                />
              : <></>
            }
          </Button>
          <Button
            variant="primary" size="sm"
            onClick={() => {
              createNewEstimate(0)
            }}
            disabled={!loader == "" ? 1 : 0}
          >
            { loader == "create" ? _l('l_please_wait') : _l("l_submit") }
            { loader == "create"  ?
              <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2 `}
                  aria-hidden="true"
                />
              : <></>
            }
          </Button>
        </Modal.Footer>
      </Modal>

      { addressModal 
        ? <AddressModal 
            show={addressModal}
            modalKey={addressModalKey}
            data = {estimateinput[addressModalKey]}
            handleClose={() => {setAddressModal(false)}}
            handleInput={(address, address2, checked) => {
              if (checked) {
                setEstimateInput({ ...estimateinput, [addressModalKey] : address, shipping_address : address2 });
              }else{
                setEstimateInput({ ...estimateinput, [addressModalKey] : address });
              } 
              setAddressModal(false)
              setAddressModalKey("")
            }}
          />
        : <></>
      }

      { emailModal
        ? <AddEmailTemplatesModalNew
            show={emailModal}
            handleClose={() => setEmailModal(false)}
            type={"estimate"}
            from={"newEstimate"}
            createEstimateHandler={(sendEmailData) => {
              createNewEstimate(1, sendEmailData)
              setEmailModal(false)
            }}
          />
        : <></>
      }
    </>
  );
};

export default AddEstimateModal;
