import React, { useState, useEffect } from "react";
import { PencilSimpleLine, Sun, GearSix, SignOut, Question, MonitorPlay,UserSwitch,CaretDown, Wrench } from "phosphor-react";
import jquery from "jquery";
import { _l, favoriteDdashboardSelected, loadDataToIDB, showLeftPanelNew } from "../../hooks/utilities";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { leftPanelFilters, setSpaceList, userLogout, isPremuimEstimateDisable, ProductTour, getCustomerSpaces, projectTaskCounts, setSelectedLanguage, setProjectTheme, setSelectedGloablView, lastSessionOfChat, setFetchedTaskandMessages, setDaterangeFilter, setDaterangeFilterApplied, setLeftPanelTab } from "../../actions/customer";
import { useDispatch } from "react-redux";
import { userOffline, connectToSocket, getGroupDetails } from "../../actions/chat";
import { adminLoginAsStaff } from "../../actions/admin-actions";
import Form from 'react-bootstrap/Form';
import CustomerServices from "../../services/customer-services";
import { showConfirmation, showFullScreenLoader, showMessage } from "../../actions/messages";
import ToolsInformationModal from "../Modals/ToolsInformationModal";
import GeneralListing from "../TaskModalComponents/GeneralListing";
import { loginAsUser } from "../../actions/auth";
import AuthServices from "../../services/auth-services";
import { Button } from "react-bootstrap";
import BenchmarkServices from "../../services/benchmark-services";
import { archieveIDBCollections, clearDatabase, restoreIDBSession } from "../../hooks/indexed_db_helpers";

const HeaderUserDropdown = ({isExternalHeader=false, user_name,userDescription,profileImageURL }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const hash = searchParams.get("hash");

  const adminAsStaff = localStorage.getItem('adminArchieve') ? true : false;

  const adminAuth = useSelector((state) => state.adminReducer.loginState);

  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem("language") || "french");
  const [hideLogoutSwitch, setHideLogoutSwitch] = useState(adminAsStaff);
  const [loginUserImage, setLoginUserImage] = useState(localStorage.getItem("contact_image"));
  const [loginUserEmail, setLoginUserEmail] = useState(localStorage.getItem("email"));
  const [loginUserName, setLoginUserName] = useState(localStorage.getItem("full_name"));
  const [preEstiPageDisable, setPreEstiPageDisable] = useState(false);
  const isPremiumEstimateEdit = useSelector((state) => state.customer.isPremiumEstimateAccess);
  const isProductTourGuide = useSelector((state) => state.customer.productTour);
  const [ProductTourGuide, setProductTourGuide] = useState(false);
  const [showToolsModal, setShowToolsModal] = useState(false);
  const [recentLoginUserList, setRecentLoginUserList] = useState([]);
  const {themeColor} = useSelector((state) => state.customer)

  const logout = () => {
    var chatUserId = localStorage.getItem("chatUserId");
    var language = localStorage.getItem("language");
    if (chatUserId != "" && chatUserId != undefined) {
      dispatch(userOffline({ userId: chatUserId }));
    }
    dispatch(userLogout());
    localStorage.clear();
    localStorage.setItem("version", process.env.REACT_APP_VERSION);
    localStorage.setItem('selectedTheme', LightTheme ? "light" : "dark");
    localStorage.setItem("language", language);
    navigate("/login");
    dispatch(
      leftPanelFilters('', '', 'all',)
    );
  }
  useEffect(() => {
    if (isProductTourGuide) {
      setProductTourGuide(true);
    } else {
      setProductTourGuide(false);
    }
  }, [isProductTourGuide]);
  const handleSwitchLogin = async() => {
    let email = "";
    email = localStorage.getItem("email");
    var chatUserId = localStorage.getItem("chatUserId");
    if (chatUserId != "" && chatUserId != undefined) {
      dispatch(userOffline({ userId: chatUserId }));
    }
    const archieve = JSON.parse(localStorage.getItem("adminArchieve"));
    const projectId = localStorage.getItem('selectedOffice');
    await archieveIDBCollections(email);
    
    localStorage.clear();
    localStorage.setItem("version", process.env.REACT_APP_VERSION);
    archieve && archieve.length && archieve.forEach((item) => {
      localStorage.setItem(item.key, item.value);
      if (item.key == "fav_dashboard") {
        favoriteDdashboardSelected(item.value);
      }
    });
    //Get Admin's Email
    email = localStorage.getItem("email");
    dispatch(adminLoginAsStaff(false));
    localStorage.removeItem("adminArchieve");
    await clearDatabase();
    await restoreIDBSession(email);
    localStorage.setItem('selectedOffice', projectId)
    dispatch(showFullScreenLoader());
    dispatch(setSpaceList(JSON.parse(localStorage.getItem("spaces"))));
    dispatch(isPremuimEstimateDisable());
    setTimeout(() => {
      dispatch(connectToSocket());
    }, 500);
    dispatch(leftPanelFilters("","","all"))
    dispatch(getGroupDetails({}));
    dispatch(lastSessionOfChat([]));
    dispatch(setFetchedTaskandMessages([]));
    dispatch(setSelectedGloablView("AichatView"));
    dispatch(setDaterangeFilter("today"))
    dispatch(setDaterangeFilterApplied(false));
    showLeftPanelNew();
    dispatch(setLeftPanelTab("homepage"))
    navigate("/redirect", {
      state: {
        url: "/",
      }
    });
  }

  const userName = localStorage.getItem("full_name");
  const emailAddress = localStorage.getItem("email");
  const contact_image = localStorage.getItem("contact_image");
  const [LightTheme, setLightTheme] = useState(false);
  const [PauseMenu, setPauseMenu] = useState(false);
  const isAdmin = localStorage.getItem("admin");

  let selectedtheme = localStorage.getItem('selectedTheme') == "light" ? true : false

  useEffect(() => {
    setHideLogoutSwitch(adminAuth);
    getUserLoginSessions();
  }, [adminAuth]);

  useEffect(() => {
    if (localStorage.getItem('selectedTheme') == "light") {
      jquery('html').addClass('light-theme');
      setLightTheme(true)
    } else {
      jquery('html').removeClass('light-theme');
      setLightTheme(false)
    }
  }, [LightTheme, selectedtheme]);

  useEffect(() => {
    setLoginUserImage(localStorage.getItem("contact_image"))
    setLoginUserName(localStorage.getItem("full_name"))
    setLoginUserEmail(localStorage.getItem("email"))
  }, [localStorage.getItem("contact_image"), localStorage.getItem("email"), localStorage.getItem("full_name")]);

  useEffect(() => {
    setPreEstiPageDisable(false)
    if (isPremiumEstimateEdit == true) {
      setPreEstiPageDisable(true)
    }
  }, [JSON.stringify(isPremiumEstimateEdit)])

  const setTheme = (theme) => {
    CustomerServices.setUserTheme(theme ? 1 : 0).then((res) => {
      localStorage.setItem('selectedTheme', theme ? "light" : "dark");
      setLightTheme(theme);
      dispatch(setProjectTheme(theme ? "light" :  "dark" ))
    });
  }
  useEffect(() => {
    if (themeColor == "light") {
      setLightTheme(true);
    }else{
      setLightTheme(false);
    }

    
  }, [themeColor])
  const setLanguage = (language) => {
    dispatch(setSelectedLanguage(language));
    localStorage.setItem('language', language);
    setCurrentLanguage(language);
    CustomerServices.setUserLanguage(language);
  }

  const relecentloginHandler = (email, clientId, project_id = 0, staff_id) => {
    dispatch(showFullScreenLoader());
    dispatch(
      loginAsUser(email, clientId, project_id)
    ).then(async() => {

      if (!localStorage.getItem("accessToken")) {
        dispatch(
          showMessage("unsucess", _l("l_error"), _l("l_invalid_credentials"))
        );
      } else {
        localStorage.setItem("password", "12345");
        await clearDatabase();
        await restoreIDBSession(email);

        await loadDataToIDB(staff_id);
        if (localStorage.getItem("membership_package_id") == 1) {
          // navigate("/premiumsubscription");
           navigate("/myprofile");
        } else {
          const client_id = localStorage.getItem("client_id");
          const contact_id = localStorage.getItem("contact_id");
          localStorage.setItem("selectedOffice",project_id);
          dispatch(getCustomerSpaces(client_id, contact_id));
          dispatch(adminLoginAsStaff(true));
          dispatch(projectTaskCounts(project_id));
          navigate("/redirect", {
            state: {
              url: "/",
            },
          });
        }
      }
    });
  };

  const getUserLoginSessions = async() => {
    if(isAdmin || adminAsStaff || hideLogoutSwitch)
    {
      try {
        const response = await AuthServices.getUserRecentSessions();
        if(response && response.data && response.data.length)
        {
          setRecentLoginUserList(response.data);
        }
      }
      catch(e)
      {
        console.error(e);
      }
    }
  };

  const discardExternalBenchmark = () => {
    dispatch(showConfirmation(_l("l_are_you_sure"), _l("l_discard_external_benchmark"), _l("l_discard"), _l("l_cancel"), () => {}, async() => {
      try {
        const response = await BenchmarkServices.discardExternalBenchmark(hash);
        if(response.status)
        {
          localStorage.clear();
          localStorage.setItem("version", process.env.REACT_APP_VERSION);
          window.location.href = "/?page=connect&register-type=1";
        }
      }
      catch(e)
      {
        console.error(e);
      }
    }))
  };

  return (
    <ul className="dropdown-menu" aria-labelledby="headerUserDropdown">
      <li className="HeaderDropdownTitle">
        <a href="#/" className="dropdown-item no-hover" onClick={() => {
          if(isExternalHeader){}else{
          if (preEstiPageDisable == true) {
            return;
          }
          navigate("/myprofile");
        }
        }}>
          <div className="d-flex align-items-center">
            <div className="c-list-icon">
              <div className="h40w40 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 comman-image-box">
                <div
                  className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                  style={{ backgroundImage: `url('${ isExternalHeader ? profileImageURL :loginUserImage}')` }}
                ></div>
              </div>
            </div>
            <div className="ps-2 w100minus40 title-fonts d-flex  flex-column">
              <div className="c-list-detail  fw-semibold text-truncate color-white">
               {isExternalHeader ? user_name && user_name != "" ? user_name : _l('l_welcome_myr_ai') : loginUserName}
                <div className="badge badge-white  lh-1 ms-2 px-2 py-1 rounded-pill "><span className="">v {process.env.REACT_APP_VERSION}</span></div>
              </div>
              <div className="c-font f-10 color-white-60 text-truncate">
                {isExternalHeader ?  userDescription : loginUserEmail}
              </div>
            
            </div>
          </div>
          <div className="pt-15">
          {
                isExternalHeader ?
                <Button className="w-100" onClick={discardExternalBenchmark}>{_l("l_discard")}</Button>
                :
                <></>
              }
          </div>
        </a>
      </li>
      <li>
        <hr className="dropdown-divider m-0" />
      </li>
      {isExternalHeader ? (<></>) :
      (<li className="three-row-btn p-2">
        <div className="d-flex align-items-center p-1 three-row-btn-bg">
          <label className={`row-btn-label w-50 ${currentLanguage == "english" ? "active-language" : ""}`} >
            <input type="radio" checked={currentLanguage == "english" ? true : false} name="Lang-selet-radio" value={currentLanguage == "english" ? true : false} onChange={() => {
              setLanguage("english");
              // window.location.reload();
            }} className="hidden-input" />
            <div className="row-btn-box c-font f-12 p-2" >{_l('l_english')}</div>
          </label>
          <label className={`row-btn-label w-50 ${currentLanguage == "french" ? "active-language" : ""}`}>
            <input type="radio" checked={currentLanguage == "french" ? true : false} name="Lang-selet-radio" value={currentLanguage == "french" ? true : false} onChange={() => {
              setLanguage("french");
              // window.location.reload();
            }} className="hidden-input" />
            <div className="row-btn-box c-font f-12 p-2">{_l('l_french')}</div>
          </label>
        </div>
      </li>)}
      {isExternalHeader ? (<></>):(
      <li>
        <a href="#/" className={`dropdown-item text-truncate  ${preEstiPageDisable == true ? 'for-disabled' : ''}`} onClick={() => {
          if (preEstiPageDisable == true) {
            return;
          }
          navigate("/myprofile");
        }}>
          <div className="d-flex align-items-center">
            <div className=" me-2" ><PencilSimpleLine size={18} className="c-icons" weight="light" /> </div>
            <div className="text-truncate">{_l('l_edit_profile')}</div>
          </div>
        </a>
      </li>
      )}
      {isExternalHeader ? <></> :
      <li className="">
        <div className="dropdown-item">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <Sun size={18} className="c-icons me-2" weight="light" />
              <div>{_l('l_appearance')}:{LightTheme ? _l('l_light') : _l('l_dark')}</div>
            </div>
            <Form.Check
              type="switch"
              id=""
              label=""
              className="with-no-label-space"
              checked={LightTheme}
              onChange={() => {
                setTheme(!LightTheme);
              }}
            />
          </div>
        </div>
      </li>}
      {isExternalHeader ? (<></>):(<>
      <li className="">
        <div className="dropdown-item">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <Wrench size={18} className="c-icons me-2" weight="light" />
              {_l('l_tools_information')}
            </div>
            <Form.Check
              type="switch"
              id=""
              label=""
              className="with-no-label-space"
              checked={showToolsModal}
              onChange={() => {
                setShowToolsModal(!showToolsModal);
              }}
            />
          </div>
        </div>
      </li>
      {/* <li className="">
        <div className="dropdown-item">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <MonitorPlay size={18} className="c-icons me-2" weight="light" />
              <div>{_l('l_start_tutorial')}</div>
            </div>
            <Form.Check
              type="switch"
              id=""
              label=""
              className="with-no-label-space"
              checked={ProductTourGuide}
              onChange={(e) => {
                dispatch(
                  ProductTour(e.target.checked)
                );
              }}
            />
          </div>
        </div>
      </li> */}
      </>
      )}
      {PauseMenu ? (
        <React.Fragment>
          <li>
            <a href="#/" className="dropdown-item text-truncate">
              <div className="d-flex">
                <div className="me-2"> <GearSix size={20} className="c-icons" weight="light" /> </div>
                <div className="text-truncate">{_l('l_setting')}</div>
              </div>
            </a>
          </li>
          <li>
            <a href="#/" className="dropdown-item text-truncate">
              <div className="d-flex">
                <div
                  className="me-2">
                  <Question size={20} className="c-icons" weight="light" />
                </div>
                <div className="text-truncate">{_l('l_help')}</div>
              </div>
            </a>
          </li>
        </React.Fragment>
      ) : (<></>
      )}
      {isExternalHeader ? (<></>):(<>
      <li>
        <hr className="dropdown-divider" />
      </li>
      <li>
        {
          adminAsStaff || hideLogoutSwitch ?
            <>
              <a href="#/" className="dropdown-item text-truncate" onClick={handleSwitchLogin}>
                <div className="d-flex">
                  <div
                    className="me-2">
                    <SignOut size={20} className="c-icons" weight="light" />
                  </div>
                  <div className="text-truncate">{_l('l_switch_to_admin')}</div>
                </div>
              </a> 
            </>
            :
            <a href="#/" className="dropdown-item text-truncate" onClick={logout}>
              <div className="d-flex align-items-center">

                <div
                  className="me-2">
                  <SignOut size={18} className="c-icons" weight="light" />
                </div>
                <div className="text-truncate">{_l('l_logout')}</div>
              </div>
            </a>
        }
        {isAdmin || adminAsStaff || hideLogoutSwitch   ?
         <div className="dropdown">
            <a href="#/" className="dropdown-item text-truncate" type="button"
              id="CategoryFilterDropdown"
              data-bs-toggle="dropdown">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <div
                    className="me-2">
                    <UserSwitch size={20} className="c-icons" weight="light" />

                  </div>
                  <div className="text-truncate">{_l('l_recent_login')}</div>
                </div>
                <CaretDown size={15} className="c-icons m-0 opacity-50" weight="fill" />
              </div>  
            </a>
            <GeneralListing widthClass="w-280"
              list={recentLoginUserList && recentLoginUserList.map((item) => {
                return {
                  name: item.fullname,
                  image: item.profile_image_url,
                  email: item.email,
                  client_id: item.client_id,
                  project_id: item.project_id,
                  is_primary_user: item.is_primary_user,
                  staff_id: item.staff_id
                };
              })}

              handler={(data) => {
                relecentloginHandler(data.email, data.client_id, data.project_id, data.staff_id)
              }}
              placeHolderName='No login user'
            />
          </div> 
        :
          <React.Fragment></React.Fragment>
        }
      </li>
      </>
      )}
      {
        showToolsModal ?
          <ToolsInformationModal
            show={showToolsModal}
            handleClose={() => {
              setShowToolsModal(false);
            }}
          />
          :
          <></>
      }
    </ul>
  );
};

export default HeaderUserDropdown;
