import React, { useEffect, useState } from "react";
import { _l, showError } from "../../hooks/utilities";
import { DotsNine } from "phosphor-react";
import CommanOffCanvas from "../Offcanvas/CommanOffCanvas";
import { useDispatch, useSelector } from "react-redux";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import customerServices from "../../services/customer-services";
import { getProviderList, getProviderListTableData, leftPanelFilters, setLeftPanelTab, setProvideClientList } from "../../actions/customer";
import CommanLoader from "../Loader/CommanLoader";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import ProviderListActionModal from "./ProviderListActionModal";
import ReactTooltip from "react-tooltip";
import GeneralListing from "../TaskModalComponents/GeneralListing";

let STAUSTAB = [
    {
        title: "l_active",
        color: "done",
        name: "Active",
    },
    {
        title: "l_invited",
        color: "bg-warning",
        name: "Invited",
    },
    {
        title: "l_past",
        color: "in-progress-blue",
        name: "Past",
    },
];

const ProviderList = () => {
    const dispatch = useDispatch();
    const { providerList, taskFilters,globalSearchString, providerListDataLoading, leftPanelTab, agentList,clientList, defaultTopics } = useSelector((state) => state.customer);

    const [selectedTab, setSelectedTab] = useState("Active");
    const [showCommanoffCanvasModal, setShowCommanoffCanvasModal] = useState(false);
    const [providerListLocal, setProviderListLocal] = useState([]);
    const [counts, setCounts] = useState({ Active: 0, Invited: 0, Past: 0 });
    const [modalData, setModalData] = useState({});
    const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
    const [selectedCategoryTittles, setSelectedCategoryTittles] = useState({});
    const [categoryList, setCategoryList] = useState([]);
    const [providerActionModal, setproviderActionModal] = useState(-1)
    const [clientListLocalData, setClientListLocalData] = useState([])
    const userType = localStorage.getItem('user_type');

    const getCompanyDetails = (user, tab_name) => {
        setShowCommanoffCanvasModal(true)
        try {
            customerServices.getSelectedProviderDetails( user.userid, tab_name).then((res)=>{
                if (res && res.status) {
                    setModalData({...res.data, name : user.company, id: user.userid });
                    dispatch(getProviderListTableData(res.data))
                    localStorage.setItem("currentCompanyID",user.userid);
                }else{
                    showError(res.message)
                }
            })
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (window.location.pathname == "/providerlist") {
            dispatch(setLeftPanelTab("my-providers"));
        }
        // dispatch(leftPanelFilters({
        //     filter_key : 21,
        //     main_key : "providers",
        //     main_sub_key : "providers",
        //     sub_category : [],
        //     title : "Cleaning"
        // }))
        dispatch(leftPanelFilters("","","all"));
    }, []);
  
    useEffect(() => {
        if (providerList && providerList.length && userType != "operator") {
            setProviderListLocal(providerList.filter((w) => w.is_existing == 1));
            setCounts({
                Active: providerList.filter((w) => w.is_existing == 1).length,
                Invited: providerList.filter((w) => w.is_existing == 2).length,
                Past: providerList.filter((w) => w.is_existing == 0).length,
            });
        }
    }, [providerList]);

    useEffect(() => {
        switch (selectedTab) {
            case "Active":
                setProviderListLocal(providerList.filter((w) => w.is_existing == 1));
                break;
            case "Invited":
                setProviderListLocal(providerList.filter((w) => w.is_existing == 2));
                break;
            case "Past":
                setProviderListLocal(providerList.filter((w) => w.is_existing == 0));
                break;
            default:
                setProviderListLocal(providerList.filter((w) => w.is_existing == 1));
                break;
        }
    }, [selectedTab, providerList]);

    useEffect(() => {
        let ids = [];
        let names = {}
        if (defaultTopics && defaultTopics.length) {
            defaultTopics.find((item) => item.main_key == "providers").categories.filter((category) => Number(category.filter_key) > 0).map((f) =>{
                ids.push(f.filter_key)
                // names.push({ "id" : f.filter_key , "tittle" : f.title})
                // tittles.push({filter_key, tittle})
                names[f.filter_key] = f.title;
            })
            setSelectedCategoryIds(ids) 
            setSelectedCategoryTittles(names)
        }
    }, [defaultTopics]);

    useEffect(() => {
        if (selectedCategoryIds && selectedCategoryIds.length && userType != "operator") {
            dispatch(getProviderList(selectedCategoryIds)) 
        }
    }, [selectedCategoryIds]);
    
    useEffect(() => {
        if (clientList && clientList.length && userType == "operator") {
            setClientListLocalData(clientList.filter((w) => w.is_active == 1));
            setCounts({
                Active: clientList.filter((w) => w.is_active == 1).length,
                Past: clientList.filter((w) => w.is_active == 0).length,
            });
        }
    }, [clientList]);

    useEffect(() => {
        if (userType == "operator") {
            switch (selectedTab) {
                case "Active":
                    setClientListLocalData(clientList.filter((w) => w.is_active == 1));
                    break;
                case "Past":
                    setClientListLocalData(clientList.filter((w) => w.is_active == 0));
                    break;
                default:
                    setClientListLocalData(clientList.filter((w) => w.is_active == 1));
                    break;
            }
        }
    }, [selectedTab, clientList]);


    const CategoryTittles = ({ids}) => {
        const tittlesArr = ids.map((i) => ({
            id: i,
            name: selectedCategoryTittles[i]
        }))
        return (
            <>
            {tittlesArr[0].name}
            {
                tittlesArr.length > 1
                    ? <div className="h20w20 rounded-circle with_overlay dropstart d-inline-flex ps-4">
                        <UncontrolledDropdown className="align-items-center d-flex dropdown justify-content-center w-100">
                            <DropdownToggle data-toggle="dropdown" tag="div" className="comman_action_icon">
                                <div className="m-auto c-font d-flex align-itmes-center justify-content-center h25w25 action_icon with_bg">
                                    {`+ ${tittlesArr.length - 1}`}
                                </div>
                                <GeneralListing
                                    unControlList={true}
                                    list={tittlesArr.slice(1).map((category) => {
                                        return {
                                            name: category.name,
                                            id: category.id,
                                        };
                                    })}
                                />
                            </DropdownToggle>
                        </UncontrolledDropdown>
                    </div>
                    : <></>
            }
        </>
        )
    }

    return (
        <><div className="comman-main-box custom-accordian-main res-padding-right0 h-100 task-list-accordian position-relative px20py20">
                <div className="comman-tab-wrapper  pb-20px">
                    <div className="d-flex align-items-center">
                        {STAUSTAB.filter((tab)=> ["my-clients","my-agents" ].includes(leftPanelTab)  ? tab.name != "Invited" : tab ).map((tab) => {
                            return (
                                <div className={`tab-wrapper d-flex align-items-center ${selectedTab === tab.name ? "active" : ""}`}>
                                    <a href="#/" className="tab-name d-flex align-items-center bg-white-03-solid" onClick={() => { setSelectedTab(tab.name); }} >
                                        <span class={`c-dots  c-7 rounded-circle me-10px ${tab.color}`} ></span>
                                        <span>{_l(tab.title)}</span>
                                        <span className="ms-1">{`[${
                                            tab.name == "Active"
                                                ? counts.Active
                                                : tab.name == "Invited"
                                                    ? counts.Invited
                                                    : tab.name == "Past"
                                                        ? counts.Past
                                                        : 0
                                        }]`}</span>
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="comman_vertical_btn_h  comman-white-box-shadow light-theme-white-bg">
                    <div className="comman-data-table employee-table small-data-table overflow-auto comman-content-scroll-wrapper flex-grow-1">
                        <table className="dataTable text-center tablewithborder">
                            <thead className="comman-white-box-shadow">
                                <tr>
                                    <th style={{ width: "15%" }} className="text-start">{_l("l_company_name")}</th>
                                    <th style={{ width: "5%" }} className="text-start">{_l("l_type_of_service")}</th>
                                    <th style={{ width: "8%" }} className="text-start">{_l("l_contact_name")}</th>
                                    <th style={{ width: "3%" }} className="text-start">{_l("l_status")}</th>
                                    <th style={{ width: "3%" }} className="text-center">{_l("l_task_score")}</th>
                                    <th style={{ width: "3%" }} className="text-center">{_l("l_risk_score")}</th>
                                    <th style={{ width: "3%" }} className="text-center">{_l("l_spaces")}</th>
                                    <th style={{ width: "3%" }} className="text-center">{_l("l_action")}</th>
                                </tr>
                            </thead>
                            <tbody style={{ verticalAlign: "middle" }}>
                                {!["my-clients","my-agents" ].includes(leftPanelTab)&& providerListLocal && providerListLocal.length > 0 ?
                                <>
                                    {providerListLocal.filter((w)=> w.company.toLowerCase().includes(globalSearchString.toLowerCase()) || w.fullname.toLowerCase().includes(globalSearchString.toLowerCase())).map((user, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <a href="#/" className="name-with-progress max-w300 d-block" onClick={() => { getCompanyDetails(user, 'company_profile'); }} >
                                                        <div className="d-flex align-items-center">
                                                            <div className="h35w35 comman-round-box with-bg rounded-circle bg-white-05">
                                                                <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{ backgroundImage: `url('${user.profile_image_url}')`, }}></div>
                                                            </div>
                                                            <div className="ps-2 w100minus70 text-start flex-grow-1">
                                                                <div className="d-flex flex-column">
                                                                    <div className="title-fonts text-truncate text-start max-width-200 fw-semibold">
                                                                        {user.company}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </td>
                                                <td className="title-fonts text-truncate text-start max-width75">
                                                <CategoryTittles ids={user.category}/>
                                                </td>
                                                <td className="title-fonts text-truncate text-start max-width75">
                                                    {user.fullname}
                                                </td>
                                                <td>
                                                    <div className="d-flex align-items-center max-width75">
                                                        <span class="c-dots  c-7 done rounded-circle me-10px"></span>
                                                        <span className="title-fonts">Active</span>
                                                    </div>
                                                </td>
                                                <td className="title-fonts text-center">
                                                    {user.total_task}
                                                </td>
                                                <td className="title-fonts text-center">
                                                    {user.risk_score == "" ? 0 : Number(user.risk_score).toFixed(2)}
                                                </td>
                                                <td className="title-fonts text-center">
                                                    {user.space_count}
                                                </td>
                                                <td>
                                                    <div className="align-items-center comman_action_icon d-flex justify-content-center">
                                                        <UncontrolledDropdown
                                                            setActiveFromChild={true}
                                                        >
                                                        <DropdownToggle className="border-0 p-0">
                                                            <a
                                                                href="#/"
                                                                className="d-flex action_icon with_bg h32w32  position-relative on-hover-active-toggle-img "
                                                                onClick={() => {providerActionModal > -1 ? setproviderActionModal(-1) : setproviderActionModal(index)}}
                                                            >
                                                            <DotsNine
                                                                size={18}
                                                                className="c-icons "
                                                                weight="light"
                                                            />
                                                            </a>
                                                        </DropdownToggle>
                                                        <ReactTooltip />
                                                        
                                                        </UncontrolledDropdown>
                                                        {providerActionModal === index &&
                                                        <div>
                                                        <ProviderListActionModal
                                                            data ={user}
                                                            actionList={["Assignee"]}
                                                        />
                                                        </div>
                                                        }
                                                    </div>
                                                    {/* <div className="align-items-center comman_action_icon d-flex justify-content-center">
                                                        <a className="action_icon with_bg h32w32">
                                                            <DotsNine size={18} className="c-icons" weight="light" />
                                                        </a>
                                                    </div> */}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </>
                                : (!["my-clients","my-agents" ].includes(leftPanelTab)) ? <tr className="">
                                    <td className="border-0" colSpan={8}>
                                        <div className="comman-placeholder-wrapper mt-4 w-100 justify-content-center">
                                            <div className="d-flex flex-column align-items-center">
                                                <CommanPlaceholder imgType="request" placeholderText = {_l("l_no_data")} />
                                            </div>
                                        </div>
                                    </td>
                                </tr> : <React.Fragment></React.Fragment>
                               }

                                {["my-agents"].includes(leftPanelTab) && agentList && agentList.length > 0 ?
                                <>
                                    {agentList.filter((w)=> w.firstname.toLowerCase().includes(globalSearchString.toLowerCase()) || w.lastname.toLowerCase().includes(globalSearchString.toLowerCase())).map((user, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <a href="#/" className="name-with-progress max-w300 d-block" onClick={() => { }} >
                                                        <div className="d-flex align-items-center">
                                                            <div className="h35w35 comman-round-box with-bg rounded-circle bg-white-05">
                                                                <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{ backgroundImage: `url('${user.profile_image}')`, }}></div>
                                                            </div>
                                                            <div className="ps-2 w100minus70 text-start flex-grow-1">
                                                                <div className="d-flex flex-column">
                                                                    <div className="title-fonts text-truncate text-start max-width-200 fw-semibold">
                                                                        {user.company}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </td>
                                                <td className="title-fonts text-truncate text-start max-width75">
                                                    {user.firstname  + " " + user.lastname}
                                                </td>
                                                <td>
                                                    <div className="d-flex align-items-center max-width75">
                                                        <span class="c-dots  c-7 done rounded-circle me-10px"></span>
                                                        <span className="title-fonts">Active</span>
                                                    </div>
                                                </td>
                                                <td className="title-fonts text-center">
                                                    {user.total_task}
                                                </td>
                                                <td className="title-fonts text-center">
                                                    {user.risk_score == "" ? 0 : Number(user.risk_score).toFixed(2)}
                                                </td>
                                                <td className="title-fonts text-center">
                                                    {user.space_count}
                                                </td>
                                                <td>
                                                    <div className="align-items-center comman_action_icon d-flex justify-content-center">
                                                        <UncontrolledDropdown
                                                            setActiveFromChild={true}
                                                        >
                                                        <DropdownToggle className="border-0 p-0">
                                                            <a
                                                                href="#/"
                                                                className="d-flex action_icon with_bg h32w32  position-relative on-hover-active-toggle-img "
                                                                onClick={() => {providerActionModal > -1 ? setproviderActionModal(-1) : setproviderActionModal(index)}}
                                                            >
                                                            <DotsNine
                                                                size={18}
                                                                className="c-icons "
                                                                weight="light"
                                                            />
                                                            </a>
                                                        </DropdownToggle>
                                                        <ReactTooltip />
                                                        
                                                        </UncontrolledDropdown>
                                                        {providerActionModal === index &&
                                                        <div>
                                                        <ProviderListActionModal
                                                            data ={user}
                                                            actionList={["Assignee"]}
                                                        />
                                                        </div>
                                                        }
                                                    </div>
                                                    {/* <div className="align-items-center comman_action_icon d-flex justify-content-center">
                                                        <a className="action_icon with_bg h32w32">
                                                            <DotsNine size={18} className="c-icons" weight="light" />
                                                        </a>
                                                    </div> */}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </>
                                : ["my-agents"].includes(leftPanelTab)  ?<tr className="">
                                    <td className="border-0" colSpan={7}>
                                        <div className="comman-placeholder-wrapper mt-4 w-100 justify-content-center">
                                            <div className="d-flex flex-column align-items-center">
                                                <CommanPlaceholder imgType="request" placeholderText = {_l("l_no_data")} />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                : <React.Fragment></React.Fragment>
                               }
                                {["my-clients" ].includes(leftPanelTab) && clientListLocalData && clientListLocalData.length > 0 ?
                                <>
                                    {clientListLocalData.filter((w)=> w.company && w.company.toLowerCase().includes(globalSearchString.toLowerCase()) || w.contact_name && w.contact_name.toLowerCase().includes(globalSearchString.toLowerCase())).map((user, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <a href="#/" className="name-with-progress max-w300 d-block"  >
                                                        <div className="d-flex align-items-center">
                                                            <div className="h35w35 comman-round-box with-bg rounded-circle bg-white-05">
                                                                <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{ backgroundImage: `url('${user.company_logo_url}')`, }}></div>
                                                            </div>
                                                            <div className="ps-2 w100minus70 text-start flex-grow-1">
                                                                <div className="d-flex flex-column">
                                                                    <div className="title-fonts text-truncate text-start max-width-200 fw-semibold">
                                                                        {user.company}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </td>
                                                <td className="title-fonts text-truncate text-start max-width75">
                                                    {user.contact_name}
                                                </td>
                                                <td>
                                                    <div className="d-flex align-items-center max-width75">
                                                        <span class={`c-dots  c-7  rounded-circle me-10px ${user.is_active ? 'done' : 'in-progress-blue'} `}></span>
                                                        <span className="title-fonts">{user.is_active ? _l("l_active") : _l("l_past")}</span>
                                                    </div>
                                                </td>
                                                <td className="title-fonts text-center">
                                                    {user.score || 0}
                                                </td>
                                                <td className="title-fonts text-center">
                                                    {user.risk_score == "" ? 0 : Number(user.risk_score).toFixed(2)}
                                                </td>
                                                <td className="title-fonts text-center">
                                                    {user.space_count}
                                                </td>
                                                <td>
                                                    <div className="align-items-center comman_action_icon d-flex justify-content-center">
                                                        <UncontrolledDropdown
                                                            setActiveFromChild={true}
                                                        >
                                                        <DropdownToggle className="border-0 p-0">
                                                            <a
                                                                href="#/"
                                                                className="d-flex action_icon with_bg h32w32  position-relative on-hover-active-toggle-img "
                                                                // onClick={() => {providerActionModal > -1 ? setproviderActionModal(-1) : setproviderActionModal(index)}}
                                                            >
                                                            <DotsNine
                                                                size={18}
                                                                className="c-icons "
                                                                weight="light"
                                                            />
                                                            </a>
                                                        </DropdownToggle>
                                                        <ReactTooltip />
                                                        
                                                        </UncontrolledDropdown>
                                                        {providerActionModal === index &&
                                                        <div>
                                                        <ProviderListActionModal
                                                            data ={user}
                                                        />
                                                        </div>
                                                        }
                                                    </div>
                                                    {/* <div className="align-items-center comman_action_icon d-flex justify-content-center">
                                                        <a className="action_icon with_bg h32w32">
                                                            <DotsNine size={18} className="c-icons" weight="light" />
                                                        </a>
                                                    </div> */}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </>
                                : ["my-clients" ].includes(leftPanelTab) ? <tr className="">
                                    <td className="border-0" colSpan={7}>
                                        <div className="comman-placeholder-wrapper mt-4 w-100 justify-content-center">
                                            <div className="d-flex flex-column align-items-center">
                                                <CommanPlaceholder imgType="request" placeholderText = {_l("l_no_data")} />
                                            </div>
                                        </div>
                                    </td>
                                </tr> : <React.Fragment></React.Fragment>
                               }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
            {showCommanoffCanvasModal ?   
            <CommanOffCanvas
                show={showCommanoffCanvasModal}
                handleClose={() => {
                    setShowCommanoffCanvasModal(false);
                    setModalData({});
                }}
                data={modalData}
                docType={"provider_page"}
                isCommentsection={true}
                isFullScreen={true}
            />
            : <></>
            }
        </>
    );
};

export default ProviderList;
