import Accordion from "react-bootstrap/Accordion";
import React from "react";
import ReactTooltip from "react-tooltip";
import { _l } from "../../hooks/utilities";
import { Trash,NotePencil  } from "phosphor-react";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";

function ManageChecklist({ checklists, handleEdit, handleDelete }) {

  return (
    <div className="custom-accordian-main Checklistaccordian">
      <Accordion defaultActiveKey="0">
        {checklists.length ? (
          checklists.map((checklist, index) => {
            return (
              <Accordion.Item eventKey={index}>
                <div className="accordian-header-box d-flex align-content-center accordian-main-header title-fonts task-list-card list-view p-1 radius_5">
                  <Accordion.Header  onClick={() => { handleEdit(checklist)}}>
                    <div className="d-flex align-items-center justify-content-between w-100 line-height-1">
                      <div className="title-font c-font f-12 fw-semibold text-truncate w100minus70">
                        {checklist.title}
                      </div>
                    </div>
                  </Accordion.Header>
                  <div className="mlminus75 d-flex flex-grow-1 justify-content-start z-index-3">
                    <a href="#/" className="d-flex z-index-2 with_separator_10" onClick={() => { handleEdit(checklist) }}
                      data-tip={_l("l_edit")}
                      data-effect="solid"
                      data-delay-show='1000'
                      data-class="tooltip-main"
                    >
                      <NotePencil size={18}  weight="light" className="c-icons m-auto" />
                    </a>
                    <a href="#/" className="d-flex z-index-2 with_separator_10 pe-0" onClick={() => { handleDelete(checklist) }}
                      data-tip={_l("l_delete")}
                      data-effect="solid"
                      data-delay-show='1000'
                      data-class="tooltip-main"
                    >
                      <Trash size={18}  weight="light" className="c-icons m-auto" />
                    </a>
                  </div>
                  <ReactTooltip />
                </div>
              </Accordion.Item>
            );
          })
        ) : (
          <CommanPlaceholder/>
        )}
      </Accordion>
    </div>
  );
}

export default ManageChecklist;
