import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useRef, useState } from "react";
import EmailDetailViewAccordians from "./EmailDetailViewAccordians";
import { _l } from "../../../hooks/utilities";

const EmailDetailView = ({ mailToView,data,setShowLoader,id }) => {
  const [selectedTab, setSelectedTab] = useState(data && data.status == 'active' ? "" : "emailbody");


  return (
    <>
      <div className="tab-wrapper_main  res-overflow-auto">
        <div className="d-flex align-items-center justify-content-between border-bottom">
          {/* <div className="comman-tab-wrapper pb-0">
            <div className="d-flex align-items-center">
              <div
                className={`tab-wrapper task-detail-tab d-flex align-items-center ${data && data.status != 'active' ? '' : ''} ${selectedTab === "emailbody" ? "active" : ""
                  }`}
              >
                <a href="#/"
                  className="tab-name"
                  onClick={() => {
                    setSelectedTab("emailbody");
                  }}
                >
                 {_l('l_email_body')} 
                </a>
              </div>
              <div
                className={`tab-wrapper task-detail-tab d-flex align-items-center ${data && data.status != 'active' ? '' : ''} ${selectedTab === "task" ? "active" : ""
                  }`}
              >
                <a href="#/"
                  className="tab-name"
                  onClick={() => {
                    setSelectedTab("task");
                  }}
                >
                  {_l('l_task')}
                </a>
              </div>
            </div>
          </div> */}
          {/* <div className="task-header-right comman-tab-wrapper p-0 d-none">
          </div> */}
        </div>
      </div>
      <div className="comman_vertical_btn_h d-flex flex-column pt-2 h54px">
        {selectedTab === "emailbody" ? (
          <EmailDetailViewAccordians mailToView={mailToView} id={id} setShowLoader={setShowLoader} />
        ) : selectedTab === "task" ? (
          <></>
        ) : (
          <></>
        )}
      </div>
      </>
  );
};
export default EmailDetailView;
