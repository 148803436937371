import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState } from "react";
import Estimate from "../../tabs/Estimate";
import { _l } from "../../../../hooks/utilities";
import SignatureConfirmationIdentity from "../../../Modals/SignatureConfirmationIdentity";

const Estimates = ({ data, handleEstimateStatus }) => {

  const [showSignaturePad, setShowSignaturePad] = useState(false);

  return (
    <>
      <div className="tab-wrapper_main pb-3 res-overflow-auto">
        <div className="d-flex align-items-center justify-content-between border-bottom">
          <div className="comman-tab-wrapper p-15 pt-0 pb-0 ps-0">
            <div className="d-flex align-items-center">
              <div className="tab-wrapper active">
                <a href="#/" className="tab-name m-0">{_l("l_basic_details")}</a>
              </div>
            </div>
          </div>
          {/* <div
            className={`right-tabing-part for-accessible ${data && ["Sent", "Draft"].includes(data.status) ? "" : "d-none"
              }`}
          >
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
                setShowSignaturePad(true);
              }}
            >
              {_l("l_accept")}
            </Button>
            <Button
              className="mx-2"
              variant="secondary"
              size="sm"
              onClick={() => {
                documentsServices.rejectEstimate(data.hash).then((res) => {
                  if (res.status == 1) {
                    handleEstimateStatus("Declined");
                  }
                });
              }}
            >
              {_l("l_reject")}
            </Button>
          </div> */}
          {/* <div
                            className="task-header-right"
                        >
                            <div className={`d-flex align-items-center justify-content-end ${selectedTab === "paytm" ? "active" : ""
                                }`}>
                                <a href="#/" className="tab-name"
                                    onClick={() => {
                                        setSelectedTab("paytm");

                                    }}>
                                    <button type="button" className="btn btn-primary btn-sm">Pay Now</button>
                                </a>
                            </div>
                        </div> */}
        </div>
      </div>
      <div className="overflow-hiiden-auto comman_vertical_btn_h p-15">
        <Estimate data={data} />
      </div>
      {showSignaturePad ? (
        <SignatureConfirmationIdentity
          show={showSignaturePad}
          external={true}
          projectId={0}
          estimate={data}
          hash={data.hash}
          handleClose={() => {
            setShowSignaturePad(false);
          }}
          callBack={() => {
            handleEstimateStatus("Accepted");
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default Estimates;
