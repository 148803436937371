import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { _l } from "../../hooks/utilities";
import { Spinner } from "reactstrap";
import customerServices from "../../services/customer-services";
import { ArrowRight, UsersThree, X } from "phosphor-react";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { setMyrStaffList, updateMyrStaffList } from "../../actions/leads-actions/leads-actions";
import CommanLoader from "../Loader/CommanLoader";
import { isLoadingData } from "../../hooks/loader_helper";
import { showMessage } from "../../actions/messages";
import { getDefaultTopics, getTopicTasks } from "../../actions/customer";
const ManagePrjoectMember = ({
  show,
  handleClose,
  project_id,
  pageName
}) => {
  const dispatch = useDispatch();
  const [loder, setLoder] = useState(false);
  const [managePeople, setManagePeople] = useState([]);
  const [PeopleList, setPeopleList] = useState([]);
  const [searchString, setSearchString] = useState("");
  // const project_id = localStorage.getItem("selectedOffice");
  const staffList = useSelector((state) => state.leadsReducer.myrStaffList)
  useEffect(() => {
    dispatch(setMyrStaffList(project_id));
  }, []);
  useEffect(() => {
    if (staffList && staffList.length > 0) {
      const formattedResponse = staffList.map((staff) => {
        return { ...staff, is_project_member: staff.is_project_member ? staff.is_project_member : 0 };
      });
      setPeopleList(formattedResponse.sort((a, b) => parseInt(b.is_project_member) - parseInt(a.is_project_member)));
    }
  }, [staffList])


  const projectPeopleManage = (e) => {
    let newArr = PeopleList;
    let Index = newArr.findIndex((p) => p.staffid == e)
    newArr[Index].is_project_member = newArr[Index].is_project_member == 1 ? 0 : 1;
    setPeopleList(newArr.slice());
    // setFinalPeople(newArr.slice());
  }
  const addPeople = () => {
    setLoder(true);
    const list = PeopleList.filter((p) => p.is_project_member == 1);
    // const sortArr = PeopleList.sort((a, b) => parseInt(b.is_project_member) - parseInt(a.is_project_member));
    customerServices.addProjectMember(list,project_id).then((res) => {
      setLoder(false);
      if (res.status == 1) {
        dispatch(getTopicTasks(project_id, pageName));
        dispatch(getDefaultTopics(project_id, pageName));
        // dispatch(updateMyrStaffList(sortArr));
        handleClose()
        dispatch(showMessage("sucess", _l("l_success"), _l(res.message)));
      }else{
        dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
      }
    })
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style with-min-height"
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
      // scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>{_l("l_manage_project_member")}</Modal.Title>
        </Modal.Header>
   
        <Modal.Body className="pt-0 d-flex flex-column light-full-bg-modal">
          <div className="mt-15px custom-modal-section-sticky">
            <div className="d-flex align-items-center flex-grow-1">
              <Form.Group className="comment-input-box d-flex flex-grow-1">
                <Form.Control
                  className=" border-0 pe-2"
                  type="text"
                  placeholder={`${_l("l_search")}`}
                  aria-label="default input example"
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                />
              </Form.Group>
            </div>
          </div>
          <div className="col flex-grow-1 set-topic-list overflow-hiiden-auto flex-grow-1 d-flex flex-column" style={{maxHeight : "75vh"}}>
            <div className="row">
              {PeopleList.filter((sub) => sub.fullname.toLowerCase().includes(searchString.toLowerCase())).length ? (
                PeopleList.filter((sub) => sub.fullname.toLowerCase().includes(searchString.toLowerCase())).map((category, index) => {
                  const isChecked = category.is_project_member == 1 ? true : false;
                  return (
                    <div
                      key={index}
                      className={"col-xl-6"}
                    >
                      <div className={`topic-main-box hr_sepretr left-25`}>
                        <div className="">
                          <div className=" py-3  d-flex align-items-center justify-content-between">
                            <div className="topic-left-title text-truncate d-flex align-item-center flex-grow-1 w100minus120">
                              <div className="comman-image-box h25w25 rounded-circle d-flex">
                                <div
                                  className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                  style={{
                                    backgroundImage: `url('${category.profile_image_url}')`,
                                  }}
                                ></div>
                              </div>
                              <div class="w100minus40 text-truncate ps-2">
                                {_l(category.fullname)}
                              </div>
                            </div>
                            <div className="topic-switch d-flex align-items-center">
                              <Form.Check type="checkbox" className="d-none" />
                              <div className="form-check form-check-inline m-0 ps-2">
                                <Form.Check
                                  className=" with-no-label-space"
                                  type="switch"
                                  role="switch"
                                  id="flexSwitchCheckChecked"
                                  checked={isChecked}
                                  onChange={() => { projectPeopleManage(category.staffid) }}
                                />
                                <label
                                  className="form-check-label p-0"
                                  htmlFor="flexSwitchCheckChecked"
                                ></label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <>
                  <CommanPlaceholder imgType="no-user" placeholderText={`${_l("l_no")} ${_l("l_my_people")}`} />
                </>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={() => { handleClose(); }}>
            {_l("l_close")}
          </Button>
          <Button variant="primary" disabled={loder} size="sm" onClick={() => {
            addPeople()
          }}>
            {loder ? _l('l_please_wait') : _l('l_save')}
            {loder && <Spinner as="span" animation="border" size="sm" role="status" className={`ms-2`} aria-hidden="true" />}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManagePrjoectMember;
