import React, { useEffect, useState } from 'react'
import googleDriveServices from '../../services/google-drive-services'
import onedriveServices from '../../services/onedrive-services'
import { _l, showError, showLeftPanelNew } from '../../hooks/utilities'
import GeneralCardView from '../Documents/GeneralCard'
import { Button } from 'react-bootstrap'
import customerServices from '../../services/customer-services'
import { OUTLOOK_OAUTH_URL } from '../../constants/constants'
import CustomGoogleIcon from '../../assets/icons/CustomGoogleIcon'
import CustomOutlookIcon from '../../assets/icons/CustomOutlookIcon'
import { useNavigate } from 'react-router-dom'
import { emailTokenExpired } from '../../actions/customer'
import { useDispatch } from 'react-redux'

const ConnectEmails = () => {
    const providers = [
        {
            name: "G Mail",
            logo: CustomGoogleIcon,
            type: "mail",
            tool: "Google",
          },
          {
            name: "Outlook",
            logo: CustomOutlookIcon,
            type: "mail",
            tool: "Outlook",
          },
      ]
    const [cardData, setCardData] = useState(providers)
    const navigate = useNavigate();
    const dispatch = useDispatch();
   
    const revokeToolAccessKey = async () => {
        customerServices.revokeMailSync("gmail").then((res) => {
            if (res.status) {
            window.dispatchEvent(new Event("mailSyncRevoked"));
            setStaticIntegrationCards();
            }
        }); 
    }

    const setStaticIntegrationCards = async () => {
        let cardDetais = providers
        let isSynced = "";
        isSynced = await checkEmailSync();
        cardDetais.forEach((tool) => {
            if (tool.name  == "G Mail") {
            tool.connected = isSynced != "" && isSynced == "gmail";
            } else if(tool.name == "Outlook"){
            tool.connected = isSynced != "" && isSynced == "Outlook";
            }
        });
        if (isSynced !== "") {
            navigate("/MyEmail")
            showLeftPanelNew();

        }
        
        setCardData(cardDetais);
    };

    const checkEmailSync = async () => {
        let syncString = "";
        try {
          const response = await customerServices.getMailSettings();
          if (response.status) {
            response.data.map((item) => {
              if (item["provider"] == "gmail") {
                syncString = "gmail";
              } else if (item["provider"] == "outlook_mail") {
                syncString = "outlook_mail";
              }
            });
          }
        } catch (e) {
          console.error(e);
        } finally {
          return syncString;
        }
      };

    const connectToThirdPartyTool = async (tool, type) => {
    const left = (window.screen.width - 600) / 2;
    const top = (window.screen.height - 600) / 4;
    switch (type) {
        case "mail":
        if (tool === "Outlook") {
            window.open(
            OUTLOOK_OAUTH_URL,
            "center window",
            "resizable = yes, width=600, height=600, top=" +
                top +
                ", left=" +
                left
            );
        } else {
            try {
            const response = await customerServices.getMailSettings();
            if (response.data) {
                startGmailConfiguration(response,top,left)
                //  window.open(
                //   response.data,
                //   "center window",
                //   "resizable = yes, width=600, height=600, top=" +
                //     top +
                //     ", left=" +
                //     left
                // );

            }
            } catch (e) {
            console.error(e);
            showError("l_something_went_wrong");
            }
        }
        break;
        default:
        break;
    }
    };

    const startGmailConfiguration = (response,top,left) => {
        openGmailConfigWindow(response,top,left);
    };

    const openGmailConfigWindow = (response,top,left) => {
        const configWindow =window.open(
          response.data,
          "center window",
          "resizable = yes, width=600, height=600, top=" +
            top +
            ", left=" +
            left
        );
        const checkWindowClosed = setInterval(() => {
            if (configWindow.closed) {
                clearInterval(checkWindowClosed);
                handleWindowClosed(); 
                dispatch(emailTokenExpired(false));
            }
        }, 1000);
    };

    const handleWindowClosed = () => {
        setStaticIntegrationCards("third_party_email", "l_email", true);
    };

    useEffect(() => {
        setStaticIntegrationCards()
    }, [])
    

    return (
      <React.Fragment>
       
          <div className='schedule-content-wrapper h-100 d-flex flex-column px-0 pb-0 radius_3 pt-10px  d-flex flex-column    '>
            <div className=' d-flex flex-column h-100 flex-grow-1 backdroplighttheme '>
              <div className='comman-main-box custom-accordian-main res-padding-right0 overflow-auto flex-grow-1 h-100 task-list-accordian   '>
                <div className='task-list-accordian-main with_checkbox_absolute mt-0 w-800px my-0 padding-bottom-60px daily-schedule-main accordion'>
                <div className=' d-flex  align-items-center justify-content-between position-sticky top-0 z-index-7  list-view p-10 rounded-0 shadow-none'>
                  <div className='c-font color-white-80 f-12 title-fonts'>
                    {_l("l_email") + ' ' + `[${cardData.length}]`}
                  </div>
                </div>
                  {cardData.map((item, index) => {
                    return(
                      <GeneralCardView
                        key={index}
                        cardTitle={item.name}
                        ownerDetails={{
                          name: item.name,
                          profile_image: item.logo,
                        }}
                        pills={[]}
                        onClick={() => {}}
                        people={[]}
                        hideChatAndCall
                        ActionButton={
                          item.connected ? (
                            <React.Fragment>
                              {item.type == "" ? (
                                <></>
                              ) : (
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => {
                                    revokeToolAccessKey(
                                      item
                                    );
                                  }}
                                >
                                  {_l("l_revoke")}
                                </Button>
                              )}
                            </React.Fragment>
                          ) : (
                            <div className="d-flex">
                              {item.type ===
                              "calendar" ? (
                                <div class="form-check with_separator_10 d-none">
                                  <input
                                    id="isAllowAllSpaces"
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={
                                      item.all_spaces
                                    }
                                    onChange={(e) => {
                                      // setListData({
                                      //   ...listData,
                                      //   [key]:
                                      //     Object.values(
                                      //       {
                                      //         ...listData[
                                      //           key
                                      //         ],
                                      //         [index2]:
                                      //           {
                                      //             ...listData[
                                      //               key
                                      //             ][
                                      //               index2
                                      //             ],
                                      //             all_spaces:
                                      //               e
                                      //                 .target
                                      //                 .checked,
                                      //           },
                                      //       }
                                      //     ),
                                      // });
                                    }}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    {_l(
                                      "l_is_allow_for_all_spaces"
                                    )}
                                  </label>
                                </div>
                              ) : (
                                <React.Fragment></React.Fragment>
                              )}
                              <div className="with_separator2_10 ps-0 me-10px"></div>
                              <Button
                                variant="secondary"
                                size="sm"
                                className=""
                                onClick={() => {
                                  connectToThirdPartyTool(
                                    item.tool,
                                    item.type
                                  );
                                }}
                              >
                                {_l("l_connect")}
                              </Button>
                            </div>
                          )
                        }
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        
      </React.Fragment>
      );
}

export default ConnectEmails