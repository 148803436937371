import moment from "moment";
import {
  dataURItoBlob,
  formateDate,
  getBenchmarkProjectId,
  getClientIdByProject,
  getContactIdBySpace,
  isArrayofObjects,
  newFilterFormate,
} from "../hooks/utilities";
import store from "../store";
import jquery from "jquery";
import apiService from "./api-service/api-service";
import { clearDatabase } from "../hooks/indexed_db_helpers";

const API_URL = process.env.REACT_APP_API_URL;
const MYR_BASE_URL = process.env.REACT_APP_MYR_WEB_URL;
const CHAT_WEB_BASE_URL = process.env.REACT_APP_CHAT_WEB_BASE_URL;
const user_type = localStorage.getItem("user_type");

const addeditProjectLease = (
  project_id,
  leaseData,
  leaseDocuments,
  otherDocuments,
  lease_id
) => {
  const endPoint = API_URL + "mod=lease&act=add_edit_project_lease";
  let form = new FormData();

  form.append("project_id", project_id);

  form.append(
    "start_date",
    moment(leaseData.leaseStartDate).format("YYYY-MM-DD")
  );
  form.append("end_date", moment(leaseData.leaseEndDate).format("YYYY-MM-DD"));
  form.append("lease_type", leaseData.isRentingorOwning == "renting" ? 1 : 2);
  form.append("termination_condition", leaseData.termandCondition.trim());
  form.append(
    "payment_type",
    leaseData.isMonthlyOrAnnualli == "monthly" ? 1 : 2
  );
  form.append("amount", leaseData.amount);

  if (leaseData.noticeDates) {
    leaseData.noticeDates
      .filter((date) => date.notice_date != "")
      .map((date, index) => {
        form.append(
          `notice_dates[${index}]`,
          moment(date.notice_date).format("YYYY-MM-DD")
        );
      });
  }

  if (leaseDocuments) {
    Array.from(leaseDocuments).map((file, index) => {      
        if (file.is_contract_file != 0 && file.is_contract_file != 1) {
            form.append(`lease_contract[${index}]`, file);
        }
    });
  }

  if (otherDocuments) {
    Array.from(otherDocuments).map((file, index) => {
        if (file.is_contract_file != 0 && file.is_contract_file != 1) {
      form.append(`file[${index}]`, file);
        }
    });
  }

  if (lease_id > 0) {
    form.append("lease_id", lease_id);
  }

  return apiService(endPoint, "POST", true, form);
};

const getProjectLease = (project_id, lease_id = 0) => {
  const endPoint = API_URL + "mod=lease&act=get_project_lease";
  let form = new FormData();
  form.append("project_id", project_id);
  if (lease_id > 0) {
    form.append("lease_id", lease_id);
  }

  return apiService(endPoint, "POST", true, form);
};

const getLeaseTopics = (project_id, lease_id, lease_sub_id) => {
  const endPoint = API_URL + "mod=lease&act=get_all_topic";
  let form = new FormData();
  if (lease_id > 0) {
    form.append(`lease_id`, lease_id);
  }
  if (lease_sub_id > 0) {
    form.append(`lease_sub_id`, lease_sub_id);
  }
  form.append("project_id", project_id);

  return apiService(endPoint, "POST", true, form);
};

const deleteLeaseDocument = (project_id,lease_id, file_id, lease_sub_id = 0) => {
  const endPoint = API_URL + "mod=lease&act=delete_lease_document";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("lease_id", lease_id);
  form.append("file_id", file_id);
  if (lease_sub_id > 0) {
    form.append(`lease_sub_id`, lease_sub_id);
  }

  return apiService(endPoint, "POST", true, form);
};

const getLeaseChartData = (project_id,lease_id, lease_sub_id = 0) => {
  const endPoint = API_URL + "mod=lease&act=get_lease_chart";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("lease_id", lease_id);
  if (lease_sub_id > 0) {
    form.append(`lease_sub_id`, lease_sub_id);
  }

  return apiService(endPoint, "POST", true, form);
};

const updateLeaseTopic = (project_id, topicData, lease_id, lease_sub_id = 0) =>{
  const endPoint = API_URL + "mod=lease&act=change_lease_topic_status";
  let form = new FormData();
  form.append(`project_id`, project_id);
  form.append(`lease_id`, lease_id);
  form.append(`topic_data`, JSON.stringify(topicData));
  if (lease_sub_id > 0) {
    form.append(`lease_sub_id`, lease_sub_id);
  }

  return apiService(endPoint, "POST", true, form);

}

const addeditProjectSubLease = (  project_id,
  leaseData,
  leaseDocuments,
  otherDocuments,
  lease_id,
  lease_area,
  resident_staff_id = 0,
  lease_sub_id
) =>{
  const endPoint = API_URL + "mod=lease&act=add_edit_project_sub_lease";
  let form = new FormData();
  form.append("project_id", project_id);

  form.append(
    "start_date",
    moment(leaseData.leaseStartDate).format("YYYY-MM-DD")
  );
  form.append("end_date", moment(leaseData.leaseEndDate).format("YYYY-MM-DD"));
  form.append("lease_type", leaseData.isRentingorOwning == "renting" ? 1 : 2);
  form.append("termination_condition", leaseData.termandCondition.trim());
  form.append(
    "payment_type",
    leaseData.isMonthlyOrAnnualli == "monthly" ? 1 : 2
  );
  form.append("amount", leaseData.amount);

  if (resident_staff_id > 0) {
    form.append("resident_staff_id", resident_staff_id);
  }

  if (lease_sub_id > 0) {
    form.append("lease_sub_id", lease_sub_id);
  }

  form.append("lease_area", JSON.stringify(lease_area));

  if (leaseData.noticeDates) {
    leaseData.noticeDates
      .filter((date) => date.notice_date != "")
      .map((date, index) => {
        form.append(
          `notice_dates[${index}]`,
          moment(date.notice_date).format("YYYY-MM-DD")
        );
      });
  }

  if (leaseDocuments) {
    Array.from(leaseDocuments).map((file, index) => {      
        if (file.is_contract_file != 0 && file.is_contract_file != 1) {
            form.append(`lease_contract[${index}]`, file);
        }
    });
  }

  if (otherDocuments) {
    Array.from(otherDocuments).map((file, index) => {
        if (file.is_contract_file != 0 && file.is_contract_file != 1) {
      form.append(`file[${index}]`, file);
        }
    });
  }

  if (lease_id > 0) {
    form.append("lease_id", lease_id);
  }

  return apiService(endPoint, "POST", true, form);

}

const getProjectSubLease = (project_id, lease_id, lease_sub_id = 0) =>{
  const endPoint = API_URL + "mod=lease&act=get_project_sub_lease";
  let form = new FormData();
  form.append(`project_id`, project_id);
  form.append(`lease_id`, lease_id);
  if (lease_sub_id > 0) {
    form.append(`lease_sub_id`, lease_sub_id);
  }

  return apiService(endPoint, "POST", true, form);

}
const cancelLeaseContract = (project_id, lease_id, sub_lease_id = 0) =>{
  const endPoint = API_URL + "mod=lease&act=cancel_lease_contract";
  let form = new FormData();
  form.append(`project_id`, project_id);
  form.append(`lease_id`, lease_id);
  if (sub_lease_id > 0) {
    form.append(`sub_lease_id`, sub_lease_id);
  }

  return apiService(endPoint, "POST", true, form);
}

export default {
  addeditProjectLease,
  getProjectLease,
  getLeaseTopics,
  deleteLeaseDocument,
  getLeaseChartData,
  updateLeaseTopic,
  addeditProjectSubLease,
  cancelLeaseContract,
  getProjectSubLease
};
