import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CustomerServices from "../../services/customer-services";
import { useSelector, useDispatch } from "react-redux";
import Toasts from "../Toasts/Toasts";
import { _l, showError } from "../../hooks/utilities";

import {
  getWalletSettings,
} from "../../actions/customer";
import { useNavigate } from "react-router-dom";
import { showMessage } from "../../actions/messages";
import Select from "react-select";

const WalletSetting = forwardRef(({formView, show, handleClose, proposalData, setNextStep, nextStep, walletSettingData, setWalletSettingData, categoryId, regionId ,fromTopicSettingModal, setUnsavedWalletSettings, isEdit, setLoadingNextStep }, ref) => {
  const { walletDetails } = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const [toastClass, setToastClass] = useState("unsucess");
  const [header, setHeader] = useState("");
  const [message, setMessage] = useState("");
  const [validationFalse, setValidationFalse] = useState(false);
  const [walletType, setWalletType] = useState(2);
  const [walletStatus, setWalletStatus] = useState(1);
  const [accountType, setAccountType] = useState(1);
  const [systemInvoices, setSystemInvoices] = useState(1);
  const [invoices_iban, setInvoices_iban] = useState('');
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const userType = localStorage.getItem("user_type");
  const [vatNumber, setVatNumber] = useState("");
  const [siren, setSiren] = useState("");
  const [capitalSocial, setCapitalSocial] = useState("");
  const [tax, setTax] = useState('0');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categoryListing, setCategoryListing] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [walletRegions, setWalletRegions] = useState([]);
  const [walletLabel, setWalletLabel] = useState("");

  const taxes = [
    {value : '0',label: _l('l_no_tax')},
    {value : '20',label: _l('20%')},
    // {value : '30',label: _l('30%')}
  ];


  const projectId = localStorage.getItem("selectedOffice");
  const navigate = useNavigate();

  const validWalletSettings = () => {
    let flag = true;
    if(!selectedCategories.length)
    {
      showError("l_please_select_category");
      flag = false;
    }
    return flag;
  };

  const editWalletSettings = async() => {
    try {
      setLoadingNextStep(true);
      await CustomerServices.updateProviderCategoryWallet(walletSettingData.id, walletSettingData.projects.map((w) => w.value), selectedCategories.map((w) => w.value), walletRegions.map((w) => w.value), walletLabel);
    }
    catch(e)
    {
      console.error(e);
    }
    finally {
      handleClose();
    }
  };

  const setWalletSettings = () => {
    if(setWalletSettingData)
    {
      if(validWalletSettings())
      {
        setWalletSettingData({
          terms: termsAndConditions,
          vat_number: vatNumber,
          capital_social: capitalSocial,
          invoice_tax: tax.value,
          categories: selectedCategories.map((w) => w.value),
          region_ids: walletRegions.map((w) => w.value),
          wallet_title: walletLabel
        });
        if(isEdit)
        {
          editWalletSettings();
        }
        else {
          setNextStep(nextStep);
        }
      }
    }
    else {
      CustomerServices.setWalletSettings(
        walletType,
        walletStatus,
        accountType,
        systemInvoices,
        invoices_iban,
        termsAndConditions,
        vatNumber,
        siren,
        capitalSocial,
        tax.value
      ).then((res) => {
        if (res.status) {
          handleClose();
          if(!res.data.account){
            if(formView)
            {
              setNextStep(nextStep);
            }
            else {
              navigate("/paymentpage", {state: {proposalData}, replace: true});
            }
          }
        } else {
          // setToastClass("unsucess");
          // setHeader("Error");
          // setMessage(res.message)
          // setValidationFalse(true)
          dispatch(showMessage('unsucess', _l("l_oops"), res.message));
        }
      });
    }
  };

  const getOperatorCompanyAccountList = async() => {
    try {
      const response = await CustomerServices.getProviderCompanyAccounts();
      const categoryResponse = await CustomerServices.getProviderCategoriesForWallet();

      if(response.status && categoryResponse.status)
      {
        const categories = categoryResponse.data.filter((category, index, self) => category.name && self.findIndex((w) => w.name == category.name) === index).map((w) => {
          return {value: w.provider_id , label: w.name}
        });
        categories.sort((a, b) => a.label.localeCompare(b.label));

        const benchmarkCategory = categoryId > 0 ? categories.find((w) => w.value == categoryId) : undefined;

        if(!response.data.length)
        {
          setSelectedCategories(categories);
        }
        else if(benchmarkCategory)
        {
          setSelectedCategories([benchmarkCategory]);
        }

        setCategoryListing(categories);

        setTermsAndConditions(response.extra.wallet_default_terms);
        setVatNumber(walletSettingData.vat_number);
        setCapitalSocial(walletSettingData.capital_social);
      }
      else {
        showError(response.message);
      }
    }
    catch(e)
    {
      console.error(e);
    }
  };

  const WalletForm = (
    <div className="form-wrapper-main">
      <div className="row">
        <div className="col-xl-12 mb-3">
          <Form.Group className=" c-input-box ">
            <Form.Label className="input-label no-asterisk ">
              {_l("l_terms_and_conditions")}
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={formView ? 4 : 2}
              placeholder={`${_l("l_terms_and_conditions")}`}
              value={termsAndConditions}
              disabled={isEdit}
              onChange={(e) => {
                setUnsavedWalletSettings(true);
                setTermsAndConditions(e.target.value);
              }}
            ></Form.Control>
          </Form.Group>
        </div>
        {!formView ? (
          <Form.Group className="col-xl-12 c-input-box pb-4 position-relative">
            <Form.Label className="input-label no-asterisk">
              {_l("l_invoices_iban")}
            </Form.Label>
            <Form.Control
              placeholder={`${_l("FR1420041010050500013M02606")}`}
              type="text"
              name=""
              value={invoices_iban}
              onChange={(e) => {
                setUnsavedWalletSettings(true);
                setInvoices_iban(e.target.value);
              }}
            />
          </Form.Group>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        {userType == "operator" ? (
          <>
            <Form.Group className={`c-input-box pb-4 position-relative  ${fromTopicSettingModal ? "col-xl-6" : "col-xl-12"}`}>
              <Form.Label className="input-label no-asterisk">
                {_l("l_vat_number")}
              </Form.Label>
              <Form.Control
                placeholder=""
                type="text"
                name=""
                value={vatNumber}
                disabled={isEdit}
                onChange={(e) => {
                  setVatNumber(e.target.value);
                  setUnsavedWalletSettings(true);
                }}
              />
            </Form.Group>
            {!formView ? (
              <Form.Group className="col-xl-12 c-input-box pb-4 position-relative">
                <Form.Label className="input-label no-asterisk">
                  {_l("l_siren_number")}
                </Form.Label>
                <Form.Control
                  placeholder={`${_l("l_siren_number_placeholder")}`}
                  type="text"
                  name=""
                  value={siren}
                  onChange={(e) => {
                    setSiren(e.target.value);
                    setUnsavedWalletSettings(true);
                  }}
                />
              </Form.Group>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            <Form.Group className={`c-input-box pb-4 position-relative ${fromTopicSettingModal ? "col-xl-6" : "col-xl-12"}`}>
              <Form.Label className="input-label no-asterisk">
                {_l("l_capital_social")}
              </Form.Label>
              <Form.Control
                placeholder=""
                type="text"
                name=""
                value={capitalSocial}
                disabled={isEdit}
                onChange={(e) => {
                  setCapitalSocial(e.target.value);
                  setUnsavedWalletSettings(true);
                }}
              />
            </Form.Group>
            <Form.Group className="col-xl-6 c-input-box pb-4 position-relative">
              <Form.Label className="input-label no-asterisk">
                {_l("l_wallet_regions")}
              </Form.Label>
                <Select
                  placeholder={_l("l_wallet_regions")}
                  className="custom-select-menu z-index-12"
                  classNamePrefix="react-select"
                  options={regionList}
                  value={walletRegions}
                  onChange={(value) => {updateWalletRegions(value); setUnsavedWalletSettings(true);}}
                  isMulti
                />
            </Form.Group>
            <Form.Group className="col-xl-6 c-input-box pb-4 position-relative">
              <Form.Label className="input-label no-asterisk">
                {_l("l_wallet")}
              </Form.Label>
              <Form.Control
                placeholder={`${_l("l_wallet")}`}
                type="text"
                name=""
                value={walletLabel}
                onChange={(e) => {setWalletLabel(e.target.value); setUnsavedWalletSettings(true);}}
              />
            </Form.Group>
          </>
        ) : (
          <></>
        )}
        <Form.Group className="col-xl-6 c-input-box pb-4 position-relative">
          <Form.Label className="input-label no-asterisk">
            {_l("l_invoices_tax")}
          </Form.Label>
          <Select
            className="custom-select-menu"
            classNamePrefix="react-select"
            placeholder={`${_l("l_tax")}`}
            isDisabled={isEdit}
            options={taxes}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            closeMenuOnSelect={true}
            value={tax}
            // menuPlacement="top"
            onChange={(value) => {
              setTax(value);
              setUnsavedWalletSettings(true);
            }}
          />
        </Form.Group>
        <Form.Group className="col-xl-6 c-input-box pb-4 position-relative">
          <Form.Label className="input-label no-asterisk ">
            {" "}
            {_l("l_select_category")}
          </Form.Label>
          <Select
            placeholder={_l("l_select_category")}
            className="custom-select-menu"
            classNamePrefix="react-select"
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            options={categoryListing}
            value={selectedCategories}
            onChange={(option) => {setSelectedCategories(option); setUnsavedWalletSettings(true);}}
            isMulti
          />
        </Form.Group>
      </div>
    </div>
  );

  const updateWalletRegions = (value) => {
    const mandatoryRegion = regionList.find((w) => w.value == regionId);
    let currentRegions = value;

    if(mandatoryRegion && !value.find((w) => w.value == regionId))
    {
      currentRegions = currentRegions.concat([mandatoryRegion]);
    }

    setWalletRegions(currentRegions);
  };

  useImperativeHandle(ref, () => ({
    setWalletSettings: setWalletSettings,
  }));

  useEffect(()=>{
    setInvoices_iban(walletDetails['invoices_iban']);
    setSystemInvoices(walletDetails['system_invoices'] == 1 ? 1 : 0);
    setWalletStatus(walletDetails['is_stripe_active'] == 1 ? 1 : 0);
    if(userType == "operator" && !setWalletSettingData && (!walletSettingData || !Object.keys(walletSettingData).length)){
      setTermsAndConditions(walletDetails['terms_and_conditions']);
      setVatNumber(walletDetails['vat_number']);
      setSiren(walletDetails['siren']);
      setCapitalSocial(walletDetails['capital_social']);
      setTax(
        taxes.filter((code) => {
          if(code.value == walletDetails['invoices_tax'] )
          {
            return {
              value: code.value,
              label: code.label,
            };
          }
        })
      );
    }
  },[walletDetails]);

  useEffect(() => {
    dispatch(getWalletSettings());
  }, [projectId]);

  useEffect(() => {

    const getRegionList = async() => {
      try {
        const response = await CustomerServices.getRegionList();
        if(response.status)
        {
          const regions = response.data.map((region) => {
            return {
              label: region.name,
              value: region.id,
            };
          })
          setRegionList(regions);
          if(!isEdit)
          {
            setWalletRegions(regions.filter((w) => w.value == regionId));
          }
        }
      }
      catch(e)
      {
        console.error(e);
      }
    }

    getOperatorCompanyAccountList();
    getRegionList();

    return () => {
      if(setWalletSettingData)
      {
        setWalletSettingData({
          terms: termsAndConditions,
          vat_number: vatNumber,
          capital_social: capitalSocial,
          invoice_tax: tax.value,
          categories: selectedCategories.map((w) => w.value),
          region_ids: walletRegions.map((w) => w.value),
          wallet_title: walletLabel
        });
      }
    }

  }, []);

  useEffect(() => {
    if(isEdit && walletSettingData && walletSettingData.wallet_title)
    {
      setWalletLabel(walletSettingData.wallet_title);
      setSelectedCategories(walletSettingData.categories);
      setWalletRegions(walletSettingData.region_ids);
      setTax(taxes.find((w) => w.value == walletSettingData.invoice_tax));
    }

  }, [walletSettingData])
  

  return (
    <>
      <div>
        {
          formView 
            ?
            <React.Fragment>
             {WalletForm}
            </React.Fragment>
            :
          <Modal
            show={show}
            onHide={handleClose}
            className="custom-modal-style"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>{_l('l_wallet_setting')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className=" py-0">
             {WalletForm}
            </Modal.Body>
            <Modal.Footer className="border-0 justify-content-end">
              <Button variant="secondary" size="sm" onClick={handleClose}>
              {_l('l_close')}
              </Button>
              <Button variant={`primary`} size="sm" onClick={() => { setWalletSettings(); }} >{_l('l_submit')}</Button>
            </Modal.Footer>
          </Modal>
        }
      </div>
      {validationFalse ? (
        <Toasts
          message={message}
          header={header}
          toastClass={toastClass}
          delay={10000}
          handler={() => {
            setValidationFalse(false);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
});

export default WalletSetting;
