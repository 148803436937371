import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CommanOffCanvas from "../Offcanvas/CommanOffCanvas";
import DocumentsServices from "../../services/documents-services";
import CustomerServices from "../../services/customer-services";
import LeftSideComponent from "./LeftSideComponent";
import {
  _l,
  createGuestProfile,
  setPageMetaDataDescription,
  showError,
} from "../../hooks/utilities";
import { useSelector, useDispatch } from "react-redux";
import {
  GET_DEFAULT_TOPICS,
  GET_FLOOR_DOCUMENT,
  GET_SELECTED_TASK,
  LOGIN_SUCCESS,
} from "../../actions/action-type";
import {
  GET_ESTIMATE_DETAILS,
  GET_INVOICE_DETAILS,
  GET_SUBSCRIPTION_DETAILS,
} from "../../actions/documents-actions/documents-action-types";
import { setDocumentType } from "../../actions/documents-actions/documents-actions";
import { SetSelectedTask, getCustomerSpaces, getTeams } from "../../actions/customer";
import DocumentModal from "../Modals/DocumentModal";
import TopicSettingModal from "../Modals/TopicSettingModal";
import CommanLoader from "../Loader/CommanLoader";
import BenchmarkServices from "../../services/benchmark-services";
import { fetchAuthToken } from "../../actions/chat-auth";
import { connectToSocket, fetchUnreadMsgCount } from "../../actions/chat";
import AlertModal from "../Modals/AlertModal";
import GoToLoginModal from "../Modals/GoToLoginModal";
const Main = () => {
  const { id, page } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState("");
  const [dataState, setDataState] = useState();
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [docModalToggle, setDocModalToggle] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [modalDocType, setModalDocType] = useState("");
  const [dashBoardDocRes, setDashBoardDocRes] = useState({});
  const [isExternal, setIsExternal] = useState(true);
  const [contacts, setContacts] = useState([]);
  const externalMentionsList = useSelector(
    (state) => state.documentsReducer.mentionList
  );
  const [loader, setLoader] = useState(false);

  const getBenchmarkDetailAndLogin = async() => {
    try {
      setLoader(true);
      const response = await BenchmarkServices.getBechmarkDetailsAndLogin(id);
      const client_id = response.data.client_id;
      const contact_id = response.data.contact_id;
      const staff_id = response.data.staff_id;

      localStorage.setItem("client_id", client_id);
      localStorage.setItem("contact_id", contact_id);
      localStorage.setItem("staff_id", staff_id);

      await dispatch(getCustomerSpaces(client_id, contact_id));

      if(response.status)
      {
        const benchmarkData = response.data;
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { data:response },
        });
        setTimeout(() => {
          // Get chat auth token
          const accessToken = localStorage.getItem("accessToken");
          if (accessToken != "" && accessToken != null) {
            const email = localStorage.getItem("email");
            const contact_id = localStorage.getItem("contact_id");
            dispatch(fetchAuthToken(email, contact_id, "client"));
            dispatch(getTeams());
          }
          
          // Connet to socket and fetch chat unread message count
          setTimeout(() => {
            const chatAccessToken = localStorage.getItem("chatAccessToken");
            if (chatAccessToken != "" && chatAccessToken != null) {
              dispatch(connectToSocket());
              var chatUserId = localStorage.getItem("chatUserId");
              var projectId = localStorage.getItem("selectedOffice");
              dispatch(fetchUnreadMsgCount(chatUserId, projectId));
            }
          }, 1000);
        }, 1500);
        
        navigate(
          `/connect-provider?space=${response.extra.benchmark_project_id}&provider-type=benchmark&id=${response.extra.external_link_benchmark_id}`
        );
      }
      else {
        showError(response.message);
        window.location.href = process.env.REACT_APP_MYR_WEB_URL;
      }
    }
    catch(e)
    {
      showError("l_something_went_wrong");
      window.location.href = process.env.REACT_APP_MYR_WEB_URL;
    }
    finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    const guestId = localStorage.getItem("guest_id");
    if(page === "benchmark")
    {
      getBenchmarkDetailAndLogin();
    }
    else if (!localStorage.getItem("accessToken")) {
      createGuestDetails();
    } else if (localStorage.getItem("accessToken") && data && page) {
      setIsExternal(false);
      switch (page) {
        case "task":
        case "request-task":
          navigate(`/`);
          dispatch({
            type: GET_SELECTED_TASK,
            payload: { data: data },
          });
          break;
        case "estimate":
          navigate(`/documents`);
          dispatch(setDocumentType(page));
          dispatch({
            type: GET_ESTIMATE_DETAILS,
            payload: data.data,
          });
        case "subscription":
          // navigate(`/`);
          dispatch(setDocumentType(page));
          dispatch({
            type: GET_SUBSCRIPTION_DETAILS,
            payload: data.data,
          });
          break;
        case "invoice":
          navigate(`/documents`);
          dispatch(setDocumentType(page));
          dispatch({
            type: GET_INVOICE_DETAILS,
            payload: data.data,
          });
        case "spaceDocument":
          navigate(`/documents`);
          dispatch(setDocumentType(page));
          dispatch({
            type: GET_FLOOR_DOCUMENT,
            payload: data.data,
          });
          break;
        case "dashboardDocuments":
          localStorage.setItem("searchParamUserId", id)
          if (id === localStorage.getItem("user_hash_id")) {
            navigate(`/dashboard`)
          }
          break;
        case "benchmark":
          getBenchmarkDetailAndLogin();
          break;
        default:
          break;
      }
    }
  }, [id, page, data]);
  useEffect(() => {
    fetchDocumentDetails();
  }, [id, page]);

  // useEffect(() => {
  //   if (localStorage.getItem("accessToken") && data && page == "task") {
  //     navigate(`/${page == "task" ? "" : page}`);
  //     setIsExternal(false)
  //     dispatch({
  //       type: GET_SELECTED_TASK,
  //       payload: {data:data},
  //     });
  //   }
  // },[dataState,page])
  useEffect(() => {
    if (dataState && !localStorage.getItem("accessToken")) {
      CustomerServices.getDefaultTopic(
        dataState.project_id,
        "",
        dataState.user_client_id,
        dataState.user_id,
        dataState.user_type,
        1
      ).then((data) => {
        if (data.status == 1) {
          dispatch({
            type: GET_DEFAULT_TOPICS,
            payload: data.data.topics,
          });
        }
      });
    }
  }, [JSON.stringify(dataState)]);

  const fetchDocumentDetails = async () => {
    try {
      let response;
      switch (page) {
        case "subscription":
          response = await DocumentsServices.getSubscriptionDetail(0, 0, id);
          break;
        case "invoice":
          response = await DocumentsServices.getInvoiceDetail(0, 0, id);
          break;
        case "estimate":
          response = await DocumentsServices.getEstimateDetail(0, 0, id);
          break;
        case "task":
          response = await CustomerServices.getTaskData(0, 0, null, id);
          dispatch(SetSelectedTask(response.data));
          break;
        case "request-task":
          response = await CustomerServices.getRequestedTaskData(0, 0, id);
          dispatch(SetSelectedTask(response.data));
          break;
        case "credit_note":
          response = await DocumentsServices.getCreditNoteDetail(0, 0, id);
          break;
        case "spaceDocument":
          response = await CustomerServices.getSpaceDocument(id, 1);
          break;
        case "dashboardDocuments":
          response = await DocumentsServices.getDocumentsForDashboard(1,id, 0);
          break;
        default:
          break;
      }
      if (response && response.status == 1 && response.data) {
        // if (!externalMentionsList) {
        // dispatch(getMentionList(response.data.id, page, 1));
        // }
        setData(response);
        setDataState(response.data);
        setPageMetaDataDescription(
          `${response.data.id} - #${response.data.name}`
        );
        setShowOffCanvas(true);
        setContacts(response.data.contacts ? response.data.contacts : []);
        if (page === "dashboardDocuments") {
          setDashBoardDocRes(response)
        }
      } else if (response && response.status === 0 && response.data.status === 2) {
        setShowAlertModal(true)
      } else {
        showError(_l(response.message))
      }
    } catch (e) {
      console.error(e);
    }
  };
  const createGuestDetails = async (guestId) => {
    try {
      const guestProfile = await createGuestProfile();
      localStorage.setItem("full_name", guestProfile.name);
      localStorage.setItem("contact_image", guestProfile.avtar_url);
      localStorage.setItem("guest_id", guestProfile.id);
      if (id) {
        localStorage.setItem("searchParamUserId", id)        
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const docToggle = async (documentData) => {
    try {
      let response;
      switch (documentData.type) {
        case "subscription":
          response = await DocumentsServices.getSubscriptionDetail(0, 0, documentData.hash);
          break;
        case "invoice":
          response = await DocumentsServices.getInvoiceDetail(0, 0, documentData.hash, documentData.is_app_invoice);
          break;
        case "estimate":
          response = await DocumentsServices.getEstimateDetail(0, 0, documentData.hash);
          break;
        case "credit_note":
        response = await DocumentsServices.getCreditNoteDetail(0, 0, documentData.hash, documentData.is_app_credit_note);
        break;
        case "spaceDocument":
          response = await CustomerServices.getSpaceDocument(documentData.hash, 1);
          break;
        case "dashboardDocuments":
          response = dashBoardDocRes
          break;
        default:
          break;
      }
      if (response && response.status == 1 && response.data) {     
        setModalDocType(documentData.type)
        setDataState(response.data);
        setPageMetaDataDescription(
          `${response.data.id} - #${response.data.name}`
        );
        if (documentData.type != "dashboardDocuments") {
          setDocModalToggle(true);
        }
        setContacts(response.data.contacts ? response.data.contacts : []);
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <div>
      
        <>
        {showOffCanvas ? (
          <React.Fragment>
            {!isExternal && page === "subscription" ? (
              <>
                <DocumentModal
                  documentData={dataState}
                  handleClose={() => {
                    setDataState({});
                    navigate("/")
                  }}
                  show={dataState.id > 0}
                  type={page}
                />
              </>
            ) : page == "dashboardDocuments" ? (
              <>
                <LeftSideComponent
                  data={dataState}
                  type={page}
                  docModalIsOpen={!docModalToggle}
                />
                <TopicSettingModal 
                  data={{main_key:"documents", id: 1}} 
                  handleClose={() => { 
                    navigate("/") 
                    localStorage.removeItem("searchParamUserId")
                  }}
                  docModalViewToggle={(documentData) => { docToggle(documentData) }}
                  show={!docModalToggle} 
                  title={"documents"}
                  hideFilterArea={true} 
                  hideSearchArea={false}
                  guestUserModal={true}
                />
                { docModalToggle ? 
                  <CommanOffCanvas
                      data={dataState}
                      setData={setData}
                      docType={modalDocType}
                      handleClose={() => {
                        setDocModalToggle(false);
                        docToggle({    
                          type: "dashboardDocuments"
                        })
                      }}
                      pinHandler={() => {}}
                      setSelectedId={() => {}}
                      show={docModalToggle}
                      isExternal={true}
                      contacts={contacts}
                      backdrop={false}
                      isCommentsection={true}
                    />
                  : <></>
                }
              </>
            ) : (
              <>
                <LeftSideComponent
                  data={dataState}
                  type={page}
                  // data={{ id: data.id, title: data.name, type: page }}
                />
                <CommanOffCanvas
                  data={dataState}
                  setData={setData}
                  docType={page}
                  handleClose={() => {
                    setShowOffCanvas(false);
                  }}
                  pinHandler={() => {}}
                  setSelectedId={() => {}}
                  show={showOffCanvas}
                  isExternal={isExternal}
                  contacts={contacts}
                  backdrop={false}
                />
              </>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment></React.Fragment>
        )}

        {
          showAlertModal
          ? <GoToLoginModal/>
          : <></>
        }
        </>
      
    </div>
  );
};

export default Main;
