import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import {
  _l,
  customOnPasteEventHandler,
  showError,
} from "../../hooks/utilities";
import CommanLoader from "../Loader/CommanLoader";
import { ArrowsClockwise, FilePdf } from "phosphor-react";
import ReactTooltip from "react-tooltip";
import CustomerServices from "../../services/customer-services";
import jquery from "jquery";
import ReactHtmlParser from "react-html-parser";
import { updateFloorSetting } from "../../actions/benchmark-actions/benchmark-actions";
import { useDispatch } from "react-redux";
import { showConfirmation, showMessage, toastCloseAction } from "../../actions/messages";

const HIDE_REFRESH_BUTTON_FOR_DOCTYPE = ["attachment", "aiChat", "floorPlan"];
const HIDE_SAVE_BUTTON_FOR_DOCTYPE = ["attachment", "aiChat", "floorPlan"];
const HIDE_USER_QUERY_FOR_DOCTYPE = ["attachment", "aiChat", "floorPlan"];

const AIContextModal = ({
  show,
  handleClose,
  handelSave,
  loadDescription,
  relType,
  data,
  aiContextDataResponse,
  aiContextResponse,
  spaceDetail,
  floorDetail,
  spaceFloorPlan,
  setNewTypeOfSpaceData,
  currentFloorTypeOfSpaces,
  isanalysis = false
}) => {
  const [isDescriptionLoaded, setIsDescriptionLoaded] = useState(false);
  const [isSaveOperationOngoing, setIsSaveOperationOngoing] = useState(false);
  const [isSaveAndSendOperationOngoing, setIsSaveAndSendOperationOngoing] =
    useState(false);
  const [isRefreshOnGoing, setIsRefreshOnGoing] = useState(false);
  const [taskMergeFields, setTaskMergeFields] = useState([]);
  const [generalMergeFields, setGeneralMergeFields] = useState([]);
  const [bulkTOSLoader, setBulkTOSLoader] = useState(false);

  const descriptionRef = useRef();
  const generealDescriptionRef = useRef();
  const floorPlanDescriptionRef = useRef();
  const dispatch = useDispatch();
  const contact_id = localStorage.getItem("contact_id");
  const client_id = localStorage.getItem("client_id");
  const staff_id = localStorage.getItem("staff_id");
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();

  const fetchDescription = async () => {
    try {
      const { contextDescription, taskContext } = await loadDescription();
      if (descriptionRef && descriptionRef.current && contextDescription) {
        descriptionRef.current.value = contextDescription.replace(
          /<br>/g,
          "\n"
        );
        adjustVisibleHeight(descriptionRef);
      }
      if (generealDescriptionRef && generealDescriptionRef.current) {
        generealDescriptionRef.current.value = taskContext.replace(
          /<br>/g,
          "\n"
        );
        adjustVisibleHeight(generealDescriptionRef);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsDescriptionLoaded(true);
    }
  };
  
// console.log("isanalysis",isanalysis, generealDescriptionRef.current ? generealDescriptionRef.current.value : "")
  const saveContext = async (save_and_send = 0) => {
    let descreption =  "";
    if (isanalysis) {
      if (descriptionRef.current && descriptionRef.current.value) {
        if (generealDescriptionRef.current && generealDescriptionRef.current.value) {
          descreption = descriptionRef.current.value + generealDescriptionRef.current.value
        }else{
          descreption = descriptionRef.current.value
        }
      }
    }
    try {
      if (save_and_send > 0) {
        setIsSaveAndSendOperationOngoing(true);
      } else {
        setIsSaveOperationOngoing(true);
        document.getElementById("createAiMyr").blur();
      }
      await handelSave(
        isanalysis ? descreption : descriptionRef.current ? descriptionRef.current.value : "",
        // generealDescriptionRef.current
        //   ? generealDescriptionRef.current.value
        //   : "",
        // save_and_send,
        firstName,
        lastName,
      );
    } catch (e) {
      console.error(e);
    } finally {
      setIsSaveOperationOngoing(false); 
      descriptionRef.current.value = "";
    }
  };

  const adjustVisibleHeight = (ref) => {
    const textarea = ref.current;
    textarea.style.height = "auto"; // Reset height
    textarea.style.height = `${textarea.scrollHeight}px`; // Set new height based on content
  };

  const handlePasteEvent = (e, ref) => {
    customOnPasteEventHandler(e, ref);
    adjustVisibleHeight(ref);
  };

  const refreshTaskContext = async () => {
    try {
      setIsRefreshOnGoing(true);
      await handelSave(
        descriptionRef.current.value,
        // generealDescriptionRef.current.value,
        // 0,
        // 1
        firstName,
        lastName,
      );
      await fetchDescription();
    } catch (e) {
      console.error(e);
    } finally {
      setIsRefreshOnGoing(false);
    }
  };

  const fetchMergeFields = async (e) => {
    try {
      const response = await CustomerServices.getAIMergeFields();
      if (response.status) {
        if (response.data.general_mergeFields) {
          setGeneralMergeFields(response.data.general_mergeFields);
        }

        if (response.data.task_mergeFields) {
          setTaskMergeFields(response.data.task_mergeFields);
        }
      }
    } catch (e) {
      showError("l_something_went_wrong");
      console.error(e);
    }
  };

  const selectMergeField = (textAreaRef, valueToAppend) => {
    const textArea = textAreaRef.current;

    const selectionStart = textArea.selectionStart;
    const selectionEnd = textArea.selectionEnd;

    const currentText = textArea.value;

    const newText =
      currentText.substring(0, selectionStart) +
      valueToAppend +
      currentText.substring(selectionEnd);

    textArea.value = newText;

    const newCursorPosition = selectionStart + valueToAppend.length;
    textArea.setSelectionRange(newCursorPosition, newCursorPosition);

    textArea.focus();
  };

  const modalTitle = () => {
    let title = "";
    switch (relType) {
      case "task":
        title = "l_task_context";
        break;
      case "attachment":
        title = "l_attachment_context";
        break;
      case "aiChat":
        title = "l_instruction_context";
        break;
      case "floorPlan":
        title = "l_floor_context";
        break;
      default:
        break;
    }
    return title;
  };

  const newTabOpenpdf = (pdf) => {
    var newTab = window.open();
    newTab.document.write('<iframe src="' + pdf + '" width="100%" height="100%"></iframe>');
  }

  useEffect(() => {
    fetchDescription();
    fetchMergeFields();
  }, []);

  useEffect(() => {
    if(floorPlanDescriptionRef.current)
    {
      adjustVisibleHeight(floorPlanDescriptionRef);
    }
  }, [floorPlanDescriptionRef.current, data]);
  
  useEffect(() =>{
    let element = document.getElementById("last-section-modal");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
      setTimeout(() => {
        jquery(".aiContextDataResponse-modal").addClass("mt-4")
      }, 500);
    }
  },[JSON.stringify(aiContextDataResponse)])

  // const addNewTypeOfSpaceBulk = async () => {
  //   let aiTypeOfSpace = []
  //   setBulkTOSLoader(true);
  //   for (const key in aiContextResponse) {
  //     let prevQuntity
  //     spaceFloorPlan.type_of_space.map(function(item) {
  //         if (item.title == key) {
  //           return prevQuntity = item.quantity;
  //         }
  //     })
  //     aiTypeOfSpace.push({
  //         name : key,
  //         quantity: aiContextResponse[key],
  //         prevQuntity: prevQuntity == undefined || null ? 0 : parseInt(prevQuntity)
  //     })
  //   }
  //   try {
  //     for (let item = 0; item < aiTypeOfSpace.length; item++) {
  //       const response = await CustomerServices.addFloorItem(
  //         spaceDetail.project_id,
  //         aiTypeOfSpace[item].quantity,
  //         [aiTypeOfSpace[item].name],
  //         "type_of_space",
  //         floorDetail.id,
  //         aiTypeOfSpace[item].prevQuntity
  //       );
  //       if (response.status) {
  //         // For final call
  //         if(item + 1 == aiTypeOfSpace.length){
  //           setBulkTOSLoader(false);
  //           dispatch(showMessage("sucess", _l("l_success"), _l(response.message)));
  //           handleClose();
  //         }
  //         dispatch(updateFloorSetting(floorDetail, response.data.type_of_space));
  //         setNewTypeOfSpaceData({ details: [], quantity: 1, title: "" });
  //       }else{
  //         dispatch(showMessage("unsucess", _l("l_error"), _l(response.message)));
  //       }
  //     }
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  const removeSpaceItems = async (slug, records, key, fromAiSpace) => {
    try {
      const response = await CustomerServices.deleteFloorItems(
        spaceDetail.project_id,
        slug,
        records,
        key
      );
      if (response.status) {
        if(fromAiSpace){
          aiGetDataFromScan3d();
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const deleteTypeOfSpaceItems = (itemIds, fromAiSpace) => {
    if (itemIds && itemIds.length > 0) {
            removeSpaceItems("type_of_space", itemIds, "space_id", fromAiSpace);
            setBulkTOSLoader(true);
            dispatch(toastCloseAction()); 
    } else {
      aiGetDataFromScan3d()
    }
  };

  const aiGetDataFromScan3d = async() => {
    try {
      const response = await CustomerServices.aiGetDataFromScan3d(contact_id, client_id, staff_id, spaceDetail.project_id, floorDetail.id, data.image_url);
      if(response.status && response.data)
      {
        addNewTypeOfSpaceBulk(response.data);
      }
      else {
        showError(response.message);
      }
    }
    catch(e)
    {
      console.error(e);
      showError("l_something_went_wrong , 1213");
    }
  };

  const addNewTypeOfSpaceBulk = async (data) => {
    let aiTypeOfSpace = []
    for (const key in data) {
      let prevQuntity
      spaceFloorPlan.type_of_space.map(function(item) {
          if (item.title == key) {
            return prevQuntity = item.quantity;
          }
      })
      aiTypeOfSpace.push({
          name : key,
          quantity: data[key],
          prevQuntity: prevQuntity == undefined || null ? 0 : parseInt(prevQuntity)
      })
    }
    try {
      for (let item = 0; item < aiTypeOfSpace.length; item++) {
        const response = await CustomerServices.addFloorItem(
          spaceDetail.project_id,
          aiTypeOfSpace[item].quantity,
          [aiTypeOfSpace[item].name],
          "type_of_space",
          floorDetail.id,
          0,
        );
        if (response.status) {
          // For final call
          if(item + 1 == aiTypeOfSpace.length){
            setBulkTOSLoader(false);
            dispatch(showMessage("sucess", _l("l_success"), _l(response.message)));
          }
          dispatch(updateFloorSetting(floorDetail, response.data.type_of_space));
          setNewTypeOfSpaceData({ details: [], quantity: 1, title: "" });
        }else{
          dispatch(showMessage("unsucess", _l("l_error"), _l(response.message)));
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Modal
        show={show}
        size={"xl"}
        onHide={handleClose}
        className="custom-modal-style "
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{_l(modalTitle())}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="with-custom-height80vh py-0">
         
          <div className="form-wrapper-main Aicontextmodal" id="aiContextDataResponse-modals">
            {relType === "floorPlan" ? (
              <React.Fragment>
                {aiContextDataResponse ? (
                  <div className="form-wrapper-main p-0" >
                    {aiContextDataResponse.map((text) => (
                      <div className="row gx-0 pb-20px mb-20 border-bottom " >
                        <span className="mb-10">{_l("l_user")}</span>
                        <span className="fw-semibold title-fonts mb-10px bg-white-05 p-10">{`${_l(text.question)}`}</span>
                        <span className="mb-10">{_l("l_ai_response")}</span>
                        <span className="color-white-60 bg-white-05 p-10 ">{ReactHtmlParser(text.answer.replace(/(?:\r\n|\r|\n)/g, '<br>'))}</span>
                      </div>
                    ))
                    }
                    <span className="" id="last-section-modal"></span>
                  </div>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <></>
            )}
            <div className={`row position-sticky bottom-0 base-modal-bg ${isSaveOperationOngoing ? 'for-disabled' : ''}`}>
            {relType == "aiChat" ?  (    
            <React.Fragment>
                <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk no-asterisk">
                    {_l("l_firstname")}
                  </Form.Label>
                  <input
                    id="createAiMyr"
                    className="form-control p-15"
                    placeholder={`${_l("l_firstname")}`}
                    type="text"
                    name="firstname"
                    onChange={(e)=>{setFirstName(e.target.value)}}
                    value={firstName}
                  />
                </Form.Group>
                <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk no-asterisk">
                    {_l("l_last_name")}
                  </Form.Label>
                  <input
                    id="createAiMyr"
                    className="form-control p-15"
                    placeholder={`${_l("l_last_name")}`}
                    type="text"
                    name="lastname"
                    onChange={(e)=>{setLastName(e.target.value)}}
                    value={lastName}
                  />
                </Form.Group>
              </React.Fragment>
              ) : <></>}
              <Form.Group className="col-xl-12 c-input-box pb-3 position-relative modalmax50vh">
                <div className="d-flex align-items-center py-3">
                  <Form.Label className="input-label no-asterisk position-sticky top-0 badgeinnerbg p-10 ps-0 mb-0">
                    {_l("l_general_context_and_instruction")}
                  </Form.Label>
                  {data && data.image_url ? 
                  <>
                    {data && data.image_url && data.image_url.slice(-3) == "png"
                    ?
                    <div className="d-flex align-items-center">
                      <div className="h50w50">
                        <img className="h50w50" src={data.image_url} />
                        {/* <img className="h50w50" src="https://images.unsplash.com/photo-1682687220742-aba13b6e50ba?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"/> */}
                      </div>
                    </div>
                    : 
                    <div className="d-flex align-items-center comman_action_icon">
                      <a href={`${data.image_url}`}  className="action_icon with_bg h32w32" ><FilePdf size={18} weight="light" className="c-icons" /></a>
                    </div>}
                  </> : <></>
                  }
                </div>
                <Form.Control
                  id="createAiMyr"
                  as="textarea"
                  placeholder={`${_l("l_type_description_here")}`}
                  type="text"
                  name=""
                  ref={descriptionRef}
                  contentEditable={true}
                  onChange={() => adjustVisibleHeight(descriptionRef)}
                  onPaste={(e) => handlePasteEvent(e, descriptionRef)}
                ></Form.Control>
              </Form.Group>
              {HIDE_USER_QUERY_FOR_DOCTYPE.includes(relType) ? (
                <React.Fragment></React.Fragment>
              ) : (
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative modalmax50vh">
                  <Form.Label className="input-label no-asterisk position-sticky top-0  w-100 badgeinnerbg p-10 px-0">
                    {_l("l_task_context")}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder={`${_l("l_type_description_here")}`}
                    type="text"
                    name=""
                    readOnly
                    ref={generealDescriptionRef}
                    contentEditable={true}
                    onChange={() => adjustVisibleHeight(generealDescriptionRef)}
                    onPaste={(e) => handlePasteEvent(e, generealDescriptionRef)}
                  ></Form.Control>
                </Form.Group>
              )}
            </div>
            
            {/*<div className="row">
              <div className="col-xl-6 pe-5">
                <div className="bg-white-05 c-font f-12 color-white radius_3 px-2 py-1 leadProfileTitle">
                  {_l("l_general_merge_fields")}
                </div>
                <ul className="p-10 px-0">
                  {generalMergeFields.map((field, index) => {
                    return (
                      <li
                        key={index}
                        className="lead-items d-flex p-1 cursor-pointer"
                        onClick={() => {
                          selectMergeField(
                            descriptionRef,
                            field.key
                          );
                        }}
                      >
                        <div className="c-font f-13 color-white-60 flex-grow-1 max-w-130px text-truncate">
                          {field.name}
                        </div>
                        <div className="c-font f-13 w100minus120 color-white-80 text-truncate text-end">
                          {field.key}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="col-xl-6">
                <div className="bg-white-05 c-font f-12 color-white radius_3 px-2 py-1 leadProfileTitle">
                  {_l("l_task_merge_fields")}
                </div>
                <ul className="p-10 px-0">
                  {taskMergeFields.map((field, index) => {
                    return (
                      <li
                        key={index}
                        className="lead-items cursor-pointer d-flex p-1"
                        onClick={() => {
                          selectMergeField(
                            descriptionRef,
                            field.key
                          );
                        }}
                      >
                        <div className="c-font f-13 color-white-60 flex-grow-1 max-w-130px text-truncate">
                          {field.name}
                        </div>
                        <div className="c-font f-13 w100minus120 color-white-80 text-truncate text-end">
                          {field.key}
                        </div>
                      </li>
                    );
                  })}
                </ul>
                </div> 
            </div>*/}
           
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          {HIDE_REFRESH_BUTTON_FOR_DOCTYPE.includes(relType) ? (
            <React.Fragment></React.Fragment>
          ) : (
            <div
              data-tip={`${_l("l_refresh_task_context")}`}
              data-effect="solid"
              data-delay-show='1000'
              data-class="tooltip-main"
              data-place="bottom"
              className=" comman_action_icon"
            >
              <a
                className={`d-flex action_icon h32w32 with_bg ${
                  isRefreshOnGoing ? "for-disabled" : ""
                }`}
                onClick={refreshTaskContext}
              >
                <ArrowsClockwise size={18} weight="light" className="c-icons" />
              </a>
            </div>
          )}
          <ReactTooltip />
          <Button
            variant="primary"
            size="sm"
            onClick={() => saveContext()}
            disabled={isRefreshOnGoing || isSaveOperationOngoing}
          >
            {isSaveOperationOngoing
              ? _l("l_please_wait")
              : _l("l_send") }{" "}
            {isSaveOperationOngoing ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                className={`ms-2 `}
                aria-hidden="true"
              />
            ) : (
              <></>
            )}
          </Button>
          {HIDE_SAVE_BUTTON_FOR_DOCTYPE.includes(relType) ? (
            <React.Fragment></React.Fragment>
          ) : (
            <Button
              variant="secondary"
              disabled={isRefreshOnGoing || isSaveAndSendOperationOngoing}
              size="sm"
              onClick={() => saveContext(1)}
            >
              {isSaveAndSendOperationOngoing
                ? _l("l_please_wait")
                : _l("l_save_and_send")}{" "}
            </Button>
          )}
          {relType === "floorPlan" && 
          <Button
            variant="secondary"
            disabled={isRefreshOnGoing || isSaveAndSendOperationOngoing || aiContextDataResponse == 0 || bulkTOSLoader ? true : false}
            size="sm"
            onClick={() =>
              deleteTypeOfSpaceItems(currentFloorTypeOfSpaces, true)
            }
          >
            {bulkTOSLoader ? _l("l_please_wait") : _l("l_add_type_of_space_label")}
            {bulkTOSLoader ?
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              className={`ms-2 `}
              aria-hidden="true"
            />
            : <></>
            }
          </Button>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AIContextModal;
