import moment from "moment";
import { dataURItoBlob, getItemId } from "../hooks/utilities";
import store from "../store";
import apiService from "./api-service/api-service";

const API_URL = process.env.REACT_APP_API_URL;
const user_type = localStorage.getItem("user_type");
const selectedProject = store.getState().customer.selectedProject;
const getSpaceRooms = (schedule_id, project_id = 0) => {
  const endPoint = API_URL + "mod=space&act=get_type_of_space_per_space";
  let form = new FormData();
  if(schedule_id > 0)
  {
    form.append("schedule_id", schedule_id);
  }
  form.append("rel_id", project_id > 0 ? project_id : localStorage.getItem('selectedOffice'));
  form.append("rel_type", "project");
  return apiService(endPoint, "POST", true, form);
};

const addEditSpaceRoom = (rooms, schedule_id) => {
  const endPoint = API_URL + "mod=space&act=add_edit_type_of_space";
  let form = new FormData();
  form.append("rel_id", localStorage.getItem('selectedOffice'));
  form.append("rel_type", "project");
  form.append("project_id", localStorage.getItem('selectedOffice'));

  rooms.forEach((attr, index) => {
    if (!attr.is_default || attr.is_default == 0) {
      if (attr.title != '') {
        form.append(`type_of_space[${index}][title]`, attr.title);
        form.append(`type_of_space[${index}][quantity]`, attr.quantity);
        if (attr.id) {
          form.append(`type_of_space[${index}][id]`, attr.id);
          form.append(`type_of_space[${index}][item_id]`, attr.item_id);
        }
      }
    }
  });
  if (schedule_id) {
    form.append("schedule_id", schedule_id);
  }
  return apiService(endPoint, "POST", true, form);
}

const deleteTypeOfSpace = (space_id, schedule_id = 0) => {
  const endPoint = API_URL + "mod=space&act=remove_type_of_space";
  let form = new FormData();
  form.append("project_id", localStorage.getItem('selectedOffice'));
  form.append("space_id", space_id);
  if(schedule_id > 0)
  {
    form.append("schedule_id", schedule_id);
  }
  return apiService(endPoint, "POST", true, form);
};

const acceptProposal = (proposal_id, benchmark_id, signature, firstName, lastName, emailAddress, startdate, is_added_by_myr, account_iban) => {
  const endPoint = API_URL + "mod=proposal&act=accept";
  let form = new FormData();
  const file = dataURItoBlob(signature);
  form.append("proposal_id", proposal_id);
  form.append("benchmark_id", benchmark_id);
  form.append("firstname", firstName);
  form.append("lastname", lastName);
  form.append("email", emailAddress);
  form.append("project_id", selectedProject);
  if(account_iban)
  {
    form.append("account_iban", account_iban);
  }
  form.append("signature", file, "signature.png");
  if(is_added_by_myr > 0)
  {
    form.append("is_added_by_myr", is_added_by_myr);
  }
  if(startdate)
  {
    form.append("startdate", startdate);
  }

  return apiService(endPoint, "POST", true, form);
};

const rejectProposal = (proposal_id, benchmark_id, is_added_by_myr) => {
  const endPoint = API_URL + "mod=proposal&act=reject";
  let form = new FormData();
  form.append("proposal_id", proposal_id);
  form.append("benchmark_id", benchmark_id);
  form.append("project_id", selectedProject);
  if(is_added_by_myr > 0)
  {
    form.append("is_added_by_myr", is_added_by_myr);
  }
  return apiService(endPoint, "POST", true, form);
};

const getEstimateUsingProposal = (proposal_id) => {
  const endPoint = API_URL + "mod=estimate&act=get_estimate_by_proposal";
  let form = new FormData();
  form.append("proposal_id", proposal_id);
  return apiService(endPoint, "POST", true, form);
};

const sendExternalProposal = (benchmark_id, invite_email) => {
  const endPoint = API_URL + "mod=benchmark&act=external_provider_invite";
  let form = new FormData();
  form.append("benchmark_id", benchmark_id);
  form.append("invite_email", invite_email);
  form.append("project_id", selectedProject);
  return apiService(endPoint, "POST", true, form);
}

const cancelExternalProposal = (benchmark_id, proposal_id) => {
  const endPoint = API_URL + "mod=benchmark&act=external_provider_remove";
  let form = new FormData();
  form.append("benchmark_id", benchmark_id);
  form.append("proposal_id", proposal_id);
  form.append("project_id", selectedProject);
  return apiService(endPoint, "POST", true, form);
}

const deleteBenchmark = (benchmark_id, project_id) => {
  const endPoint = API_URL + "mod=benchmark&act=delete_benchmark";
  let form = new FormData();
  form.append("benchmark_id", benchmark_id);
  form.append("project_id", project_id || selectedProject);
  return apiService(endPoint, "POST", true, form);
}

const updateTypeOfSpace = (schedule_id, title, quantity, id, item_id) => {
  const endPoint = API_URL + "mod=space&act=add_edit_type_of_space";
  let form = new FormData();
  form.append("rel_id", localStorage.getItem('selectedOffice'));
  form.append("rel_type", "project");
  form.append("project_id", localStorage.getItem('selectedOffice'));
  form.append(`type_of_space[0][title]`, title);
  form.append(`type_of_space[0][quantity]`, quantity);
  form.append(`type_of_space[0][id]`, id);
  form.append(`type_of_space[0][item_id]`, item_id);
  form.append("schedule_id", schedule_id);
  return apiService(endPoint, "POST", true, form);
}

const getProposalComments = (proposal_id) => {
  const endPoint = API_URL + "mod=task&act=get_proposal_comments";
  let form = new FormData();
  form.append("proposal_id", proposal_id);
  form.append("project_id", selectedProject);
  form.append("all", 1);
  return apiService(endPoint, "POST", true, form);
}

const setScheduleSlotAssign = (benchmark_id, availability, assignee, assigned_items) => {
  const endPoint = API_URL + "mod=benchmark&act=set_schedule_slot_assign";
  let form = new FormData();

  form.append("benchmark_id", benchmark_id);
  form.append("availability", JSON.stringify(availability));
  form.append("assignee", JSON.stringify(assignee));
  form.append("assigned_items", JSON.stringify(assigned_items));
  return apiService(endPoint, "POST", true, form);
}

const addEditChecklistComment = (comment, files, checklist_id, comment_id, rel_type = '') => {
  const endPoint = API_URL + "mod=comment&act=add_comment";
  let form = new FormData();
  form.append("content", comment);
  if(files)
  {
    Array.from(files).forEach((file, file_index) => {
      form.append(`file[${file_index}]`, file);
    });
  }
  form.append("rel_id", checklist_id);
  if (rel_type == '') {
    form.append("rel_type", "schedule_item");
  }else{
    form.append("rel_type", rel_type);
  }
  
  if(comment_id > 0)
  {
    form.append("comment_id", comment_id);
  }
  return apiService(endPoint, "POST", true, form, 0, false, -1);
}

const deleteChecklistComment = (checklist_id, comment_id) => {
  const endPoint = API_URL + "mod=comment&act=remove_comment";
  let form = new FormData();
  form.append("rel_id", checklist_id);
  form.append("rel_type", "schedule_item");
  form.append("comment_id", comment_id);
  return apiService(endPoint, "POST", true, form, 0, false, -1);
}
const copyBenchmarkSchedule = (benchmark_id) => {
  const endPoint = API_URL + "mod=benchmark&act=copy_benchmark_schedule";
  let form = new FormData();
  form.append("schedule_id", benchmark_id);
  return apiService(endPoint, "POST", true, form);
}

const updateTypeOfSpaceData = (room, schedule_id) => {
  const endPoint = API_URL + "mod=space&act=update_type_of_space_schedule";
  let form = new FormData();
  form.append("schedule_id", schedule_id);
  form.append("project_id", room.rel_id);
  form.append("id", getItemId(schedule_id, room.id));
  form.append("title", room.title);
  form.append("quantity", room.quantity);
  return apiService(endPoint, "POST", true, form);
}

const getFloorDetails = (project_id, all_type_of_space) => {
  const endPoint = API_URL + "mod=space&act=get_floor_details";
  let form = new FormData();
  form.append("project_id", project_id ? project_id : localStorage.getItem("selectedOffice"));
  if(all_type_of_space)
  {
    form.append("all_type_of_space", all_type_of_space);
  }
  return apiService(endPoint, "POST", true, form);
}

const addBulkProvidersToCategory = (project_id, category_id, provider_data,return_invitee_list, benchmark_id, proposal_send) => {
  const endPoint = API_URL + "mod=benchmark&act=add_bluk_provider_to_category";
  let form = new FormData();
  const userType = localStorage.getItem("user_type");
  
  if(userType === "staff")
  {
    const spaces = JSON.parse(localStorage.getItem("spaces"));
    let currentSpace = spaces.find(w => w.project_id == project_id);
    if (currentSpace) {
      form.append("space_client_id", currentSpace.client_id);
      form.append("space_contact_id", currentSpace.contact_id);
    }
  }

  form.append("category_id", category_id);
  form.append("project_id", project_id);

  if(benchmark_id > 0)
  {
    form.append("benchmark_id", benchmark_id);
  }
  if(proposal_send > 0)
  {
    form.append("proposal_send", proposal_send);
  }

  provider_data.forEach((provider, index) => {
    form.append(`provider_data[${index}][type_name]`, provider.name);
    form.append(`provider_data[${index}][phonenumber]`, provider.phonenumber);
    form.append(`provider_data[${index}][invitee_email]`, provider.email);
    form.append(`provider_data[${index}][company_name]`, provider.company_name);
  });
  form.append("return_invitee_list",return_invitee_list);
  return apiService(endPoint, "POST", true, form);
}

const addBenchmarkChecklists = (benchmark_id, project_id, list, floor_id, is_update, equipment_id) => {
  const endPoint = API_URL + "mod=benchmark&act=add_benchmark_checklist";

  let formattedChecklist = [];
  let file_counts = 0;
  let form = new FormData();

  list.forEach((item) => {
    
    const itemObj = {
      slot_id: isNaN(item.id) ? 0 : item.id,
      item_name: item.checklist[0].name,
      item_slot: moment(item.slot).format('HH:mm:ss'),
      room_id: item.room_id,
      days: item.days,
      frequency: item.frequency,
      priority: item.priority,
      comments: [],
      floor_id: item.floor_id,
      type_of_space_items: item.type_of_space_items,
      type_of_space_item_id: item.type_of_space_item_id,
      photo_required: item.photo_required,
      equipment_detail:item.equipment_detail      
    };
    if(item.comments && item.comments.length)
    {
      let comments = [];
      item.comments.forEach((comment) => {
        let commentMetaData = {...comment, attachments: []};
        if(comment.attachments)
        {
          Array.from(comment.attachments).forEach((attachment) => {
            form.append(`file[${file_counts}]`, attachment);
            file_counts += 1;
            commentMetaData.attachments.push(attachment.name);
          });
        }
        comments.push(commentMetaData);
      });
      itemObj.comments = comments;
    }
    formattedChecklist.push(itemObj);
  });

  form.append("is_update", is_update);
  form.append("benchmark_id", benchmark_id);
  form.append("project_id", project_id);
  form.append("floor_id", floor_id);
  form.append("new_list", JSON.stringify(formattedChecklist));
  if(equipment_id)
  {
    form.append("equipment_id", equipment_id);
  }

  if(["/connect"].includes(window.location.pathname))
  {
    form.append("is_external", 1)
  }
  
  return apiService(endPoint, "POST", true, form);
}

const getOperatorsByCategory = (project_id, category_id, all_myr_provider,include_manager = 0, for_task_assignment) => {
  const endPoint = API_URL + "mod=benchmark&act=get_existing_and_invitee_operator_by_customer";
  let form = new FormData();

  form.append("project_id", project_id);
  if (user_type == "staff") {  
    const client_id = localStorage.getItem("virtualSpaceClientId")
    form.append("client_id", client_id);
  }
  form.append("category_id", category_id);
  form.append("include_manager", include_manager);  
  form.append("all_myr_provider", all_myr_provider);
  if(for_task_assignment > 0)
  {
    form.append("for_task_assignment", for_task_assignment);
  }
  return apiService(endPoint, "POST", true, form);
}
  
const updateTypeOfSpaceTitle = (project_id, slug, space_id, item_id, title, poiSet, floorId, skillRequireForBenchmark = 0) => {
  const endPoint = API_URL + "mod=space&act=edit_type_of_space";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("slug", slug);
  form.append("space_id", space_id);
  if (poiSet && poiSet.length > 0) { 
    form.append("poi_set", JSON.stringify(poiSet));
  }
  form.append("floor_id", floorId);
  if(item_id > 0)
  {
    form.append("item_id", item_id);
  }
  form.append("title", title);
  form.append("skill_require_for_benchmark", skillRequireForBenchmark);
  return apiService(endPoint, "POST", true, form);
}

const removeScheduleChecklistItem = (schedule_id, project_id, item_ids) => {
  const endPoint = API_URL + "mod=benchmark&act=remove_schedule_item";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("schedule_id", schedule_id);
  item_ids.forEach((w, index) => {
    form.append(`item_id[${index}]`, w);
  })
  return apiService(endPoint, "POST", true, form);
}

const getChecklistTemplate = (project_id, category_id, is_task, is_all) => {
  const endPoint = API_URL + "mod=benchmark&act=get_schedule_checklist_templates_list";
  let form = new FormData();
  if (project_id > 0) {
    form.append("project_id", project_id);
  }
  form.append("category_id", category_id);
  form.append("is_task", is_task);
  if(is_all > 0)
  {
    form.append("is_all", is_all);
  }

  return apiService(endPoint, "POST", true, form);
};

const createChecklistTemplate = (project_id, category_id, template_title, list, is_task) => {
  const endPoint = API_URL + "mod=benchmark&act=add_schedule_checklist_as_template";
  let formattedChecklist = [];
  let form = new FormData();
  list.forEach((item) => {
    const itemObj = {
      slot_id: isNaN(item.id) ? 0 : item.id,
      item_name: item.checklist[0].name,
      item_slot: moment(item.slot).format('HH:mm:ss'),
      room_id: item.room_id,
      days: item.days,
      frequency: item.frequency,
      priority: item.priority,
      comments: [],
      floor_id: item.floor_id,
      type_of_space_items: item.type_of_space_items,
      type_of_space_item_id: item.type_of_space_item_id,
      photo_required: item.photo_required
    };
    formattedChecklist.push(itemObj);
  });

  form.append("project_id", project_id);
  form.append("category_id", category_id);
  form.append("template_title", template_title);
  form.append("is_task", is_task);
  form.append("new_list", JSON.stringify(formattedChecklist));
  return apiService(endPoint, "POST", true, form);
};

const getTemplateChecklistItems = (project_id, category_id, template_id, is_task, is_all) => {
  const endPoint = API_URL + "mod=benchmark&act=get_schedule_checklist_template_detail";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("category_id", category_id);
  form.append("template_id", template_id);
  form.append("is_task", is_task);
  if(is_all > 0)
  {
    form.append("is_all", is_all);
  }
  return apiService(endPoint, "POST", true, form);
};

const getInvitedCustomerList = (project_id) => {
  const endPoint = API_URL + "mod=space&act=get_invite_client_list";
  let form = new FormData();
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const deleteCustomerInvitee = (project_id, invitee_id) => {
  const endPoint = API_URL + "mod=space&act=delete_invite_client";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("invitee_id", invitee_id);
  return apiService(endPoint, "POST", true, form);
};

const getOperatorsListByProject = (project_id) => {
  const endPoint = API_URL + "mod=benchmark&act=get_project_provider";
  let form = new FormData();
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
}

const getCategoryProviderList = (project_id, category_ids) => {
  const endPoint = API_URL + "mod=benchmark&act=get_provider_list";
  let form = new FormData();
  form.append("project_id", project_id);
  category_ids.forEach((category, index) => {
    form.append(`category_ids[${index}]`, category);
  })
  return apiService(endPoint, "POST", true, form);
}

const upgradeOperatorPlan = (
  firstname,
  lastname,
  email,
  signature,
  package_id
) => {
  const endPoint = API_URL + "mod=estimate&act=upgrade_membership_plan";
  const file = dataURItoBlob(signature);
  let form = new FormData();
  form.append("firstname", firstname);
  form.append("last_name", lastname);
  form.append("email", email);
  form.append("signature", file, "signature.png");
  form.append("package_id", package_id);
  return apiService(endPoint, "POST", true, form);
};

const removeProvider = (project_id, provider_staff_id, provider_client_id) => {
  const endPoint = API_URL + "mod=benchmark&act=remove_provider";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("provider_staff_id", provider_staff_id);
  form.append("provider_client_id", provider_client_id);
  return apiService(endPoint, "POST", true, form);
};

const inviteProvidersWithCategories = (project_id, provider_data) => {
  const endPoint = API_URL + "mod=benchmark&act=invite_provider_by_category";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("provider_data", JSON.stringify(provider_data));
  return apiService(endPoint, "POST", true, form);
};

const removeProviderCategory = (project_id, category_id) => {
  const endPoint = API_URL + "mod=benchmark&act=remove_provider_category";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("category_id", category_id);
  return apiService(endPoint, "POST", true, form);
};

const discardExternalBenchmark = (hash) => {
  const endPoint = API_URL + "mod=user&act=delete_external_user";
  let form = new FormData();
  form.append("hash", hash);
  form.append("is_external", 1);
  return apiService(endPoint, "POST", true, form);
};

const updateTaskChecklistTemplate = (list, category_id, template_title, template_id, is_task, project_id) => {
  const endPoint =
    API_URL + "mod=benchmark&act=edit_checklist_template";
  let formattedChecklist = [];
  let form = new FormData();
  list.forEach((item) => {
    const itemObj = {
      slot_id: isNaN(item.id) ? 0 : item.id,
      item_name: item.checklist[0].name,
      item_slot: moment(item.slot).format("HH:mm:ss"),
      room_id: item.room_id,
      days: item.days,
      frequency: item.frequency,
      priority: item.priority,
      comments: [],
      floor_id: item.floor_id,
      type_of_space_items: item.type_of_space_items,
      type_of_space_item_id: item.type_of_space_item_id,
      photo_required: item.photo_required,
    };
    formattedChecklist.push(itemObj);
  });

  form.append("category_id", category_id);
  form.append("template_title", template_title);
  if(template_id > 0)
  {
    form.append("template_id", template_id);
  }
  if(project_id > 0)
  {
    form.append("project_id", project_id);
  }
  form.append("is_task", is_task);
  form.append("new_list", JSON.stringify(formattedChecklist));
  return apiService(endPoint, "POST", true, form);
};

const deleteChecklistTemplate = (template_id) => {
  const endPoint = API_URL + "mod=benchmark&act=delete_checklist_template";
  let form = new FormData();
  form.append("template_id", template_id);
  return apiService(endPoint, "POST", true, form);
};

const getBasicDetailsCustomFields = (type) => {
  const endPoint = API_URL + "mod=general&act=get_custom_fields";
  let form = new FormData();
  form.append("type", type);
  return apiService(endPoint, "POST", true, form);
};

const deleteBenchmarkUnitItem = (project_id, benchmark_id, unit_task_id) => {
  const endPoint = API_URL + "mod=benchmark&act=delete_benchmark_unit_task";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("benchmark_id", benchmark_id);
  form.append("unit_task_id", unit_task_id);
  return apiService(endPoint, "POST", true, form);
};

const addBenchmarkUnitItems = (project_id, benchmark_id, unit_tasks) => {
  const endPoint = API_URL + "mod=benchmark&act=add_edit_benchmark_unit_task";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("benchmark_id", benchmark_id);
  form.append("unit_task", JSON.stringify(unit_tasks.map((unit) => {
    const frequencyArray = ["monthly", "quarterly", "half_yearly", "yearly"];
    unit.name = unit.description;
    Object.keys(unit).forEach((key) => {
      if(frequencyArray.includes(key) && !unit[key])
      {
        delete unit[key];
      }
    })
    return unit;
  })));
  return apiService(endPoint, "POST", true, form);
};

const downloadPDP = (schedule_id, project_id, doc_type) => {
  const endPoint = API_URL + "mod=document&act=get_schedule_pdf";
  let form = new FormData();
  form.append("schedule_id", schedule_id);
  form.append("project_id", project_id);
  form.append("doc_type", doc_type);
  return apiService(endPoint, "POST", true, form);
};

const setBenchmarkGroup = (project_ids, group_id) => {
  const endPoint = API_URL + "mod=dashboard&act=add_edit_benchmark_group";
  let form = new FormData();
  if(project_ids && project_ids.length)
  {
    project_ids.forEach((project, index) => {
      form.append(`project_ids[${index}]`, project);
    });
  }
  if(!isNaN(Number(group_id)) && Number(group_id))
  {
    form.append("group_id", group_id);
  }
  return apiService(endPoint, "POST", true, form);
};

const getBenchmarkGroupInfo = (group_id) => {
  const endPoint = API_URL + "mod=dashboard&act=get_group_list";
  let form = new FormData();
  if(group_id && group_id > 0)
  {
    form.append("group_id", group_id);
  }
  return apiService(endPoint, "POST", true, form);
};

const generateDashboardBenchmark = (schedule_ids) => {
  const endPoint = API_URL + "mod=dashboard&act=send_proposal";
  let form = new FormData();
  if(schedule_ids && schedule_ids.length)
  {
    schedule_ids.forEach((schedule, index) => {
      form.append(`schedule_ids[${index}]`, schedule);
    });
  }
  return apiService(endPoint, "POST", true, form);
};

const createSolarBenchmark = (benchmarkData) => {
  const endPoint = API_URL + "mod=user&act=add_user_with_hirihng_benchmark";
  let form = new FormData();
  form.append(`address`, benchmarkData.address);
  form.append(`region_id`, benchmarkData.region_id);
  form.append(`region_name`, benchmarkData.region_name);
  form.append(`place_id`, "");
  form.append(`lat`, "");
  form.append(`lng`, "");
  form.append(`superfice`, benchmarkData.superficy);
  form.append(`no_of_hiring`, benchmarkData.number_of_people);
  form.append(`start_date`, moment(benchmarkData.startDate).format("YYYY-MM-DD HH:mm:ss"));
  form.append(`budget`, benchmarkData.budget);
  form.append(`name`, benchmarkData.fullName);
  form.append(`phonenumber`, benchmarkData.phonenumber);
  form.append(`email`, benchmarkData.email);
  form.append(`password`, benchmarkData.password);
  form.append(`is_external`, 1);
  return apiService(endPoint, "POST", true, form);
}

const addAgentBidAmount = (amounts, project_id, requirement_id) => {
  const endPoint = API_URL + "mod=benchmark&act=update_requirment_amount";
  let form = new FormData();
  form.append("amounts", JSON.stringify(amounts));
  form.append("project_id", project_id);
  form.append("requirement_id", requirement_id);
  return apiService(endPoint, "POST", true, form);
};

const updateJobOperatorRequirement = (project_id, requirement_id, operator_requirment) => {
  const endPoint = API_URL + "mod=space&act=edit_requirment";
  let form = new FormData();
  form.append("operator_requirment", JSON.stringify(operator_requirment));
  form.append("project_id", project_id);
  form.append("requirement_id", requirement_id);
  return apiService(endPoint, "POST", true, form);
};

const operatorAcceptRejectJobProposal = (project_id, requirement_id, proposal_id, action, signature,schedule_id) => {
  const endPoint = action === "accept" ? API_URL + "mod=benchmark&act=accept_requirment_proposal" : API_URL + "mod=benchmark&act=reject_requirment_proposal";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("proposal_id", proposal_id);
  form.append("requirement_id", requirement_id);
  form.append("schedule_id", schedule_id);
  if (action === "accept") {
  const file = dataURItoBlob(signature);
  form.append("signature", file, "signature.png");
    
  }
  
  return apiService(endPoint, "POST", true, form);
};

const agentAcceptRejectJobProposal = (project_id, requirement_id, agent_status, proposal_id) => {
  const endPoint = API_URL + "mod=benchmark&act=accept_agent_proposal";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("agent_status", agent_status);
  form.append("requirement_id", requirement_id);
  form.append("proposal_id", proposal_id);
  return apiService(endPoint, "POST", true, form);
};

const getAgentJobDetail = (project_id, requirement_id) => {
  const endPoint = API_URL + "mod=space&act=get_space_requirment";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("requirement_id", requirement_id);
  return apiService(endPoint, "POST", true, form);
};
const acceptAgentContract = (requirement_id, proposal_id,signature) => {
  const endPoint = API_URL + "mod=benchmark&act=accept_agent_contract";
  let form = new FormData();
  form.append("requirement_id", requirement_id);
  form.append("proposal_id", proposal_id);
  if(signature){
    const file = dataURItoBlob(signature);
    form.append("signature", file, "signature.png");
  }

  return apiService(endPoint, "POST", true, form);
};
const uploadCompanyContract = (requirement_id, proposal_id,schedule_id,contract,signature) => {
  const endPoint = API_URL + "mod=benchmark&act=add_proposal_company_contract";
  let form = new FormData();
  form.append("requirement_id", requirement_id);
  form.append("proposal_id", proposal_id);
  form.append("schedule_id", schedule_id);
  form.append("cordinates", JSON.stringify(
    [
      {
        "user" : "company",
        "x" : 50,
        "y" : 50,
        "height" : 20, 
        "width" : 50,
        "page":1 
      },
      {
        "user" : "agent",
        "x" : 50,
        "y" : 50,
        "height" : 20, 
        "width" : 50 ,
        "page":1 
      }
    ]
  ));
  if(contract)
  {
    Array.from(contract).forEach((file, file_index) => {
      form.append(`contract`, file);
    });
  }

  if (signature) {    
    const file = dataURItoBlob(signature);
    form.append("signature", file, "signature.png");
  }

  return apiService(endPoint, "POST", true, form);
};

const updateBenchmarkChecklist = (project_id, new_list, benchmark_id) => {
  const endPoint = API_URL + "mod=benchmark&act=edit_schedule";
  let form = new FormData();
  form.append(`project_id`, project_id);
  form.append(`benchmark_id`, benchmark_id);
  form.append(`new_list`, JSON.stringify(new_list));
  return apiService(endPoint, "POST", true, form);
};

const getTaskForEditSchedule = (project_id, schedule_id, assignee_id, date, frequency) => {
  const endPoint = API_URL + "mod=task&act=get_task_for_edit_schedule";
  let form = new FormData();
  form.append(`project_id`, project_id);
  form.append(`schedule_id`, schedule_id);
  form.append(`assignee_id`, assignee_id);
  form.append(`date`, date);
  if(frequency && frequency.length)
  {
    form.append(`frequency`, frequency);
  }
  return apiService(endPoint, "POST", true, form);
};

const getOperatorList = (project_id, benchmark_id, provider_user_id) => {
  const endPoint = API_URL + "mod=benchmark&act=get_operator_details_for_edit_details";
  let form = new FormData();
  form.append(`project_id`, project_id);
  form.append(`benchmark_id`, benchmark_id);
  form.append(`provider_user_id`, provider_user_id);
  return apiService(endPoint, "POST", true, form);
};

const updateSlotAssignmentForSchedule = (project_id, benchmark_id, assigne_id, day, new_day, new_assigne_id, is_delete) => {
  const endPoint = API_URL + "mod=benchmark&act=update_schedule_item";
  let form = new FormData();
  form.append(`project_id`, project_id);
  form.append(`benchmark_id`, benchmark_id);
  form.append(`assigne_id`, assigne_id);
  form.append(`day`, day);
  form.append(`new_day`, new_day);
  if(new_assigne_id > 0)
  {
    form.append(`new_assigne_id`, new_assigne_id);
  }
  if(is_delete > 0)
  {
    form.append(`is_delete`, is_delete);
  }
  return apiService(endPoint, "POST", true, form);
};

const createEstimate = (estimateInputData, saveAndSend, sendEmailData) => {
  const {
    customer_client_id,
    project_id,
    staff_id,
    start_date, 
    end_date,
    customer_company_name,
    estimate_type,
    payment_mode,
    recurring, 
    billing_address, 
    show_shipping_on_estimate ,
    items, 
    address,
    estimate_subtotal, 
    discount_total, 
    estimate_total,
    adjustment, 
    terms,
    client_note 
  } = estimateInputData;

  const { email_send_to_id, reminder_date, attachpdf } = sendEmailData;

  const endPoint = API_URL + "mod=estimate&act=create_estimate_for_user";
  let form = new FormData();
  console.log(customer_client_id);
  form.append(`staff_id`, staff_id);
  form.append(`project_id`, project_id);
  form.append(`customer_client_id`, customer_client_id.value);
  if (address && address != "") {
    form.append(`address`, address);
  }
  form.append(`terms`, terms);
  form.append(`start_date`, moment(start_date).format("YYYY-MM-DD"));
  if (end_date) {
    form.append(`end_date`, end_date);
  }
  form.append(`show_shipping_on_estimate`, show_shipping_on_estimate);
  form.append(`sub_total`, estimate_subtotal);
  form.append(`total`, estimate_total);
  form.append(`discount_total`, discount_total);
  form.append(`adjustment`, adjustment);
  form.append(`recurring`, recurring);
  if (customer_company_name) {
    form.append(`custom_company_name`, customer_company_name);
  }
  if (items && items.length > 0) {
    form.append(`item`,  JSON.stringify(items));
  }
  if (billing_address && billing_address != null) {
    form.append(`billing_address`, JSON.stringify(billing_address));
  }
  form.append(`estimate_type`,  estimate_type.value);
  form.append(`payment_mode`,  payment_mode.value);
  if (saveAndSend) {
    form.append(`save_and_send`, saveAndSend)
  }
  if (client_note) {
    form.append(`clientnote`, client_note)
  }
  if (email_send_to_id) {
    form.append(`email_send_to_id`, email_send_to_id)
  }
  if (reminder_date) {
    form.append(`reminder_date`, reminder_date)
  }
  if (attachpdf) {
    form.append(`attachpdf`, attachpdf)
  }
  return apiService(endPoint, "POST", true, form);
};

const getBechmarkDetailsAndLogin = (hash) => {
  const endPoint = API_URL + "mod=benchmark&act=get_benchmark_details_and_login";
  let form = new FormData();
  form.append(`hash`, hash);
  form.append(`is_external`, 1);
  return apiService(endPoint, "POST", true, form);
};

const updateScheduleBydate = (project_id, benchmark_id, new_list,date) => {
  const endPoint = API_URL + "mod=benchmark&act=edit_schedule_by_date";
  let form = new FormData();
  form.append(`project_id`, project_id);
  form.append(`benchmark_id`, benchmark_id);
  form.append(`new_list`, JSON.stringify(new_list));
  if (date) {
    form.append(`date`, moment(date).format("YYYY-MM-DD"));
  }
  return apiService(endPoint, "POST", true, form);
}

const addBasicDetailsWithAttachments = (
  project_id,
  provider_id,
  benchamrkProposalEmails,
  attachments
) =>{
  const endPoint = API_URL + "mod=benchmark&act=add_schedule_basic_info_attachment";
  let form = new FormData();

  form.append(`project_id`, project_id);
  form.append(`provider_id`, provider_id);

  if (benchamrkProposalEmails.length) {
    benchamrkProposalEmails.forEach((email, index) => {
      form.append(`benchamrk_proposal_emails[${index}]`, email);
    });
  }
  if (attachments) {
    Array.from(attachments).forEach((file, index) => {
      form.append(`file[${index}]`, file);
    })
    
  }
  
  return apiService(endPoint, "POST", true, form);
}


export default {
  addEditSpaceRoom,
  getSpaceRooms,
  deleteTypeOfSpace,
  acceptProposal,
  getEstimateUsingProposal,
  rejectProposal,
  sendExternalProposal,
  cancelExternalProposal,
  deleteBenchmark,
  updateTypeOfSpace,
  getProposalComments,
  setScheduleSlotAssign,
  addEditChecklistComment,
  deleteChecklistComment,
  copyBenchmarkSchedule,
  updateTypeOfSpaceData,
  getFloorDetails,
  addBulkProvidersToCategory,
  addBenchmarkChecklists,
  getOperatorsByCategory,
  updateTypeOfSpaceTitle,
  removeScheduleChecklistItem,
  getChecklistTemplate,
  createChecklistTemplate,
  getTemplateChecklistItems,
  getInvitedCustomerList,
  deleteCustomerInvitee,
  getOperatorsListByProject,
  getCategoryProviderList,
  upgradeOperatorPlan,
  removeProvider,
  inviteProvidersWithCategories,
  removeProviderCategory,
  discardExternalBenchmark,
  updateTaskChecklistTemplate,
  deleteChecklistTemplate,
  getBasicDetailsCustomFields,
  deleteBenchmarkUnitItem,
  addBenchmarkUnitItems,
  downloadPDP,
  setBenchmarkGroup,
  getBenchmarkGroupInfo,
  generateDashboardBenchmark,
  createSolarBenchmark,
  addAgentBidAmount,
  updateJobOperatorRequirement,
  operatorAcceptRejectJobProposal,
  getAgentJobDetail,
  agentAcceptRejectJobProposal,
  acceptAgentContract,
  uploadCompanyContract,
  updateBenchmarkChecklist,
  getTaskForEditSchedule,
  getOperatorList,
  updateSlotAssignmentForSchedule,
  createEstimate,
  getBechmarkDetailsAndLogin,
  updateScheduleBydate,
  addBasicDetailsWithAttachments
}