import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import customerServices from "../../services/customer-services";
import { Clock } from "phosphor-react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { showMessage } from "../../actions/messages";
import { _l } from "../../hooks/utilities";
import { Col, Row } from "reactstrap";
import Spinner from "react-bootstrap/Spinner";
import { isLoadingData } from "../../hooks/loader_helper";
import CommanLoader from "../Loader/CommanLoader";

const RobotScheduleModal = ({
  show,
  handleClose,
  equipmentId, 
  category, 
  project_id 
}) => {
  const dispatch = useDispatch();
  const [loder, setLoder] = useState(false);
  const date = moment(new Date()).format('YYYY-MM-DD')
  const category_id = useSelector((state) => state.customer.equipment_category_id)
  const [timeselect, setTimeselect] = useState([
      { day: "monday", day_key: "mon", startTime: new Date(), start: false, endTime: new Date(), stop: false },
      { day: "tuesday", day_key: "tue", startTime: new Date(), start: false, endTime: new Date(), stop: false },
      { day: "wednesday", day_key: "wed", startTime: new Date(), start: false, endTime: new Date(), stop: false },
      { day: "thursday", day_key: "thu", startTime: new Date(), start: false, endTime: new Date(), stop: false },
      { day: "friday", day_key: "fri", startTime: new Date(), start: false, endTime: new Date(), stop: false },
      { day: "saturday", day_key: "sat", startTime: new Date(), start: false, endTime: new Date(), stop: false },
      { day: "sunday", day_key: "sun", startTime: new Date(), start: false, endTime: new Date(), stop: false },
  ]);
  const handleChange = (name, value, key) => {
      let Arr = timeselect
      Arr[key] = {
          ...Arr[key],
          [name]: value
      }
      setTimeselect(Arr.slice());
  }

  useEffect(() => {
      if (category_id == category.id) {
      customerServices.GetRobotSchedule(category.id).then((res) => {
          if (res.status == 1 && res.data.length > 0) {
              let arr = []
              res.data.forEach((ele) => {
                  arr.push({ 
                      day: ele.day == "mon"
                      ? "monday"
                      : ele.day == "tue"
                      ? "tuesday"
                      : ele.day == "wed"
                      ? "wednesday"
                      : ele.day == "thu"
                      ? "thursday"
                      : ele.day == "fri"
                      ? "friday"
                      : ele.day == "sat"
                      ? "saturday"
                      : ele.day == "sun"
                      ? "sunday"
                      : "",
                      day_key: ele.day, 
                      startTime: new Date(moment(date + ele.start_time,'YYYY-MM-DDLT').format("YYYY-MM-DD h:mm A")), 
                      start: ele.start_time == "" ? false : true, 
                      endTime: new Date(moment(date + ele.end_time,'YYYY-MM-DDLT').format("YYYY-MM-DD h:mm A")), 
                      stop: ele.end_time == "" ? false : true 
                  })
              });
              setTimeselect(arr.slice());
          }
      })
  }
  }, [category_id])

  const handleSubmit = () => {
      setLoder(true);
      customerServices.SetRobotSchedule(equipmentId, category.id, project_id, timeselect).then((res) => {
      setLoder(false);
          if (res.status == 1) {
              handleClose()
              dispatch(showMessage('sucess', _l("l_success"), res.message));
          } else {
              dispatch(showMessage("unsucess", _l("l_error"), res.message));
          }
      })
  }
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style add-topic-search overflow-hidden"
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>{category.quantity_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 d-flex flex-column">
          <div className="custom-modal-section-sticky">
          <div>
                <Row className="">
                    <Col xl="4">
                        <div className="c-font f-13 fw-semibold title-fonts py-3 border-bottom mb-2 w-100">{_l("l_robot_schedule_day")}</div>
                    </Col>
                    <Col xl="4">
                        <div className="c-font f-13 fw-semibold title-fonts py-3 border-bottom mb-2 w-100 text-center">{_l("l_robot_schedule_launch")}</div>
                    </Col>
                    <Col xl="4">
                        <div className="c-font f-13 fw-semibold title-fonts py-3 border-bottom mb-2 w-100 text-center">{_l("l_robot_schedule_stop")}</div>
                    </Col>
                </Row>
                {timeselect.length > 0 && timeselect.map((item,key) => {
                    return (
                        <Row className="" key={key}>
                            <Col xl="4">
                                <div className="w-100 d-flex align-items-center justify-content-between form-control h-35 bg-transparent border-0 px-0">
                                    <div className="c-font f-13">{_l(`l_${item.day}`)}</div>
                                </div>
                            </Col>
                            <Col xl="4">
                                <div className="w-100 d-flex align-items-center justify-content-between form-control h-35 bg-transparent border-0 px-0 ">
                                    <div className="w-25 d-flex">
                                        <Form.Check className="me-auto" onChange={(e) => handleChange("start",e.target.checked,key)} checked={item.start} type="checkbox" />
                                    </div>
                                    {item.start ?
                                        <a href="#/"
                                            className="white-border-box custom-datepicker custom-timepicker"
                                        >
                                            <div className="d-flex align-items-center">
                                                <Clock size={18} weight="light" className="c-icons" />
                                                <DatePicker showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption="Time"
                                                    dateFormat="h:mm aa"
                                                    selected={item.startTime}
                                                    onChange={(date) => { handleChange("startTime", date, key) }}
                                                    calendarStartDay={1} />
                                            </div>
                                        </a>
                                        :
                                        <></>
                                    }
                                </div>
                            </Col>
                            <Col xl="4">
                            <div className="w-100 d-flex align-items-center justify-content-between form-control h-35 bg-transparent border-0 px-0 ">
                                    <div className="w-25 d-flex">
                                    <Form.Check disabled={!item.start} className=""
                                        onChange={(e) => {
                                            handleChange("stop",e.target.checked,key)
                                        }}
                                        checked={item.stop} type="checkbox" />
                                    </div>
                                    {item.stop ?
                                        <a href="#/"
                                            className="white-border-box custom-datepicker custom-timepicker"
                                        >
                                            <div className="d-flex align-items-center">
                                                <Clock size={18} weight="light" className="c-icons" />
                                                <DatePicker showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption="Time"
                                                    dateFormat="h:mm aa"
                                                    selected={item.endTime}
                                                    onChange={(date) => handleChange("endTime", date, key)}
                                                    calendarStartDay={1} />
                                            </div>
                                        </a>
                                        :
                                        <></>
                                    }
                                </div>
                            </Col>
                        </Row>
                    )
                })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
        <Button variant="secondary" size="sm" onClick={handleClose}>{_l('l_close')}</Button>
                <Button variant="primary" size="sm" disabled={loder} onClick={() => handleSubmit()}>{loder ? _l("l_please_wait") : _l("l_save")}
            {loder ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                className={`ms-2 `}
                aria-hidden="true"
              />
            ) : (
              <></>
            )}</Button>
        </Modal.Footer>
      </Modal>
    
    </>
  );
};

export default RobotScheduleModal;
