import { Check, CheckCircle, Hash, MagnifyingGlass, X } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { Button, Col } from "react-bootstrap";
import { _l } from "../../../hooks/utilities";
import { useSearchParams } from "react-router-dom";
import CommanLoader from "../../Loader/CommanLoader";
import CustomerServices from "../../../services/customer-services";
import CommanPlaceholder from "../../Placeholder/CommanPlaceholder";
import Autocomplete from "../../Dropdowns/Autocomplete";
import { useSelector } from "react-redux";
import { insertCollectionToDB, readCollectionFromDB } from "../../../hooks/indexed_db_helpers";

const ProviderCategoryList = ({ providerData, setProviderData, setStep, setUploadBulkCategory }) => {
  const [categoryTitle, setCategoryTitle] = useState("");
  const [searchParams] = useSearchParams();

  let recordId = searchParams.get("id");
  let type = searchParams.get("provider-type");

  const userType = localStorage.getItem("user_type");
  const selectedOffice = localStorage.getItem("selectedOffice");
  const email = localStorage.getItem("email");
  const client_id = localStorage.getItem("client_id");

  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [categorySearchInput, setCategorySearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const checkValidCategoryTitle = () => {
    if (categories.length && categories[0].length) {
      let duplicateTitleIndex = categories[0].findIndex(
        (w) =>
          w.title &&
          categoryTitle &&
          w.title.trim().toLowerCase() == categoryTitle.trim().toLowerCase()
      );
      if (duplicateTitleIndex > -1) {
        setProviderData({
          ...providerData,
          categoryId: categories[0][duplicateTitleIndex].filter_key,
          categoryName: categories[0][duplicateTitleIndex].title,
        });
      } else if (/^[a-zA-Z0-9\s'-]+$/.test(categoryTitle)) {
        setProviderData({
          ...providerData,
          categoryId: 0,
          categoryName: categoryTitle.substring(0, 50).trim(),
        });
      }
    }
  };

  const handleSelectCategory = (e, item) => {
    if (providerData.id > 0) {
      if (type === "external-benchmark") {
        if(benchmarkDetail.status != 4)
        {
          setProviderData({
            ...providerData,
            categoryId: e.target.value,
            categoryName: item.title,
          });
        }
        setStep("space-address");
      }
      else {
        setStep(userType == "operator" ? "basic-details" : "select-provider");
      }
    } else if (userType == "operator") {
      let spaceData = localStorage.getItem("spaces");
      const client_id = localStorage.getItem("client_id");
      spaceData = spaceData ? JSON.parse(spaceData) : [];
      let spaceDetails = spaceData.find((w) => w.project_id == selectedOffice);
      if (
        spaceDetails &&
        spaceDetails.is_added_from_operator != 0 &&
        spaceDetails.client_id == client_id
      ) {
        setProviderData({
          ...providerData,
          categoryId: e.target.value,
          categoryName: item.title,
        });
        setStep("select-provider");
      } else {
        setStep("basic-details");
        setProviderData({
          ...providerData,
          categoryId: e.target.value,
          categoryName: item.title,
          invited_providers: [{ email }],
        });
      }
    } else {
      setProviderData({
        ...providerData,
        categoryId: e.target.value,
        categoryName: item.title,
      });
      if (type === "external-benchmark") {
        setStep("space-address");
      } else if (["benchmark", "schedule"].includes(providerData.type)) {
        setStep("provider-source");
      } else {
        setStep("select-provider");
      }
    }
  };

  const CategoryListing = categories.map((item, index) => {
    if (
      item.is_default == 0 &&
      item.client_id != client_id 
    ) {
      return <React.Fragment></React.Fragment>;
    }
    else if(categorySearchInput && categorySearchInput.trim().length && item.title && !item.title.trim().toLowerCase().includes(categorySearchInput.trim().toLowerCase()))
    {
      return <React.Fragment></React.Fragment>;
    }
    return (
      <Col xs={4} key={index}>
        <div className="select-input-cox p-20 w-100">
          <input
            className="bottom-0 end-0 form-check-input h-100 position-absolute start-0 top-0 w-100 bg-transparent"
            name="provider-categories"
            value={item.filter_key}
            checked={providerData && item.filter_key == providerData.categoryId}
            type="radio"
            onClick={(e) => handleSelectCategory(e, item)}
          />
          <a href="#/" className="check-image">
            <div className="check-image-box">
              <Check size={148} className="c-icons color-white-03-solid" />
            </div>
          </a>
          <div className="align-items-center d-flex">
            <div className="h25w25">
              <Hash size={22} weight="light" className="c-icons" />
            </div>
            <div className="c-font f-14 text-truncate  ps-2">{item.title}</div>
          </div>
        </div>
      </Col>
    );
  });

  const { benchmarkDetail } = useSelector(
    (state) => state.customer
  );

  useEffect(() => {
    setLoadingCategories(true);

    const getProviderTopics = async() => {
      try {

        //Fetch List From IDB If Exists;

        const idbCategoryList = await readCollectionFromDB("default_categories", "filter_key");
        if(idbCategoryList.length)
        {
          setCategories(idbCategoryList);
        }

        const response = await CustomerServices.getDefaultTopicList(1);
        if (response.status) {
          let allCategories = response.data.map((w) => {
            return {
              title: w.name,
              filter_key: w.id,
              client_id: w.client_id,
              is_default: w.is_default,
            };
          });
          allCategories.sort((a, b) => a.title.localeCompare(b.title));
          if(!idbCategoryList.length)
          {
            setCategories(allCategories);
          }
          insertCollectionToDB("default_categories", allCategories, "filter_key");
        }
      }
      catch(e)  {
        console.error(e)
      }
      finally{
        setLoadingCategories(false);
      }
    };

    getProviderTopics();

  }, []);

  useEffect(() => {
    if(!categorySearchInput || !categorySearchInput.trim().length)
    {
      setSearchResults(categories);
    }
    else {
      setSearchResults(categories.filter((w) => w.title && w.title.trim().toLowerCase().includes(categorySearchInput.trim().toLowerCase())));
    }
  }, [categorySearchInput]);
  

  return (
    <React.Fragment>
      <div className="row my-0 g-4">
      {
        providerData.id > 0 || providerData.type === "add_provider"
        ?
        <React.Fragment></React.Fragment>
        :
        <div className="d-flex justify-content-end m-0 bgOfficelisting position-sticky top-0 z-index-2 lightthemebgwhite">
          <div className="d-flex align-items-center justify-content-between w-100 ">
          <div class="d-flex align-items-center justify-content-between flex-grow-1">
          <Col xs={12} key={-1}>
            <div className="align-items-center comment-input-box d-flex form-control">
              <div className="align-items-center d-flex flex-grow-1 position-relative">
                <div className="pe-2">
                  <MagnifyingGlass size={18} weight="light" className="c-icons" />
                </div>
                  <input
                    type="text"
                    placeholder={_l("l_type_to_search")}
                    className=" p-0 bg-transparent border-0   pe-5 shadow-none "
                    value={categorySearchInput}
                    onChange={(e) => {setCategorySearchInput(e.target.value)}}
                  />
                  {
                    categorySearchInput.length
                    ?
                    <a href="#/" className="send-arrow-btn with_position_right_zero" 
                    onClick={()=>{setCategorySearchInput("")}}>
                      <div className="img-width-18 m-auto">
                        <X size={16}
                          weight="light"
                          className="c-icons" />
                      </div>
                    </a> 
                    :
                    <React.Fragment></React.Fragment>
                  }
                
              </div>
            </div>
          </Col>
          </div>
         </div>
         {
          type === "external-benchmark"
          ?
          <></>
          :
            <div className="ms-2 d-flex ">
              <Button variant="white-05 light-theme-white-bg" className="text-nowrap" onClick={() => setUploadBulkCategory(true)}>{_l("l_bulk_category_upload")}</Button>
            </div>
         }
        </div>
      }
      
        {providerData.id > 0 &&
        providerData.categoryId &&
        providerData.categoryName && (type !== "external-benchmark" || benchmarkDetail.status == 4)? (
          <React.Fragment>
            <Col xs={4}>
              <div className="select-input-cox p-20 w-100">
                <input
                  className="bottom-0 end-0 form-check-input h-100 position-absolute start-0 top-0 w-100 bg-transparent"
                  name="provider-categories"
                  value={providerData.categoryId}
                  checked
                  type="radio"
                />
                <a href="#/" className="check-image">
                  <div className="check-image-box">
                    <Check size={14} className="c-icons color-white-03-solid" />
                  </div>
                </a>
                <div className="align-items-center d-flex">
                  <div className="h25w25">
                    <Hash size={22} weight="light" className="c-icons" />
                  </div>
                  <div className="c-font f-14 text-truncate  ps-2">
                    {providerData.categoryName}
                  </div>
                </div>
              </div>
            </Col>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {!loadingCategories && ["schedule", "invite_provider"].includes(providerData.type) ? (
              <Col xs={4} key={-1}>
                <div className="select-input-cox p-20 w-100">
                  <div className="align-items-center d-flex">
                    <div className="h25w25">
                      <Hash size={22} weight="light" className="c-icons" />
                      <Autocomplete
                        placeholder={_l("l_add_category")}
                        inputClass="form-control bottom-0 end-0 h-100 position-absolute start-0 top-0 w-100 c-font f-14 text-truncate ps-5 bg-transparent"
                        dropdownClass="max-height-20vh start-0 mt-3"
                        value={categoryTitle}
                        onChange={(e) => {
                          setCategoryTitle(e.target.value);
                          setProviderData({
                            ...providerData,
                            categoryId: 0,
                            categoryName: e.target.value,
                          });
                        }}
                        onSelect={(value) => {
                          setCategoryTitle(value.label);
                          setProviderData({
                            ...providerData,
                            categoryId: 0,
                            categoryName: value.label,
                          });
                        }}
                        options={categories
                          .map((w) => {
                            if (w.is_default == 1 || w.client_id == client_id) {
                              return { label: "" };
                            }
                            return { label: w.title, value: w.filter_key };
                          })
                          .filter(
                            (obj, index, self) =>
                              index ===
                                self.findIndex(
                                  (o) =>
                                    obj.label.trim().toLowerCase() ==
                                    o.label.trim().toLowerCase()
                                ) && obj.label !== ""
                          )}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            ) : (
              <React.Fragment></React.Fragment>
            )}
         {recordId && !categories.length ? (
              // <CommanLoader />
              <></>
            ) : categories.length && providerData.type !== "add_provider" ? (
              CategoryListing
            ) : loadingCategories ? (
              // <CommanLoader />
              <></>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            {
              categorySearchInput.trim().length && !searchResults.length
              ?
              <CommanPlaceholder
                imgType="no-user"
                mainSpacing="h-100 mt-auto"
                placeholderText={`${_l("l_no")} ${_l("l_category")}`}
              />
              :
              <React.Fragment></React.Fragment>
            }
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default ProviderCategoryList;
