import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Check, CheckCircle, Minus, PencilLine, Plus, X } from "phosphor-react";
import {
  _l,
  removeDuplicateObjects,
  showError,
} from "../../../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import CustomerServices from "../../../services/customer-services";
import { showMessage } from "../../../actions/messages";
import CommanPlaceholder from "../../Placeholder/CommanPlaceholder";

const Accessories = ({
  benchmarkAccesories,
  defaultAccessories,
  setBenchmarkAccesories,
  readOnlyMode,
  setButtonsForGenerate,
  updateDefaultAccessories,
}) => {
  const dispatch = useDispatch();

  const benchmarkDetail = useSelector(
    (state) => state.customer.benchmarkDetail
  );

  const [accessoriesList, setAccessoriesList] = useState(defaultAccessories);
  const [newAccessoryData, setNewAccessoryData] = useState({
    id: 0,
    description: "",
    quantity: 0,
    checked: false,
  });
  const [accessoryEditIndex, setAccessoryEditIndex] = useState(-1);

  const addNewAccessory = () => {
    if (newAccessoryData.description && newAccessoryData.description.length) {
      if (
        accessoriesList.findIndex(
          (x) =>
            x.description.trim().toLowerCase() ==
            newAccessoryData.description.trim()
        ) === -1
      ) {
        CustomerServices.createAccessories(
          newAccessoryData.description.trim()
        ).then((res) => {
          if (res.status && res.data) {
            setAccessoriesList([
              ...accessoriesList.filter((a) => a.id != 0),
              {
                id: res.data.item_id,
                description: newAccessoryData.description,
                quantity: newAccessoryData.quantity,
                checked: true,
              },
            ]);
            setBenchmarkAccesories(
              benchmarkAccesories.concat([
                {
                  id: res.data.item_id,
                  description: newAccessoryData.description,
                  quantity: newAccessoryData.quantity,
                  checked: true,
                },
              ])
            );
            setNewAccessoryData({
              id: 0,
              checked: false,
              description: "",
              quantity: 1,
            });
            updateDefaultAccessories();
          } else {
            dispatch(showMessage("unsucess", _l("l_error"), res.message));
          }
        });
      } else {
        showError("l_accessory_already_exists");
      }
    }
  };

  const addAccessoryToBenchmark = (accessory, checked, index) => {
    setButtonsForGenerate();
    if (checked) {
      setBenchmarkAccesories(
        benchmarkAccesories.concat([{ ...accessory, checked }])
      );
    } else {
      setBenchmarkAccesories(
        benchmarkAccesories.filter((x) => x.id != accessory.id)
      );
    }
    setAccessoriesList(
      Object.values({ ...accessoriesList, [index]: { ...accessory, checked } })
    );
  };

  const updateQuantity = (accessory, quantity, index) => {
    setAccessoriesList(
      Object.values({ ...accessoriesList, [index]: { ...accessory, quantity } })
    );
    let benchmarkAccessoriesIndex = benchmarkAccesories.findIndex(
      (w) => w.id == accessory.id
    );
    if (benchmarkAccessoriesIndex > -1) {
      setButtonsForGenerate();
      setBenchmarkAccesories(
        Object.values({
          ...benchmarkAccesories,
          [benchmarkAccessoriesIndex]: {
            ...benchmarkAccesories[benchmarkAccessoriesIndex],
            quantity: Number(quantity),
          },
        })
      );
    }
  };

  const updateAccessory = async(updatedDescription, id) => {
    try {
      const response = await CustomerServices.createAccessories(updatedDescription, undefined, id);
      if(response.status)
      {
        setAccessoriesList(accessoriesList.map((acc) => {
          if(acc.id == id)
          {
            acc.description = updatedDescription;
          }
          return acc;
        }));
        setAccessoryEditIndex(-1);
        showMessage("l_title_updated");
      }
      else {
        showError(response.message);
      }
    }
    catch(e)
    {
      showError("l_something_went_wrong");
      console.error(e);
    }
  };

  useEffect(() => {
    if (
      benchmarkDetail &&
      benchmarkDetail.accessories &&
      benchmarkDetail.accessories.length
    ) {
      let defaultList = accessoriesList.slice();
      let existingAccessiresList = benchmarkDetail.accessories;
      existingAccessiresList.forEach((x) => {
        x.checked = true;
      });
      defaultList = defaultList.filter(
        (w) => existingAccessiresList.findIndex((x) => x.acc_id == w.id) === -1
      );
      setAccessoriesList(
        removeDuplicateObjects(existingAccessiresList.concat(defaultList))
      );
      setBenchmarkAccesories(existingAccessiresList);
    }
  }, [benchmarkDetail]);

  useEffect(() => {
    if (benchmarkAccesories && benchmarkAccesories.length) {
      let defaultList = [...defaultAccessories];
      let existingAccessiresList = [...benchmarkAccesories];
      existingAccessiresList.forEach((x) => {
        x.checked = true;
      });
      defaultList = defaultList.filter(
        (w) => existingAccessiresList.findIndex((x) => x.id == w.id) === -1
      );
      setAccessoriesList(
        removeDuplicateObjects(existingAccessiresList.concat(defaultList))
      );
      setBenchmarkAccesories(existingAccessiresList);
    }
  }, []);

  useEffect(() => {
    if (accessoriesList && accessoriesList.length) {
      setBenchmarkAccesories(accessoriesList.filter((a)=> a.quantity > 0))
    }else{
      setBenchmarkAccesories([])
    }
  }, [JSON.stringify(accessoriesList)])

  return (
    <React.Fragment>
      {readOnlyMode ? (
        <React.Fragment></React.Fragment>
      ) : (
        <div className="end-0 gy-0 gx-2 m-0 mb-4 z-index-2 bg-white-03-solid position-sticky top-0">
          <Form.Group className="col-xl-12 c-input-box position-relative ">
            <div className="d-flex align-items-center justify-content-between w-100 ">
              <div className="d-flex align-items-center justify-content-between form-control flex-grow-1 h_53px comman_action_icon">
                <div className="with_separator_10 flex-grow-1 ps-0">
                  <input
                    type="text"
                    className="bg-transparent border-0 "
                    placeholder={_l("l_item_name")}
                    name=""
                    value={newAccessoryData.description}
                    onChange={(e) => {
                      setNewAccessoryData({
                        ...newAccessoryData,
                        description: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-between with_separator_10 pe-0 w_170px">
                  <a
                    href="#/"
                    className={`c-font f-22 line-height-0 ${
                      readOnlyMode ? "for-disabled" : ""
                    }`}
                    onClick={() => {
                      if (newAccessoryData.quantity > 0)
                        setNewAccessoryData({
                          ...newAccessoryData,
                          quantity: Number(newAccessoryData.quantity) - 1,
                        });
                    }}
                  >
                    <div className="action_icon with_bg h32w32">
                      <Minus size={18} className="c-icons" weight="light" />
                    </div>
                  </a>
                  <input
                    type="number"
                    className="bg-transparent border-0 text-center "
                    placeholder="$5151"
                    name=""
                    value={newAccessoryData.quantity}
                    min={0}
                  />
                  <a
                    href="#/"
                    className={`c-font f-22 line-height-0 ${
                      readOnlyMode ? "for-disabled" : ""
                    }`}
                    onClick={() => {
                      setNewAccessoryData({
                        ...newAccessoryData,
                        quantity: Number(newAccessoryData.quantity) + 1,
                      });
                    }}
                  >
                    <div className="action_icon with_bg h32w32">
                      <Plus size={18} className="c-icons" weight="light" />
                    </div>
                  </a>
                </div>
              </div>
              <a
                href="#/"
                className="align-items-center d-flex form-control h-100 h50w50 justify-content-center ms-3 px-2"
                onClick={addNewAccessory}
              >
                <div className="h-100">
                  <Check size={20} className="c-icons" weight="light" />
                </div>
              </a>
            </div>
          </Form.Group>
        </div>
      )}
      <div className="flex-grow-1">
        <div
          className={`${
            !accessoriesList.length ? "h-100" : "row m-0 g-0  overflow-auto"
          }`}
        >
          {accessoriesList.length ? (
            accessoriesList.map((x, index) => {
              return (
                <div
                  key={index}
                  className="task-list-card w-100 comman_action_icon shadow-none borderbottomtransparent"
                >
                  <div class="align-items-center d-flex justify-content-between">
                    {readOnlyMode ? (
                      <></>
                    ) : (
                      <div className="d-flex align-items-center align-items-center d-flex w100minus100per">
                        <div className="d-flex align-items-center text-truncate flex-gorw-1 w-100">
                          {
                            accessoryEditIndex == index
                            ?
                            <Form.Group className="c-input-box position-relative flex-grow-1">
                            <Form.Control
                              type="text" 
                              className=" form-control h32wauto py-0 w-100"
                              name=""
                              value={x.description}
                              onChange={(e) => {setAccessoriesList(accessoriesList.map((acc, pos) => {
                                if(pos == index)
                                {
                                  acc.description = e.target.value;
                                }
                                return acc;
                              }))}}
                            />
                          </Form.Group>
                            :
                            <div class="color-white-80 fw-semibold text-truncate title-fonts">
                              {x.description}
                            </div>
                          }
                          {
                            readOnlyMode || x.is_default
                            ?
                            <></>
                            :
                            <div className="comman_action_icon ms-10px me-10px">
                              {
                                accessoryEditIndex != index
                                ?
                                <a
                                  href="javascript:;"
                                  className="action_icon with_bg h32w32"
                                  onClick={() => setAccessoryEditIndex(index)}
                                >
                                  <PencilLine
                                    size={18}
                                    className="c-icons"
                                    weight="light"
                                  />
                                </a>
                                :
                                <a
                                  href="javascript:;"
                                  className="action_icon with_bg h32w32"
                                  onClick={() => updateAccessory(x.description, x.id)}
                                >
                                  <Check
                                    size={18}
                                    className="c-icons"
                                    weight="light"
                                  />
                                </a>
                              }
                            </div>
                          }
                        </div>
                      </div>
                    )}

                    <div className="d-flex align-items-center justify-content-between w_170px">
                      {readOnlyMode ? (
                        <React.Fragment></React.Fragment>
                      ) : (
                        <a
                          href="#/"
                          className="c-font f-22 line-height-0"
                          onClick={() => {
                            if (Number(x.quantity) > 0) {
                              updateQuantity(x, Number(x.quantity) - 1, index);
                            }
                          }}
                        >
                          <div className="action_icon with_bg h32w32 light-theme-white-bg">
                            <Minus
                              size={18}
                              className="c-icons"
                              weight="light"
                            />
                          </div>
                        </a>
                      )}
                      <input
                        type="number"
                        className="bg-transparent border-0 text-center "
                        value={x.quantity}
                        onChange={() => {}}
                        disabled
                      />
                      {readOnlyMode ? (
                        <React.Fragment></React.Fragment>
                      ) : (
                        <a
                          href="#/"
                          className="c-font f-22 line-height-0"
                          onClick={() => {
                            updateQuantity(x, Number(x.quantity) + 1, index);
                          }}
                        >
                          <div className="action_icon with_bg h32w32 light-theme-white-bg">
                            <Plus
                              size={18}
                              className="c-icons"
                              weight="light"
                            />
                          </div>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <CommanPlaceholder
              mainSpacing="h-100 m-auto"
              imgType="todo-task"
              placeholderText={`${_l("l_no")} ${_l("l_accessories")}`}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Accessories;
