import { CalendarBlank, DotsThreeVertical, Trash, UserCircleGear } from 'phosphor-react'
import React, { useState } from 'react'
import { _l } from '../../../hooks/utilities'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import GeneralListing from '../../TaskModalComponents/GeneralListing'
import Lightbox from "yet-another-react-lightbox";
import { useNavigate } from 'react-router-dom'
import ActionDropdown from '../../Dropdowns/ActionDropdown'

const LeaseCard = ({
    leaseItem, 
    space, 
    openDetailLease = () => {},
    actionItems,
    isFrom
}) => {
    const [openCalendar, setOpenCalendar] = useState(false)
    const [openGallery, setOpenGallery] = useState(false);
    const [lightBoxAttachments,setLightBoxAttachments] = useState([])
    const navigate = useNavigate()
  return (
    <>
        <div className='task-list-card list-view cursor-pointer' onClick={openDetailLease}>
            <div className='task-list-wrapper'>
            <div className='task-list-wrapper '>
                  <div className='d-flex w-100 align-items-start'>
                    <div className='d-flex task-list-detail'>
                      <div className='task-left flex-grow-1 width100minus160 '>
                        <div className='d-flex align-items-center w-100 task-left-part-toggle text-truncate'>
                            <div className='flex-wrap d-flex  w-100 align-items-center comman_action_icon'>
                              {(space && space.logo && space.logo != '' || isFrom == "driveLease") && <div className=' h32w32 '>
                                  <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{backgroundImage:`url(${space && space.logo || 'https://myrpp.myr.ai/orb/assets/images/default-comapny-placeholder.svg'})`}}></div>
                              </div>}
                              <div className='d-flex flex-column w100minus100per task-list-name text-truncate'>
                                <div className='fw-semibold mb-1 text-truncate w-100'>{space && space.office_title || ''}</div>
                                <div class="c-font color-white-60 f-10 lh-lg d-flex align-items-center">
                                    <div className='pe-10px'>{_l("l_start_date")}:<span class=" p-0 ">{moment(leaseItem.detail && leaseItem.detail.start_date).format("DD/MM/yy hh:mm a") || ''}</span></div>
                                        -
                                    <div className='ps-10px'>{_l("l_end_date")}:<span class=" p-0 ">{moment(leaseItem.detail && leaseItem.detail.end_date).format("DD/MM/yy hh:mm a") || ''}</span></div>
                                </div>
                              </div>
                            </div>
                      
                        </div>
                      </div>
                      <div className='d-flex align-items-center task-bottom-part-full '>
                        <div className='align-items-center comman_action_icon d-flex gap10 task-right-static-options'>
                        <div className={`d-flex align-items-center comman_action_icon multi_inner_wrapper`}>
                            {leaseItem && leaseItem.documents && leaseItem.documents.length > 0 &&
                            leaseItem.documents.map ((img , index) =>
                            {return(<>
                            {index == 0 ? 
                            <div className="assignee-img-with-remove me-10px">
                                <a href="#/"
                                className="h32w32 comman-round-box with-bg d-flex align-items-center bg-white-05 radius_3"
                                data-tip={""}
                                data-place="left"
                                data-effect="solid"
                                data-delay-show='1000'
                                data-class="tooltip-main"
                                onClick={(e)=>{
                                    e.stopPropagation();
                                    setOpenGallery(true);
                                    setLightBoxAttachments([img])
                                }}
                                >
                                <div
                                    className="comman-bg-img h-100 w-100 bg-style-cover radius_3"
                                    style={{
                                    backgroundImage: `url('${img.image_url}')`,
                                    }}
                                ></div>
                                </a>
                            </div> : <></>}
                            {index == 1 && leaseItem.documents.length > 1 ?
                            <>
                            <div className="assignee-img-with-remove me-10px">
                                <a href="#/"
                                className="h32w32 comman-round-box with_overlay d-flex align-items-center bg-white-05 radius_3"
                                data-tip={""}
                                data-place="left"
                                data-effect="solid"
                                data-delay-show='1000'
                                data-class="tooltip-main"
                                onClick={(e) => e.stopPropagation()}
                                >
                                <div class="comman-bg-img h-100 w-100 bg-style-cover radius_3 d-flex"
                                style={{backgroundImage:`url(${leaseItem.documents && leaseItem.documents[1].image_url})`}}
                                type="button"
                                id="GeneralListing"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="outside"
                                aria-expanded="false">
                                    <div class="unread_count m-auto">+{leaseItem.documents.length - 1}</div> 
                                </div>
                                <GeneralListing 
                                handler={
                                (attachData)=>{
                                    setLightBoxAttachments([attachData]);
                                    setOpenGallery(true);
                                }} 
                                roundedImage={false} list={leaseItem.documents.map((generalAttach, index) => {
                                return {
                                    name: generalAttach.file_name,
                                    image: generalAttach.image_url,
                                    id: generalAttach.id,
                                    otherData: generalAttach
                                };
                                })} />
                                </a>
                                </div>
                            </>
                            : <></>}
                            </>)}
                            )}
                        </div>
                            {leaseItem.detail && leaseItem.detail.notice_date_detail && leaseItem.detail.notice_date_detail.length > 0 && <div className={`position-relative d-flex assignee-list-wrapper card-view-less-padding`}>
                                <div className={`dropdown-center multi_inner_wrapper with-min-width ps-0 d-flex  with_minus`}>
                                    <div className="pe-10px">
                                        <div className="badge   rounded-pill fw-light h32wauto c-font f-12 d-flex align-items-center "
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            data-tip={`${_l("l_notice_end_date")}`}
                                            data-effect="solid"
                                            data-delay-show="1000"
                                            data-class="tooltip-main">
                                            <span className={` p-0 `}>{leaseItem.detail && leaseItem.detail.notice_date_detail && leaseItem.detail.notice_date_detail[0] && leaseItem.detail.notice_date_detail[0].notice_date}</span>
                                            <ReactTooltip />
                                        </div>
                                    </div>
                                    {/* <a
                                        href="#/"
                                        className="badge rounded-pill text-nowrap h32w32 list-toggle-view-none d-flex"
                                        // id="TaskAssigneeDropdown"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        data-tip={`${_l("l_view_all")}`}
                                        data-effect="solid"
                                        data-delay-show="1000"
                                        data-class="tooltip-main"   
                                    >
                                        <div className="unread_count m-auto c-font f-10">+1</div>
                                    </a> */}
                                    {leaseItem.detail && leaseItem.detail.notice_date_detail && leaseItem.detail.notice_date_detail.length > 1 ?
                                        <UncontrolledDropdown className="w-100  position-relative light-theme-blue-bg radius_5" isOpen={openCalendar}>
                                        <DropdownToggle
                                            data-toggle="dropdown "
                                            tag="a"
                                            className=""
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setOpenCalendar(!openCalendar);
                                            }}
                                        >
                                            {leaseItem.detail.notice_date_detail.length > 1 ? (
                                            <div className="badge rounded-pill text-nowrap h32w32 list-toggle-view-none d-flex">
                                                <div className="unread_count m-auto c-font f-10">
                                                    +{leaseItem.detail.notice_date_detail.length - 1}
                                                </div>
                                            </div>    
                                            ) : (
                                            <></>
                                            )}
                                        </DropdownToggle>
                                        <React.Fragment>
                                            <DropdownMenu
                                            container="body"
                                            direction="down"
                                            className={`dropdown-menu w-140`}
                                            >
                                            {leaseItem.detail.notice_date_detail &&
                                                leaseItem.detail.notice_date_detail.length > 1 &&
                                                leaseItem.detail.notice_date_detail
                                                .filter((notice) => notice.notice_date !== "")
                                                .map((notice, index) => {
                                                    return (
                                                    <React.Fragment>
                                                        <li className="hr_sepretr l_50px">
                                                        <DropdownItem
                                                            toggle={false}
                                                            className="text-truncate"
                                                        >
                                                            <div className="d-flex align-items-center">
                                                            <div className="c-list-icon comman_action_icon">
                                                                <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
                                                                <CalendarBlank
                                                                    size={18}
                                                                    weight="light"
                                                                    className="c-icons"
                                                                />
                                                                </div>
                                                            </div>
                                                            <div className="ps-2 w100minus20 text_wrap d-flex align-items-center justify-content-between">
                                                                <div className="c-list-detail text-truncate color-white">
                                                                {moment(notice.notice_date).format(
                                                                    "DD/MM/YYYY"
                                                                )}
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </DropdownItem>
                                                        </li>
                                                    </React.Fragment>
                                                    );
                                                })}
                                            </DropdownMenu>
                                        </React.Fragment>
                                        </UncontrolledDropdown> 
                                    : <></>}   
                                </div>
                                <ReactTooltip />
                            </div>}
                            <div className="pe-10px">
                                <div className="badge   rounded-pill fw-light h32wauto c-font f-12 d-flex align-items-center "
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    data-tip={`${_l("l_status")}`}
                                    data-effect="solid"
                                    data-delay-show="1000"
                                    data-class="tooltip-main">
                                    <span className={` p-0 `}>{leaseItem.status == '1' ? _l("l_active") : leaseItem.status == '2' ? _l("l_future") : _l("l_inactive")}</span>
                                    <ReactTooltip />
                                </div>
                            </div>
                            <div className={`comman_action_icon  card-view-less-padding pe-0 before-dnone `}>
                                <div className="c-font f-12 h32wauto d-flex align-items-center  fw-semibold badge radius_3">          
                                    <span className="color-green">{leaseItem.detail && leaseItem.detail.amount}</span>
                                </div>
                            </div>
                        </div>
                      </div>
                      {actionItems && actionItems.length ? (
                        <div className="dropstart comman_action_icon ml-10" onClick={(e) => e.stopPropagation()} >
                            <a href="#/"
                                type="button"
                                className="action_icon h32w32 with_bg with-border-active line-height-0 "
                                data-tip={_l("l_options")}
                                data-effect="solid"
                                data-delay-show='1000'
                                data-class="tooltip-main"
                                id="deLeteDropdown"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <DotsThreeVertical
                                size={18}
                                className="c-icons"
                                weight="light"
                                />
                            </a>
                            <ActionDropdown actions={actionItems} />
                            {/* <DeleteDropdown /> */}
                            </div>
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                    </div>
                  </div>
                </div>
            </div>
        </div>
       { openGallery && lightBoxAttachments && lightBoxAttachments.length > 0 && 
        <Lightbox
            open={openGallery}
            close={() => setOpenGallery(false)}
            slides={lightBoxAttachments.map ((img , index)  => {
            return { src: img.image_url || img.image};
            })}
        />}
    </>
  )
}

export default LeaseCard