import React, { useRef, useEffect } from "react";
import moment from "moment";
import 'moment/locale/fr'
import CalendarWithFilterIcon from "../assets/icons/CalendarWithFilterIcon";
import { CaretRight, CaretLeft, ChatCircle } from "phosphor-react";
import { _l, addActiveBorderforWeekCalendar, formateDate } from "../hooks/utilities";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTaskCount,
  filterDashboardTasks,
  projectTaskCounts,
  fetchMails,
  getAttendanceList,
  getAttendanceCountWeekCalendar,
  getTopicTasks,
  dayGridMonth,
  dayGridWeek,
  dayGridDay,
  showTasklistInCalendar,
  selectedCalendarDate,
  setDaterangedayDifference,
  setDaterangeFilter,
  setselectedDaterangeFilter,
  setSelectedDateView,
  setSelectedGloablView,
  setCalendarView,
  setSelectedDateForChatai,
  setShowWeekcalendardays,
  setDaterangeFilterApplied,
  changeWeekCalNavi,
  setLastMessageOrTaskDate,
  updateTaskList
} from "../actions/customer";
import ReactTooltip from "react-tooltip";
import jquery from "jquery";
import subDays from "date-fns/subDays";
import addDays from "date-fns/addDays";
// import "rsuite/dist/rsuite.min.css";

import {
  leftPanelFilters,
  setActiveTaskStatusFilters,
  unReadCommentTasks,
} from "../actions/customer";
import { useNavigate } from "react-router-dom";
import StepIcon from "../assets/icons/StepIcon";
import DateRangeDropdown from "./Dropdowns/DateRangeDropdown";
import { Dropdown, DropdownItem, DropdownToggle } from "reactstrap";
import { showConfirmation, toastCloseAction } from "../actions/messages";
import differenceInDays from "date-fns/differenceInDays";
import getMonth from "date-fns/getMonth";
import { fetchMessages } from "../actions/chat";

const Calendar = ({ projectId, clearStatusFilter, setTaskModalClose, seteMaildate = '', pageName = '', setRefetch, setShowLoader, setSelectStartDate = () => {} ,setSelectEndDate = ()=>{}, selectedEventDate }) => {
  const [filters, setFilters] = useState([]);
  const [unreadCommentTask, setUnreadCommentTask] = useState(false);
  const navigate = useNavigate();
  const {
    taskFilters,
    isAppliedUnreadComment,
    activeTaskStatusFilters,
    taskUnreadCmtCntAll, filterBenchmarkCategory,
    isDayGridWeek,
    isDayGridMonth,
    isDayGridDay,
    globalView,
    daterangeFilterApplied,
    getSelectedFloorId,
    dateView,
    createTaskModalIsopen,
    ChatAiDate,
    taskandMessages,
    displayThreeDays,
    daterangeAppliedFilters,
    defaultTopics,
    dashBoradView,
    unreadTaskComment,
    firstTaskDate,
    lastTaskDate,
    calendarDateIndex,
    commanoffcanvasIsOpen,
    globalSearchString
  } = useSelector((state) => state.customer);
  const {
    leadsFilters,
    leadStatusToggle,
    leadsBulkActionToggle
  } = useSelector((state) => state.leadsReducer);
  const ref = useRef(null);
  const ref1 = useRef(null);

  const filterTitles = [
    "my_self",
    "current_providers",
    "my_peoples",
    "equipments",
    "myr_network",
  ];
  const savedDate = localStorage.getItem("selectedDate");
  const viewPage = window.location.pathname;
  const calendarFilterOption = [
    "by people",
    "by team",
    "Type of services",
    "custom date",
  ];
  const [startDate, setStartDate] = useState(new Date());
  // const [fromDate, setFromDate] = useState("");
  // const [toDate, setToDate] = useState("");
  const [calendarData, setCalendarData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    savedDate ? formateDate(savedDate) : ""
  );
  useEffect(() => {
    dispatch(selectedCalendarDate(selectedDate));
  },[selectedDate])
  const [isMobileView, setisMobileView] = useState(
    window.innerWidth <= 1024 ? true : false
  );
  const [showFilterArea, setShowFilterArea] = useState(false);
  const [taskAssigneeData, setTaskAssigneeData] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [dateRange1, setDateRange1] = useState('');
  const [dateRange2, setDateRange2] = useState('');
  const [isDateRangeFilterApplied, setIsDateRangeFilterApplied] = useState(false);
  const [taskFilterAll, settaskFilterAll] = useState(0);
  const [appliedFilters, setappliedFilters] = useState("today");
  const [dayDifference, setdayDifference] = useState(0);
  const [monthDifference, setMonthDifference] = useState(0);
  const client_id = localStorage.getItem("client_id");
  const contact_id = localStorage.getItem("contact_id");
  const language = localStorage.getItem("language");
  var todayDate = moment().format("YYYY-MM-DD");
  const [startDateNew, setStartDateNew] = useState('');
  // var yesterdayDate = moment().subtract(1, "days").format("YYYY-MM-DD");
  const lang = localStorage.getItem('language');
  const [nextBtnClicked, setNextBtnClicked] = useState(false);
  const [prevBtnClicked, setPrevBtnClicked] = useState(false);
  const [fromDateState, setFromDateState] = useState();
  const [toDateState, setToDateState] = useState();
  const status_filter = [
    "estimate",
    "staff",
    "client",
    "fullcalendar",
    "AdminTaskPage",
    "subscription",
    "credit_notes",
    "_equipments",
    "invoice",
    "credit_notes",
    "subscription",
    "schedule",
    "benchmark",
  ];
  let cutomerData = useSelector((state) => state.customer.calendarData);
  let weekAttendanceCount = useSelector((state) => state.customer.getAttendanceCountWeekCalendarer);
  const inv_count = useSelector((state) => state.customer.inv_count);
  const [showDateRangeDropDown, setShowDateRangeDropDown] = useState(false)
  let chatAiselectedDate = localStorage.getItem("selectedDate")
  var currentDate   = new Date();
  var tomorrowDate  = new Date();
  var yesterdayDate = new Date();
  const [datesArr,setDatesArr] = useState([]);
  if (localStorage.getItem('language') == "english"){
  moment.locale('en');
  } else {
  moment.locale('fr');
  }
  // Increase the date by 1 day
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);

  // Decrease the date by 1 day
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);


  const dispatch = useDispatch();

  const handleWindowResize = () =>
    window.innerWidth <= 1024 ? setisMobileView(true) : setisMobileView(false);

  const handleDropDownOpen = () => {
    jquery("#TaskListMySpace").addClass("active");
  };
  const calendarHandleDropDownOpen = () => {
    jquery("#CalendarFilterDropdown").addClass("active");
  };
  const calendarHandleDropDownClose = () => {
    jquery("#CalendarFilterDropdown").removeClass("active");
  };

  const handleDropDownClose = () => {
    jquery("#TaskListMySpace").removeClass("active");
  };
  const isLeadPage = pageName == 'leadPage' ? 1 : 0
  const [invcount, setInvcount] = useState(0);
  useEffect(() => {
    if (!savedDate) {
      localStorage.setItem(
        "selectedDate",
        formateDate(moment(new Date()))
      );
    }
    
    window.addEventListener("resize", handleWindowResize);
    const element = ref.current;
    const element1 = ref1.current;

    // element.addEventListener("shown.bs.dropdown", handleDropDownOpen);
    // element.addEventListener("hide.bs.dropdown", handleDropDownClose);
    // element1.addEventListener("shown.bs.dropdown", calendarHandleDropDownOpen);
    // element1.addEventListener("hide.bs.dropdown", calendarHandleDropDownClose);

    return () => {
      window.removeEventListener("resize", handleWindowResize);

      // element.addEventListener("shown.bs.dropdown", handleDropDownOpen);
      // element.addEventListener("hide.bs.dropdown", handleDropDownClose);
      if (element1 != null) {
        element1.addEventListener(
          "shown.bs.dropdown",
          calendarHandleDropDownOpen
          );
          element1.addEventListener(
            "hide.bs.dropdown",
            calendarHandleDropDownClose
            );
      }
    };
  }, []);
  useEffect(() => {
      // setInvcount(inv_count ? inv_count : 0);
      if(inv_count != undefined)
      {
        if(pageName === "invoice" && inv_count && taskFilters.length == 0)
        {
          jquery('a#active_week_date div.inv_counter').html(`${inv_count ? inv_count.counter : 0}`);
        }  else {
          jquery('a#active_week_date div.inv_counter').html(``);
        }
      }
  }, [inv_count])
  useEffect(() => {
    if (projectId) {
      if (isLeadPage) {
        dispatch(getTaskCount(client_id, contact_id, projectId, selectedDate, [], '', '', '', '', 1));
      } else {
        // dispatch(getTaskCount(client_id, contact_id, projectId, selectedDate, [], '', '', '', '', 0, pageName));
      }
    }
   
  }, [projectId]);

  useEffect(() => {
    if (pageName == "MyPeople") {
      if (weekAttendanceCount) {
        setCalendarData(weekAttendanceCount)
      }
    } else{
    if (cutomerData) {
      if (!isMobileView) setCalendarData(cutomerData);
      else {
        setCalendarData(cutomerData.slice(2, -2));
      }
    }
  }
  }, [cutomerData, isMobileView ,weekAttendanceCount]);

  useEffect(() => {

    if (activeTaskStatusFilters && isAppliedUnreadComment == true) {
      setUnreadCommentTask(true);
      if (viewPage == "/") {
        isSelectedDateActive(activeTaskStatusFilters, "unReadComment");
      }
    }
    if (isAppliedUnreadComment) {
      if (['operatorListReporting', 'equipmentListReporting'].includes(pageName)) {

        // let dateRangeFilters = taskFilters && taskFilters.length ? taskFilters.filter((dateRangeFilter) => dateRangeFilter.main_key == "date_range_filter") : []
        // if (dateRangeFilters.length) {
        //   dispatch(
        //     leftPanelFilters({
        //       main_key: dateRangeFilters[0].main_key,
        //       filter_key: dateRangeFilters[0].filter_key,
        //       title: dateRangeFilters[0].title,
        //     })
        //   );
        // }
      } 
      else {
        // dispatch(
        //   leftPanelFilters(
        //     {
        //       main_key: "date_range_filter",
        //       filter_key: "all",
        //       title: "All",
        //     },
        //     "add"
        //   )
        // );
      }

    } else if (!isAppliedUnreadComment && taskFilters.length && !filterBenchmarkCategory) {

      let fromEmailPageNavigate = taskFilters.filter((task) => task.main_key == "my_emails")
      if (fromEmailPageNavigate.length) {
        return 0;
      } else {
        // dispatch(
        //   leftPanelFilters({
        //     main_key: "date_range_filter",
        //     filter_key: "all",
        //     title: "All",
        //   })
        // );
      }}
  }, [isAppliedUnreadComment]);  
  
    
    useEffect(() => {
      setIsDateRangeFilterApplied(daterangeFilterApplied)
    }, [daterangeFilterApplied])

    useEffect(() => {
      setappliedFilters(daterangeAppliedFilters)
    }, [daterangeAppliedFilters])
    
  useEffect(() => {
    // if (pageName == "calendar" && isDateRangeFilterApplied && dayDifference < 8){
      let weekDaysArray = [];
      let today = ""
      if(startDateNew != "" && startDateNew != undefined && startDateNew != null){
      today = new Date(startDateNew);
        for (let i = 0; i < 7; i++) {
          const date = new Date(today);
          date.setDate(today.getDate() + i);
          weekDaysArray.push(date);}        
    
    }
        localStorage.setItem("initialStartDate", weekDaysArray.slice(0, 1))
        localStorage.setItem("initialEndDate", weekDaysArray.slice(-1))
      // setCalendarData(weekDaysArray)
    // }
    
  }, [isDateRangeFilterApplied,dayDifference,startDateNew])
  useEffect(() => {
    if (selectedEventDate && selectedEventDate != "" && pageName != "ChatAi") {
      // let currentDate = moment(selectedEventDate)
      // let preday = currentDate.clone().subtract(1,"day")
      // setSelectStartDate(preday._d)
      dispatch(getTaskCount(client_id, contact_id, projectId, formateDate(selectedEventDate), [], '', '', '', '', 0, pageName));
    }
  }, [selectedEventDate])
  
  useEffect(() => {
    // let arr = cutomerData && cutomerData.filter((day)=>  day.date == formateDate(ChatAiDate))
    // if(arr && arr.length == 0){
    //   dispatch(getTaskCount(client_id, contact_id, projectId, formateDate(ChatAiDate), [], '', '', '', '', 0, pageName));
    // }
    if(pageName == "ChatAi" || globalView == "AichatView"){
      dispatch(getTaskCount(client_id, contact_id, projectId, formateDate(ChatAiDate), taskFilters, '', activeTaskStatusFilters, '', '', 0, pageName));
    }

    
  }, [formateDate(ChatAiDate)])
  
  const handleChangeDate = (date,changeView = true) => {
    // setappliedFilters("today")
    dispatch(setDaterangeFilterApplied(false));

    if (formateDate(date) == formateDate(new Date())) {
      dispatch(setDaterangeFilter("today"));
    }else{
      dispatch(setDaterangeFilter("date"));
    }
    dispatch(setShowWeekcalendardays(true));

    if (globalView != "calendarView" && globalView != "spaceView" && changeView) { 
      dispatch(setCalendarView("day_view"));
      localStorage.setItem("tasklistView", "liteVersionView")
      dispatch(setSelectedDateView("dayView"));
      // dispatch(setSelectedGloablView("liteVersionView"))
    }

    if (seteMaildate != '') {
      localStorage.removeItem("fromDate");
      localStorage.removeItem("toDate");
      setRefetch(true);
      seteMaildate(date);
    }

    if(window.location.pathname != '/dashboardfordaterange')
    {
      setTaskModalClose();
      clearStatusFilter();
    }
    const formattedDate = date.toISOString().split("T")[0];
    localStorage.setItem("selectedDate",date)
    dispatch(dayGridDay(true));
    dispatch(dayGridMonth(false));
    dispatch(dayGridWeek(false));

    setSelectedDate(formattedDate);
    setStartDateNew(date)
    // localStorage.setItem("selectedDate", date);
    if (pageName == "ChatAi" || globalView == "AichatView") {
      dispatch(setSelectedDateForChatai(formateDate(date)));
      setTimeout(() => {
        scrollElement(formateDate(date));
      }, 1000);
    }
    var isAppliedAlready = filters.findIndex(
      (filter) => filter.main_key === "date_range_filter"
    );
    // dispatch(setActiveTaskStatusFilters([], 1));
    if (isAppliedAlready != -1) {
      // dispatch(
      //   leftPanelFilters({
      //     main_key: "date_range_filter",
      //     filter_key: "date_range_filter",
      //     title: "date_range_filter",
      //   })
      // );
      // dispatch(projectTaskCounts(projectId, taskFilters, '', isLeadPage, pageName));
      localStorage.removeItem("fromDate");
      localStorage.removeItem("toDate");
    } else {
      // dispatch(
      //   leftPanelFilters({
      //     main_key: "date_range_filter",
      //     filter_key: "date_range_filter",
      //     title: "date_range_filter",
      //   }, "remove"))
      if( globalView != "calendarView") {
        addActiveBorderforWeekCalendar();
    }
    }
    // if (isLeadPage) {
    //   dispatch(
    //     getTaskCount(client_id, contact_id, projectId, formattedDate, taskFilters, '', '', '', '', 1)
    //   );
    // } 
    if(pageName == "MyPeople") {
        dispatch(getAttendanceCountWeekCalendar(projectId , formattedDate))
    } else if (globalView == "spaceView") {
      dispatch(
        getTaskCount(client_id, contact_id, projectId, formattedDate, taskFilters, '', '', '', '', 0,pageName,globalView,getSelectedFloorId)
      );
    } else {
      dispatch(getTaskCount(client_id, contact_id, projectId, formateDate(date), taskFilters, '', activeTaskStatusFilters, '', '', isLeadPage, pageName, '', '', leadsFilters, globalSearchString));  // <<-- this line commented bcz on change date two times API call  -->>
      dispatch(projectTaskCounts(projectId, taskFilters, '', isLeadPage, pageName, '',leadsFilters, globalSearchString));

    }
  };

  // const handleSkipWeek = (direction) => {
  //   let date = isMobileView
  //     ? new Date(calendarData[1].date)
  //     : new Date(calendarData[3].date);
  //   if (globalView != "calendarView") {
  //     if (direction === "prev") {
  //       date.setDate(date.getDate() - 1);
  //     } else {
  //       date.setDate(date.getDate() + 1);
  //     }
  //   } else {
  //     if (appliedFilters == "this_week") {
  //       dispatch(dayGridDay(false));
  //       dispatch(dayGridMonth(false ));
  //       dispatch(dayGridWeek(true));
  //       if (direction === "prev") {
  //         date.setDate(date.getDate() - 7);
  //       } else {
  //         date.setDate(date.getDate() + 7);
  //       }
  //     } else if (appliedFilters == "today") {
  //       dispatch(dayGridDay(true));
  //       dispatch(dayGridMonth(false));
  //       dispatch(dayGridWeek(false));
  //       if (direction === "prev") {
  //         date.setDate(date.getDate() - 1);
  //       } else {
  //         date.setDate(date.getDate() + 1);
  //       }
  //     }
  //   }
  //   handleChangeDate(date);
  // };
  const handleSkipWeek = (direction) => {
    if ((globalView != "calendarView" && dateView == "weekView") || (globalView == "calendarView" && isDayGridWeek)) {
      let StartDate = localStorage.getItem('fromDate')
      let EndDate = localStorage.getItem('toDate')
      if (direction === "prev") {
        setStartDateNew(moment(StartDate).subtract(7, 'days'))
        StartDate = moment(StartDate).subtract(7, 'days');
        EndDate = moment(EndDate).subtract(7, 'days');
      } else {
        setStartDateNew(moment(StartDate).add(7, 'days'))
        StartDate = moment(StartDate).add(7, 'days');
        EndDate = moment(EndDate).add(7, 'days');
      }
      localStorage.setItem("fromDate", formateDate(StartDate))
      localStorage.setItem("selectedDate", formateDate(moment(StartDate).add(3, 'days')))
      localStorage.setItem("toDate", formateDate(EndDate))
      var title = StartDate + " - " + EndDate;
      dispatch( 
        leftPanelFilters(
          {
            main_key: "date_range_filter",
            filter_key: "date_range_filter",
            title: title,
          },"add"
        )
      );
    } else if ((globalView != "calendarView" && dateView == "monthView") || (globalView == "calendarView" && isDayGridMonth)) {
      let currentStartDate = localStorage.getItem('fromDate')
      let currentEndDate = localStorage.getItem('toDate')
      if (direction === "prev") {
        currentStartDate = moment(currentStartDate).subtract(1, 'month')
        currentEndDate = moment(currentEndDate).subtract(1, 'month')
      } else {
        currentStartDate = moment(currentStartDate).add(1, 'month')
        currentEndDate = moment(currentEndDate).add(1, 'month')
      }
      localStorage.setItem("fromDate", formateDate(currentStartDate))
      // currentEndDate = moment(currentStartDate).endOf('month');
      localStorage.setItem("toDate", formateDate(currentEndDate))
      setFromDateState(currentStartDate);
      setToDateState(currentEndDate);
      var title = currentStartDate + " - " + currentEndDate;
      dispatch(
        leftPanelFilters(
          {
            main_key: "date_range_filter",
            filter_key: "date_range_filter",
            title: title,
          },
          "add"
        )
      );
    } else if((globalView != "calendarView" && dateView == "dayView") || (globalView == "calendarView" && isDayGridDay)) {
      let date = isMobileView
      ? new Date(calendarData[1].date)
      : new Date(calendarData[3].date);
      if (direction == "prev") {
        if( dayDifference >= 7 ){
          let rangeStartDate = subDays(new Date(datesArr[0]), dayDifference);
          let rangeEndDate = subDays(new Date(datesArr[1]), dayDifference + 1 );
          filterDatedRange([rangeStartDate, rangeEndDate])
        }
        else{
          date.setDate(date.getDate() - 1);
          // dispatch(setSelectedDateForChatai(formateDate(calendarData[2].date)))
          // date = new Date(calendarData[2].date)
        }
      } else {
          if( dayDifference >= 7 ){
            let rangeStartDate = addDays(new Date(datesArr[0]), dayDifference + 1);
            let rangeEndDate = addDays(new Date(datesArr[1]), dayDifference);
            filterDatedRange([rangeStartDate, rangeEndDate])
          }
        date.setDate(date.getDate() + 1);
      }
      handleChangeDate(date);
    }else if(dateView == "customDateView"){
      let StartDate = localStorage.getItem('fromDate')
      let EndDate = localStorage.getItem('toDate')
      if (direction === "prev") {
        setStartDateNew(moment(StartDate).subtract(dayDifference, 'days'))
        StartDate = moment(StartDate).subtract(dayDifference, 'days');
        EndDate = moment(EndDate).subtract(dayDifference, 'days');
      } else {
        setStartDateNew(moment(StartDate).add(dayDifference, 'days'))
        StartDate = moment(StartDate).add(dayDifference, 'days');
        EndDate = moment(EndDate).add(dayDifference, 'days');
      }
      localStorage.setItem("fromDate", formateDate(StartDate))
      // localStorage.setItem("selectedDate", formateDate(moment(StartDate).add(3, 'days')))
      localStorage.setItem("toDate", formateDate(EndDate))
      var title = moment(StartDate).format("DD/MM/YYYY") + " - " + moment(EndDate).format("DD/MM/YYYY");
      dispatch( 
        leftPanelFilters(
          {
            main_key: "date_range_filter",
            filter_key: "date_range_filter",
            title: title,
          },"add"
        )
      );

    }
  };

  const handleTaskFilter = (category, value) => {
    let currentFilters = activeFilters;
    const filterKey = category.toLowerCase().split(" ").join("_");
    const indexOfValue = currentFilters[filterKey].indexOf(value);
    if (indexOfValue === -1) {
      currentFilters[filterKey].push(value);
    } else {
      currentFilters[filterKey].splice(indexOfValue, 1);
    }
    setActiveFilters(currentFilters);
  };

  const applyFilters = (reset = false) => {
    if (reset) {
      let filterKeys = [];
      for (let filterKey in activeFilters) {
        filterKeys[filterKey] = [];
      }
      setActiveFilters(filterKeys);
    }
    dispatch(
      filterDashboardTasks(
        client_id,
        contact_id,
        projectId,
        reset ? undefined : activeFilters
      )
    );
  };
  let predefinedBottomRanges = [
    {
      label: _l('l_today'),
      value: [new Date(), new Date(),"today"],
    },
    {
      label: _l('l_this_week'),
      // value: [startOfWeek(new Date()), endOfWeek(new Date()),"this_week"],
      value: [new Date(new Date().setDate(new Date().getDate() - new Date().getDay() +1 )), new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 7)),"this_week"],
    },
    {
      label: _l('l_last_7_days'),
      value: [subDays(new Date(), 6), new Date(),"last_7_days"],
    },
    {
      label: _l('l_last_30_days'),
      value: [subDays(new Date(), 29), new Date(),"last_30_days"],
    },
  ];
  if(pageName != "MyPeople"){
    predefinedBottomRanges.push({
      label: _l('l_all'),
      value: [addDays(new Date(), -1), addDays(new Date(), -1), "all"],
    });
  }
  if (pageName == 'operatorListReporting') {
    predefinedBottomRanges = predefinedBottomRanges.filter((date) => date.label != _l('l_all'))
  }
  const filterDatedRange = (dateArr = []) => {
    setDateRange1("");
    setDateRange2("");
    setDatesArr(dateArr);
    if(taskFilters && taskFilters.length > 0)
      {
        if(status_filter.indexOf(pageName) > -1)
        {
          let vr = taskFilters.length;
          for (let i = 0; i < vr; i++) {
            taskFilters.splice(taskFilters[i], 1);
          } 
        }
      }
    if (dateArr && dateArr.length > 0) {
      // calendarRef.current = null
      // handleChangeView()
      let date1 = dateArr[0]
      if (formateDate(dateArr[0]) == formateDate(dateArr[1]) && (dateArr[2] == undefined)) {
        // date1 = moment(dateArr[0]).subtract(3,'days')
        dispatch(dayGridDay(true));
        dispatch(dayGridWeek(false));
        dispatch(dayGridMonth(false));
      }else{
        if(dateArr[2] == "today"){
          addActiveBorderforWeekCalendar();
          localStorage.setItem("selectedDate",new Date())
        }

      }
      setStartDateNew(date1);
      setSelectStartDate(formateDate(dateArr[0]));
      setSelectEndDate(dateArr[1]);
      dispatch(setselectedDaterangeFilter(dateArr[2]))
      var m1 = moment(dateArr[0],'YYYY-MM-DD');
      var m2 = moment(dateArr[1],'YYYY-MM-DD');
      let tempdayDifference = m2.diff(m1,'days')
      const startMoment =  moment(dateArr[0]).format('MM');
      const endMoment =  moment(dateArr[1]).format('MM');
      const monthDifference = endMoment - startMoment
      setMonthDifference(monthDifference)
      dispatch(setDaterangedayDifference(tempdayDifference));
      if (dateArr[2]) {
        dispatch(setDaterangeFilter(dateArr[2]))
      }
      setdayDifference(tempdayDifference)
      // let monthDifference = (moment(dateArr[1]).format("MM") - moment(dateArr[0]).format("MM"))
      // let yearDifference = (moment(dateArr[1]).format("YYYY") - moment(dateArr[0]).format("YYYY"))
      if (m2.diff(m1,'days') == 6) {
        dispatch(setShowWeekcalendardays(false));
        dispatch(setDaterangeFilterApplied(false));
        setIsDateRangeFilterApplied(false);
      } else if(m2.diff(m1,'days') == 0){
        dispatch(setShowWeekcalendardays(true));
        dispatch(setDaterangeFilterApplied(false));
        setIsDateRangeFilterApplied(false);
      } else {
        dispatch(setShowWeekcalendardays(false));
        dispatch(setDaterangeFilterApplied(true));
        setIsDateRangeFilterApplied(true);
      }
      if (dateArr[2] == "today" && globalView != "calendarView") {
        dispatch(setCalendarView("day_view"));
        localStorage.setItem("tasklistView", globalView == "listView" ? "liteVersionView" : globalView)
        dispatch(setSelectedDateView("dayView"));
        // dispatch(setSelectedGloablView(globalView == "listView" ? "liteVersionView" : globalView))
        dispatch(showTasklistInCalendar(false));
      }else if((dateArr[2] == "this_week" || dateArr[2] == "last_7_days") && globalView != "calendarView"){
        localStorage.setItem("tasklistView", "liteVersionView" )
        // dispatch(setSelectedGloablView("liteVersionView" ));
        dispatch(setSelectedDateView("weekView"));  
      }else if (((tempdayDifference < 7 && tempdayDifference > 0) || tempdayDifference >= 7 )  && globalView != "calendarView"){
        localStorage.setItem("tasklistView", "liteVersionView")
        // dispatch(setSelectedGloablView("liteVersionView" ));
        // dispatch(setSelectedDateView("weekView"));
      }

      // if(pageName == "calendar" || globalView == "calendarView"){
      if (dateArr[2] == "today") {
        dispatch(dayGridDay(true));
        dispatch(dayGridMonth(false));
        dispatch(dayGridWeek(false));
        dispatch(showTasklistInCalendar(false));
        setStartDateNew(new Date())

      } else if(dateArr[2] == "this_week" || dateArr[2] == "last_7_days"){
        dispatch(dayGridMonth(false));
        dispatch(dayGridWeek(true));
        dispatch(dayGridDay(false));
        dispatch(showTasklistInCalendar(false));

      }else if (dateArr[2] == "last_30_days") {
        dispatch(dayGridMonth(true));
        dispatch(dayGridWeek(false));
        dispatch(dayGridDay(false));
        dispatch(showTasklistInCalendar(false));
        
        
      } else if (dateArr[2] == "all") {
        dispatch(dayGridMonth(true));
        dispatch(dayGridWeek(false));
        dispatch(dayGridDay(false));
        dispatch(showTasklistInCalendar(true));
      }else if(tempdayDifference >= 7){
        dispatch(dayGridWeek(false));
        dispatch(dayGridMonth(true));
        dispatch(dayGridDay(false));

      } else if (tempdayDifference < 3 && tempdayDifference > 0) {
        dispatch(dayGridWeek(false));
        dispatch(dayGridMonth(false));
        dispatch(dayGridDay(true));
        dispatch(showTasklistInCalendar(false));
        setappliedFilters("3_days");
      } else if (tempdayDifference >= 3 && tempdayDifference < 7) {
        dispatch(dayGridMonth(false));
        dispatch(dayGridWeek(true));
        dispatch(dayGridDay(false));   

      } else if (tempdayDifference == 0) {
        dispatch(dayGridDay(true));
        dispatch(dayGridMonth(false));
        dispatch(dayGridWeek(false));
        // }
      }
      
      var fromDate = formateDate(dateArr[0]);
      var toDate = formateDate(dateArr[1]);
      if (pageName == 'MyEmail') {
        // setShowLoader(true);
        // dispatch(fetchMails(taskFilters, dateArr[2] == "all" ? 1 : 0)).then((res) => {
        //   // setShowLoader(false);
        // });
      }
      if (fromDate == toDate && dateArr[2] != "all" && appliedFilters != "date") {
        handleChangeDate(dateArr[0]);
      } else {
          if(pageName=='dashboard'){
            navigate("/dashboardfordaterange");
          }
          if(window.location.pathname != '/dashboardfordaterange')
          { clearStatusFilter(); }
        dispatch(setActiveTaskStatusFilters([], 1));
        localStorage.setItem("fromDate", fromDate);
        localStorage.setItem("toDate", toDate);
        jquery(`#kpi_task_status_1`).removeClass("active");
        jquery(`#kpi_task_status_4`).removeClass("active");
        jquery(`#kpi_task_status_5`).removeClass("active");
        jquery(`#kpi_task_status_3`).removeClass("active");
        jquery(`#kpi_task_status_16`).removeClass("active");
        jquery(`#kpi_task_status_28`).removeClass("active");
        jquery(`#kpi_task_status_30`).removeClass("active");

        var filter_key = "date_range_filter";
        var title =
          moment(dateArr[0]).format("DD/MM/YYYY") +
          " - " +
          moment(dateArr[1]).format("DD/MM/YYYY");
        if (dateArr[2] == "all") {
          filter_key = "all";
          title = "All";
        }
        localStorage.setItem('taskFilters', JSON.stringify({
          main_key: "date_range_filter",
          filter_key: filter_key,
          title: title,
        }));
        dispatch(
          leftPanelFilters(
            {
              main_key: "date_range_filter",
              filter_key: filter_key,
              title: title,
            },
            "add"
          )
        );
        if (pageName == 'MyPeople') {
          dispatch(getAttendanceList(fromDate,toDate));
        }
        if (pageName != 'operatorListReporting' && window.location.pathname != '/dashboardfordaterange') {
          if (isLeadPage) {
            dispatch(
              projectTaskCounts(projectId, taskFilters, {
                main_key: "date_range_filter",
                filter_key: filter_key,
                title: title,
              }, 1)
            );
          }else {
            dispatch(
              projectTaskCounts(projectId, taskFilters, {
                main_key: "date_range_filter",
                filter_key: filter_key,
                title: title,
              }, 0, pageName)
            );
          }
        }
      }
    }
  };

  const isSelectedDateActive = (taskOrStatusFilters, mainKey) => {
    if (mainKey == "date_range_filter") {
      for (let task = 0; task < taskOrStatusFilters.length; task++) {
        if (taskOrStatusFilters[task].main_key == mainKey) {
          setTimeout(() => {

          }, 100);
          break;
        }
      }
    } else if (mainKey == "unReadComment") {
      if (taskOrStatusFilters.length > 0) {
        setTimeout(() => {

        }, 100);
      }
    }
  };
  const clearDateRangeFilter = () => {
    let dateRangeFilters = taskFilters && taskFilters.length ? taskFilters.filter((dateRangeFilter) => dateRangeFilter.main_key == "date_range_filter") : []
    if (dateRangeFilters.length) {
      dispatch(
        leftPanelFilters({
          main_key: dateRangeFilters[0].main_key,
          filter_key: dateRangeFilters[0].filter_key,
          title: dateRangeFilters[0].title,
        })
      );
    }
  }
  
  const addDaysCustom = (d, days) => {
    var d = new Date(d);
    d.setDate(d.getDate()+days);
    return d;
  };
  useEffect(()=>{
    const queryParameters = new URLSearchParams(window.location.search);
    if(queryParameters.get('invoiceDate')){
      setTimeout(() => {
        filterDatedRange([addDaysCustom(queryParameters.get('invoiceDate'),-30),addDaysCustom(queryParameters.get('invoiceDate'),0)]);
      }, 3000);
    }
    if (pageName == "MyPeople") {
      dispatch(getAttendanceCountWeekCalendar(localStorage.getItem("selectedOffice"),selectedDate))
    }
  },[]);


  useEffect(() => {
      jquery(document).mouseup(function (e) {
          var container = jquery('#dateRangePickerDropdown');
          if (!container.is(e.target) && container.has(e.target).length === 0) {
            setShowDateRangeDropDown(false)
          }
      });

  })

  useEffect(() => {
    let weekDaysArray = [];
    let today = ""
    if(startDateNew != "" && startDateNew != undefined && startDateNew != null){
    today = new Date(startDateNew);
      for (let i = 0; i < 7; i++) {
        const date = new Date(today);
        date.setDate(today.getDate() + i);
        weekDaysArray.push(date);}        
  
  }
   if (window.location.pathname == "/leads") {
    localStorage.setItem('selectedDate',dayDifference > 2 ? formateDate(weekDaysArray[0]) : formateDate(weekDaysArray[0]) );
    dispatch(getTaskCount(client_id, contact_id, projectId, (["ChatDate","today","date"].includes(appliedFilters) || dayDifference == 0) ? formateDate(localStorage.getItem('selectedDate')) :  dayDifference > 2 ? formateDate(weekDaysArray[3]) : formateDate(weekDaysArray[1])  , taskFilters, '', activeTaskStatusFilters, '', '', true, pageName,  '', '', leadsFilters, globalSearchString));
  }
  }, [pageName,leadsFilters, leadStatusToggle, globalSearchString, leadsBulkActionToggle])
  
  useEffect(() => {
    let weekDaysArray = [];
    let today = ""
    if(startDateNew != "" && startDateNew != undefined && startDateNew != null){
    today = new Date(startDateNew);
      for (let i = 0; i < 7; i++) {
        const date = new Date(today);
        date.setDate(today.getDate() + i);
        weekDaysArray.push(date);}        
  
  }
  localStorage.setItem('selectedDate',dayDifference > 2 ? formateDate(weekDaysArray[0]) : formateDate(weekDaysArray[0]) );
   if (window.location.pathname == "/leads") {
    dispatch(getTaskCount(client_id, contact_id, projectId, (["ChatDate","today","date"].includes(appliedFilters) || dayDifference == 0) ? formateDate(localStorage.getItem('selectedDate')) :  dayDifference > 2 ? formateDate(weekDaysArray[3]) : formateDate(weekDaysArray[1])  , taskFilters, '', activeTaskStatusFilters, '', '', true, pageName, '', '', leadsFilters, globalSearchString));
  }else{
    dispatch(getTaskCount(client_id, contact_id, projectId, (["ChatDate","today","date"].includes(appliedFilters) || dayDifference == 0) ? formateDate(localStorage.getItem('selectedDate')) :  dayDifference > 2 ? formateDate(weekDaysArray[3]) : formateDate(weekDaysArray[1])  , taskFilters, '', activeTaskStatusFilters, '', '', 0, pageName));
   }

  if(globalView == "calendarView"){
    if ((differenceInDays(datesArr[1], datesArr[0]) > 31) ||
        (getMonth(datesArr[1]) != getMonth(datesArr[0]) && differenceInDays(datesArr[1], datesArr[0]) > 6) || 
        (appliedFilters == "all")) {
      filterDatedRange([new Date(),new Date()])
      if (appliedFilters != "all") {
        dispatch(
          showConfirmation(
            _l("l_are_you_sure"),
            differenceInDays(datesArr[1], datesArr[0]) > 31 ? 
            _l("l_sorry_calendar_view_cant_be_shown_for_large_date_range_selections_do_you want_to_switch_to_list_view")
            : _l("l_sorry_calendar_view_cant_be_shown_for_two_diffrent_months_do_you_want_to_switch_to_list_view"),
            _l("l_yes"),
            _l("l_no"),
            undefined,
            (e) => {
              dispatch(setSelectedGloablView("listView"));
              navigate("/")
              filterDatedRange([datesArr[0],datesArr[1]])
              dispatch(toastCloseAction());
            }
          )
        );
      }
    }
  }
  }, [dayDifference,appliedFilters, JSON.stringify(taskFilters),globalView])

  const scrollElement = (date)=>{
    let id = moment(date).format("YYYY-MM-DD");
    let el = document.getElementById(id);
    if (el) {
      setTimeout(() => {
        el.scrollIntoView({ behavior: "auto", block: "start", inline: "start" })  
      }, 500);
    }
  }

  return (
    <>
    {
        // isLoadingData("task_list")
        //   ?
        //   <div className="c-top-calendar">
        //     <div className="bg-white-03 flex-grow-1 c-top-calendar">
        //     </div>
        //   </div>
        //   : 
        <div className={`${localStorage.getItem("contact_role") == 3 ? "" : "ps-10px "}pt-10px`}>
      <div className={`c-top-calendar w-100 px-0 ${pageName == "dashboardnew" ? "w-100 px-0" : ""} ${globalView == "liteVersionView" ? "daily-schedule-main w-800px px-15px" : ""} ${createTaskModalIsopen ? "comman-backdrop-search" : ""}`}>
          <div className="d-flex">
            <a
               className={` Cleftbox radius_3 z-index-3 w65 ${isAppliedUnreadComment == true ? "c-green-border-50 " : ""
              }`}
              onClick={() => {
                dispatch(updateTaskList([]))
              if (!["operatorListReporting", "equipmentListReporting",].includes(pageName)) {
                dispatch(leftPanelFilters("","","applyUnread"))
                if (pageName == 'operatorListReporting') {
                  clearDateRangeFilter()
                }
                dispatch(
                  unReadCommentTasks(isAppliedUnreadComment ? false : true)
                  );
                // 
                if (!isAppliedUnreadComment && pageName == 'MyEmail') {
                  setShowLoader(true);
                  dispatch(fetchMails(taskFilters)).then((res) => {
                    setShowLoader(false);
                  });
                }
              }
              }}
            >
              <div
                className="on-hover-active-toggle-img align-items-center bg-white-05 border-transparent c-font d-flex f-10 h-100 flex-column justify-content-center left-box radius_3 text-on-hover-active-green position-relative"
                type="button"
              >
                <ChatCircle size={18}  weight="light" className="c-icons my-1" />
                <div className="color-white-60 ">{_l('l_unread')}</div>
                {(unreadTaskComment > 0 && (pageName == "Dashboard" || pageName == "LiteVersion" || globalView == "liteVersionView" || (pageName == "ChatAi" || globalView == "AichatView"))) ? (
                  <div className={`${["operatorListReporting", "equipmentListReporting"].includes(pageName) ? "d-none" :""} rounded-pill badge-custom d-flex unread-badge`}>
                    {unreadTaskComment}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </a>
          <div className="calendar-date-wrapper ">
          { appliedFilters == "all" ? <React.Fragment></React.Fragment>
           : <>
              {(pageName != "calendar" || globalView != "calendarView") ?
                <a href="#/"
                  className="slider_btn_left slider_btn"
                  onClick={(e) => {
                    // setPrevBtnClicked(true);
                    // if ((appliedFilters == "all") || (appliedFilters == "last_30_days")) {
                    //   jquery(".fc-prev-button").on("click", function () {
                    //   });
                    //   jquery(".fc-prev-button").trigger("click");

                    // } else {
                    // }
                    handleSkipWeek("prev");
                    dispatch(changeWeekCalNavi("prev"));
                  }
                  }
                >
                  <CaretLeft size={14} weight="fill" className="c-icons" />
                </a> :
                <>{pageName == "calendar" || globalView == "calendarView" ? <>
                  <a href="#/"
                    className="slider_btn_left slider_btn"
                    onClick={(e) => {
                        handleSkipWeek("prev")
                    }
                    }
                  >
                    <CaretLeft size={14} weight="fill" className="c-icons" />
                  </a>
                </> : <></>}</>}
              {(pageName != "calendar" || globalView != "calendarView") ?
                <a href="#/"
                  className="slider_btn_right slider_btn"
                  onClick={(e) => {
                    handleSkipWeek("next");
                    dispatch(changeWeekCalNavi("next"));
                  }}
                >
                  <CaretRight size={14} weight="fill" className="c-icons" />
                </a>
                :
                <>{pageName == "calendar" || globalView == "calendarView" ? <>
                  <a href="#/"
                    className="slider_btn_right slider_btn"
                    onClick={(e) => {
                        handleSkipWeek("next")
                    }
                    }
                  >
                    <CaretRight size={14} weight="fill" className="c-icons" />
                  </a>
                </> : <></>}</>}
             </>
            }
{
  // ((pageName == "Dashboard" && globalView == "liteVersionView") || (globalView == "calendarView" && isDayGridDay) || (dateView == "dayView" && globalView != "calendarView" && globalView != "listView"  )) 
  // ?
<>
                      <div className="d-flex align-items-center calendar-date">
                      {/* chat feed calendar start */}
                        {/* {(pageName == "ChatAi" || globalView == "AichatView") && isDateRangeFilterApplied == false && ["ChatDate","today","date"].includes(appliedFilters) ?
                        <>
                          <a href="#/" className={"cal-date-box flex-grow-1"}
                          onClick={()=>{
                            scrollElement(moment(ChatAiDate).subtract(1, "day"))
                            dispatch(setSelectedDateForChatai(moment(ChatAiDate).subtract(1, "day")))
                            handleChangeDate(moment(ChatAiDate).subtract(1, "day"));
                          }}>
                            <div className="date-text c-font f-22 title-fonts"
                                data-tip={moment(ChatAiDate).subtract(1, "day").format("DD/MM/YYYY")}
                                data-effect="solid"
                                data-class="tooltip-main"
                                data-place="bottom">
                              {moment(ChatAiDate).subtract(1, "day").format("DD")}
                            </div>
                            <div className="date-details">
                              <div className="day-name c-font f-12 text-center text-uppercase">
                                {formateDate(moment(ChatAiDate).subtract(1, "day")) == formateDate(yesterdayDate) 
                                  ? _l("l_yesterday") 
                                  : formateDate(currentDate) == formateDate(moment(ChatAiDate).subtract(1, "day")) 
                                    ? _l("l_today") 
                                    : formateDate(tomorrowDate) == formateDate(moment(ChatAiDate).subtract(1, "day")) 
                                      ? _l("l_tomorrow") 
                                      : moment(ChatAiDate).subtract(1, "day").locale(lang == "french" ? "fr" : "en").format('dddd').substring(0, 3).toUpperCase()}
                              </div>
                              <div className={`date-task-dots align-items-center justify-content-center`}>
                              <div className="d-flex">
                                  {taskandMessages && taskandMessages.filter((task) => formateDate(task.startdate ? task.startdate : task.duedate ? task.duedate : "") == formateDate(moment(ChatAiDate).subtract(4, "day")) && task.is_requested == 1).length > 0 ? <div className="pe-1 d-flex align-items-center">
                                    <span className="  f-color-orange rounded-pill badge-custom with-orange-border with-position-relative fw-semibold m-auto h18w18 d-flex">
                                      {taskandMessages.filter((task) => formateDate(formateDate(task.startdate ? task.startdate : task.duedate ? task.duedate : "")) == formateDate(moment(ChatAiDate).subtract(4, "day")) && task.is_requested == 1).length}
                                    </span>
                                  </div> : <></>}
                                  {taskandMessages && taskandMessages.filter((task) => formateDate(task.startdate ? task.startdate : task.duedate ? task.duedate : "") == formateDate(moment(ChatAiDate).subtract(4, "day")) && task.status == 1  && task.is_requested == 0).length > 0 ? <div className="pe-1 d-flex align-items-center">
                                    <span className="  f-color-orange rounded-pill badge-custom with-to-do-border with-position-relative fw-semibold m-auto h18w18 d-flex">
                                      {taskandMessages.filter((task) => formateDate(task.startdate ? task.startdate : task.duedate ? task.duedate : "") == formateDate(moment(ChatAiDate).subtract(4, "day")) && task.status == 1 && task.is_requested == 0).length}
                                    </span>
                                  </div> : <></>}
                                  {taskandMessages && taskandMessages.filter((task) => formateDate(task.startdate ? task.startdate : task.duedate ? task.duedate : "") == formateDate(moment(ChatAiDate).subtract(4, "day")) && task.status == 4 && task.is_requested == 0).length > 0 ? <div className="pe-1 d-flex align-items-center">
                                    <span className="  f-color-orange rounded-pill badge-custom with-inprogress-border with-position-relative fw-semibold m-auto h18w18 d-flex">
                                      {taskandMessages.filter((task) => formateDate(task.startdate ? task.startdate : task.duedate ? task.duedate : "") == formateDate(moment(ChatAiDate).subtract(4, "day")) && task.status == 4 && task.is_requested == 0).length}
                                    </span>
                                  </div> : <></>}
                                  {taskandMessages && taskandMessages.filter((task) => formateDate(task.startdate ? task.startdate : task.duedate ? task.duedate : "") == formateDate(moment(ChatAiDate).subtract(4, "day")) && task.status == 5 && task.is_requested == 0).length > 0 ? <div className="pe-1 d-flex align-items-center">
                                    <span className="  f-color-orange rounded-pill badge-custom with-done-border with-position-relative fw-semibold m-auto h18w18 d-flex">
                                      {taskandMessages.filter((task) => formateDate(task.startdate ? task.startdate : task.duedate ? task.duedate : "") == formateDate(moment(ChatAiDate).subtract(4, "day")) && task.status == 5 && task.is_requested == 0).length}
                                    </span>
                                  </div> : <></>}
                                </div>
                              </div>
                            </div>
                          </a>
                          <a href="#/" className={"cal-date-box flex-grow-1 c-gradinet-active-border"} 
                          onClick={()=>{
                            scrollElement(moment(ChatAiDate));
                            dispatch(setSelectedDateForChatai(moment(ChatAiDate)))
                            handleChangeDate(new Date(ChatAiDate));
                          }} >
                            <div className="date-text c-font f-22 title-fonts "
                            data-tip={moment(ChatAiDate).format("DD/MM/YYYY")}
                            data-effect="solid"
                            data-class="tooltip-main"
                            data-place="bottom">
                              {moment(ChatAiDate).format("DD")}
                            </div>
                            <div className="date-details">
                              <div className="day-name c-font f-12 text-center text-uppercase">
                              {formateDate(moment(ChatAiDate)) == formateDate(yesterdayDate) ? _l("l_yesterday") 
                                        : formateDate(currentDate) == formateDate(moment(ChatAiDate)) ? _l("l_today") 
                                          : formateDate(tomorrowDate) == formateDate(moment(ChatAiDate)) ? _l("l_tomorrow") 
                                            : moment(ChatAiDate).locale(lang == "french" ? "fr" : "en").format('dddd').substring(0, 3).toUpperCase()}
                              </div>
                              <div className={`date-task-dots align-items-center justify-content-center `}>
                                 <div className="d-flex ">
                                  {taskandMessages && taskandMessages.filter((task) => formateDate(task.startdate ? task.startdate : task.duedate ? task.duedate : "") == formateDate(moment(ChatAiDate)) && task.is_requested == 1).length > 0 ? <div className="pe-1 d-flex align-items-center">
                                    <span className="  f-color-orange rounded-pill badge-custom with-orange-border with-position-relative fw-semibold m-auto h18w18 d-flex">
                                      {taskandMessages.filter((task) => formateDate(task.startdate ? task.startdate : task.duedate ? task.duedate : "") == formateDate(moment(ChatAiDate)) && task.is_requested == 1).length}
                                    </span>
                                  </div> : <></>}
                                  {taskandMessages && taskandMessages.filter((task) => formateDate(task.startdate ? task.startdate : task.duedate ? task.duedate : "") == formateDate(moment(ChatAiDate)) && task.status == 1  && task.is_requested == 0 ).length > 0 ?
                                   <div className="pe-1 d-flex align-items-center">
                                    <span className="  f-color-orange rounded-pill badge-custom with-to-do-border with-position-relative fw-semibold m-auto h18w18 d-flex 2">
                                      {taskandMessages.filter((task) => formateDate(task.startdate ? task.startdate : task.duedate ? task.duedate : "") == formateDate(moment(ChatAiDate)) && task.status == 1 && task.is_requested == 0).length}
                                    </span>
                                  </div> : <></>}
                                  {taskandMessages && taskandMessages.filter((task) => formateDate(task.startdate ? task.startdate : task.duedate ? task.duedate : "") == formateDate(moment(ChatAiDate)) && task.status == 4 && task.is_requested == 0).length > 0 ? <div className="pe-1 d-flex align-items-center">
                                    <span className="  f-color-orange rounded-pill badge-custom with-inprogress-border with-position-relative fw-semibold m-auto h18w18 d-flex">
                                      {taskandMessages.filter((task) => formateDate(task.startdate ? task.startdate : task.duedate ? task.duedate : "") == formateDate(moment(ChatAiDate)) && task.status == 4 && task.is_requested == 0).length}
                                    </span>
                                  </div> : <></>}
                                  {taskandMessages && taskandMessages.filter((task) => formateDate(task.startdate ? task.startdate : task.duedate ? task.duedate : "") == formateDate(moment(ChatAiDate)) && task.status == 5 && task.is_requested == 0).length > 0 ? <div className="pe-1 d-flex align-items-center">
                                    <span className="  f-color-orange rounded-pill badge-custom with-done-border with-position-relative fw-semibold m-auto h18w18 d-flex">
                                      {taskandMessages.filter((task) => formateDate(task.startdate ? task.startdate : task.duedate ? task.duedate : "") == formateDate(moment(ChatAiDate)) && task.status == 5 && task.is_requested == 0).length}
                                    </span>
                                  </div> : <></>}
                                </div>
                              </div>
                              <div class="date-task-dots d-flex align-items-center justify-content-center inv_counter"></div>
                            </div>
                          </a>
                          <a href="#/" className={"cal-date-box flex-grow-1"}
                          onClick={()=>{
                            scrollElement(moment(ChatAiDate).add(1, "day"))
                            dispatch(setSelectedDateForChatai(moment(ChatAiDate).add(1, "day")))
                            handleChangeDate(moment(ChatAiDate).add(1, "day"));
                          }}>
                            <div className="date-text c-font f-22 title-fonts"
                            data-tip={moment(ChatAiDate).add(1, "day").format("DD/MM/YYYY")}
                            data-effect="solid"
                            data-class="tooltip-main"
                            data-place="bottom">
                              {moment(ChatAiDate).add(1, "day").format("DD")}
                            </div>
                            <div className="date-details">
                              <div className="day-name c-font f-12 text-center text-uppercase">
                              {formateDate(moment(ChatAiDate).add(1, "day")) == formateDate(yesterdayDate) 
                                ? _l("l_yesterday") 
                                : formateDate(currentDate) == formateDate(moment(ChatAiDate).add(1, "day")) 
                                  ? _l("l_today") 
                                  : formateDate(tomorrowDate) == formateDate(moment(ChatAiDate).add(1, "day")) 
                                    ? _l("l_tomorrow") 
                                    : moment(ChatAiDate).add(1, "day").locale(lang == "french" ? "fr" : "en").format('dddd').substring(0, 3).toUpperCase()}
                              </div>
                              <div className={`date-task-dots align-items-center justify-content-center`}>
                                <div className="d-flex">
                                  {taskandMessages && taskandMessages.filter((task) => formateDate(task.startdate ? task.startdate : task.duedate ? task.duedate : "") == formateDate(moment(ChatAiDate).add(1, "day")) && task.is_requested == 1).length > 0 ? <div className="pe-1 d-flex align-items-center">
                                    <span className="  f-color-orange rounded-pill badge-custom with-orange-border with-position-relative fw-semibold m-auto h18w18 d-flex">
                                      {taskandMessages.filter((task) => formateDate(task.startdate ? task.startdate : task.duedate ? task.duedate : "") == formateDate(moment(ChatAiDate).add(1, "day")) && task.is_requested == 1).length}
                                    </span>
                                  </div> : <></>}
                                  {taskandMessages && taskandMessages.filter((task) => formateDate(task.startdate ? task.startdate : task.duedate ? task.duedate : "") == formateDate(moment(ChatAiDate).add(1, "day")) && task.status == 1  && task.is_requested == 0).length > 0 ? <div className="pe-1 d-flex align-items-center">
                                    <span className="  f-color-orange rounded-pill badge-custom with-to-do-border with-position-relative fw-semibold m-auto h18w18 d-flex">
                                      {taskandMessages.filter((task) => formateDate(task.startdate ? task.startdate : task.duedate ? task.duedate : "") == formateDate(moment(ChatAiDate).add(1, "day")) && task.status == 1 && task.is_requested == 0).length}
                                    </span>
                                  </div> : <></>}
                                  {taskandMessages && taskandMessages.filter((task) => formateDate(task.startdate ? task.startdate : task.duedate ? task.duedate : "") == formateDate(moment(ChatAiDate).add(1, "day")) && task.status == 4 && task.is_requested == 0).length > 0 ? <div className="pe-1 d-flex align-items-center">
                                    <span className="  f-color-orange rounded-pill badge-custom with-inprogress-border with-position-relative fw-semibold m-auto h18w18 d-flex">
                                      {taskandMessages.filter((task) => formateDate(task.startdate ? task.startdate : task.duedate ? task.duedate : "") == formateDate(moment(ChatAiDate).add(1, "day")) && task.status == 4 && task.is_requested == 0).length}
                                    </span>
                                  </div> : <></>}
                                  {taskandMessages && taskandMessages.filter((task) => formateDate(task.startdate ? task.startdate : task.duedate ? task.duedate : "") == formateDate(moment(ChatAiDate).add(1, "day")) && task.status == 5 && task.is_requested == 0).length > 0 ? <div className="pe-1 d-flex align-items-center">
                                    <span className="  f-color-orange rounded-pill badge-custom with-done-border with-position-relative fw-semibold m-auto h18w18 d-flex">
                                      {taskandMessages.filter((task) => formateDate(task.startdate ? task.startdate : task.duedate ? task.duedate : "") == formateDate(moment(ChatAiDate).add(1, "day")) && task.status == 5 && task.is_requested == 0).length}
                                    </span>
                                  </div> : <></>}
                                </div>
                              </div>
                              <div class="date-task-dots d-flex align-items-center justify-content-center inv_counter"></div>
                            </div>
                          </a>
                        </>  */}
                      {/* // chat feed calendar end
                        :  */}
                        <>
                        {calendarData && calendarData.length > 0 && calendarData.filter((day, key)=> (globalView == "calendarView" && isDayGridWeek &&commanoffcanvasIsOpen)? key >= (calendarDateIndex -1 ) && key <= (calendarDateIndex + 1) : displayThreeDays ? key > 1 && key < 5 : key > -1 && key < 8).map((day, index) => {
                          const dateString = new Date(day.date);
                          const dayName = moment(day.date).locale(lang == "french" ? "fr" : "en").format('dddd').substring(0, 3);
                          if (isDateRangeFilterApplied === false && daterangeFilterApplied == false) {
                            return (
                              <React.Fragment key={index}>
                                <a href="#/"
                                  data-tip={
                                    day.date != ""
                                      ? moment(day.date).format("DD/MM/YYYY")
                                      : ""
                                  }
                                  data-effect="solid"
                                  data-delay-show='1000'
                                  data-class="tooltip-main"
                                  data-place="bottom"
                                  id={
                                    index === Math.floor(calendarData.length / 2)
                                      ? `active_week_date`
                                      : ``
                                  }
                                  className={
                                    index === Math.floor(calendarData.filter((day, key)=> displayThreeDays ? key > 1 && key < 5 : key > -1 && key < 8).length / 2) && pageName != 'benchmarkList' && ( ["ChatDate","today","date"].includes(appliedFilters) ) && !taskFilters.some((w) => w.main_key === "unread_comments")
                                      ? `cal-date-box flex-grow-1 c-gradinet-active-border`
                                      : `cal-date-box flex-grow-1`
                                  }
                                  onClick={(e) => {
                                    if (pageName == 'operatorListReporting') {
                                      dispatch(
                                        unReadCommentTasks(false)
                                      );

                                    }
                                    if (pageName == "MyPeople") {
                                      // dispatch(getAttendanceList(moment(day.date).format("YYYY-MM-DD"),moment(day.date).format("YYYY-MM-DD")));
                                    }
                                    if(taskFilters.find((w) => w.main_key === "unread_comments"))
                                    {
                                      dispatch(leftPanelFilters("","","applyUnread"));
                                      dispatch(unReadCommentTasks(false));
                                    }
                                    dispatch(leftPanelFilters("","","remove_range"))
                                    handleChangeDate(dateString);

                                    if (pageName == "ChatAi" || globalView == "AichatView") {
                                      dispatch(setSelectedDateForChatai(formateDate(dateString)));
                                      dispatch(setDaterangeFilter("ChatDate"))
                                      localStorage.setItem("fromDate",formateDate(dateString));   
                                      dispatch(setLastMessageOrTaskDate(formateDate(dateString)));
                                      let el = document.getElementById(`seperator-${moment(dateString).format("DD/MM/YYYY")}`);
                                      if (el) {
                                        el.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });                                        
                                      }   
                                      dispatch(fetchMessages(         
                                        localStorage.getItem("chatUserId"), 
                                        localStorage.getItem("activeGroupId"),
                                        0,
                                        ()=>{},
                                        1,
                                        new Date(moment(dateString).add(1,"days")),
                                      ))               
                                    }
                                    // setUnreadCommentTask(false)
                                  }}
                                >
                                  <div className="date-text c-font f-22 title-fonts">
                                    {dateString.getDate()}
                                    {/* {moment(dateString).format("DD/MM/YYYY")} */}
                                  </div>
                                  <div className="date-details">
                                    <div className="day-name c-font f-12 text-center text-uppercase">
                                      {["ChatDate","today","date"].includes(appliedFilters) ? formateDate(dateString) == formateDate(yesterdayDate) ? _l("l_yesterday") 
                                        : formateDate(currentDate) == formateDate(dateString) ? _l("l_today") 
                                          : formateDate(tomorrowDate) == formateDate(dateString) ? _l("l_tomorrow") 
                                            : dayName.toUpperCase() : dayName.toUpperCase()}
                                    </div>
                                    {pageName == "leadPage" ?
                                      <div className={`date-task-dots d-flex align-items-center justify-content-center`}>
                                        {day.client_count === 0 && day.interested_count === 0 && day.lead_count === 0 && day.not_interested_count === 0 && day.ongoing_count === 0  ?
                                          <div className="">
                                            <span className="c-dots not-started rounded-circle"></span>
                                            <span className="c-dots not-started rounded-circle"></span>
                                            <span className="c-dots not-started rounded-circle"></span>
                                          </div>
                                        : <>
                                            {day.interested_count > 0 ? (
                                              <div className="pe-1 d-flex align-items-center">
                                                <span className="  f-color-white rounded-pill badge-custom with-inprogress-border with-position-relative fw-semibold m-auto h18w18 d-flex">
                                                  {day.interested_count}
                                                </span>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                            {day.lead_count > 0 ? (
                                              <div className="pe-1 d-flex align-items-center">
                                                <span className="  f-color-white rounded-pill badge-custom with-done-border with-position-relative fw-semibold m-auto h18w18 d-flex">
                                                  {day.lead_count}
                                                </span>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                            {day.not_interested_count > 0 ? (
                                              <div className="pe-1 d-flex align-items-center">
                                                <span className="  f-color-orange rounded-pill badge-custom with-orange-border with-position-relative fw-semibold m-auto h18w18 d-flex">
                                                  {day.not_interested_count}
                                                </span>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                            {day.ongoing_count > 0 ? (
                                              <div className="pe-1 d-flex align-items-center">
                                                <span className="  f-color-orange rounded-pill badge-custom with-lead-ongoing-border with-position-relative fw-semibold m-auto h18w18 d-flex">
                                                  {day.ongoing_count}
                                                </span>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                            {day.client_count > 0 ? (
                                              <div className="pe-1 d-flex align-items-center">
                                                <span className="  f-color-orange rounded-pill badge-custom with-to-do-border with-position-relative fw-semibold m-auto h18w18 d-flex">
                                                  {day.client_count}
                                                </span>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                          </>}
                                      </div> 
                                      :
                                    <div className={`date-task-dots d-flex align-items-center justify-content-center ${pageName == "MyPeople" || (pageName == "dashboardnew" && dashBoradView == "spaceView") ? "d-none" : ""}`}>
                                      {(day.completed_count === 0 &&
                                        day.inprogress_count === 0 &&
                                        day.todo_count === 0 && day.request_total == 0) || (pageName == "leadPage" && day.completed_count === 0 &&
                                          day.inprogress_count === 0) ? (<>
                                        <div className="">
                                          <span className="c-dots not-started rounded-circle"></span>
                                          <span className="c-dots not-started rounded-circle"></span>
                                          <span className="c-dots not-started rounded-circle"></span>
                                        </div>
                                     </> ) : day.completed_count +
                                        day.inprogress_count +
                                        day.todo_count + day.request_total ==
                                        0 ? (
                                        <>
                                        </>
                                      ) : (
                                        <>
                                          {day.request_total > 0 ? (
                                            <div className="pe-1 d-flex align-items-center">
                                              {/* <span
                                      key={index}
                                      className="c-dots in-progress-white rounded-circle mx-2 mt-1 ms-1"
                                    ></span> */}
                                              <span className="  f-color-orange rounded-pill badge-custom with-orange-border with-position-relative fw-semibold m-auto h18w18 d-flex">
                                                {day.request_total}
                                              </span>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                          {day.todo_count > 0 ? (
                                            <div className="pe-1 d-flex align-items-center">
                                              {/* <span
                                      key={index}
                                      className="c-dots in-progress-white rounded-circle mx-2 mt-1 ms-1"
                                    ></span> */}
                                              <span className="  f-color-white rounded-pill badge-custom with-to-do-border with-position-relative fw-semibold m-auto h18w18 d-flex">
                                                {day.todo_count}
                                              </span>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                          {day.inprogress_count > 0 ? (
                                            <div className="pe-1 d-flex align-items-center">
                                              {/* <span
                                      key={index}
                                      className="c-dots in-progress-blue rounded-circle mx-2 mt-1 ms-1"
                                    ></span> */}
                                              <span className="rounded-pill f-color-white badge-custom with-inprogress-border with-position-relative fw-semibold m-auto d-flex h18w18">
                                                {day.inprogress_count}
                                              </span>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                          {day.completed_count > 0 ? (
                                            <div className="d-flex align-items-center">
                                              {/* <span
                                      key={index}
                                      className="c-dots done rounded-circle mx-2 mt-1 ms-1"
                                    ></span> */}
                                              <span className=" rounded-pill f-color-white badge-custom  with-done-border with-position-relative fw-semibold m-auto d-flex h18w18">
                                                {day.completed_count}
                                              </span>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      )}
                                    </div> 
                                    }
                                    <div className={`date-task-dots d-flex align-items-center justify-content-center ${pageName == "MyPeople" ? "" : "d-none"}`}>
                                      {day.office > 0 ? (
                                        <div className="pe-1 d-flex align-items-center">
                                          <span className=" rounded-pill f-color-white badge-custom  with-done-border with-position-relative fw-semibold m-auto d-flex h18w18">
                                            {day.office}
                                          </span>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                      {day.remote > 0 ? (
                                        <div className="pe-1 d-flex align-items-center">
                                          <span className=" rounded-pill f-color-white badge-custom  with-inprogress-border with-position-relative fw-semibold m-auto d-flex h18w18">
                                            {day.remote}
                                          </span>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                      {day.home > 0 ? (
                                        <div className="pe-1 d-flex align-items-center">
                                          <span className=" rounded-pill f-color-white badge-custom  with-to-do-border with-position-relative fw-semibold m-auto d-flex h18w18">
                                            {day.home}
                                          </span>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                      {day.leave > 0 ? (
                                        <div className="pe-1 d-flex align-items-center">
                                          <span className=" rounded-pill f-color-white badge-custom  with-orange-border with-position-relative fw-semibold m-auto d-flex h18w18">
                                            {day.leave}
                                          </span>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div class="date-task-dots d-flex align-items-center justify-content-center inv_counter"></div>
                                  </div>
                                </a>
                                <ReactTooltip />
                              </React.Fragment>
                            );
                          } 
                          // else if (isDateRangeFilterApplied && taskFilterAll == 1 && (index > 1 && index < 5)) {
                          //   return (
                          //     <React.Fragment key={index}>
                          //       <a href="#/"
                          //         data-tip={
                          //           day.date != ""
                          //             ? moment(day.date).format("DD/MM/YYYY")
                          //             : ""
                          //         }
                          //         data-effect="solid"
                          //         data-class="tooltip-main"
                          //         data-place="left"
                          //         id={
                          //           index === Math.floor(calendarData.length / 2)
                          //             ? `active_week_date`
                          //             : ``
                          //         }
                          //         className={
                          //           index === Math.floor(calendarData.length / 2) && pageName != 'benchmarkList' && parseInt(activeTaskStatusFilters.length) == 0 && appliedFilters == "today"
                          //             ? `cal-date-box flex-grow-1 c-gradinet-active-border`
                          //             : `cal-date-box flex-grow-1`
                          //         }
                          //         onClick={(e) => {
                          //           if (pageName == 'operatorListReporting') {
                          //             dispatch(
                          //               unReadCommentTasks(false)
                          //             );

                          //           }
                          //           handleChangeDate(dateString);
                          //           // setUnreadCommentTask(false)
                          //         }}
                          //       >
                          //         <div className="date-text c-font f-22 title-fonts">
                          //           {dateString.getDate()}
                          //         </div>
                          //         <div className="date-details">
                          //           <div className="day-name c-font f-12 text-center text-uppercase">
                          //           {dateString.getDate() == yesterdayDate.getDate() ? _l("l_yesterday") : currentDate.getDate() == dateString.getDate() ? _l("l_today") :tomorrowDate.getDate() == dateString.getDate() ? _l("l_tomorrow") : dayName.toUpperCase()}
                          //           </div>
                          //           <div className={`date-task-dots d-flex align-items-center justify-content-center ${pageName == "MyPeople" || pageName == "dashboardnew" ? "d-none" : ""}`}>
                          //             {(day.completed_count === 0 &&
                          //               day.inprogress_count === 0 &&
                          //               day.todo_count === 0 && day.request_total == 0) || (pageName == "leadPage" && day.completed_count === 0 &&
                          //                 day.inprogress_count === 0) ? (<>
                          //               <div className="">
                          //                 <span className="c-dots not-started rounded-circle"></span>
                          //                 <span className="c-dots not-started rounded-circle"></span>
                          //                 <span className="c-dots not-started rounded-circle"></span>
                          //               </div>
                          //             </>) : day.completed_count +
                          //               day.inprogress_count +
                          //               day.todo_count + day.request_total ==
                          //               0 ? (
                          //               <>
                          //               </>
                          //             ) : (
                          //               <>
                          //                 {day.request_total > 0 ? (
                          //                   <div className="pe-1 d-flex align-items-center">
                          //                     <span className="  f-color-orange rounded-pill badge-custom with-orange-border with-position-relative fw-semibold m-auto h18w18 d-flex">
                          //                       {day.request_total}
                          //                     </span>
                          //                   </div>
                          //                 ) : (
                          //                   <></>
                          //                 )}
                          //                 {day.todo_count > 0 ? (
                          //                   <div className="pe-1 d-flex align-items-center">
                          //                     <span className="  f-color-white rounded-pill badge-custom with-to-do-border with-position-relative fw-semibold m-auto h18w18 d-flex">
                          //                       {day.todo_count}
                          //                     </span>
                          //                   </div>
                          //                 ) : (
                          //                   <></>
                          //                 )}
                          //                 {day.inprogress_count > 0 ? (
                          //                   <div className="pe-1 d-flex align-items-center">
                          //                     <span className="rounded-pill f-color-white badge-custom with-inprogress-border with-position-relative fw-semibold m-auto d-flex h18w18">
                          //                       {day.inprogress_count}
                          //                     </span>
                          //                   </div>
                          //                 ) : (
                          //                   <></>
                          //                 )}
                          //                 {day.completed_count > 0 ? (
                          //                   <div className="d-flex align-items-center">
                          //                     <span className=" rounded-pill f-color-white badge-custom  with-done-border with-position-relative fw-semibold m-auto d-flex h18w18">
                          //                       {day.completed_count}
                          //                     </span>
                          //                   </div>
                          //                 ) : (
                          //                   <></>
                          //                 )}
                          //               </>
                          //             )}
                          //           </div>
                          //           <div class="date-task-dots d-flex align-items-center justify-content-center inv_counter"></div>
                          //         </div>
                          //       </a>
                          //       <ReactTooltip />
                          //     </React.Fragment>
                          //   );
                          // } 
                          else if ((isDateRangeFilterApplied === true || daterangeFilterApplied == true) && (index == 0)){
                            let date1 = firstTaskDate && appliedFilters == "all" ? firstTaskDate : new Date(localStorage.getItem('fromDate'));
                            let date2 = lastTaskDate && appliedFilters == "all" ?  lastTaskDate : new Date(localStorage.getItem('toDate'));
                            const fromDateName = moment(date1).locale(lang == "french" ? "fr" : "en").format('dddd').substring(0, 3)
                            const toDateName = moment(date2).locale(lang == "french" ? "fr" : "en").format('dddd').substring(0, 3)
                            if( appliedFilters == "all" ){
                              return(
                              <div className="calendar-date-wrapper p-0">
                              <div className="d-flex align-items-center calendar-date">
                                <a href="#/" className="cal-date-box flex-grow-1 d-flex align-items-center">
                                  <div className=" date-text d-flex flex-column justify-content-center align-items-center">
                                    <span className="c-font f-22 title-fonts fw-bold text-capitalize letter_spacing2"> {_l("l_all")} </span>
                                  </div>
                                </a>
                              </div>
                            </div>
                              )
                            } else {
                            return (
                              <div className="calendar-date-wrapper p-0">
                                <div className="d-flex align-items-center calendar-date">
                                  <a href="#/" className="cal-date-box flex-grow-1 d-flex align-items-center">
                                    <div className=" date-text d-flex flex-column justify-content-center align-items-center">
                                      <span className="c-font f-10 fw-normal">{_l("l_from")}</span>
                                      <span className="c-font f-22 title-fonts fw-bold text-capitalize letter_spacing2"> {moment(date1).format("D/MM/YYYY")} </span>
                                    </div>
                                    {/* <div class="c-font color-white-80 day-name f-12 letter_spacing2 ps-3 text-center text-uppercase">{fromDateName}</div> */}
                                  </a>
                                  <a href="#/" className="cal-date-box  flex-grow-1 d-flex align-items-center">
                                    <div className="date-text d-flex flex-column justify-content-center align-items-center">
                                      <span className="c-font f-10 fw-normal">{_l("l_to")}</span>
                                       <span className="c-font f-22 title-fonts fw-bold text-capitalize letter_spacing2"> {moment(date2).format("D/MM/YYYY")} </span>
                                    </div>
                                    {/* <div class="c-font color-white-80 day-name f-12 letter_spacing2 ps-3 text-center text-uppercase">{toDateName}</div> */}
                                  </a>
                                </div>
                              </div>
                            )
                            }
                          }
                        })}
                        </>
                        {/* } */}
                        

                      </div>
                      </> 
                      // :

            //           <>
            //   <div className="d-flex align-items-center calendar-date">
            //     {calendarData.map((day, index) => {
            //       const dateString = new Date(day.date);
            //       if(selectedEventDate != "" && selectedEventDate != undefined){
            //         if(index == 2){
            //           setSelectStartDate(day.date)
            //         }

            //       }else if(appliedFilters == "today") {
            //         if(index == 4 && nextBtnClicked){
            //           setSelectStartDate(day.date)
            //             setNextBtnClicked(false);
            //         }
            //         if(index == 2 && prevBtnClicked){
            //           setSelectStartDate(day.date)
            //           setNextBtnClicked(false);
            //           setPrevBtnClicked(false);
            //         }
            //       } else if(appliedFilters == "this_week"){
            //         if(index == 0){
            //           setSelectStartDate(day.date)
            //         }
            //       }
            //       const dayName = moment(day.date).locale(lang == "french" ? "fr" : "en").format('dddd').substring(0, 3);
            //       if ((isDateRangeFilterApplied == false || dateView == "weekView") && (appliedFilters == "last_7_days" || appliedFilters == "this_week" || appliedFilters == "today" || appliedFilters == "date")) {
            //       return (
            //         <React.Fragment key={index}>
            //           <a href="#/"
            //             data-tip={
            //               day.date != ""
            //                 ? moment(day.date).format("DD/MM/YYYY")
            //                 : ""
            //             }
            //             data-effect="solid"
            //             data-class="tooltip-main"
            //             data-place="bottom"
            //             id={
            //               index === Math.floor(calendarData.length / 2)
            //                 ? `active_week_date`
            //                 : ``
            //             }
            //             className={
            //               index === Math.floor(calendarData.length / 2) && pageName != 'benchmarkList' && parseInt(activeTaskStatusFilters.length) == 0 && (appliedFilters == "today" || appliedFilters == "date" )
            //               ? `cal-date-box cal-date-box-view-toggle c-gradinet-active-border `
            //               : `cal-date-box cal-date-box-view-toggle`
            //             }
            //             onClick={(e) => {
            //               dispatch(setSelectedDateView(''));
            //               if (pageName == 'operatorListReporting') {
            //                 dispatch(
            //                   unReadCommentTasks(false)
            //                   );                              
            //                 }
            //                 if (pageName == "MyPeople") {                              
            //                 }
            //                 handleChangeDate(dateString);
            //                 // setUnreadCommentTask(false)
            //                 if(pageName == "calendar" || globalView == "calendarView" || (pageName == "ChatAi" || globalView == "AichatView")){
            //                     dispatch(commanoffcanvasisOpen(false))
            //                     dispatch(dayGridDay(true));
            //                     dispatch(dayGridMonth(false));
            //                     dispatch(dayGridWeek(false));
            //                     setSelectStartDate(day.date)
            //                     setappliedFilters("date");
            //                     setStartDateNew(day.date)
            //                 }
            //               }}
            //               >
            //             <div className="date-text c-font f-22 title-fonts">
            //               {dateString.getDate()}
            //             </div>
            //             <div className="date-details">
            //               <div className="day-name c-font f-12 text-center">
            //                 {dayName.toUpperCase()}
            //               </div>
            //               <div className={`date-task-dots d-flex align-items-center justify-content-center ${pageName == "MyPeople" ? "d-none" : ""}`}>
            //                 {(day.completed_count === 0 &&
            //                   day.inprogress_count === 0 &&
            //                   day.todo_count === 0 && day.request_total == 0) || (pageName == "leadPage" && day.completed_count === 0 &&
            //                     day.inprogress_count === 0) ? (<>
            //                   <div className="">
            //                     <span className="c-dots not-started rounded-circle"></span>
            //                     <span className="c-dots not-started rounded-circle"></span>
            //                     <span className="c-dots not-started rounded-circle"></span>
            //                   </div>
            //                 </>) : day.completed_count +
            //                   day.inprogress_count +
            //                   day.todo_count + day.request_total ==
            //                   0 ? (
            //                   <> </>
            //                 ) : (
            //                   <>
            //                     {day.request_total > 0 ? (
            //                       <div className="pe-1 d-flex align-items-center">
            //                         <span className="  f-color-orange rounded-pill badge-custom with-orange-border with-position-relative fw-semibold m-auto h18w18 d-flex">
            //                           {day.request_total}
            //                         </span>
            //                       </div>
            //                     ) : (
            //                       <></>
            //                     )}
            //                     {day.todo_count > 0 ? (
            //                       <div className="pe-1 d-flex align-items-center">
            //                         <span className="  f-color-white rounded-pill badge-custom with-to-do-border with-position-relative fw-semibold m-auto h18w18 d-flex">
            //                           {day.todo_count}
            //                         </span>
            //                       </div>
            //                     ) : (
            //                       <></>
            //                     )}
            //                     {day.inprogress_count > 0 ? (
            //                       <div className="pe-1 d-flex align-items-center">
            //                         <span className="rounded-pill f-color-white badge-custom with-inprogress-border with-position-relative fw-semibold m-auto d-flex h18w18">
            //                           {day.inprogress_count}
            //                         </span>
            //                       </div>
            //                     ) : (
            //                       <></>
            //                     )}
            //                     {day.completed_count > 0 ? (
            //                       <div className="d-flex align-items-center">
            //                         <span className=" rounded-pill f-color-white badge-custom  with-done-border with-position-relative fw-semibold m-auto d-flex h18w18">
            //                           {day.completed_count}
            //                         </span>
            //                       </div>
            //                     ) : (
            //                       <></>
            //                     )}
            //                   </>
            //                 )}
            //               </div>
            //               <div className={`date-task-dots d-flex align-items-center justify-content-center ${pageName == "MyPeople" ? "" : "d-none"}`}>
            //                   {day.office > 0 ? (
            //                       <div className="pe-1 d-flex align-items-center">
            //                         <span className=" rounded-pill f-color-white badge-custom  with-done-border with-position-relative fw-semibold m-auto d-flex h18w18">
            //                           {day.office}
            //                         </span>
            //                       </div>
            //                     ) : (
            //                       <></>
            //                     )}
            //                     {day.remote > 0 ? (
            //                       <div className="pe-1 d-flex align-items-center">
            //                         <span className=" rounded-pill f-color-white badge-custom  with-inprogress-border with-position-relative fw-semibold m-auto d-flex h18w18">
            //                           {day.remote}
            //                         </span>
            //                       </div>
            //                     ) : (
            //                       <></>
            //                     )}
            //                     {day.home > 0 ? (
            //                       <div className="pe-1 d-flex align-items-center">
            //                         <span className=" rounded-pill f-color-white badge-custom  with-to-do-border with-position-relative fw-semibold m-auto d-flex h18w18">
            //                           {day.home}
            //                         </span>
            //                       </div>
            //                     ) : (
            //                       <></>
            //                     )}
            //                     {day.leave > 0 ? (
            //                       <div className="pe-1 d-flex align-items-center">
            //                         <span className=" rounded-pill f-color-white badge-custom  with-orange-border with-position-relative fw-semibold m-auto d-flex h18w18">
            //                           {day.leave}
            //                         </span>
            //                       </div>
            //                     ) : (
            //                       <></>
            //                   )}
            //               </div>
            //               <div class="date-task-dots d-flex align-items-center justify-content-center inv_counter"></div>
            //             </div>
            //           </a>
            //           <ReactTooltip />
            //         </React.Fragment>
            //       );
            //     }else if ((isDateRangeFilterApplied && taskFilterAll == 1) || (globalView == "calendarView" && dayDifference < 7) ) {
            //       if( commanoffcanvasIsOpen && index > 1 && index < 5)
            //         {return (
            //           <React.Fragment key={index}>
            //             <a href="#/"
            //               data-tip={
            //                 day.date != ""
            //                   ? moment(day.date).format("DD/MM/YYYY")
            //                   : ""
            //               }
            //               data-effect="solid"
            //               data-class="tooltip-main"
            //               data-place="left"
            //               id={
            //                 index === Math.floor(calendarData.length / 2)
            //                   ? `active_week_date`
            //                   : ``
            //               }
            //               className={
            //                 index === Math.floor(calendarData.length / 2) && pageName != 'benchmarkList' && parseInt(activeTaskStatusFilters.length) == 0 && appliedFilters == "today"
            //                   ? `cal-date-box flex-grow-1 c-gradinet-active-border`
            //                   : `cal-date-box flex-grow-1`
            //               }
            //               onClick={(e) => {
            //                 if (pageName == 'operatorListReporting') {
            //                   dispatch(
            //                     unReadCommentTasks(false)
            //                   );

            //                 }
            //                 handleChangeDate(dateString);
            //                 if (globalView  == "calendarView") {
            //                   dispatch(commanoffcanvasisOpen(false))                              
            //                 }
            //                 // setUnreadCommentTask(false)
            //               }}
            //             >
            //               <div className="date-text c-font f-22 title-fonts">
            //                 {dateString.getDate()}
            //               </div>
            //               <div className="date-details">
            //                 <div className="day-name c-font f-12 text-center">
            //                 {dayName.toUpperCase()}
            //                 </div>
            //                 <div className={`date-task-dots d-flex align-items-center justify-content-center ${pageName == "MyPeople" ? "d-none" : ""}`}>
            //                   {(day.completed_count === 0 &&
            //                     day.inprogress_count === 0 &&
            //                     day.todo_count === 0 && day.request_total == 0) || (pageName == "leadPage" && day.completed_count === 0 &&
            //                       day.inprogress_count === 0) ? (<>
            //                     <div className="">
            //                       <span className="c-dots not-started rounded-circle"></span>
            //                       <span className="c-dots not-started rounded-circle"></span>
            //                       <span className="c-dots not-started rounded-circle"></span>
            //                     </div>
            //                   </>) : day.completed_count +
            //                     day.inprogress_count +
            //                     day.todo_count + day.request_total ==
            //                     0 ? (
            //                     <>
            //                     </>
            //                   ) : (
            //                     <>
            //                       {day.request_total > 0 ? (
            //                         <div className="pe-1 d-flex align-items-center">
            //                           <span className="  f-color-orange rounded-pill badge-custom with-orange-border with-position-relative fw-semibold m-auto h18w18 d-flex">
            //                             {day.request_total}
            //                           </span>
            //                         </div>
            //                       ) : (
            //                         <></>
            //                       )}
            //                       {day.todo_count > 0 ? (
            //                         <div className="pe-1 d-flex align-items-center">
            //                           <span className="  f-color-white rounded-pill badge-custom with-to-do-border with-position-relative fw-semibold m-auto h18w18 d-flex">
            //                             {day.todo_count}
            //                           </span>
            //                         </div>
            //                       ) : (
            //                         <></>
            //                       )}
            //                       {day.inprogress_count > 0 ? (
            //                         <div className="pe-1 d-flex align-items-center">
            //                           <span className="rounded-pill f-color-white badge-custom with-inprogress-border with-position-relative fw-semibold m-auto d-flex h18w18">
            //                             {day.inprogress_count}
            //                           </span>
            //                         </div>
            //                       ) : (
            //                         <></>
            //                       )}
            //                       {day.completed_count > 0 ? (
            //                         <div className="d-flex align-items-center">
            //                           <span className=" rounded-pill f-color-white badge-custom  with-done-border with-position-relative fw-semibold m-auto d-flex h18w18">
            //                             {day.completed_count}
            //                           </span>
            //                         </div>
            //                       ) : (
            //                         <></>
            //                       )}
            //                     </>
            //                   )}
            //                 </div>
            //                             <div class="date-task-dots d-flex align-items-center justify-content-center inv_counter"></div>
            //               </div>
            //             </a>
            //             <ReactTooltip />
            //           </React.Fragment>
            //         );}else if (commanoffcanvasIsOpen == false) {
            //           return (
            //           <React.Fragment key={index}>
            //             <a href="#/"
            //               data-tip={
            //                 day.date != ""
            //                   ? moment(day.date).format("DD/MM/YYYY")
            //                   : ""
            //               }
            //               data-effect="solid"
            //               data-class="tooltip-main"
            //               data-place="left"
            //               id={
            //                 index === Math.floor(calendarData.length / 2)
            //                   ? `active_week_date`
            //                   : ``
            //               }
            //               className={
            //                 index === Math.floor(calendarData.length / 2) && pageName != 'benchmarkList' && parseInt(activeTaskStatusFilters.length) == 0 && appliedFilters == "today"
            //                   ? `cal-date-box flex-grow-1 c-gradinet-active-border`
            //                   : `cal-date-box flex-grow-1`
            //               }
            //               onClick={(e) => {
            //                 if (pageName == 'operatorListReporting') {
            //                   dispatch(
            //                     unReadCommentTasks(false)
            //                   );

            //                 }
            //                 handleChangeDate(dateString);
            //                 if (pageName  == "calendar") {
            //                   dispatch(commanoffcanvasisOpen(false))                              
            //                 }
            //                 // setUnreadCommentTask(false)
            //               }}
            //             >
            //               <div className="date-text c-font f-22 title-fonts">
            //                 {dateString.getDate()}
            //               </div>
            //               <div className="date-details">
            //                 <div className="day-name c-font f-12 text-center">
            //                 {dayName.toUpperCase()}
            //                 </div>
            //                 <div className={`date-task-dots d-flex align-items-center justify-content-center ${pageName == "MyPeople" ? "d-none" : ""}`}>
            //                   {(day.completed_count === 0 &&
            //                     day.inprogress_count === 0 &&
            //                     day.todo_count === 0 && day.request_total == 0) || (pageName == "leadPage" && day.completed_count === 0 &&
            //                       day.inprogress_count === 0) ? (<>
            //                     <div className="">
            //                       <span className="c-dots not-started rounded-circle"></span>
            //                       <span className="c-dots not-started rounded-circle"></span>
            //                       <span className="c-dots not-started rounded-circle"></span>
            //                     </div>
            //                   </>) : day.completed_count +
            //                     day.inprogress_count +
            //                     day.todo_count + day.request_total ==
            //                     0 ? (
            //                     <>
            //                     </>
            //                   ) : (
            //                     <>
            //                       {day.request_total > 0 ? (
            //                         <div className="pe-1 d-flex align-items-center">
            //                           <span className="  f-color-orange rounded-pill badge-custom with-orange-border with-position-relative fw-semibold m-auto h18w18 d-flex">
            //                             {day.request_total}
            //                           </span>
            //                         </div>
            //                       ) : (
            //                         <></>
            //                       )}
            //                       {day.todo_count > 0 ? (
            //                         <div className="pe-1 d-flex align-items-center">
            //                           <span className="  f-color-white rounded-pill badge-custom with-to-do-border with-position-relative fw-semibold m-auto h18w18 d-flex">
            //                             {day.todo_count}
            //                           </span>
            //                         </div>
            //                       ) : (
            //                         <></>
            //                       )}
            //                       {day.inprogress_count > 0 ? (
            //                         <div className="pe-1 d-flex align-items-center">
            //                           <span className="rounded-pill f-color-white badge-custom with-inprogress-border with-position-relative fw-semibold m-auto d-flex h18w18">
            //                             {day.inprogress_count}
            //                           </span>
            //                         </div>
            //                       ) : (
            //                         <></>
            //                       )}
            //                       {day.completed_count > 0 ? (
            //                         <div className="d-flex align-items-center">
            //                           <span className=" rounded-pill f-color-white badge-custom  with-done-border with-position-relative fw-semibold m-auto d-flex h18w18">
            //                             {day.completed_count}
            //                           </span>
            //                         </div>
            //                       ) : (
            //                         <></>
            //                       )}
            //                     </>
            //                   )}
            //                 </div>
            //                             <div class="date-task-dots d-flex align-items-center justify-content-center inv_counter"></div>
            //               </div>
            //             </a>
            //             <ReactTooltip />
            //           </React.Fragment>
            //         );}
            //       }else if ((isDateRangeFilterApplied) && taskFilterAll == 0 && (index == 0)){
            //         const fromDateName = moment(fromDateState ? fromDateState : localStorage.getItem('fromDate')).locale(lang == "french" ? "fr" : "en").format('dddd').substring(0, 3)
            //         const toDateName = moment(toDateState ? toDateState : localStorage.getItem('toDate')).locale(lang == "french" ? "fr" : "en").format('dddd').substring(0, 3)
            //         let date1 = new Date(localStorage.getItem('fromDate'));
            //         let date2 = new Date(localStorage.getItem('toDate'));
            //         return (
            //           <div className="calendar-date-wrapper ">
            //             <div className="d-flex align-items-center calendar-date">
            //               <a href="#/" className="cal-date-box flex-grow-1 c-gradinet-active-border d-flex align-items-center">
            //                 <div className="date-text d-flex flex-column justify-content-center align-items-center">
            //                   <span className="c-font f-10 fw-normal">{_l("l_start_date")}</span>
            //                   <span className="c-font f-22 title-fonts fw-bold text-capitalize letter_spacing2">{moment(date1).format("Do MMM YYYY")} </span>
            //                   {/* {moment(fromDateState ? fromDateState : localStorage.getItem('fromDate')).locale(lang == "french" ? "fr" : "en").format("DD/MMM").slice(0,6)} */}
            //                   </div>
            //                 {/* <div class="c-font color-white-80 day-name f-12 letter_spacing2 ps-3 text-center text-uppercase">{fromDateName}</div> */}
            //               </a>
            //               <a href="#/" className="cal-date-box  flex-grow-1 c-gradinet-active-border d-flex align-items-center">
                          
            //                 <div className="date-text d-flex flex-column justify-content-center align-items-center">
            //                   <span className="c-font f-10 fw-normal">{_l("l_end_date")}</span> 
            //                   <span className="c-font f-22 title-fonts fw-bold text-capitalize letter_spacing2">{moment(date2).format("Do MMM YYYY")}</span>
            //                   {/* {moment(toDateState ? toDateState : localStorage.getItem('toDate')).locale(lang == "french" ? "fr" : "en").format("DD/MMM").slice(0,6)} */}
            //                 </div>
                          
            //                 {/* <div class="c-font color-white-80 day-name f-12 letter_spacing2 ps-3 text-center text-uppercase">{toDateName}</div> */}
            //               </a>
            //             </div>
            //           </div>
            //         )

            //       }
            //     })}
            //   </div>
            // </>
            }
            </div>
            {pageName == "Dashboard" || pageName =="MySpace" ? 
            <div className={`comman_action_icon btn-group dropup dropup-center ms-2 step-with-absolute-pos d-none`}>
                  <a href="#/"
                    className="btn-white-10 d-flex h30w30 on-hover-active-toggle-img p-5px radius_3" 
                    type="button"
                    id="FloorFilterDropdown"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                    data-tip={_l("l_floor_fliter")}
                    data-effect="solid"
                    data-delay-show='1000'
                    // data-for={randomID}
                    data-class="tooltip-main"
                    data-place="top"
                  >
                    <StepIcon className="HW18" />
                  </a>
                </div>
                : <></>}
          </div>
      </div>
      </div>
}
      <ReactTooltip />
    </>
  );
};

export default Calendar;
