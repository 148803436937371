import React, { useEffect, useState } from "react";
import LogoGreenLight from "../../assets/icons/LogoGreenLight";
import LogoGreenDark from "../../assets/images/tomoonlogohorizontal.svg";
import LogoCircle from "../../assets/images/tomoonlogo.svg";
import {
  AddressBook,
  Buildings,
  CalendarBlank,
  DotsThreeVertical,
  Envelope,
  Gauge,
  HardDrives,
  UserCircle,
  UserGear,
  UserPlus,
  Users,
  Wrench,
} from "phosphor-react";
import { _l, formateDate, hideRightPanelNew, showLeftPanelNew } from "../../hooks/utilities";
import { commanoffcanvasisOpen, dayGridDay, dayGridMonth, dayGridWeek, emailTokenExpired, getDefaultTopics, leftPanelFilters, setDaterangeFilter, setDaterangeFilterApplied, setLeftPanelTab, setOpenOrCloseContactDirectoryChat, setSelectedGloablView } from "../../actions/customer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const LeftPanelMain = ({ 
    setshowMyProfile = () => {}, 
    showMyProfile,
    contactlistShow,
    setContactlistShow = () => {},
    contactDirTopicChange = () => {},
    setSettingTopicName = () => {},
    setTopicMainKey = () => {},
    handleChangeViewtoFavView = () => {},
    isSynced
 }) => {
  const { leftPanelTab, taskFilters } = useSelector((state) => state.customer);

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const userType = localStorage.getItem("user_type");
  let projectId = localStorage.getItem("selectedOffice");
  let currentCompanyID = localStorage.getItem("currentCompanyID") ? localStorage.getItem("currentCompanyID") : 0;

  const [LightTheme, setLightTheme] = useState(
    localStorage.getItem("selectedTheme") == "light" ? true : false
  );

  const handleChangeTab = (tab, navigation) => {
    dispatch(commanoffcanvasisOpen(false));

    dispatch(setLeftPanelTab(tab));
    if (navigation) {
    navigate(navigation);
    }
    
    if (tab == "my-contacts") {
      dispatch(setLeftPanelTab("my-contacts"));
      dispatch(setOpenOrCloseContactDirectoryChat(true));
      setContactlistShow(true);
      contactDirTopicChange({}, false);
    } else {      
      dispatch(setOpenOrCloseContactDirectoryChat(false));
      setContactlistShow(false);
      localStorage.removeItem("isContactDir");
    }

    if (tab == "my-calendar") {
      dispatch(setSelectedGloablView("calendarView"));
        dispatch(dayGridDay(false));
        dispatch(dayGridWeek(false));
        dispatch(dayGridMonth(true));
        dispatch(dayGridMonth(true));
        dispatch(setDaterangeFilterApplied(true));
        dispatch(leftPanelFilters({
          filter_key : "date_range_filter",
          image : "", 
          main_key : "date_range_filter",
          main_sub_key : "", 
          sub_category : [] ,
          title : formateDate(moment(new Date()).startOf('month')) + " - "+ formateDate(moment(new Date()).endOf('month'))
        },"onlyDateFilter"))
        localStorage.setItem("fromDate",formateDate(moment(new Date()).startOf('month')));
        localStorage.setItem("toDate", formateDate(moment(new Date()).endOf('month')));
    } else {
      dispatch(dayGridDay(true));
      dispatch(dayGridWeek(false));
      dispatch(dayGridMonth(false));
      handleChangeViewtoFavView();
    }
    hideRightPanelNew();
    showLeftPanelNew();

    if (tab == "my-request") {
      dispatch(setDaterangeFilter("all"))
      dispatch(setDaterangeFilterApplied(true));
    }else{
      dispatch(setDaterangeFilter("today"))
      dispatch(setDaterangeFilterApplied(false));
      dispatch(leftPanelFilters("","","all"))
    }

  }
  


  useEffect(() => {
    if (leftPanelTab == "my-email" ) {
        dispatch(getDefaultTopics(projectId, "", "", "MyEmail",taskFilters));
    }
  }, [leftPanelTab])
  
  return (
    <>
      <div
        id="intro-office-list"
        className="expanded fixed-left-panel intro-office-list office-list"
      >
        <div className="fixed-left-panel-wrapper h-100 d-flex flex-column">
          <div className="upper-part ">
            <a href="#/" className="company-logo-box position-relative office-box p-20pximp w-100"
            onClick={()=>{
              handleChangeTab("homepage", "/"); 
            }}>
              <div className="d-flex align-items-center justify-content-between h-100">
                <a href="#" className="h-100">
                {(leftPanelTab === "my-providers" && currentCompanyID > 0)   ?
                <img src={LogoCircle} className="header-logo-img h-100 w-100" />
                :
                <img src={LogoGreenDark} className="header-logo-img h-100 w-100" />
                
                    
                 }
                </a>
                {/* <div className="comman_action_icon">
                  <a href="#/" className="action_icon  h32w32">
                    <DotsThreeVertical
                      size={16}
                      weight="light"
                      className="c-icons"
                    />
                  </a>
                </div> */}
              </div>
            </a>
          </div>
          <ul className="office-list-main mt-0 pt-10px" id="left-office-list">

            {/* ----------------------------- My Request start ----------------------------- */}

            <li className={`office-box p-0 on-hover-active-toggle-img ${leftPanelTab == "my-request" ? "active" : ""}`}>
              <a
                href="#/"
                className="w-100 d-flex align-items-center justify-content-between p20px px-0 h70w70 px-0"
                onClick={() => {
                  dispatch(leftPanelFilters("","","applyForMewithAll"));
                  handleChangeTab("my-request" , "/");                
                }}
              >
                <div className="d-flex align-items-center comman_action_icon">
                  <div className="d-flex flex-column justify-content-center align-items-center  w-70px">
                    <div className="p-20 py-0 text-center">
                    <div className="h32w32">
                      <Gauge size={28} weight="light" className="c-icons" />
                    </div>
                    </div>
                     
                    {leftPanelTab === "my-providers" && currentCompanyID > 0 ?  <div class={`color-white-80 c-font f-9  office-subtitle  hover-span fw-light hover-span line-height-1   text-truncate text-center w-100 pt-1 px-1`}>{_l("l_my_tasks")}</div> : <React.Fragment></React.Fragment>}
                    
                  </div>
                  <div className="c-font f-15 hover-span">
                    {_l("l_my_tasks")}
                  </div>
                </div>
                {/* <div className="rounded-pill badge-custom d-flex with-position-relative border-0 h20w20">
                  <span className="m-auto ps-0">48</span>
                </div> */}
              </a>
            </li>
            {/* ----------------------------- My Request End ----------------------------- */}

            {/* ----------------------------- My Spaces start ----------------------------- */}

            <li className={`office-box p-0 on-hover-active-toggle-img ${leftPanelTab == "my-space" ? "active" : ""}`}>
              <a
                href="#/"
                className="w-100 d-flex align-items-center justify-content-between p20px px-0 h70w70"
                onClick={() => {
                  handleChangeTab("my-space" , "/");
                }}
              >
                <div className="d-flex align-items-center comman_action_icon">
                  <div className="d-flex flex-column justify-content-center align-items-center w-70px">
                  <div className="p-20 py-0 text-center">
                  <div className="action_icon h30w30 without-bg">
                    <Buildings size={28} weight="light" className="c-icons" />
                  </div>
                  </div>
                  {leftPanelTab === "my-providers" && currentCompanyID > 0 ?  <div class={`color-white-80 c-font f-9  office-subtitle  hover-span fw-light hover-span line-height-1   text-truncate text-center w-100 pt-1 px-1`}>{_l("l_space")}</div> : <React.Fragment></React.Fragment>}
                    </div>
                  <div className="c-font f-15 hover-span">
                   {_l("l_space")}
                  </div>
                </div>
                {/* <div className="rounded-pill badge-custom d-flex with-position-relative border-0 h20w20">
                  <span className="m-auto ps-0">48</span>
                </div> */}
              </a>
            </li>

            {/* ----------------------------- My Spaces End ----------------------------- */}

            {/* ----------------------------- My Clients start ----------------------------- */}
            {userType === "operator" ?
            <li className={`office-box p-0 on-hover-active-toggle-img ${leftPanelTab == "my-clients" ? "active" : ""}`}>
              <a
                href="#/"
                className="w-100 d-flex align-items-center justify-content-between p20px px-0 h70w70"
                onClick={() => {
                  handleChangeTab("my-clients" , "/");                  
                }}
              >
                <div className="d-flex align-items-center comman_action_icon">
                  <div className="d-flex flex-column justify-content-center align-items-center p-20 py-0 text-center w-70px">
                  <div className="p-20 py-0 text-center">
                  <div className="action_icon h30w30 without-bg">
                    <UserGear size={28} weight="light" className="c-icons" />
                  </div>
                  </div>
                  {leftPanelTab === "my-providers" && currentCompanyID > 0 ?  <div class={`color-white-80 c-font f-9  office-subtitle  hover-span fw-light hover-span line-height-1   text-truncate text-center w-100 pt-1 px-1`}>{_l("l_my_clients")}</div> : <React.Fragment></React.Fragment>}
                    </div>
                  <div className="c-font f-15 hover-span">
                    {_l("l_my_clients")}
                  </div>
                </div>
                {/* <div className="rounded-pill badge-custom d-flex with-position-relative border-0 h20w20">
                  <span className="m-auto ps-0">48</span>
                </div> */}
              </a>
            </li>
            : <React.Fragment></React.Fragment>}
            {/* ----------------------------- My Clients End ----------------------------- */}

            {/* ----------------------------- My Agents start ----------------------------- */}
          {userType === "operator" ?
            <li className={`office-box p-0 on-hover-active-toggle-img ${leftPanelTab == "my-agents" ? "active" : ""}`}>
              <a
                href="#/"
                className="w-100 d-flex align-items-center justify-content-between p20px px-0 h70w70"
                onClick={() => {
                  handleChangeTab("my-agents" , "/");
                  dispatch(setSelectedGloablView("AichatView"));
                }}
              >
                <div className="d-flex align-items-center comman_action_icon">
                  <div className="d-flex flex-column justify-content-center align-items-center w-70px">
                  <div className="p-20 py-0 text-center">
                  <div className="action_icon h30w30 without-bg">
                    <UserCircle size={28} weight="light" className="c-icons" />
                  </div>
                  </div>
                  {leftPanelTab === "my-providers" && currentCompanyID > 0 ?  <div class={`color-white-80 c-font f-9  office-subtitle  hover-span fw-light hover-span line-height-1   text-truncate text-center w-100 pt-1 px-1`}>{_l("l_my_agents")}</div> : <React.Fragment></React.Fragment>}
                    </div>
                  <div className="c-font f-15 hover-span">
                    {_l("l_my_agents")}
                  </div>
                </div>
                {/* <div className="rounded-pill badge-custom d-flex with-position-relative border-0 h20w20">
                  <span className="m-auto ps-0">48</span>
                </div> */}
              </a>
            </li>
            : <React.Fragment></React.Fragment>}
            {/* ----------------------------- My Agents End ----------------------------- */}


            {/* ----------------------------- My Providers start ----------------------------- */}
          {userType === "contact" ?
            <li className={`office-box p-0 on-hover-active-toggle-img ${leftPanelTab == "my-providers" ? "active" : ""}`}>
              <a
                href="#/"
                className="w-100 d-flex align-items-center justify-content-between p20px px-0 h70w70"
                onClick={() => {
                  handleChangeTab("my-providers" , "/providerlist");
                }}
              >
                <div className="d-flex align-items-center comman_action_icon">
                <div className="d-flex flex-column justify-content-center align-items-center  w-70px">
                <div className="p-20 py-0 text-center">
                  <div className="action_icon h30w30 without-bg">
                    <UserPlus size={28} weight="light" className="c-icons" />
                  </div>
                  </div>
                  {leftPanelTab === "my-providers" && currentCompanyID > 0 ?  <div class={`color-white-80 c-font f-9  office-subtitle  hover-span fw-light hover-span line-height-1   text-truncate text-center w-100 pt-1 px-1`}>{_l("l_providers")}</div> : <React.Fragment></React.Fragment>}
                  </div>
                  <div className="c-font f-15 hover-span">
                  {_l("l_providers")}
                  </div>
                </div>
                {/* <div className="rounded-pill badge-custom d-flex with-position-relative border-0 h20w20">
                  <span className="m-auto ps-0">48</span>
                </div> */}
              </a>
            </li>
            : <React.Fragment></React.Fragment>}
            {/* ----------------------------- My Providers end ----------------------------- */}

            {/* ----------------------------- My Tenants start ----------------------------- */}
          {userType === "contact" ?
            <li className={`office-box p-0 on-hover-active-toggle-img ${leftPanelTab == "my-tenants" ? "active" : ""}`}>
              <a
                href="#/"
                className="w-100 d-flex align-items-center justify-content-between p20px px-0 h70w70"
                onClick={() => {
                  handleChangeTab("my-tenants" , "/");
                  dispatch(setSelectedGloablView("AichatView"));
                }}
              >
                <div className="d-flex align-items-center comman_action_icon">
                  <div className="d-flex flex-column justify-content-center align-items-center w-70px">
                  <div className="p-20 py-0 text-center">
                  <div className="action_icon h30w30 without-bg">
                    <Users size={28} weight="light" className="c-icons" />
                  </div>
                  </div>
                  {leftPanelTab === "my-providers" && currentCompanyID > 0 ?  <div class={`color-white-80 c-font f-9  office-subtitle  hover-span fw-light hover-span line-height-1   text-truncate text-center w-100 pt-1 px-1`}>{_l("l_tenants")}</div> : <React.Fragment></React.Fragment>}
                    </div>
                  <div className="c-font f-15 hover-span">
                  {_l("l_tenants")}
                  </div>
                </div>
                {/* <div className="rounded-pill badge-custom d-flex with-position-relative border-0 h20w20">
                  <span className="m-auto ps-0">48</span>
                </div> */}
              </a>
            </li>
            : <React.Fragment></React.Fragment>}
            {/* ----------------------------- My Tenants end ----------------------------- */}


            {/* ----------------------------- Equipments start ----------------------------- */}
          {userType === "contact" ?
            <li className={`office-box p-0 on-hover-active-toggle-img ${leftPanelTab == "equipments" ? "active" : ""}`}>
              <a
                href="#/"
                className="w-100 d-flex align-items-center justify-content-between p20px px-0 h70w70"
                onClick={() => {
                  handleChangeTab("equipments" , "/equipment-table");
                }}
              >
                <div className="d-flex align-items-center comman_action_icon">
                  <div className="d-flex flex-column justify-content-center align-items-center w-70px">
                  <div className="p-20 py-0 text-center">
                  <div className="action_icon h30w30 without-bg">
                    <Wrench size={28} weight="light" className="c-icons" />
                  </div>
                  </div>
                  {leftPanelTab === "equipments" && currentCompanyID > 0 ?  <div class={`color-white-80 c-font f-9  office-subtitle  hover-span fw-light hover-span line-height-1   text-truncate text-center w-100 pt-1 px-1`}>{_l("l_equipments")}</div> : <React.Fragment></React.Fragment>}
                    </div>
                  <div className="c-font f-15 hover-span">
                  {_l("l_equipments")}
                  </div>
                </div>
                {/* <div className="rounded-pill badge-custom d-flex with-position-relative border-0 h20w20">
                  <span className="m-auto ps-0">48</span>
                </div> */}
              </a>
            </li>
            : <React.Fragment></React.Fragment>}
            {/* ----------------------------- Equipments end ----------------------------- */}
            

            {/* ----------------------------- contactDir start ----------------------------- */}
          {
          //  userType === "staff"?
          //   <li className={`office-box p-0 on-hover-active-toggle-img ${leftPanelTab == "my-contacts" ? "active" : ""}`}>
          //     <a
          //       href="#/"
          //       className="w-100 d-flex align-items-center justify-content-between p20px px-0 h70w70"
          //       onClick={() => {
          //         handleChangeTab("my-contacts" , "/");
          //       }}
          //     >
          //       <div className="d-flex align-items-center comman_action_icon">
          //         <div className="d-flex flex-column justify-content-center align-items-center w-70px">
          //         <div className="p-20 py-0 text-center">
          //         <div className="action_icon h30w30 without-bg">
          //           <AddressBook size={28} weight="light" className="c-icons" />
          //         </div>
          //         </div>
          //         {leftPanelTab === "my-providers" && currentCompanyID > 0 ?  <div class={`color-white-80 c-font f-9  office-subtitle  hover-span fw-light hover-span line-height-1   text-truncate text-center w-100 pt-1 px-1`}>{_l("l_contacts")}</div> : <React.Fragment></React.Fragment>}
          //         </div>
          //         <div className="c-font f-15 hover-span">
          //         {_l("l_contacts")}
          //         </div>
          //       </div>
          //       {/* <div className="rounded-pill badge-custom d-flex with-position-relative border-0 h20w20">
          //         <span className="m-auto ps-0">48</span>
          //       </div> */}
          //     </a>
          //   </li>
          //   : <React.Fragment></React.Fragment>
          }
            {/* ----------------------------- contactDir End ----------------------------- */}
            

            {/* ----------------------------- Staff List start ----------------------------- */}
          {
           userType === "staff"?
            <li className={`office-box p-0 on-hover-active-toggle-img ${leftPanelTab == "my-staff" ? "active" : ""}`}>
              <a
                href="#/"
                className="w-100 d-flex align-items-center justify-content-between p20px px-0 h70w70"
                onClick={() => {
                  handleChangeTab("my-staff" , "/");
                }}
              >
                <div className="d-flex align-items-center comman_action_icon">
                  <div className="d-flex flex-column justify-content-center align-items-center w-70px">
                  <div className="p-20 py-0 text-center">
                  <div className="action_icon h30w30 without-bg">
                    <Users size={28} weight="light" className="c-icons" />
                  </div>
                  </div>
                  </div>
                  <div className="c-font f-15 hover-span">
                  {_l("l_staff")}
                  </div>
                </div>
              </a>
            </li>
            : <React.Fragment></React.Fragment>
          }
            {/* ----------------------------- Staff List End ----------------------------- */}

          </ul>
          <div className="lower-part mt-auto border-top pt-0">
            <ul className="office-list-main">
            {/* ----------------------------- My Email start ----------------------------- */}
            <li className={`office-box p-0 on-hover-active-toggle-img ${leftPanelTab == "my-email" ? "active" : ""}`}>
              <a
                href="#/"
                className="w-100 d-flex align-items-center justify-content-between p20px px-0 h70w70"
                onClick={() => {
                  dispatch(emailTokenExpired(true))
                  handleChangeTab("my-email" , "/MyEmail");
                }}
              >
                <div className="d-flex align-items-center comman_action_icon">
                  <div className="d-flex flex-column justify-content-center align-items-center w-70px">
                  <div className="p-20 py-0 text-center">
                  <div className="action_icon h30w30 without-bg">
                    <Envelope size={28} weight="light" className="c-icons" />
                  </div>
                  </div>
                  {leftPanelTab === "my-providers" && currentCompanyID > 0 ?  <div class={`color-white-80 c-font f-9  office-subtitle  hover-span fw-light hover-span line-height-1   text-truncate text-center w-100 pt-1 px-1`}>{_l("l_email")}</div> : <React.Fragment></React.Fragment>}
                  </div>
                  <div className="c-font f-15 hover-span">{_l("l_email")}</div>
                </div>
                {/* <div className="rounded-pill badge-custom d-flex with-position-relative border-0 h20w20">
                  <span className="m-auto ps-0">48</span>
                </div> */}
              </a>
            </li>
            {/* ----------------------------- My Email End ----------------------------- */}

            {/* ----------------------------- Drive Start ----------------------------- */}
            <li className={`office-box p-0 on-hover-active-toggle-img ${leftPanelTab == "my-drive" ? "active" : ""}`}>
              <a
                href="#/"
                className="w-100 d-flex align-items-center justify-content-between p20px px-0 h70w70"
                onClick={() => {
                  localStorage.setItem("lastLeftPanelTab", leftPanelTab)
                  handleChangeTab("my-drive", "/my-drive");
                  // setSettingTopicName("l_drive");
                  // setTopicMainKey("documents");

                }}
              >
                <div className="d-flex align-items-center comman_action_icon">
                  <div className="d-flex flex-column justify-content-center align-items-center w-70px">
                  <div className="p-20 py-0 text-center">
                  <div className="action_icon h30w30 without-bg">
                    <HardDrives size={28} weight="light" className="c-icons" />
                  </div>
                  </div>
                  {leftPanelTab === "my-providers" && currentCompanyID > 0 ?  <div class={`color-white-80 c-font f-9  office-subtitle  hover-span fw-light hover-span line-height-1   text-truncate text-center w-100 pt-1 px-1`}>{_l("l_drive")}</div> : <React.Fragment></React.Fragment>}
                    </div>
                  <div className="c-font f-15 hover-span">{_l('l_drive')}</div>
                </div>
                {/* <div className="rounded-pill badge-custom d-flex with-position-relative border-0 h20w20">
                  <span className="m-auto ps-0">48</span>
                </div> */}
              </a>
            </li>
            {/* ----------------------------- Drive End ----------------------------- */}


            {/* ----------------------------- Calendar Start ----------------------------- */}
            <li className={`office-box p-0 on-hover-active-toggle-img ${leftPanelTab == "my-calendar" ? "active" : ""}`}>
              <a
                href="#/"
                className="w-100 d-flex align-items-center justify-content-between p20px px-0 h70w70"
                onClick={() => {
                  handleChangeTab("my-calendar","/");
                  
                }}
              >
                <div className="d-flex align-items-center comman_action_icon">
                  <div className="d-flex flex-column justify-content-center align-items-center w-70px">
                  <div className="p-20 py-0 text-center">
                  <div className="action_icon h30w30 without-bg">
                    <CalendarBlank size={28} weight="light" className="c-icons" />
                  </div>
                  </div>
                  {leftPanelTab === "my-providers" && currentCompanyID > 0 ?  <div class={`color-white-80 c-font f-9  office-subtitle  hover-span fw-light hover-span line-height-1   text-truncate text-center w-100 pt-1 px-1`}>{_l("l_calendar")}</div> : <React.Fragment></React.Fragment>}
                    
                    </div>
                  <div className="c-font f-15 hover-span">{_l('l_calendar')}</div>
                </div>
                {/* <div className="rounded-pill badge-custom d-flex with-position-relative border-0 h20w20">
                  <span className="m-auto ps-0">48</span>
                </div> */}
              </a>
            </li>
            {/* ----------------------------- Calendar End ----------------------------- */}
            </ul>
            <div className="new_user_profile_bottom">
              <div className="company-logo-box position-relative d-flex align-items-center justify-content-between comman-round-box pe-10px">
                <a
                  href="#/"
                  className="d-flex align-items-center w-100 h70w70"
                  onClick={() => {
                    setshowMyProfile(!showMyProfile);
                  }}
                >
                  <div
                    className={`h32w32 comman-round-box rounded-circle mx20px ${
                      userType == "staff"
                        ? "staff_user"
                        : userType == "operator"
                        ? "operator_user"
                        : "contact_user"
                    } `}
                  >
                    <div
                      className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                      style={{
                        backgroundImage: `url(${localStorage.getItem(
                          "contact_image"
                        )})`,
                      }}
                    ></div>
                  </div>
                  <div className=" d-flex flex-column">
                    <div className="c-font f-15 fw-semibold">
                      {localStorage.getItem("full_name")}
                    </div>
                    <div className="c-font f-10 color-white-60">
                      {_l("l_my_profile")}
                    </div>
                  </div>
                </a>
                <div className="d-flex align-items-center gap10px">
                <div className="rounded-pill badge-custom d-flex with-position-relative border-0 h18w18 "><span className="m-auto ps-0">17</span></div>
                <div className="comman_action_icon ms-10px">
                  <a href="#/" className="action_icon  h32w32">
                    <DotsThreeVertical
                      size={16}
                      weight="light"
                      className="c-icons"
                    />
                  </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftPanelMain;
