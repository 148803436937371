import React, { useState, useEffect } from "react";
import { PencilSimpleLine, Sun, GearSix, SignOut, Question, MonitorPlay,UserSwitch,CaretDown, Wrench, Gauge, EnvelopeSimple, BezierCurve, Buildings, CurrencyEur, Rows, HardDrives } from "phosphor-react";
import jquery from "jquery";
import { TaskModalClose, _l, accessPremiumFeature, favoriteDdashboardSelected, loadDataToIDB } from "../../hooks/utilities";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { leftPanelFilters, setSpaceList, userLogout, isPremuimEstimateDisable, ProductTour, getCustomerSpaces, projectTaskCounts, setSelectedLanguage, setProjectTheme, setSelectedGloablView, lastSessionOfChat, setFetchedTaskandMessages, commanoffcanvasisOpen, SetSelectedTask, setBulkTaskRating, setDashBoardView } from "../../actions/customer";
import { useDispatch } from "react-redux";
import { userOffline, connectToSocket, getGroupDetails } from "../../actions/chat";
import { adminLoginAsStaff } from "../../actions/admin-actions";
import Form from 'react-bootstrap/Form';
import CustomerServices from "../../services/customer-services";
import { showConfirmation, showFullScreenLoader, showMessage } from "../../actions/messages";
import ToolsInformationModal from "../Modals/ToolsInformationModal";
import GeneralListing from "../TaskModalComponents/GeneralListing";
import { loginAsUser } from "../../actions/auth";
import AuthServices from "../../services/auth-services";
import { Button } from "react-bootstrap";
import BenchmarkServices from "../../services/benchmark-services";
import { archieveIDBCollections, clearDatabase, restoreIDBSession } from "../../hooks/indexed_db_helpers";

const MyProfileDropDownNew = ({
  isExternalHeader=false, 
  user_name,
  userDescription,
  profileImageURL , 
  contactlistShow, 
  setContactlistShow, 
  setSelectedProject = () => {}, 
  selectLiteVersion = () => {},
  disabledThirdPartyToolAccess,
  setSettingTopicName,
  setTopicMainKey,
  unreadMessages,
  unreadComments,
  setSettingMenuRender,
  SettingMenuRender,
  estimateDetails,
  viewPage,
  proposalUnreadComments,
  setshowMyProfile,
  setSelectedSpace
}) => {

  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type")
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const hash = searchParams.get("hash");

  const adminAsStaff = localStorage.getItem('adminArchieve') ? true : false;
  const contact_role = localStorage.getItem("contact_role");
  const [randomID, setRandomID] = useState(String(Math.random()));

  const adminAuth = useSelector((state) => state.adminReducer.loginState);

  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem("language") || "french");
  const [hideLogoutSwitch, setHideLogoutSwitch] = useState(adminAsStaff);
  const [loginUserImage, setLoginUserImage] = useState(localStorage.getItem("contact_image"));
  const [loginUserEmail, setLoginUserEmail] = useState(localStorage.getItem("email"));
  const [loginUserName, setLoginUserName] = useState(localStorage.getItem("full_name"));
  const [preEstiPageDisable, setPreEstiPageDisable] = useState(false);
  const isPremiumEstimateEdit = useSelector((state) => state.customer.isPremiumEstimateAccess);
  const isProductTourGuide = useSelector((state) => state.customer.productTour);
  const [ProductTourGuide, setProductTourGuide] = useState(false);
  const [showToolsModal, setShowToolsModal] = useState(false);
  const [recentLoginUserList, setRecentLoginUserList] = useState([]);
  const {themeColor} = useSelector((state) => state.customer)

  const logout = () => {
    var chatUserId = localStorage.getItem("chatUserId");
    var language = localStorage.getItem("language");
    if (chatUserId != "" && chatUserId != undefined) {
      dispatch(userOffline({ userId: chatUserId }));
    }
    dispatch(userLogout());
    localStorage.clear();
    localStorage.setItem("version", process.env.REACT_APP_VERSION);
    localStorage.setItem('selectedTheme', LightTheme ? "light" : "dark");
    localStorage.setItem("language", language);
    navigate("/login");
    dispatch(
      leftPanelFilters('', '', 'all',)
    );
  }
  useEffect(() => {
    if (isProductTourGuide) {
      setProductTourGuide(true);
    } else {
      setProductTourGuide(false);
    }
  }, [isProductTourGuide]);
  const handleSwitchLogin = async() => {
    let email = "";
    email = localStorage.getItem("email");
    var chatUserId = localStorage.getItem("chatUserId");
    if (chatUserId != "" && chatUserId != undefined) {
      dispatch(userOffline({ userId: chatUserId }));
    }
    const archieve = JSON.parse(localStorage.getItem("adminArchieve"));
    const projectId = localStorage.getItem('selectedOffice');
    await archieveIDBCollections(email);
    
    localStorage.clear();
    localStorage.setItem("version", process.env.REACT_APP_VERSION);
    archieve.forEach((item) => {
      localStorage.setItem(item.key, item.value);
      if (item.key == "fav_dashboard") {
        favoriteDdashboardSelected(item.value);
      }
    });
    //Get Admin's Email
    email = localStorage.getItem("email");
    dispatch(adminLoginAsStaff(false));
    localStorage.removeItem("adminArchieve");
    await clearDatabase();
    await restoreIDBSession(email);
    localStorage.setItem('selectedOffice', projectId)
    dispatch(showFullScreenLoader());
    dispatch(setSpaceList(JSON.parse(localStorage.getItem("spaces"))));
    dispatch(isPremuimEstimateDisable());
    setTimeout(() => {
      dispatch(connectToSocket());
    }, 500);
    dispatch(leftPanelFilters("","","all"))
    dispatch(getGroupDetails({}));
    dispatch(lastSessionOfChat([]));
    dispatch(setFetchedTaskandMessages([]));
    dispatch(setSelectedGloablView("AichatView"));
    navigate("/redirect", {
      state: {
        url: "/",
      }
    });
  }

  const userName = localStorage.getItem("full_name");
  const emailAddress = localStorage.getItem("email");
  const contact_image = localStorage.getItem("contact_image");
  const [LightTheme, setLightTheme] = useState(false);
  const [PauseMenu, setPauseMenu] = useState(false);
  const isAdmin = localStorage.getItem("admin");

  let selectedtheme = localStorage.getItem('selectedTheme') == "light" ? true : false

  useEffect(() => {
    setHideLogoutSwitch(adminAuth);
    getUserLoginSessions();
  }, [adminAuth]);

  useEffect(() => {
    if (localStorage.getItem('selectedTheme') == "light") {
      jquery('html').addClass('light-theme');
      setLightTheme(true)
    } else {
      jquery('html').removeClass('light-theme');
      setLightTheme(false)
    }
  }, [LightTheme, selectedtheme]);

  useEffect(() => {
    setLoginUserImage(localStorage.getItem("contact_image"))
    setLoginUserName(localStorage.getItem("full_name"))
    setLoginUserEmail(localStorage.getItem("email"))
  }, [localStorage.getItem("contact_image"), localStorage.getItem("email"), localStorage.getItem("full_name")]);

  useEffect(() => {
    setPreEstiPageDisable(false)
    if (isPremiumEstimateEdit == true) {
      setPreEstiPageDisable(true)
    }
  }, [JSON.stringify(isPremiumEstimateEdit)])

  const setTheme = (theme) => {
    CustomerServices.setUserTheme(theme ? 1 : 0).then((res) => {
      localStorage.setItem('selectedTheme', theme ? "light" : "dark");
      setLightTheme(theme);
      dispatch(setProjectTheme(theme ? "light" :  "dark" ))
      setshowMyProfile(false);
    });
  }
  useEffect(() => {
    if (themeColor == "light") {
      setLightTheme(true);
    }else{
      setLightTheme(false);
    }

    
  }, [themeColor])
  const setLanguage = (language) => {
    dispatch(setSelectedLanguage(language));
    localStorage.setItem('language', language);
    setCurrentLanguage(language);
    CustomerServices.setUserLanguage(language);
  }

  const relecentloginHandler = (email, clientId, project_id = 0, staff_id) => {
    dispatch(showFullScreenLoader());
    dispatch(
      loginAsUser(email, clientId, project_id)
    ).then(async() => {

      if (!localStorage.getItem("accessToken")) {
        dispatch(
          showMessage("unsucess", _l("l_error"), _l("l_invalid_credentials"))
        );
      } else {
        localStorage.setItem("password", "12345");
        await clearDatabase();
        await restoreIDBSession(email);

        await loadDataToIDB(staff_id);
        if (localStorage.getItem("membership_package_id") == 1) {
          // navigate("/premiumsubscription");
           navigate("/myprofile");
        } else {
          const client_id = localStorage.getItem("client_id");
          const contact_id = localStorage.getItem("contact_id");
          localStorage.setItem("selectedOffice",project_id);
          dispatch(getCustomerSpaces(client_id, contact_id));
          dispatch(adminLoginAsStaff(true));
          dispatch(projectTaskCounts(project_id));
          navigate("/redirect", {
            state: {
              url: "/",
            },
          });
        }
      }
    });
  };

  const getUserLoginSessions = async() => {
    if(isAdmin || adminAsStaff || hideLogoutSwitch)
    {
      try {
        const response = await AuthServices.getUserRecentSessions();
        if(response && response.data && response.data.length)
        {
          setRecentLoginUserList(response.data);
        }
      }
      catch(e)
      {
        console.error(e);
      }
    }
  };

  const discardExternalBenchmark = () => {
    dispatch(showConfirmation(_l("l_are_you_sure"), _l("l_discard_external_benchmark"), _l("l_discard"), _l("l_cancel"), () => {}, async() => {
      try {
        const response = await BenchmarkServices.discardExternalBenchmark(hash);
        if(response.status)
        {
          navigate("/login");
          localStorage.clear();
          localStorage.setItem("version", process.env.REACT_APP_VERSION);
          window.location.reload();
        }
      }
      catch(e)
      {
        console.error(e);
      }
    }))
  };
  return (
    <>
    <div className={`myprofilesetting-menu-main w275px myprofilesetting-menu-main-close`}>
    <ul aria-labelledby="headerUserDropdown">
      <li className="HeaderDropdownTitle">
        <a href="#/" className="dropdown-item no-hover" onClick={() => {
          if(isExternalHeader){}else{
          if (preEstiPageDisable == true) {
            return;
          }
          navigate("/myprofile");
          setshowMyProfile(false)
        }
        }}>
          <div className="d-flex align-items-center">
            <div className="c-list-icon">
              <div className="h40w40 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 comman-image-box">
                <div
                  className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                  style={{ backgroundImage: `url('${ isExternalHeader ? profileImageURL :loginUserImage}')` }}
                ></div>
              </div>
            </div>
            <div className="ps-2 w100minus40 title-fonts d-flex  flex-column">
              <div className="c-list-detail  fw-semibold text-truncate color-white">
               {isExternalHeader ? user_name && user_name != "" ? user_name : _l('l_welcome_myr_ai') : loginUserName}
                <div className="badge badge-white  lh-1 ms-2 px-2 py-1 rounded-pill "><span className="">v {process.env.REACT_APP_VERSION}</span></div>
              </div>
              <div className="c-font f-10 color-white-60 text-truncate">
                {isExternalHeader ?  userDescription : loginUserEmail}
              </div>
            
            </div>
          </div>
          {
            isExternalHeader ?
          <div className="pt-15">
                <Button className="w-100" onClick={discardExternalBenchmark}>{_l("l_discard")}</Button>
          </div>
                :
                <></>
              }
        </a>
      </li>
      {isExternalHeader ? (<></>) :
      (<li className="three-row-btn p-2">
        <div className="d-flex align-items-center p-1 three-row-btn-bg">
          <label className={`row-btn-label w-50 ${currentLanguage == "english" ? "active-language" : ""}`} >
            <input type="radio" checked={currentLanguage == "english" ? true : false} name="Lang-selet-radio" value={currentLanguage == "english" ? true : false} onChange={() => {
              setLanguage("english");
              // window.location.reload();
            }} className="hidden-input" />
            <div className="row-btn-box c-font f-12 p-2" >{_l('l_english')}</div>
          </label>
          <label className={`row-btn-label w-50 ${currentLanguage == "french" ? "active-language" : ""}`}>
            <input type="radio" checked={currentLanguage == "french" ? true : false} name="Lang-selet-radio" value={currentLanguage == "french" ? true : false} onChange={() => {
              setLanguage("french");
              // window.location.reload();
            }} className="hidden-input" />
            <div className="row-btn-box c-font f-12 p-2">{_l('l_french')}</div>
          </label>
        </div>
      </li>)}
      <li>
        <hr className="dropdown-divider m-0" />
      </li>
      {isExternalHeader ? <></> :
      <li className={` ${contact_role == 3 ? "d-none" : ''}`}>
        <a className ="dropdown-item"
          data-tip={`${_l("l_spaces")}`}
          data-for={randomID}
          data-effect="solid"
          data-delay-show='1000'
          data-class="tooltip-main"
          data-place="right"
          onClick={() => {
            setSelectedSpace({});
            dispatch(setFetchedTaskandMessages([]))
            dispatch(commanoffcanvasisOpen(false)) 
            dispatch(SetSelectedTask({}));
            TaskModalClose();
            dispatch(getGroupDetails({})); 
            if ((["operator","contact"].includes(userType) && contact_role != 3) || userType == "staff") {
              if (!contactlistShow) {
              dispatch(leftPanelFilters("","","topicFilterClear"))
              }
              navigate("/dashboard");
              dispatch(setDashBoardView("spaceView"))
              localStorage.removeItem("selectedOffice")
              localStorage.removeItem("selectedVirtualSpace")
              dispatch(setBulkTaskRating([]))
              setSelectedProject(0)
            }else{
              selectLiteVersion()
            }
            if (contactlistShow) {
              setContactlistShow(false)
              localStorage.removeItem("isContactDir")
            }
            setshowMyProfile(false)
          }}
        >
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <Gauge size={18} weight="light" className="c-icons me-2" />
              <div>{_l('l_reporting')}</div>
            </div>
          </div>
        </a>
      </li>}
      {isExternalHeader ? <></> :
      <li className="d-none">
        <a className="dropdown-item"
          data-tip={`${_l("l_email")}`}
          data-for={randomID}
          data-effect="solid"
          data-delay-show='1000'
          data-class="tooltip-main"
          data-place="right"
          onClick={() => {
            if(disabledThirdPartyToolAccess)
            {
              return false;
            }
            accessPremiumFeature(0, () => {
              setSettingTopicName("l_third_party_integration");
              setTopicMainKey("third_party_integrations");
            }, true);
          }}
        >
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="d-flex ">
                {userType == "staff" ?
                  <EnvelopeSimple size={18} weight="light" className="c-icons me-2" />
                  :
                  <BezierCurve size={18} weight="light" className="me-2" />}
                <div className={`${userType == "staff" ? "" : "text-truncate w-70"}`}>
                  {userType == "staff" ? _l("l_email") : _l("l_third_party_integration")}
                </div>
              </div>
              <div className="d-flex mlminus10">
                {unreadComments ?
                  <div class="with-bg h18w18 justify-content-center position-static my-auto  badge-green rounded-circle d-flex right-accordian-header-pill" >
                    <span class="m-auto ps-0 ">{unreadComments}</span>
                  </div>
                  : <></>}
                {unreadMessages ?
                  <div class="ms-1 with-bg h18w18 justify-content-center position-static my-auto  badge-green rounded-circle d-flex right-accordian-header-pill" >
                    <span class="m-auto ps-0 ">{unreadMessages}</span>
                  </div> : <></>}
              </div>
            </div>
          </div>
        </a>
      </li>}
      {isExternalHeader ? <></> :
      <li className={` ${contact_role == 3 || userType !== "staff" ? "d-none" : ''}`}>
        <a className="dropdown-item"
          data-tip={`${_l("l_setting")}`}
          data-for={randomID}
          data-effect="solid"
          data-delay-show='1000'
          data-place="right"
          data-class="tooltip-main"
          onClick={() => {
            if(disabledThirdPartyToolAccess)
            {
              return false;
            }
            if (userType == "staff") {
              setSettingMenuRender(!SettingMenuRender)
            }else{
              accessPremiumFeature(0, () => {
                // navigate("/companyprofile");
                setSettingTopicName("l_company_profile");
                setTopicMainKey("company-profile");
              }, true);
            }
          }}
        >
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
            {userType == "staff" ?   
              <GearSix size={18} weight="light" className={`c-icons me-2`} />
              :
              <Buildings size={18} weight="light" className={`c-icons me-2`} />
            }
              <div>{_l('l_setting')}</div>
            </div>
          </div>
        </a>
      </li>}
      {isExternalHeader && localStorage.getItem("user_type") != "staff"  ? <></> :
      <li className={`${ parseInt(contact_role) != 1 ? "d-none" : ''}`}>
        <a className="dropdown-item"
        data-tip={`${_l(estimateDetails.package_name ? estimateDetails.package_name : "l_stand_slone_platform" )}`}
        data-for={randomID}
        data-effect="solid"
        data-delay-show='1000'
        data-class="tooltip-main"
        data-place="right"
        onClick={() => {
          if(!disabledThirdPartyToolAccess)
          {
            navigate("/premiumsubscription");
            setshowMyProfile(false);
          }
        }}
        >
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <CurrencyEur size={18} weight="light" className="c-icons me-2" />
              <div>{_l('l_my_plan')}</div>
            </div>
          </div>
        </a>
      </li>}
      {isExternalHeader ? <></> :
      <li className={`${contact_role != 3 && userType == "operator"  &&  proposalUnreadComments > 0 || localStorage.getItem("is_registered") == 1 ? "" : "d-none"}`}>
        <a className="dropdown-item"
          data-tip={`${_l("l_proposals")}`}
          data-for={randomID}
          data-effect="solid"
          data-delay-show='1000'
          data-class="tooltip-main"
          data-place="right"
          onClick={() => {
            navigate("/proposals", { state: { from: viewPage } });
            setshowMyProfile(false);
          }}
        >
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center w-100 justify-content-between">
              <div className="d-flex ">
                <Rows size={18} weight="light" className="c-icons me-2" />
                <div>{_l('l_proposals')}</div></div>
              {
                proposalUnreadComments > 0
                ?
                <div class="with-bg h18w18 justify-content-center position-static my-auto  badge-green rounded-circle d-flex right-accordian-header-pill" >
                  <span class="m-auto ps-0 ">{proposalUnreadComments}</span>
                </div>
                :
                <React.Fragment></React.Fragment>
              }
            </div>
          </div>
        </a>
      </li>}
      {isExternalHeader ? (<></>):(
      <li>
        <a href="#/" className={`dropdown-item text-truncate  ${preEstiPageDisable == true ? 'for-disabled' : ''}`} onClick={() => {
          if (preEstiPageDisable == true) {
            return;
          }
          navigate("/myprofile");
          setshowMyProfile(false);
        }}>
          <div className="d-flex align-items-center">
            <div className="" >
              {userType == "staff" ?
                <PencilSimpleLine size={18} className="c-icons me-2" weight="light" /> 
              :
                <GearSix size={18} weight="light" className={`c-icons me-2`} />
              }
              </div>
            <div className="text-truncate">{userType == "staff" ? _l('l_edit_profile') :_l('l_setting') + ' & ' +_l('l_edit_profile')}</div>
          </div>
        </a>
      </li>
      )}
      <li>
        <hr className="dropdown-divider m-0" />
      </li>
      {isAdmin || adminAsStaff || hideLogoutSwitch   ?
        <li>
         <div className="dropdown">
            <a href="#/" className="dropdown-item text-truncate" type="button"
              id="CategoryFilterDropdown"
              data-bs-toggle="dropdown">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <div
                    className="me-2">
                    <UserSwitch size={20} className="c-icons" weight="light" />

                  </div>
                  <div className="text-truncate">{_l('l_recent_login')}</div>
                </div>
                <CaretDown size={15} className="c-icons m-0 opacity-50" weight="fill" />
              </div>  
            </a>
            <GeneralListing widthClass="w-280"
              list={recentLoginUserList && recentLoginUserList.map((item) => {
                return {
                  name: item.fullname,
                  image: item.profile_image_url,
                  email: item.email,
                  client_id: item.client_id,
                  project_id: item.project_id,
                  is_primary_user: item.is_primary_user,
                  staff_id: item.staff_id
                };
              })}

              handler={(data) => {
                relecentloginHandler(data.email, data.client_id, data.project_id, data.staff_id)
              }}
              placeHolderName='No login user'
            />
          </div>
        </li>   
        :
          <React.Fragment></React.Fragment>
        }
      {isExternalHeader ? <></> :
      <li className="">
        <div className="dropdown-item">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <Sun size={18} className="c-icons me-2" weight="light" />
              <div>{_l('l_appearance')}:{LightTheme ? _l('l_light') : _l('l_dark')}</div>
            </div>
            <Form.Check
              type="switch"
              id=""
              label=""
              className="with-no-label-space"
              checked={LightTheme}
              onChange={() => {
                setTheme(!LightTheme);
                
              }}
            />
          </div>
        </div>
      </li>}
      {isExternalHeader ? (<></>):(<>
      <li className="">
        <div className="dropdown-item">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <Wrench size={18} className="c-icons me-2" weight="light" />
              {_l('l_tools_information')}
            </div>
            <Form.Check
              type="switch"
              id=""
              label=""
              className="with-no-label-space"
              checked={showToolsModal}
              onChange={() => {
                setShowToolsModal(!showToolsModal);
              }}
            />
          </div>
        </div>
      </li>
      {/* <li className="">
        <div className="dropdown-item">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <MonitorPlay size={18} className="c-icons me-2" weight="light" />
              <div>{_l('l_start_tutorial')}</div>
            </div>
            <Form.Check
              type="switch"
              id=""
              label=""
              className="with-no-label-space"
              checked={ProductTourGuide}
              onChange={(e) => {
                dispatch(
                  ProductTour(e.target.checked)
                );
              }}
            />
          </div>
        </div>
      </li> */}
      </>
      )}
      {PauseMenu ? (
        <React.Fragment>
          <li>
            <a href="#/" className="dropdown-item text-truncate">
              <div className="d-flex">
                <div className="me-2"> <GearSix size={20} className="c-icons" weight="light" /> </div>
                <div className="text-truncate">{_l('l_setting')}</div>
              </div>
            </a>
          </li>
          <li>
            <a href="#/" className="dropdown-item text-truncate">
              <div className="d-flex">
                <div
                  className="me-2">
                  <Question size={20} className="c-icons" weight="light" />
                </div>
                <div className="text-truncate">{_l('l_help')}</div>
              </div>
            </a>
          </li>
        </React.Fragment>
      ) : (<></>
      )}
      {isExternalHeader ? (<></>):(<>
      <li>
        <hr className="dropdown-divider" />
      </li>
      <li>
        {
          adminAsStaff || hideLogoutSwitch ?
            <>
              <a href="#/" className="dropdown-item text-truncate" onClick={handleSwitchLogin}>
                <div className="d-flex">
                  <div
                    className="me-2">
                    <SignOut size={20} className="c-icons" weight="light" />
                  </div>
                  <div className="text-truncate">{_l('l_switch_to_admin')}</div>
                </div>
              </a> 
            </>
            :
            <a href="#/" className="dropdown-item text-truncate" onClick={logout}>
              <div className="d-flex align-items-center">

                <div
                  className="me-2">
                  <SignOut size={18} className="c-icons" weight="light" />
                </div>
                <div className="text-truncate">{_l('l_logout')}</div>
              </div>
            </a>
        }
      </li>
      </>
      )}
      {
        showToolsModal ?
          <ToolsInformationModal
            show={showToolsModal}
            handleClose={() => {
              setShowToolsModal(false);
            }}
          />
          :
          <></>
      }
    </ul>
        </div>
    </>
  )
}

export default MyProfileDropDownNew