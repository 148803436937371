import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import { _l } from "../../hooks/utilities";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

const SpaceSelectionModal = ({showSpaceModal, handleClose}) => {

  const navigate = useNavigate();
  const { show, actionType } = showSpaceModal
  const spaceList = JSON.parse(localStorage.getItem("spaces")).map((space) => {
    return { value: space.project_id, label: space.office_title != "" ? space.office_title : space.postal_address };
  })
  const [selectedSpace, setSelectedSpace] = useState(null)

  const handleNavigate = () => {
    if (selectedSpace != null) {
        console.log(actionType,"");
        const { value } = selectedSpace
        switch (actionType) {
            case "schedule":
                navigate(`/connect-provider?space=${value}&provider-type=schedule`);
                break;
            case "benchmark":
                navigate(`/connect-provider?space=${value}&provider-type=benchmark`);
                break;
            case "provider":
                navigate(`/connect-provider?space=${value}&provider-type=invite_provider`);
                break;
            case "equipment":
                navigate(`/equipments?space=${value}&action=equipment-list`);
                break;
            default:
                handleClose()
                break;
        }
        handleClose()
    }
  }

  return (
    <>
          <Modal
              show={show}
              onHide={handleClose}
              className="custom-modal-style w1300px"
              aria-labelledby="contained-modal-title-vcenter"
              size="md"
              centered
              scrollable
          >
              <Modal.Header closeButton className="">
                  <Modal.Title>{`${_l('l_select')} ${_l('l_space')}`}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="py-0 min-h-230px">
                  <div className="form-wrapper-main">
                      <div className="row m-0 mb-3">
                          <Form.Group className="col-xl-12 c-input-box position-relative mb-3 g-0 z-3">
                              <div className="d-flex">
                                  <Select
                                      className="custom-select-menu bg-white-03 onboarding-map-input scroll-height-20vh position-sticky top-0 flex-grow-1"
                                      placeholder={_l("l_select")}
                                      options={spaceList ? spaceList : []}
                                      value={selectedSpace}
                                      onChange={(e) => setSelectedSpace(e)}
                                      classNamePrefix="react-select"
                                  />
                              </div>
                          </Form.Group>
                      </div>
                  </div>
              </Modal.Body>
              <Modal.Footer className="border-0 justify-content-end">
                  <Button variant="secondary" size="sm" onClick={handleClose}>
                      {_l('l_close')}
                  </Button>
                  <Button variant="primary" size="sm" onClick={() => handleNavigate()}>
                      {_l('l_select')}
                  </Button>
              </Modal.Footer>
          </Modal>
    </>
  );
};

export default SpaceSelectionModal;
