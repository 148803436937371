import React, { useState, useEffect } from "react";
import CommanSmallSearchbox from "../Searchbox/CommanSmallSearchbox";

import CustomerServices from "../../services/customer-services";
import Form from 'react-bootstrap/Form';
import { useSelector, useDispatch } from "react-redux";
import { X } from "phosphor-react";
import { _l, showLeftPanelNew } from "../../hooks/utilities";
import moment from "moment";
import jquery, { data } from "jquery";
import {
  leftPanelFilters, unReadCommentTasks
} from "../../actions/customer";
import EquipmentDayTable from "./EquipmentDayTable";
import EquipmentStatusTable from "./EquipmentStatusTable";
const EquipmentSheetMain = ({ projectId }) => {
  const { calendarData, taskFilters, isAppliedUnreadComment, globalSearchString, daterangeAppliedFilters, defaultTopics } = useSelector((state) => state.customer);
  const [getEquipment, setEquipment] = useState([])
  const [getEquipmentForSearch, setEquipmentList] = useState([])
  const [taskTotalCount, setTaskTotalCount] = useState({})
  const [ToggleReportingView, setToggleReportingView] = useState(false);
  const [searchEmpolyees, setSearchEmpolyees] = useState('');
  const [initailAPI, setInitailAPI] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedEquipmentId, setSelectedEmployeeId] = useState([])
  const dispatch = useDispatch();
  let unReadFilter = isAppliedUnreadComment ? isAppliedUnreadComment : false
  let fromDate = localStorage.getItem('selectedDate') ? moment(localStorage.getItem('selectedDate')).format("YYYY-MM-DD") : new Date()

  let toDate = ''
  let dateRangeFilters = taskFilters && taskFilters.length ? taskFilters.filter((dateRangeFilter) => dateRangeFilter.main_key == "date_range_filter") : []

  useEffect(() => {
    if (isAppliedUnreadComment && initailAPI) {

      getEquipmentList('', '', unReadFilter)
    } else if (!isAppliedUnreadComment && initailAPI) {

      if (dateRangeFilters && dateRangeFilters.length > 0) {
        fromDate = localStorage.getItem('fromDate')
        toDate = localStorage.getItem('toDate')
        getEquipmentList(fromDate != '' ? fromDate : '', toDate, unReadFilter)
      }else if (dateRangeFilters && dateRangeFilters.length == 0) {
        fromDate = moment(localStorage.getItem('selectedDate')).format("YYYY-MM-DD")
        getEquipmentList(fromDate != '' ? fromDate : '', '', unReadFilter)
      }
    }
  }, [isAppliedUnreadComment, taskFilters])
  useEffect(() => {
    if (projectId) {
      if ((dateRangeFilters && dateRangeFilters.length > 0) || (!["ChatDate","today","date"].includes(daterangeAppliedFilters))) {
        fromDate = localStorage.getItem('fromDate')
        toDate = localStorage.getItem('toDate')
      }
      if (!isAppliedUnreadComment && fromDate && dateRangeFilters && dateRangeFilters.length > 0 && initailAPI) {

        getEquipmentList(fromDate != '' ? fromDate : '', toDate, unReadFilter)
      } else if (!isAppliedUnreadComment && fromDate && dateRangeFilters && dateRangeFilters.length == 0 && initailAPI) {

        getEquipmentList(fromDate != '' ? fromDate : '', toDate, unReadFilter)
      }
    }

  }, [projectId, calendarData])

  // useEffect(() => {
  //   if (globalSearchString !== "" || globalSearchString !== undefined || globalSearchString != []){
  //     let tempSearchEmpolyees = getEquipmentForSearch.filter((data)=>data && data.quantity_name.toLowerCase().includes( globalSearchString   ? globalSearchString  .toLocaleLowerCase() : "")
  //     )
  //     setEquipment(tempSearchEmpolyees);
  //   } else {
  //     setEquipment(getEquipmentForSearch);
  //   }
  // }, [globalSearchString])
  useEffect(() => {
    setSelectedEmployeeId(taskFilters.filter((data)=>data.filter_key != "date_range_filter").map((id) => id.filter_key));
  }, [JSON.stringify(taskFilters)])
  
  useEffect(()=>{
    let taskfilter = taskFilters && taskFilters.length > 0 ? taskFilters.filter((data)=>data.filter_key != "date_range_filter") : []
    if (taskfilter.length == 0) {
      dispatch(leftPanelFilters("", "equipments", "hold_range_filter_task_count"))
    }
  },[defaultTopics])

  useEffect(() => {
    if ((dateRangeFilters && dateRangeFilters.length) || (!["ChatDate","today","date"].includes(daterangeAppliedFilters))) {
      fromDate = localStorage.getItem('fromDate')
      toDate = localStorage.getItem('toDate')
    }else{
      toDate = '';
    }
    getEquipmentList(fromDate, toDate, unReadFilter, taskFilters)
    setInitailAPI(true)
    showLeftPanelNew();
  }, [])

  function getEquipmentList(fromDate, toDate, unReadFilter) {
    setShowLoader(true);
    CustomerServices.getEquipmentReportList(fromDate, toDate, unReadFilter, taskFilters).then((res) => {
      setShowLoader(false);
      if (res.status) {
        setEquipment(res.data);
        setEquipmentList(res.data)
        setTaskTotalCount(res.extra ? res.extra : {})
      }
    });
  }
  // useEffect(() => {
  //   setShowLoader(true);
  //   CustomerServices.getEquipmentReportList(fromDate, toDate, unReadFilter).then((res) => {
  //     setShowLoader(false);
  //     if (res.status) {
  //       setEquipment(res.data);
  //       setEquipmentList(res.data)
  //       setTaskTotalCount(res.extra ? res.extra : {})
  //     }
  //   });
  // }, [])

  return (
    <>
      <div className="comman-main-box custom-accordian-main res-padding-right0 h-100 task-list-accordian position-relative pe-0 ps-10px">
        <div className="task-list-accordian custom-accordian-main mb-2 px-0 ">
          <div className="d-flex align-items-center justify-content-between">
            <div className="active to-do accordion-header w-auto flex-grow-1">
              <div className="accordion-button">
                <div className="left-task-header radius_3 pe-3">
                  <div className="d-flex align-items-center fw-semibold title-fonts c-font f-13">
                    <span className="c-dots  c-7 in-progress-white rounded-circle me-2"></span>
                    <span className="task-title pe-2">{_l('l_total_equipment')}</span>
                    <span className=""> [{getEquipment.length}]</span>
                  </div>
                </div>
              </div>
            </div>
            {taskFilters && taskFilters.length ? (
              taskFilters.map((value, index) => {
                if (value.main_key == "date_range_filter" && value.filter_key != "all") {
                  let displayFilterKey = value.title;
                  jquery("body #active_week_date").removeClass("c-gradinet-active-border");
                  return (
                    <div
                      key={index}
                      className="badge  c-font f-12 w-auto color-white-80 bg-white-05  radius_3 me-2 comman-round-box active"
                    >
                      <div className="d-flex align-items-center">
                        {/* <span className="color-white">{`${filter_key[0].toUpperCase()}${
                                filter_key.length > 1
                                  ? filter_key.slice(1).split("_").join(" ")
                                  : ""
                              }`}</span> */}

                        <span className="color-white">
                          {displayFilterKey}
                        </span>
                        <a href="#/"
                          className="ps-1"
                          onClick={() => {
                            dispatch(
                              leftPanelFilters({
                                main_key: value.main_key,
                                filter_key: value.filter_key,
                                title: value.title,
                                main_sub_key: value.main_sub_key,
                              })
                            );


                            // jquery(
                            //   ".fixed-left-panel.provider-list"
                            // ).addClass("expanded");
                            // jquery("body").addClass("body-toggle");
                            // jquery(".after-login-header").addClass(
                            //   "body-toggle"
                            // );
                            // jquery("footer").addClass("body-toggle");
                          }}
                        >
                          <X size={14} className="c-icons" weight="light" />
                        </a>
                      </div>
                    </div>
                  );
                }
              })
            ) : (
              <></>
            )}
            <div className="d-flex align-items-center ">
              <Form.Check
                type="switch"
                id="custom-switch"
                label={_l("l_date_view")}
                className="me-3 withbgwhite"
                onClick={() => {
                  setToggleReportingView(!ToggleReportingView);
                }}
              />
              {/* <CommanSmallSearchbox searchEmpolyees={searchEmpolyees} setSearchEmpolyees={setSearchEmpolyees} /> */}
            </div>
          </div>
        </div>
        {ToggleReportingView ? <EquipmentDayTable /> : <EquipmentStatusTable projectId={projectId} getEquipment={getEquipment} taskTotalCount={taskTotalCount} showLoader={showLoader} selectedEquipmentId={selectedEquipmentId}/>}

      </div>

    </>
  )
}

export default EquipmentSheetMain