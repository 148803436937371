import React, { useEffect, useState } from "react";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Select from "react-select";
import Form from 'react-bootstrap/Form';
import { _l } from "../../../hooks/utilities";
import CustomWhatsappIcon from "../../../assets/icons/CustomWhatsappIcon";
import CustomGmailIcons from "../../../assets/icons/CustomGmailIcons";
import CustomSlackIcon from "../../../assets/icons/CustomSlackIcon";
import { XCircle, Plus, NotePencil, Trash, User } from "phosphor-react";
import User_2 from "../../../assets/images/default-image-placeholder.png";
import AddEmployee from "../../Modals/AddEmployee";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyEmployee } from "../../../actions/customer";
import { showMessage } from "../../../actions/messages";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

const PilotOnboardingTenant = ({ data, projectId, setIsExternal, setSelectedTab, toolsList, InternalDropdawn, RoleList, handleSave, loder }) => {
  const dispatch = useDispatch();
  const regex = /^[0-9\b]+$/;
  const [documentType, setDocumentType] = useState( {
    value: "1",
    label: "Internal",
    key:"internal"
  });
  const [documentId, setDocumentId] = useState(0);
  const [showAddContact, setShowAddContact] = useState(false);
  const [visibility, setVisibility] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [totalArea, setTotalArea] = useState(0);
  const [tenants, setTenants] = useState(0);
  const [cost, setCost] = useState(0);
  const [meeting, setMeeting] = useState(0);
  const [saving, setSaving] = useState(0);
  const [tools, setTools] = useState([]);
  const [people, setPeople] = useState([]);
  const [peopleName, setPeopleName] = useState("");
  const [peopleRole, setPeopleRole] = useState("");
  const [facilityManager, setFacilityManager] = useState({});
  const [selectedPeople, setSelectedPeople] = useState([]);
  const companyEmployeeList = useSelector((state) => state.customer.getCompanyEmployee);
  useEffect(() => {
    if (data) {
      setCompanyName(data.company_name);
      setTotalArea(data.total_area);
      if (data.document_type) { 
        setDocumentType(InternalDropdawn.filter(t => t.key == data.document_type)[0])
      }
      setDocumentId(data.document_id);
      setVisibility(data.visibility || []);
      setTenants(data.tenants);
      setSaving(data.saving);
      setCost(data.cost);
      setMeeting(data.meeting);
      setSelectedPeople(data.people)
      setFacilityManager(data.facility_manager)
      if (data.tools) { 
        setTools(toolsList.map(t => data.tools.includes(t.label) ? t : ''));
      }
    }
  }, [data])
  useEffect(() => {
    dispatch(getCompanyEmployee());
  }, []);
  useEffect(() => {
    if (documentType.key == "external") {
      setIsExternal(true);
    } else {
      setIsExternal(false);
    }
  }, [documentType,data])
  useEffect(() => {
    if (companyEmployeeList) {
      const formattedResponse = companyEmployeeList.map((employee, index) => {
        return {
          id: employee.id,
          index: index + 1,
          people_name: employee.full_name,
          people_role: employee.role_detail.role_name
        };
      });
      setPeople(formattedResponse);
    }
  }, [companyEmployeeList])

  const deleteTools = (id) => {
    setTools(tools.filter(t => t.value != id))
  }
  const addPeople = () => {
    setPeople([...people, { people_name: peopleName, people_role: peopleRole.label }]);
    setPeopleName("");
    setPeopleRole("");
  }
  const validateData = () => {
    if (companyName == "") {
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_plaese_enter_comapny_name")));
      return false
    } else if(documentType && documentType.value == ""){
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_plaese_select_type")));
      return false
    } else if (totalArea == "" || totalArea < 0){
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_plaese_enter_total_area")));
      return false
    } else if (cost == "" || cost < 0){
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_plaese_enter_cost")));
      return false
    } else if (visibility.length == 0){
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_plaese_select_visibility")));
      return false
    } else {
      return true
    }
  }
  const handleNext = () => {
      let toolsItem = []
      for (let i = 0; i < tools.length; i++) {
        if (tools[i] != "") {
          toolsItem.push(tools[i].label)       
        }
      }
      if (validateData()) { 
        handleSave(projectId, documentId, documentType.key, companyName, totalArea, tenants, cost, visibility, meeting, toolsItem, selectedPeople, saving, facilityManager)
      }
  }
  return (
    <>
    <div className="flex-grow-1 overflow-hiiden-auto">
      <Form className="row">
        <Form.Group className="mb-3 col-xl-6  c-input-box" controlId="formGroupPassword">
          <Form.Label className="input-label no-asterisk ">FM - External / Internal? </Form.Label>
          <Select
            className="custom-select-menu"
            classNamePrefix="react-select"
            placeholder={_l("l_select_type")}
            options={InternalDropdawn}
            closeMenuOnSelect={true}
            onChange={(value) => {
              setDocumentType(value)
            }}
            value={documentType}
          />
        </Form.Group>
        <Form.Group className="mb-3 col-xl-6 c-input-box" controlId="formGroupEmail">
          <Form.Label className="input-label no-asterisk ">{_l("l_company_name")}</Form.Label>
          <Form.Control type="test" placeholder="Jaguar pvt. ltd"  onChange={(e) => setCompanyName(e.target.value)} value={companyName} />
        </Form.Group>
        <Form.Group className="mb-3 col-xl-6 c-input-box" controlId="formGroupEmail">
          <Form.Label className="input-label no-asterisk ">{_l("l_total")} {_l("l_area")}</Form.Label>
          <Form.Control type="number" placeholder={_l("l_total") + " " +_l("l_area")} pattern="[0-9]*" onChange={(e) => setTotalArea(regex.test(e.target.value) ? e.target.value : 0 )} value={totalArea} />
        </Form.Group>
        <Form.Group className="mb-3 col-xl-6 c-input-box" controlId="formGroupEmail">
          <Form.Label className="input-label no-asterisk ">{_l("l_tenants")}</Form.Label>
          <Form.Control type="number" placeholder={_l("l_tenants")} pattern="[0-9]*" onChange={(e) => setTenants(regex.test(e.target.value) ? e.target.value : 0 )} value={tenants} />
        </Form.Group>
        <Form.Group className="mb-3 col-xl-6 c-input-box" controlId="formGroupEmail">
          <Form.Label className="input-label no-asterisk ">{_l("l_cost")}</Form.Label>
          <Form.Control type="number" placeholder={_l("l_cost")} pattern="[0-9]*" onChange={(e) => setCost(regex.test(e.target.value) ? e.target.value : 0 )} value={cost} />
        </Form.Group>
        <Form.Group className="mb-3 col-xl-6 c-input-box" controlId="formGroupPassword">
          <Form.Label className="input-label no-asterisk ">{_l("l_meeting")}</Form.Label>
          <Form.Control type="number" placeholder={_l("l_meeting")} pattern="[0-9]*" onChange={(e) => setMeeting(regex.test(e.target.value) ? e.target.value : 0 )} value={meeting} />
        </Form.Group>
        <Form.Group className="mb-3 col-xl-6 c-input-box" controlId="formGroupPassword">
          <Form.Label className="input-label no-asterisk ">{_l("l_tools")}</Form.Label>
          <Select
            value={tools}
            className="custom-select-menu max-h95 multi-select-with-scroll select53px"
            classNamePrefix="react-select"
            options={toolsList}
            isMulti
            closeMenuOnSelect={false}
            placeholder={`${_l('l_select_tool')}`}
            onChange={(value) => {
              setTools(value);
            }}
          />
        </Form.Group>
        {
          documentType.key == "internal" ? 
        <Form.Group className="mb-3 col-xl-6 c-input-box" controlId="formGroupEmail">
          <Form.Label className="input-label no-asterisk ">{_l("l_saving")}</Form.Label>
          <Form.Control type="number" placeholder={_l("l_saving")} pattern="[0-9]*" onChange={(e) => setSaving(regex.test(e.target.value) ? e.target.value : 0 )} value={saving} />
        </Form.Group>
          :
          <></>
        }
        <div className="d-flex align-items-center mb-3">
        {
          tools.map((t, k) => {
            return (
                t != "" ?
              <div className="badge rounded-pill text-nowrap d-flex align-items-center me-2" key={k}>
                {t.value == 1 ?
                  <CustomWhatsappIcon className="h25w25" />
                  : t.value == 2 ?
                    <CustomGmailIcons className="h25w25" />
                    : t.value == 3 ?
                      <CustomSlackIcon className="h20w20" />
                      :
                      <></>
                }
                <span className="c-font color-white-80 f-12 flex-grow-1 px-2 text-truncate">{t.label}</span> <a href="#/" onClick={() => deleteTools(t.value)}><XCircle size={18} className="c-icons m-0 opacity-50" weight="light" /></a>
              </div>
              :
              <></>
            );
          })
        }
      </div>
          <Form.Group className="d-flex mb-3 col-xl-6 c-input-box">
            <Form.Check className="me-2" type="checkbox" checked={visibility.includes("contact")} onChange={(e) => {
               if (e.target.checked) {
                setVisibility([...visibility,"contact"])
              }else{
                setVisibility(visibility.filter(v => v != "contact"))
              }
              }} />
            <Form.Label className="input-label no-asterisk ">{_l("l_visible_to_customer")}</Form.Label>
          </Form.Group>
          <Form.Group className="d-flex mb-3 col-xl-6 c-input-box">
            <Form.Check className="me-2" type="checkbox" checked={visibility.includes("operator")} onChange={(e) => {
                if (e.target.checked) {
                  setVisibility([...visibility,"operator"])
                }else{
                  setVisibility(visibility.filter(v => v != "operator"))
                }
              }} />
            <Form.Label className="input-label no-asterisk ">{_l("l_visible_to_provider")}</Form.Label>
          </Form.Group>
      </Form>
      <Form className=" py-2 border-bottom d-flex justify-content-between align-items-center comman_action_icon">
        <div className="fw-semibold title-fonts">{_l("l_people")}</div>
        <Button variant="secondary" size="sm"  onClick={() => setShowAddContact(true)}>{_l("l_add_people")}</Button>
          {/* <a href="#/" className="action_icon  h30w30 d-flex ms-2 btn-primary"><Plus size={18} className="m-auto" weight="light" /></a> */}
        
        {/* <Form.Group className="mb-3 col-xl-6" controlId="formGroupEmail">
          <div className="d-flex align-items-center">
            <div className="comman-image-box h25w25 rounded-circle me-2">
              <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url('${User_2}')` }}>

              </div>
            </div>
            <Form.Control type="text" placeholder="Jaguar pvt. ltd" value={peopleName} onChange={(e) => setPeopleName(e.target.value)} />
          </div>
          
        </Form.Group>
        <Form.Group className="mb-3 col-xl-6" controlId="formGroupEmail">
          <div className="d-flex align-items-center comman_action_icon">
          <Select
            className="custom-select-menu"
            classNamePrefix="react-select"
            placeholder={_l("l_select")}
            options={RoleList}
            closeMenuOnSelect={false}
            onChange={(value) => {
              setPeopleRole(value)
            }}
            value={peopleRole}
          />
          <a href="#/" className="action_icon with_bg h30w30 d-flex ms-2" onClick={() => addPeople()}><Plus  size={18} className="c-icons " weight="light" /></a>
          </div>
        </Form.Group> */}
       
      </Form>
      <ul className="w-100 mt-3">
        {
          people.map((p, k) => {
            return (
              <li className="w-100" key={k}>
                <a href="#/" className="task-list-card list-view w-100 p-2 d-flex align-items-center">
                  <Form.Check className="me-2" type="checkbox" checked={selectedPeople.includes(p.id)} onChange={(e) => {
                    if (selectedPeople.includes(p.id)) {
                      setSelectedPeople(selectedPeople.filter(x => x != p.id))
                    }else{
                      setSelectedPeople([...selectedPeople,p.id])
                    }
                  }} />
                  <div className="d-flex flex-grow-1 align-items-center w100minus100per">
                    <div className="comman-image-box h25w25 rounded-circle me-2">
                      <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle d-flex">
                        <User size={14} className="c-icons " weight="light" />
                      </div>
                    </div>
                    <div className="flex-grow-1 fw-semibold text-truncate title-fonts w100minus100per pe-2">{p.people_name}</div>
                  </div>
                  <div className="fw-light max-width-160px text-truncate">{p.people_role}</div>
                  <div className="d-flex  justify-content-start z-index-3 comman_action_icon d-none">
                    <a href="#/" className="action_icon with_bg h30w30 d-flex me-2">
                      <NotePencil size={18} className="c-icons " weight="light" />
                    </a>
                    <a href="#/" className="action_icon with_bg h30w30 d-flex">
                      <Trash size={18} className="c-icons " weight="light" />
                    </a>
                  </div>
                </a>
              </li>
            )
          })
        }
      </ul>
      </div>
      <div className="d-flex justify-content-end pt-2"><Button variant="primary" size="sm"  onClick={() => {
        if(!loder){
          handleNext()
        }
        }}>
        {loder
            ? _l('l_please_wait')
            : _l('l_next')}
          {loder ? <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            className={`ms-2 `}
            aria-hidden="true"
          />
            : <></>
          }
        </Button></div>
      {
        showAddContact ? (
          <AddEmployee
            handleClose={() => {
              setShowAddContact(false);
            }}
            projectId={projectId}
            show={showAddContact}
          // updateDataSource={handleAddedContact}
          // topicType={topicType}
          // selectedRecord={selectedEmployeeData}
          />
        ) : (
          <></>
        )
      }
    
    </>
  );
};


export default PilotOnboardingTenant;

