import React, { useState, useEffect, useRef } from "react";
import {
  CloseCommentModal,
  getAssigneeTypesForRequest,
  LeftPanelResponsiveToggle,
  _l,
  getDatePickerLocale,
  getDatePickerTimeCaption,
  removeDuplicateObjects,
  getYearsBetweenDates,
  selectedAssigneeForTaskAndRequest,
  HideDropdown,
  showSuccess,
  showError,
} from "../hooks/utilities";
import HighPriorityIcon from "../assets/icons/HighPriorityIcon";
import HighPriorityActiveIcon from "../assets/icons/HighPriorityActiveIcon";
import CustomerServices from "./../services/customer-services";
import CheckedDropdown from "./Dropdowns/CheckedDropdown";
import ImageUploadDropdown from "./Dropdowns/ImageUploadDropdown";
import DeleteDropdown from "./Dropdowns/DeleteDropdown";
import moment from "moment";
import TaskListStatusDropdown from "./Dropdowns/TaskListStatusDropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactTooltip from "react-tooltip";
import Toasts from "./Toasts/Toasts";
import Form from "react-bootstrap/Form";
import { UncontrolledDropdown, DropdownToggle, DropdownItem } from "reactstrap";
import LeftPanelLogo from "../assets/images/tomoonlogo.svg";
import {
  CalendarBlank,
  Plus,
  PushPin,
  Image,
  ChatCircle,
  CaretDown,
  UserCircleGear,
  DotsThreeVertical,
  Eye,
  DownloadSimple,
  Cube,
  MapPin,
  DotsNine,
  ArrowBendDownLeft,
  Star,
  Trash,
  PhoneCall,
  CaretRight,
  ListChecks,
} from "phosphor-react";
import {
  getDaysBetweenDates,
  getHoursBetweenDates,
  getMintuesBetweenDates,
} from "../hooks/utilities";
import {
  updateTaskStatus,
  changePriority,
  getSelectedTask,
  getTaskCount,
  setTaskData,
  IsToastOpenOrClose,
  getTopicTasks,
  updateUnreadCommentCount,
  setTaskUnreadCmtCntAll,
  getMentionList,
  setTaskAssigneeNew,
  taskBulkAction,
  setVirtualSpaceNotificationCount,
  updateTaskPin,
  setSpaceNotificationCount,
  updateTaskList,
  commanoffcanvasisOpen,
  setFetchedTaskandMessages,
  chatUnreadMessageCount,
  updateProviderListTableData,
  getProviderListTableData,
  istodoTable,
} from "../actions/customer";

import { getGroupDetails, initCall } from "../actions/chat";
import SpaceListDropdown from "./Dropdowns/SpaceListDropdown";
import { useDispatch, useSelector } from "react-redux";
import jquery from "jquery";
import GeneralListing from "./TaskModalComponents/GeneralListing";
import * as bootstrap from "bootstrap";
import TrackingDropdown from "./Dropdowns/TrackingDropdown";
import ImageMapPinIcon from "../assets/icons/ImageMapPinIcon";
import { showMessage, toastCloseAction } from "../actions/messages";
import { GET_SELECTED_TASK } from "../actions/action-type";
import CommentMinified from "./Comments/CommentMinified";
import DocumentsServices from "../services/documents-services";
import ImageViewer from "./Offcanvas/ImageViewer";
import MeetingProviderModal from "./Modals/MeetingProviderModal";
import customerServices from "./../services/customer-services";
import EditTaskModal from "./Modals/EditTaskModal";
import RobotPlanDetailModal from "./Modals/RobotPlanDetailModal";
import { CUSTOM_ASSIGNEE_CHANGE } from "../constants/constants";
import AddAssigneeMainDropdownNew from "./TaskModalComponents/AddAssigneeMainDropdownNew";
import CommanPlaceholder from "./Placeholder/CommanPlaceholder";
import ReactHtmlParser from "react-html-parser";
import Lightbox from "yet-another-react-lightbox";
import CommanOffCanvas from "./Offcanvas/CommanOffCanvas";

import ShareNetworkIcon from "../assets/icons/ShareNetworkIcon";
import ActionDropdown from "./Dropdowns/ActionDropdown";
import TaskShareLinkModal from "./Modals/TaskShareLinkModal";
import UncontrolledGeneralListing from "./TaskModalComponents/UncontrolledGeneralListing";
import AddTaskIcon from "../assets/icons/AddTaskIcon";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import chatServices from "../services/chat-services";
import StatusDropdown from "./Dropdowns/StatusDropdown";
window.bootstrap = bootstrap;

let showComments = false;

const TaskCard = ({
  task,
  active,
  handler = () => {},
  getTaskDetails,
  pageName = "",
  taskCheckUncheck = () => {},
  checkedTask,
  taskPinned = "false",
  setTaskPinned,
  contacts = [],
  setTaskId,
  setCallUserList = () => {},
  driveDocument,
  initReply = () => {},
  replyTaskId = 0,
  fromReply = false,
  showOptions = 0,
  setshowOptions = () => {},
  setSelectedTaskIdforIconToggle = () => {},
  selectedTaskIdforIconToggle = 0,
  showDefaultIcons = [],
  setShowDefaultIcons = () => {},
  handleToggleIcons = () => {},
  msgid = "",
  setUnreadMessage = () => {},
}) => {
  const {
    taskUnreadCmtCntAll,
    userTeams,
    commanoffcanvasIsOpen,
    taskFilters,
    operatorListByCategory,
    virtualSpaceList,
    taskList,
    taskandMessages,
    globalView,
    groupDetails,
    providerlistTableData
  } = useSelector((state) => state.customer);
  const isVirtualSpaces = localStorage.getItem("selectedVirtualSpace");
  const [openCheckList, setOpenCheckList] = useState(false);
  const [openCheckListTracking, setOpenCheckListTracking] = useState(false);
  const [isLiteVersion, setisLiteVersion] = useState(false);
  const [openAttachmentsDropdown, setOpenAttachmentsDropdown] = useState(false);
  const [openLinksDropdown, setOpenLinksDropdown] = useState(false);
  const [openCommentTab, setOpenCommentTab] = useState(false);
  const [openFollowersDropdown, setOpenFollowersDropdown] = useState(false);
  const [openAssigneeDropdown, setOpenAssigneeDropdown] = useState(false);
  const [taskPriority, setTaskPriority] = useState(
    jquery.isNumeric(task.priority) === false
      ? task.priority && task.priority.trim() == "3"
        ? 3
        : 2
      : task.priority == 3
      ? 3
      : 2
  );
  const [providerCategorylist, setProviderCategorylist] = useState([]);
  const [taskHide, setTaskHide] = useState(false);
  const [updatedCommentCount, setUpdatedCommentCount] = useState(
    Number(task.comments_count)
  );
  const [updatedLinkCount, setUpdatedLinkCount] = useState(0);
  const [task_type, setTask_type] = useState();
  const [taskProviderTitle, setTaskProviderTitle] = useState("");
  const [meetingModal, setMeetingModal] = useState(false);
  // const [dropdownOpen, setDropdownOpen] = useState([]);
  const [selectedAssigneeNew, setSelectedAssigneeNew] = useState([]);
  // const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [isTaskPinned, setIsTaskPinned] = useState(
    task.pinned && task.pinned == 1 ? true : false
  );
  const [showTaskImage, setShowTaskImage] = useState(false);
  const [attachmentDetails, setAttachmentDetails] = useState({});
  const [selectedAttachmentId, setSelectedAttachmentId] = useState(0);
  const [replyCommentData, setReplyCommentData] = useState({});
  const [fileUploadLoader, setFileUploadLoader] = useState(false);

  const [taskStartDate, setTaskStartDate] = useState(
    task.startdate && task.startdate != "0000-00-00 00:00:00"
      ? new Date(task.startdate)
      : null
  );
  const [taskDueDate, setTaskDueDate] = useState(
    task.duedate && task.duedate != "0000-00-00 00:00:00"
      ? new Date(task.duedate)
      : null
  );
  const showStartDate =
    (task.addedfrom == 0 &&
      task.schedule_id &&
      parseInt(task.schedule_id) > 0) ||
    task.is_requested == 1 ||
    (task.provider && !task.duedate)
      ? true
      : false;
  const startDateTitle = taskStartDate
    ? `${_l("l_start_date")}: ` +
      moment(taskStartDate).format("DD/MM/YYYY hh:mm a")
    : `${_l("l_start_date")}`;
  const dueDateTitle = taskDueDate
    ? `${_l("l_due_date")}: ` + moment(taskDueDate).format("DD/MM/YYYY hh:mm a")
    : `${_l("l_due_date")}`;

  const [updatedAttachmentCount, setUpdatedAttachmentCount] = useState(0);
  const [requestAssignee, setRequestAssignee] = useState([]);
  const [taskStatus, setTaskStatus] = useState(task.status);
  const [taskAssignee, setTaskAssignee] = useState([]);
  const [taskAssigneeTeam, setTaskAssigneeTeam] = useState([]);
  const [taskAllAssignee, setTaskAllAssignee] = useState([]);
  const [showTaskAssignee, setShowTaskAssignee] = useState(0);
  const [selectedTaskAssigneesId, setSelectedTaskAssigneesId] = useState([]);
  const handleTaskAssigneeData = () => {
    setShowTaskAssignee(showTaskAssignee == 0 ? 1 : 0);
  };

  const [selectedTagId, setTagId] = useState(0);

  const dispatch = useDispatch();
  const selectedTask = useSelector((state) => state.customer.selectedTask);
  const project_id = useSelector((state) => state.customer.selectedProject);
  const defaultTopics = useSelector((state) => state.customer.defaultTopics);
  const mentionsList = useSelector((state) => state.customer.mentionList);

  const selectedDate =
    localStorage.getItem("calendarData") &&
    localStorage.getItem("calendarData") != "undefined" &&
    JSON.parse(localStorage.getItem("calendarData")).length
      ? JSON.parse(localStorage.getItem("calendarData"))[3].date
      : new Date();

  const contact_id = localStorage.getItem("contact_id");
  const userType = localStorage.getItem("user_type");
  const userRole = localStorage.getItem("contact_role");
  const client_id = localStorage.getItem("client_id");
  const projectId = localStorage.getItem("selectedOffice");
  const contact_role = localStorage.getItem("contact_role");
  let selectedTheme = localStorage.getItem("selectedTheme");

  const staff_id = localStorage.getItem("staff_id");
  const [toastHeader, setToastHeader] = useState("");
  const [confirmButtonText, setConfirmButtonText] = useState("");
  const [cancelButtonText, setCancelButtonText] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastClass, setToastClass] = useState("unsucess");
  const [toastMessage, setToastMessage] = useState("");
  const [attachmentId, setAttachmentId] = useState(0);
  const [requestTaskId, setRequestTaskId] = useState(0);
  const [toastToggle, setToastToggle] = useState(false);
  const [taskAssigneePills, setTaskAssigneePills] = useState([]);
  const [commentsList, setCommentsList] = useState([]);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [showMapModal, setShowMapModal] = useState(false);
  const [showMapInfoModal, setShowMapInfoModal] = useState(false);
  const [attachments, setAttachments] = useState({
    file_array: [],
    date: "",
    currentIndex: 0,
  });
  const [isTaskOwner, setIsTaskOwner] = useState(
    task.addedfrom > 0 &&
      task.addedfrom == (userType == "contact" ? contact_id : staff_id) &&
      task.is_added_from_contact == (userType == "contact" ? "1" : "0")
      ? true
      : false
  );
  const [taskUserAssignees, setTaskUserAssignees] = useState([]);
  const [officetitle, setofficetitle] = useState([]);

  const pathname = window.location.pathname;
  const toastData = useSelector((state) => state.customer.toastClose);
  const unreadCommentCount = useRef(task.unread_comment_count);
  const spaceState = useSelector((state) => state.customer.spaces);
  const [openSpaceListDropdown, setOpenSpaceListDropdown] = useState(false);
  const [selectedSpace, setSelectedSpace] = useState(task.rel_id);
  const [hideclass, sethideclass] = useState(true);
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString()
  );
  const [taskTime, settaskTime] = useState("");

  const [taskCardIconToggle, setTaskCardIconToggle] = useState(true);
  const handleCloseShareLink = () => setShowLink(false);
  const handleShowShareLink = () => setShowLink(true);
  const [showLink, setShowLink] = useState(false);
  const [todoTaskTitle, setTodoTaskTitle] = useState("")

  useEffect(() => {
    let tempDefaultIcons = [];
    if (isTaskPinned == true) {
      tempDefaultIcons.push("pin");
    }
    if (taskAssignee.length > 0) {
      tempDefaultIcons.push("peopels");
    }
    if (taskAssigneePills.length > 0) {
      tempDefaultIcons.push("pills");
    }
    if (taskPriority == 3) {
      tempDefaultIcons.push("priority");
    }
    if (task.comments_count > 0) {
      tempDefaultIcons.push("comment");
    }
    if (task.rating && task.rating > 0) {
      tempDefaultIcons.push("star");
    }
    if (pageName == "LiteVersion") {
      setShowDefaultIcons(tempDefaultIcons);
    } else {
      setShowDefaultIcons([
        "calendar",
        "assigneTo",
        "pin",
        "peopels",
        "pills",
        "priority",
        "cube",
        "map",
        "status",
        "comment",
        "star",
        "delete",
        "share",
        "call",
        "template",
      ]);
    }
  }, [JSON.stringify(task), taskAssigneeTeam, taskAssignee]);
  useEffect(() => {
    if (
      task.schedule_id > 0 ||
      task.rel_type == "equipment" ||
      task.is_requested == 1
    ) {
      if (taskStartDate) {
        settaskTime(moment(taskStartDate));
      } else if (task.startdate != null && task.startdate != "") {
        settaskTime(moment(task.startdate));
      } else if (task.duedate != null && task.duedate != "") {
        settaskTime(moment(task.duedate));
      }
    } else {
      if (taskDueDate) {
        settaskTime(moment(taskDueDate));
      } else if (task.startdate != null && task.startdate != "") {
        settaskTime(moment(task.startdate));
      } else if (task.duedate != null && task.duedate != "") {
        settaskTime(moment(task.duedate));
      }
    }
    // if (task.startdate != null  && task.startdate != "" ){
    //   settaskTime(moment(task.startdate).format("YYYY-MM-DD HH:mm:ss"));
    // } else if (task.duedate != null && task.duedate != "") {
    //   settaskTime(moment(task.duedate).format("YYYY-MM-DD HH:mm:ss"));
    // }
    // else {
    //   settaskTime(moment(task.dateadded).format("YYYY-MM-DD HH:mm:ss"));
    // }
    setCurrentTime(moment(new Date()));
  }, [JSON.stringify(task)]);

  useEffect(() => {
    if (taskAssigneeTeam.length > 0 || taskAssignee.length > 0) {
      setTaskAllAssignee(
        removeDuplicateObjects(taskAssignee.concat(taskAssigneeTeam))
      );
    }
  }, [taskAssigneeTeam, taskAssignee]);

  useEffect(() => {
    if (commanoffcanvasIsOpen) {
      setTaskCardIconToggle(true);
    } else {
      setTaskCardIconToggle(false);
    }
  }, [commanoffcanvasIsOpen]);

  useEffect(() => {
    if (defaultTopics && defaultTopics.length) {
      let requestAssigneeList = [];

      defaultTopics.forEach((topic) => {
        switch (topic.main_key) {
          case "my_task":
            topic.categories.forEach((my_task) => {
              if (!isNaN(parseInt(my_task.filter_key))) {
                requestAssigneeList.push({
                  main_key: topic.main_key,
                  type:
                    requestAssigneeList.findIndex(
                      (x) => x.type == topic.title
                    ) === -1
                      ? topic.title
                      : "",
                  name: my_task.title,
                  id: my_task.filter_key,
                  is_assigned: my_task.is_assigned || 0,
                  image: my_task.image || "",
                });
              }
            });
            break;
          case "provider_task":
            topic.categories.forEach((provider) => {
              requestAssigneeList.push({
                main_key: topic.main_key,
                type:
                  requestAssigneeList.findIndex(
                    (x) => x.type == topic.title
                  ) === -1
                    ? topic.title
                    : "",
                name: provider.title,
                id: provider.filter_key,
                is_assigned: provider.is_assigned || 0,
                image: provider.image || "",
              });
            });
            break;

          case "my_people":
            topic.categories.forEach((provider) => {
              requestAssigneeList.push({
                main_key: topic.main_key,
                type:
                  requestAssigneeList.findIndex(
                    (x) => x.type == topic.title
                  ) === -1
                    ? topic.title
                    : "",
                name: provider.title,
                id: provider.filter_key,
                is_assigned: provider.is_assigned || 0,
                image: provider.image || "",
              });
            });
            break;

          case "proposals":
            topic.categories.forEach((proposal) => {
              requestAssigneeList.push({
                main_key: topic.main_key,
                type:
                  requestAssigneeList.findIndex(
                    (x) => x.type == topic.title
                  ) === -1
                    ? topic.title
                    : "",
                name: proposal.title,
                id: proposal.filter_key,
                is_assigned: proposal.is_assigned || 0,
                image: proposal.image || "",
              });
            });
            break;

          case "user_journey":
            topic.categories.forEach((proposal) => {
              requestAssigneeList.push({
                main_key: topic.main_key,
                type:
                  requestAssigneeList.findIndex(
                    (x) => x.type == topic.title
                  ) === -1
                    ? topic.title
                    : "",
                name: proposal.title,
                id: proposal.filter_key,
                is_assigned: proposal.is_assigned || 0,
                image: proposal.image || "",
              });
            });
            break;

          case "myr_staff_task":
            topic.categories.forEach((proposal) => {
              requestAssigneeList.push({
                main_key: topic.main_key,
                type:
                  requestAssigneeList.findIndex(
                    (x) => x.type == topic.title
                  ) === -1
                    ? topic.title
                    : "",
                name: proposal.title,
                id: proposal.filter_key,
                is_assigned: proposal.is_assigned || 0,
                image: proposal.image || "",
              });
            });
            break;
          case "task_topic":
            if (
              localStorage.getItem("selectedVirtualSpace") &&
              Object.keys(localStorage.getItem("selectedVirtualSpace")).length
            ) {
              topic.categories.forEach((proposal) => {
                requestAssigneeList.push({
                  main_key: topic.main_key,
                  type:
                    requestAssigneeList.findIndex(
                      (x) => x.type == topic.title
                    ) === -1
                      ? topic.title
                      : "",
                  name: proposal.title,
                  id: proposal.filter_key,
                  is_assigned: proposal.is_assigned || 0,
                  image: proposal.image || "",
                });
              });
            }
            break;

          default:
            break;
        }
      });

      let assigneeTypes = getAssigneeTypesForRequest();
      if (task && task.is_requested == 0) {
        assigneeTypes.push("my_task");
      }
      setProviderCategorylist(
        requestAssigneeList.filter((x) => assigneeTypes.includes(x.main_key))
      );
    }
  }, [defaultTopics]);

  useEffect(() => {
    if (task.assignee_list_DEV) {
      setSelectedAssigneeNew(task.assignee_list_DEV);
    }
  }, [JSON.stringify(task.assignee_list_DEV)]);
  useEffect(() => {
    if (selectedAssigneeNew) {
      selectedAssigneeForTaskAndRequest(
        selectedAssigneeNew,
        setTaskAssigneePills,
        setTaskAssignee,
        defaultTopics
      );
    }
  }, [selectedAssigneeNew, defaultTopics]);

  useEffect(() => {
    if (pageName == "LiteVersion") {
      setisLiteVersion(true);
    } else {
      setShowDefaultIcons([
        "calendar",
        "assigneTo",
        "pin",
        "peopels",
        "pills",
        "priority",
        "cube",
        "map",
        "status",
        "comment",
        "star",
        "delete",
        "share",
        "call",
        "template",
      ]);
    }
  }, [pageName]);
  useEffect(() => {
    jquery(document).on("click", ".close-toast", function () {
      resetToast();
    });
  }, []);

  useEffect(() => {
    let officetitles = spaceState
      .concat(virtualSpaceList)
      .filter(
        (space) =>
          space.project_id == selectedSpace || space.id == selectedSpace
      );
    setofficetitle(officetitles);
  }, [task, selectedSpace]);

  useEffect(() => {
    setTaskStartDate(
      task.startdate && task.startdate != "0000-00-00 00:00:00"
        ? new Date(task.startdate)
        : null
    );

    setTaskDueDate(
      task.duedate && task.duedate != "0000-00-00 00:00:00"
        ? new Date(task.duedate)
        : null
    );
  }, [JSON.stringify(task)]);

  // Assignee new flow end-----------------------------------------------
  useEffect(() => {
    // if (task.is_requested == 0) {
    setTaskProviderTitle(task.provider_title);
    let task_assignee = [];
    let task_assignee_team = [];
    let task_assignee_ids = [];
    let task_assignee_pills = [];

    if (
      task.assignee_list &&
      task.assignee_list.length &&
      task.assignee_list.constructor === Array
    ) {
      task.assignee_list &&
        task.assignee_list.length &&
        task.assignee_list.map((ta) => {
          ta.categories &&
            ta.categories.map((taskAssign) => {
              // taskAssign.categories.map((taskAssignFilterKey) => {
              defaultTopics &&
                defaultTopics.forEach((topic) => {
                  if (topic.main_key == ta.main_key) {
                    topic.categories.forEach((provider) => {
                      if (taskAssign.filter_key == provider.filter_key) {
                        if (
                          ta.main_key == "equipments" ||
                          (task.is_requested == 1 &&
                            topic.main_key == "provider_task" &&
                            userType == "contact" &&
                            !CUSTOM_ASSIGNEE_CHANGE.includes(contact_role))
                        ) {
                          task_assignee_ids.push({
                            name: ta.main_key,
                          });
                        } else {
                          task_assignee_ids.push({
                            id: taskAssign.filter_key,
                            name: ta.main_key,
                          });
                        }
                      }
                    });
                  }
                });
              if (
                ta.main_key == "myr_staff_task" ||
                ta.main_key == "my_people" ||
                ta.main_key == "task_topic"
              ) {
                defaultTopics &&
                  defaultTopics.forEach((topic) => {
                    if (topic.main_key == ta.main_key) {
                      topic.categories.forEach((provider) => {
                        if (taskAssign.filter_key == provider.filter_key) {
                          task_assignee.push({
                            name: provider.title,
                            profile_image: provider.image,
                            id: provider.filter_key,
                            email: provider.email,
                            user_type: provider.user_type,
                          });
                          if (provider.filter_key < 0 && !isVirtualSpaces) {
                            task_assignee_team.push({
                              name: provider.title,
                              profile_image: provider.image,
                              id: provider.filter_key,
                              email: provider.email,
                              user_type: provider.user_type,
                            });
                          }
                          if (provider.filter_key > 0 && isVirtualSpaces) {
                            task_assignee_team.push({
                              name: provider.title,
                              profile_image: provider.image,
                              id: provider.filter_key,
                              email: provider.email,
                              user_type: provider.user_type,
                            });
                          }
                        }
                      });
                    }
                  });
              } else {
                defaultTopics &&
                  defaultTopics.forEach((topic) => {
                    if (topic.main_key == ta.main_key) {
                      topic.categories.forEach((provider) => {
                        if (taskAssign.filter_key == provider.filter_key) {
                          let subCategory = [];
                          if (
                            task.is_requested == 1 &&
                            topic.main_key == "provider_task"
                          ) {
                            // dispatch(getOperatorsByCategory(projectId, taskAssign.filter_key, 1, 1));
                            taskAssign.sub_category &&
                              taskAssign.sub_category.length > 0 &&
                              taskAssign.sub_category.forEach((dd) => {
                                // if (taskAssign.sub_category.includes(dd.staffid)) {
                                subCategory.push({ ...dd, id: dd.staffid });
                                //   task_assignee.push({
                                //     name: dd.fullname,
                                //     id: dd.staffid,
                                //     profile_image: dd.profile_image_url,
                                //     main_key:topic.main_key
                                //   });
                                // }
                              });
                          } else {
                            provider.sub_category &&
                              provider.sub_category.forEach((sc) => {
                                taskAssign.sub_category &&
                                  taskAssign.sub_category.length > 0 &&
                                  taskAssign.sub_category.forEach((dd) => {
                                    if (sc.id == dd) {
                                      subCategory.push(dd);
                                      task_assignee_pills.push({
                                        name: sc.name,
                                        id: sc.id,
                                        main_key: topic.main_key,
                                      });
                                    }
                                  });
                              });
                          }

                          task_assignee_ids.push({
                            id: provider.filter_key,
                            name: provider.title,
                            sub_category: subCategory,
                          });

                          // if (topic.main_key != "equipments" && !(task.is_requested == 1 && topic.main_key == "provider_task" && CUSTOM_CLIENT_IDS.includes(client_id) && CUSTOM_ADMIN_ROLES[client_id] && !CUSTOM_ADMIN_ROLES[client_id].includes(contact_role))) {
                          if (
                            topic.main_key != "equipments" ||
                            !(
                              task.is_requested == 1 &&
                              topic.main_key == "provider_task" &&
                              userType == "contact" &&
                              !CUSTOM_ASSIGNEE_CHANGE.includes(contact_role)
                            )
                          ) {
                            task_assignee_pills.push({
                              name: provider.title,
                              id: provider.filter_key,
                              main_key: topic.main_key,
                            });
                          }
                        }
                      });
                    }
                  });
                if (
                  (ta.main_key == "provider_task" ||
                    ta.main_key == "proposals") &&
                  userType == "staff"
                ) {
                  ta.categories.map((data) => {
                    task_assignee_pills.push({
                      name: "Myr.ai Support",
                      id: data.filter_key,
                      main_key: data.filter_key,
                    });
                  });
                }
              }
            });
        });
      // })
      // }
    }
  }, [openAssigneeDropdown, defaultTopics, JSON.stringify(task)]);

  useEffect(() => {
    jquery(document).mouseup(function (e) {
      var container = jquery(".chat-page");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        setshowOptions(0);
      }
    });
  });

  const calendarData = useSelector((state) => state.customer.calendarData);

  const changeTaskPriority = (e) => {
    dispatch(
      changePriority(
        task.id,
        project_id,
        taskPriority == 3 ? 3 : 2,
        task.provider
      )
    ).then(() => {
      setTaskPriority(taskPriority == 3 ? 2 : 3);
    });
  };

  const changeTaskStatus = (statusId) => {
    if (statusId != "task_convert_to_request") {
      setTaskStatus(statusId);
    }
    let index = taskList.findIndex((t) => t.id == task.id);
    let tempdata = taskList;
    if (index > -1) {
      if (statusId != "task_convert_to_request") {
        tempdata[index].status = statusId;
        tempdata[index].is_requested = 0;
      } else {
        tempdata[index].is_requested = 1;
      }
    }
    dispatch(updateProviderListTableData({['taskId'] : task.id, ["statusId"] : statusId}, "task", "status"));

    dispatch(
      updateTaskStatus(
        task.id,
        project_id,
        statusId,
        task.is_requested,
        task.provider,
        task.id,
        task.name
      )
    );
    dispatch(getTaskCount(client_id, contact_id, project_id, selectedDate));
  };

  const handleRequestAssignee = (assigneeDetail) => {
    let selectedAssignees = {
      main_key: assigneeDetail.main_key,
      filter_key: assigneeDetail.id,
      image: assigneeDetail.image || "",
      name: assigneeDetail.name || "",
      is_assigned: 1,
    };
    if (task.is_requested > 0) {
      setTaskAssignee([selectedAssignees]);
    }

    CustomerServices.setRequestTaskAssignee(task.id, [selectedAssignees]).then(
      (res) => {
        if (res.status && res.data && res.data.schedule_assignees) {
          // if (taskAssignee.length) {
          //   setTaskAssignee([selectedAssignees]);
          // } else {
          //   setTaskAssignee([]);
          // }

          dispatch(
            setTaskAssigneeNew(
              {
                filter_key: assigneeDetail.id,
                title: assigneeDetail.name,
                image: assigneeDetail.image || "",
              },
              assigneeDetail.main_key,
              assigneeDetail.id,
              task.id,
              task.is_requested > 0 ? 1 : 0
            )
          );

          // getTask(task.id, 1);
        }
      }
    );
  };

  const pinTask = () => {
    setTaskPinned(!taskPinned);
    setIsTaskPinned(!isTaskPinned);

    dispatch(
      updateTaskPin(
        task.id,
        project_id,
        isTaskPinned == 1 ? 1 : 0,
        task.provider
      )
    );
  };

  const deleteTask = (request_task_id = 0) => {
    CustomerServices.deleteTask(
      request_task_id != 0 ? request_task_id : task.id,
      project_id
    ).then((res) => {
      if (res.status === 1) {
        if (window.location.pathname === "/providerlist") {
          dispatch(updateProviderListTableData({['taskId'] : task.id}, "task", "delete"));
          dispatch(IsToastOpenOrClose(false));
        }else{
          let id = request_task_id != 0 ? request_task_id : task.id;
          dispatch(getTaskCount(client_id, contact_id, project_id, selectedDate));
          localStorage.setItem("is_task_delete", 1);
          dispatch(
            taskBulkAction(
              [request_task_id != 0 ? request_task_id : task.id],
              "delete_task",
              ""
            )
          );
          handler(0);
          dispatch(IsToastOpenOrClose(false));
          if (
            selectedTask &&
            (selectedTask.id || selectedTask.task_id) === task.id
          ) {
            dispatch(getSelectedTask(0, 0, null, null, 1));
          }
          let newList = taskandMessages.filter((task) => task.id != id);
          dispatch(setFetchedTaskandMessages(newList));
  
          let tempData = taskList.filter((task) => task.id != id);
          dispatch(updateTaskList(tempData));
  
          //Acknowledgement for deletion if required
        }
      }
    });
    if (window.location.pathname !== "/providerlist") {
      CloseCommentModal();
    }
  };

  const getTask = (id, is_requested = null, provider) => {
    if (!selectedTask || selectedTask.id !== id) {
      if (!task) {
        dispatch(
          getSelectedTask(project_id, id, is_requested, false, 0, provider)
        );
      } else {
        const data = { status: 1, data: task };
        // dispatch(
        //   getSelectedTask(project_id, id, is_requested, false, 0, provider)
        // );
        dispatch({
          type: GET_SELECTED_TASK,
          payload: { data },
        });
      }
    }
  };

  const handleUpdateDate = (startDate = "", dueDate = "") => {
    let start_date = startDate && startDate != "" ? startDate : "";
    let due_date = dueDate && dueDate != "" ? dueDate : "";
    let index = taskList.findIndex((t) => t.id == task.id);
    let tempdata = taskList;
    if (index > -1) {
      tempdata[index].startdate =
        start_date != ""
          ? moment(start_date).format("YYYY-MM-DD HH:mm:ss")
          : null;
      tempdata[index].duedate =
        due_date != "" ? moment(due_date).format("YYYY-MM-DD HH:mm:ss") : null;
    }
    dispatch(updateTaskList(tempdata));
    dispatch(setTaskData(project_id, task.id, task.name, start_date, due_date));
    dispatch(getTaskCount(client_id, contact_id, project_id, selectedDate));
  };
  const resetToast = () => {
    setShowToast(false);
    setToastHeader("");
    setToastClass("");
    setToastMessage("");
    setConfirmButtonText("");
    setCancelButtonText("");
    setRequestTaskId(0);
  };

  const handleBadgeCount = (task) => {
    var taskUnreadCmtCntEl = jquery(
      "span[data-unread-cmt-id='" + task.id + "']"
    );
    var taskUnreadCmtCnt = parseInt(taskUnreadCmtCntEl.text());
    if (taskUnreadCmtCnt > 0) {
      dispatch(getTopicTasks(project_id));
      dispatch(updateUnreadCommentCount(task.id, 0));
      dispatch(setTaskUnreadCmtCntAll(taskUnreadCmtCntAll - taskUnreadCmtCnt));
      var projUnreadCntEl = jquery(
        "span[data-proj-unread-cnt-id='" + project_id + "']"
      );
      var projUnreadCnt = parseInt(projUnreadCntEl.text());
      if (projUnreadCnt > 0) {
        projUnreadCntEl.text(projUnreadCnt - 1);
        if (projUnreadCnt == taskUnreadCmtCnt) {
          projUnreadCntEl.addClass("d-none");
        }
      }
    }
  };

  const is_requested = task.is_requested == "1" ? true : null;
  const RequestTaskYears =
    task.startdate && task.startdate != null
      ? getYearsBetweenDates(task.converted_date, task.startdate)
      : "";
  const RequestTaskdays =
    task.converted_date && task.converted_date != null
      ? getDaysBetweenDates(task.converted_date)
      : "";
  const RequestTaskHours =
    task.converted_date && task.converted_date != null
      ? getHoursBetweenDates(task.converted_date)
      : "";
  const RequestTaskMintues =
    task.converted_date && task.converted_date != null
      ? getMintuesBetweenDates(task.converted_date)
      : "";
  const handleWindowResize = () =>
    window.innerWidth <= 1500 ? setisMobileView(true) : setisMobileView(false);
  const [isMobileView, setisMobileView] = useState(
    window.innerWidth <= 1500 ? true : false
  );
  const LeftPanelBaseOnScreenSize = () => {};
  const getMentionlist = (id) => {
    dispatch(getMentionList(id, "task"));
  };

  const handleAssigneesList = (task) => {
    let taskAssignees = [];
    if (task && task.is_requested == 1) {
      taskAssignees =
        task.assignees_type && task.assignees_type.assginee_type
          ? [
              {
                id: task.assignees_type.assginee_id,
                name: task.assignees_type.assginee_type,
              },
            ]
          : [];
    } else {
      taskAssignees =
        task && task.assignee_list && task.assignee_list.categories
          ? task.assignee_list.categories.map((assign) => {
              return {
                name: task.assignee_list.main_key,
                id: assign.filter_key,
              };
            })
          : [];
    }

    setOpenAssigneeDropdown(true);
    // dispatch(updateTaskAssigneesList(taskAssignees, task.id));
  };

  const getTaskComments = async () => {
    showComments = true;
    setCommentsLoading(true);
    try {
      const comments = await DocumentsServices.getComments(
        task.id,
        "task",
        undefined,
        undefined,
        unreadCommentCount.current > 0 ? 1 : 0
      );
      if (comments.data) {
        if (unreadCommentCount.current > 0) {
          dispatch(
            setTaskUnreadCmtCntAll(
              Number(taskUnreadCmtCntAll) - Number(unreadCommentCount.current)
            )
          );
          if (
            localStorage.getItem("selectedVirtualSpace") &&
            JSON.parse(localStorage.getItem("selectedVirtualSpace"))
          ) {
            dispatch(
              setVirtualSpaceNotificationCount(
                project_id,
                unreadCommentCount.current,
                -1
              )
            );
          } else {
            dispatch(
              setSpaceNotificationCount(
                project_id,
                unreadCommentCount.current,
                -1
              )
            );
          }
          unreadCommentCount.current = 0;
        }
        setCommentsList(
          comments.data.map((comment) => {
            return {
              id: comment.id,
              content: comment.content,
              contact_id: comment.contact_id,
              staffid: comment.staffid,
              full_name: comment.contact_full_name || comment.staff_full_name,
              profile_url: comment.addedfrom_details.profile_url,
              attachments: comment.attachments,
              date: comment.time,
              dateadded: comment.dateadded,
              is_allowed_to_edit: comment.is_allowed_to_edit,
              parent_comment_details: comment.parent_comment_details
                ? comment.parent_comment_details
                : "",
              parent_comment_id: comment.parent_comment_id
                ? comment.parent_comment_id
                : 0,
            };
          })
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      setCommentsLoading(false);
    }
  };

  const handleImagePreview = (comment) => {
    setShowImagePreview(true);
    setAttachments({
      ...attachments,
      file_array: comment.attachments,
      currentIndex: 0,
      date: comment.dateadded,
    });
  };

  const closePreview = () => {
    setAttachments({ ...attachments, file_array: [], currentIndex: 0 });
    setShowImagePreview(false);
  };

  const changeAttachment = (direction) => {
    if (direction === "prev" && attachments.currentIndex > 0) {
      setAttachments({
        ...attachments,
        currentIndex: attachments.currentIndex--,
      });
    } else if (
      direction === "next" &&
      attachments.currentIndex <= attachments.current.file_array.length
    ) {
      setAttachments({
        ...attachments,
        currentIndex: attachments.currentIndex++,
      });
    }
  };

  const handleAddEditComment = async (content, files, id) => {
    setFileUploadLoader(true);
    try {
      const response = await DocumentsServices.addDocumentComment(
        "task",
        task.id,
        content,
        0,
        id,
        files,
        0,
        0,
        replyCommentData.id
      );
      if (id > 0) {
        const commentIndex = commentsList.findIndex((w) => w.id == id);
        setCommentsList(
          Object.values({
            ...commentsList,
            [commentIndex]: { ...commentsList[commentIndex], content },
          })
        );
      } else {
        const staffid = localStorage.getItem("staff_id");
        const profile_url = localStorage.getItem("contact_image");
        const full_name = localStorage.getItem("full_name");
        let attachments = [];

        if (files) {
          Array.from(files).map((file) => {
            attachments.push({
              file_url: URL.createObjectURL(file),
              file_name: file.name,
            });
          });
        }

        var addComments = {
          id: response.data.comment_id,
          content: content,
          contact_id: contact_id,
          staffid: staffid,
          full_name: full_name,
          profile_url: profile_url,
          is_allowed_to_edit: "1",
          attachments: attachments,
          date: response.data.time,
          dateadded: new Date(),
        };
        if (
          replyCommentData &&
          replyCommentData.content != "" &&
          replyCommentData.id != ""
        ) {
          addComments.parent_comment_details = {
            id: replyCommentData.id,
            content: replyCommentData.content,
            file_id: "0",
          };
          addComments.parent_comment_id = replyCommentData.id;
        } else if (
          replyCommentData.attachments &&
          replyCommentData.attachments[0].file_url != ""
        ) {
          let replyCommentDatas = replyCommentData.attachments[0];
          addComments.parent_comment_details = {
            content: "[task_attachment]",
            file_id: replyCommentDatas.file_id,
            file_name: replyCommentDatas.file_name,
            file_url: replyCommentDatas.file_url,
          };
          addComments.parent_comment_id = replyCommentData.id;
        }
        setCommentsList(commentsList.concat([addComments]));
        setUpdatedCommentCount(Number(commentsList.length + 1));
        setFileUploadLoader(false);
      }
    } catch (e) {
      setFileUploadLoader(false);
      console.error(e);
    }
  };

  const handleDeleteComment = (comment) => {
    try {
      if (pageName == "task" || pageName == "LiteVersion") {
        customerServices.removeComment(projectId, comment.id).then((res) => {
          if (res.status == 1) {
            setCommentsList(commentsList.filter((x) => x.id != comment.id));
            setUpdatedCommentCount(Number(commentsList.length - 1));
          }
        });
      } else {
        const response = DocumentsServices.removeComment(
          task.id,
          "task",
          comment.id
        );
        setCommentsList(commentsList.filter((x) => x.id != comment.id));
        setUpdatedCommentCount(Number(commentsList.length - 1));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const makeCall = (myrUserId, email, myrUserType) => {
    dispatch(
      initCall({
        userId: localStorage.getItem("orgChatUserId"),
        myrUserId: myrUserId,
        email: email,
        myrUserType: myrUserType == "contact" ? "client" : "staff",
        extraData: {
          taskId: task.id,
          taskName: task.name,
        },
      })
    );
  };
  const reqTimePill = (
    <>
      <div
        className={`title-fonts text-truncate pill-bottom-show color-white-60 pe-2 ${
          RequestTaskdays == "" &&
          RequestTaskHours == "" &&
          RequestTaskMintues == ""
            ? "d-none"
            : ""
        }`}
      >
        <span className="me-1 ps-0">
          <span
            className={` c-font f-13  ${
              parseInt(RequestTaskdays) >= 0 ? "color-green" : "color-red"
            }`}
          >
            {Math.abs(RequestTaskdays)}
          </span>
          <span className="color-white-60 c-font f-10">{_l("l_day")}</span>
        </span>
        <span className="me-1 ps-0">
          <span
            className={` c-font f-13  ${
              RequestTaskHours >= 0 ? "color-green" : "color-red"
            }`}
          >
            {Math.abs(RequestTaskHours)}
          </span>
          <span className="color-white-60 c-font f-10">{_l("l_hrs")}</span>
        </span>
        <span className="me-1 ps-0">
          <span
            className={` c-font f-13  ${
              RequestTaskHours >= 0 ? "color-green" : "color-red"
            }`}
          >
            {Math.abs(RequestTaskMintues)}
          </span>
          <span className="color-white-60 c-font f-10">{_l("l_min")}</span>
        </span>
      </div>
      <div
        className={`title-fonts text-truncate pill-bottom-show color-white-60 pe-2 ${
          RequestTaskdays == "" &&
          RequestTaskHours == "" &&
          RequestTaskMintues == "" &&
          task.startdate
            ? ""
            : "d-none"
        }`}
      >
        <span className="me-1">
          <span className={` c-font f-13 color-green`}>{_l("l_just_now")}</span>
        </span>
      </div>
    </>
  );

  let time = task && task.total_time && task.total_time.split(":");
  const ScheduleTaskHours =
    task.total_time && task.total_time != null ? parseInt(time[0].trim()) : "";
  const ScheduleTaskMintues =
    task.total_time && task.total_time != null ? parseInt(time[1].trim()) : "";
  const scheduleTimePill = (
    <>
      <div
        className={`title-fonts text-truncate pill-bottom-show color-white-60 pe-2 ${
          ScheduleTaskHours == "" && ScheduleTaskMintues == "" ? "d-none" : ""
        }`}
      >
        <span className="me-1">
          <span
            className={` c-font f-13  ${
              ScheduleTaskHours >= 0 ? "color-green" : "color-red"
            }`}
          >
            {ScheduleTaskHours}
          </span>
          <span className="color-white-60 c-font f-10">{_l("l_hrs")}</span>
        </span>
        <span className="me-1">
          <span
            className={` c-font f-13  ${
              ScheduleTaskHours >= 0 ? "color-green" : "color-red"
            }`}
          >
            {ScheduleTaskMintues}
          </span>
          <span className="color-white-60 c-font f-10">{_l("l_min")}</span>
        </span>
      </div>
      <div
        className={`title-fonts text-truncate pill-bottom-show color-white-60 pe-2 ${
          ScheduleTaskHours == "" &&
          ScheduleTaskMintues == "" &&
          task.total_time
            ? ""
            : "d-none"
        }`}
      ></div>
    </>
  );

  const isLoginUser = (uId, uEmail, uType) => {
    if (uType == userType && uEmail == localStorage.getItem("email")) {
      return true;
    } else {
      return false;
    }
  };

  const setAllUserAssignees = (newTaskAssignee) => {
    let virtualSpaces = localStorage.getItem("selectedVirtualSpace");
    let teamIds = [];
    let taskMembers = [];

    newTaskAssignee &&
      newTaskAssignee.forEach((member) => {
        if (!isLoginUser(member.id, member.email, member.user_type)) {
          if (member.id > 0 && virtualSpaces) {
            teamIds.push(member.id);
          } else if (member.id < 0 && !virtualSpaces) {
            teamIds.push(Math.abs(member.id).toString());
          } else {
            taskMembers.push(member);
          }
        }
      });

    if (teamIds.length) {
      try {
        if (userTeams) {
          userTeams.forEach((team) => {
            if (team.manager_id) {
              taskMembers.push({
                id: team.manager_id,
                name: team.manager_name,
                profile_image: team.manager_profile_image,
                email: team.manager_email,
                user_type: team.manager_user_type,
              });
            }
            if (teamIds.includes(team.id) && team.team.length) {
              team.team.forEach((team_member) => {
                taskMembers.push({
                  id: virtualSpaces
                    ? `-${team_member.member_id}`
                    : team_member.member_id,
                  name: `${team_member.firstname} ${team_member.lastname}`,
                  profile_image: team_member.profile_image,
                  email: team_member.email,
                  user_type: team_member.user_type,
                });
              });
            }
          });
        }
      } catch (e) {
        console.error(e);
      }
    }
    if (!isTaskOwner && task.addedfrom > 0) {
      taskMembers.push({
        name: task.creator_name,
        profile_image: task.creator_profile_image,
        id: task.addedfrom,
        email: task.creator_email,
        user_type: task.is_added_from_contact == "1" ? "contact" : "staff",
      });
    }
    setTaskUserAssignees(removeDuplicateObjects(taskMembers));
  };

  const convertDateintoTimezone = (date) => {
    var utc = new Date();
    var offset = utc.getTimezoneOffset();
    var MS_PER_MINUTE = 60000;
    var myStartDate = new Date(
      new Date(date).getTime() - offset * MS_PER_MINUTE
    );
    return myStartDate;
  };
  const handleTaskSpace = (space) => {
    customerServices
      .changeTaskspace(
        staff_id,
        contact_id,
        client_id,
        space.project_id,
        task.id
      )
      .then((res) => {
        if (res.status) {
          if (window.location.pathname === "/providerlist") {
            dispatch(updateProviderListTableData({...space, ['taskId'] : task.id}, "task", "spaces"))
          }
          dispatch(showMessage("sucess", _l("l_success"), _l(res.message)));
        } else {
          dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
        }
      });
  };

  const isTaskAssignmentDisabled = () => {
    let flag = false;
    if (contact_role == 3) {
      flag = true;
    } else if (
      task.is_allowed_to_delete > 0 &&
      task.is_allowed_to_edit > 0 &&
      task.is_one_time_benchmark > 0
    ) {
      return flag;
    } else if (
      task.schedule_id > 0 &&
      (userType !== "operator" || contact_role == "3")
    ) {
      flag = true;
    }
    return flag;
  };

  const createTodo = () => {
    try {
      customerServices.createToDos(
          [{title : todoTaskTitle}],
          [
              {
                 "main_key": "provider_page",
                 "categories": [
                    {
                      "filter_key": localStorage.getItem("currentCompanyID"),
                       "main_sub_key": "provider",
                       "sub_category": []
                    }
                 ]
              }
           ],
     
      ).then((res)=>{
          if (res && res.status) {
              let ids = res.data && res.data.task_ids ? res.data.task_ids : [];
              if (ids && ids.length > 0) {
                  customerServices.getTasklistForChat(undefined,ids,1).then((res)=>{
                      if (res && res.status) {
                        let oldTask = providerlistTableData.task
                        let finalArr = oldTask.concat(res.data ? res.data : [])
                        dispatch(getProviderListTableData({...providerlistTableData, task : finalArr}))
                        setTodoTaskTitle("")
                        showSuccess("l_task_created");
                      }else{
                        showError("l_something_went_wrong");
                      }
                  })
              }

          }else{
          }
      })
    } catch (error) {
      console.log(error);
    } 
  }

  return (
    <React.Fragment>
      {meetingModal ? (
        <MeetingProviderModal
          meetingProvider={meetingModal}
          HandleClose={() => {
            setMeetingModal(false);
          }}
          selectedTask={task}
          commentSection={1}
        />
      ) : (
        <></>
      )}
      {showToast && toastData ? (
        <Toasts
          delay={2000}
          handler={() => {
            setShowToast(false);
            dispatch(istodoTable(false));
          }}
          header={toastHeader}
          message={toastMessage}
          toastClass={toastClass}
          cancelButtonText={cancelButtonText}
          cancelButtonHandler={() => {
            setShowToast(false);
            dispatch(istodoTable(false));
            // setAttachmentId(0);
          }}
          confirmButtonText={confirmButtonText}
          confirmButtonHandler={() => {
            dispatch(istodoTable(false));
            deleteTask();
            if (requestTaskId && requestTaskId != 0) {
              const previousId = requestTaskId;
              setRequestTaskId(0);
              deleteTask(previousId);
              handler();
            }
            // setAttachmentId(0);
            setShowToast(false);
          }}
        />
      ) : (
        <></>
      )}
      {pageName === "provider-list" ? (
        <>
          <tr>
            <td className="pe-0">
              <a
                href="#/"
                className="name-with-progress max-width-200 d-block"
                onClick={() => {
                  dispatch(commanoffcanvasisOpen(true));
                  getTask(task.id, task.is_requested > 0, task.provider);
                  handler(
                    task.rel_id,
                    task.id,
                    true,
                    null,
                    task.request_task_id
                  );
                }}
              >
                <div className="d-flex align-items-center">
                  <div className="h35w35 comman-round-box with-bg rounded-circle bg-white-05">
                    <div
                      className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                      style={{
                        backgroundImage: `url('${task.creator_profile_image}')`,
                      }}
                    ></div>
                  </div>
                  <div className="ps-2 w100minus70 text-start flex-grow-1">
                    <div className="d-flex flex-column">
                      <div className="title-fonts text-truncate text-start max-width-200 fw-semibold">
                        {task.creator_name}
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </td>
            <td className={`${task.name == "" ? "" : "pe-0"}`}>
              <a
                href="#/"
                className="name-with-progress max-w300 d-block"
                onClick={() => {
                  if (task.name && task.name != "") {
                    
                    dispatch(commanoffcanvasisOpen(true));
                    getTask(task.id, task.is_requested > 0, task.provider);
                    handler(
                      task.rel_id,
                      task.id,
                      true,
                      null,
                      task.request_task_id
                    );
                  }}
                  }
              >
               {task.name && task.name != "" ?
                <div className="d-flex align-items-center">
                  <div className="ps-2 w100minus70 text-start flex-grow-1">
                    <div className="d-flex flex-column">
                      <div className="title-fonts text-truncate text-start max-width-200 fw-semibold">
                        {task.name}
                      </div>
                      <div class="c-font color-white-60 f-10 lh-1 location me-2 fw-normal">
                        <span class=" p-0 ">
                          {moment(
                            task.duedate
                              ? task.duedate
                              : task.startdate
                              ? task.startdate
                              : task.dateadded
                          ).format("DD/MM/YY HH:mm a")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                :
                  <>
                    <Form.Group className="col-xl-12 c-input-box position-relative">
                      <Form.Control
                        className="p-10"
                        placeholder={`${_l("l_task_name")}`}
                        type="text"
                        name="task_name"
                        value={todoTaskTitle}
                        onChange={(e) => {
                          setTodoTaskTitle(e.target.value);
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Enter" && todoTaskTitle.trim() !== "") {
                            createTodo()
                          }
                        }}
                        onBlur={()=>{
                          if (todoTaskTitle.trim() != "") {
                            createTodo()
                          }
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </>}
              </a>
            </td>
            <td className="title-fonts text-truncate text-start">
              <div class=" white-border-box p-0 d-flex list-toggle-view-box position-relative h32wauto">
                <a
                  href="#/"
                  class="custom-datepicker datepicker-input w-90 m-auto ms-1 c-font  "
                  data-bs-toggle="tooltip"
                  data-tip="21/05/2024"
                  data-effect="solid"
                  data-delay-show="1000"
                  data-class="tooltip-main"
                  currentitem="false"
                >
                  <div class="d-flex align-items-center">
                    <div class="h20w20">
                      <CalendarBlank
                        size={18}
                        weight="light"
                        className="c-icons"
                      />
                    </div>
                    <DatePicker
                      portalId="custom_datepicker"
                      calendarClassName="custom-datepicker"
                      timeCaption={getDatePickerTimeCaption()}
                      calendarStartDay={1}
                      locale={getDatePickerLocale()}
                      className="close-toast"
                      showTimeSelect
                      dateFormat="dd/MM/yyyy hh:mm a"
                      selected={
                        new Date(
                          task.duedate
                            ? task.duedate
                            : task.startdate
                            ? task.startdate
                            : task.dateadded
                        )
                      }
                      onChange={(date) => {
                        if (showStartDate) {
                          setTaskStartDate(date);
                          handleUpdateDate(date);
                        } else {
                          setTaskDueDate(date);
                          handleUpdateDate("", date);
                        }
                      }}
                    >
                      <div className="datepicker-label">
                        {_l("l_select_date_and_time")}
                      </div>
                    </DatePicker>
                  </div>
                </a>
              </div>
            </td>
            <td className="title-fonts text-truncate text-start">
              <div className="d-flex">
                <div className="comman_action_icon card-view-less-padding p-0 me-10px">
                  <UncontrolledDropdown className="dropdown">
                    <DropdownToggle
                      tag="a"
                      className={`justify-content-center align-items-center ${task.space_name ? "action_icon with_bg h32w32" : ""}`}
                      // className="action_icon with_bg h32w32 justify-content-center align-items-center"
                      type="button"
                      data-tip={`${
                        task.rel_id ? task.rel_id : _l("l_all_spaces")
                      }`}
                      data-effect="solid"
                      data-delay-show="1000"
                      data-class="tooltip-main"
                      id="SapceListMainDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-auto-close="true"
                      onClick={() => {
                        // setOpenSpaceListDropdown(true);
                      }}
                    >
                      <div
                        className={`on-hover-active-toggle-img text-on-hover-active-green ${
                          selectedSpace > 0 ? "active" : ""
                        }`}
                      >
                      {
                         !task.space_name ?
                          <div className="action_icon with_bg on-hover-active-toggle-img text-on-hover-active-green light-theme-img h32wauto  color-white-80 calendarpadding d-flex align-items-center text-truncate">
                            <MapPin size={18} className="c-icons" weight="light" />
                            <span className="c-font f-12 ps-2 text-uppercase no-display-in-responsive text-on-hover-active-green">
                              {_l("l_add_space")}
                            </span>
                          </div>
                          : <MapPin size={18} className="c-icons" weight="light" />
                      }
                        
                      </div>
                    </DropdownToggle>
                    <DropdownItem className="p-0 no-hover" tag="div">
                      <SpaceListDropdown
                        openSpaceListDropdown={openSpaceListDropdown}
                        SpaceList={spaceState}
                        setOpenSpaceListDropdown={setOpenSpaceListDropdown}
                        handler={handleTaskSpace}
                        selectedSpace={selectedSpace}
                        isFromTaskCard={true}
                        setSelectedSpace={setSelectedSpace}
                        unContolList={true}
                      />
                    </DropdownItem>
                  </UncontrolledDropdown>
                  <ReactTooltip />
                </div>
                {
                  task.space_name ? 
                  <div className="position-relative d-flex assignee-list-wrapper card-view-less-padding ">
                    <div className="dropdown-center multi_inner_wrapper with-min-width ps-0 d-flex  with_minus ">
                      <div className="pe-10px">
                        <div className="badge rounded-pill text-nowrap list-toggle-view-none">
                          <span className=" p-0 ">
                            {task.space_name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  : <></>
                }
              </div>
            </td>
            <td className="title-fonts text-truncate text-start">

              <div className="d-flex align-items-center">
              {taskAssigneePills.length == 0 && taskAssignee == 0 ? (
                <>
                </>
              ) : (
                <>
                  {taskAssigneePills.length > 0 ||
                  taskAllAssignee.length > 0 ? (
                    <div
                      className={`position-relative d-flex assignee-list-wrapper card-view-less-padding`}
                    >
                      <div
                        className={`dropdown-center multi_inner_wrapper with-min-width ps-0 d-flex  with_minus ${
                          taskAssigneePills && taskAssigneePills.length >= 1
                            ? ""
                            : "d-none"
                        }`}
                      >
                        {taskAssigneePills && taskAssigneePills.length >= 1 ? (
                          taskAssigneePills.map((assignee, index) => {
                            if (index < 1)
                              return (
                                <div className="pe-10px">
                                  <div
                                    className="badge rounded-pill text-nowrap list-toggle-view-none"
                                    key={index}
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    data-tip={`${_l(assignee.name)}`}
                                    data-effect="solid"
                                    data-delay-show="1000"
                                    data-class="tooltip-main"
                                  >
                                    <span className={` p-0 `}>
                                      {assignee.main_key == "my_task"
                                        ? "#"
                                        : ""}
                                      {_l(assignee.name)}
                                    </span>
                                    <ReactTooltip />
                                  </div>
                                </div>
                              );
                          })
                        ) : (
                          <></>
                        )}

                        {taskAssigneePills && taskAssigneePills.length > 1 ? (
                          <div className="pe-10px">
                            <a
                              href="#/"
                              className="badge rounded-pill text-nowrap list-toggle-view-none"
                              // id="TaskAssigneeDropdown"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              data-tip={`${_l("l_view_all")}`}
                              data-effect="solid"
                              data-delay-show="1000"
                              data-class="tooltip-main"
                            >
                              <div className="unread_count m-auto c-font f-10">
                                +{taskAssigneePills.length - 1}
                              </div>
                            </a>
                            <GeneralListing
                              handler={() => {}}
                              list={
                                taskAssigneePills &&
                                taskAssigneePills.length &&
                                taskAssigneePills.map((item, index) => {
                                  return {
                                    name: item.name,
                                    id: index,
                                  };
                                })
                              }
                              isHash={true}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div
                        className={`dropdown-center multi_inner_wrapper with-min-width d-flex ps-0 pe-10px with_minus before-dnone ${
                          taskAllAssignee && taskAllAssignee.length >= 1
                            ? ""
                            : "d-none"
                        }`}
                      >
                        {taskAllAssignee && taskAllAssignee.length >= 1 ? (
                          taskAllAssignee.map((assignee, index) => {
                            if (index < 1)
                              return (
                                <a
                                  href="#/"
                                  data-bs-toggle="tooltip"
                                  className="comman-image-box multi-wrapper-border h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                                  data-tip={assignee.name || assignee.full_name}
                                  data-place="right"
                                  data-effect="solid"
                                  data-delay-show="1000"
                                  data-class="tooltip-main"
                                  key={index}
                                >
                                  <div
                                    className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                    style={{
                                      backgroundImage: `url('${
                                        assignee.user_image ||
                                        assignee.profile_image
                                      }')`,
                                    }}
                                  ></div>
                                  <ReactTooltip />
                                </a>
                              );
                          })
                        ) : (
                          <></>
                        )}
                        <UncontrolledDropdown>
                          <DropdownToggle tag="div">
                            {taskAllAssignee && taskAllAssignee.length > 1 ? (
                              <a
                                href="#/"
                                className="comman-image-box h32w32 with_overlay d-flex rounded-circle dropdown-center"
                                // id="TaskAssigneeDropdown"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                data-tip={`${_l("l_view_all")}`}
                                data-effect="solid"
                                data-delay-show="1000"
                                data-class="tooltip-main"
                              >
                                <div className="unread_count m-auto c-font f-12">
                                  +{taskAllAssignee.length - 1}
                                </div>
                              </a>
                            ) : (
                              <></>
                            )}
                            {taskAllAssignee && taskAllAssignee.length ? (
                              <GeneralListing
                                unControlList={true}
                                handler={() => {}}
                                list={
                                  taskAllAssignee &&
                                  taskAllAssignee.length &&
                                  taskAllAssignee
                                    .sort((a, b) => {
                                      if (a.name < b.name) return -1;
                                      if (a.name > b.name) return 1;
                                    })
                                    .map((item, index) => {
                                      return {
                                        name: item.full_name || item.name,
                                        image:
                                          item.user_image || item.profile_image,
                                        id: item.id,
                                      };
                                    })
                                }
                                customSort={true}
                              />
                            ) : (
                              <></>
                            )}
                          </DropdownToggle>
                        </UncontrolledDropdown>
                      </div>
                      <ReactTooltip />
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
              <div
                className={`comman_action_icon card-view-none me-10px  ${
                  isTaskAssignmentDisabled() ? "for-disabled" : ""
                }`}
              >
                <div className="d-flex align-items-center">
                  <div className="dropup dropup-center">
                    <UncontrolledDropdown
                      setActiveFromChild={true}
                      isOpen={openAssigneeDropdown}
                      direction="end"
                      className="w-200"
                      data-tip={`${_l("l_add_assignee")}`}
                      data-effect="solid"
                      data-delay-show="1000"
                      data-class="tooltip-main"
                      data-place="top"
                    >
                      <DropdownToggle
                        id={`AddAssigneeMainDropdownNew_${task.id}`}
                        className="border-0  p-0 d-flex align-items-center"
                        onClick={() =>
                          setOpenAssigneeDropdown(!openAssigneeDropdown)
                        }
                      >
                        <div
                          className="action_icon with_bg on-hover-active-toggle-img text-on-hover-active-green light-theme-img h32wauto  color-white-80 calendarpadding d-flex align-items-center text-truncate"
                          id={`AssigneeMainDropdownNew_${task.id}`}
                        >
                          <UserCircleGear
                            size={18}
                            weight="light"
                            className="c-icons"
                          />
                         {taskAssigneePills.length == 0 && taskAssignee == 0 ? <span className="c-font f-12 ps-2 text-uppercase no-display-in-responsive text-on-hover-active-green">
                            {_l("l_assign_to")}
                          </span> : <></>} 
                        </div>
                      </DropdownToggle>
                      {/* { openAssigneeDropdown ? */}
                      {openAssigneeDropdown == true ? (
                        <AddAssigneeMainDropdownNew
                          key={task.id}
                          projectId={project_id}
                          taskId={task.id}
                          openAssigneeDropdown={openAssigneeDropdown}
                          setOpenAssigneeDropdown={setOpenAssigneeDropdown}
                          fromTaskcard="1"
                          selectedTagId={selectedTagId}
                          pageName="task"
                          selectedId={selectedTaskAssigneesId}
                          isRequest={task.is_requested == 1}
                          saveBtnToggle={true}
                          selectedAssignee={selectedAssigneeNew}
                          setSelectedAssignee={setSelectedAssigneeNew}
                        />
                      ) : (
                        <></>
                      )}
                    </UncontrolledDropdown>
                  </div>
                </div>
              </div>
              </div>
            </td>
            <td className="comman_action_icon">
              <div class="d-flex align-items-center ">
              <div className="dropdown ">
                  <div
                    className={`white-border-box color-white-60 action_icon h32wauto calendarpadding with_bg dropdown-center ms-10px`}
                    data-tip={_l("l_task_status")}
                    data-effect="solid"
                    data-delay-show='1000'
                    data-class="tooltip-main"
                    id="TaskListStatusDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <a href="#/" className="d-flex align-items-center dropdown dropdown-center">
                      <span
                        class={`c-dots c-7 rounded-circle me-2 ${task.is_requested == 1
                            ? "bg-warning"
                            : task.status == 1
                              ? "in-progress-white"
                              : task.status == 4
                                ? "in-progress-blue"
                                : "done"
                          }`}
                      ></span>
                      <span class="c-font f-12 text-truncate text-uppercase">
                        {_l(
                          task.is_requested == 1
                            ? "l_request"
                            : task.status == 1
                              ? "l_not_started"
                              : task.status == 4
                                ? "l_inprogress"
                                : "l_done"
                        )}
                      </span> 
                      <TaskListStatusDropdown
                        setTaskStatus={changeTaskStatus}
                        is_task={1}
                      />
                    </a>
                  </div>
              </div>
              </div>
            </td>

            <td className="p-2">
              <div className="d-flex  align-items-center justify-content-end ">
                <div className="before-dnone comman_action_icon with_separator_10 pe-0">
                  <a
                    href="#/"
                    className="action_icon with_bg h32w32 light-theme-img close-toast"
                    data-tip={
                      isTaskPinned && isTaskPinned == true
                        ? _l("l_unpin")
                        : _l("l_pin")
                    }
                    data-effect="solid"
                    data-delay-show="1000"
                    data-class="tooltip-main"
                  >
                    {isTaskPinned && isTaskPinned == true ? (
                      <div
                        className="m-auto "
                        onClick={() => {
                          pinTask();
                        }}
                      >
                        <PushPin
                          size={18}
                          weight="fill"
                          className="Push-Pin-fill"
                        />
                      </div>
                    ) : (
                      <div
                        className="m-auto"
                        onClick={() => {
                          pinTask();
                        }}
                      >
                        <PushPin size={18} weight="light" className="c-icons" />
                      </div>
                    )}
                  </a>
                  <ReactTooltip />
                </div>
                {/* comment icon start */}
                <div
                  className={`${
                    isLiteVersion ? " " : ""
                  } dropdown-center dropdown with_separator_10 text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding close-toast`}
                >
                  <UncontrolledDropdown className="w-100">
                    <DropdownToggle
                      data-toggle="dropdown"
                      tag="span"
                      className="comman_action_icon w-100 d-flex close-toast border-0"
                    >
                      <a
                        href="#/"
                        data-tip={_l("l_comments")}
                        data-effect="solid"
                        data-delay-show="1000"
                        data-class="tooltip-main"
                        onClick={(e) => {
                          setOpenCommentTab(true);
                          getMentionlist(task.id);
                          getTaskComments();
                        }}
                      >
                        <div className="d-flex align-items-center w-100">
                          <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                            <ChatCircle
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                            <span
                              className={`rounded-pill badge-custom p-0 ${
                                unreadCommentCount.current == 0 ||
                                Number(updatedCommentCount)
                                  ? "d-none"
                                  : ""
                              }`}
                              data-unread-cmt-id={task.id}
                            >
                              {unreadCommentCount.current}
                            </span>
                          </div>
                          {/* {isLiteVersion ? (<></>) : ( */}
                          <span
                            className="c-font f-12 d-flex justify-content-center flex-grow-1 number-counter-toggle"
                            data-cmt-cnt-id={task.id}
                          >
                            {Number(updatedCommentCount) > 0
                              ? Number(updatedCommentCount)
                              : 0}
                          </span>
                          {/* )} */}
                        </div>
                      </a>
                    </DropdownToggle>
                    <DropdownItem toggle={false} className="p-0">
                      {
                        <CommentMinified
                          comments={commentsList}
                          disabled={
                            task &&
                            parseInt(task.request_task_id) > 0 &&
                            task.is_requested == 1
                              ? true
                              : false
                          }
                          handleAddComment={handleAddEditComment}
                          deleteComment={handleDeleteComment}
                          id={task.id}
                          task={task}
                          loading={commentsLoading}
                          mentionList={mentionsList.map((name) => {
                            let id =
                              name.is_team && name.is_team == 1
                                ? "-" + name.id
                                : name.id;
                            return {
                              display: name.name,
                              id: id,
                              image: name.profile_image || "",
                              isteam: name.is_team ? 1 : 0,
                            };
                          })}
                          handleImagePreview={handleImagePreview}
                          unContolList={true}
                          totalUnreadCommentCount={task.unread_comment_count}
                          showComments={showComments}
                          setReplyCommentData={setReplyCommentData}
                          fileLoader={fileUploadLoader}
                        />
                      }
                    </DropdownItem>
                  </UncontrolledDropdown>
                </div>
                {/* comment icon end */}
                <div className="comman_action_icon card-view-less-padding p-0 d-flex align-items-center justify-content-center me-10px">
                  <div
                    className={`priority-checkbox span-13 white-border-box action_icon with_bg h32w32 close-toast`}
                    data-tip={_l("l_high_priority")}
                    data-effect="solid"
                    data-delay-show="1000"
                    data-class="tooltip-main"
                  >
                    <input
                      type="checkbox"
                      id="priority"
                      className="absolute-input"
                      checked={task.priority == 3 ? true : false}
                      onChange={(e) => {
                        changeTaskPriority(e);
                      }}
                    />
                    <div className="check_box">
                      <HighPriorityActiveIcon className="HW18 check_span_img active" />
                      <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                    </div>
                  </div>
                  <ReactTooltip />
                </div>
                <div
                  className={`align-items-center d-flex  ${
                    globalView == "kanbanView"
                      ? "flex-wrap kanbanbottomspacing"
                      : ""
                  } ${
                    commanoffcanvasIsOpen
                      ? "with-abs-position-bg-taskcard tabledots"
                      : ""
                  }`}
                >
                  {showDefaultIcons.includes("eye") ? (
                    <div className="comman_action_icon card-view-less-padding p-0 me-2"></div>
                  ) : (
                    <></>
                  )}
                  {(pathname == "/documents" &&
                    task &&
                    task.provider == "google_drive") ||
                  (task && task.provider == "one_drive") ? (
                    <>
                      <div className="comman_action_icon with_separator_10 card-view-less-padding">
                        <div className="c-font f-12 fw-semibold text-nowrap ">
                          {task.size
                            ? Math.floor(parseInt(task.size) / 1024) + " KB"
                            : "No Size"}
                        </div>
                      </div>
                      <div className="dropdown-center dropdown with_separator_10 d-flex align-items-center card-view-less-padding before-block min-width-auto before-dnone">
                        <div className="comman_action_icon w-100 d-flex">
                          <div className="action_icon with_bg h32w32 on-hover-active-toggle-img position-relative">
                            <a>
                              <div
                                className="action_icon with_bg on-hover-active-toggle-img position-relative"
                                onClick={() => {
                                  window.open(task.webViewLink, "_blank");
                                }}
                              >
                                <Eye
                                  size={18}
                                  weight="light"
                                  className="c-icons"
                                />
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="dropdown-center dropdown with_separator_10 d-flex align-items-center card-view-less-padding before-block min-width-auto before-dnone">
                        <a
                          className="comman_action_icon w-100 d-flex"
                          href={task.downloadUrl}
                          data-tip={_l("l_download")}
                          data-effect="solid"
                          data-delay-show="1000"
                          data-class="tooltip-main"
                        >
                          {task.fileExtension !== "form" && (
                            <div className="action_icon with_bg h32w32 on-hover-active-toggle-img position-relative">
                              <DownloadSimple
                                size={18}
                                weight="light"
                                className="c-icons"
                              />
                            </div>
                          )}
                        </a>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {/* call icon start */}
                  {selectedTaskIdforIconToggle == task.id ? (
                    <UncontrolledDropdown
                      direction={"down"}
                      className="comman_action_icon me-10px d-none"
                    >
                      <DropdownToggle tag="div" className="">
                        <a
                          href="#/"
                          className="dropdown-item p-0"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                          aria-expanded="false"
                          data-popper-placement="left-start"
                        >
                          <div className="action_icon with_bg h32w32">
                            <PhoneCall
                              size={18}
                              className="c-icons"
                              weight="light"
                            />
                          </div>
                        </a>
                        <UncontrolledGeneralListing
                          list={taskUserAssignees.map((item, index) => {
                            return {
                              name: item.name,
                              image: item.profile_image,
                              id: index,
                              isOnline: 0,
                              userId: item.id,
                              email: item.email,
                              userType: item.user_type,
                            };
                          })}
                          makeCall={makeCall}
                          forCall={true}
                        />
                      </DropdownToggle>
                      <ReactTooltip />
                    </UncontrolledDropdown>
                  ) : (
                    <></>
                  )}
                  {/* call icon end */}

                  {/* share Icon start */}
                  {selectedTaskIdforIconToggle == task.id ? (
                    <div>
                      <div
                        className="comman_action_icon card-view-less-padding p-0 me-0 dropdown"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                        data-popper-placement="left-start"
                      >
                        <a
                          href="#/"
                          className={` white-border-box action_icon with_bg h32w32`}
                          data-tip={_l("l_share")}
                          data-effect="solid"
                          data-delay-show="1000"
                          data-class="tooltip-main"
                          onClick={() => {
                            setTask_type(
                              task.is_requested && task.is_requested == 1
                                ? "request-task"
                                : "task"
                            );
                          }}
                        >
                          <div className="">
                            <ShareNetworkIcon
                              size={14}
                              className="c-icons with-fill"
                              weight="light"
                            />
                          </div>
                        </a>
                        <ActionDropdown
                          actions={[
                            {
                              actionName: _l("l_copy_link"),
                              actionHandler: () => {
                                navigator.clipboard.writeText(
                                  `${
                                    process.env.REACT_APP_MYR_WEB_URL
                                  }/${task_type}/${task ? task.hash : ""}`
                                );
                                dispatch(
                                  showMessage(
                                    "sucess",
                                    _l("l_success"),
                                    _l("l_link_copied")
                                  )
                                );
                                HideDropdown();
                              },
                              actionIcon: "CopyIcon",
                            },
                            {
                              actionName: _l("l_send_to_email"),
                              actionHandler: () => {
                                setShowLink(true);
                                HideDropdown();
                              },
                              actionIcon: "EnvelopeSimpleIcon",
                            },
                            {
                              actionName: _l("l_open_link"),
                              actionHandler: () => {
                                window.open(
                                  `${
                                    process.env.REACT_APP_MYR_WEB_URL
                                  }/${task_type}/${task ? task.hash : ""}`
                                );
                                HideDropdown();
                              },
                              actionIcon: "LinkSimpleIcon",
                            },
                          ]}
                        />
                        <TaskShareLinkModal
                          show={showLink}
                          handleClose={handleCloseShareLink}
                          handleShow={handleShowShareLink}
                          link={`${process.env.REACT_APP_MYR_WEB_URL}/${task_type}/${task.hash}`}
                          data={{ id: task.id, contacts: contacts }}
                          docType={task_type}
                        />
                      </div>
                      <ReactTooltip />
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* share Icon end */}

                  {/* task rating start */}
                  <>
                    {/* Task temporary implementation of average rating */}
                    {selectedTaskIdforIconToggle == task.id ? (
                      <div className="  with_separator_10 text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding ">
                        <>
                          {["bottom"].map((placement) => (
                            <OverlayTrigger
                              trigger="hover"
                              key={placement}
                              placement={placement}
                              overlay={
                                <Popover
                                  className="d-none"
                                  id={`popover-positioned-${placement}`}
                                >
                                  <Popover.Body className="w-250 p-0">
                                    <div className="c-font f-12 color-white-60 p-10 pb-0">
                                      header
                                    </div>
                                    <ul className="notification-listing">
                                      <li className="hr_sepretr dropdown-item">
                                        <div className="d-flex align-items-center text-truncate">
                                          <div className="c-list-icon ">
                                            <AddTaskIcon className="HW18" />
                                          </div>
                                          <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                            <div className="c-list-detail text-truncate">
                                              {_l("l_tasks")}
                                            </div>
                                            <div className="unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex">
                                              0
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li className="hr_sepretr dropdown-item">
                                        <div className="d-flex align-items-center text-truncate">
                                          <div className="c-list-icon ">
                                            <AddTaskIcon className="HW18" />
                                          </div>
                                          <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                            <div className="c-list-detail text-truncate">
                                              {_l("l_tasks")}
                                            </div>
                                            <div className="unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex">
                                              0
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li className="hr_sepretr dropdown-item">
                                        <div className="d-flex align-items-center text-truncate">
                                          <div className="c-list-icon ">
                                            <AddTaskIcon className="HW18" />
                                          </div>
                                          <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                            <div className="c-list-detail text-truncate">
                                              {_l("l_tasks")}
                                            </div>
                                            <div className="unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex">
                                              0
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li className="hr_sepretr dropdown-item">
                                        <div className="d-flex align-items-center text-truncate">
                                          <div className="c-list-icon ">
                                            <AddTaskIcon className="HW18" />
                                          </div>
                                          <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                            <div className="c-list-detail text-truncate">
                                              {_l("l_tasks")}
                                            </div>
                                            <div className="unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex">
                                              0
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li className="hr_sepretr dropdown-item">
                                        <div className="d-flex align-items-center text-truncate">
                                          <div className="c-list-icon ">
                                            <AddTaskIcon className="HW18" />
                                          </div>
                                          <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                            <div className="c-list-detail text-truncate">
                                              {_l("l_tasks")}
                                            </div>
                                            <div className="unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex">
                                              0
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <a
                                href="#/"
                                className="comman_action_icon w_100px d-flex align-items-center close-toast border-0"
                                data-tip={_l("l_star_rating")}
                                data-effect="solid"
                                data-delay-show="1000"
                                data-class="tooltip-main"
                              >
                                <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                                  {task.average_rating &&
                                  !isNaN(Number(task.average_rating)) &&
                                  Number(task.average_rating) ? (
                                    <Star
                                      size={18}
                                      className="c-icons"
                                      weight="fill"
                                      color="#00ff6a"
                                    />
                                  ) : (
                                    <Star
                                      size={18}
                                      className="c-icons"
                                      weight="light"
                                    />
                                  )}
                                </div>
                                <span className="c-font f-12 d-flex justify-content-center flex-grow-1 title-fonts ps-0">
                                  {task.average_rating &&
                                  !isNaN(Number(task.average_rating))
                                    ? client_id == 1080
                                      ? `${Number(task.average_rating).toFixed(
                                          1
                                        )} / 20`
                                      : Number(task.average_rating).toFixed(1)
                                    : 0}
                                </span>
                              </a>
                            </OverlayTrigger>
                          ))}
                        </>
                        <ReactTooltip />
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                  {/* task rating end */}

                  {/* push pin icon start  */}

                  {/* push pin icon end  */}

                  {/* Delete Icon start */}
                  {selectedTaskIdforIconToggle == task.id && parseInt(task.is_allowed_to_delete) == 1 ? (
                    <div className="comman_action_icon card-view-less-padding p-0 me-10px">
                      <a
                        href="#/"
                        className={`priority-checkbox span-13 white-border-box action_icon with_bg h32w32`}
                        data-tip={_l("l_delete")} 
                        data-effect="solid"
                        data-delay-show="1000"
                        data-class="tooltip-main"
                        onClick={() => {
                          resetToast();
                          dispatch(IsToastOpenOrClose(true));
                          if (window.location.pathname == "/providerlist") {
                            dispatch(istodoTable(true));
                          }
                          // setToastToggle(false)

                          // setShowToast(false);

                          let deleteConfirmationText =
                            task && task.request_task_id > 0
                              ? task.is_requested == 1
                                ? _l("l_and_converted_task")
                                : _l("l_and_request_task")
                              : "";
                          setToastHeader(_l("l_are_you_sure"));
                          setToastClass("toast-with-btn");
                          setToastMessage(
                            _l("l_you_want_tot_delete_this_task") +
                              deleteConfirmationText +
                              " ?"
                          );
                          setConfirmButtonText(_l("l_delete"));
                          setCancelButtonText(_l("l_cancel"));
                          setShowToast(true);
                          setRequestTaskId(
                            task &&
                              task.request_task_id &&
                              task.request_task_id != 0
                              ? task.request_task_id
                              : 0
                          );
                        }}
                      >
                        <div className="">
                          <Trash size={18} className="c-icons" weight="light" />
                        </div>
                      </a>
                      <ReactTooltip />
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* Delete Icon end */}
                </div>
                <div
                  className={`comman_action_icon  ${
                    globalView == "kanbanView"
                      ? "absoluteDotsTask"
                      : "d-flex align-items-center justify-content-center"
                  }`}
                >
                  <a
                    href="#/"
                    className={`action_icon h32w32 with_bg with-border-active`}
                    onClick={() => {
                      handleToggleIcons(
                        isTaskPinned,
                        taskAssignee,
                        taskAssigneePills,
                        taskPriority,
                        task.comments_count,
                        task.rating,
                        setTaskCardIconToggle,
                        taskCardIconToggle
                      );
                      if (selectedTaskIdforIconToggle == task.id) {
                        setSelectedTaskIdforIconToggle(0);
                      } else {
                        setSelectedTaskIdforIconToggle(task.id);
                      }
                    }}
                  >
                    <DotsNine size={18} weight="light" className="c-icons" />
                  </a>
                </div>
              </div>
            </td>
          </tr>
        </>
      ) : !taskHide && pageName != "ChatAi" ? (
        <div
          className={`task-list-card list-view ${
            selectedTaskIdforIconToggle == task.id ? "active" : ""
          } ${active ? "active" : ""} ${
            isLiteVersion ? "taskcardliteversion overflow-visible" : ""
          }`}
          id={`task_${
            task &&
            (typeof task.id == "string"
              ? task.id.replace(new RegExp("=", "g"), "")
              : task.id)
          }`}
        >
          {isLiteVersion ? (
            <div
              className={`text-center c-font color-white-80 f-10 p-2 liteversion-date-box comman-white-box base-body-bg min-width-60 fw-semibold d-none ${
                commanoffcanvasIsOpen ? "d-none" : ""
              }`}
            >
              {taskFilters.length > 0 ? (
                task.startdate ? (
                  <>
                    <div>
                      {moment(convertDateintoTimezone(task.startdate)).format(
                        "DD/MM/yy hh:mm a"
                      )}
                    </div>
                    <div>
                      {moment(convertDateintoTimezone(task.startdate)).format(
                        "  hh:mm a"
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      {moment(convertDateintoTimezone(task.dateadded)).format(
                        "DD/MM/yy"
                      )}
                    </div>
                    <div>
                      {moment(convertDateintoTimezone(task.dateadded)).format(
                        "hh:mm a"
                      )}
                    </div>
                  </>
                )
              ) : task.startdate ? (
                moment(convertDateintoTimezone(task.startdate)).format(
                  "hh:mm a"
                )
              ) : (
                moment(convertDateintoTimezone(task.dateadded)).format(
                  "hh:mm a"
                )
              )}
            </div>
          ) : (
            <></>
          )}
          {isTaskPinned ? (
            <div className="task-pin-status rounded-circle h25w25 bg-white-05 d-none">
              <div className="d-flex w-100 h-100">
                <Image size={16} weight="light" className="c-icons" />
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="task-list-wrapper">
            <div className="d-flex w-100 align-items-start">
              <div className="d-flex task-list-detail">
                <div
                  className={`task-left flex-grow-1 width100minus160 ${
                    globalView == "kanbanView" ? "w-100" : ""
                  }`}
                >
                  <div className="d-flex align-items-center w-100 task-left-part-toggle text-truncate">
                    {(pathname == "/documents" &&
                      task &&
                      task.provider == "google_drive") ||
                    (task && task.provider == "one_drive") ? (
                      <></>
                    ) : (
                      <div className="task-creator-img">
                        <div
                          className={`h20w20 d-flex align-items-center me-10px ${
                            globalView == "kanbanView" ? "mb-10" : ""
                          }`}
                        >
                          <Form.Check
                            type="checkbox"
                            className="m-auto"
                            checked={
                              checkedTask && checkedTask.length
                                ? checkedTask.filter((id) => task.id == id)
                                    .length
                                  ? true
                                  : false
                                : false
                            }
                            onChange={(e) => {
                              taskCheckUncheck(task.id);
                            }}
                            disabled={task.is_allowed_to_edit != 1}
                          />
                        </div>
                      </div>
                    )}
                    <a
                      href="#/"
                      id={`task-${task.id}`}
                      className={`task-top-part text-truncate flex-grow-1 d-flex ${
                        task.request_task_id == 0
                          ? "main-calc-width-task"
                          : "main-calc-width"
                      }`}
                      onClick={() => {
                        if (driveDocument) {
                          handler();
                          return;
                        }
                        setShowToast(false);
                        dispatch(toastCloseAction());
                        handler(
                          task.id,
                          true,
                          is_requested,
                          task.request_task_id
                        );
                        getTask(task.id, is_requested, task.provider);
                        LeftPanelBaseOnScreenSize();
                        LeftPanelResponsiveToggle();
                        setCallUserList(taskUserAssignees);
                        setSelectedTaskIdforIconToggle(0);
                        // showTaskModal();
                      }}
                    >
                      <div
                        className={`${
                          isLiteVersion ? "flex-wrap" : ""
                        } d-flex  w-100 align-items-center comman_action_icon`}
                      >
                        <div className="task-creator-img h32w32 task-creator-img m-auto rounded-5">
                          <div
                            id="deLeteDropdown"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            data-bs-auto-close="outside"
                            data-tip={
                              task.creator_name ? task.creator_name : task.name
                            }
                            data-effect="solid"
                            data-delay-show="1000"
                            data-class="tooltip-main"
                            data-place="right"
                            className={`comman-image-box h-100 w-100 rounded-circle m-auto  ${
                              task.schedule_id > 0 ? "" : ""
                            }`}
                          >
                            <div
                              className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                              style={{
                                backgroundImage: `url('${task.creator_profile_image}')`,
                              }}
                            ></div>
                            {task.addedfrom > 0 && !isTaskOwner ? (
                              <DeleteDropdown
                                docType={task_type}
                                pageName="task"
                                widthClass="w-140"
                                allowToCall={true}
                                makeCall={makeCall}
                                allowToDelete={false}
                                taskUserAssignees={[
                                  {
                                    name: task.creator_name,
                                    profile_image: task.creator_profile_image,
                                    id: task.addedfrom,
                                    email: task.creator_email,
                                    user_type:
                                      task.is_added_from_contact == "1"
                                        ? "contact"
                                        : "staff",
                                  },
                                ]}
                                callListPos={"right"}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        {isLiteVersion ? <></> : <></>}
                        <div className="d-flex flex-column w100minus100per task-list-name text-truncate ms-10px">
                          {["left"].map((placement) => (
                            <div
                              className={`text-truncate ${
                                isLiteVersion ? "w-100 mb-1" : ""
                              }`}
                              data-tip={`#${task.id} - ${task.name}`}
                              data-effect="solid"
                              data-delay-show="1000"
                              data-class="tooltip-main"
                              data-place="top"
                            >
                              {task.name}
                              {task.fileExtension ? (
                                <span>
                                  {" "}
                                  [{task.fileExtension.toUpperCase()}]
                                </span>
                              ) : (
                                <></>
                              )}
                            </div>

                            //   </OverlayTrigger>
                          ))}

                          {/* show space address */}
                          <div className="d-flex align-items-center">
                            {isLiteVersion ? (
                              task.schedule_id > 0 ||
                              task.rel_type == "equipment" ||
                              task.is_requested == 1 ? (
                                <div className="c-font color-white-60 f-10 lh-1 location me-2 fw-normal ">
                                  <span className={` p-0 `}>
                                    {taskStartDate
                                      ? moment(taskStartDate).format(
                                          "DD/MM/YYYY hh:mm a"
                                        )
                                      : _l("l_no_due_date")}
                                  </span>
                                </div>
                              ) : (
                                <div className="c-font color-white-60 f-10 lh-1 location me-2 fw-normal ">
                                  <span className={` p-0 `}>
                                    {taskDueDate
                                      ? moment(taskDueDate).format(
                                          "DD/MM/YYYY hh:mm a"
                                        )
                                      : _l("l_no_due_date")}
                                  </span>
                                </div>
                              )
                            ) : (
                              <></>
                            )}
                            {task.is_requested == "1" ? (
                              <>
                                {isLiteVersion ? (
                                  task.converted_date != undefined &&
                                  task.converted_date != null ? (
                                    reqTimePill
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : task.schedule_id > 0 ? (
                              <>{scheduleTimePill}</>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>

                        {/* show space address */}
                      </div>
                    </a>

                    <div className="task-mid-part w-460 d-flex res-flex-wrap-nowrap align-items-center  for-content-between comman_action_icon justify-content-end">
                      <div className="d-flex align-items-center justify-content-end res-space-start res-width-100">
                        {task.provider == "google_drive" &&
                        task.is_synced !== "undefined" &&
                        task.shared === true ? (
                          <div
                            key={"shared"}
                            className="color-white-60 c-font f-12 pe-2 text-nowrap "
                          >
                            # {_l("l_shared_to_me")}
                          </div>
                        ) : (
                          <></>
                        )}

                        {task.provider == "google_drive" &&
                        task.is_synced !== "undefined" ? (
                          task.parents.map((parent, _index) => {
                            return (
                              <div
                                key={_index}
                                className="color-white-60 c-font f-12 pe-2 text-nowrap "
                              >
                                # {parent.name}
                              </div>
                            );
                          })
                        ) : task.provider == "one_drive" &&
                          task.is_synced !== "undefined" ? (
                          <div
                            key={0}
                            className="color-white-60 c-font f-12 pe-2 text-nowrap "
                          >
                            # {task.parent}
                          </div>
                        ) : (
                          <></>
                        )}

                        <div className="title-fonts text-truncate pill-bottom-show color-white-60 c-font f-12 pe-2 d-none">
                          {_l("l_requested")}
                        </div>
                        {/* {task.is_requested == "1" ? (
                          <>
                            {isLiteVersion ? (task.converted_date != undefined && task.converted_date != null ? reqTimePill : <></>) : (<></>)}
                          </>
                        ) : task.schedule_id > 0 ? <>{(scheduleTimePill)}</> 
                        : <></>} */}

                        <div className="pill-min-width pill-bottom-show  text-end d-none">
                          {task.is_requested == "1" ? (
                            <>
                              <div className="d-flex align-items-center">
                                <a
                                  href="#/"
                                  className="badge  badge-white rounded-pill me-2"
                                  onClick={() => {
                                    if (
                                      task &&
                                      parseInt(task.request_task_id) > 0
                                    ) {
                                      handler(
                                        task.id,
                                        true,
                                        is_requested,
                                        task.request_task_id
                                      );
                                      getTask(
                                        task.id,
                                        is_requested,
                                        task.provider
                                      );
                                      // showTaskModal();
                                      jquery("body").removeClass("body-toggle");
                                      jquery(".provider-list").removeClass(
                                        "expanded"
                                      );
                                      jquery(".office-list").removeClass(
                                        "expanded"
                                      );
                                      setSelectedTaskIdforIconToggle(0);
                                    }
                                  }}
                                >
                                  <span className="text-warning p-0">
                                    {_l("l_request")}
                                  </span>
                                </a>
                                <a
                                  href="#/"
                                  className={`badge  badge-white rounded-pill me-2 ${
                                    task && parseInt(task.request_task_id) > 0
                                      ? ""
                                      : "d-none"
                                  }`}
                                  onClick={() => {
                                    if (
                                      task &&
                                      parseInt(task.request_task_id) > 0
                                    ) {
                                      handler(
                                        task.request_task_id,
                                        true,
                                        null,
                                        task.id
                                      );
                                      getTask(
                                        task.request_task_id,
                                        null,
                                        task.provider
                                      );
                                      // showTaskModal();
                                      jquery("body").removeClass("body-toggle");
                                      jquery(".provider-list").removeClass(
                                        "expanded"
                                      );
                                      jquery(".office-list").removeClass(
                                        "expanded"
                                      );
                                    }
                                  }}
                                >
                                  <span className="color-white p-0">
                                    {task.provider
                                      ? task.is_synced
                                        ? _l("l_document_task")
                                        : _l("l_document")
                                      : _l("l_task")}
                                  </span>
                                </a>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="d-flex align-items-center">
                                <a
                                  href="#/"
                                  className="badge  badge-white rounded-pill me-2"
                                  onClick={() => {
                                    if (
                                      task &&
                                      parseInt(task.request_task_id) > 0
                                    ) {
                                      handler(
                                        task.id,
                                        true,
                                        null,
                                        task.request_task_id
                                      );
                                      getTask(task.id, null, task.provider);
                                      // showTaskModal();
                                      jquery("body").removeClass("body-toggle");
                                      jquery(".provider-list").removeClass(
                                        "expanded"
                                      );
                                      jquery(".office-list").removeClass(
                                        "expanded"
                                      );
                                    }
                                  }}
                                >
                                  <span className="color-white p-0">
                                    {task.provider
                                      ? task.is_synced
                                        ? _l("l_document_task")
                                        : _l("l_document")
                                      : _l("l_task")}
                                  </span>
                                </a>
                                <a
                                  href="#/"
                                  className={`badge  badge-white rounded-pill me-2 fw-light ${
                                    task && parseInt(task.request_task_id) > 0
                                      ? ""
                                      : "d-none"
                                  }`}
                                  onClick={() => {
                                    if (
                                      task &&
                                      parseInt(task.request_task_id) > 0
                                    ) {
                                      handler(
                                        task.request_task_id,
                                        true,
                                        1,
                                        task.id
                                      );
                                      getTask(
                                        task.request_task_id,
                                        1,
                                        task.provider
                                      );
                                      // showTaskModal();
                                      jquery("body").removeClass("body-toggle");
                                      jquery(".provider-list").removeClass(
                                        "expanded"
                                      );
                                      jquery(".office-list").removeClass(
                                        "expanded"
                                      );
                                    }
                                  }}
                                >
                                  <span className="text-warning p-0">
                                    {_l("l_request")}
                                  </span>
                                </a>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`d-flex align-items-center task-bottom-part-full `}
                >
                  <div className="task-right-static-options">
                    <div className="d-flex align-items-center">
                      <div
                        className={`d-flex align-items-center multi_inner_wrapper justify-content-end w-120 w-auto `}
                      >
                        <>
                          {task.schedule_id > 0 &&
                          taskProviderTitle &&
                          taskProviderTitle != "" ? (
                            <div className="badge rounded-pill text-nowrap list-toggle-view-none ms-10px">
                              <span className={` p-0 `}>
                                {"#"}
                                {taskProviderTitle}
                              </span>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      </div>

                      {/* ai created task badge start */}
                     {task.parent_id > 0 ? 
                      <div className="badge rounded-pill text-nowrap list-toggle-view-none me-2">
                        <span className=" p-0 ">{_l("l_ai_task")}</span>
                      </div> : <></>}
                      {/* ai created task badge end */}

                      {/* Common date picker for start_date and due_date */}
                      <UncontrolledDropdown className=" d-none">
                        <DropdownToggle
                          tag="div"
                          className="action_icon with_bg h32w32 on-hover-active-toggle-img light-theme-img"
                          type="button"
                          onClick={(e) => setOpenCheckListTracking(true)}
                          data-tip={_l("l_task_tracking")}
                          data-effect="solid"
                          data-delay-show="1000"
                          data-class="tooltip-main"
                          data-place="top"
                        >
                          <ImageMapPinIcon className="HW18 with-path-stroke" />
                        </DropdownToggle>
                        <DropdownItem className="p-0 no-hover" tag="div">
                          <TrackingDropdown
                            data={task}
                            projectId={project_id}
                            openCheckListTracking={openCheckListTracking}
                            setOpenCheckListTracking={setOpenCheckListTracking}
                          />
                        </DropdownItem>
                      </UncontrolledDropdown>
                      {isLiteVersion ? <></> : <></>}

                      <div
                        className={`align-items-center d-flex with-abs-position-bg-taskcard ${
                          globalView == "kanbanView"
                            ? "flex-wrap kanbanbottomspacing"
                            : ""
                        } ${
                          commanoffcanvasIsOpen
                            ? "with-abs-position-bg-taskcard"
                            : ""
                        }`}
                      >
                        {showDefaultIcons.includes("eye") ? (
                          <div className="comman_action_icon card-view-less-padding p-0 me-2"></div>
                        ) : (
                          <></>
                        )}
                        {(pathname == "/documents" &&
                          task &&
                          task.provider == "google_drive") ||
                        (task && task.provider == "one_drive") ? (
                          <>
                            <div className="comman_action_icon with_separator_10 card-view-less-padding">
                              <div className="c-font f-12 fw-semibold text-nowrap ">
                                {task.size
                                  ? Math.floor(parseInt(task.size) / 1024) +
                                    " KB"
                                  : "No Size"}
                              </div>
                            </div>
                            <div className="dropdown-center dropdown with_separator_10 d-flex align-items-center card-view-less-padding before-block min-width-auto before-dnone">
                              <div className="comman_action_icon w-100 d-flex">
                                <div className="action_icon with_bg h32w32 on-hover-active-toggle-img position-relative">
                                  <a>
                                    <div
                                      className="action_icon with_bg on-hover-active-toggle-img position-relative"
                                      onClick={() => {
                                        window.open(task.webViewLink, "_blank");
                                      }}
                                    >
                                      <Eye
                                        size={18}
                                        weight="light"
                                        className="c-icons"
                                      />
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="dropdown-center dropdown with_separator_10 d-flex align-items-center card-view-less-padding before-block min-width-auto before-dnone">
                              <a
                                className="comman_action_icon w-100 d-flex"
                                href={task.downloadUrl}
                                data-tip={_l("l_download")}
                                data-effect="solid"
                                data-delay-show="1000"
                                data-class="tooltip-main"
                              >
                                {task.fileExtension !== "form" && (
                                  <div className="action_icon with_bg h32w32 on-hover-active-toggle-img position-relative">
                                    <DownloadSimple
                                      size={18}
                                      weight="light"
                                      className="c-icons"
                                    />
                                  </div>
                                )}
                              </a>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {/* Progress bar  start */}

                        {/* {!task.provider && !isLiteVersion ? (
                          <div className="progress_wrapper d-none">
                            {isLiteVersion ? (<></>) : (
                              <div
                                className={`w-185 dropdown dropdown-center ${task.addedfrom == 0 &&
                                    task.schedule_id &&
                                    parseInt(task.schedule_id) > 0
                                    ? "for-disabled"
                                    : ""
                                  } `}
                              
                              >
                                <div
                                  className="d-flex dropdown dropdown-center align-items-center table-list-progress d-flex align-items-center justify-content-end  card-view-less-padding with_separator_10 px-0 comman_action_icon close-toast"
                                  type="button"
                                  id="checkedDropdown"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="outside"
                                  data-bs-auto-close="outside"
                                  data-tip={
                                    task &&
                                      parseInt(task.is_allowed_to_edit) > 0 &&
                                      task.is_requested != 1
                                      ? _l("l_add_checklist")
                                      : _l("l_view_checklist")
                                  }
                                  data-effect="solid"
                                  data-delay-show='1000'
                                  data-class="tooltip-main"
                                  onClick={() => setTaskId(task.id)}
                                >
                                  <div className="">
                                    <a href="#/" className="before-dnone comman_action_icon pe-0 with_separator_10 card-view-none d-none">
                                      <div className="d-flex align-items-center">
                                        <div className="action_icon with_bg h32w32 rounded-circle on-hover-active-toggle-img light-theme-img">
                                          <Plus
                                            size={18}
                                            weight="light"
                                            className="c-icons"
                                          />
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                  <div
                                    className="dropdown dropdown-center progress-wrapper-minus d-flex "
                                    onClick={(e) => setOpenCheckList(true)}
                                  >
                                    <a href="#/"
                                      className="progress-wrapper-link"
                                    
                                    >
                                      <div className="progress-slider with_separator_10 before-dnone card-view-less-padding">
                                        <div
                                          className="d-flex align-items-center"
                                        // onClick={(e) => setOpenCheckList(true)}
                                        >
                                          <div className="progress w_100">
                                            <div
                                              className="progress-bar"
                                              style={{
                                                width:
                                                  task.checklist_progress + "%",
                                              }}
                                            ></div>
                                          </div>
                                          <div className="progress-text c-font f-12 title-fonts">
                                            {`${task.checklist_progress}%`}
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                                <CheckedDropdown
                                  setOpenCheckList={setOpenCheckList}
                                  project_id={project_id}
                                  openCheckList={openCheckList}
                                  task_id={task.id}
                                  taskDetails={task}
                                  task_status={task.status}
                                  isSystemTask={
                                    task && parseInt(task.is_allowed_to_edit) != 1
                                      ? true
                                      : null
                                  }
                                  isChecklistPermission={
                                    task &&
                                      task.is_requested == 1 &&
                                      task.request_task_id > 0
                                      ? 1
                                      : 0
                                  }
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <></>
                        )} */}
                        {/* Progress bar  end */}

                        {/* image upload dropdown start */}

                        {/* {isLiteVersion ? <></> :
                        <div className={`${isLiteVersion ? "" : "w_172"} d-flex align-items-center justify-content-end table-list-attechment `}>
                          <div className="dropdown-center dropdown with_separator_10 d-flex align-items-center card-view-less-padding">
                            <UncontrolledDropdown className="w-100">
                              <DropdownToggle data-toggle="dropdown" tag="span" className="comman_action_icon w-100 d-flex close-toast border-0">
                                <a href="#/"
                                  data-tip={_l("l_attachments")}
                                  data-effect="solid"
                                  data-delay-show='1000'
                                  data-class="tooltip-main"
                                  onClick={(e) => {
                                    // if (parseInt(task.request_task_id) == 0) {
                                    setOpenAttachmentsDropdown(true);
                                    // }
                                  }}
                                >
                                  <div className="d-flex align-items-center w-100">
                                    <div className="action_icon with_bg h32w32 on-hover-active-toggle-img light-theme-img">
                                      <Image
                                        size={18}
                                        weight="light"
                                        className="c-icons"
                                      />
                                    </div>
                                    <span className="c-font f-12 d-flex justify-content-center flex-grow-1 text-on-hover-active-green number-counter-toggle">
                                      {updatedAttachmentCount ||
                                        task.attachments_count}
                                    </span>
                                  </div>
                                </a>
                              </DropdownToggle>
                              <ImageUploadDropdown
                                setOpenAttachmentsDropdown={
                                  setOpenAttachmentsDropdown
                                }
                                openAttachmentsDropdown={
                                  openAttachmentsDropdown
                                }
                                task_id={task.id}
                                project_id={project_id}
                                updateImageCount={setUpdatedAttachmentCount}
                                Request_task_converted={
                                  task &&
                                    parseInt(task.request_task_id) > 0 &&
                                    task.is_requested == 1
                                    ? true
                                    : false
                                }
                                taskDetails={task}
                                unContolList={true}
                              />
                            </UncontrolledDropdown>
                          </div>
                        </div>} */}
                        {/* image upload dropdown end */}

                        {/* status  start*/}
                        {selectedTaskIdforIconToggle == task.id &&
                        showDefaultIcons.includes("status") ? (
                          <div
                            className={` dropdown dropdown-center comman_action_icon card-view-less-padding p-0 me-10px
                            ${
                              task &&
                              !(
                                userType == "operator" && task.schedule_id > 0
                              ) &&
                              ((parseInt(task.is_allowed_to_edit) > 0 &&
                                task.is_requested == 0) ||
                                (parseInt(task.request_task_id) == 0 &&
                                  task.is_requested == 1))
                                ? ""
                                : "for-disabled"
                            }
                          `}
                          >
                            <div
                              className={`white-border-box me-0 color-white-60 action_icon with_bg h32w32 d-flex close-toast`}
                              type="button"
                              id="TaskListStatusDropdown"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              data-tip={`${_l("l_status")}`}
                              data-effect="solid"
                              data-delay-show="1000"
                              data-class="tooltip-main"
                            >
                              <div className="d-flex align-items-center m-auto">
                                <span
                                  className={`c-dots c-10 ${
                                    task.is_requested == 1
                                      ? "bg-warning"
                                      : task.status == 1
                                      ? "in-progress-white"
                                      : task.status == 4
                                      ? "in-progress-blue"
                                      : "done"
                                  } rounded-circle`}
                                ></span>
                                <span className="d-none">
                                  {taskStatus == 1
                                    ? "Not Started"
                                    : taskStatus == 4
                                    ? "In Progress"
                                    : "Done"}
                                </span>
                                <CaretDown
                                  size={14}
                                  weight="light"
                                  className="c-icons d-none"
                                />
                                {(task &&
                                  parseInt(task.is_allowed_to_edit) > 0 &&
                                  task.is_requested == 0) ||
                                (task &&
                                  parseInt(task.request_task_id) == 0 &&
                                  task.is_requested == 1) ? (
                                  <TaskListStatusDropdown
                                    setTaskStatus={changeTaskStatus}
                                    is_task={1}
                                  />
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            <ReactTooltip />
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* staus end */}

                        {/* call icon start */}
                        {selectedTaskIdforIconToggle == task.id &&
                        showDefaultIcons.includes("call") &&
                        parseInt(task.addedfrom) > 0 ? (
                          <UncontrolledDropdown
                            direction={"down"}
                            className="comman_action_icon me-10px d-none"
                          >
                            <DropdownToggle tag="div" className="">
                              <a
                                href="#/"
                                className="dropdown-item p-0"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="outside"
                                aria-expanded="false"
                                data-popper-placement="left-start"
                              >
                                <div className="action_icon with_bg h32w32">
                                  <PhoneCall
                                    size={18}
                                    className="c-icons"
                                    weight="light"
                                  />
                                </div>
                              </a>
                              <UncontrolledGeneralListing
                                list={taskUserAssignees.map((item, index) => {
                                  return {
                                    name: item.name,
                                    image: item.profile_image,
                                    id: index,
                                    isOnline: 0,
                                    userId: item.id,
                                    email: item.email,
                                    userType: item.user_type,
                                  };
                                })}
                                makeCall={makeCall}
                                forCall={true}
                              />
                            </DropdownToggle>
                            <ReactTooltip />
                          </UncontrolledDropdown>
                        ) : (
                          <></>
                        )}
                        {/* call icon end */}

                        {/* share Icon start */}
                        {selectedTaskIdforIconToggle == task.id &&
                        showDefaultIcons.includes("share") ? (
                          <div>
                            <div
                              className="comman_action_icon card-view-less-padding p-0 me-10px dropdown"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="outside"
                              aria-expanded="false"
                              data-popper-placement="left-start"
                            >
                              <a
                                href="#/"
                                className={` white-border-box action_icon with_bg h32w32`}
                                data-tip={_l("l_share")}
                                data-effect="solid"
                                data-delay-show="1000"
                                data-class="tooltip-main"
                                onClick={() => {
                                  setTask_type(
                                    task.is_requested && task.is_requested == 1
                                      ? "request-task"
                                      : "task"
                                  );
                                }}
                              >
                                <div className="">
                                  <ShareNetworkIcon
                                    size={14}
                                    className="c-icons with-fill"
                                    weight="light"
                                  />
                                </div>
                              </a>
                              <ActionDropdown
                                actions={[
                                  {
                                    actionName: _l("l_copy_link"),
                                    actionHandler: () => {
                                      navigator.clipboard.writeText(
                                        `${
                                          process.env.REACT_APP_MYR_WEB_URL
                                        }/${task_type}/${task ? task.hash : ""}`
                                      );
                                      dispatch(
                                        showMessage(
                                          "sucess",
                                          _l("l_success"),
                                          _l("l_link_copied")
                                        )
                                      );
                                      HideDropdown();
                                    },
                                    actionIcon: "CopyIcon",
                                  },
                                  {
                                    actionName: _l("l_send_to_email"),
                                    actionHandler: () => {
                                      setShowLink(true);
                                      HideDropdown();
                                    },
                                    actionIcon: "EnvelopeSimpleIcon",
                                  },
                                  {
                                    actionName: _l("l_open_link"),
                                    actionHandler: () => {
                                      window.open(
                                        `${
                                          process.env.REACT_APP_MYR_WEB_URL
                                        }/${task_type}/${task ? task.hash : ""}`
                                      );
                                      HideDropdown();
                                    },
                                    actionIcon: "LinkSimpleIcon",
                                  },
                                ]}
                              />
                              <TaskShareLinkModal
                                show={showLink}
                                handleClose={handleCloseShareLink}
                                handleShow={handleShowShareLink}
                                link={`${process.env.REACT_APP_MYR_WEB_URL}/${task_type}/${task.hash}`}
                                data={{ id: task.id, contacts: contacts }}
                                docType={task_type}
                              />
                            </div>
                            <ReactTooltip />
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* share Icon end */}

                        {/* task rating start */}
                        <>
                          {/* Task temporary implementation of average rating */}
                          {(showDefaultIcons.includes("star") &&
                            selectedTaskIdforIconToggle == task.id) ||
                          (task.average_rating &&
                            !isNaN(Number(task.average_rating)) &&
                            Number(task.average_rating)) ? (
                            <div className="  with_separator_10 text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding ">
                              <>
                                {["bottom"].map((placement) => (
                                  <OverlayTrigger
                                    trigger="hover"
                                    key={placement}
                                    placement={placement}
                                    overlay={
                                      <Popover
                                        className="d-none"
                                        id={`popover-positioned-${placement}`}
                                      >
                                        <Popover.Body className="w-250 p-0">
                                          <div className="c-font f-12 color-white-60 p-10 pb-0">
                                            header
                                          </div>
                                          <ul className="notification-listing">
                                            <li className="hr_sepretr dropdown-item">
                                              <div className="d-flex align-items-center text-truncate">
                                                <div className="c-list-icon ">
                                                  <AddTaskIcon className="HW18" />
                                                </div>
                                                <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                                  <div className="c-list-detail text-truncate">
                                                    {_l("l_tasks")}
                                                  </div>
                                                  <div className="unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex">
                                                    0
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                            <li className="hr_sepretr dropdown-item">
                                              <div className="d-flex align-items-center text-truncate">
                                                <div className="c-list-icon ">
                                                  <AddTaskIcon className="HW18" />
                                                </div>
                                                <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                                  <div className="c-list-detail text-truncate">
                                                    {_l("l_tasks")}
                                                  </div>
                                                  <div className="unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex">
                                                    0
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                            <li className="hr_sepretr dropdown-item">
                                              <div className="d-flex align-items-center text-truncate">
                                                <div className="c-list-icon ">
                                                  <AddTaskIcon className="HW18" />
                                                </div>
                                                <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                                  <div className="c-list-detail text-truncate">
                                                    {_l("l_tasks")}
                                                  </div>
                                                  <div className="unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex">
                                                    0
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                            <li className="hr_sepretr dropdown-item">
                                              <div className="d-flex align-items-center text-truncate">
                                                <div className="c-list-icon ">
                                                  <AddTaskIcon className="HW18" />
                                                </div>
                                                <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                                  <div className="c-list-detail text-truncate">
                                                    {_l("l_tasks")}
                                                  </div>
                                                  <div className="unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex">
                                                    0
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                            <li className="hr_sepretr dropdown-item">
                                              <div className="d-flex align-items-center text-truncate">
                                                <div className="c-list-icon ">
                                                  <AddTaskIcon className="HW18" />
                                                </div>
                                                <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                                  <div className="c-list-detail text-truncate">
                                                    {_l("l_tasks")}
                                                  </div>
                                                  <div className="unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex">
                                                    0
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </Popover.Body>
                                      </Popover>
                                    }
                                  >
                                    <a
                                      href="#/"
                                      className="comman_action_icon w_100px d-flex align-items-center close-toast border-0"
                                      data-tip={_l("l_star_rating")}
                                      data-effect="solid"
                                      data-delay-show="1000"
                                      data-class="tooltip-main"
                                    >
                                      <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                                        {task.average_rating &&
                                        !isNaN(Number(task.average_rating)) &&
                                        Number(task.average_rating) ? (
                                          <Star
                                            size={18}
                                            className="c-icons"
                                            weight="fill"
                                            color="#00ff6a"
                                          />
                                        ) : (
                                          <Star
                                            size={18}
                                            className="c-icons"
                                            weight="light"
                                          />
                                        )}
                                      </div>
                                      <span className="c-font f-12 d-flex justify-content-center flex-grow-1 title-fonts ps-0">
                                        {task.average_rating &&
                                        !isNaN(Number(task.average_rating))
                                          ? client_id == 1080
                                            ? `${Number(
                                                task.average_rating
                                              ).toFixed(1)} / 20`
                                            : Number(
                                                task.average_rating
                                              ).toFixed(1)
                                          : 0}
                                      </span>
                                    </a>
                                  </OverlayTrigger>
                                ))}
                              </>
                              <ReactTooltip />
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                        {/* task rating end */}

                        {/* cube start */}
                        {task.rel_type == "equipment" &&
                        showDefaultIcons.includes("cube") &&
                        selectedTaskIdforIconToggle == task.id ? (
                          <>
                            {task.surface > 0 ? (
                              <div className="badge rounded-pill text-nowrap list-toggle-view-none me-1">
                                <span className={` p-0 `}>
                                  {task.surface}m<sup>2</sup>
                                </span>
                              </div>
                            ) : (
                              <></>
                            )}
                            {task.duration > 0 ? (
                              <div className="badge rounded-pill text-nowrap list-toggle-view-none me-1">
                                <span className={` p-0`}>
                                  {task.duration}hrs
                                </span>
                              </div>
                            ) : (
                              <></>
                            )}
                            <a
                              href="#/"
                              className="me-10px comman_action_icon position-relative"
                              data-tip={_l("l_robot_plan")}
                              data-effect="solid"
                              data-delay-show="1000"
                              data-class="tooltip-main"
                              onClick={() => {
                                if (task.map_attachment_url == "") {
                                  setShowMapModal(true);
                                } else {
                                  setShowMapInfoModal(true);
                                }
                              }}
                            >
                              <div
                                className={`action_icon with_bg h32w32 on-hover-active-toggle-img`}
                              >
                                <Cube
                                  size={18}
                                  className="c-icons"
                                  weight="light"
                                />
                              </div>
                            </a>
                            <ReactTooltip />
                          </>
                        ) : (
                          <></>
                        )}
                        {/* cube end */}

                        {/* map icon start */}
                        {isLiteVersion ? (
                          (selectedTaskIdforIconToggle == task.id &&
                            showDefaultIcons.includes("map")) ||
                          window.location.pathname == "/dashboard" ? (
                            <div className="comman_action_icon card-view-less-padding p-0 me-10px">
                              <UncontrolledDropdown className="dropdown">
                                <DropdownToggle
                                  tag="a"
                                  className="action_icon with_bg h32w32 justify-content-center align-items-center"
                                  type="button"
                                  data-tip={`${
                                    task.rel_id
                                      ? task.rel_id
                                      : _l("l_all_spaces")
                                  }`}
                                  data-effect="solid"
                                  data-delay-show="1000"
                                  data-class="tooltip-main"
                                  id="SapceListMainDropdown"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  data-bs-auto-close="true"
                                  onClick={() => {
                                    // setOpenSpaceListDropdown(true);
                                  }}
                                >
                                  <div
                                    className={`on-hover-active-toggle-img text-on-hover-active-green ${
                                      selectedSpace > 0 ? "active" : ""
                                    }`}
                                  >
                                    <MapPin
                                      size={18}
                                      className="c-icons"
                                      weight="light"
                                    />
                                  </div>
                                </DropdownToggle>
                                <DropdownItem
                                  className="p-0 no-hover"
                                  tag="div"
                                >
                                  <SpaceListDropdown
                                    openSpaceListDropdown={
                                      openSpaceListDropdown
                                    }
                                    SpaceList={spaceState}
                                    setOpenSpaceListDropdown={
                                      setOpenSpaceListDropdown
                                    }
                                    handler={handleTaskSpace}
                                    selectedSpace={selectedSpace}
                                    isFromTaskCard={true}
                                    setSelectedSpace={setSelectedSpace}
                                    unContolList={true}
                                  />
                                </DropdownItem>
                              </UncontrolledDropdown>
                              <ReactTooltip />
                            </div>
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                        {/* map icon end */}

                        {/* HighPriorityIcon start */}
                        {taskPriority == 1 ||
                        (selectedTaskIdforIconToggle == task.id &&
                          showDefaultIcons.includes("priority")) ? (
                          <div className="comman_action_icon card-view-less-padding p-0 me-10px">
                            <div
                              className={`priority-checkbox span-13 white-border-box action_icon with_bg h32w32 close-toast`}
                              data-tip={
                                taskPriority == 3
                                  ? _l("l_high_priority")
                                  : _l("l_priority")
                              }
                              data-effect="solid"
                              data-delay-show="1000"
                              data-class="tooltip-main"
                            >
                              <input
                                type="checkbox"
                                id="priority"
                                className="absolute-input"
                                checked={taskPriority == 3 ? true : false}
                                onChange={(e) => {
                                  changeTaskPriority(e);
                                }}
                                disabled={
                                  task && parseInt(task.request_task_id) > 0
                                }
                              />
                              <div className="check_box">
                                <HighPriorityActiveIcon className="HW18 check_span_img active" />
                                <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                              </div>
                            </div>
                            <ReactTooltip />
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* HighPriorityIcon end */}

                        {/* push pin icon start  */}
                        {isTaskPinned == true ||
                        (selectedTaskIdforIconToggle == task.id &&
                          showDefaultIcons.includes("pin")) ? (
                          <div className="before-dnone comman_action_icon with_separator_10">
                            <a
                              href="#/"
                              className="action_icon with_bg h32w32 light-theme-img close-toast"
                              data-tip={
                                isTaskPinned && isTaskPinned == true
                                  ? _l("l_unpin")
                                  : _l("l_pin")
                              }
                              data-effect="solid"
                              data-delay-show="1000"
                              data-class="tooltip-main"
                            >
                              {isTaskPinned && isTaskPinned == true ? (
                                <div
                                  className="m-auto "
                                  onClick={() => {
                                    pinTask();
                                  }}
                                >
                                  <PushPin
                                    size={18}
                                    weight="fill"
                                    className="Push-Pin-fill"
                                  />
                                </div>
                              ) : (
                                <div
                                  className="m-auto"
                                  onClick={() => {
                                    pinTask();
                                  }}
                                >
                                  <PushPin
                                    size={18}
                                    weight="light"
                                    className="c-icons"
                                  />
                                </div>
                              )}
                            </a>
                            <ReactTooltip />
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* push pin icon end  */}

                        {/* comment icon start */}
                        {Number(updatedCommentCount) > 0 ||
                        (selectedTaskIdforIconToggle == task.id &&
                          showDefaultIcons.includes("comment")) ? (
                          <div
                            className={`${
                              isLiteVersion ? " " : ""
                            } dropdown-center dropdown with_separator_10 text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding close-toast`}
                          >
                            <UncontrolledDropdown className="w-100">
                              <DropdownToggle
                                data-toggle="dropdown"
                                tag="span"
                                className="comman_action_icon w-100 d-flex close-toast border-0"
                              >
                                <a
                                  href="#/"
                                  data-tip={_l("l_comments")}
                                  data-effect="solid"
                                  data-delay-show="1000"
                                  data-class="tooltip-main"
                                  onClick={(e) => {
                                    setOpenCommentTab(true);
                                    getMentionlist(task.id);
                                    getTaskComments();
                                  }}
                                >
                                  <div className="d-flex align-items-center w-100">
                                    <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                                      <ChatCircle
                                        size={18}
                                        weight="light"
                                        className="c-icons"
                                      />
                                      <span
                                        className={`rounded-pill badge-custom p-0 ${
                                          unreadCommentCount.current == 0 ||
                                          Number(updatedCommentCount)
                                            ? "d-none"
                                            : ""
                                        }`}
                                        data-unread-cmt-id={task.id}
                                      >
                                        {unreadCommentCount.current}
                                      </span>
                                    </div>
                                    {/* {isLiteVersion ? (<></>) : ( */}
                                    <span
                                      className="c-font f-12 d-flex justify-content-center flex-grow-1 number-counter-toggle"
                                      data-cmt-cnt-id={task.id}
                                    >
                                      {Number(updatedCommentCount) > 0
                                        ? Number(updatedCommentCount)
                                        : 0}
                                    </span>
                                    {/* )} */}
                                  </div>
                                </a>
                              </DropdownToggle>
                              <DropdownItem toggle={false} className="p-0">
                                {
                                  <CommentMinified
                                    comments={commentsList}
                                    disabled={
                                      task &&
                                      parseInt(task.request_task_id) > 0 &&
                                      task.is_requested == 1
                                        ? true
                                        : false
                                    }
                                    handleAddComment={handleAddEditComment}
                                    deleteComment={handleDeleteComment}
                                    id={task.id}
                                    task={task}
                                    loading={commentsLoading}
                                    mentionList={mentionsList.map((name) => {
                                      let id =
                                        name.is_team && name.is_team == 1
                                          ? "-" + name.id
                                          : name.id;
                                      return {
                                        display: name.name,
                                        id: id,
                                        image: name.profile_image || "",
                                        isteam: name.is_team ? 1 : 0,
                                      };
                                    })}
                                    handleImagePreview={handleImagePreview}
                                    unContolList={true}
                                    totalUnreadCommentCount={
                                      task.unread_comment_count
                                    }
                                    showComments={showComments}
                                    setReplyCommentData={setReplyCommentData}
                                    fileLoader={fileUploadLoader}
                                  />
                                }
                              </DropdownItem>
                            </UncontrolledDropdown>
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* comment icon end */}
                        {/* Convert to checklist start */}
                        {/* {showDefaultIcons.includes('template') ?
                          <div className="comman_action_icon me-10px">
                            <a href="#/" className='d-flex action_icon with_bg h32w32  position-relative on-hover-active-toggle-img '
                                data-tip={`${_l("l_select_checklist_template")}`}
                                data-effect="solid"
                                data-delay-show='1000'
                                data-class="tooltip-main"
                                data-place="top"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                data-bs-auto-close="true"
                                onClick={() => {}}>
                                <ListChecks size={18} className="c-icons " weight="light" />
                            </a>
                          </div>
                          :
                          <></>
                        } */}
                        {/* Convert to checklist end */}
                        {/* Delete Icon start */}
                        {selectedTaskIdforIconToggle == task.id &&
                        showDefaultIcons.includes("delete") &&
                        parseInt(task.is_allowed_to_delete) == 1 ? (
                          <div className="comman_action_icon card-view-less-padding p-0 me-10px">
                            <a
                              href="#/"
                              className={`priority-checkbox span-13 white-border-box action_icon with_bg h32w32`}
                              data-tip={_l("l_delete")}
                              data-effect="solid"
                              data-delay-show="1000"
                              data-class="tooltip-main"
                              onClick={() => {
                                resetToast();
                                dispatch(IsToastOpenOrClose(true));
                                // setToastToggle(false)

                                // setShowToast(false);

                                let deleteConfirmationText =
                                  task && task.request_task_id > 0
                                    ? task.is_requested == 1
                                      ? _l("l_and_converted_task")
                                      : _l("l_and_request_task")
                                    : "";
                                setToastHeader(_l("l_are_you_sure"));
                                setToastClass("toast-with-btn");
                                setToastMessage(
                                  _l("l_you_want_tot_delete_this_task") +
                                    deleteConfirmationText +
                                    " ?"
                                );
                                setConfirmButtonText(_l("l_delete"));
                                setCancelButtonText(_l("l_cancel"));
                                setShowToast(true);
                                setRequestTaskId(
                                  task &&
                                    task.request_task_id &&
                                    task.request_task_id != 0
                                    ? task.request_task_id
                                    : 0
                                );
                              }}
                            >
                              <div className="">
                                <Trash
                                  size={18}
                                  className="c-icons"
                                  weight="light"
                                />
                              </div>
                            </a>
                            <ReactTooltip />
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* Delete Icon end */}

                        {/* task assignees start */}
                        {taskAssigneePills.length > 0 ||
                        taskAllAssignee.length > 0 ? (
                          <div
                            className={`position-relative d-flex assignee-list-wrapper card-view-less-padding ${
                              isLiteVersion ? "" : "px-2"
                            }`}
                          >
                            <div
                              className={`dropdown-center multi_inner_wrapper with-min-width ps-0 d-flex  with_minus  ${
                                globalView == "kanbanView" ? "mt-10" : ""
                              }  ${
                                taskAssigneePills &&
                                taskAssigneePills.length >= 1
                                  ? ""
                                  : "d-none"
                              }`}
                            >
                              {taskAssigneePills &&
                              taskAssigneePills.length >= 1 ? (
                                taskAssigneePills.map((assignee, index) => {
                                  if (index < 1)
                                    return (
                                      <div className="pe-10px">
                                        <div
                                          className="badge rounded-pill text-nowrap list-toggle-view-none"
                                          key={index}
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                          data-tip={`${_l(assignee.name)}`}
                                          data-effect="solid"
                                          data-delay-show="1000"
                                          data-class="tooltip-main"
                                        >
                                          <span className={` p-0 `}>
                                            {assignee.main_key == "my_task"
                                              ? "#"
                                              : ""}
                                            {_l(assignee.name)}
                                          </span>
                                          <ReactTooltip />
                                        </div>
                                      </div>
                                    );
                                })
                              ) : (
                                <></>
                              )}

                              {taskAssigneePills &&
                              taskAssigneePills.length > 1 ? (
                                <div className="pe-10px">
                                  <a
                                    href="#/"
                                    className="badge rounded-pill text-nowrap list-toggle-view-none"
                                    // id="TaskAssigneeDropdown"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    data-tip={`${_l("l_view_all")}`}
                                    data-effect="solid"
                                    data-delay-show="1000"
                                    data-class="tooltip-main"
                                    onClick={handleTaskAssigneeData}
                                  >
                                    <div className="unread_count m-auto c-font f-10">
                                      +{taskAssigneePills.length - 1}
                                    </div>
                                  </a>
                                  <GeneralListing
                                    handler={() => {}}
                                    list={
                                      taskAssigneePills &&
                                      taskAssigneePills.length &&
                                      taskAssigneePills.map((item, index) => {
                                        return {
                                          name: item.name,
                                          id: index,
                                        };
                                      })
                                    }
                                    isHash={true}
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                              {/* {taskAssigneePills && taskAssigneePills.length > 0 ? (
                                <GeneralListing
                                  handler={() => { }}
                                  list={
                                    taskAssigneePills &&
                                    taskAssigneePills.length &&
                                    taskAssigneePills.map((item, index) => {
                                      return {
                                        name: item.name,
                                        id: index,
                                      };
                                    })
                                  }
                                  isHash={true}
                                />
                              ) : (
                                <></>
                              )} */}
                            </div>
                            <div
                              className={`dropdown-center multi_inner_wrapper with-min-width d-flex ps-0 pe-10px with_minus before-dnone ${
                                globalView == "kanbanView" ? "mt-10" : ""
                              } ${
                                taskAllAssignee && taskAllAssignee.length >= 1
                                  ? ""
                                  : "d-none"
                              }`}
                            >
                              {taskAllAssignee &&
                              taskAllAssignee.length >= 1 ? (
                                taskAllAssignee.map((assignee, index) => {
                                  if (index < 1)
                                    return (
                                      <a
                                        href="#/"
                                        data-bs-toggle="tooltip"
                                        className="comman-image-box multi-wrapper-border h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                                        data-tip={
                                          assignee.name || assignee.full_name
                                        }
                                        data-place="right"
                                        data-effect="solid"
                                        data-delay-show="1000"
                                        data-class="tooltip-main"
                                        key={index}
                                      >
                                        <div
                                          className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                          style={{
                                            backgroundImage: `url('${
                                              assignee.user_image ||
                                              assignee.profile_image
                                            }')`,
                                          }}
                                        ></div>
                                        <ReactTooltip />
                                      </a>
                                    );
                                })
                              ) : (
                                <></>
                              )}
                              <UncontrolledDropdown>
                                <DropdownToggle tag="div">
                                  {taskAllAssignee &&
                                  taskAllAssignee.length > 1 ? (
                                    <a
                                      href="#/"
                                      className="comman-image-box h32w32 with_overlay d-flex rounded-circle dropdown-center"
                                      // id="TaskAssigneeDropdown"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      data-tip={`${_l("l_view_all")}`}
                                      data-effect="solid"
                                      data-delay-show="1000"
                                      data-class="tooltip-main"
                                      onClick={handleTaskAssigneeData}
                                    >
                                      <div className="unread_count m-auto c-font f-12">
                                        +{taskAllAssignee.length - 1}
                                      </div>
                                    </a>
                                  ) : (
                                    <></>
                                  )}
                                  {taskAllAssignee && taskAllAssignee.length ? (
                                    <GeneralListing
                                      unControlList={true}
                                      handler={() => {}}
                                      list={
                                        taskAllAssignee &&
                                        taskAllAssignee.length &&
                                        taskAllAssignee
                                          .sort((a, b) => {
                                            if (a.name < b.name) return -1;
                                            if (a.name > b.name) return 1;
                                          })
                                          .map((item, index) => {
                                            return {
                                              name: item.full_name || item.name,
                                              image:
                                                item.user_image ||
                                                item.profile_image,
                                              id: item.id,
                                            };
                                          })
                                      }
                                      customSort={true}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </DropdownToggle>
                              </UncontrolledDropdown>
                            </div>
                            <ReactTooltip />
                          </div>
                        ) : (
                          <></>
                        )}

                        {/* task assignees end */}

                        {/* separator Start */}

                        {globalView == "kanbanView" ? (
                          <></>
                        ) : (
                          <div
                            className={`px-0 ${
                              showDefaultIcons.length > 0 ||
                              selectedTaskIdforIconToggle == task.id
                                ? "with_separator2_10"
                                : ""
                            } me-10px`}
                          ></div>
                        )}

                        {/* separator end */}

                        {/* calendar start */}
                        <div
                          className="comman_action_icon custom-datepicker w-330px withinputabsolute me-10px"
                          data-bs-toggle="tooltip"
                          data-tip={
                            task.schedule_id > 0 ||
                            task.rel_type == "equipment" ||
                            task.is_requested == 1
                              ? startDateTitle
                              : dueDateTitle
                          }
                          data-effect="solid"
                          data-delay-show="1000"
                          data-class="tooltip-main"
                        >
                          <div
                            className={`action_icon with_bg h32w32 on-hover-active-toggle-img light-theme-img position-relative `}
                          >
                            <CalendarBlank
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                            <DatePicker
                              portalId="custom_datepicker"
                              calendarClassName="custom-datepicker"
                              timeCaption={getDatePickerTimeCaption()}
                              selected={
                                task.schedule_id > 0 ||
                                task.rel_type == "equipment" ||
                                task.is_requested == 1
                                  ? taskStartDate
                                  : taskDueDate
                              }
                              calendarStartDay={1}
                              locale={getDatePickerLocale()}
                              className="close-toast"
                              placeholderText={`${
                                task.schedule_id > 0 ||
                                task.rel_type == "equipment" ||
                                task.is_requested == 1
                                  ? _l("l_startdate")
                                  : _l("l_duedate")
                              }`}
                              onChange={(date) => {
                                if (showStartDate) {
                                  setTaskStartDate(date);
                                  handleUpdateDate(date);
                                } else {
                                  setTaskDueDate(date);
                                  handleUpdateDate("", date);
                                }
                              }}
                              showTimeSelect
                              dateFormat="dd/MM/yyyy hh:mm a"
                              readOnly={
                                task.is_requested === "1"
                                  ? true
                                  : task.is_allowed_to_edit == 1
                                  ? false
                                  : true
                              }
                            >
                              <div className="datepicker-label">
                                {_l("l_select_date_and_time")}
                              </div>
                            </DatePicker>
                          </div>
                        </div>
                        {/* calendar end */}

                        {/* assigne to btn start */}
                        <div
                          className={`comman_action_icon card-view-none me-10px  ${
                            isTaskAssignmentDisabled() ? "for-disabled" : ""
                          }`}
                        >
                          <div className="d-flex align-items-center">
                            <div className="dropup dropup-center">
                              <UncontrolledDropdown
                                setActiveFromChild={true}
                                isOpen={openAssigneeDropdown}
                                direction="end"
                                className="w-200"
                                data-tip={`${_l("l_add_assignee")}`}
                                data-effect="solid"
                                data-delay-show="1000"
                                data-class="tooltip-main"
                                data-place="top"
                              >
                                <DropdownToggle
                                  id={`AddAssigneeMainDropdownNew_${task.id}`}
                                  className="border-0  p-0 d-flex align-items-center"
                                  onClick={() =>
                                    setOpenAssigneeDropdown(
                                      !openAssigneeDropdown
                                    )
                                  }
                                >
                                  <div
                                    className="action_icon with_bg on-hover-active-toggle-img text-on-hover-active-green light-theme-img h32wauto  color-white-80 calendarpadding d-flex align-items-center text-truncate"
                                    id={`AssigneeMainDropdownNew_${task.id}`}
                                  >
                                    <UserCircleGear
                                      size={18}
                                      weight="light"
                                      className="c-icons"
                                    />
                                    <span className="c-font f-12 ps-2 text-uppercase no-display-in-responsive text-on-hover-active-green">
                                      {_l("l_assign_to")}
                                    </span>
                                  </div>
                                </DropdownToggle>
                                {/* { openAssigneeDropdown ? */}
                                {openAssigneeDropdown == true ? (
                                  <AddAssigneeMainDropdownNew
                                    key={task.id}
                                    projectId={project_id}
                                    taskId={task.id}
                                    openAssigneeDropdown={openAssigneeDropdown}
                                    setOpenAssigneeDropdown={
                                      setOpenAssigneeDropdown
                                    }
                                    fromTaskcard="1"
                                    selectedTagId={selectedTagId}
                                    pageName="task"
                                    selectedId={selectedTaskAssigneesId}
                                    isRequest={task.is_requested == 1}
                                    saveBtnToggle={true}
                                    selectedAssignee={selectedAssigneeNew}
                                    setSelectedAssignee={setSelectedAssigneeNew}
                                  />
                                ) : (
                                  <></>
                                )}
                              </UncontrolledDropdown>
                            </div>
                          </div>
                        </div>
                        {/* assigne to btn end */}
                        <div
                          className={`comman_action_icon  ${
                            globalView == "kanbanView" ? "absoluteDotsTask" : ""
                          }`}
                        >
                          <a
                            href="#/"
                            className={`action_icon h32w32 with_bg with-border-active`}
                            onClick={() => {
                              handleToggleIcons(
                                isTaskPinned,
                                taskAssignee,
                                taskAssigneePills,
                                taskPriority,
                                task.comments_count,
                                task.rating,
                                setTaskCardIconToggle,
                                taskCardIconToggle
                              );
                              if (selectedTaskIdforIconToggle == task.id) {
                                setSelectedTaskIdforIconToggle(0);
                              } else {
                                setSelectedTaskIdforIconToggle(task.id);
                              }
                            }}
                          >
                            <DotsNine
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={`task-assign-section `}>
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="pill-min-width  pill-bottom-top text-end d-none">
                        {task.is_requested == "1" ? (
                          <>
                            <div className="d-flex align-items-center">
                              <a
                                href="#/"
                                className="badge  badge-white rounded-pill me-2"
                                onClick={() => {
                                  if (
                                    task &&
                                    parseInt(task.request_task_id) > 0
                                  ) {
                                    handler(
                                      task.id,
                                      true,
                                      is_requested,
                                      task.request_task_id
                                    );
                                    getTask(
                                      task.id,
                                      is_requested,
                                      task.provider
                                    );
                                    jquery("body").removeClass("body-toggle");
                                    jquery(".provider-list").removeClass(
                                      "expanded"
                                    );
                                    jquery(".office-list").removeClass(
                                      "expanded"
                                    );
                                  }
                                }}
                              >
                                <span className="text-warning p-0">
                                  {_l("l_request")}
                                </span>
                              </a>
                              <a
                                href="#/"
                                className={`badge  badge-white rounded-pill me-2 ${
                                  task && parseInt(task.request_task_id) > 0
                                    ? ""
                                    : "d-none"
                                }`}
                                onClick={() => {
                                  if (
                                    task &&
                                    parseInt(task.request_task_id) > 0
                                  ) {
                                    handler(
                                      task.request_task_id,
                                      true,
                                      null,
                                      task.id
                                    );
                                    getTask(
                                      task.request_task_id,
                                      null,
                                      task.provider
                                    );
                                    // showTaskModal();
                                    jquery("body").removeClass("body-toggle");
                                    jquery(".provider-list").removeClass(
                                      "expanded"
                                    );
                                    jquery(".office-list").removeClass(
                                      "expanded"
                                    );
                                  }
                                }}
                              >
                                <span className="color-white p-0">
                                  {task.provider
                                    ? task.is_synced
                                      ? _l("l_document_task")
                                      : _l("l_document")
                                    : _l("l_task")}
                                </span>
                              </a>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="d-flex align-items-center">
                              <a
                                href="#/"
                                className="badge  badge-white rounded-pill me-2"
                                onClick={() => {
                                  if (
                                    task &&
                                    parseInt(task.request_task_id) > 0
                                  ) {
                                    handler(
                                      task.id,
                                      true,
                                      null,
                                      task.request_task_id
                                    );
                                    getTask(task.id, null, task.provider);
                                    // showTaskModal();
                                    jquery("body").removeClass("body-toggle");
                                    jquery(".provider-list").removeClass(
                                      "expanded"
                                    );
                                    jquery(".office-list").removeClass(
                                      "expanded"
                                    );
                                  }
                                }}
                              >
                                <span className="color-white p-0">
                                  {task.provider
                                    ? task.is_synced
                                      ? _l("l_document_task")
                                      : _l("l_document")
                                    : _l("l_task")}
                                </span>
                              </a>
                              <a
                                href="#/"
                                className={`badge  badge-white rounded-pill me-2 fw-light ${
                                  task && parseInt(task.request_task_id) > 0
                                    ? ""
                                    : "d-none"
                                }`}
                                onClick={() => {
                                  if (
                                    task &&
                                    parseInt(task.request_task_id) > 0
                                  ) {
                                    handler(
                                      task.request_task_id,
                                      true,
                                      1,
                                      task.id
                                    );
                                    getTask(
                                      task.request_task_id,
                                      1,
                                      task.provider
                                    );
                                    // showTaskModal();
                                    jquery("body").removeClass("body-toggle");
                                    jquery(".provider-list").removeClass(
                                      "expanded"
                                    );
                                    jquery(".office-list").removeClass(
                                      "expanded"
                                    );
                                  }
                                }}
                              >
                                <span className="text-warning p-0">
                                  {_l("l_request")}
                                </span>
                              </a>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {isLiteVersion ? (
                  <></>
                ) : (
                  <div className={`dropstart h32w32 `}>
                    {/* ${ task && parseInt(task.is_allowed_to_delete) == 1  ? "" : "for-disabled"  } */}
                    <a
                      href="#/"
                      type="button"
                      className={`line-height-0 light-theme-img`}
                      id="deLeteDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-auto-close="outside"
                      data-tip={`${_l("l_options")}`}
                      data-effect="solid"
                      data-delay-show="1000"
                      data-class="tooltip-main"
                      data-place="left"
                      onClick={() => {
                        dispatch(IsToastOpenOrClose(false));
                        setTask_type(
                          task.is_requested && task.is_requested == 1
                            ? "request-task"
                            : "task"
                        );
                      }}
                    >
                      <DotsThreeVertical
                        size={18}
                        weight="light"
                        className="c-icons"
                      />
                    </a>
                    {/* {task && parseInt(task.is_allowed_to_delete) == 1 ? ( */}
                    <DeleteDropdown
                      edit={false}
                      widthClass="w-140"
                      // handlePin={pinTask}
                      handleDelete={() => {
                        resetToast();
                        dispatch(IsToastOpenOrClose(true));
                        // setToastToggle(false)

                        setShowToast(false);

                        let deleteConfirmationText =
                          task && task.request_task_id > 0
                            ? task.is_requested == 1
                              ? _l("l_and_converted_task")
                              : _l("l_and_request_task")
                            : "";
                        setToastHeader(_l("l_are_you_sure"));
                        setToastClass("toast-with-btn");
                        setToastMessage(
                          _l("l_you_want_tot_delete_this_task") +
                            deleteConfirmationText +
                            " ?"
                        );
                        setConfirmButtonText(_l("l_delete"));
                        setCancelButtonText(_l("l_cancel"));
                        setShowToast(true);
                        setRequestTaskId(
                          task &&
                            task.request_task_id &&
                            task.request_task_id != 0
                            ? task.request_task_id
                            : 0
                        );
                      }}
                      docType={task_type}
                      task={task}
                      pageName="task"
                      contacts={contacts}
                      allowToShare={true}
                      allowToCall={task.addedfrom > 0 ? true : false}
                      makeCall={makeCall}
                      taskUserAssignees={taskUserAssignees}
                      callListPos={"left"}
                    />
                  </div>
                )}
              </div>
              <ReactTooltip />
            </div>
          </div>
        </div>
      ) : (
        <>
          {/* chaiAI view start */}
          {fromReply ? (
            <div
              className="task-list-card list-view no-hover bg-transparent border-0 for-disabled p-0 "
              onClick={() => {
                dispatch(commanoffcanvasisOpen(true));
                jquery(".ai-chat-header").removeClass("mt-10");
                if (fromReply) {
                  let element = document.getElementById("task_" + task.id);
                  if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                    setTimeout(() => {
                      jquery(".ai-chat-header").addClass("mt-10");
                    }, 500);
                  }
                }
                handler(task.rel_id, task.id, true, null, task.request_task_id);
              }}
            >
              <div className="d-none">
                <ArrowBendDownLeft
                  size={18}
                  className="c-icons m-0 mb-1"
                  weight="light"
                />
              </div>
              <div className="task-list-wrapper comman_action_icon base-body-color p-2">
                <div className=" d-flex  w-100 align-items-center">
                  <div className="task-creator-img">
                    <div className="comman-image-box h32w32 rounded-circle">
                      <div
                        className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                        style={{
                          backgroundImage: `url(${task.creator_profile_image})`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="task-list-name text-start text-truncate py-0 c-font f-14imp">
                    {task.name}
                  </div>
                </div>
                <div className="d-flex flex-wrap align-items-center task-bottom-part-full pt-2">
                  <div className="d-flex align-items-center">
                    <div className="bg-white-05 c-font custom-datepicker datepicker-input h32w40 m-auto ms-1 w-90  calendarpadding custom-datepicker d-flex flex-column   h32wauto justify-content-center me-2 text-truncate">
                      <div className="d-flex align-items-center py-1">
                        <CalendarBlank
                          weight="light"
                          className="c-icons"
                          height={18}
                          width={18}
                        />
                        <div className="">
                          <DatePicker
                            portalId="custom_datepicker"
                            calendarClassName="custom-datepicker w-90"
                            timeCaption={getDatePickerTimeCaption()}
                            selected={
                              task.schedule_id > 0 ||
                              task.rel_type == "equipment" ||
                              task.is_requested == 1
                                ? taskStartDate
                                : taskDueDate
                            }
                            calendarStartDay={1}
                            locale={getDatePickerLocale()}
                            className="close-toast"
                            placeholderText={`${
                              task.schedule_id > 0 ||
                              task.rel_type == "equipment" ||
                              task.is_requested == 1
                                ? _l("l_startdate")
                                : _l("l_duedate")
                            }`}
                            onChange={(date) => {
                              if (showStartDate) {
                                setTaskStartDate(date);
                                handleUpdateDate(date);
                              } else {
                                setTaskDueDate(date);
                                handleUpdateDate("", date);
                              }
                            }}
                            showTimeSelect
                            dateFormat="dd/MM/yyyy"
                            readOnly={
                              task.is_requested === "1"
                                ? true
                                : task.is_allowed_to_edit == 1
                                ? false
                                : true
                            }
                          >
                            <div className="datepicker-label">
                              {_l("l_select_date_and_time")}
                            </div>
                          </DatePicker>
                        </div>
                        {/* <span className="c-font f-13">11/10/2023</span> */}
                      </div>
                    </div>
                    <div className="white-border-box d-flex align-items-center action_icon h32w32 with_bg me-2">
                      <a
                        href="#/"
                        className={`priority-checkbox text-truncate`}
                      >
                        <div className="check_box">
                          {taskPriority == 1 ? (
                            <HighPriorityActiveIcon className="HW18 check_span_img active" />
                          ) : (
                            <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                          )}
                        </div>
                      </a>
                    </div>
                    <div className="white-border-box d-flex align-items-center action_icon h32w32 with_bg me-2">
                      <a href="#/" className={``}>
                        <div className={`  m-auto `}>
                          {isTaskPinned && isTaskPinned == true ? (
                            <div className="m-auto ">
                              <PushPin
                                size={16}
                                weight="fill"
                                className="Push-Pin-fill"
                              />
                            </div>
                          ) : (
                            <div className="m-auto">
                              <PushPin
                                size={16}
                                weight="light"
                                className="c-icons"
                              />
                            </div>
                          )}
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div
                      className={`white-border-box action_icon h32wauto with_bg me-2 p-2 `}
                    >
                      <a
                        href="#/"
                        className="d-flex align-items-center dropdown dropdown-center"
                      >
                        <span
                          className={`c-dots c-10 rounded-circle ${
                            task.is_requested == 1
                              ? "bg-warning"
                              : task.status == 1
                              ? "in-progress-white"
                              : task.status == 4
                              ? "in-progress-blue"
                              : "done"
                          }`}
                        ></span>
                        {/* <span className="c-font f-11 text-uppercase ps-2">{_l(task.is_requested == 1 ? "l_request"
                              : task.status == 1
                                ? "l_not_started"
                                : task.status == 4
                                  ? "l_inprogress"
                                  : "l_done")}</span> */}
                      </a>
                    </div>
                    <div
                      className={`dropdown-center multi_inner_wrapper with-min-width ps-0 pe-2 d-flex with_minus ${
                        taskAssigneePills && taskAssigneePills.length >= 1
                          ? ""
                          : "d-none"
                      }`}
                    >
                      {taskAssigneePills && taskAssigneePills.length >= 1 ? (
                        taskAssigneePills.map((assignee, index) => {
                          if (index < 1)
                            return (
                              <div
                                className="badge rounded-pill text-nowrap list-toggle-view-none me-1 m-w80px text-truncate"
                                key={index}
                              >
                                <span className={` p-0 `}>
                                  {assignee.main_key == "my_task" ? "#" : ""}
                                  {_l(assignee.name)}
                                </span>
                                <ReactTooltip />
                              </div>
                            );
                        })
                      ) : (
                        <></>
                      )}
                      {taskAssigneePills && taskAssigneePills.length > 1 ? (
                        <a
                          href="#/"
                          className="badge rounded-pill text-nowrap list-toggle-view-none me-1 "
                          // id="TaskAssigneeDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-tip={`${_l("l_view_all")}`}
                          data-effect="solid"
                          data-delay-show="1000"
                          data-class="tooltip-main"
                          onClick={handleTaskAssigneeData}
                        >
                          <div className="unread_count m-auto c-font f-10">
                            +{taskAssigneePills.length - 1}
                          </div>
                        </a>
                      ) : (
                        <></>
                      )}
                      {taskAssigneePills && taskAssigneePills.length ? (
                        <GeneralListing
                          handler={() => {}}
                          list={
                            taskAssigneePills &&
                            taskAssigneePills.length &&
                            taskAssigneePills.map((item, index) => {
                              return {
                                name: item.name,
                                id: index,
                              };
                            })
                          }
                          isHash={true}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <div
                      className={`dropdown-center multi_inner_wrapper with-min-width d-flex with_minus before-dnone ${
                        pageName == "ChatAi" || globalView == "AichatView"
                          ? "before-block"
                          : ""
                      }  ${
                        taskAllAssignee && taskAllAssignee.length >= 1
                          ? ""
                          : "d-none"
                      }`}
                    >
                      {taskAllAssignee && taskAllAssignee.length >= 1 ? (
                        taskAllAssignee.map((assignee, index) => {
                          if (index < 1)
                            return (
                              <a
                                href="#/"
                                data-bs-toggle="tooltip"
                                className="comman-image-box multi-wrapper-border h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                                data-tip={assignee.name || assignee.full_name}
                                data-place="right"
                                data-effect="solid"
                                data-delay-show="1000"
                                data-class="tooltip-main"
                                key={index}
                              >
                                <div
                                  className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                  style={{
                                    backgroundImage: `url('${
                                      assignee.user_image ||
                                      assignee.profile_image
                                    }')`,
                                  }}
                                ></div>
                                <ReactTooltip />
                              </a>
                            );
                        })
                      ) : (
                        <></>
                      )}
                      {taskAllAssignee && taskAllAssignee.length > 1 ? (
                        <a
                          href="#/"
                          className="comman-image-box h32w32 with_overlay d-flex rounded-circle dropdown-center"
                          // id="TaskAssigneeDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-tip={`${_l("l_view_all")}`}
                          data-effect="solid"
                          data-delay-show="1000"
                          data-class="tooltip-main"
                          onClick={handleTaskAssigneeData}
                        >
                          <div className="unread_count m-auto c-font f-12">
                            +{taskAllAssignee.length - 1}
                          </div>
                        </a>
                      ) : (
                        <></>
                      )}
                      {taskAllAssignee && taskAllAssignee.length ? (
                        <GeneralListing
                          handler={() => {}}
                          list={
                            taskAllAssignee &&
                            taskAllAssignee.length &&
                            taskAllAssignee.map((item, index) => {
                              return {
                                name: item.full_name || item.name,
                                image: item.user_image || item.profile_image,
                                id: item.id,
                              };
                            })
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div
                className={` ${
                  fromReply
                    ? " align-items-center base-body-color justify-content-between overflow-hidden radius_3 fst-italic"
                    : "w100minus30 chat-msg"
                }`}
              >
                <div
                  className={`chat-text-box with-media with-task-card ${
                    fromReply
                      ? "for-disabled base-body-color max-w320 w-auto"
                      : ""
                  } ${
                    pageName == "ChatAi" || globalView == "AichatView"
                      ? "p-2 pb-0 w-100 "
                      : ""
                  } ${
                    moment(taskTime).isAfter(moment(currentTime))
                      ? "opacity-50"
                      : "opacity-100"
                  } ${active ? "active z-index-7" : ""}`}
                >
                  <div
                    className={`task-list-card list-view ${
                      pageName == "ChatAi" || globalView == "AichatView"
                        ? "aichatlist d-inline"
                        : ""
                    } `}
                  >
                    <div className="task-list-wrapper">
                      <div className="d-flex w-100 align-items-start">
                        <div className="d-flex task-list-detail">
                          <div className="task-left flex-grow-1 width100minus160">
                            <div className="d-flex align-items-center w-100 task-left-part-toggle">
                              {hideclass ? (
                                <></>
                              ) : (
                                <div className="task-creator-img">
                                  <div
                                    className={`h20w20 d-flex align-items-center me-2`}
                                  >
                                    <Form.Check
                                      type="checkbox"
                                      className="m-auto"
                                    />
                                  </div>
                                </div>
                              )}
                              <div
                                className="task-top-part text-truncate flex-grow-1 d-flex main-calc-width-task"
                                data-tip={`#${task.id} - ${task.name}`}
                                data-effect="solid"
                                data-delay-show="1000"
                                data-class="tooltip-main"
                                onClick={() => {
                                  dispatch(commanoffcanvasisOpen(true));
                                  handler(
                                    task.rel_id,
                                    task.id,
                                    true,
                                    null,
                                    task.request_task_id
                                  );
                                  // getTask(task.id, is_requested, task.provider);
                                }}
                              >
                                <div className=" d-flex  w-100 align-items-center">
                                  <div className="task-creator-img">
                                    <div className="comman-image-box h32w32 rounded-circle charcirclenewcolor">
                                      <div
                                        className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                        style={{
                                          backgroundImage: `url(${task.creator_profile_image})`,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                  <a
                                    href="#/"
                                    className={`task-list-name text-start text-truncate ${
                                      pageName == "ChatAi" ||
                                      globalView == "AichatView"
                                        ? "py-0 c-font f-14imp"
                                        : ""
                                    } `}
                                  >
                                    {task.name}
                                      </a>
                                </div>
                              </div>
                              {hideclass ? (
                                <></>
                              ) : (
                                <div className="task-mid-part w-460 d-flex res-flex-wrap-nowrap align-items-center  for-content-between comman_action_icon justify-content-end">
                                  <div className="d-flex align-items-center justify-content-end res-space-start res-width-100">
                                    <div class="title-fonts text-truncate pill-bottom-show color-white-60 pe-2">
                                      <span class="me-1 ps-0">
                                        <span class="c-font f-13 color-green">
                                          1
                                        </span>
                                        <span class="color-white-60 c-font f-10">
                                          Day
                                        </span>
                                      </span>
                                      <span class="me-1 ps-0">
                                        <span class="c-font f-13 color-green">
                                          16
                                        </span>
                                        <span class="color-white-60 c-font f-10">
                                          Hrs
                                        </span>
                                      </span>
                                      <span class="me-1 ps-0">
                                        <span class="c-font f-13 color-green">
                                          33
                                        </span>
                                        <span class="color-white-60 c-font f-10">
                                          Min
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div
                                className={`d-flex align-items-center task-bottom-part-full ${
                                  pageName == "ChatAi" ||
                                  globalView == "AichatView"
                                    ? "pt-2"
                                    : ""
                                }`}
                              >
                                <div className="task-right-static-options">
                                  <div className="d-flex align-items-center">
                                    {hideclass ? (
                                      <></>
                                    ) : (
                                      <a
                                        href="#/"
                                        className="me-2 comman_action_icon"
                                      >
                                        <div
                                          className={`action_icon with_bg on-hover-active-toggle-img`}
                                        >
                                          <img
                                            alt="logo"
                                            src={LeftPanelLogo}
                                            className="header-logo-img h20w20"
                                          />
                                        </div>
                                      </a>
                                    )}
                                    <div className="d-flex align-items-center multi_inner_wrapper justify-content-end w-120 w-auto ">
                                      <div
                                        className={`position-relative d-flex assignee-list-wrapper card-view-less-padding`}
                                      >
                                        <div className="dropdown-center multi_inner_wrapper with-min-width d-flex with_minus  ">
                                          <>
                                            {task.schedule_id > 0 &&
                                            taskProviderTitle &&
                                            taskProviderTitle != "" ? (
                                              <div className="badge rounded-pill text-nowrap list-toggle-view-none m-w80px text-truncate">
                                                <span className={` p-0 `}>
                                                  {"#"}
                                                  {taskProviderTitle}
                                                </span>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                            {task.rel_type == "equipment" ? (
                                              <>
                                                {task.surface > 0 ? (
                                                  <div className="badge rounded-pill text-nowrap list-toggle-view-none me-1 m-w80px text-truncate">
                                                    <span className={` p-0 `}>
                                                      {task.surface}m
                                                      <sup>2</sup>
                                                    </span>
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                                {task.duration > 0 ? (
                                                  <div className="badge rounded-pill text-nowrap list-toggle-view-none me-1 m-w80px text-truncate">
                                                    <span className={` p-0`}>
                                                      {task.duration}hrs
                                                    </span>
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            ) : (
                                              <></>
                                            )}

                                            <div
                                              className={`position-relative d-flex assignee-list-wrapper card-view-less-padding`}
                                            >
                                              <div
                                                className={`dropdown-center multi_inner_wrapper with-min-width pe-2 d-flex with_separator_10 with_minus ${
                                                  taskAssigneePills &&
                                                  taskAssigneePills.length >= 1
                                                    ? ""
                                                    : "d-none"
                                                }`}
                                              >
                                                {taskAssigneePills &&
                                                taskAssigneePills.length >=
                                                  1 ? (
                                                  taskAssigneePills.map(
                                                    (assignee, index) => {
                                                      if (index < 1)
                                                        return (
                                                          <div
                                                            className="badge rounded-pill text-nowrap list-toggle-view-none me-1 m-w80px text-truncate"
                                                            key={index}
                                                            data-tip={`${_l(
                                                              assignee.name
                                                            )}`}
                                                            data-effect="solid"
                                                            data-delay-show="1000"
                                                            data-class="tooltip-main"
                                                          >
                                                            <span
                                                              className={` p-0 `}
                                                            >
                                                              {assignee.main_key ==
                                                              "my_task"
                                                                ? "#"
                                                                : ""}
                                                              {_l(
                                                                assignee.name
                                                              )}
                                                            </span>
                                                            <ReactTooltip />
                                                          </div>
                                                        );
                                                    }
                                                  )
                                                ) : (
                                                  <></>
                                                )}
                                                {taskAssigneePills &&
                                                taskAssigneePills.length > 1 ? (
                                                  <a
                                                    href="#/"
                                                    className="badge rounded-pill text-nowrap list-toggle-view-none me-1 "
                                                    // id="TaskAssigneeDropdown"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                    data-tip={`${_l(
                                                      "l_view_all"
                                                    )}`}
                                                    data-effect="solid"
                                                    data-delay-show="1000"
                                                    data-class="tooltip-main"
                                                    onClick={
                                                      handleTaskAssigneeData
                                                    }
                                                  >
                                                    <div className="unread_count m-auto c-font f-10">
                                                      +
                                                      {taskAssigneePills.length -
                                                        1}
                                                    </div>
                                                  </a>
                                                ) : (
                                                  <></>
                                                )}
                                                {taskAssigneePills &&
                                                taskAssigneePills.length ? (
                                                  <GeneralListing
                                                    handler={() => {}}
                                                    list={
                                                      taskAssigneePills &&
                                                      taskAssigneePills.length &&
                                                      taskAssigneePills.map(
                                                        (item, index) => {
                                                          return {
                                                            name: item.name,
                                                            id: index,
                                                          };
                                                        }
                                                      )
                                                    }
                                                    isHash={true}
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                              </div>
                                              <div
                                                className={`dropdown-center multi_inner_wrapper with-min-width d-flex with_separator_10 with_minus before-dnone ${
                                                  pageName == "ChatAi" ||
                                                  globalView == "AichatView"
                                                    ? "before-block"
                                                    : ""
                                                }  ${
                                                  taskAllAssignee &&
                                                  taskAllAssignee.length >= 1
                                                    ? ""
                                                    : "d-none"
                                                }`}
                                              >
                                                {taskAllAssignee &&
                                                taskAllAssignee.length >= 1 ? (
                                                  taskAllAssignee.map(
                                                    (assignee, index) => {
                                                      if (index < 1)
                                                        return (
                                                          <a
                                                            href="#/"
                                                            data-bs-toggle="tooltip"
                                                            className="comman-image-box multi-wrapper-border h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                                                            data-tip={
                                                              assignee.name ||
                                                              assignee.full_name
                                                            }
                                                            data-place="right"
                                                            data-effect="solid"
                                                            data-delay-show="1000"
                                                            data-class="tooltip-main"
                                                            key={index}
                                                          >
                                                            <div
                                                              className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                                              style={{
                                                                backgroundImage: `url('${
                                                                  assignee.user_image ||
                                                                  assignee.profile_image
                                                                }')`,
                                                              }}
                                                            ></div>
                                                            <ReactTooltip />
                                                          </a>
                                                        );
                                                    }
                                                  )
                                                ) : (
                                                  <></>
                                                )}
                                                {taskAllAssignee &&
                                                taskAllAssignee.length > 1 ? (
                                                  <a
                                                    href="#/"
                                                    className="comman-image-box h32w32 with_overlay d-flex rounded-circle dropdown-center"
                                                    // id="TaskAssigneeDropdown"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                    data-tip={`${_l(
                                                      "l_view_all"
                                                    )}`}
                                                    data-effect="solid"
                                                    data-delay-show="1000"
                                                    data-class="tooltip-main"
                                                    onClick={
                                                      handleTaskAssigneeData
                                                    }
                                                  >
                                                    <div className="unread_count m-auto c-font f-12">
                                                      +
                                                      {taskAllAssignee.length -
                                                        1}
                                                    </div>
                                                  </a>
                                                ) : (
                                                  <></>
                                                )}
                                                {taskAllAssignee &&
                                                taskAllAssignee.length ? (
                                                  <GeneralListing
                                                    handler={() => {}}
                                                    list={
                                                      taskAllAssignee &&
                                                      taskAllAssignee.length &&
                                                      taskAllAssignee.map(
                                                        (item, index) => {
                                                          return {
                                                            name:
                                                              item.full_name ||
                                                              item.name,
                                                            image:
                                                              item.user_image ||
                                                              item.profile_image,
                                                            id: item.id,
                                                          };
                                                        }
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                              </div>
                                              <ReactTooltip />
                                            </div>
                                          </>
                                        </div>
                                      </div>
                                      <div
                                        className={`comman_action_icon card-view-none  ps-0  ${
                                          pageName == "ChatAi" ||
                                          globalView == "AichatView"
                                            ? ""
                                            : "with_separator_10"
                                        } ${
                                          task.schedule_id == 0 &&
                                          task.is_allowed_to_edit > 0
                                            ? ""
                                            : task.schedule_id > 0 &&
                                              userType == "operator" &&
                                              contact_role != "3"
                                            ? ""
                                            : "for-disabled"
                                        }`}
                                      >
                                        <div className="d-flex align-items-center">
                                          <div className="dropup dropup-center">
                                            <div className="w-200 dropdown dropend">
                                              <UncontrolledDropdown
                                                setActiveFromChild={true}
                                                isOpen={openAssigneeDropdown}
                                                direction="end"
                                                className="w-200"
                                                data-tip={`${_l(
                                                  "l_add_assignee"
                                                )}`}
                                                data-effect="solid"
                                                data-delay-show="1000"
                                                data-class="tooltip-main"
                                                data-place="top"
                                              >
                                                <DropdownToggle
                                                  id="AddAssigneeMainDropdownNew"
                                                  className="border-0  ms-10px p-0"
                                                  onClick={() =>
                                                    setOpenAssigneeDropdown(
                                                      !openAssigneeDropdown
                                                    )
                                                  }
                                                >
                                                  <div className="action_icon with_bg on-hover-active-toggle-img light-theme-img h32w32">
                                                    <UserCircleGear
                                                      size={18}
                                                      weight="light"
                                                      className="c-icons"
                                                    />
                                                  </div>
                                                </DropdownToggle>
                                                {/* {
                                  openAssigneeDropdown ? */}
                                                {openAssigneeDropdown ==
                                                true ? (
                                                  <AddAssigneeMainDropdownNew
                                                    key={task.id}
                                                    projectId={project_id}
                                                    taskId={task.id}
                                                    openAssigneeDropdown={
                                                      openAssigneeDropdown
                                                    }
                                                    setOpenAssigneeDropdown={
                                                      setOpenAssigneeDropdown
                                                    }
                                                    fromTaskcard="1"
                                                    selectedTagId={
                                                      selectedTagId
                                                    }
                                                    pageName="task"
                                                    selectedId={
                                                      // task && task.is_requested == 1
                                                      //   ? task.assignees_type &&
                                                      //     task.assignees_type.assginee_type
                                                      //     ? [
                                                      //         {
                                                      //           id: task.assignees_type
                                                      //             .assginee_id,
                                                      //           name: task.assignees_type
                                                      //             .assginee_type,
                                                      //         },
                                                      //       ]
                                                      //     : []
                                                      // :
                                                      selectedTaskAssigneesId
                                                    }
                                                    isRequest={
                                                      task.is_requested == 1
                                                    }
                                                    saveBtnToggle={true}
                                                    selectedAssignee={
                                                      selectedAssigneeNew
                                                    }
                                                    setSelectedAssignee={
                                                      setSelectedAssigneeNew
                                                    }
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                                {/* :
                                  <></>
                                  } */}
                                              </UncontrolledDropdown>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-none dropdown">
                                      <UncontrolledDropdown className=" d-none">
                                        <DropdownToggle
                                          tag="div"
                                          className="action_icon with_bg on-hover-active-toggle-img light-theme-img"
                                          type="button"
                                          data-tip={_l("l_task_tracking")}
                                          data-effect="solid"
                                          data-delay-show="1000"
                                          data-class="tooltip-main"
                                          data-place="top"
                                        >
                                          <ImageMapPinIcon className="HW18 with-path-stroke" />
                                        </DropdownToggle>
                                        <DropdownItem
                                          className="p-0 no-hover"
                                          tag="div"
                                        >
                                          {/* <TrackingDropdown /> */}
                                        </DropdownItem>
                                      </UncontrolledDropdown>
                                    </div>
                                    <div
                                      className={` white-border-box p-0 d-flex list-toggle-view-box position-relative ${
                                        commanoffcanvasIsOpen
                                          ? "bg-white-05 border-0 h32w32"
                                          : "h32wauto"
                                      }`}
                                    >
                                      <a
                                        href="#/"
                                        className={`custom-datepicker datepicker-input w-90 m-auto ms-1 c-font ${
                                          fromReply ? "fst-italic" : ""
                                        } ${
                                          commanoffcanvasIsOpen
                                            ? "withinputabsolute bg-white-05"
                                            : ""
                                        }`}
                                        data-bs-toggle="tooltip"
                                        data-tip={`${
                                          task.schedule_id > 0 ||
                                          task.rel_type == "equipment" ||
                                          task.is_requested == 1
                                            ? moment(taskStartDate).format(
                                                "DD/MM/YYYY"
                                              )
                                            : moment(taskDueDate).format(
                                                "DD/MM/YYYY"
                                              )
                                        }`}
                                        data-effect="solid"
                                        data-delay-show="1000"
                                        data-class="tooltip-main"
                                      >
                                        <div className="d-flex align-items-center">
                                          <div className="h20w20">
                                            <CalendarBlank
                                              size={18}
                                              weight="light"
                                              className="c-icons"
                                            />
                                          </div>
                                          <DatePicker
                                            portalId="custom_datepicker"
                                            calendarClassName="custom-datepicker"
                                            timeCaption={getDatePickerTimeCaption()}
                                            selected={
                                              task.schedule_id > 0 ||
                                              task.rel_type == "equipment" ||
                                              task.is_requested == 1
                                                ? taskStartDate
                                                : taskDueDate
                                            }
                                            calendarStartDay={1}
                                            locale={getDatePickerLocale()}
                                            className="close-toast"
                                            placeholderText={`${
                                              task.schedule_id > 0 ||
                                              task.rel_type == "equipment" ||
                                              task.is_requested == 1
                                                ? _l("l_startdate")
                                                : _l("l_duedate")
                                            }`}
                                            onChange={(date) => {
                                              if (showStartDate) {
                                                setTaskStartDate(date);
                                                handleUpdateDate(date);
                                              } else {
                                                setTaskDueDate(date);
                                                handleUpdateDate("", date);
                                              }
                                            }}
                                            showTimeSelect
                                            dateFormat="dd/MM/yyyy hh:mm a"
                                            readOnly={
                                              task.is_requested === "1"
                                                ? true
                                                : task.is_allowed_to_edit == 1
                                                ? false
                                                : true
                                            }
                                          >
                                            <div className="datepicker-label">
                                              {_l("l_select_date_and_time")}
                                            </div>
                                          </DatePicker>
                                          {/* <span className="color-white-60 d-none">Aug, 8 2022</span> */}
                                        </div>
                                      </a>
                                    </div>
                                    <ReactTooltip/>
                                    <div
                                      className={` dropdown dropdown-center comman_action_icon card-view-less-padding p-0 ms-10px ${
                                        task &&
                                        !(
                                          userType == "operator" &&
                                          task.schedule_id > 0
                                        ) &&
                                        ((parseInt(task.is_allowed_to_edit) >
                                          0 &&
                                          task.is_requested == 0) ||
                                          (parseInt(task.request_task_id) ==
                                            0 &&
                                            task.is_requested == 1))
                                          ? ""
                                          : "for-disabled"
                                      }`}
                                    >
                                      <div
                                        className={`white-border-box me-0 color-white-60 action_icon with_bg  d-flex close-toast h32w32`}
                                        type="button"
                                        id="TaskListStatusDropdown"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        data-tip={`${_l("l_status")}`}
                                        data-effect="solid"
                                        data-delay-show="1000"
                                        data-class="tooltip-main"
                                      >
                                        <div className="d-flex align-items-center m-auto">
                                          <span
                                            className={`c-dots c-10 ${
                                              task.is_requested == 1
                                                ? "bg-warning"
                                                : task.status == 1
                                                ? "in-progress-white"
                                                : task.status == 4
                                                ? "in-progress-blue"
                                                : "done"
                                            } rounded-circle`}
                                          ></span>
                                          <span className="d-none">
                                            {taskStatus == 1
                                              ? "Not Started"
                                              : taskStatus == 4
                                              ? "In Progress"
                                              : "Done"}
                                          </span>
                                          <CaretDown
                                            size={14}
                                            weight="light"
                                            className="c-icons d-none"
                                          />
                                          {(task &&
                                            parseInt(task.is_allowed_to_edit) >
                                              0 &&
                                            task.is_requested == 0) ||
                                          (task &&
                                            parseInt(task.request_task_id) ==
                                              0 &&
                                            task.is_requested == 1) ? (
                                            <TaskListStatusDropdown
                                              setTaskStatus={changeTaskStatus}
                                              is_task={1}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={`${
                                        isLiteVersion ? " " : ""
                                      } comman_action_icon dropdown-center dropdown ms-10px text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding close-toast`}
                                    >
                                      <UncontrolledDropdown className="w-100">
                                        <DropdownToggle
                                          data-toggle="dropdown"
                                          tag="span"
                                          className="comman_action_icon w-100 d-flex close-toast border-0 ps-0"
                                        >
                                          <a
                                            href="#/"
                                            data-tip={_l("l_comments")}
                                            data-effect="solid"
                                            data-delay-show="1000"
                                            data-class="tooltip-main"
                                            onClick={(e) => {
                                              setOpenCommentTab(true);
                                              getMentionlist(task.id);
                                              getTaskComments();
                                            }}
                                          >
                                            <div className="d-flex align-items-center w-100">
                                              <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                                                <ChatCircle
                                                  size={18}
                                                  weight="light"
                                                  className="c-icons"
                                                />
                                                <span
                                                  className={`rounded-pill badge-custom p-0 ${
                                                    unreadCommentCount.current ==
                                                      0 ||
                                                    unreadCommentCount.current ==
                                                      undefined ||
                                                    unreadCommentCount.current ==
                                                      null ||
                                                    unreadCommentCount.current ==
                                                      NaN
                                                      ? "d-none"
                                                      : ""
                                                  }`}
                                                  data-unread-cmt-id={task.id}
                                                >
                                                  {unreadCommentCount.current}
                                                </span>
                                              </div>
                                              {/* {isLiteVersion ? (<></>) : ( */}
                                              <span
                                                className="c-font f-12 d-flex justify-content-center flex-grow-1 number-counter-toggle"
                                                data-cmt-cnt-id={task.id}
                                              >
                                                {Number(updatedCommentCount) > 0
                                                  ? updatedCommentCount
                                                  : 0}
                                              </span>
                                              {/* )} */}
                                            </div>
                                          </a>
                                        </DropdownToggle>
                                        <DropdownItem
                                          toggle={false}
                                          className="p-0"
                                        >
                                          {
                                            <CommentMinified
                                              comments={commentsList}
                                              disabled={
                                                task &&
                                                parseInt(task.request_task_id) >
                                                  0 &&
                                                task.is_requested == 1
                                                  ? true
                                                  : false
                                              }
                                              handleAddComment={
                                                handleAddEditComment
                                              }
                                              deleteComment={
                                                handleDeleteComment
                                              }
                                              id={task.id}
                                              task={task}
                                              loading={commentsLoading}
                                              mentionList={mentionsList.map(
                                                (name) => {
                                                  let id =
                                                    name.is_team &&
                                                    name.is_team == 1
                                                      ? "-" + name.id
                                                      : name.id;
                                                  return {
                                                    display: name.name,
                                                    id: id,
                                                    image:
                                                      name.profile_image || "",
                                                    isteam: name.is_team
                                                      ? 1
                                                      : 0,
                                                  };
                                                }
                                              )}
                                              handleImagePreview={
                                                handleImagePreview
                                              }
                                              unContolList={true}
                                              totalUnreadCommentCount={
                                                task.unread_comment_count
                                              }
                                              showComments={showComments}
                                              fileLoader={fileUploadLoader}
                                            />
                                          }
                                        </DropdownItem>
                                      </UncontrolledDropdown>
                                    </div>
                                    <div className="comman_action_icon card-view-less-padding p-0 ms-10px">
                                      <div
                                        className={`priority-checkbox span-13 white-border-box action_icon with_bg close-toast h32w32`}
                                        data-tip={""}
                                        data-effect="solid"
                                        data-delay-show="1000"
                                        data-class="tooltip-main"
                                      >
                                        <input
                                          type="checkbox"
                                          id="priority"
                                          className="absolute-input"
                                          data-tip={
                                            taskPriority == 3
                                              ? _l("l_high_priority")
                                              : _l("l_priority")
                                          }
                                          data-effect="solid"
                                          data-delay-show="1000"
                                          data-class="tooltip-main"
                                          checked={
                                            taskPriority == 3 ? true : false
                                          }
                                          onChange={(e) => {
                                            changeTaskPriority(e);
                                          }}
                                        />
                                        <div className="check_box">
                                          <HighPriorityActiveIcon className="HW18 check_span_img active" />
                                          <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                                        </div>
                                      </div>
                                    </div>
                                    {hideclass ? (
                                      <></>
                                    ) : (
                                      <div className="progress_wrapper">
                                        <div className="w-185 dropdown dropdown-center  ">
                                          <div
                                            className="d-flex dropdown dropdown-center align-items-center table-list-progress d-flex align-items-center justify-content-end  card-view-less-padding with_separator_10 px-0 comman_action_icon close-toast"
                                            type="button"
                                            id="checkedDropdown"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="outside"
                                            data-bs-auto-close="outside"
                                            data-tip={""}
                                            data-effect="solid"
                                            data-delay-show="1000"
                                            data-class="tooltip-main"
                                          >
                                            <div className="">
                                              <a
                                                href="#/"
                                                className="before-dnone comman_action_icon pe-0 with_separator_10 card-view-none d-none"
                                              >
                                                <div className="d-flex align-items-center">
                                                  <div className="action_icon with_bg rounded-circle on-hover-active-toggle-img light-theme-img">
                                                    <Plus
                                                      size={14}
                                                      weight="light"
                                                      className="c-icons"
                                                    />
                                                  </div>
                                                </div>
                                              </a>
                                            </div>
                                            <div className="dropdown dropdown-center progress-wrapper-minus d-flex ">
                                              <a
                                                href="#/"
                                                className="progress-wrapper-link"
                                                // type="button"
                                                // id="checkedDropdown"
                                                // data-bs-toggle="dropdown"
                                                // aria-expanded="false"
                                              >
                                                <div className="progress-slider with_separator_10 before-dnone card-view-less-padding">
                                                  <div
                                                    className="d-flex align-items-center"
                                                    // onClick={(e) => setOpenCheckList(true)}
                                                  >
                                                    <div className="progress w_100">
                                                      <div
                                                        className="progress-bar"
                                                        style={{ width: "15%" }}
                                                      ></div>
                                                    </div>
                                                    <div className="progress-text c-font f-12 title-fonts">
                                                      15%
                                                    </div>
                                                  </div>
                                                </div>
                                              </a>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="w_172 d-flex align-items-center justify-content-end table-list-attechment ">
                                          <div className="dropdown-center dropdown with_separator_10 d-flex align-items-center card-view-less-padding">
                                            <UncontrolledDropdown className="w-100">
                                              <DropdownToggle
                                                data-toggle="dropdown"
                                                tag="span"
                                                className="comman_action_icon w-100 d-flex close-toast border-0"
                                              >
                                                <a
                                                  href="#/"
                                                  data-tip={_l("l_attachments")}
                                                  data-effect="solid"
                                                  data-delay-show="1000"
                                                  data-class="tooltip-main"
                                                >
                                                  <div className="d-flex align-items-center w-100">
                                                    <div className="action_icon with_bg on-hover-active-toggle-img light-theme-img">
                                                      <Image
                                                        size={16}
                                                        weight="light"
                                                        className="c-icons"
                                                      />
                                                    </div>
                                                    <span className="c-font f-12 d-flex justify-content-center flex-grow-1 text-on-hover-active-green number-counter-toggle">
                                                      5
                                                    </span>
                                                  </div>
                                                </a>
                                              </DropdownToggle>
                                              {/* <ImageUploadDropdown/> */}
                                            </UncontrolledDropdown>
                                          </div>
                                          <div className=" dropdown-center dropdown with_separator_10 d-flex align-items-center card-view-less-padding close-toast">
                                            <UncontrolledDropdown className="w-100">
                                              <DropdownToggle
                                                data-toggle="dropdown"
                                                tag="span"
                                                className="comman_action_icon w-100 d-flex close-toast border-0"
                                              >
                                                <a
                                                  href="#/"
                                                  data-tip={_l("l_comments")}
                                                  data-effect="solid"
                                                  data-delay-show="1000"
                                                  data-class="tooltip-main"
                                                >
                                                  <div className="d-flex align-items-center w-100">
                                                    <div className="action_icon with_bg position-relative on-hover-active-toggle-img light-theme-img">
                                                      <ChatCircle
                                                        size={16}
                                                        weight="light"
                                                        className="c-icons"
                                                      />
                                                      <span
                                                        className={`rounded-pill badge-custom p-0`}
                                                      >
                                                        5
                                                      </span>
                                                    </div>

                                                    <span className="c-font f-12 d-flex justify-content-center flex-grow-1 text-on-hover-active-green number-counter-toggle">
                                                      5
                                                    </span>
                                                  </div>
                                                </a>
                                              </DropdownToggle>
                                              <DropdownItem
                                                toggle={false}
                                                className="p-0"
                                              >
                                                {/* <CommentMinified/> */}
                                              </DropdownItem>
                                            </UncontrolledDropdown>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="task-assign-section">
                          {task.parent_id > 0 ? 
                                      <div className="badge rounded-pill text-nowrap list-toggle-view-none me-1 mb-10px">
                                        <span className=" p-0 ">{_l("l_ai_task")}</span>
                                      </div> : <></>}
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="before-dnone comman_action_icon with_separator_10 ps-1 pe-0">
                                <a
                                  href="#/"
                                  className="action_icon with_bg light-theme-img close-toast h32w32"
                                  data-tip={
                                    isTaskPinned && isTaskPinned == true
                                      ? _l("l_unpin")
                                      : _l("l_pin")
                                  }
                                  data-effect="solid"
                                  data-delay-show="1000"
                                  data-class="tooltip-main"
                                >
                                  {isTaskPinned && isTaskPinned == true ? (
                                    <div
                                      className="m-auto "
                                      onClick={() => {
                                        // if (
                                        //   task &&
                                        //   parseInt(task.is_allowed_to_edit) > 0
                                        // ) {
                                        pinTask();
                                        // }
                                      }}
                                    >
                                      <PushPin
                                        size={18}
                                        className="Push-Pin-fill"
                                        weight="fill"
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className="m-auto"
                                      onClick={() => {
                                        // if (
                                        //   task &&
                                        //   parseInt(task.is_allowed_to_edit) > 0
                                        // ) {
                                        pinTask();
                                        // }
                                      }}
                                    >
                                      <PushPin
                                        size={16}
                                        weight="light"
                                        className="c-icons"
                                      />
                                    </div>
                                  )}
                                </a>
                              </div>
                              {hideclass ? (
                                <></>
                              ) : (
                                <div className="dropstart h20w20 ">
                                  <a
                                    href="#/"
                                    type="button"
                                    className={`line-height-0 light-theme-img`}
                                    id="deLeteDropdown"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    data-bs-auto-close="outside"
                                    data-tip={`${_l("l_options")}`}
                                    data-effect="solid"
                                    data-delay-show="1000"
                                    data-class="tooltip-main"
                                    data-place="left"
                                  >
                                    <DotsThreeVertical
                                      size={16}
                                      weight="light"
                                      className="c-icons"
                                    />
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {task && task.attachments && task.attachments.length > 0 ? (
                    <hr className={`w-7 my-2`} />
                  ) : (
                    <></>
                  )}
                  <div
                    className={`row image-upload-wrapper multi_inner_wrapper g-3 mb-2 px-1 ${
                      task && task.attachments && task.attachments.length > 0
                        ? ""
                        : "d-none"
                    }`}
                  >
                    {task &&
                      task.attachments &&
                      task.attachments.length > 0 &&
                      task.attachments.map((img, index) => {
                        if (index < 3) {
                          return (
                            <>
                              <div className="col-lg-3 px-1">
                                <a
                                  href="#/"
                                  className="align-items-center  comman-round-box d-flex h50px radius_3 position-relative"
                                  onClick={() => {
                                    setShowTaskImage(true);
                                    setAttachmentDetails(img);
                                  }}
                                >
                                  <div
                                    className="comman-bg-img h-100 w-100 bg-style-cover radius_3"
                                    style={{
                                      backgroundImage: `url('${img.image_url}')`,
                                    }}
                                  ></div>
                                </a>
                              </div>{" "}
                            </>
                          );
                        } else if (index == 3) {
                          return (
                            <>
                              {task.attachments.length < 5 ? (
                                <div className="col-lg-3 px-1">
                                  <a
                                    href="#/"
                                    className="align-items-center  comman-round-box d-flex h50px radius_3 position-relative "
                                    onClick={() => {
                                      setShowTaskImage(true);
                                      setAttachmentDetails(img);
                                    }}
                                  >
                                    <div
                                      className="comman-bg-img h-100 w-100 bg-style-cover radius_3"
                                      style={{
                                        backgroundImage: `url('${img.image_url}')`,
                                      }}
                                    ></div>
                                  </a>
                                </div>
                              ) : (
                                <>
                                  <div className="col-lg-3 px-1">
                                    <a
                                      href="#/"
                                      className="align-items-center  comman-round-box d-flex h50px radius_3 position-relative with-fullimg-overlay"
                                      onClick={() => {
                                        setShowTaskImage(true);
                                        setAttachmentDetails(img);
                                      }}
                                    >
                                      <div
                                        className="comman-bg-img h-100 w-100 bg-style-cover radius_3"
                                        style={{
                                          backgroundImage: `url('${img.image_url}')`,
                                        }}
                                      ></div>
                                      <div class="absolute fs-5 text-white c-font f-16 unread_count">
                                        {"+" + (task.attachments.length - 3)}
                                      </div>
                                    </a>
                                  </div>
                                </>
                              )}
                            </>
                          );
                        }
                      })}
                  </div>
                  <div className="taskListing mb-2">
                    {task &&
                      task.comments &&
                      task.comments.length > 0 &&
                      task.comments.slice(0, 4).map((comment, index) => {
                        return (
                          <>
                            <div className="d-flex align-items-center mt-2 aichatTaskListing">
                              <div className="d-flex align-items-center">
                                <div
                                  className={`c-dots c-7 rounded-circle ${
                                    task.is_requested == 1
                                      ? "bg-warning"
                                      : task.status == 1
                                      ? "in-progress-white"
                                      : task.status == 4
                                      ? "in-progress-blue"
                                      : "done"
                                  }`}
                                ></div>
                                <div className="c-font f-10 mx-2 title-fonts">
                                  {moment(comment.dateadded).format(
                                    "YYYY-MM-DD HH:mm"
                                  )}
                                </div>
                              </div>
                              <div className="c-font f-10 text-truncate w100minus100per text-start">
                                {ReactHtmlParser(
                                  comment.content &&
                                    comment.content.replace(/(<([^>]+)>)/gi, "")
                                )}
                              </div>
                              {/* <span className="c-font f-10" dangerouslySetInnerHTML={{ __html: comment.content && comment.content.replace( /(?:\r\n|\r|\n)/g, "<br>") }}></span> */}
                            </div>
                          </>
                        );
                      })}
                  </div>
                  {fromReply ? (
                    <></>
                  ) : (
                    <UncontrolledDropdown
                      setActiveFromChild={true}
                      isOpen={showOptions == task.id}
                      direction="end"
                      className={`dropdown dropdown-center chat-action-option dropup`}
                      onMouseLeave={() => {
                        setTimeout(() => {
                          setshowOptions(0);
                        }, 500);
                      }}
                    >
                      <DropdownToggle
                        id={`taskOptions`}
                        type="button"
                        className={`border-0 line-height-0  p-0 d-flex align-items-center`}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-auto-close="outside"
                        data-tip={`${_l("l_options")}`}
                        data-effect="solid"
                        data-delay-show="1000"
                        data-class="tooltip-main"
                        data-place="left"
                        onClick={() => {
                          if (showOptions == task.id) {
                            setshowOptions(0);
                          } else {
                            setshowOptions(task.id);
                          }
                          dispatch(IsToastOpenOrClose(false));
                          setTask_type(
                            task.is_requested && task.is_requested == 1
                              ? "request-task"
                              : "task"
                          );
                        }}
                      >
                        <DotsThreeVertical
                          size={16}
                          weight="light"
                          className="c-icons"
                        />
                      </DropdownToggle>
                      <DeleteDropdown
                        edit={false}
                        widthClass="w-140"
                        // handlePin={pinTask}
                        handleDelete={() => {
                          resetToast();
                          dispatch(IsToastOpenOrClose(true));
                          // setToastToggle(false)

                          setShowToast(false);

                          let deleteConfirmationText =
                            task && task.request_task_id > 0
                              ? task.is_requested == 1
                                ? _l("l_and_converted_task")
                                : _l("l_and_request_task")
                              : "";
                          setToastHeader(_l("l_are_you_sure"));
                          setToastClass("toast-with-btn");
                          setToastMessage(
                            _l("l_you_want_tot_delete_this_task") +
                              deleteConfirmationText +
                              " ?"
                          );
                          setConfirmButtonText(_l("l_delete"));
                          setCancelButtonText(_l("l_cancel"));
                          setShowToast(true);
                          setRequestTaskId(
                            task &&
                              task.request_task_id &&
                              task.request_task_id != 0
                              ? task.request_task_id
                              : 0
                          );

                          // setAttachmentId(attachment.id);
                        }}
                        // handelGoogleMeet={()=>{setMeetingModal(true)}}
                        docType={task_type}
                        task={task}
                        pageName="task"
                        contacts={contacts}
                        allowToShare={true}
                        allowToCall={task.addedfrom > 0 ? true : false}
                        makeCall={makeCall}
                        taskUserAssignees={taskUserAssignees}
                        callListPos={"left"}
                        initReply={initReply}
                        newEditId={
                          msgid != ""
                            ? msgid
                            : task &&
                              task.request_task_id &&
                              task.request_task_id != 0
                            ? task.request_task_id
                            : task.id
                        }
                        unreadMessage={true}
                        setUnreadMessage={setUnreadMessage}
                      />
                    </UncontrolledDropdown>
                  )}
                </div>
                {fromReply ? (
                  <></>
                ) : (
                  <div className="chat-send-name c-font f-10 color-white-60">
                    <div className="d-flex align-items-center">
                      <span className="with_separator_10 ps-0 pe-2">
                        {"Myr.ai"}
                      </span>
                      <span className="with_separator_10 ps-2 pe-0">
                        {moment(
                          task.startdate
                            ? task.startdate
                            : task.converted_date
                            ? task.converted_date
                            : task.duedate
                        ).format("YYYY-MM-DD HH:mm")}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {/* chaiAI view end */}
        </>
      )}
      {showMapModal ? (
        <EditTaskModal
          show={showMapModal}
          handleClose={() => {
            setShowMapModal(false);
          }}
          task_id={task.id}
          surface={task.surface}
          taskDuration={task.duration}
          attachment={task.map_attachment_url}
        />
      ) : (
        <></>
      )}
      {showMapInfoModal ? (
        <RobotPlanDetailModal
          show={showMapInfoModal}
          handleClose={() => {
            setShowMapInfoModal(false);
          }}
          handleOpenEdit={() => {
            setShowMapModal(true);
          }}
          surface={task.surface}
          duration={task.duration}
          attachment={task.map_attachment_url}
        />
      ) : (
        <></>
      )}
      {showImagePreview ? (
        <ImageViewer
          CloseCommentModal={closePreview}
          attachmentDate={attachments.date}
          attachmentURL={
            attachments.file_array[attachments.currentIndex].file_url
          }
          changeAttach={changeAttachment}
          handleAction={() => {}}
          isChat={false}
          showNextBtn={attachments.file_array.length > 0}
          showPrevBtn={attachments.file_array.length > 0}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {showTaskImage && Object.keys(attachmentDetails).length > 0 ? (
        <CommanOffCanvas
          show={showTaskImage}
          handleClose={() => {
            setShowTaskImage(false);
          }}
          data={{
            id: attachmentDetails.id,
            taskId: task.id,
            projectId: project_id,
            attachmentDetails: attachmentDetails,
            taskDetails: task,
            allAttachments: task.attachments,
          }}
          docType={"imageview"}
          selectedTaskId={task.id}
          pageName={pageName}
          handleParentComponentAction={() => {}}
          isCommentsection={false}
          setSelectedId={setSelectedAttachmentId}
        />
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default TaskCard;
