import { CaretDoubleDown, ChartLineUp, DotsThree, Funnel, Plus, Star, UsersThree } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { _l, formateDate, removeDuplicateObjects } from '../../hooks/utilities'
import LocationCustomIcon from '../../assets/icons/LocationCustomIcon'
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import UncontrolledGeneralListing from '../TaskModalComponents/UncontrolledGeneralListing'
import { useDispatch, useSelector } from 'react-redux'
import { SetSelectedTask, clientTabData, commanoffcanvasisOpen, dayGridDay, dayGridMonth, dayGridWeek, getDashboardDetailswithSpace, getDashboardFilterList, getDefaultTopics, leftPanelFilters, setDaterangeFilter, setDaterangeFilterApplied, setGloablSearchString, setSelectedDateView, setSelectedGloablView, updateTaskList } from '../../actions/customer'
import GeneralListing from '../TaskModalComponents/GeneralListing'
import ReactTooltip from 'react-tooltip'
import DropdownWithAccordion from '../Dropdowns/DropdownWithAccordion'
import CustomerServices from '../../services/customer-services'
import CommanPlaceholder from '../Placeholder/CommanPlaceholder'
import { GoogleMap, OverlayView, useJsApiLoader } from '@react-google-maps/api'
import { mapStyle } from '../../assets/map/mapStyle'
import CustomMarker from './CustomMarker'
import CommanLoader from '../Loader/CommanLoader'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { Chart } from "react-google-charts";
import { useNavigate } from 'react-router-dom'
import jquery from 'jquery'
import CreditNoteModal from '../Modals/CreditNoteModal'
import moment from 'moment'
import PotentialSavingsModal from '../Modals/PotentialSavingsModal'
import { showMessage } from '../../actions/messages'

const Dashboard = ({pageName}) => {
  const dispatch = useDispatch();
  const userType = localStorage.getItem("user_type");
  const userRole = localStorage.getItem("contact_role");
  const { dashBoradDetails, taskFilters, getSelectedCalendarDate, dashBoradView, dashBoradFilter, globalSearchString, globalView, dashboradSummaryData,daterangeAppliedFilters, daterangeFilterApplied, creditNoteDetails, activeTaskStatusFilters } = useSelector((state) => state.customer);
  const [dashboardDetails, setDashboardDetails] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [spaceSearchString, setSpaceSearchString] = useState('');
  const [filterArr, setFilterArr] = useState([]);
  const [filterListForDisplay, setFilterListForDisplay] = useState([]);
  const [totalSuperficy, setTotalSuperficy] = useState(0);
  const [totalTaskScore, setTotalTaskScore] = useState(0);
  const [showPeopleList, setShowPeopleList] = useState(0);
  const [activeMarkerSpaceId, setActiveMarkerSpaceId] = useState(0)
  const [locationPinScale, setlocationPinScale] = useState(3)
  const [loader, setloader] = useState(false);
  const [chatData, setChatdata] = useState([])
  const [chatOpen, setChatOpen] = useState(false)
  const [selectedspaceforpopover, setselectedspaceforpopover] = useState(0)
  const [selectedslug, setselectedslug] = useState("")
  const [openCreditNoteModal, setOpenCreditNoteModal] = useState(false);
  const [openPotentialSavings, setOpenPotentialSavings] = useState(false);
  const [potentialSavingDetail, setPotentialSavingDetail] = useState();
  const [totalPotentialSaving, setTotalPotentialSaving] = useState(0)
  const [dashboardnNewSpacepage, setDashboardnNewSpacepage] = useState(1)
  const [dashboardnNewSpaceLength, setdashboardnNewSpaceLength] = useState([])
  let fromDate = localStorage.getItem("fromDate");
  let toDate = localStorage.getItem("toDate");
  let selectedTheme = localStorage.getItem("selectedTheme");
  const [showModalLoader, setshowModalLoader] = useState(false)

  let star = [
    {
      value :1,
      titte : "l_worst"
    },
    {
      value :2,
      titte : "l_bad"
    },
    {
      value :3,
      titte : "l_average"
    },
    {
      value :4,
      titte : "l_good"
    },
    {
      value :5,
      titte : "l_excellent"
    },
     ]

    const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_SECRET
    });

  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getDashboardFilterList());
    dispatch(leftPanelFilters("","","all"))

  }, [])

  useEffect(() => {
    if (globalSearchString != undefined) {
      setSpaceSearchString(globalSearchString);
      setdashboardnNewSpaceLength(dashboardDetails && dashboardDetails.filter((spa) =>(spa.office_title || "").toLowerCase().includes(globalSearchString.toLowerCase())))
      setDashboardnNewSpacepage(1);
    }else if (globalSearchString == ''){
      setdashboardnNewSpaceLength(dashboardDetails)
      setDashboardnNewSpacepage(1);
    }
  },[globalSearchString])

  const totalTime = (hours, minutes,action = "") => {
    let hoursToMinutes = 0
    let totalminutes = 0
    let totalhours = 0
    hoursToMinutes = Math.round(hours * 60);

    if(!dashBoradDetails.length)
    {
      return false;
    }

    if (action == "avg") {
      totalhours = Math.floor(((minutes + hoursToMinutes) / dashBoradDetails.length) / 60);
      totalminutes = Math.round(((minutes + hoursToMinutes) / dashBoradDetails.length) % 60);
    }else{
      totalhours = Math.floor((minutes + hoursToMinutes) / 60);
      totalminutes = Math.round((minutes + hoursToMinutes) % 60);
    }
    return { totalHours: totalhours, totalMinutes: totalminutes }
  }

  useEffect(() => {

    if (dashBoradDetails) {
      setDashboardDetails(dashBoradDetails);
      setdashboardnNewSpaceLength(dashBoradDetails)
      let newFilterDisplay = filterListForDisplay
      const sum = dashBoradDetails.reduce((acc, item) => Number(acc) + Number(item.superficies), 0);
      setTotalSuperficy(sum);

      const totalRequestQuote = dashBoradDetails.reduce((acc, item) => Number(acc) + Number(item.total_quote_request ? item.total_quote_request : 0), 0);
      let keyRequest = newFilterDisplay.findIndex((fi) => fi.slug == "l_number_of_quote_requests")
      if (keyRequest > -1) { 
      newFilterDisplay[keyRequest].value = dashboradSummaryData && dashboradSummaryData.total_quote_request ? dashboradSummaryData.total_quote_request : 0
      }

      // lease amount       
      let keyLeaseAmount = newFilterDisplay.findIndex((fi) => fi.slug == "l_lease_amount")
      if (keyLeaseAmount > -1) {
        newFilterDisplay[keyLeaseAmount].value = dashboradSummaryData && dashboradSummaryData.total_rent_amount ? dashboradSummaryData.total_rent_amount : 0
      }

      //  google rating
      const totalGooglerating = dashBoradDetails.reduce((acc, item) => Number(acc) + Number(item.google_rating ? item.google_rating : 0), 0);
      let keyGoogleRaring = newFilterDisplay.findIndex((fi) => fi.slug == "l_google_rating")
      if (keyGoogleRaring > -1) { 
      newFilterDisplay[keyGoogleRaring].value = dashboradSummaryData && dashboradSummaryData.total_rating ? dashboradSummaryData.total_rating : 0
      }
      //  credit not count
      const totalCreditNotCount = dashBoradDetails.reduce((acc, item) => Number(acc) + Number(item.creditnote_count ? item.creditnote_count: 0), 0);
      let keycreditcount = newFilterDisplay.findIndex((fi) => fi.slug == "l_creditnote_count")
      if (keycreditcount > -1) { 
      newFilterDisplay[keycreditcount].value = dashboradSummaryData && dashboradSummaryData.total_potential_saving ? dashboradSummaryData.total_potential_saving : 0
      setTotalPotentialSaving(dashboradSummaryData && dashboradSummaryData.total_potential_saving ? dashboradSummaryData.total_potential_saving : 0)
      }
      // setTotalQuoteRequest(totalRequestQuote);
      const totaltaskScore = dashBoradDetails.reduce((acc, item) => Number(acc) + Number(item.project_kpi_count && item.project_kpi_count.workspace_score ? item.project_kpi_count.workspace_score : 0), 0);
      setTotalTaskScore(dashboradSummaryData && dashboradSummaryData.total_task_score ? dashboradSummaryData.total_task_score : 0);
      // let keyTask = newFilterDisplay.findIndex((fi) => fi.slug == "l_task_score")
      // if (keyTask > -1) { 
      //   newFilterDisplay[keyTask].value = totaltaskScore
      // }

      const totalsatisfaction = dashBoradDetails.reduce((acc, item) => Number(acc) + Number(item.satisfaction ? item.satisfaction : 0), 0);
      let keySatisfaction = newFilterDisplay.findIndex((fi) => fi.slug == "l_satisfaction_level")
      if (keySatisfaction > -1) { 
      newFilterDisplay[keySatisfaction].value = totalsatisfaction / dashBoradDetails.length
      }

      const totalDelivery = dashBoradDetails.reduce((acc, item) => Number(acc) + Number(item.delivery_of_consumables ? item.delivery_of_consumables : 0), 0);
      let keyTotalDelivery = newFilterDisplay.findIndex((fi) => fi.slug == "l_delivery_of_consumables")
      if (keyTotalDelivery > -1) { 
      newFilterDisplay[keyTotalDelivery].value = totalDelivery
      }

      // setTotalSatisfaction(totalsatisfaction / dashBoradDetails.length);

      // total hours and minutes
      const avgHours = dashBoradDetails.reduce((acc, item) => Number(acc) + Number(item.avg_time_to_answer ? item.avg_time_to_answer.hour : 0), 0);
      const avgMinute = dashBoradDetails.reduce((acc, item) => Number(acc) + Number(item.avg_time_to_answer ? item.avg_time_to_answer.minute : 0), 0);
      const totalAvgTime = totalTime(avgHours, avgMinute);
      let keyTimeAns = newFilterDisplay.findIndex((fi) => fi.slug == "l_avg_time_to_answer")
      if (keyTimeAns > -1) { 
      newFilterDisplay[keyTimeAns].value = dashboradSummaryData && dashboradSummaryData.total_avg_time_to_ans ? dashboradSummaryData.total_avg_time_to_ans : {days : 0 , hour : 0 , minute : 0} 
      }
      // setTotalAvgMinutes(totalAvgTime.totalHours);
      // setTotalAvgHours(totalAvgTime.totalMinutes);

      const totalSiteHours = dashBoradDetails.reduce((acc, item) => Number(acc) + Number(item.site_work_duration ? item.site_work_duration.hour : 0), 0);
      const totalSiteMinute = dashBoradDetails.reduce((acc, item) => Number(acc) + Number(item.site_work_duration ? item.site_work_duration.minute : 0), 0);
      const totalSiteTime = totalTime(totalSiteHours, totalSiteMinute);
      let keySite = newFilterDisplay.findIndex((fi) => fi.slug == "l_on_site_work_duration")
      if (keySite > -1) { 
      newFilterDisplay[keySite].value = dashboradSummaryData && dashboradSummaryData.total_work_duration ? dashboradSummaryData.total_work_duration : {days : 0 , hour : 0 , minute : 0} 
      }

      // total hours and minutes for avg time to fix
      const avgHoursfix = dashBoradDetails.reduce((acc, item) => Number(acc) + Number(item.avg_time_to_fix ? item.avg_time_to_fix.hour : 0), 0);
      const avgMinutefix = dashBoradDetails.reduce((acc, item) => Number(acc) + Number(item.avg_time_to_fix ? item.avg_time_to_fix.minute : 0), 0);
      const totalAvgTimefix = totalTime(avgHoursfix, avgMinutefix,"avg");
      let keyTimeFix = newFilterDisplay.findIndex((fi) => fi.slug == "l_avg_time_to_fix")
      if (keyTimeFix > -1) { 
      newFilterDisplay[keyTimeFix].value = dashboradSummaryData && dashboradSummaryData.total_avg_time_to_fix ? dashboradSummaryData.total_avg_time_to_fix : {days : 0 , hour : 0 , minute : 0} 
      }
      // setTotalAvgToFixMinutes(totalAvgTimefix.totalHours);
      // setTotalAvgToFixHours(totalAvgTimefix.totalMinutes);

      setFilterListForDisplay(newFilterDisplay.slice());
    }
  }, [dashBoradDetails, dashboradSummaryData])
  useEffect(() => {
    // if (filterArr.length > 0 ) {
      if (taskFilters && taskFilters.length > 0 && taskFilters.filter((dd) => dd.main_key == "date_range_filter" && dd.filter_key != "all").length > 0) {
        dispatch(getDashboardDetailswithSpace("dateRang", filterList.filter((fi) => filterArr.includes(fi.id)),setloader, taskFilters, activeTaskStatusFilters));
      }else if(taskFilters && taskFilters.length > 0 && taskFilters.filter((dd) => dd.main_key == "date_range_filter" && dd.filter_key == "all")){
        dispatch(getDashboardDetailswithSpace("allfilter", filterList.filter((fi) => filterArr.includes(fi.id)),setloader, taskFilters, activeTaskStatusFilters))
      }else{
        dispatch(getDashboardDetailswithSpace("", filterList.filter((fi) => filterArr.includes(fi.id)),setloader, taskFilters , activeTaskStatusFilters));
      }
    // }
  }, [taskFilters, filterArr, getSelectedCalendarDate, activeTaskStatusFilters])

  useEffect(() => {
    if(filterArr && filterArr.length > 0){
      let arr = filterListForDisplay
      let Index = arr.findIndex((dd) => dd.slug == "l_task_score");
      if (Index > -1) {
        arr[Index].value = totalTaskScore
      }
      setFilterListForDisplay(arr.slice());
    }
  },[totalTaskScore, filterArr])

  // useEffect(() => {
  //   if (filterArr.length > 0) {
  //     dispatch(getDashboardDetailswithSpace("", filterList.filter((fi) => filterArr.includes(fi.id)),setloader));
  //   }
  // }, [getSelectedCalendarDate, JSON.stringify(filterArr)])

  const selectedFilter = (items, selectedItem) => {
    // let tempFilter = filterArr;
    // let tempFilterData = filterListForDisplay;

    CustomerServices.changeDashboardFilter(items).then((res) => {
      if (res.status == 1) {
        // items.map((item)=>{
        //   if (tempFilter.includes(item.id)) {
        //     tempFilter = tempFilter.filter((filter) => filter != selectedItem.includes(filter));
        //     tempFilterData = tempFilterData.filter((filter) =>filter.id != selectedItem.includes(filter.id));
        //   } else {
        //     tempFilter.push(item.id);
        //     tempFilterData.push({ ...item, is_active: "1" });
        //   }
        // })
        setFilterArr(selectedItem.slice());
        setFilterListForDisplay(removeDuplicateObjects(items).slice());
      }
    })
  }

  useEffect(() => {
    if (dashBoradFilter.length > 0) {
     if(filterArr.length == 0){
      setFilterList(dashBoradFilter);
      let filter = []
      let tempFilterData = []
      dashBoradFilter.forEach((dd) => {
        if (dd.is_active == 1) {
          filter.push(dd.id);
          tempFilterData.push(dd);;
        }
      })
      setFilterArr(filter.slice());
      setFilterListForDisplay(tempFilterData.slice());
    }
    } 
  }, [JSON.stringify(dashBoradFilter)])

  const [markers, setMarkers] = useState([]);
  const containerStyle = {
    width: "100%",
    height: "100%",
  };
  useEffect(() => {
    let tempArr = []
    if (dashboardDetails) {
    dashboardDetails.map((space) => {
      if (space.latitude != null && space.longitude != null && space.latitude != "" && space.longitude != "" && space.latitude != 0 && space.longitude != 0) { 
        tempArr.push({...space,position:{lat:+space.latitude, lng:+space.longitude}})
      }
    })
    }
    setMarkers(tempArr.slice());
  },[JSON.stringify(dashboardDetails)])

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach((position) => bounds.extend(position.position));
    map.fitBounds(bounds);
  };

  function handleZoomChange (){
    setlocationPinScale(this.getZoom())
  }

  var options = {
    'title': '',
    legend: {textStyle: {color: '#FCA120'}, position: 'top', alignment: 'start'},
    curveType: 'function',
    backgroundColor: {
      fill: 'transparent',
      stroke: '#a4aeb5',
      strokeSize: 2
    },
    hAxis: {
      title: '',
      textStyle: {
        color: '#a4aeb5',
        fontSize: 12,
        fontName: 'Montserrat',
      },
      titleTextStyle: {
        color: '#a4aeb5',
        fontSize: 12,
        fontName: 'Montserrat',
      }
    },

    vAxis: {
      title: '',
      textStyle: {
        color: '#a4aeb5',
        fontSize: 12,
      },
      // ticks: [0, 50, 100, 150, 200, 250],
      gridlines: {
        color: '#3A4F5E',
      },
      titleTextStyle: {
        color: '#a4aeb5',
        fontSize: 0,
      }
    },
    colors: ['#FCA120']
  };

  const dashbordChartData = (id, slug)=>{
    setChatOpen(false)
   let  dateFilter = '';
    dateFilter = daterangeAppliedFilters == "today" ? null :  taskFilters && taskFilters.length > 0 && taskFilters.filter((dd) => dd.main_key == "date_range_filter").length > 0 ? taskFilters && taskFilters.length > 0 && taskFilters.filter((dd) => dd.main_key == "date_range_filter" && dd.filter_key == "all").length > 0 ? "allFilter":"dateRang" : null;
    CustomerServices.dashboardChart(dateFilter,id, slug).then((res)=>{
      if(res.status == 1){
        let data = res.data
        let hoursToMinutes = 0
        let datas = [['month', `${_l(`${slug}`)}`]];
          Object.keys(data).map((item)=>{
            if(slug == "l_task_score" ){
            datas.push([item, data[item].score]);
            }else {
            hoursToMinutes = (Math.round(data[item].days * 24) + data[item].hour + Math.round(data[item].minute/ 60));
            datas.push([item, hoursToMinutes]);
            }
          })
        setChatdata(datas)
        setChatOpen(true)
      }else{
        console.log(res)
      }
    })
  }

  const fetchPotentialSavings = async(space , is_all = 0) => {
    try {
      setshowModalLoader(true);
      const response = await CustomerServices.getPotentialSavingsData(space.project_id , is_all);
      if(response.status && response.data)
      {
        is_all == 1 ? setOpenPotentialSavings(true) : setOpenCreditNoteModal(true);        
        setselectedspaceforpopover(0);
        setselectedslug("");
        setPotentialSavingDetail(response.data);
        setshowModalLoader(false);
      }else{
        setshowModalLoader(false);
      }
    }
    catch(e)
    {
      setshowModalLoader(false);
      console.error(e);
    }
  }

  const viewCardDetails = (slug, space, is_all) => {
    switch(slug)
    {
      case "l_creditnote_count":
        fetchPotentialSavings(space, is_all)
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    jquery(document).mouseup(function (e) {
      var container = jquery('.CardListingWrapper');
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        setselectedspaceforpopover(0)
        setselectedslug("")
      }
    })
  })

  const handleShowDays = (slug, value) =>{
    // site_work_duration
    switch (slug) {
      case "l_avg_time_to_answer":
        if(value.avg_time_to_answer && value.avg_time_to_answer.days > 0 ){
          return value.avg_time_to_answer.days
        }else{
          return 0
        }
      case "l_avg_time_to_fix":
        if(value.avg_time_to_fix && value.avg_time_to_fix.days > 0 ){
          return value.avg_time_to_fix.days
        }
        else{
          return 0
        }
      case "l_on_site_work_duration":
        if(value.site_work_duration && value.site_work_duration.days > 0 ){
          return value.site_work_duration.days
        }else{
          return 0
        }
      default: 
        return 0;
    }
  }
  const handleShowHours = (slug, value) =>{
    // site_work_duration
    switch (slug) {
      case "l_avg_time_to_answer":
        if(value.avg_time_to_answer && value.avg_time_to_answer.hour > 0 ){
          return value.avg_time_to_answer.hour
        }else{
          return 0
        }
      case "l_avg_time_to_fix":
        if(value.avg_time_to_fix && value.avg_time_to_fix.hour > 0 ){
          return value.avg_time_to_fix.hour
        }else{
          return 0
        }
      case "l_on_site_work_duration":
        if(value.site_work_duration && value.site_work_duration.hour > 0 ){
          return value.site_work_duration.hour
        }else{
          return 0
        }   
      default: 
        return 0;
    }
  }
  const handleShowMinits = (slug, value) =>{
    // site_work_duration
    switch (slug) {
      case "l_avg_time_to_answer":
        if(value.avg_time_to_answer && value.avg_time_to_answer.minute > 0 ){
          return value.avg_time_to_answer.minute
        }else{
          return 0
        }
      case "l_avg_time_to_fix":
        if(value.avg_time_to_fix && value.avg_time_to_fix.minute > 0 ){
          return value.avg_time_to_fix.minute
        }else{
          return 0
        }
      case "l_on_site_work_duration":
        if(value.site_work_duration && value.site_work_duration.minute > 0 ){
          return value.site_work_duration.minute
        }else{
          return 0
        }    
      default: 
        return 0;
    }
  }

  return (
    <div className="d-flex flex-column mt-10 overflow-auto h-100 ps-10px">
      {dashBoradView == "mapView" ? (
        <>
          <div className="mx-auto position-relative h-100 w-100">
            <div className="position-absolute top-0 radius_3 left-0 h-100 w-100">
              {isLoaded && <GoogleMap
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_SECRET}
                onLoad={handleOnLoad}
                options={{
                  Zoom: true,
                  zoomControl: true,
                  streetViewControl: false,
                  scrollwheel: true,
                  styles: mapStyle,
                  minZoom: 5,
                }}
                mapContainerStyle={containerStyle}
                zoom={8}
                onZoomChanged={handleZoomChange}
              >
                {markers &&
                  markers.map((position, k) => (
                    <OverlayView
                      position={position.position}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                      <CustomMarker
                        data={position}
                        activeMarkerSpaceId={activeMarkerSpaceId}
                        setActiveMarkerSpaceId={setActiveMarkerSpaceId}
                        locationPinScale={locationPinScale}
                      />
                    </OverlayView>
                    // let data = <CustomMarker data={position} />
                    // return  (<Marker
                    //     key={k}
                    //     position={position.position}
                    //     content={data}
                    //   >
                    //   {/* <CustomMarker data={position} /> */}
                    //   </Marker>
                  ))}
              </GoogleMap>}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="FilterList position-sticky top-0 base-body-color z-index-12 pb-3">
            <div className="d-flex  justify-content-between">
              <div className="d-flex w-100 overflow-x-auto overflow-y-hidden text-nowrap Filterparentdiv">
                <a href="#/" className="radius_3 bg-white-05 c-font f-12 flex-grow-1 me-10px px-2 text-center text-uppercase d-flex align-items-center justify-content-center flex-column light-theme-white-bg">
                  <div className="color-green light-theme-new-color-green">{_l("l_superficy")}</div>
                  <div className="c-font f-18 fw-semibold pt-1 title-fonts">
                    {totalSuperficy} SQM
                  </div>
                </a>
                {filterListForDisplay &&
                  filterListForDisplay.map((dd) => {
                    if (
                      dd.slug == "l_avg_time_to_answer" ||
                      dd.slug == "l_avg_time_to_fix" ||
                      dd.slug == "l_on_site_work_duration"
                    ) {
                      return (
                        <a href="#/" className="radius_3 color-orange bg-white-05 c-font f-12 flex-grow-1 me-10px p-10 text-center  d-flex align-items-center justify-content-center flex-column light-theme-white-bg">
                          <div className="">{_l(dd.slug)}</div>
                          <div className="title-fonts  d-flex align-items-center c-font f-18 fw-semibold title-font">
                           {dd.value && dd.value.days ? <>
                           <span className="fw-semibold">
                              {dd.value ? dd.value.days : 0}
                            </span>
                            <span className="color-white-60 px-1 c-font f-14">
                              {_l("l_days")}
                            </span> </> : <></>}
                            {dd.value && dd.value.hour > 0 ?
                            <>
                            <span className="fw-semibold">
                              {dd.value ? dd.value.hour : 0}
                            </span>
                            <span className="color-white-60 px-1 c-font f-14">
                              {_l("l_hrs")}
                            </span> </> : <></>}
                            <span className="fw-semibold px-1">
                              {dd.value ? dd.value.minute : 0}
                            </span>
                            <span className="color-white-60 c-font f-14">
                              {_l("l_min")}
                            </span>
                          </div>
                        </a>
                      );
                    } else {
                      return (
                        <a className="radius_3 bg-white-05 c-font f-12 flex-grow-1 me-10px px-2 text-center text-uppercase d-flex align-items-center justify-content-center flex-column"
                          data-tip={`${dd.slug == "l_creditnote_count" && userType == "operator"? creditNoteDetails && creditNoteDetails.is_credit_note == 1 ? _l("l_rais_the_credit_note") : "" : ""}`}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main" 
                          // && (moment(fromDate).format('MM') == moment(toDate).format('MM'))
                        onClick={()=>{
                          if (dd.slug == "l_creditnote_count" && daterangeFilterApplied && (moment(fromDate).format('MM') == moment(toDate).format('MM'))) {
                            viewCardDetails(dd.slug, {project_id : 0} , 1)
                            setOpenPotentialSavings(true);
                          }else if(dd.slug == "l_creditnote_count" ){
                            dispatch(showMessage("unsucess", _l("l_error"), _l("l_creditnote_count_error_message"),));
                          }
                        }}>
                          <div className="color-blue">{_l(dd.slug)}</div>
                          <div className="progress-slider w-75 ">
                            <div className="d-flex align-items-center title-fonts justify-content-center">
                              {dd.slug == "l_satisfaction_level" ||
                              dd.slug == "l_task_score" ? (
                                <div className="progress">
                                  <div
                                    className="progress-bar progress-bar-color-blue"
                                    style={{
                                      width: `${dd.value ? dd.value : 0}%`,
                                    }}
                                  ></div>
                                </div>
                              ) : (
                                <></>
                              )}
                              {dd.slug == "l_satisfaction_level" ||
                              dd.slug == "l_task_score" ? (
                                <div className="ms-2 c-font f-18 fw-semibold  title-fonts">{`${
                                  dd.value ? dd.value : 0
                                }%`}</div>
                              ) : (
                                <>
                                  {dd.slug == "l_google_rating" ? (
                                    <div className="action_icon h32w32 with_bg position-relative on-hover-active-toggle-img d-flex align-items-center">
                                      {dd.value == 0 ? (
                                        <Star
                                          size={18}
                                          weight="light"
                                          className="c-icons"
                                        />
                                      ) : (
                                        <Star
                                          size={18}
                                          className="c-icons"
                                          weight="fill"
                                          color={selectedTheme == "light" ? "#FCA120" : "#00ff6a"}
                                        />
                                      )}
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  <div className="ms-2 c-font f-18 fw-semibold pt-1 title-fonts">
                                    {["l_creditnote_count", "l_lease_amount"].includes(dd.slug)
                                      ? "€ "
                                      : ""}
                                    {dd.value ? dd.value : 0} 
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </a>
                      );
                    }
                  })}
              </div>
              {(["operator", "contact"].includes(userType) && userRole != 3) ||
              userType == "staff" ? (
                <div className="d-flex flex-column justify-content-center align-items-center position-relative with_bg">
                  <div
                    className="d-flex on-hover-active-toggle-img radius_3 p-10 bg-white-05 d-flex h65w65 w65 light-theme-white-bg"
                    type="button"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                    data-tip={_l("l_filter")}
                    data-effect="solid"
                    data-delay-show='1000'
                    data-className="tooltip-main"
                  >
                    <Funnel size={20} weight="light" className="c-icons" />
                  </div>
                  <GeneralListing
                    handler={selectedFilter}
                    list={filterList}
                    selectedIds={filterArr}
                    type={"dashboadkpiSort"}
                    placeHolderName={_l("l_no_data")}
                    filterListForDisplay={filterListForDisplay}
                  />
                  <ReactTooltip />
                </div>
              ) : (
                <a href="#/" className="radius_3 p-10 bg-white-05 d-flex h60w60">
                  <Plus size={20} weight="light" className="c-icons " />
                </a>
              )}
            </div>
          </div>
          <div className="ListingDashboard mx-auto px15px padding-bottom-60pximp overflow-auto">
            <div className="CardListingWrapper">
              {dashboardDetails &&
              dashboardDetails.filter((spa) =>
                (spa.office_title || "")
                  .toLowerCase()
                  .includes(spaceSearchString.toLowerCase())
              ).length > 0 ? (
                dashboardDetails
                  .filter((spa) =>
                    (spa.office_title || "")
                      .toLowerCase()
                      .includes(spaceSearchString.toLowerCase())
                  )
                  .sort(
                    (a, b) =>
                      b.project_kpi_count.workspace_score -
                      a.project_kpi_count.workspace_score
                  ).slice(0, dashboardnNewSpacepage > 1 ? (dashboardnNewSpacepage - 1) * 5 + 5 : dashboardnNewSpacepage * 5)
                  .map((space, key) => {
                    let peopleArr = [];
                    space.customers.forEach((customer) => {
                      peopleArr.push({ ...customer, type: "Customer" });
                    });
                    space.providers.forEach((provider) => {
                      peopleArr.push({ ...provider, type: "Provider" });
                    });
                    const projectStatus =
                      space.project_status && space.project_status == 2
                        ? "inprogress half"
                        : space.project_status == 3
                        ? "inprogress half"
                        : space.project_status == 5
                        ? "done half"
                        : space.project_status == 6
                        ? "done"
                        : space.project_status == 1
                        ? "onboarding"
                        : "";
                    const latitude = 22.30412216444457;
                    const longitude = 70.80223731541531;
                    const zoom = 10;

                    const mapURL = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d${latitude}!2d${longitude}!3d${zoom}!4d0!5d0!6m1!1e1`;
                    return (
                      <div className="Cardbox bg-white-03 radius_3 p-15 mb-15px">
                        <div className="d-flex">
                          <div className="CardImage pe-15px w25per">
                            <a href="#/" className="w-100 flex-grow-1 position-relative h-100 mapoverlaybottom radius_3">
                              <div className="position-absolute top-0 radius_3 left-0 h-100 w-100">
                                <iframe
                                  className="w-100 h-100 radius_3"
                                  // src={`https://www.openstreetmap.org/?mlat=${space.latitude}&mlon=${space.longitude}#map=${space.latitude}/${space.longitude}`}
                                  src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAP_SECRET}&q=${space.latitude},${space.longitude}`}
                                  // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15282225.79979123!2d73.7250245393691!3d20.750301298393563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30635ff06b92b791%3A0xd78c4fa1854213a6!2sIndia!5e0!3m2!1sen!2sin!4v1587818542745!5m2!1sen!2sin"
                                  // src={`https://www.google.com/maps/?q=22.3039,70.8022`}
                                  // src={`https://www.google.com/maps/?q=${space.latitude},${space.longitude}`}
                                  // src={`https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d${space.latitude}!2d-${space.longitude}!3d${space.longitude}!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cce04ff4fe494ef%3A0x26bb54f60c29f6e!5e0!3m2!1sen!2sca!4v1528808935681`}
                                  style={{ border: 0 }}
                                  allowFullScreen
                                  aria-hidden="false"
                                  tabIndex="0"
                                ></iframe>
                              </div>
                              <div className="text-uppercase mapsuperficy c-font f-12 fw-semibold">
                                <div className="color-green mb-1">
                                  {_l("l_superficy")}
                                </div>
                                <div className=" title-fonts  color-white">
                                  {space.superficies} SQM
                                </div>
                              </div>
                            </a>
                          </div>
                          <div className="CardDetail d-flex flex-column justify-content-between flex-grow-1">
                            <div className="d-flex align-items-center">
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="d-flex  flex-grow-1 w-50 w100minus100per">
                                  <div
                                    className={`with-circle half ${projectStatus}`}
                                  >
                                    <div
                                      className="bg-white-05 h52w52 comman-round-box rounded-circle title-fonts d-flex"
                                      data-tip={`${space.office_title}`}
                                      data-effect="solid"
                                      data-delay-show='1000'
                                      data-className="tooltip-main"
                                    >
                                      <span className="m-auto c-font p-1 f-9 office-subtitle text-truncate hover-span">
                                        {space.office_title}
                                      </span>
                                      {Number(space.chat_notification_count) +
                                        Number(
                                          space.unread_notification_count
                                        ) >
                                      0 ? (
                                        <span className="rounded-pill badge-custom d-flex ">
                                          {Number(
                                            space.chat_notification_count
                                          ) +
                                            Number(
                                              space.unread_notification_count
                                            )}
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                  <div className="ps10px text-truncate pe-2">
                                    <a href="#/"
                                      className="c-font f-18 fw-semibold title-fonts text-truncate d-block"
                                      data-tip={"#" + space.project_id +"-" + space.postal_address}
                                      data-effect="solid"
                                      data-delay-show='1000'
                                      data-className="tooltip-main"
                                      data-place="right"
                                      onClick={() => {
                                        dispatch(clientTabData(space));
                                        // dispatch(
                                        //   setSelectedGloablView(
                                        //     globalView == "liteVersionView"
                                        //       ? "liteVersionView"
                                        //       : globalView
                                        //   )
                                        // );
                                        // dispatch(commanoffcanvasisOpen(false)) 
                                        // dispatch(SetSelectedTask(""));
                                        // dispatch(leftPanelFilters("","","all"));
                                        // dispatch(setDaterangeFilter("today"));
                                        // dispatch(setSelectedDateView("dayView"));
                                        // dispatch(setDaterangeFilterApplied(false));
                                        // dispatch(setDaterangeFilter("today"));
                                        // dispatch(updateTaskList([]));
                                        // dispatch(dayGridDay(true));
                                        // dispatch(dayGridMonth(false));
                                        // dispatch(dayGridWeek(false));
                                        // localStorage.setItem("selectedDate", formateDate(new Date()));
                                        // localStorage.setItem(
                                        //   "isLiteVersion",
                                        //   false
                                        // );
                                        // localStorage.setItem(
                                        //   "generalChatForLiteVersion",
                                        //   false
                                        // );
                                        // localStorage.setItem(
                                        //   "selectedOffice",
                                        //   space.project_id
                                        // );
                                        // localStorage.setItem(
                                        //   "selected_project_client_id",
                                        //   space.client_id
                                        // );
                                        // let FavView = localStorage.getItem("fav_dashboard")
                                        // FavView == "AichatView" ?
                                        //   navigate("/aichat") :
                                        //   navigate("/") 
                                        //     FavView == "calendar" ?
                                        //       dispatch(setSelectedGloablView("calendarView"))
                                        //       : FavView == "my_spaces" ?
                                        //         dispatch(setSelectedGloablView("spaceView"))
                                        //         : FavView == "kanban_view" ?
                                        //           dispatch(setSelectedGloablView("kanbanView"))
                                        //           : FavView == "my_tasks" ? dispatch(setSelectedGloablView("listView"))
                                        //             : localStorage.getItem("contact_role") == 3 ? 
                                        //              navigate("/") 
                                        //                : navigate("/aichat");
                                      }}
                                    >
                                      {space.postal_address}
                                    </a>
                                    {space.chat_group_message != "" ? (
                                      <div className="d-flex align-items-center c-font color-white-60 f-10">
                                        <div className="h18w18">
                                          <div
                                            className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                            style={{
                                              backgroundImage: `url(${space.chat_group_picture})`,
                                            }}
                                          ></div>
                                        </div>
                                        <div className=" fw-bold title-fonts ps-1">
                                          {space.chat_group_name != ""
                                            ? space.chat_group_name + " :"
                                            : ""}{" "}
                                        </div>
                                        <div className="ps-1">
                                          {space.chat_group_message}
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                                <div className="d-flex kpi-main-wrapper p-0 kpi-main-box-shadow">
                                  <a href="#/" className="kpi-box bg-white-05 w_25per w60px h52px light-theme-grey-light-bg">
                                    <div className="kpi-upper-box">
                                      <div className="d-flex align-items-center justify-content-center">
                                        <div className="kpi-upper-box-text c-font f-10">
                                          <span className="color-orange">
                                            {_l("l_request")}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="w-100   text-center">
                                      <div className="kpi-statatics">
                                        {space.project_kpi_count.request_count}
                                      </div>
                                    </div>
                                  </a>
                                  <a href="#/" className="kpi-box bg-white-05 w_25per w60px h52px light-theme-grey-light-bg">
                                    <div className="kpi-upper-box">
                                      <div className="d-flex align-items-center justify-content-center">
                                        <div className="kpi-upper-box-text c-font f-10">
                                          <span className="color-white-80">
                                            {_l("l_todo_kpi_name")}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="w-100   text-center">
                                      <div className="kpi-statatics">
                                        {space.project_kpi_count.todo_count}
                                      </div>
                                    </div>
                                  </a>
                                  <a href="#/" className="kpi-box bg-white-05 w_25per w60px h52px light-theme-grey-light-bg">
                                    <div className="kpi-upper-box">
                                      <div className="d-flex align-items-center justify-content-center">
                                        <div className="kpi-upper-box-text c-font f-10">
                                          <span className="color-blue">
                                            {_l("l_ongoing_kpi")}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="w-100   text-center">
                                      <div className="kpi-statatics">
                                        {space.project_kpi_count.ongoing_count}
                                      </div>
                                    </div>
                                  </a>
                                  <a href="#/" className="kpi-box bg-white-05 w_25per w60px h52px light-theme-grey-light-bg">
                                    <div className="kpi-upper-box">
                                      <div className="d-flex align-items-center justify-content-center">
                                        <div className="kpi-upper-box-text c-font f-10">
                                          <span className="color-green">
                                            {_l("l_done_kpi")}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="w-100   text-center">
                                      <div className="kpi-statatics">
                                        {
                                          space.project_kpi_count
                                            .completed_count
                                        }
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="border-bottom pb-0 pt-15"></div>
                            <div className="align-items-center justify-content-between pb15px pt-15 pb-0 row">
                              <div className="col-lg-6 flex-grow-1  pe-1">
                                <div className="d-flex flex-column ">
                                  <div className="c-font color-white-60 f-10 pe-2">
                                    {_l("l_members")}:
                                  </div>
                                  <div className="multi_image_wrapper">
                                    <div className="d-flex align-items-center">
                                      {peopleArr &&
                                        peopleArr.length > 0 &&
                                        peopleArr.map((user, index) => {
                                          if (index < 5) {
                                            return (
                                              <a href="#/"
                                                className="align-items-center bgOfficelisting bg-white-03 d-flex h32w32 me-2 rounded-circle with-bg"
                                                data-tip={`${user.name}`}
                                                data-effect="solid"
                                                data-delay-show='1000'
                                                data-className="tooltip-main"
                                              >
                                                <div
                                                  className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                                  style={{
                                                    backgroundImage: `url(${user.image})`,
                                                  }}
                                                ></div>
                                              </a>
                                            );
                                          }
                                        })}
                                      {peopleArr && peopleArr.length > 4 ? (
                                        <>
                                          <UncontrolledDropdown
                                            direction="end"
                                            className=" "
                                          >
                                            <DropdownToggle
                                              className="border-transparentImp no-hover ps-0 light-themebgtransparent"
                                              onClick={() =>
                                                setShowPeopleList(
                                                  showPeopleList == space.id
                                                    ? 0
                                                    : space.id
                                                )
                                              }
                                            >
                                              <div className="c-list-icon">
                                                <div className="bg-white-03 bgOfficelisting h32w32 with_overlay d-flex rounded-circle">
                                                  <DotsThree
                                                    size={16}
                                                    weight="light"
                                                    className="c-icons"
                                                  />
                                                </div>
                                              </div>
                                            </DropdownToggle>
                                            <DropdownItem
                                              toggle={false}
                                              className="p-0"
                                            >
                                              {showPeopleList == space.id ? (
                                                <DropdownWithAccordion
                                                  list={peopleArr}
                                                  handler={() => {}}
                                                  unControlList={false}
                                                />
                                              ) : (
                                                <></>
                                              )}
                                            </DropdownItem>
                                          </UncontrolledDropdown>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      <ReactTooltip />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="align-items-cente col-auto d-flex flex-column h-100 ps-0 w_270px">
                                <div className="c-font color-white-60 f-10 pe-2 pb-10px">
                                  {_l("l_workspace_score")}
                                </div>
                                <div className="progress-slider d-flex align-items-center">
                                  <div className="progress ">
                                    <div
                                      className="progress-bar"
                                      style={{
                                        width: `${space.project_kpi_count.workspace_score}%`,
                                      }}
                                    ></div>
                                  </div>
                                  <div className="c-font f-12 ms-3 title-fonts">{`${space.project_kpi_count.workspace_score}%`}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-wrap overflow-y-hidden text-nowrap pt-15 filterWrapper row g-3">
                          {filterListForDisplay &&
                            filterListForDisplay.map((dd) =>
                              [
                                "l_avg_time_to_answer",
                                "l_avg_time_to_fix",
                                "l_on_site_work_duration",
                                "l_task_score",
                                "l_google_rating",
                              ].includes(dd.slug) ? (
                                ["auto"].map((placement) => (
                                  <OverlayTrigger
                                    delay={{ show: 1500 }}
                                    key={placement}
                                    placement={placement}
                                    onEnter={() => {
                                      dashbordChartData(
                                        space.project_id,
                                        dd.slug
                                      );
                                    }}
                                    show={
                                      selectedspaceforpopover == space.id &&
                                      selectedslug == dd.slug
                                        ? true
                                        : false
                                    }
                                    overlay={
                                      <Popover
                                        id={`popover-positioned-${placement}`}
                                        className={` ${chatData.length < 9 ? "" : "chart-overflow"} w-500`}
                                      >
                                        <Popover.Body
                                          className={`p-0 position-relative calednarwithborder ${
                                            dd.slug == "l_google_rating"
                                              ? "overflow-auto max-h40vh rating-popover"
                                              : ""
                                          }`}
                                        >
                                          {dd.slug == "l_google_rating" ? (
                                            <div className="title-font f13 position-sticky top-0 z-index-2 bg-white-03-solid-imp p-10 border-bottom">
                                              <div className="c-font f-16 fw-semibold title-fonts">
                                                {space.office_title}
                                              </div>
                                              <div className="c-font color-white-60 f-10">
                                                {space.postal_address}
                                              </div>
                                              <div className="d-flex align-items-center pt-1 pe-10px">
                                                <div className="c-font f-18 color-white-60 d-flex align-items-center">
                                                  {space.google_rating}
                                                </div>
                                                <div className="d-flex align-items-center">
                                                  {star.map((item, index) => {
                                                    return (
                                                      <>
                                                        <div
                                                          className={`rating-popover-items text-center d-flex flex-column px-1 py-0 ${
                                                            index <
                                                            Math.round(
                                                              space.google_rating
                                                            )
                                                              ? "active"
                                                              : ""
                                                          }`}
                                                        >
                                                          {index <
                                                          Math.round(
                                                            space.google_rating
                                                          ) ? (
                                                            <Star
                                                              size={18}
                                                              weight="fill"
                                                              className="c-icons"
                                                            />
                                                          ) : (
                                                            <Star
                                                              size={18}
                                                              className="c-icons"
                                                            />
                                                          )}
                                                        </div>
                                                      </>
                                                    );
                                                  })}
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                          {dd.slug == "l_google_rating" ? (
                                            <>
                                              {" "}
                                              {space.google_reviews &&
                                                space.google_reviews.map(
                                                  (item) => {
                                                    return (
                                                      <>
                                                        <div className="popover-detail-wrapper padding_10 py-0">
                                                          <div className="d-flex p-10 px-0 hr_sepretr">
                                                            <div className="h32w32 rounded-5">
                                                              <div
                                                                className="com bg-style-cover h-100 w-100 rounded-circle comman-image-box"
                                                                style={{
                                                                  backgroundImage: `url(${item.author_image})`,
                                                                }}
                                                              ></div>
                                                            </div>
                                                            <div className="d-flex flex-column w100minus100per text-truncate ms-10px ">
                                                              <div className="d-flex justify-content-between align-items-center">
                                                                <div className="">
                                                                  <div className="text-truncate w-100">
                                                                    {
                                                                      item.author_name
                                                                    }
                                                                  </div>
                                                                  {/* <div className='c-font color-white-60 f-10'>2 reviews·12 photos</div> */}
                                                                </div>
                                                                <div className="text-end">
                                                                  <div className="d-flex align-items-center">
                                                                    {star.map(
                                                                      (
                                                                        star,
                                                                        index
                                                                      ) => {
                                                                        return (
                                                                          <>
                                                                            <div
                                                                              className={`rating-popover-items text-center d-flex flex-column px-1 py-0 ${
                                                                                index <
                                                                                Math.round(
                                                                                  item.rating
                                                                                )
                                                                                  ? "active"
                                                                                  : ""
                                                                              }`}
                                                                            >
                                                                              {index <
                                                                              Math.round(
                                                                                item.rating
                                                                              ) ? (
                                                                                <Star
                                                                                  size={
                                                                                    18
                                                                                  }
                                                                                  weight="fill"
                                                                                  className="c-icons"
                                                                                />
                                                                              ) : (
                                                                                <Star
                                                                                  size={
                                                                                    18
                                                                                  }
                                                                                  className="c-icons"
                                                                                />
                                                                              )}
                                                                            </div>
                                                                          </>
                                                                        );
                                                                      }
                                                                    )}
                                                                  </div>
                                                                  <div className="c-font color-white-60 f-10 pt-1">
                                                                    {
                                                                      item.relative_time_description
                                                                    }
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <p className="text-wrap color-white-60 c-font f-10 pt-10px mb-0">
                                                                {item.text}
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </>
                                                    );
                                                  }
                                                )}
                                            </>
                                          ) : chatOpen &&
                                            chatData.length >= 1 ? (
                                            <div className="position-relative">
                                              <div className="h-100">
                                                <Chart
                                                  chartType="LineChart"
                                                  width={chatData.length < 9 ? "530px"  : "1350PX" }
                                                  // width="1300px"
                                                  height="100%"
                                                  data={chatData}
                                                  options={options}
                                                />
                                              </div>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                        </Popover.Body>
                                      </Popover>
                                    }
                                  >
                                    {dd.slug != "l_task_score" &&
                                    dd.slug != "l_google_rating" ? (
                                      <div className="w25per">
                                        <a href="#/"
                                          className="radius_3 color-orange bg-white-05 c-font f-12 flex-grow-1 calednarwithborder p-10 text-center  d-flex align-items-center justify-content-center flex-column light-theme-grey-light-bg h-100 position-relative"
                                          // onMouseEnter={() => {
                                          //   setselectedspaceforpopover(
                                          //     selectedspaceforpopover ==
                                          //       space.id
                                          //       ? 0
                                          //       : space.id
                                          //   );
                                          //   setselectedslug(dd.slug);
                                          // }}
                                          onClick={() => {
                                            setselectedspaceforpopover( space.id );
                                            setselectedslug(selectedslug == dd.slug && selectedspaceforpopover == space.id ? "" : dd.slug);
                                          }}
                                        >
                                          {[
                                            "l_avg_time_to_answer",
                                            "l_avg_time_to_fix",
                                            "l_on_site_work_duration",
                                            "l_task_score",
                                          ].includes(dd.slug) ? (
                                            <div className="h20w20 d-flex justify-content-center align-items-center position-absolute top-0 end-0">
                                              <ChartLineUp
                                                size={12}
                                                weight="fill"
                                                className="c-icons"
                                              />
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                          <div className="">{_l(dd.slug)}</div>
                                          <div className="title-fonts pt-1 d-flex align-items-center c-font f-18 title-font">

                                              {/* Day Start */}
                                              {handleShowDays(dd.slug, space) > 0 ?
                                                <>
                                                  <span className="fw-semibold">
                                                    {handleShowDays(dd.slug, space)}
                                                  </span>
                                                  <span className="color-white-60 px-1 c-font f-14">
                                                    {_l("l_days")}
                                                  </span></> : <></>}
                                              {/* Day end */}

                                              {/* Hours Start */}
                                              {(handleShowDays(dd.slug, space) > 0) || (handleShowHours(dd.slug, space) > 0 && handleShowHours(dd.slug, space) == 0) ?
                                                <>
                                                  <span className="fw-semibold">
                                                    {handleShowHours(dd.slug, space)}
                                                  </span>
                                                  <span className="color-white-60 px-1 c-font f-14">
                                                    {_l("l_hrs")}
                                                  </span> </> : <></>}
                                              {/* Hours end */}

                                              {/* minit Start */}
                                              {handleShowMinits(dd.slug, space) > -1 ?
                                                <>
                                                  <span className="fw-semibold px-1">
                                                    {handleShowMinits(dd.slug, space)}
                                                  </span>
                                                  <span className="color-white-60 c-font f-14">
                                                    {_l("l_min")}
                                                  </span></> : <></>}
                                              {/* minit end */}
                                          </div>
                                        </a>
                                      </div>
                                    ) : (
                                      <div className="w25per">
                                        <a href="#/"
                                          className="radius_3 bg-white-05 calednarwithborder c-font f-12 flex-grow-1 p-10 text-center text-uppercase d-flex align-items-center justify-content-center flex-column light-theme-grey-light-bg h-100 position-relative"
                                          onClick={() => {
                                            if (dd.slug == "l_google_rating") {
                                              setselectedspaceforpopover(
                                                selectedspaceforpopover ==
                                                  space.id &&
                                                  selectedslug == dd.slug
                                                  ? 0
                                                  : space.id
                                              );
                                              setselectedslug(dd.slug);
                                            }else if(dd.slug == "l_task_score"){
                                              setselectedspaceforpopover( space.id );
                                            setselectedslug(selectedslug == dd.slug && selectedspaceforpopover == space.id ? "" : dd.slug);
                                            }
                                          }}
                                          // onMouseEnter={() => {
                                          //   if (dd.slug == "l_task_score") {
                                          //     setselectedspaceforpopover(
                                          //       selectedspaceforpopover ==
                                          //         space.id &&
                                          //         selectedslug == dd.slug
                                          //         ? 0
                                          //         : space.id
                                          //     );
                                          //     setselectedslug(dd.slug);
                                          //   }
                                          // }}
                                        >
                                          {[
                                            "l_avg_time_to_answer",
                                            "l_avg_time_to_fix",
                                            "l_on_site_work_duration",
                                            "l_task_score",
                                          ].includes(dd.slug) ? (
                                            <div className="h20w20 d-flex justify-content-center align-items-center position-absolute top-0 end-0">
                                              <ChartLineUp
                                                size={12}
                                                weight="fill"
                                                className="c-icons"
                                              />
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                          <div className="color-blue">
                                            {_l(dd.slug)}
                                          </div>
                                          <div className="progress-slider w-75 ">
                                            <div className="d-flex align-items-center title-fonts justify-content-center">
                                              {dd.slug == "l_task_score" ? (
                                                <div className="progress h32w32">
                                                  <div
                                                    className="progress-bar progress-bar-color-blue"
                                                    style={{
                                                      width: `${
                                                        dd.slug ==
                                                          "l_task_score" &&
                                                        space.project_kpi_count &&
                                                        space.project_kpi_count
                                                          .workspace_score
                                                          ? space
                                                              .project_kpi_count
                                                              .workspace_score
                                                          : 0
                                                      }%`,
                                                    }}
                                                  ></div>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                              {dd.slug == "l_task_score" ? (
                                                <div className="ms-2 c-font f-18 fw-semibold  title-fonts">
                                                  {`${
                                                    dd.slug == "l_task_score" &&
                                                    space.project_kpi_count &&
                                                    space.project_kpi_count
                                                      .workspace_score
                                                      ? space.project_kpi_count
                                                          .workspace_score
                                                      : 0
                                                  }%`}
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                              {dd.slug == "l_google_rating" ? (
                                                <div className="action_icon  with_bg position-relative on-hover-active-toggle-img d-flex align-items-center d-flex">
                                                  {space.google_rating == 0 ? (
                                                    <Star
                                                      size={18}
                                                      weight="light"
                                                      className="c-icons"
                                                    />
                                                  ) : (
                                                    <Star
                                                      size={18}
                                                      className="c-icons"
                                                      weight="fill"
                                                      color="#00ff6a"
                                                    />
                                                  )}
                                                  <span className="ms-2 c-font f-18 fw-semibold  title-fonts ">
                                                    {space.google_rating}
                                                  </span>
                                                </div>
                                              ) : dd.slug ==
                                                "l_creditnote_count" ? (
                                                <span className="ms-2 c-font f-18 fw-semibold  title-fonts ">
                                                  {"€"}
                                                </span>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </div>
                                        </a>
                                      </div>
                                    )}
                                  </OverlayTrigger>
                                ))
                              ) : (
                                <div
                                  className="w25per"
                                  onClick={() => {
                                    viewCardDetails(dd.slug, space, 0);
                                  }}
                                >
                                  <a href="#/" className="radius_3 bg-white-05 c-font f-12 flex-grow-1 p-10 text-center calednarwithborder text-uppercase d-flex align-items-center justify-content-center flex-column light-theme-grey-light-bg h-100 position-relative">
                                    {[
                                      "l_avg_time_to_answer",
                                      "l_avg_time_to_fix",
                                      "l_on_site_work_duration",
                                      "l_task_score",
                                    ].includes(dd.slug) ? (
                                      <div className="h20w20 d-flex justify-content-center align-items-center position-absolute top-0 end-0">
                                        <ChartLineUp
                                          size={12}
                                          weight="fill"
                                          className="c-icons"
                                        />
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                    <div className="color-blue">
                                      {_l(dd.slug)}
                                    </div>
                                    <div className="progress-slider w-75 ">
                                      <div className="d-flex align-items-center title-fonts justify-content-center">
                                        {dd.slug == "l_satisfaction_level" ? (
                                          <div className="progress">
                                            <div
                                              className="progress-bar progress-bar-color-blue"
                                              style={{
                                                width: `${
                                                  dd.slug ==
                                                    "l_satisfaction_level" &&
                                                  space.satisfaction
                                                    ? space.satisfaction
                                                    : 0
                                                }%`,
                                              }}
                                            ></div>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                        {dd.slug == "l_satisfaction_level" ? (
                                          <div className="ms-2 c-font f-18 fw-semibold  title-fonts">
                                            {`${
                                              dd.slug ==
                                                "l_satisfaction_level" &&
                                              space.satisfaction
                                                ? space.satisfaction
                                                : 0
                                            }%`}
                                          </div>
                                        ) : (
                                          <>
                                            {" "}
                                            {dd.slug == "l_google_rating" ? (
                                              <div className="action_icon h32w32 with_bg position-relative on-hover-active-toggle-img d-flex align-items-center">
                                                {space.google_rating == 0 ? (
                                                  <Star
                                                    size={18}
                                                    weight="light"
                                                    className="c-icons"
                                                  />
                                                ) : (
                                                  <Star
                                                    size={18}
                                                    className="c-icons"
                                                    weight="fill"
                                                    color={selectedTheme == "light" ? "#FCA120" : "#00ff6a"}
                                                  />
                                                )}
                                              </div>
                                            ) : ["l_creditnote_count", "l_lease_amount"].includes(dd.slug) ? (
                                              <span className="ms-2 c-font f-18 fw-semibold  title-fonts ">
                                                {"€"}
                                              </span>
                                            ) : (
                                              <></>
                                            )}
                                            <div
                                              className={`ms-2 c-font f-18 fw-semibold  title-fonts ${
                                                dd.slug == "l_google_rating"
                                                  ? "mt-1"
                                                  : ""
                                              }`}
                                            >
                                              {dd.slug ==
                                                "l_number_of_quote_requests" &&
                                              space.total_quote_request
                                                ? space.total_quote_request
                                                : dd.slug ==
                                                    "l_delivery_of_consumables" &&
                                                  space.delivery_of_consumables
                                                ? space.delivery_of_consumables
                                                : dd.slug ==
                                                    "l_compliance_documents" &&
                                                  space.compliance_documents
                                                ? space.compliance_documents
                                                : dd.slug ==
                                                    "l_creditnote_count" &&
                                                  space.creditnote_count > 0
                                                ? Number(
                                                    space.creditnote_count
                                                  ).toFixed(2)
                                                : dd.slug ==
                                                  "l_potential_saving"
                                                ? 0
                                                : dd.slug == "l_lease_amount"
                                                ? space.lease_amount
                                                : 0}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              )
                            )}
                        </div>
                      </div>
                    );
                  })
              ) : (
                <>
                  <CommanPlaceholder
                    imgType="no-space"
                    placeholderText={"l_no_active_space"}
                  />
                </>
              )}
            </div>
            {(dashboardnNewSpaceLength.length >= (dashboardnNewSpacepage - 1) * 5 + 5 &&
              dashboardnNewSpaceLength.length != (dashboardnNewSpacepage - 1) * 5 + 5) ? 
            <div>
              <a href="#/"
                className="btn btn-white-03 w-100 view-more-btn"
                onClick={() => {
                  setDashboardnNewSpacepage(prev => prev + 1)
                }}
              >
                <div className="d-flex align-item-center justify-content-center">
                  <span className=" title-fonts c-font f-12 ms-2">
                    {_l("l_view_more_btn")} (
                    {dashboardnNewSpacepage > 1
                                ? dashboardnNewSpaceLength.length -
                                ((dashboardnNewSpacepage - 1) * 5 + 5)
                                : dashboardnNewSpaceLength.length - 5}
                    )
                  </span>
                  <CaretDoubleDown size={12} className="c-icons mx-2" weight="light" />
                </div>
              </a>
            </div>
            : <></>}
          </div>
        </>
      )}
      {/* location map custom style start */}
      <div style={{ display: "none" }}>
        {/* location map 1 */}
        <div className="location-custom-style">
          <span className="badge p-1 rounded-pill color-white mb-2">
            {_l("l_tenants")}
          </span>
          <div className="multi_inner_wrapper with-min-width d-flex with_minus justify-content-start mb-2">
            <a href="#/" className="comman-image-box  h35w35 rounded-circle one assignee-list-wrapper card-view-less-padding after-none blue-border-w-2">
              <div
                className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                style={{
                  backgroundImage: `url(https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80)`,
                }}
              ></div>
            </a>
            <a href="#/" className="comman-image-box  h35w35 rounded-circle one assignee-list-wrapper card-view-less-padding after-none blue-border-w-2">
              <div
                className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                style={{
                  backgroundImage: `url(https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80)`,
                }}
              ></div>
            </a>
            <a href="#/" className="comman-image-box  h35w35 rounded-circle one assignee-list-wrapper card-view-less-padding after-none">
              <UncontrolledDropdown className="with_overlay d-flex rounded-circl">
                <DropdownToggle
                  tag="a"
                  className="comman-image-box h35w35 with_overlay d-flex rounded-circle dropdown team-list-dropdown z-index-2 after-none blue-border-w-2"
                >
                  <div
                    className="unread_count m-auto"
                    type="button"
                    id="GeneralListing"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                  >
                    +5
                  </div>
                  <UncontrolledGeneralListing
                    list={[1, 2, 3].map((index) => {
                      return {
                        name: "User name",
                        image:
                          "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
                        id: index,
                      };
                    })}
                  />
                </DropdownToggle>
              </UncontrolledDropdown>
            </a>
          </div>
          <div className="d-flex h60w60 justify-content-center position-relative mb-3">
            <LocationCustomIcon className="" />
            <div className="with-circle not-started position-absolute start-50 translate-middle location-inner-div">
              <div className="comman-round-box d-flex h35w35 rounded-circle title-fonts">
                <span className="m-auto c-font f-9 office-subtitle text-truncate p-1">
                  PARIS 2
                </span>
              </div>
            </div>
          </div>
          <span className="badge badge-in-progress rounded-pill text-uppercase py-2 mb-2">
            <span className="">{_l("l_satisfaction")}</span>
          </span>
          <div className="dropdown progress-wrapper-minus d-flex">
            <a href="#/" className="progress-wrapper-link">
              <div className="progress-slider with_separator_10 before-dnone card-view-less-padding">
                <div className="d-flex align-items-center">
                  <div className="progress w_100">
                    <div
                      className="progress-bar progress-bar-color-blue"
                      style={{ width: `88%` }}
                    ></div>
                  </div>
                  <div className="progress-text c-font f-12 title-fonts">
                    88%
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        {/* location map 2 */}
        <div className="location-custom-style">
          <span className="badge p-1 rounded-pill color-white mb-2">
            {_l("l_cleaning_placeholder")}
          </span>
          <div className="multi_inner_wrapper with-min-width d-flex with_minus justify-content-start mb-2">
            <a href="#/" className="comman-image-box  h35w35 rounded-circle one assignee-list-wrapper card-view-less-padding after-none green-border-w-2">
              <div
                className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                style={{
                  backgroundImage: `url(https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80)`,
                }}
              ></div>
            </a>
          </div>
          <span className="badge badge-done rounded-pill text-uppercase py-2 mb-2">
            <span className="">{_l("l_task_Score")}</span>
          </span>
          <div className="dropdown progress-wrapper-minus d-flex">
            <a href="#/" className="progress-wrapper-link">
              <div className="progress-slider with_separator_10 before-dnone card-view-less-padding">
                <div className="d-flex align-items-center">
                  <div className="progress w_100">
                    <div
                      className="progress-bar"
                      style={{ width: `88%` }}
                    ></div>
                  </div>
                  <div className="progress-text c-font f-12 title-fonts">
                    88%
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      {/* location map custom style end */}
      {openCreditNoteModal ? (
        <>
          <CreditNoteModal
            data={potentialSavingDetail}
            show={openCreditNoteModal}
            handleClose={() => {
              setOpenCreditNoteModal(false);
            }}
          />{" "}
        </>
      ) : (
        <></>
      )}
      {openPotentialSavings ? (
        <>
          <PotentialSavingsModal
            data={potentialSavingDetail}
            show={openPotentialSavings}
            handleClose={() => {
              setOpenPotentialSavings(false);
            }}
            totalPotentialSaving={totalPotentialSaving}
            showModalLoader={showModalLoader}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Dashboard