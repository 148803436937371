import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import {
  X,
  MagnifyingGlass,
  ShareNetwork,
  VideoCamera,
  CornersIn,
  CornersOut,
  UserCircleGear,
} from "phosphor-react";
import {
  _l,
  hideLeftPanelNew,
  TaskCommentModalOpen,
  TaskModalClose,
} from "../../hooks/utilities";
import Form from "react-bootstrap/Form";
import { createScheduleDetails } from "../../actions/benchmark-actions/benchmark-actions";
import { useDispatch, useSelector } from "react-redux";
import { commanoffcanvasisOpen, commanoffcanvasisOpenfromCalendar, getBenchmarkDetail, updateHideLoader } from "../../actions/customer";
import { Info } from "phosphor-react";
import PlusIcon from "../../assets/icons/PlusIcon";
import PencilSimpleLineIcon from "../../assets/icons/PencilSimpleLineIcon";
import { leadModalToggle } from "../../actions/customer";
import jquery from "jquery";
import Autocomplete from "../Dropdowns/Autocomplete";
import Spinner from "react-bootstrap/Spinner";
import customerServices from "../../services/customer-services";
import GeneralListing from "./GeneralListing";
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import UnControlMainFiltersDropDown from "./LeadsMainFiltersDropDown";

function CommonHeaderModal({
  data,
  type,
  handleAction,
  isExternal,
  showFullscreen,
  setShowFullscreen,
  setShowCommentModal,
  ShowCommentModal,
  IsKanbanView = false,
  openProposalAmountModal,
  onCloseShowLeftPanelDefault,
  allData,
  readOnlyMode,
  disableFullScreenToggle=false,
  setActiveTaskID=()=>{},
  setModalHeaderIconToggle=true,
  newTypeOfSpaceData,
  setNewTypeOfSpaceData,
  getSpaceOptionList,
  equipmentData,
  handleChange,
  selectedEquipmentsQuantity,
  setRequestData,
  requestData,
  loaderForPOI = false
}) {
  const userType = localStorage.getItem("user_type");
  const [createrName, setCreaterName] = useState("");
  const [createrProfile, setCreaterProfile] = useState("");
  const [name, setName] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [namePermission, setNamePermission] = useState(false);
  const [taskRedirectBtnPermission, setTaskRedirectBtnPermission] =
    useState(false);
  const [taskRedirectBtnText, setTaskRedirectBtnText] = useState(false);
  // const [showFullscreen, setShowFullscreen] = useState(false);
  const [followBtnText, setFollowBtnText] = useState("");
  const [myr_total, setMyr_total] = useState(0);
  const [status, setStatus] = useState("");
  const [statusText, setStatusText] = useState("Draft");
  const [proposalAmount, setProposalAmount] = useState("");
  const [proposalId, setProposalId] = useState(0);
  const [proposalDescription, setProposalDescription] = useState("");
  const dispatch = useDispatch();
  const { createScheduleDetail } = useSelector(
    (state) => state.benchmarkReducer
  );
  const selectedTask = useSelector((state) => state.customer.selectedTask);
  const {calendarCanvasIsOpen, globalView ,commanoffcanvasIsOpen, providerlistTableData, defaultTopics } = useSelector((state) => state.customer);

  const [isUnKnownBudget, setIsUnKnownBudget] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const projectId = localStorage.getItem("selectedOffice")
  const [categoryListing, setCategoriesList] = useState([]);
  const [taskAssignee, setTaskAssignee] = useState([])


  const statusClass = {
    Accepted: "color-green",
    Unpaid: "color-red",
    Paid: "color-green",
    Partially: "color-orange",
    Overdue: "color-red",
    Cancelled: "color-red",
    Draft: "color-orange",
    Open: "c-text done",
    Close: "text-danger",
    active: "color-green",
    deactive: "color-red",
    1: "text-warning",
    2: "text-danger",
    3: "c-text done",
    4: "c-text in-progress-blue",
    5: "text-danger",
  };

  const getStatusText = () => {
    if(data.document_type == "benchmark")
    {
      if(userType == "operator")
      {
        switch(data.proposal_status)
        {
          case "2":
            return _l("l_declined")
          case "3":
            return _l("l_accepted");;
          case "4":
            if(data.proposal_amount == 0 || data.proposal_amount == null)
            {
              return _l("l_received");
            }
            else {
              return _l("l_pending");
            }
          default:
            break;
        }
      }
      else {
        if(data.status == 1)
        {
          return _l('l_draft');
        }
        else if(data.is_opened == 1)
        {
          return _l('l_open');
        }
        else {
          return _l('l_close');
        }
      }
    }
    else {
      if((data.status == 3 || data.status == 4))
      {
        if(!data.start_date)
        {
          return _l('l_waiting_for_start_date');
        }
        else if(data.is_assigneed == 0) {
          return _l('l_waiting_for_assignee');
        }
        else {
          return data.status_text;
        }
      }
      else {
        return data.status_text;
      }
    }
  };

  const getStatusClass = () => {
    if(["benchmark", "schedule"].includes(type))
    {
      if (type == "benchmark") {
        if (userType == "operator") {
          switch (data.proposal_status) {
            case "2":
              return "text-danger";
            case "3":
              return "text-done";
            case "4":
              if (data.proposal_amount == 0 || data.proposal_amount == null) {
                return "in-progress";
              }
              else {
                return "text-warning";
              }
            default:
              break;
          }
        }
        else {
          if(data.status == 1)
          {
            return "text-warning";
          }
          else if (data.is_opened == 1) {
            return "text-done";
          }
          else {
            return "text-danger";
          }
        }
      }
      else {
        if ((data.status == 3 || data.status == 4)) {
          if(!data.start_date)
          {
            return "text-warning";
          }
          else if((data.is_assigneed == 0))
          {
            return "text-warning";
          }
          else {
            return "text-done";
          }
        }
        else {
          return "text-done";
        }
      }
    }
    else {
      return statusClass[status];
    }
  };

  useEffect(() => {
    if (data && allData) {
      setCreaterName(allData.type == "staff" ? allData.addedfrom_details.full_name : (allData.type == 'customer') ? allData.name :data.creater_name ? data.creater_name : "");
      setCreaterProfile(allData.type == "staff" || allData.type == 'customer' ? allData.profile_image :data.creater_profile ? data.creater_profile : "");
      setName(allData.type == "staff" ? allData.addedfrom_details.full_name : allData.type == 'customer' ? allData.full_name : data.document_name ? data.document_name :allData.type == 'equipment' ? allData.name : allData.title ? allData.title : "");
      setNamePermission(allData.type == 'staff' || allData.type == 'customer' ? false : data.document_name_permission);
      setTaskRedirectBtnPermission(data.task_redirect_btn_permission);
      setDocumentType(data.is_synced ? "Google Document" : data.document_type);
      setTaskRedirectBtnText(data.task_redirect_btn_text);
      // setShowFullscreen(data.show_full_screen)
      setFollowBtnText(data.follow_btn_text);
      setMyr_total(data.myr_total && data.myr_total);
      if (type == "schedule" || type == "benchmark") {
        setIsUnKnownBudget(data.isUnKnownBudget ? data.isUnKnownBudget : "");
        setProposalAmount(data.proposal_amount);
        setProposalDescription(data.proposal_description);
        setProposalId(data.proposal_id);
        setStatusText(getStatusText());
        setStatus(data.proposal_status ? data.proposal_status : data.status);
      }
      else if(type == "invoice")
      {
        setStatus(data.is_app_invoice != 1 ? data.status : "");
      }
      else {
        setStatusText(data.status_text ? data.status_text : "");
        setStatus(data.status ? data.status : "");
      }
     
    }
  }, [JSON.stringify(data), type, JSON.stringify(allData)]);
  
  useEffect(() => {
    if (createScheduleDetail) {
      if (type == "schedule" || type == "benchmark") {
        setSelectedTab(
          createScheduleDetail.selectedTab
            ? createScheduleDetail.selectedTab
            : ""
        );
      }
    }
  }, [createScheduleDetail]);
  
  useEffect(() => {
    if(selectedTask){
      setDocumentType((selectedTask.type === "event & task" || selectedTask && selectedTask.calendar_id ) ? _l('l_google_task') : data.document_type);
    }
   
  }, [selectedTask]);
  useEffect(() => {
    localStorage.setItem("click_detail", JSON.stringify(false));
    
  }, [JSON.parse(localStorage.getItem("click_detail")) == true]);
  const scheduleBenchmarkAmount = (value) => {
    dispatch(
      createScheduleDetails({
        ...createScheduleDetail,
        [`amount`]: value,
        ["isUnKnownBudget"]: false,
      })
    );
    dispatch(getBenchmarkDetail(0, 0, "edit", "max_budget", value));
  };
  /*Close detail modal and comment on office change and week calendar date change*/
  useEffect(() => {
    if (!calendarCanvasIsOpen && !commanoffcanvasIsOpen && !isExternal && type !== "provider_page") { 
      handleAction("modalClose");
    }else if (type === "provider_page") {
      dispatch(commanoffcanvasisOpen(true))
      hideLeftPanelNew()
        TaskCommentModalOpen()
    }
  }, [commanoffcanvasIsOpen,calendarCanvasIsOpen])

  useEffect(() => {
    let categoryLists = [];

if (providerlistTableData && providerlistTableData.company_profile) {
  let data  = providerlistTableData.company_profile
  customerServices.getProviderServices().then((response) => {
    if (response.status) {
       categoryLists = response.data.map((w) => {
          return { 
            label: w.name, 
            value: w.id, 
          };
        })
      setCategoriesList(categoryLists ? categoryLists.filter((item) => data.categories && data.categories.includes(item.value)).map((w) => {
          return{
            label : w.label,
            value : w.value,
            isFixed : true,
          }
        })  : []);
    }
  })
  
}
  }, [providerlistTableData])

  useEffect(() => {
    if (type === "provider_page") {
      let  setAssigneeProviders = defaultTopics && defaultTopics.filter((item) => item.main_key === "my_contact");
      let proviserAssineeId = providerlistTableData && providerlistTableData.provider_assignee && providerlistTableData.provider_assignee.map((item) => item.assignee_id);
      setAssigneeProviders = setAssigneeProviders && setAssigneeProviders[0] && setAssigneeProviders[0].categories.filter((item) => proviserAssineeId.includes(item.filter_key));
      setTaskAssignee(setAssigneeProviders);
    }
  }, [JSON.stringify(defaultTopics), JSON.stringify(providerlistTableData)])

  return (
    <div className="task-detail-header h-100">
      <div className="d-flex align-items-center h-100">
        <div className="task-header-left flex-grow-1">
          <div className="d-flex align-items-center">
            <div className={`d-none task-creator-icon ${isExternal ? "z-index-12":""} `}>
              <div
                className="h25w25 comman-round-box rounded-circle bg-white-05"
                aria-expanded="false"
                data-tip={`${createrName ?  createrName : name }`}
                data-effect="solid"
                data-delay-show='1000'
                data-class="tooltip-main"
              >
                <div
                  className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                  style={{
                    backgroundImage: `url('${type == "pilotonboarding" ? localStorage.getItem("contact_image") : createrProfile}')`,
                  }}
                ></div>
              </div>
            </div>
            {
              ["type_of_space","equipment_task"].includes(type) ?
              <>
                  <Form.Group className="c-input-box pb-0 position-relative position-sticky top-0 bg-base-header-color z-index-4 w-33">
                      <div className="d-flex align-items-center justify-content-between w-100 ">
                          <div className="align-items-center bg-transparent border-0 d-flex flex-grow-1 form-control h32wauto justify-content-between ps-0">
                              <div className="with_separator_10 flex-grow-1 ps-0">
                                  <Autocomplete
                                      inputClass="bg-transparent border-0"
                                      dropdownClass="max-height-20vh"
                                      value={type == "type_of_space" ? newTypeOfSpaceData.title : equipmentData.title}
                                      options={getSpaceOptionList()}
                                      onChange={(e) => {
                                        if (type == "type_of_space") {
                                          setNewTypeOfSpaceData({
                                            ...newTypeOfSpaceData,
                                            title: e.target.value,
                                        });
                                        } else {
                                          handleChange("title", e.target.value)
                                        }
                                      }}
                                      onSelect={(value) => {
                                        if (type == "type_of_space") {
                                          setNewTypeOfSpaceData({
                                            ...newTypeOfSpaceData,
                                            title: value.label,
                                        });
                                        } else {
                                          selectedEquipmentsQuantity(value.value);
                                        }
                                      }}
                                  />
                              </div>
                          </div>
                      </div>
                  </Form.Group>
              </>
              : type == "poi" ? 
                  <div className="task-name-input w100minus40 position-relative">
                    <div className={`absoluteTaskName ${namePermission ? "" : "d-none"}`}
                      data-tip={`#`}
                      data-effect="solid"
                      data-delay-show='1000'
                      data-place="left"
                      data-class="tooltip-main"
                      data-bs-placement="left">
                    </div>
                    <input
                      type=""
                      className="form-control transparent py-0 ps-0 title-fonts shadow-none"
                      placeholder={`${_l("l_poi_name")}`}
                      data-tip={data.name}
                      data-effect="solid"
                      data-delay-show='1000'
                      data-place="left"
                      data-class="tooltip-main"
                      data-bs-placement="left"
                      value={data.name}
                      onChange={(e) => {handleAction("poi_title",e.target.value)}}
                    />
                  </div>
              :
            <div className={`task-name-input  position-relative ${type == "provider_page"? "" : "w100minus40"}`}>
              <div className={ `absoluteTaskName  ${isExternal || namePermission ? "":"d-none"}` }
                    data-tip={`#${data.id || data.taskId || data.document_id} - ${name}`}
                    data-effect="solid"
                    data-delay-show='1000'
                    data-place="left"
                    data-class="tooltip-main"
                    data-bs-placement="left">
                  </div>
                    <div className="d-flex">
                      <input
                        type=""
                        className="form-control transparent py-0 ps-0 title-fonts shadow-none c-font f-14 fw-semibold"
                        placeholder={`${_l("l_task_name")}`}
                        data-tip={`#${data.id || data.taskId || data.document_id} - ${name}`}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-place="left"
                        data-class="tooltip-main"
                        data-bs-placement="left"
                        value={
                          type === "gmail"
                            ? (requestData.conversation.messages[0]['payload']['headers'].filter((header) => header['name'] == 'Subject'))[0]['value']
                            : requestData && requestData.id > 0 && type != "provider_page" 
                              ? type == "pilotonboarding"
                                ? _l("l_pilot_onboarding") 
                              : type == "task" || type == "request-task" 
                                ? name : `#${data.id || data.taskId || data.document_id} - ${name}` 
                              : requestData.name
                        }
                        // disabled={namePermission}
                        currentitem="false"
                        onClick={() => { setModalHeaderIconToggle(false) }}
                        onChange={(e) => {
                          if (requestData && requestData.id > 0) {
                            setName(e.target.value);
                            handleAction("nameChangeValue", e.target.value);
                          } else {
                            setRequestData({ ...requestData, name: e.target.value })
                          }
                        }}
                        onBlur={(e) => {
                          handleAction("nameUpdate", e.target.value);
                        }}
                      />
                      {type == "provider_page" ? <>
                        {categoryListing.length > 0 ? (
                          <div
                            className={`position-relative d-flex assignee-list-wrapper card-view-less-padding`}
                          >
                            <div
                              className={`dropdown-center multi_inner_wrapper with-min-width ps-0 d-flex  with_minus ${categoryListing && categoryListing.length > 0
                                  ? ""
                                  : "d-none"
                                }`}
                            >
                              {categoryListing && categoryListing.length >= 1 ? (
                                categoryListing.map((assignee, index) => {
                                  if (index < 3)
                                    return (
                                      <div className="pe-10px">
                                        <div
                                          className="badge rounded-pill text-nowrap list-toggle-view-none"
                                          key={index}
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                          data-tip={`${_l(assignee.label)}`}
                                          data-effect="solid"
                                          data-delay-show="1000"
                                          data-class="tooltip-main"
                                        >
                                          <span className={` p-0 `}>
                                            {assignee.main_key == "my_task"
                                              ? "#"
                                              : ""}
                                            {_l(assignee.label)}
                                          </span>
                                          <ReactTooltip />
                                        </div>
                                      </div>
                                    );
                                })
                              ) : (
                                <></>
                              )}

                              {categoryListing && categoryListing.length > 3 ? (
                                <div className="pe-10px">
                                  <a
                                    href="#/"
                                    className="badge rounded-pill text-nowrap list-toggle-view-none"
                                    // id="TaskAssigneeDropdown"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    data-tip={`${_l("l_view_all")}`}
                                    data-effect="solid"
                                    data-delay-show="1000"
                                    data-class="tooltip-main"
                                  >
                                    <div className="unread_count m-auto c-font f-10">
                                      +{categoryListing.length - 3}
                                    </div>
                                  </a>
                                  <GeneralListing
                                    handler={() => { }}
                                    list={
                                      categoryListing &&
                                      categoryListing.length &&
                                      categoryListing.map((item, index) => {
                                        return {
                                          name: item.label,
                                          id: index,
                                        };
                                      })
                                    }
                                    isHash={true}
                                    direction={true}
                                    unControlList={true}
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <ReactTooltip />
                          </div>
                        ) : (
                          <></>
                        )}
                      </> : <></>}
                    </div>
            </div>
          }
            <a href="#/"
              className="btn btn-primary btn-sm d-none"
              onClick={() => {
              }}
            >
              {_l("l_add_category")}
            </a>
          </div>
          </div>
          {type === "provider_page" && 
          <div
            className={`dropdown-center multi_inner_wrapper with-min-width d-flex with_separator2_10 with_minus ${
              taskAssignee && taskAssignee.length >= 1
                ? ""
                : "d-none"
            }`}
          >
            {taskAssignee && taskAssignee.length >= 1 ? (
              taskAssignee.map((assignee, index) => {
                if (index < 1)
                  return (
                    <a
                      href="#/"
                      data-bs-toggle="tooltip"
                      className="comman-image-box multi-wrapper-border h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                      data-tip={
                        assignee.full_name
                      }
                      data-place="top"
                      data-effect="solid"
                      data-delay-show="1000"
                      data-class="tooltip-main"
                      key={index}
                    >
                      <div
                        className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                        style={{
                          backgroundImage: `url('${
                            assignee.image
                          }')`,
                        }}
                      ></div>
                      <ReactTooltip />
                    </a>
                  );
              })
            ) : (
              <></>
            )}
            {taskAssignee && taskAssignee.length > 1 ? (
              <a
                href="#/"
                className="comman-image-box h32w32 with_overlay d-flex rounded-circle dropdown-center"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-tip={`${_l("l_view_all")}`}
                data-effect="solid"
                data-delay-show="1000"
                data-class="tooltip-main"
                // onClick={handleTaskAssigneeData}
              >
                <div className="unread_count m-auto c-font f-12">
                  +{taskAssignee.length - 1}
                </div>
              </a>
            ) : (
              <></>
            )}
            {taskAssignee && taskAssignee.length ? (
              <GeneralListing
                handler={() => {}}
                list={
                  taskAssignee &&
                  taskAssignee.length &&
                  taskAssignee.map((item, index) => {
                    return {
                      name: item.title,
                      image:
                        item.image,
                      id: index,
                    };
                  })
                }
              />
            ) : (
              <></>
            )}
          </div>
          }
        {type == "provider_page" && 
          <UncontrolledDropdown className="comman_action_icon ms-10px"
              data-tip={_l("l_assign_to")}
              data-effect="solid"
              data-delay-show='1000'
              data-class="tooltip-main"
              ><DropdownToggle
                  tag={'a'}
                  className="d-flex on-hover-active-toggle-img action_icon with_bg h32w32"
                  type="button"
                  data-bs-toggle="dropdown" 
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                  onClick={(e) => {
                      e.stopPropagation();
                      // showLeadContactComments();
                  }}
              > 
              <div className="d-flex align-items-center w-100">
                  {/* <div className="btn btn-primary btn-sm text-nowrap">
                  {_l("l_assign_to")}
                  </div> */}
                  <UserCircleGear
                    size={24}
                    weight="light"
                    className={`c-icons`}
                  />
              </div>
              </DropdownToggle>
              <ReactTooltip />
              <DropdownItem toggle={false} className="p-0"> 
                  <UnControlMainFiltersDropDown
                      type = {"provider_page"}
                  />
              </DropdownItem>
          </UncontrolledDropdown>
          // <a href="#/"
          //   className="btn btn-primary btn-sm"
          //   onClick={() => {
          //   }}
          // >
          //   {_l("l_add_category")}
          // </a>
        }
        <div className="task-header-right pe-2">
          <div className="d-flex align-items-center justify-content-end comman_action_icon">
            <a href="#/"
              className={`btn btn-primary btn-sm line-height-1 action_icon mx-2 ${
                taskRedirectBtnPermission ? "" : "d-none"
              }`}
              onClick={() => {
                if (taskRedirectBtnPermission) {
                  handleAction("taskMoveBtn", "");
                }
              }}
            >
              {taskRedirectBtnText}
            </a>
            {type != "task" &&
              type != "request-task" &&
              type != "lead" &&
              type != "gmail" &&
              type != "document" &&
              userType == "operator" ? (
              <>
                <a href="#/" className="badge rounded-pill me-2 text-nowrap d-none"
                data-tip={_l("l_proposal_amount")}
                data-effect="solid"
                data-delay-show='1000'
                data-class="tooltip-main"
                  onClick={() => openProposalAmountModal(true, proposalAmount, proposalDescription, proposalId)}>
                  {type == "benchmark"
                    ?
                    <>
                      <span
                        className={`p-0 me-1`}
                      >
                        € {parseFloat(proposalAmount).toFixed(2)}
                      </span>
                      {
                        proposalAmount > 0 ?
                            <PencilSimpleLineIcon />
                          :
                            <PlusIcon />
                      }
                    </>
                    : <></>
                  }
                </a>
              </>
            ) : (
              <></>
            )}
            {status != "" && type != "task" &&
            type != "request-task" &&
            type != "lead" &&
            type != "gmail" &&
            type != "document" &&
            type != "pilotonboarding"  ? (
              <>
                <div className="badge rounded-pill me-2 text-nowrap d-none">
                  <span
                    className={`${getStatusClass(status)} p-0`}
                  >
                    {type == "benchmark" || type == "schedule"
                      ? statusText
                      : status}
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className={`badge ${
                  type == "request-task" ? "badge-warning" : ""
                } rounded-pill d-none`}>
              <span
                className={``}
              >
                {userType == "operator" && documentType == "benchmark" ? _l('l_proposal') : documentType}
              </span>
            </div>

            <div className="d-flex align-items-center justify-content-between">
              <a href="#/" className="action_icon d-none">
                <MagnifyingGlass size={18} className="c-icons" weight="light" />
              </a>
              <a href="#/"
                className="action_icon ml-10 d-none"
                onClick={() => {
                  handleAction("shareNetwork", "");
                }}
              >
                <ShareNetwork size={18} className="c-icons" weight="light" />
              </a>

              {type != "type_of_space" && type != "poi" && type != "task" &&
              type != "request-task" &&
              type != "lead" &&
              type != "gmail" &&
              type != "document" &&
              type != "pilotonboarding" ? (
                <>
                  {type == "schedule" || type == "benchmark" ? (
                    <>
                      <div className="task-header-right ps-2 d-flex align-items-center">
                        <Form.Check
                          type="checkbox"
                          id="budget"
                          className="h-100 d-flex"
                          onChange={(e) => {
                            dispatch(
                              createScheduleDetails({
                                ...createScheduleDetail,
                                [`isUnKnownBudget`]: e.target.checked,
                              })
                            );
                            dispatch(
                              getBenchmarkDetail(
                                0,
                                0,
                                "edit",
                                "budget_is_empty",
                                e.target.checked ? "1" : "0"
                              )
                            );
                          }}
                          checked={isUnKnownBudget}
                          disabled={
                            (selectedTab == "create-schedule-type" && !readOnlyMode) ? false : true
                          }
                        />
                        <a href="#/"
                          className="with_separator_10"
                          data-tip={_l("l_approximate_i_dont_know_my")}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                        >
                          <Info size={16} weight="light" className="c-icons" />
                        </a>
                        <div className="c-font color-white-80 f-12 mx-2"
                          data-tip={_l("l_budget")}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main">
                          {_l("l_budget")}
                        </div>
                        <div
                          className={`d-flex align-items-center radius_3 badge-white ps-2 ${
                            isUnKnownBudget ? "schedule-checbox-disbled" : ""
                          }`}
                          data-tip={
                            isUnKnownBudget ? "" : myr_total
                          }
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                        >
                          
                          <span className=" color-green c-font f-10 fw-semibold text-center title-fonts">
                            €
                          </span>
                          <input
                            className="bg-transparent m_w50 color-green c-font f-10 fw-semibold p-1 px-0  text-center title-fonts border-0"
                            value={isUnKnownBudget || !myr_total ? 0 : myr_total.replace(/[^0-9.]|_/g, "")}
                            type="number"
                            onChange={(e) => {
                              const value = e.target.value;

                              if (!isNaN(parseFloat(value))) {
                                setMyr_total(value);
                                if (type == "benchmark" || type == "schedule") {
                                  scheduleBenchmarkAmount(value);
                                }
                              } else {
                                setMyr_total("");
                              }
                            }}
                            disabled={
                              selectedTab == "create-schedule-type"
                                ? false
                                : true
                            }
                          />
                          <ReactTooltip />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className={`comman_action_icon ${type == "provider_page" ? "d-none" : ""} `}>
                      <div className="f-12 fw-semibold badge radius_3 H32 d-flex">
                        <span className="color-green m-auto">{myr_total}</span>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}

              <a href="#/"
                className={`btn btn-white-10 btn-sm line-height-1 action_icon ml-10 d-none ${
                  isExternal ? "for-accessible" : ""
                }`}
                onClick={() => {
                  handleAction("follow", true);
                }}
              >
                {followBtnText}
              </a>
              <a href="#/" className="action_icon ml-10 d-none">
                <VideoCamera size={18} className="c-icons" weight="light" />
              </a>
            </div>
            {/* <div className="badge badge-white color-green c-font f-14 fw-semibold p-2">
                        ${myr_total}
                      </div> */}
            {/* {type == "task" || type == "request-task" ? (
              <input
                className={`badge-white color-green c-font f-14 ml-10 fw-semibold p-1 radius_3 text-center title-fonts border-0 H32 ${
                  showMyrTotal ? "" : "d-none"
                }`}
                placeholder="$300"
                type="text"
                data-tip={`${_l("l_amount")}`}
                data-effect="solid"
                data-delay-show='1000'
                data-class="tooltip-main"
                value={"€ " + myr_total}
                id="myr_total"
                onChange={(e) => {
                  if (parseFloat(e.target.value) !== NaN) {
                    let TempTotal = e.target.value;
                    setMyr_total(TempTotal.replaceAll("€", "").trim());
                    handleAction(
                      "setMyr_total",
                      TempTotal.replaceAll("€", "").trim()
                    );
                  }
                }}
                onBlur={(e) => {
                  if (myr_total !== NaN && myr_total > 0) {
                    let TempTotal = e.target.value;
                    handleAction(
                      "myrTotalUpdate",
                      TempTotal.replaceAll("€", "").trim()
                    );
                  }
                }}
                ref={(reference) => {
                  handleAction("totalRef", reference);
                }}
                disabled={myr_total_permission}
              />
            ) : (
              <></>
            )} */}
            {/* <a
              className={`h32w32 d-flex mr10minus action_icon with_bg ms-2 with_bg with-border-active ${modalHeaderIconToggle ?"active":""}`}
              onClick={() => {
                setModalHeaderIconToggle(!modalHeaderIconToggle);
              }}
            >
              <DotsNine size={18} className="c-icons" weight="light" />
            </a>  */}
            <div className={`before-dnone  with_separator_10 d-none ${IsKanbanView || disableFullScreenToggle || ShowCommentModal ? "d-none" : ""}`}>
              {showFullscreen ? (
                <a href="#/"
                  className={`action_icon with_bg h32w32 d-flex  ${isExternal ? "d-none" : ""}`}
                  onClick={() => {
                    setShowFullscreen(false);
                    handleAction("showFullscreen", false);
                    setShowCommentModal(true);
                    
                  }}
                  // id="modal_zoom_in"
                  data-tip={`${_l("l_reset_screen")}`}
                  data-effect="solid"
                  data-delay-show='1000'
                  data-class="tooltip-main"
                >
                  <CornersIn
                    size={18}
                    className="c-icons zoom_in"
                    weight="light"
                  />
                </a>
              ) : (
                <a href="#/"
                  className={`action_icon with_bg h32w32 d-flex  ${isExternal ? "d-none" : ""}`}
                  onClick={() => {
                    setShowFullscreen(true);
                    handleAction("showFullscreen", true);
                    setShowCommentModal(true);
                    
                  }}
                  // id="modal_zoom_out"
                  data-tip={`${_l("l_full_screen")}`}
                  data-effect="solid"
                  data-delay-show='1000'
                  data-class="tooltip-main"
                >
                  <CornersOut
                    size={18}
                    className="c-icons zoom_out"
                    weight="light"
                  />
                </a>
              )}
            </div>
            {type == "poi" ? 
            <a href="#/"  className={`btn btn-primary btn-sm-2 text-uppercase line-height-1 mt-1`} disabled={loaderForPOI} onClick={() => {
                  handleAction("poi_create");
                }}>
            {loaderForPOI ? _l('l_please_wait') : _l('l_save')}
            {loaderForPOI ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                className={`ms-2 `}
                aria-hidden="true"
              />
            ) : (
              <></>
            )}
          </a>
            :<></>}

            <a href="#/"
              type="button"
              className={`modal-close bg-white-05 h32w32 d-flex position-relaive modal-close-button z-index-5 d-none ${
                isExternal || ShowCommentModal ? "d-none" : ""
              }`}
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={() => {
                handleAction("modalClose");
                dispatch(updateHideLoader(false));
                dispatch(commanoffcanvasisOpen(false))
                dispatch(commanoffcanvasisOpenfromCalendar(false))
                if (calendarCanvasIsOpen) {
                  setActiveTaskID(0)
                }

                if(onCloseShowLeftPanelDefault){
                  TaskModalClose(onCloseShowLeftPanelDefault);
                }else{
                  TaskModalClose();
                }
                if(type == "lead"){
                  dispatch(leadModalToggle(false));
                }
                if(window.location.pathname == "/liteversion" || globalView == "liteVersionView")
                {
                  localStorage.removeItem("selectedOffice")
                }

                if((projectId == 0 || projectId == null || projectId == undefined)){
                  hideLeftPanelNew();
                }
              }}
              data-tip={`${_l("l_close")}`}
              data-effect="solid"
              data-delay-show='1000'
              data-class="tooltip-main"
            >
              <X size={16} className="c-icons" weight="light" />
            </a>
          </div>
        </div>
      </div>
      <ReactTooltip />

  </div>
  );
}

export default React.memo(CommonHeaderModal);
