import React, { useEffect, useRef, useState } from "react";
import LockIcon from "../assets/icons/LockIcon";
import ScheduleDropDown from "./MyPeople/ScheduleDropDown";
import PlusIcon from "../assets/icons/PlusIcon";
import { CardListScroll, DefaultLeftPanel, HideDropdown, TaskModalClose, _l, accessPremiumFeature, addActiveBorderforWeekCalendar, addexpandedClass, favoriteDdashboardSelected, formateDate, hideLeftPanelNew, hideRightPanelNew, isUserHaveOnboardingSpace,  leftPanelMouseUpEvent, loadDataToIDB, showError, showLeftPanel, showLeftPanelNew, showLeftpanelonSearchHover, removeDuplicates, showContactDirectory, hideContactDirectory } from "../hooks/utilities";
import CustomerServices from "../services/customer-services";
import GeneralListing from "./TaskModalComponents/GeneralListing";
import WalletSetting from "./Modals/WalletSetting";
import Button from "react-bootstrap/Button";
import ReactHtmlParser from "react-html-parser";
import { Funnel, Check, DotsThreeVertical, PushPin, MagnifyingGlass, CaretDown, UserCircleGear, Wrench, UsersThree, EnvelopeSimple, Wallet, Buildings, X, CaretUp, Rows, CurrencyEur, Chats, Users, HardDrives, DotsNine, BezierCurve, GearSix, SignIn, AddressBook, Gauge, ListBullets, UserPlus } from "phosphor-react";
import {
  getCustomerSpaces,
  projectTaskCounts,
  setProject,
  getTopicTasks,
  setTaskSearchString,
  leftPanelFilters,
  getTaskCount,
  setActiveTaskStatusFilters,
  setTaskUnreadCmtCntAll,
  getDefaultTopics,
  isIBANModalOpen,
  setCreateScheduledetails,
  fetchMails,
  getLockScreenTaskList,
  setVirtualSpaceList,
  clearDocumentData,
  commanoffcanvasisOpen,
  setAllChatUserList,
  selectedChatUser,
  setSelectedGloablView,
  getTaskList,
  lastSessionOfChat,
  setFetchedTaskandMessages,
  updateTaskList,
  setBulkTaskRating,
  setDaterangeFilterApplied,
  setSelectedDateView,
  dayGridDay,
  setShowWeekcalendardays,
  setDaterangeFilter,
  dayGridMonth,
  dayGridWeek,
  setTopicThreadId,
  clearDefaultTopicsList,
  SetSelectedTask,
  clientTabData,
  setGloablSearchString,
  fullScreenLoaderChangeSpace,
  clearChatTaskList,
  contactDirCount,
  selectJobProposalPage,
  setOpenOrCloseContactDirectoryChat,
  accordionsEventKey,
  setDashBoardView,
  getProviderList,
  setProviderAgnetList,
  setProvideClientList,
  emailTokenExpired,
  isTenantsData,
  setLeftPanelTab,
} from "../actions/customer";
import {
  fetchUnreadMsgCount,
  setOpenGeneralChatGroup,
  userOffline,
  setUnreadMsgCount,
  getGroupDetails,
  setClearmessage,
  groupIn,
  fetchMessages,
  fetchGroups,
  fetchGroupsExternal
} from "../actions/chat";
import { fetchAuthToken } from "../actions/chat-auth";
import { useSelector, useDispatch } from "react-redux";
import AddEditCategory from "./Modals/AddEditCategory";
import jquery from "jquery";
import TwoStepAccordians from "./Accordians/TwoStepAccordians";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import CommanLoader from "./Loader/CommanLoader";
import moment from "moment";
import ActionDropdown from "./Dropdowns/ActionDropdown";
import AddTaskIcon from "../assets/icons/AddTaskIcon";
import RequestTaskIcon from "../assets/icons/RequestTaskIcon";
import { loginAsUser } from "../actions/auth";
import { showFullScreenLoader, showMessage, showConfirmation, toastCloseAction } from "../actions/messages";
import { adminLoginAsStaff } from "../actions/admin-actions";
import ProviderDropdownWithprogressBar from "./Dropdowns/ProviderDropdownWithprogressBar";
import Spinner from "react-bootstrap/esm/Spinner";
import EmailSyncIcon from "../assets/icons/EmailSyncIcon";
import EmailRevokeIcon from "../assets/icons/EmailRevokeIcon";
import { Chat, FileText } from "phosphor-react";
import { is_discord_connected } from "../actions/discord_auth";
import { is_googlecalendar_connected } from "../actions/googlecalendar_auth";
import { is_google_drive_connected } from "../actions/google-drive";
import SettingMenu from "./SettingMenu/SettingMenu";
import ProgressBar from 'react-bootstrap/ProgressBar'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import User_2 from "../assets/images/users/user_2.jpg";
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import UncontrolledGeneralListing from "./TaskModalComponents/UncontrolledGeneralListing";
import MailSetting from "./Modals/MailSetting";
import { getPilotOnboardingDocumentDetail } from "../actions/documents-actions/documents-actions";
import CommanOffCanvas from "./Offcanvas/CommanOffCanvas";
import CommanPlaceholder from "./Placeholder/CommanPlaceholder";
import authServices from "../services/auth-services";
import ManagePrjoectMember from "./Modals/ManagePrjoectMember";
import adminServices from "../services/admin-services";
import { CLIENT_TAB_DATA, USER_LOGOUT } from "../actions/action-type";
import AdminServices from "../services/admin-services";
import SpaceIBANConfiguration from "./Modals/SpaceIBANConfiguration";
import OpenAi from "./Modals/OpenAi";
import Autocomplete from "./Dropdowns/Autocomplete";
import PremiumEstimateIconCrown from "../assets/icons/PremiumEstimateIconCrown";
import ThreeStarIcon from "../assets/icons/ThreeStarIcon";
import { setOnboardingStepData } from "../actions/onboarding-actions/onboarding-actions";
import TopicSettingModal from "./Modals/TopicSettingModal";
import StaffIbanSetup from "./Modals/StaffIbanSetup";
import Toasts from "./Toasts/Toasts";
import AlertModal from "./Modals/AlertModal";
import CelebrationIcon from "../assets/icons/CelebrationIcon";
import { Form } from "react-bootstrap";
import PaymentModal from "./Modals/PaymentModal";
import DocumentsServices from "../services/documents-services";
import TeamList from "./Accordians/TeamList";
import { insertCollectionToDB, readCollectionFromDB } from "../hooks/indexed_db_helpers";
import chatServices from "../services/chat-services";
import MyProfileDropDownNew from "./Dropdowns/MyProfileDropDownNew";
import LeftPanelSubMenu from "./LeftPanel/LeftPanelSubMenu";
import LeftPanelMain from "./LeftPanel/LeftPanelMain";
import customerServices from "../services/customer-services";

let filterStatus = [];
let projectFilters = {my_spaces: false, project_status: [], account_executive: [], account_manager: []};

let searchMentionUsers = [];

const CONTACT_MENU_OPTIONS = ["plan", "mail", "company-profile"];
const OPERATOR_MENU_OPTIONS = ["plan", "mail", "company-profile", "proposals"];
const STAFF_MENU_OPTIONS = ["teams", "company-profile", "mail"];
let SPACE_LIST_RESULTS_PER_PAGE = 8;

let VISIBLE_MENUS = [];

const LeftPanel = ({
  setSelectedProject = ()=>{},
  projectId,
  isStatusCleared,
  clearStatusFilter,
  pageName = null,
  removedFilterStatus,
  setTaskModalClose,
  allMAilMessages,
  allImportantMessages,
  hideMailSyncBtn,
  setShowMailModal,
  synchronizedMail,
  setSynchronizedMail,
  hideDriveSyncBtn = true,
  setIsKanbanView =()=>{}
}) => {

  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const [selectedOffice, setSelectedOffice] = useState(
   localStorage.getItem("is_registered") == 1 ? 0 : 0
  );
  const adminUser = localStorage.getItem("staff_id");
  const startDate = localStorage.getItem('selectedDate')
  const [SettingMenuRender, setSettingMenuRender] = useState(false);
  const [showScheduleDropDown, setShowScheduleDropDown] = useState(false);
  const [selectOperators, setSelectOperators] = useState({});
  const [Modal1show, setModal1show] = useState(false);
  const [activeSpaces, setActiveSpaces] = useState([]);
  const [inActiveSpaces, setInActiveSpaces] = useState([]);
  const [selectedSpace, setSelectedSpace] = useState({});
  const [todoTasks, setTodoTasks] = useState(0);
  const [inProgressTasks, setInProgressTasks] = useState(0);
  const [completedTasks, setCompletedTasks] = useState(0);
  const [virtualSpaceUnreadMsg, setVirtualSpaceUnreadMsg] = useState(0);
  const [clientIdLoginAsUser, setClientIdLoginAsUser] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [isOfficeSelected, setIsOfficeSelected] = useState(
    localStorage.getItem("selectedOffice") ?  localStorage.getItem("selectedOffice") : -1
  );
  const [teamLeftPanel, setTeamLeftPanel] = useState(false);
  const dispatch = useDispatch();

  const {  generalMsgCount, openGeneralChatGroup, groupList, groupListExternal, groupDetails } = useSelector((state) => state.chat);
  const customerData = useSelector((state) => state.customer);
  const spaceState = useSelector((state) => state.customer.spaces);
  const proposalCount = useSelector((state) => state.customer.proposalKpiCount);
  const messagesStates = useSelector((state) => state.messages);
  const { taskFilters, taskCounts, selectedUserForChat, isBackdrop, generalUnreadCount, globalView, selectedProject, defaultTopics, fullScreenLoadChanSpace, isJobProposalActive, isContactDirChat, dashBoradView, leftPanelTab} = useSelector((state) => state.customer);
  const { importantMessages, inboxMessages, statusClose, virtualSpaceList,isAllChatUserlistShow, topicLoadedFromIDB, isTopicListLoading ,globalSearchString, emailTokenVaild, isTenantsDataVaild, providerWallet } = useSelector((state) => state.customer);
  virtualSpaceList.forEach((w) => {w.is_virtual_space = 1});
  virtualSpaceList.forEach((w) => {w.is_project_member = 1});
  virtualSpaceList.forEach((w) => {w.project_status = 1});
  virtualSpaceList.forEach((w) => {w.project_id = w.id});
  const activeTaskStatusFilters = useSelector(
    (state) => state.customer.activeTaskStatusFilters
  );
  const isPremiumEstimateEdit = useSelector(
    (state) => state.customer.isPremiumEstimateAccess
  );
const projectAttendancCounts =  useSelector((state) => state.customer.projectAttendancCount)
  const isIBANModal = useSelector((state) => state.customer.isIBANModalOpen);
  const [searchBarFocus, setSearchBarFocus] = useState(false);
  const [WalletActiveClass, setWalletActiveClass] = useState(true);
  const [MyEmailActiveClass, setMyEmailActiveClass] = useState(true);
  const [MyLeadActiveClass, setMyLeadActiveClass] = useState(true);
  const [PremiumPageActiveClass, setPremiumPageActiveClass] = useState(true);
  const [CompanyProfileActiveClass, setCompanyProfileActiveClass] =
    useState(true);
  const [loder, setLoder] = useState(false);
  const [activeOfficePage, setActiveOfficePage] = useState(1);
  const [project_id, setproject_id] = useState();
  const [settingTopicName, setSettingTopicName] = useState("");
  const [topicMainKey, setTopicMainKey] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastClass, setToastClass] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [toastHeader, setToastHeader] = useState("");
  const [officeId, setOfficeId] = useState(0);
  const [estimateId, setEstimateId] = useState(0);
  const [showProjectStatusList, setshowProjectStatusList] = useState(false);
  const [selectedspaceStatus, setSelectedspaceStatus] = useState(0)
    const disablePages = [
    "companyprofile",
    "mywallet",
    "myspacenew",
    "MyEmail",
    "premiumsubscription",
    "leadPage"
  ];
  const [showBackDropClass, setShowBackDropClass] = useState(false);
  const [spaceSearchOnGoing, setSpaceSearchOnGoing] = useState(false);
  const noLeftPanel = [
    // "dashboard",
    "CompanyProfile",
    "MyProfile",
    "MyWallet",
    "Onboarding",
    "SpaceOnBoardingEquipments",
    "SpaceOnBoardingNew",
    "SpaceOnBoarding",
    "SpaceOnBoardingSchedule",
    "SpaceOnBoardingTeam",
    "SpaceOnBoardingTenet",
    "spaceOnboardingInvite",
    "PaymentPage",
    "paymentsetupnew",
    "PremiumSubscription",
    "SpaceOnBoardingNew",
    "dashboardwithdaterange",
    "dashboardpre"
  ];
  const noLeftPanelPages = [
    // "dashboard",
    "CompanyProfile",
    "MyProfile",
    "MyWallet",
    "Onboarding",
    "SpaceOnBoardingEquipments",
    "SpaceOnBoardingNew",
    "SpaceOnBoarding",
    "SpaceOnBoardingSchedule",
    "SpaceOnBoardingTeam",
    "SpaceOnBoardingTenet",
    "spaceOnboardingInvite",
    "PaymentPage",
    "paymentsetupnew",
    "PremiumSubscription",
    "SpaceOnBoardingNew",
    "dashboardwithdaterange",
    "dashboardpre"

  ];
  const fullScrollPages =["Dashboard","LiteVersion", "benchmarkList", "documents", "MyEmail", "Wallet", "leadPage", "MyPeople", "Proposals", "schedule", "client", "AdminTaskPage","SpaceOnBoarding","ChatAi","MyProfile","benchmark"];
  const noActiveSpacePageNames = ["invoice", "estimate", "subscription", "staff", "benchmark", "schedule", "CompanyProfile", "Proposals", "MyProfile", "client", "fullcalendar", "AdminTaskPage", "credit_notes", "_equipments", "PaymentPage"];
  if(!openGeneralChatGroup || !isAllChatUserlistShow){
    noLeftPanelPages.push("Chat");
    noLeftPanelPages.push("LiteVersion");
  }

  const client_id = localStorage.getItem("client_id");
  const contact_id = localStorage.getItem("contact_id");
  const admin = localStorage.getItem("admin");
  const adminArchieve = localStorage.getItem("adminArchieve");
  const rel_id =
  localStorage.getItem("user_type") === "staff"
    ? localStorage.getItem("staff_id")
    : localStorage.getItem("client_id");
  const boss_operator = localStorage.getItem("boss_operator");

  const [officeWorkSpaceScore, setOfficeWorkSpaceScore] = useState(0);
  const [getUser, setUser] = useState(false);
  const [userList, setUserList] = useState([]);
  const [pendingPaymentInfo, setPendingPaymentInfo] = useState({});
  const [subscriptionLoader, setSubscriptionLoader] = useState(false);

  const staffOrContactId =
    localStorage.getItem("user_type") == "contact"
      ? localStorage.getItem("contact_id")
      : localStorage.getItem("staff_id");
  let user_type = localStorage.getItem("user_type");
  user_type = user_type === "contact" ? "client" :user_type === "operator" ? "operator" : "staff";
  const isOperator = localStorage.getItem("user_type") === "operator";
  const userType = localStorage.getItem("user_type");
  const contact_role = localStorage.getItem("contact_role");
  const is_agent = localStorage.getItem("is_agent");
  const isAdmin = localStorage.getItem("admin");
  const spaceArray = localStorage.getItem("spaces") ? JSON.parse(localStorage.getItem("spaces")) : [];
  const company_name = localStorage.getItem("company_name");
  const company_logo = localStorage.getItem("company_logo");
  const clientWorkspaceScore = localStorage.getItem("workspace_score");
  const savedProject = localStorage.getItem("selectedOffice");
  const disabledThirdPartyToolAccess = spaceArray && spaceArray.length <= 1 && localStorage.getItem("space_setup_pending") > 0;

  const [spaces, setSpaces] = useState([]);
  const [clientTeamData, setClientTeamData] = useState([]);
  const [showSearch, setshowSearch] = useState(false);
  const [searchCategory, setSearchCategory] = useState("");
  const [preEstiPageDisable, setPreEstiPageDisable] = useState("");
  const [getIbanModlaOpen, setGetIbanModlaOpen] = useState("");
  const [searchTask, setSearchTask] = useState("");
  const [filterTaskActive, setFilterTaskActive] = useState([]);
  const [projectTaskCount, setProjectTaskCount] = useState([]);
  const [estimateDetails, setEstimateDetails] = useState({});
  const [show, setShow] = useState(false);
  const [ibanShow, setIbanShow] = useState(false);
  const [spaceDetails, setSpaceDetails] = useState({});
  const [proposalKpiCount, setProposalKpiCount] = useState({});
  const [randomID, setRandomID] = useState(String(Math.random()));
  const [managePeopleModal, setManagePeopleModal] = useState(false);
  const [showOpenAiModal, setshowOpenAiModal] = useState(false)
  const [spacecontent, setspaeContent] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const viewPage = window.location.pathname;
  const email = localStorage.getItem("email");
  let defaultTaskStatus = [0, 1, 4, 5];
  let defaultProposalStatus = [6, 4, 3, 2];
  let defaultInvoiceStatus = [1, 2, 5, 6];
  let defaultBenchmarkStatus = [1, 3, 4, 5];
  let defaultEstimateStatus = [1, 2, 3, 4];
  let defaultStaffStatus = [0, 1];
  let defaulSubscriptionStatus = ['active', 'not_subscribed', 'future', 'canceled'];
  let defaultCreditNoteStatus = [1, 2, 3];
  let defaultLeadsStatus = [1, 3, 16, 28, 30];
  if ( ["Dashboard" , "calendar" , "fullcalendar" ,"dashboard" , "AdminTaskPage"].includes(pageName)) {
    // 0 - for request task
    defaultTaskStatus = [0, 1, 4, 5];
  }
  const [LeftPanelEmailPage, setLeftPanelEmailPage] = useState(false);
  const [officeFilterKey, setOfficeFilterKey] = useState("");
  const [spaceFilter, setSpaceFilter] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState();
  const [createTeamModal, setCreateTeamModal] = useState(false);
  const [equipmentModal, setEquipmentModal] = useState(false);
  const [showDrviesModal, setShowDrviesModal] = useState(false);
  const handleDriveModalClose = () => setShowDrviesModal(false);
  const handleDriveModalShow = () => setShowDrviesModal(true);
  const [inactiveOfficeShow, setInactiveOfficeShow] = useState(true);
  const [employeeData, setEmployeeData] = useState("");
  const invoiceCounter = useSelector((state) => state.customer.get_counts);
  const staffCounter = useSelector((state) => state.customer.staffCounter);
  const benchmarkCounter = useSelector((state) => state.customer.benchmarkCounter);
  const spaceClient = useSelector((state) => state.customer.spaceClient);
  const estimateCounter = useSelector((state) => state.customer.get_counts);
  const subCounter = useSelector((state) => state.customer.subCounter);
  const [invoiceCount, setInvoiceCount] = useState([]);
  const [estimateCount, setEstimateCount] = useState([]);
  const [isEmailSynce, setIsEmailSynce] = useState();
  const [showSyncMailModal, setSyncShowMailModal] = useState(false);
  const [synchronizedMailLeft, setSynchronizedMailLeft] = useState(false);
  const [onboardDocument, setOnboardDocument] = useState("");
  const [showDocumentCanvas, setShowDocumentCanvas] = useState(false);
  const [topicType, setTopicType] = useState("");
  const [selectedTeamForEdit, setSelectedTeamForEdit] = useState();
  const [showAddTeamMember, setshowAddTeamMember] = useState(false);
  const [adminTeamList, setAdminTeamList] = useState([]);
  const [spaceData, setspaceData] = useState([])
  const [showLeftPanelMenu, setshowLeftPanelMenu] = useState(false)
  const getOnboardDocument = useSelector((state) => state.documentsReducer.getOnboardDocument);
  const [overdueTaskCounts, setOverdueTaskCounts] = useState([]);
  const [filterDropDownProps, setFilterDropDownProps] = useState([]);
  const [mentionStaffArray, setMentionStaffArray] = useState([]);
  const [selectedStaffMembersForSpaceFilter, setSelectedStaffMembersForSpaceFilter] = useState([]);
  const [defaultTopicsstate, setdefaultTopicsstate] = useState([]);
  const [forMeTopic, setForMeTopic] = useState([]);
  const [showStaffIBANSetup, setShowStaffIBANSetup] = useState(false);
  const [spaceInvitationData, setSpaceInvitationData] = useState({});
  const [visibleOptionIcons, setvisibleOptionIcons] = useState([]);
  const [menuOptionsFloat, setMenuOptionsFloat] = useState(true);
  const [activeMenuOptions, setActiveMenuOptions] = useState([]);
  const [benchmarkDiscardModal, setBenchmarkDiscardModal] = useState({});
  const [searchCategoty, setsearchCategoty] = useState("")
  const [searchTopic, setsearchTopic] = useState("")
  const [showForMeSection, setShowForMeSection] = useState(false)
  const [loginAsUserLoader, setLoginAsUserLoader] = useState(false)
  const [showLoginAsUserList, setShowLoginAsUserList] = useState(false);
  const [isLoginAsUserDataLoaded, setIsLoginAsUserDataLoaded] = useState([]);
  const [contactlistShow, setContactlistShow] = useState(false);
  const [searhContact, setSearhContact] = useState("")
  const [showMyProfile, setshowMyProfile] = useState(false)
  let space_pending = localStorage.getItem("space_setup_pending")
  let is_registered = localStorage.getItem("is_registered")
  
  const panelRef = useRef();
  const menuButtonRef = useRef();
  const officeListDivRef = useRef();
  const officeSearchboxRef = useRef();
  const topicSearch = useRef();
  const { contactDirMsgCount } = useSelector((state) => state.chat);
  const {
    leadsFilters,
  } = useSelector((state) => state.leadsReducer);

  const [searchParams, setSearchParams] = useSearchParams();

  const hideLeftPanelFooter = [
    "Proposals",
    "dashboard",
    "estimate",
    "staff",
    "client",
    "fullcalendar",
    "AdminTaskPage",
    "subscription",
    "credit_notes",
    "_equipments",
    "invoice",
    "credit_notes",
    "subscription",
    "schedule",
    "benchmark",
    "leadPage",
    "MyProfile",
    "SpaceOnBoarding",
    // "dashboardnew"
  ];

  const DEFAULT_SUB_MENU_PAGES = ["SpaceOnBoarding", "SpaceOnBoardingNew"];

  const projectStatusArray = [
    {
      id: 1,
      name: _l("l_todo"),
      class: "color-gray",
    },
    {
      id: 2,
      name: _l("l_free_trial"),
      class: "color-blue",
    },
    {
      id: 6,
      name: _l("l_active"),
      class: "color-green",
    },
    {
      id: 7,
      name: _l("l_inactive"),
      class: "color-white",
    },
    // {
    //   id: 3,
    //   name: _l("l_subscription_required"),
    //   class: "color-blue",
    // },
    // {
    //   id: 5,
    //   name: _l("l_ready"),
    //   class: "color-green",
    // },
  ];
  const isLeadPage = pageName == "leadPage" ? 1 : 0;
  const [leftpanelToggleOnLiteversion,setLeftpanelToggleOnLiteversion] = useState(false);
  const proposalUnreadComments = localStorage.getItem("unread_schedule_comment");
  let FavView = localStorage.getItem("fav_dashboard");
  const dashboardBenchmarkId = !isNaN(Number(searchParams.get("dashboard-benchmark-id"))) && Number(searchParams.get("dashboard-benchmark-id")) ? Number(searchParams.get("dashboard-benchmark-id")) : 0;
  let spacesArray = userType == "staff" ? activeSpaces : activeSpaces;
  var chatUserId = localStorage.getItem("chatUserId");
  const [isSynced, setisSynced] = useState("");

  const closeInvitationAlert = async() => {
    try {
      const response = await CustomerServices.readSpaceInvitationAlert(spaceInvitationData.project_id);
      if(response.status)
      {
        setSpaceInvitationData({});
      }
      else {
        showError(response.message);
      }
    }
    catch(e)
    {
      console.error(e);
    }
  }


  useEffect(() => {
    if(noActiveSpacePageNames.indexOf == -1)
    {
      jquery('.office-box[data-space-div="'+isOfficeSelected+'"]').addClass('active');
    }

  }, [noActiveSpacePageNames])
  useEffect(() => {
   
    if(noLeftPanel.includes(pageName) && showLeftPanelMenu)
    showLeftPanel(true,true);
    if(!["operatorListReporting","equipmentListReporting","SpaceOnBoardingNew"].includes(pageName)){
      CardListScroll();
    }

    return () => {
    }
  }, [pageName,showLeftPanelMenu]);

  useEffect(() => {
    if (selectedTeam && Object.keys(selectedTeam).length > 0 && adminTeamList && adminTeamList.length > 0) {
      let virualspace = adminTeamList.filter((vs) =>  vs && vs.id == selectedTeam.id)
      dispatch(setUnreadMsgCount(parseInt(virualspace[0].unread_chat_msg_count)))
    } else if (selectedOffice > 0 && activeSpaces && activeSpaces.length > 0) {
      let space = activeSpaces.filter((as) => as && as.project_id == selectedOffice)
      if (space && space.length > 0) {
        dispatch(setUnreadMsgCount(parseInt(space[0].chat_notification_count)))
      }
    }

    if (selectedOffice > 0 && spacesArray.length > 0) {
      let tempSelectedSpace = spacesArray.filter((as) => as && as.project_id == selectedOffice)
      setSelectedSpace(tempSelectedSpace.length > 0 ? tempSelectedSpace[0] : {})
    }
    // setSelectedProject(is_registered == 1 || leftPanelTab != "my-space" ? 0 : Number(selectedOffice))
  }, [selectedOffice, activeSpaces, adminTeamList])
  useEffect(() => {
    if (isAdmin == "true" && selectedUserForChat && Object.keys(selectedUserForChat).length > 0 && !selectedUserForChat.generalChat && pageName == "Chat") {
      // setShowUserList(true);
      dispatch(setAllChatUserList(true));
    }
    
    },[selectedUserForChat, pageName])
  useEffect(() => {
    jquery("body").removeClass("task-modal-offcanvas-open");
    if(pageName == "staff" || pageName == "client" || pageName == "_equipments")
    {
      jquery('._staff').attr('style', 'width:150px');
    }
    if(pageName == 'credit_notes')
    {
      jquery('._credit_notes').attr('style', 'width: 100px');
    }
    setFilterTaskActive([]);
    dispatch(setActiveTaskStatusFilters([], 1));
    filterStatus = [];
  }, [pageName])
  useEffect(() => {
    // if(pageName == 'dashboard' || pageName == "Dashboard"){
      if(globalView == "listView" || globalView == "calendarView" || globalView == "kanbanView"){
      localStorage.removeItem("is_dashboard");
      localStorage.setItem("is_dashboard", !isNaN(Number(projectId)) && Number(projectId) > 0 ? 0 : 1);
    }else  {
      localStorage.removeItem("is_dashboard");
      localStorage.setItem("is_dashboard",0);
    }

    if(pageName == "LiteVersion"){
      setSelectedOffice()
    } else if((globalView == "listView" && (projectId == null || projectId == 0 || projectId == undefined)) || pageName != "dashboardnew" ){
      const firstProjectId = spaces.find(
        (offices) => offices.status == "1" || offices.status == "2"
      );
      if (pageName != "dashboardnew") {
        // setSelectedOffice(
        //   savedProject
        //     ? savedProject
        //     : firstProjectId && firstProjectId.project_id
        //       ? firstProjectId.project_id
        //       : 0
        // );
      }
    }
  },[pageName , globalView]);
  useEffect(() => {
    if (selectedProject && pageName != "dashboardnew" && is_registered != 1) {
      setSelectedOffice(leftPanelTab !== "my-space" ? 0 : Number(selectedProject));
      setSelectedSpace(leftPanelTab !== "my-space" ? {} : selectedSpace )
    }
  },[selectedProject])
  useEffect(() => {
    if (getOnboardDocument && Object.keys(getOnboardDocument).length > 0) {
      setOnboardDocument(getOnboardDocument);
    }
  },[getOnboardDocument]);
  useEffect(() => {
    if(invoiceCounter)
    {
      setInvoiceCount(invoiceCounter.data);
    }
    if(estimateCounter)
    {
      setEstimateCount(estimateCounter.data);
    }
  }, [invoiceCounter, estimateCounter])
  useEffect(() => {
    if(pageName == "invoice" || pageName == "estimate" || pageName == "subscription" || pageName == "_equipments" || pageName == "staff" || pageName == "client" || pageName == "AdminTaskPage" || pageName == "credit_notes" || pageName == "fullcalendar" || pageName == "benchmark" || pageName == "schedule" )
    {
      jquery(".office-box").removeClass("active");
      jquery(".left-panel-company-setting").addClass("active");
    }
  }, [pageName])

  useEffect(() => {

    const activeSpace = localStorage.getItem("is_active_space");
    if((spaceState && spaceState.length == 0) && (virtualSpaceList && virtualSpaceList.length == 0) && (is_registered == 1) && !["Proposals", "SpaceOnBoarding", "SpaceOnBoardingNew", "MyProfile", "PremiumSubscription","hireNewAgent","OnboardingProposal","OnboardingEmployee"].includes(pageName)){
      hideLeftPanelNew();
      // navigate("/aichat");
      // dispatch(setSelectedGloablView("AichatView"))
    } else if((isNaN(activeSpace) || Number(activeSpace) <= 0) && !["Proposals", "SpaceOnBoarding", "SpaceOnBoardingNew", "MyProfile", "PremiumSubscription"].includes(pageName) && localStorage.getItem("space_setup_pending") != 1)
    {
      // navigate("/premiumsubscription");
      // navigate("/myprofile");

    }
    
    const space_setup_pending = localStorage.getItem("space_setup_pending");

    if(space_setup_pending > 0 && !["ChatAi", "SpaceOnBoarding", "SpaceOnBoardingNew", "MyProfile", "PremiumSubscription" ,"Proposals"].includes(pageName) && ["operator", "contact"].includes(userType) && contact_role != 3){
      if(userType === "contact" || (userType === "operator" && (!spaceArray.length || spaceArray.every((w) => w.is_added_from_operator == 1))))
      {
        if (userType === "operator" && providerWallet == 0 ) {
          dispatch(selectJobProposalPage(false));
        } else {          
          navigate("/");
          dispatch(setSelectedGloablView("AichatView"));
        }
      }
    }

    const invitedProject = spaceArray.find((w) => w.space_invitation_alert);

    if(invitedProject)
    {
      setSpaceInvitationData({
        project_id: invitedProject.project_id,
        message: invitedProject.space_invitation_alert
      });
    }

    let currentSpaceDetails = spaceArray.find((w) => w.project_id == selectedOffice && w.project_id != -1);
    if(currentSpaceDetails)
    {

      if(currentSpaceDetails.is_external > 0 && !["/connect-provider"].includes(viewPage) && !["MyProfile", "ChatAi"].includes(pageName))
      {
        navigate(`/aichat`);
      }

      if(currentSpaceDetails.pending_benchmarks && currentSpaceDetails.pending_benchmarks.length && !["SpaceOnBoarding", "SpaceOnBoardingNew", "MyProfile", "PremiumSubscription","ChatAi"].includes(pageName))
      {
        if(currentSpaceDetails.pending_benchmarks.length === 1)
        {
          navigate(`/connect-provider?space=${currentSpaceDetails.project_id}&provider-type=benchmark&id=${currentSpaceDetails.pending_benchmarks[0].id}`);
        }
        else {
          navigate(`/explore-features?setting-menu=providers&section=benchmark&category=${currentSpaceDetails.pending_benchmarks[0].provider_id}&space=${currentSpaceDetails.project_id}`);
        }
      }
      else if(userType !== "staff" && currentSpaceDetails.status == 0 && ![, "SpaceOnBoarding", "SpaceOnBoardingNew", "MyProfile", "PremiumSubscription", "ChatAi","Dashboard"].includes(pageName))
      {
        if(userType == "operator")
        {
          navigate(`/proposals`, { state: { from: viewPage } });
        }
        else {
          navigate(`/explore-features?space=${currentSpaceDetails.project_id}`);
        }
      }
      else if(currentSpaceDetails.is_external && currentSpaceDetails.schedule_id && !["Chat"].includes(pageName) && userType !== "operator")
      {
        dispatch(
          setOnboardingStepData({
            showChat: true,
          })
        );
        // setBenchmarkDiscardModal(currentSpaceDetails);
      }
      else if(userType === "operator" && Number(currentSpaceDetails.is_added_from_operator) && !currentSpaceDetails.is_onboard_completed_operator && !["PremiumSubscription", "Proposals", "PaymentPage", "SpaceOnBoarding", "MyProfile", "SpaceOnBoardingNew", "ChatAi"].includes(pageName))
      {
        navigate(`/explore-features?space=${selectedOffice}`);
      }
      else if(userType === "contact" && !Number(currentSpaceDetails.is_added_from_operator) && !currentSpaceDetails.is_onboard_completed_contact && !["PremiumSubscription", "PaymentPage", "SpaceOnBoarding", "MyProfile", "SpaceOnBoardingNew", "ChatAi"].includes(pageName))
      {
        navigate(`/explore-features?space=${selectedOffice}`);
      }
    }
    else if(spaceArray.length === 1 && ((spaceArray[0].pending_benchmarks && spaceArray[0].pending_benchmarks.length) || spaceArray[0].status == 0) && !["SpaceOnBoarding", "SpaceOnBoardingNew", "MyProfile", "PremiumSubscription", "ChatAi"].includes(pageName))
    {
      if(spaceArray[0].status == 0)
      {
        if(userType == "operator")
        {
          navigate(`/aichat`, { state: { from: viewPage } });
        }
        else {
          navigate(`/explore-features?space=${spaceArray[0].project_id}`);
        }
      }
      else if(!["SpaceOnBoarding", "SpaceOnBoardingNew", "MyProfile", "PremiumSubscription", "ChatAi"].includes(pageName)) {
        if(spaceArray[0].pending_benchmarks.length === 1)
        {
          navigate(`/connect-provider?space=${spaceArray[0].project_id}&provider-type=benchmark&id=${spaceArray[0].pending_benchmarks[0].id}`);
        }
        else {
          navigate(`/explore-features?setting-menu=providers&section=benchmark&category=${spaceArray[0].pending_benchmarks[0].provider_id}&space=${spaceArray[0].project_id}`);
        }
      }
    }
    else if(spaceArray.length  && !currentSpaceDetails && !isUserHaveOnboardingSpace(spaceArray) && !["PremiumSubscription", "Proposals", "PaymentPage", "SpaceOnBoarding", "MyProfile", "SpaceOnBoardingNew"].includes(pageName)) {
      // navigate("/premiumsubscription");
    }


    if (spaces.length && spaceState.length) {
      let sortedSpaces = spaces.filter(
        (space) => space.status == "1" || space.status == "2" || space.status == "0"
      );
      if (userType == 'staff') {
        sortedSpaces = spaces.filter(
          (space) => space.status == "1" || space.status == "2" || space.status == "0"
        );
      }
      if (
        selectedOffice > 0 &&
        sortedSpaces.length &&
        sortedSpaces.find((space) => space.project_id == selectedOffice)
      ) {
        sortedSpaces = [
          sortedSpaces.find((space) => space.project_id == selectedOffice),
          ...sortedSpaces.filter((space) => space.project_id != selectedOffice),
        ];
        //
      }
      setActiveSpaces(sortedSpaces.length ? sortedSpaces : []);
      const firstProjectId = spaces.find(
        (offices) => offices.status == "1" || offices.status == "2"
      );
      // if(globalView == "listView" && (projectId == null || projectId == 0 || projectId == undefined)){
        if (pageName != "dashboardnew" && is_registered != 1){
          if (globalView == "AichatView" && !projectId > 0) {
            // setSelectedProject(
            //   savedProject
            //     ? savedProject
            //     : firstProjectId && firstProjectId.project_id
            //       ? firstProjectId.project_id
            //       : 0
            // )
          }else if (globalView !== "AichatView" && leftPanelTab != "my-request") {
            // setSelectedProject(
            //   savedProject
            //     ? savedProject
            //     : firstProjectId && firstProjectId.project_id
            //       ? firstProjectId.project_id
            //       : 0
            // )
          }
        }
      // ;}

      if (is_registered != 1 && pageName != "dashboardnew" && !Number(selectedOffice) &&  leftPanelTab == "my-space") {  
        // setSelectedOffice(
        //   savedProject
        //     ? savedProject
        //     : firstProjectId && firstProjectId.project_id
        //       ? firstProjectId.project_id
        //       : 0
        // );
      }

      localStorage.removeItem("selectedVirtualSpaceId");
      if (localStorage.getItem("user_type") == "staff") {
        let tempProjectId = savedProject
          ? savedProject
          : firstProjectId && firstProjectId.project_id
            ? firstProjectId.project_id
            : 0;
        let spaceDetails = sortedSpaces.filter(
          (space) => space.project_id == tempProjectId
        );
        if (spaceDetails.length) {
          localStorage.setItem(
            "virtualSpaceClientId",
            spaceDetails[0].client_id ? spaceDetails[0].client_id : ""
          );
        }
      }
    } else if (spaces.length && spaceState.length == 0) {
      setActiveSpaces([]);
      
    }else {
      setActiveSpaces(spaceState)
    }

    if (
      isOfficeSelected != "" &&
      activeSpaces &&
      activeSpaces.length &&
      localStorage.getItem("selectedOffice") &&
      parseInt(localStorage.getItem("selectedOffice")) > 0
    ) {
      const spaceId = spaces.filter(
        (space) => space.project_id == localStorage.getItem("selectedOffice")
      );
      if (spaceId[0]) {
        setOfficeOnTop(spaceId[0].id);
        setSpaceDetails(spaceId[0]);

        if (selectedOffice == 0 && pageName != "dashboardnew" && leftPanelTab == "my-space") {
          // setSelectedOffice(spaceId[0].project_id);
        }
      }
      setIsOfficeSelected("");
    }

    if (activeSpaces &&
      activeSpaces.length &&
      localStorage.getItem("selectedOffice") &&
      parseInt(localStorage.getItem("selectedOffice")) > 0) {
      const spaceId = spaces.filter(
        (space) => space.project_id == localStorage.getItem("selectedOffice")
      );
      if (spaceId[0] && spaceId[0] != undefined && pageName != "dashboardnew") {
        // setSelectedSpace(spaceId[0])
       
      }
    }
    else if(userType == "operator" && pageName == "Proposals")
    {
      dispatch(getDefaultTopics(0, "", "", pageName));
      dispatch(getTopicTasks(0, pageName));
    }

    if (pageName == "MyWallet") {
      setWalletActiveClass(false);
    } else if (pageName == "MyEmail") {
      setMyEmailActiveClass(false);
      setLeftPanelEmailPage(true);
    }else{
      setLeftPanelEmailPage(false);
    }
    if (window.location.pathname === "/leads") {
      setMyLeadActiveClass(false);
    } else if (pageName == "PremiumSubscription") {
      setPremiumPageActiveClass(false);
    } else if (pageName == "CompanyProfile") {
      setCompanyProfileActiveClass(false);
    }
   
    spaces.map((space, index) => {
      if (selectedOffice === space.project_id) {
        dispatch(setTaskUnreadCmtCntAll(space.notification_count));
      }
    });
    if (activeSpaces.length) {
      reorderOffices();
    } 
    if (spaceState && spaceState.length) {
       userType == "staff" ? setSpaces(spaceState.concat(virtualSpaceList)) : setSpaces(spaceState);
       let tempSpaces = spaceState.concat(userType == "staff" ? virtualSpaceList : spaceState).filter((space)=>{
        if (userType != "operator") { 
          return space.is_project_member == 1
        }else{
         return space
        }
       })
        setSpaces(tempSpaces)
      }

    //Block Operator to access platform in case of pending payment action
    if(userType === 'operator')
    {
      let pendingPayments = localStorage.getItem('pending_payments');
      pendingPayments = pendingPayments ? JSON.parse(pendingPayments) : [];
      if(pendingPayments.length)
      {
        setPendingPaymentInfo(pendingPayments[0]);
        localStorage.removeItem("pending_payments");
      }
    }
    
  }, [spaceState, JSON.stringify(spaces) ,selectedOffice, spaceArray.length  ]);

  useEffect(() => {
    if((spaceState && spaceState.length == 0) && is_registered == 1 && (virtualSpaceList && virtualSpaceList.length == 0) && !["Proposals", "SpaceOnBoarding", "SpaceOnBoardingNew", "MyProfile", "PremiumSubscription","hireNewAgent","OnboardingProposal","OnboardingEmployee"].includes(pageName)){
      // hideLeftPanelNew();
      // navigate("/aichat");
      // dispatch(setSelectedGloablView("AichatView"))
    }
  }, [pageName, globalView])
  
  useEffect(() => {
    handleVisibleMenus();
    CustomerServices.getOverDueTaskCOunt(staffOrContactId,contact_id,client_id).then((res)=>{
      if (res.status) {
        setOverdueTaskCounts(res.data)          
      }
    })
    localStorage.removeItem("is_pilot_onboarding");
  }, [])

  // useEffect(() => {
  //   if (isContactDir) {
  //     let group = JSON.parse(localStorage.getItem("getGroupDetails"))
  //     // let group = {groupId: localStorage.getItem("activeGroupId")}
  //     console.log(group,"gorup2");
  //     contactDirTopicChange(group ? group : {},false)
  //   }
  // }, [JSON.stringify(groupListExternal)])
  
  useEffect(() => {
    if (projectAttendancCounts) {
      setProjectTaskCount(projectAttendancCounts)
    }
  }, [projectAttendancCounts])

  
  

  const handleChangeProjectStatus =(id)=>{
    if(id != 7){

      
      CustomerServices.changePeojectStatus(project_id,id ).then((res) => {
        if(res.status){
          setSelectedspaceStatus(id)
          dispatch(showMessage("sucess", _l('l_success'), res.message ?  _l(res.message)  : _l('l_space') + _l("l_status") + _l("l_change") + _l('l_successfully')));
          // dispatch(getCustomerSpaces(client_id, contact_id));        
          let index = activeSpaces.findIndex((space)=> parseInt(space.project_id) == parseInt(project_id));
    
          let updatelsit = activeSpaces[index]
    
          updatelsit.project_status = id
          // setInActiveSpaces((Object.values({...activeSpaces , [index] :updatelsit})))
        }else{
        dispatch(showMessage("unsucess", _l('l_error'), res.message ?  _l(res.message)  : _l('l_something_went_wrong')));

        }
      })
      
    }else{
      handleSpaceDelete()
    }
    
  }
  

  useEffect(() => {
    if (isAdmin == "true" && userType == "staff" && ( globalView =="liteVersionView" || pageName == "Chat") && isAllChatUserlistShow) {
      jquery(".provider-list").addClass("expanded");
      showLeftPanelNew();
      dispatch(setAllChatUserList(true));
    }else{
      dispatch(setAllChatUserList(false));
    }
    if(( globalView != "liteVersionView") &&  pageName != "Chat"){
      localStorage.setItem("generalChatForLiteVersion",false);
      localStorage.setItem('isLiteVersion',false);
    }
    if(pageName != "PremiumSubscription"){
      setPremiumPageActiveClass(true)
    }
  },[pageName, globalView]);
  
  const OnMouseOverOfficeList = () => {
    hideRightPanelNew();
    if((userType == "contact" || userType ==  "operator") && Number(contact_role) == 3){
      showLeftPanelNew();
    }else{
      addexpandedClass();
      jquery("body").addClass("hover-left-penal");      
    }
    jquery(".task-comment-modal").removeClass("show-comments");
    HideDropdown();
    
  };
  
  useEffect(() => {
    if (localStorage.getItem('selectedTheme') == "light") {
      jquery('html').addClass('light-theme');
    } else {
      jquery('html').removeClass('light-theme');
    }
  }, []);
  
  useEffect(() => {
    if (projectId > 0 && spaceState.length > 0) { 
      if(!["SpaceOnBoarding", "MyProfile", "ConnectProvider"].includes(pageName) && contact_role != 3 && is_registered != 1)
      {
        showLeftPanelNew();
      }
      const tempSelectedSpace = spaceState.filter((s) => s.project_id == projectId)[0]
      // setSelectedSpace(tempSelectedSpace ? tempSelectedSpace : {});
    }
    
    if (pageName == "Chat" || pageName == "Proposals" || pageName == "invoice" || pageName == "estimate" || pageName == "subscription" || pageName == "staff" || pageName == "AdminTaskPage" || pageName == "credit_notes"  || pageName == "fullcalendar" || pageName == "benchmark" || pageName == "schedule" || pageName =="PremiumSubscription") {

    } else {
      // jquery(".office-list-icon ").on("mouseover", function () {
      //   OnMouseOverOfficeList();
      // });
    
    }
    jquery(document).mouseup(function (e) {
      var container = jquery(".fixed-left-panel");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        setTeamLeftPanel(false);
        setshowLeftPanelMenu(false);
        // HideDropdown();
      }
    });
    jquery(".office-list-icon-search").on("click", function () {
      showLeftPanelNew();
      addexpandedClass();
      // setTimeout(function () {
      //   jquery(".office-list-icon-search-input").focus();
      // }, 500);
    });
   
    jquery(".left_panel_toggle").on("click", function () {
    });
    jquery(".office-box").on("click", function (event) {
    });
    if (spaces && spaces.length && projectId) {
      const project = spaces.filter((s) => s.project_id == projectId);
      if (
        projectId &&
        (!customerData.clientTeamData ||
          !customerData.clientTeamData[parseInt(projectId)])
      ) {
        // dispatch(getClientTeamData(projectId));
      }
      if (project.length) {
        // setSelectedSpace(project[0]);
        localStorage.setItem("selected_project_client_id",project.client_id);
        setOfficeWorkSpaceScore(project[0].workspace_score);
        if (localStorage.getItem("user_type") == "staff") {
          localStorage.setItem(
            "virtualSpaceClientId",
            project[0].client_id ? project[0].client_id : ""
          );
        }
      } else {
        // setSelectedSpace(spaces[0]);
        localStorage.setItem("selected_project_client_id",spaces[0].client_id);
        setOfficeWorkSpaceScore(spaces[0].workspace_score);
      }
      if(!["operatorListReporting","equipmentListReporting"].includes(pageName)){
        dispatch(projectTaskCounts(projectId, "", "", isLeadPage, pageName, globalView));
        if (isLeadPage) {
          dispatch(
            getTaskCount(client_id, contact_id, projectId, formateDate(startDate), taskFilters, '', '', '', '', 1, pageName,  '', '', leadsFilters)
          );
        }
      }
      // dispatch(getTopicTasks(projectId, pageName,0,startDate,taskFilters));
      if (pageName == "dashboardnew") {
        dispatch(getDefaultTopics(null, "", "", pageName,taskFilters));
      }else{
        dispatch(getDefaultTopics(projectId, "", "", pageName,taskFilters));
      }
      
    }else if(adminTeamList && adminTeamList.length > 0 && localStorage.getItem("selectedVirtualSpace") &&
    JSON.parse(localStorage.getItem("selectedVirtualSpace")).id){
      dispatch(getDefaultTopics(adminTeamList[0].id, "", "", pageName,taskFilters));
    }
    if (userType != "staff") {
      CustomerServices.getPremiumEstimateDetails().then((res) => {
        if (res.status) {
          setEstimateDetails(res.data);
          localStorage.setItem(
            "iban_modal_open",
            res.data && res.data.open_modal
          );
          // localStorage.setItem("membership_package_id",res.data && res.data.package_id);
          dispatch(isIBANModalOpen());
        } else {
          setEstimateDetails({});
        }
      });
    }
  }, [projectId, pageName]);  
  useEffect(() => {
    if(defaultTopics != undefined) {
      setdefaultTopicsstate(defaultTopics)
      defaultTopics.map((dt) => {
        if (dt.main_key == "my_task") {
          if (dt.categories) { 
            setForMeTopic(dt.categories.map((dd)=> {return{...dd,main_key:dt.main_key}}))
          }
        }
      })
    }
  }, [defaultTopics])

  // useEffect(() => {
  //   if (globalView == "calendarView" && userType != "staff") {
  //     dispatch(leftPanelFilters("", "", "topicFilterClear"));
  //   }
  // }, [globalView])

  const getAdminTeamList = async () => {
    try {
      const response = await AdminServices.getVirtualSpaceList();

      setAdminTeamList(
        response.data.map((data) => {
          return { ...data, handler: selectAdminTeam };
        })
      );
      dispatch(setVirtualSpaceList(response.data));
    } catch (e) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_something_went_wrong"))
      );
    }
  };

  useEffect(() => {
    if (customerData.clientTeamData) {
      setClientTeamData(customerData.clientTeamData[parseInt(projectId)]);
    }
  }, [customerData.clientTeamData]);

  useEffect(() => {
    if(space_pending == 1){
      hideLeftPanelNew();
    }
    
  }, [space_pending])
  

  useEffect(() => {
    if (removedFilterStatus && removedFilterStatus.flag == true) {
      filterTasksByStatus(removedFilterStatus.status);
    }
  }, [removedFilterStatus]);

  useEffect(() => {
    if (customerData.taskCounts) {
      setProjectTaskCount(customerData.taskCounts);
    }
  }, [customerData.taskCounts]);
  
  useEffect(() => {
    if (
      customerData.createSchduleDetails &&
      Object.keys(customerData.createSchduleDetails).length > 0 &&
      pageName != "benchmarkList"
    ) {
      dispatch(setCreateScheduledetails({}));
    }
  }, [customerData.createSchduleDetails]);
  const reorderOffices = () => {
    let activeSpaceList = spaceState.filter(
      (x) => x.status === "1" || x.status === "2"
    );

    activeSpaceList = [
      spaceState.find((space) => space.project_id == selectedOffice),
      ...spaceState.filter(
        (space) =>
          userType == 'staff' ? space.project_id != selectedOffice &&
            (space.status === "1" || space.status === "2" || space.status == '0') : space.project_id != selectedOffice &&
          (space.status === "1" || space.status === "2" || space.status == '0')
      ),
    ];
    if (activeSpaceList.length > 0) {
      setActiveSpaces(
        activeSpaceList.sort(function (a, b) {
          if (parseInt(a.pin_count) < parseInt(b.pin_count)) return 1;
          if (parseInt(a.pin_count) > parseInt(b.pin_count)) return -1;
          if (!a.chat_group_message || a.chat_group_message === '') return 1;
          if (!b.chat_group_message || b.chat_group_message === '') return -1;
          const chatA = moment(a.chat_group_message_date ? a.chat_group_message_date : '');
          const chatB = moment(b.chat_group_message_date ? b.chat_group_message_date : '');
          if (chatA.isBefore(chatB)) return 1;
          if (chatA.isAfter(chatB)) return -1;
          if ((parseInt(a.notification_count) + parseInt(a.chat_notification_count) + parseInt(a.unread_notification_count)) > (parseInt(b.notification_count) + parseInt(b.chat_notification_count) + parseInt(b.unread_notification_count))) return -1;
          if ((parseInt(a.notification_count) + parseInt(a.chat_notification_count) + parseInt(a.unread_notification_count)) < (parseInt(b.notification_count) + parseInt(b.chat_notification_count) + parseInt(b.unread_notification_count))) return 1;
          if (a.project_id == selectedOffice) return -1;
        
          return 0;
        })
      );
    }
  };

  const setOfficeOnTop = (spaceId) => {
    if (activeSpaces.length > 0) {
      const targetSpace = activeSpaces.filter(
        (office) => office && office.id === spaceId
      );
      const otherOffices = activeSpaces.filter(
        (office) => office && office.id !== spaceId
      );
      var allOffices = targetSpace.concat(otherOffices);
      allOffices.sort(function (a, b) {
        if (parseInt(a.pin_count) < parseInt(b.pin_count)) return 1;
        if (parseInt(a.pin_count) > parseInt(b.pin_count)) return -1;
        if (!a.chat_group_message || a.chat_group_message === '') return 1;
        if (!b.chat_group_message || b.chat_group_message === '') return -1;
        const chatA = moment(a.chat_group_message_date ? a.chat_group_message_date : '');
        const chatB = moment(b.chat_group_message_date ? b.chat_group_message_date : '');
        if ((chatA).isBefore(chatB)) return 1;
        if ((chatA).isAfter(chatB)) return -1;
        if ((parseInt(a.notification_count) + parseInt(a.chat_notification_count) + parseInt(a.unread_notification_count)) > (parseInt(b.notification_count) + parseInt(b.chat_notification_count) + parseInt(b.unread_notification_count))) return -1;
        if ((parseInt(a.notification_count) + parseInt(a.chat_notification_count) + parseInt(a.unread_notification_count)) < (parseInt(b.notification_count) + parseInt(b.chat_notification_count) + parseInt(b.unread_notification_count))) return 1;
        if (a.project_id == spaceId) return -1;
        return 0;
      });
      setActiveSpaces(allOffices);
    }
  };

  useEffect(() => {
    let allSpaces = spaces.filter(
      (space) => space.status == "1" || space.status == "2"
    );
    if (allSpaces.length > 0) {
      const targetSpace = allSpaces.filter(
        (office) => office.id === isOfficeSelected.id
      );
      const otherOffices = allSpaces.filter(
        (office) => office.id !== isOfficeSelected.id
      );
      var allOffices = targetSpace.concat(otherOffices);
      allOffices.sort(function (a, b) {
        if (parseInt(a.pin_count) < parseInt(b.pin_count)) return 1;
        if (parseInt(a.pin_count) > parseInt(b.pin_count)) return -1;
        if (!a.chat_group_message || a.chat_group_message === '') return 1;
        if (!b.chat_group_message || b.chat_group_message === '') return -1;
        const chatA = moment(a.chat_group_message_date ? a.chat_group_message_date : '');
        const chatB = moment(b.chat_group_message_date ? b.chat_group_message_date : '');
        if ((chatA).isBefore(chatB)) return 1;
        if ((chatA).isAfter(chatB)) return -1;
        if ((parseInt(a.notification_count) + parseInt(a.chat_notification_count) + parseInt(a.unread_notification_count)) > (parseInt(b.notification_count) + parseInt(b.chat_notification_count) + parseInt(b.unread_notification_count))) return -1;
        if ((parseInt(a.notification_count) + parseInt(a.chat_notification_count) + parseInt(a.unread_notification_count)) < (parseInt(b.notification_count) + parseInt(b.chat_notification_count) + parseInt(b.unread_notification_count))) return 1;
        if (a.project_id == isOfficeSelected.id) return -1;
        return 0;
      });
      setActiveSpaces(allOffices);
    }
  }, []);

  useEffect(()=>{
    if(spaceClient && spaceClient.project_id)
    {
      onClickOfficeChange(spaceClient);
      localStorage.setItem("selected_project_client_id",spaceClient.client_id);
      let data = [];
      dispatch(clientTabData(data));
    }
  }, [spaceClient])
  // useEffect(() => {
  //   // if(pageName != "LiteVersion" && globalView != "liteVersionView"){
  //     if (globalView == "spaceView" && getSelectedFloorId > 0) {
  //       dispatch(getTaskCount(client_id, contact_id, projectId, localStorage.getItem("selectedDate") ? moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD") : (moment(new Date()).format("YYYY-MM-DD")), taskFilters, '', '', '', '', 0, pageName, (projectId == null || projectId == 0 || projectId == undefined) ? "liteVersionView" : globalView,getSelectedFloorId));
  //     }else{
  //       dispatch(getTaskCount(client_id, contact_id, projectId, localStorage.getItem("selectedDate") ? moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD") : (moment(new Date()).format("YYYY-MM-DD")), taskFilters, '', '', '', '', 0, pageName, (projectId == null || projectId == 0 || projectId == undefined) ? "liteVersionView" : globalView));
  //     }
  //   // }
  // },[taskFilters,getSelectedFloorId,globalView])
  const handleOfficeChange = (space, is_virtual_space = false) => {
    // dispatch(leftPanelFilters("","","topicFilterClear"))
    dispatch(lastSessionOfChat([]))

    setSelectedSpace(space);
    dispatch(commanoffcanvasisOpen(false)) 
    dispatch(SetSelectedTask({}));
    let project_id = space.project_id ? parseInt(space.project_id) : 0;
    if (is_virtual_space) {
      dispatch(setUnreadMsgCount(+space.unread_chat_msg_count > 0 ? +space.unread_chat_msg_count : 0))
    }else{
    dispatch(setUnreadMsgCount(+space.chat_notification_count > 0 ? +space.chat_notification_count : 0))
    }
    dispatch(setTaskUnreadCmtCntAll(parseInt(0)));
    if (is_virtual_space) {
      localStorage.setItem(
        "prevSelectedOffice",
        localStorage.getItem("selectedOffice")
      );
      project_id = space.id;
      setTeamLeftPanel(false);
      dispatch(getTopicTasks(project_id, "", 1));
      dispatch(getDefaultTopics(projectId, "", "", pageName));
      dispatch(setTaskUnreadCmtCntAll(parseInt(space.unread_notification_count)));
      // jquery("#intro-office-list").removeClass("expanded");
    } else {
      removeVirtualSpace();
      setSelectedTeam();
      setSelectedOffice();
      setOfficeOnTop(space.id);
      setSpaceDetails(space);
      if (project_id != localStorage.getItem("selectedOffice")) {
        setShowLoader(true);
      } else {
        setOfficeOnTop(space.id);
      }
    }

    setshowSearch(false);
    setSearchCategory("");
    if (setTaskModalClose) {
      setTaskModalClose();
    }

    setSelectedOffice(Number(project_id));
    setSelectedProject(Number(project_id));
    dispatch(setProject(project_id));
    if(pageName === "SpaceOnBoarding")
    {
      let id = searchParams.get("space");
      if(id)
      {
        searchParams.set("space", project_id);
      }
      else {
        searchParams.append("space", project_id);
      }
      setSearchParams(searchParams);
    }
    dispatch(is_discord_connected(project_id));
    dispatch(is_google_drive_connected(project_id));
    dispatch(is_googlecalendar_connected(project_id));
    
    var chatUserId = localStorage.getItem("chatUserId");
    dispatch(fetchUnreadMsgCount(chatUserId, localStorage.getItem("isLiteVersion") == "true" ? 0 : project_id));

    dispatch(getTaskCount(
        client_id,
        contact_id,
        project_id,
       moment(localStorage.getItem('__selectedDate')).format("YYYY-MM-DD") ? moment(localStorage.getItem('__selectedDate')).format("YYYY-MM-DD") : moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD") ,
        taskFilters,
        activeTaskStatusFilters,
        "",
        "",
        "",
        (projectId == null || projectId == 0 || projectId == undefined) ? "liteVersionView" : globalView
    ));
   
    if (
      !customerData.clientTeamData ||
      !customerData.clientTeamData[parseInt(projectId)]
    ) {
      // dispatch(getClientTeamData(project_id));
    } else {
      setClientTeamData(customerData.clientTeamData[project_id]);
    }
    localStorage.setItem("selectedOffice", project_id);
    if (userType == "operator") {
      localStorage.setItem(
        "is_added_from_operator",
        space.is_added_from_operator ? space.is_added_from_operator : 0
      );
      localStorage.setItem(
        "operatorClientId",
        space.client_id ? space.client_id : 0
      );
      // if (space.is_added_from_operator == 1) {
      localStorage.setItem(
        "spaceClientDetails",
        JSON.stringify([
          {
            cp_id: space.client_id ? space.client_id : 0,
            mobile: space.phonenumber ? space.phonenumber : "",
            email: space.email ? space.email : "",
            profile_url: space.profile_image ? space.profile_image : "",
          },
        ])
      );
      // }
    }

    if (pageName == "dashboard") {
      
    }
    if (["SpaceOnBoarding", "Chat", "Proposals"].includes(pageName)) {
      jquery(".provider-list").addClass("expanded");
    }
    setTimeout(() => {
      setShowLoader(false);
    }, 3000);
    setTeamLeftPanel(false);
    setSearchString("")
  };
  const onPagechangeToggle = () => {
  };
  const filterTasksByStatus = (statusId) => {
    let otherFilters = customerData.taskFilters ? customerData.taskFilters : [];

    // Remove all status which are not active
    filterStatus.forEach((status) => {
      if (!jquery(`#kpi_task_status_` + status).hasClass("active")) {
        setFilterTaskActive([...filterTaskActive, status]);
        var sIndex = filterStatus.indexOf(status);
        filterStatus.splice(sIndex, 1);
      }
    });
    const index = filterStatus.indexOf(statusId);

    if (index === -1) {
      filterStatus.push(statusId);
      jquery(`#kpi_task_status_` + statusId).addClass("active");
      setFilterTaskActive([...filterTaskActive, statusId]);
    } else {
      filterStatus.splice(index, 1);
      setFilterTaskActive(
        filterTaskActive.filter((status) => status != statusId)
      );
    }
    
    if (isLeadPage) {
      dispatch(
        getTaskCount(
          0,
          0,
          localStorage.getItem("selectedOffice"),
          moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD"),
          otherFilters,
          "",
          filterStatus,
          "",
          "",
          1,
          pageName,
          (projectId == null || projectId == 0 || projectId == undefined) ? "liteVersionView" : globalView,
          '',
          leadsFilters, globalSearchString
        )
      );
    } else {

      dispatch(
        getTaskCount(
          0,
          0,
          localStorage.getItem("selectedOffice"),
          moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD"),
          otherFilters,
          "",
          filterStatus,
          "",
          "",
          0,
          pageName,
          (projectId == null || projectId == 0 || projectId == undefined) ? "liteVersionView" : globalView
        )
      );
    }

    var isDateActive = 0;
    var viewAll = 1;
    if (filterStatus.length > 0) {
      clearStatusFilter(false);
    } else if (globalView == "calendarView"){
      jquery("#active_week_date").removeClass("c-gradinet-active-border");      
    }else {
      isDateActive = 1;
      viewAll = 0;
      addActiveBorderforWeekCalendar();
    }
    dispatch(
      setActiveTaskStatusFilters({
        filterStatus,
      })
    );
    // if (
    //   filterStatus.length > 0 &&
    //   otherFilters.findIndex((x) => x.main_key === "date_range_filter") !== -1
    // ) {
    //   dispatch(
    //     leftPanelFilters({
    //       main_key: "date_range_filter",
    //       filter_key: "date_range_filter",
    //       title: "date_range_filter",
    //     })
    //   );
    // } else {
    // }
  };

  const spaceFilterOnStatus = (value, key) => {
    value = value.toString();
    let newArr = [...spaceFilter, value];

    if (spaceFilter.filter((x) => x == value).length) {
      newArr = spaceFilter.filter((c) => c != value);
    }
    setSpaceFilter(newArr);
    
    if(key === "my_spaces")
    {
      if(!projectFilters.my_spaces)
      {
        projectFilters.account_executive.push(adminUser);
        projectFilters.account_manager.push(adminUser);
        projectFilters.my_spaces = true;
      }
      else {
        projectFilters.account_executive = projectFilters.account_executive.filter((w) => w != adminUser);
        projectFilters.account_manager = projectFilters.account_manager.filter((w) => w != adminUser);
        projectFilters.my_spaces = false;
      }
    }
    else if (projectFilters[key])
    {
      if(projectFilters[key].includes(value))
      {
        projectFilters[key] = projectFilters[key].filter((w) => w.toString() != value.toString());
      }
      else {
        projectFilters[key].push(value);
      }
      if(projectFilters.my_spaces)
      {
        projectFilters.account_executive.push(adminUser);
        projectFilters.account_manager.push(adminUser);
      }
    }
 
    let allSpaces = spaces.filter(
      // (space) => userType == 'staff' ? space.status == "0" || space.status == "1" || space.status == "2" : space.status == "1" || space.status == "2" // only working for admin
      (space) => space.status == "0" || space.status == "1" || space.status == "2"
    );
    if (allSpaces.length > 0) {
      const targetSpace = allSpaces.filter(
        (office) => office.id === isOfficeSelected.id
      );
      const otherOffices = allSpaces.filter(
        (office) => office.id !== isOfficeSelected.id
      );
      var allOffices = targetSpace.concat(otherOffices);
      allOffices.sort(function (a, b) {
        if (parseInt(a.pin_count) < parseInt(b.pin_count)) return 1;
        if (parseInt(a.pin_count) > parseInt(b.pin_count)) return -1;
        if (!a.chat_group_message || a.chat_group_message === '') return 1;
        if (!b.chat_group_message || b.chat_group_message === '') return -1;
        const chatA = moment(a.chat_group_message_date ? a.chat_group_message_date : '');
        const chatB = moment(b.chat_group_message_date ? b.chat_group_message_date : '');
        if ((chatA).isBefore(chatB)) return 1;
        if ((chatA).isAfter(chatB)) return -1;
        if ((parseInt(a.notification_count) + parseInt(a.chat_notification_count) + parseInt(a.unread_notification_count)) > (parseInt(b.notification_count) + parseInt(b.chat_notification_count) + parseInt(b.unread_notification_count))) return -1;
        if ((parseInt(a.notification_count) + parseInt(a.chat_notification_count) + parseInt(a.unread_notification_count)) < (parseInt(b.notification_count) + parseInt(b.chat_notification_count) + parseInt(b.unread_notification_count))) return 1;
        if (a.project_id == isOfficeSelected.id) return -1;
        return 0;
      });

      let projectIdArray = [];
      if(searchMentionUsers.length)
      {
        projectIdArray = searchMentionUsers.reduce((commonProjects, obj) => 
        {
          const projects = obj.projects;
          return commonProjects.filter(project => projects.includes(project));
        }, searchMentionUsers[0].projects);
      }
      const myArrayFiltered = allSpaces.filter((el) => {
        let flag = true;
        if(projectIdArray.length && !projectIdArray.includes(el.project_id))
        {
          flag = false;
        }

        if(projectFilters["project_status"].length && !projectFilters["project_status"].includes(el.project_status))
        {
          flag = false;
        }

        if(flag)
        {
          return el;
        }
      });
      
      let appliedFilters = 0;
      Object.keys(projectFilters).forEach((key) => {
        if(projectFilters[key].length && !isNaN(projectFilters[key].length))
        {
          appliedFilters += projectFilters[key].length;
        }
      })
      if (appliedFilters > 0 || searchMentionUsers.length) {
        setActiveSpaces(myArrayFiltered);
      } else {
        setActiveSpaces(allOffices);
      }
    }
  };

  let onBoardingSpaces = spaceState;
  //This sort is bringing the selected space on top in onboarding page, to maintain the order by notifications, commenting this code
  // onBoardingSpaces.sort((a, b) => a.project_id == selectedOffice ? -1 : b.project_id == selectedOffice ? 1 : 0)
  // console.log("virtualSpaceList",virtualSpaceList ,"selectedStaffMembersForSpaceFilter",selectedStaffMembersForSpaceFilter,"spacesArray",spacesArray)
  let getSearchedOffice = []

   getSearchedOffice = pageName === "SpaceOnBoarding"
    ?
    onBoardingSpaces
    :
    spacesArray && (spacesArray.length || projectFilters.project_status.length || searchMentionUsers.length)
      ? removeDuplicates(spacesArray.filter(
        (space) =>{
          removeDuplicates(spaceArray)
            if(searchString !== "" || spaceFilter.length > 1 || userType != "staff" ){
              return(
            (space &&
              space.office_title &&
              space.office_title
                .toLowerCase()
                .includes(searchString.toLowerCase())) ||
            (space &&
              space.postal_address &&
              space.postal_address
                .toLowerCase()
                .includes(searchString.toLowerCase())) ||
            (space &&
              space.name &&
              space.name
                .toLowerCase()
                .includes(searchString.toLowerCase())) ||
            (space &&
              space.company &&
              space.company
                .toLowerCase()
                .includes(searchString.toLowerCase())) 
                || (space && space.pin_count && space.pin_count == 1) 
          )

          } else{
             return( 
              selectedStaffMembersForSpaceFilter.length == 0 ? 
              userType == "staff" && space != undefined && (space.is_project_member > 0 || (space.pin_count && space.pin_count == 1)) && space.project_status != 7
             : spacesArray && space.project_status != 7 )
          }
        }

      ))
      : spaceState && spaceState.length > 0 && userType == "staff"  ? spaceState.filter((space)=>{
        return space.members.some((member)=> member.staff_id == localStorage.getItem("staff_id") ||
        (space && space.pin_count && space.pin_count == 1))
       }) 
       :[];

       if (getSearchedOffice && getSearchedOffice.length > 0 && Object.keys(selectedSpace).length > 0 && selectedSpace.project_id && (!projectFilters.project_status.length /* && !mentionStaffArray.length */)) {
        if (!getSearchedOffice.some((office)=> office.project_id == selectedSpace.project_id)) {
          getSearchedOffice = getSearchedOffice.concat(selectedSpace);
        }
       }

  let getinActiveSpaces = inActiveSpaces.filter(
    (space) =>
      (space &&
        space.office_title &&
        space.office_title
          .toLowerCase()
          .includes(searchString.toLowerCase())) ||
      (space &&
        space.postal_address &&
        space.postal_address
          .toLowerCase()
          .includes(searchString.toLowerCase())) ||
      (space &&
        space.company &&
        space.company.toLowerCase().includes(searchString.toLowerCase()))
  );
  
  useEffect(() => {
    if (isStatusCleared) {
      filterStatus = [];
      setFilterTaskActive([]);
    }
  }, [isStatusCleared]);

  useEffect(() => {
    if (activeTaskStatusFilters && activeTaskStatusFilters.length) {
      setFilterTaskActive(activeTaskStatusFilters);
      if (viewPage == "/" || isLeadPage) {
    
      }
    }
  }, [activeTaskStatusFilters]);

  useEffect(() => {
    dispatch(getCustomerSpaces(client_id, staffOrContactId));

    // dispatch(getDefaultTopics(projectId, "", "", pageName));
    dispatch(fetchAuthToken(email, contact_id, "client"));
    if(pageName != "dashboardnew" && pageName != "MyEmail"){
      dispatch(getDefaultTopics(0, "", "", leftPanelTab == "my-request" ? "dashboardnew" : pageName));
    }
  }, []);

  useEffect(() => {
    if (leftPanelTab == "my-request" && defaultTopics && defaultTopics.length > 0) {
      dispatch(leftPanelFilters("","","applyForMewithAll"));
      dispatch(setDaterangeFilter("all"))
      dispatch(setDaterangeFilterApplied(true));
    }
  }, [leftPanelTab, JSON.stringify(defaultTopics)])
  

  const loginHandler = (email, clientId, type, id, projectID,allData) => {
    setSelectedProject()
    const from_admin_side = 1
    localStorage.setItem("user_client_id",clientId);
    localStorage.setItem("user_project_id",project_id == 0 ? projectId : project_id);
    let userType = allData.user_type.toLowerCase() == "provider" ? "staff" : "contact";
    setLoginAsUserLoader(true);
    authServices.getUserInfo(userType.toLowerCase(), id, from_admin_side).then(
      (res) => {
        if (res.status == 1 && res.data) {
          setSelectedProject()
          dispatch(setVirtualSpaceList([]));
          setSelectedTeam()
          localStorage.removeItem('selectedVirtualSpace')
            if (chatUserId != "" && chatUserId != undefined) {
              dispatch(userOffline({ userId: chatUserId }));
            }
          // if (res.data.registration_confirmed == 1) {
            dispatch(showFullScreenLoader());
            dispatch(
              loginAsUser(email, clientId, project_id == 0 ? projectId : project_id)
            ).then(async(loginRes) => {
              if(loginRes.status == 1){
                if (res.data.registration_confirmed == 0) {
                  localStorage.setItem("is_registration_confirmed", res.data.registration_confirmed);
                  localStorage.setItem("user_type", res.data.type);
                  localStorage.setItem("userId", res.data.id);
                }
                if (!localStorage.getItem("accessToken")) {
                  dispatch(
                    showMessage("unsucess", _l("l_error"), _l("l_invalid_credentials"))
                  );
                } else {
                  localStorage.setItem("password", "12345");
                  await loadDataToIDB(allData.staff_id);
                  if (localStorage.getItem("membership_package_id") == 1) {
                    // navigate("/premiumsubscription");
                  navigate("/myprofile");

                  } else {
                    const client_id = localStorage.getItem("client_id");
                    const contact_id = localStorage.getItem("contact_id");
                    localStorage.setItem("selectedOffice",project_id == 0 ? projectId : project_id);
                    dispatch(getCustomerSpaces(client_id, contact_id));
                    dispatch(adminLoginAsStaff(true));
                    // dispatch(projectTaskCounts(projectId,));
                    favoriteDdashboardSelected(loginRes.data.dashboard_name);
                    localStorage.setItem("fav_dashboard",loginRes.data.dashboard_name);
                    leftPanelFilters("","", "all")
                    dispatch(getGroupDetails({}))
                    dispatch(lastSessionOfChat([]))
                    dispatch(setSelectedGloablView("AichatView"));
                    navigate("/redirect", {
                      state: {
                        // url: "/liteversion",
                        url: "/",
                      },
                    });
                  }
                }
                dispatch(leftPanelFilters("","","topicFilterClear"))
              }else{
                dispatch(showMessage("unsucess", _l("l_error"), loginRes.message));
              }
            });      
          
        }else{
          dispatch(showMessage("unsucess", _l("l_error"), res.message));
        }
      }
    ).catch(() => {
      setLoginAsUserLoader(false);
    });
  };

  const pinProject = (project_id) => {
    CustomerServices.pinProject(project_id).then((res) => {
      if (res.status && res.data) {
        removeDuplicates(spacesArray)
        const spaceIndex = spacesArray.findIndex(
          (x) => x && x.project_id == project_id
        );
        let spa = Object.values({
          ...spacesArray,
          [spaceIndex]: {
            ...spacesArray[spaceIndex],
            pin_count: spacesArray[spaceIndex].pin_count == "0" ? "1" : "0",
          },
        });
        // if (spa.length > 0) {
        const targetSpace = spa.filter(
          (office) => office && office.id === selectedSpace.id
        );
        const otherOffices = spa.filter(
          (office) => office && office.id !== selectedSpace.id
        );
        spa = targetSpace.concat(otherOffices);
        removeDuplicates(spa)
        spa.sort(function (a, b) {
          if (parseInt(a.pin_count) < parseInt(b.pin_count)) return 1;
          if (parseInt(a.pin_count) > parseInt(b.pin_count)) return -1;
          if (!a.chat_group_message || a.chat_group_message === '') return 1;
          if (!b.chat_group_message || b.chat_group_message === '') return -1;
          const chatA = moment(a.chat_group_message_date ? a.chat_group_message_date : '');
          const chatB = moment(b.chat_group_message_date ? b.chat_group_message_date : '');
          if ((chatA).isBefore(chatB)) return 1;
          if ((chatA).isAfter(chatB)) return -1;
          if ((parseInt(a.notification_count) + parseInt(a.chat_notification_count) + parseInt(a.unread_notification_count)) > (parseInt(b.notification_count) + parseInt(b.chat_notification_count) + parseInt(b.unread_notification_count))) return -1;
          if ((parseInt(a.notification_count) + parseInt(a.chat_notification_count) + parseInt(a.unread_notification_count)) < (parseInt(b.notification_count) + parseInt(b.chat_notification_count) + parseInt(b.unread_notification_count))) return 1;
          if (a.project_id == selectedSpace.id) return -1;
          return 0;
        });
        //
        setActiveSpaces(spa);
        spaces.find(p => p.project_id === project_id && (p.pin_count = p.pin_count == "0" ? "1" : "0", true))
        setSpaces(spaces);
        // setActiveSpaces(Object.values({...activeSpaces, [spaceIndex]: {...activeSpaces[spaceIndex], pin_count : activeSpaces[spaceIndex].pin_count == "0" ? "1" : "0" } }));
      }
      if (res.status === 0) {
        dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
      }
    });
  };

  const getUserList = async (client_id, project_id) => {
    const setDataToStateVariable = (data) => {
      if (data) {
        let customers =
          data.customers && data.customers.members
            ? data.customers.members
            : [];
        customers.sort(
          (a, b) =>
            b.is_primary - a.is_primary ||
            a.fullname.toLowerCase().localeCompare(b.fullname.toLowerCase())
        );
        let providers =
          data.providers && data.providers.members
            ? data.providers.members
            : [];
        providers.sort(
          (a, b) =>
            b.is_boss_operator - a.is_boss_operator ||
            a.fullname.toLowerCase().localeCompare(b.fullname.toLowerCase())
        );
        if (customers.length) {
          customers.forEach((customer, index) => {
            customer["type"] = data.customers.title;
            customer["user_type"] = data.customers.title;
          });
        }
        if (providers.length) {
          providers.forEach((provider, index) => {
            provider["type"] = data.providers.title;
            provider["user_type"] = data.providers.title;
          });
        }
        setUserList(customers.concat(providers));

        if (providers.length == 0 && customers.length == 0) {
          setUserList([]);
        }
      }
    };

    const fetchListFromAPI = (updateLocalState = true) => {
      setLoder(updateLocalState);
      setIsLoginAsUserDataLoaded(false);
      CustomerServices.getDetailForLoginAsUser(client_id, project_id)
        .then((res) => {
          if (res.status && res.data) {
            if (updateLocalState) {
              setDataToStateVariable(res.data);
            }

            insertCollectionToDB(
              "login_as_user_list",
              [{ data: res.data, project_id }],
              "project_id"
            );
          }
        })
        .catch((e) => {
          console.error("Can not fetch login as users data....", e);
        })
        .finally(() => {
          setLoder(false);
          setIsLoginAsUserDataLoaded(true);
        });
    };

    try {
      setIsLoginAsUserDataLoaded(false);
      const data = await readCollectionFromDB(
        "login_as_user_list",
        "project_id"
      );
      if (!data || !data.length) {
        fetchListFromAPI();
      } else {
        let currentProjectMemberData = data.find(
          (w) => w.project_id == project_id
        );
        if (currentProjectMemberData) {
          setDataToStateVariable(currentProjectMemberData.data);
          fetchListFromAPI(false); 
        } else {
          fetchListFromAPI();
        }
      }
    } catch (e) {
      fetchListFromAPI();
      console.error(e);
    } finally {
      setIsLoginAsUserDataLoaded(true);
      setLoder(false);
    }
  };

  const closeSearchArea = () => {
      setShowBackDropClass(false);
      setSpaceSearchOnGoing(false);
      setSearchBarFocus(false);
      // setSearchString("");
  };

  useEffect(() => {
    let element = document.getElementById("modified_height");
    let element2 = document.body;

    const handleOfficeListOutsideClick = (e) => {
      if((officeListDivRef && officeListDivRef.current && officeSearchboxRef && officeSearchboxRef.current && !officeListDivRef.current.contains(e.target) && !officeSearchboxRef.current.contains(e.target) && officeListDivRef && topicSearch.current && officeSearchboxRef && officeSearchboxRef.current && !topicSearch.current.contains(e.target) && !officeSearchboxRef.current.contains(e.target)))
      {
        jquery("#intro-office-list").removeClass("searchbutton");
        closeSearchArea();
      }
    }
    
    document.addEventListener("click", handleOfficeListOutsideClick);
    if (
      noLeftPanel.indexOf(viewPage.replace("/", "")) > -1 ||
      noLeftPanelPages.indexOf(pageName) > -1
    ) {
      if (window.location.pathname != "/dashboard") {
        DefaultLeftPanel();
      }   
      document.addEventListener("mouseup", leftPanelMouseUpEvent);
      if (
        pageName != "Chat" &&
        pageName != "AddBenchmark" &&
        pageName != "documents" &&
        (projectId > 0 && projectId != "null") &&
        localStorage.getItem("is_dashboard") == 1
      ) {
        jquery(".office-box").removeClass("active");
      }
    }
    if (fullScrollPages.indexOf(pageName) > -1) {
      if (element && element2) {
        element.classList.add("modified-height", "modified-res-default-height");
        element2.classList.add("pb-0");
      }
    }
    if(pageName !== "Dashboard" && pageName !== "Chat") {
      // dispatch(setSelectedGloablView("listView"))
    }
    return () => {
      document.removeEventListener("mouseup", leftPanelMouseUpEvent);
      document.removeEventListener("click", handleOfficeListOutsideClick);
      element = document.getElementById("modified_height");
      if (element && element2) {
        element.classList.remove("modified-res-default-height");
        element2.classList.remove("pb-0");
      }
    };

   
  }, [pageName,globalView]);

  useEffect(() => {
    if (pageName == "Dashboard" && globalView == 'listView' && window.location.pathname != "/dashboard") {
      showLeftPanelNew();
    }
  }, [pageName,globalView])

  useEffect(() => {
    setPreEstiPageDisable(false);
    if (isPremiumEstimateEdit == true) {
      // setPreEstiPageDisable(true);
    }
    setGetIbanModlaOpen(false);
    if (isIBANModal == true) {
      setGetIbanModlaOpen(true);
    }
  }, [JSON.stringify(isPremiumEstimateEdit), JSON.stringify(isIBANModal)]);
  
  useEffect(() => {
    if (pageName == "MyEmail") {
      localStorage.setItem("email_filter_key", "inbox");
      localStorage.removeItem("email_filter_email");
    }
  }, []);
  useEffect(() => {
    // if (pageName == "MyEmail" || pageName == " ") {
      CustomerServices.getMailSettings().then((res) => {
        setIsEmailSynce(res.status)
        if (res.status) {
          res.data.map((item) => {
            if (item["provider"] == "gmail" && setSynchronizedMail) {
              setSynchronizedMail("gmail");
            } else if (item["provider"] == "outlook_mail" && setSynchronizedMail) {
              setSynchronizedMail("outlook_mail");
            }
          });
        }
      });
    // }

  }, []);
  useEffect(() => {
    if (localStorage.getItem("selectedVirtualSpace")) {
      try {
        let virtualSPace = JSON.parse(
          localStorage.getItem("selectedVirtualSpace")
        );
        setSelectedTeam(
          virtualSPace && Object.keys(virtualSPace).length ? virtualSPace : {}
        );
        dispatch(getDefaultTopics(localStorage.getItem('selectedOffice'), "", "", pageName));
      } catch (error) {
        console.log(error);
      }
      jquery(".link-center-box-toggle").addClass("d-none");
    } else {
      setSelectedTeam();
      jquery(".link-center-box-toggle").removeClass("d-none");
    }
  }, [localStorage.getItem("selectedVirtualSpace")]);

  const revokeMailSync = () => {
    CustomerServices.revokeMailSync(synchronizedMail).then((res) => {
      if (res.status) {
        window.dispatchEvent(new Event("mailSyncRevoked"));
        setSynchronizedMail(false);
      }
    });
  };
  

  const bulkAddCustomTopic = () => {
    window.dispatchEvent(new Event("bulkAddCustomTopic"));
  };

  const selectAdminTeam = (team, is_remove = 0) => {
    dispatch(fullScreenLoaderChangeSpace(true));
    dispatch(setGloablSearchString(''))
    dispatch(lastSessionOfChat([]));
    TaskModalClose();
    dispatch(setSelectedDateView("dayView"));
    dispatch(setDaterangeFilterApplied(false));
    dispatch(selectedChatUser(undefined));
    dispatch(dayGridDay(true));
    dispatch(dayGridMonth(false));
    dispatch(dayGridWeek(false));
    dispatch(setShowWeekcalendardays(true));
    dispatch(setDaterangeFilter("today"));
    handleChangeViewtoFavView();
    localStorage.setItem("isLiteVersion", false);
    localStorage.setItem("generalChatForLiteVersion",false);
    dispatch(leftPanelFilters("","","all"));
    dispatch(getGroupDetails({}));
    dispatch(setFetchedTaskandMessages([]));
    dispatch(updateTaskList([]));
    localStorage.setItem("selectedDate", formateDate(new Date()));
    localStorage.removeItem("activeGroupId");
    dispatch(setClearmessage());


    if (is_remove) {
      handleOfficeChange(team);
    } else {
      setSelectedProject(team.id)
      localStorage.setItem("selectedVirtualSpace", JSON.stringify(team));
      localStorage.setItem("selected_project_client_id",team.id);

      setSelectedTeam(team);
      handleOfficeChange(team, true);
      if (pageName != "Chat") {
        navigate("/");
        dispatch(setSelectedGloablView("AichatView"));;
      }
    }
    if (contactlistShow) {
      contactDirTopicChange({}, true)
    }
    dispatch(accordionsEventKey(false));
  }; 
  const removeVirtualSpace = (flag = 0) => {
    if (flag && localStorage.getItem("prevSelectedOffice")) {
      localStorage.setItem("selectedOffice", localStorage.getItem("prevSelectedOffice"));
      localStorage.removeItem("prevSelectedOffice");
    }
    localStorage.removeItem("selectedVirtualSpace");
  };
  useEffect(() => {
    window.addEventListener('edit_wallet_settings', () => {
      setShow(true);
    });
  }, []);

  const [unreadComments, setUnreadComments] = useState(0);
  const [unreadMessages, setUnreadMessages] = useState(0);
  useEffect(() => {

    const totalMails = allMAilMessages ? allMAilMessages : [...(importantMessages || []), ...(inboxMessages || [])];
    let unread_comments = 0;
    let unread_messages = 0;

    (totalMails || []).map((email) => {
      unread_comments = unread_comments + (email['thread']['mentions'] || []).filter((comment) => comment['is_read'] == 0).length;
      unread_messages = unread_messages + ((email['conversation']['messages'].filter((reply) => reply['labelIds'] && reply['labelIds'].includes('UNREAD')) || []).length > 0 ? 1 : 0);
    });
    setUnreadComments(unread_comments);
    setUnreadMessages(unread_messages);

  }, [importantMessages, inboxMessages, statusClose, allMAilMessages]);

  const createChatChannels = (projectId = 0) => {
    dispatch(toastCloseAction());
    setShowLoader(true);
    CustomerServices.createChatChannels(projectId).then((res) => {
      setShowLoader(false);
      if (res.status) {
        dispatch(showMessage('sucess', "Success", res.message));
      } else {
        dispatch(showMessage("unsucess", _l("l_error"), res.message));
      }
    });
  };

  const getDataForProjectFilter = async() => {
    try {
      const response = await AdminServices.getMyRStaffList("0");
      if(response.status && response.data.length)
      {
        const accountExecutiveList = response.data.filter((w) => w.role == 3 && w.staffid != adminUser);
        const accountManagerList = response.data.filter((w) => w.role == 12 && w.staffid != adminUser);

        let props = [
          {
            key: "l_my_spaces", 
            id: -1,
            name: _l("l_my_space"),
            options: [],
            handler: spaceFilterOnStatus,
          },
          {
            key: "l_project_status", 
            handler: spaceFilterOnStatus,
            options: projectStatusArray.map((w) => {return {
                      ...w,
              }})
          },
          {
            key: "l_account_executive", 
            handler: spaceFilterOnStatus,
            options: accountExecutiveList.map((w) => {return {
                      ...w,
                      name: w.fullname,
                      id: w.staffid,
              }})
          },
          {
            key: "l_account_manager", 
            handler: spaceFilterOnStatus,
            options: accountManagerList.map((w) => {return {
                      ...w,
                      name: w.fullname,
                      id: w.staffid,
              }})
          },
        ];

        setFilterDropDownProps(props);
        spaceFilterOnStatus("", "l_my_spaces");
      }
    }
    catch(e)
    {
      console.error(e);
    }
  }

  useEffect(() => {
    var totalUnreadCount = 0;
    if (virtualSpaceList && virtualSpaceList.length > 0) {
      virtualSpaceList.map((item) => {
        totalUnreadCount += parseInt(item.unread_chat_msg_count) + parseInt(item.unread_notification_count);
        if (item.id == projectId) {
          dispatch(setTaskUnreadCmtCntAll(parseInt(item.unread_notification_count)));
        }
      });
    }
    setVirtualSpaceUnreadMsg(totalUnreadCount);
  }, [virtualSpaceList]);

  useEffect(() => {
    if (proposalCount) {
      setProposalKpiCount(proposalCount)
    }
  }, [proposalCount])

  useEffect(() => {
    if((userType == "staff" || userType == "operator") && selectedSpace.client_id && selectedSpace.clientId != undefined){
      localStorage.setItem("virtualSpaceClientId", selectedSpace.client_id)
    }else if(projectId){
     let tempspace = spaceArray.filter((space)=>space.project_id == projectId)
     if(tempspace.length > 0){
      localStorage.setItem("virtualSpaceClientId", tempspace[0].client_id)
     }
    // this code is commneted cause it is creating infinte api call 
    //  if(Object.keys(selectedSpace).length > 0){
    //   setSelectedProject(selectedSpace.project_id ? selectedSpace.project_id : selectedSpace.id)
    //   localStorage.setItem("selectedOffice",selectedSpace.project_id ? selectedSpace.project_id : selectedSpace.id)
    //  }
    }

    if(userType == "staff" && spaceState.length == 0 && adminTeamList.length > 0 ){
      localStorage.setItem("virtualSpaceClientId", adminTeamList[0].id)

      // setSelectedProject(adminTeamList[0].id)
    }

  }, [selectedSpace , userType, projectId,adminTeamList,spaceState])
  

  useEffect(() => {
    CustomerServices.getCompanyEmployee().then((res) => {
      if (res.status && res.data) {
        setEmployeeData(res.data)
        if (res.data && res.data.length == 0) {
          dispatch(isTenantsData(true));
        }else{
          dispatch(isTenantsData(false));
        }
      }
    });
  }, []);

  useEffect(() => {
    if (userType == "staff") {
      getDataForProjectFilter();
      if (clientTeamData && clientTeamData.myr_staff) {
        setEmployeeData(clientTeamData.myr_staff);
      }
    }
  }, [clientTeamData])

  useEffect(() => {
    if(pageName == 'dashboard' ){
      localStorage.removeItem("is_dashboard");
      localStorage.setItem("is_dashboard",0);
    }else if(pageName != 'LiteVersion' && globalView != "liteVersionView"){
      localStorage.removeItem("is_dashboard");
      localStorage.setItem("is_dashboard",1);
    }

    if(["PremiumSubscription", "Proposals", "MyEmail", "SpaceOnBoarding"].includes(pageName))
    {
      let menuName = "";
      switch(pageName)
      {
        case "PremiumSubscription":
          menuName = "plan";
          break;
        case "Proposals":
          menuName = "proposals";
          break;
        case "SpaceOnBoarding":
          if(window.location.href.includes("proposal"))
          {
            menuName = "proposals";
          }
          break;
        case "Proposals":
          menuName = "mail";
          break;
      }
      setActiveMenuOptions([menuName]);
    }
  },[]);

useEffect(() => {
  if (FavView == "AichatView" && pageName == "Dashboard" && globalView == "AichatView") {
    if(messagesStates && !messagesStates.idbDataLoader)
    {
      dispatch(fullScreenLoaderChangeSpace(false))
    }
    else {
      dispatch(fullScreenLoaderChangeSpace(true))
    }
  }else{
    dispatch(fullScreenLoaderChangeSpace(false))
  }
},[pageName, selectedOffice])

  useEffect(() => {
    generateThreadId();
  }, [taskFilters, globalView])

  useEffect(() => {
    if (localStorage.getItem("searchParamUserId")) {
      setSettingTopicName("l_drive");
      setTopicMainKey("documents");
    }
  }, [])

  const mailModalHandleClose = () => setSyncShowMailModal(false);
  const mailModalHandleShow = () => setSyncShowMailModal(true);
  const onClickOfficeChange = (space) => {
    // set default values of global state on space change
    // localStorage.removeItem("is_registered")
    dispatch(accordionsEventKey(false));
    dispatch(setGloablSearchString(''))
    dispatch(clearDefaultTopicsList())
    TaskModalClose();
    dispatch(setSelectedDateView("dayView"));
    dispatch(setDaterangeFilterApplied(false));
    dispatch(selectedChatUser(undefined));
    dispatch(dayGridDay(true));
    dispatch(dayGridMonth(false));
    dispatch(dayGridWeek(false));
    dispatch(setShowWeekcalendardays(true));
    dispatch(setDaterangeFilter("today"));
    handleChangeViewtoFavView();
    localStorage.setItem("isLiteVersion", false);
    localStorage.setItem("generalChatForLiteVersion",false);
    dispatch(leftPanelFilters("","","all"));
    dispatch(getGroupDetails({}));
    dispatch(setFetchedTaskandMessages([]));
    dispatch(updateTaskList([]));
    localStorage.setItem('selectedOffice', space.project_id);
    localStorage.setItem("selected_project_client_id",space.client_id);
    localStorage.setItem("selectedDate", formateDate(new Date()));
    dispatch(setClearmessage());
    dispatch(updateTaskList([]));
    // jquery("#intro-office-list").removeClass("expanded");

    if (FavView == "AichatView") {
      dispatch(fullScreenLoaderChangeSpace(true))
    }
    if((user_type == "contact" || user_type ==  "operator") && Number(contact_role) == 3){
      navigate("/");
      dispatch(setSelectedGloablView("AichatView"));
      handleChangeViewtoFavView()
    }
    if (globalView  != "AichatView") {
      localStorage.removeItem("activeGroupId");
    }
    if (
      localStorage.getItem(
        "membership_package_id"
      ) == 1
    ) {
      return;
    }
    if (projectId != space.project_id) {
      handleOfficeChange(space);
      if (pageName == 'Proposals') {
        navigate("/");
      }
    } else {
      jquery(".office-list").removeClass(
        "expanded"
      );
      setTeamLeftPanel(false);
      /*click on Selected space redirect  */
      if(localStorage.getItem("user_type") == 'contact'){
        if (pageName == "Chat") {
          navigate("/chat");
        }
       
      }else{
          navigate("/");
          dispatch(setSelectedGloablView("AichatView"));
      }
    }

    if (pageName != "Chat" && pageName !='LiteVersion') {
      jquery("body").removeClass(
        "with-right-penel"
      );
      showLeftPanelNew();
      jquery(".task-comment-modal").removeClass(
        "show-comments"
      );
      if (
        disablePages.indexOf(
          viewPage.replace("/", "")
        ) > -1 ||
        noLeftPanelPages.indexOf(pageName) > -1 ||
        isLeadPage
      ) {
        if(localStorage.getItem("user_type") == 'contact'){
          navigate("/");
          dispatch(setSelectedGloablView("AichatView"));;
          
        }else if (pageName !== "SpaceOnBoarding"){
          navigate("/");
          dispatch(setSelectedGloablView("AichatView"));;
        }
      }
    }else{
      hideLeftPanelNew();
      jquery(".provider-list").addClass("d-none")
    }
    dispatch(setOpenGeneralChatGroup(false));
    dispatch(clearDocumentData());
    if (contactlistShow) {
      contactDirTopicChange({}, true)
    }
    dispatch(accordionsEventKey(false));
    };

    const handleEditAdminTeam = (team, is_user_journey = '') => {
      if (is_user_journey == 'user_journey' || is_user_journey == 'my_people' || is_user_journey == 'task_topic') {
        CustomerServices.getTeamDetails(Math.abs(team)).then((res) => {
          if (res.status) {
            if (res.data) {
              const teamData = {
                id: res.data.id,
                members: res.data.team.map((x) => {
                  return {
                    member_id: x.member_id,
                    firstname: x.firstname,
                    lastname: x.lastname,
                  };
                }),
                teamTitle: res.data.name,
                manager_name: res.data.manager_name,
                manager_id: res.data.manager_id
              };
              setTopicType(is_user_journey)
              setSelectedTeamForEdit(teamData);
              setshowAddTeamMember(true);
            }
          } else {
            dispatch(
              showMessage("unsucess", _l("l_error"), res.message ? res.message : "")
            );
          }
        });
  
      } else {
        const teamData = {
          id: team.id,
          members: team.members.map((x) => {
            return {
              member_id: x.staffid,
              firstname: x.full_name,
              lastname: "",
            };
          }),
          teamTitle: team.name,
        };
        setSelectedTeamForEdit(teamData);
        setshowAddTeamMember(true);
      }
    };
  
    const handleFavoriteAdminTeam = (team) => {
      adminServices.setFavouriteVirtualSpace(team.id, team.is_selected == 0 ? 1 : 0).then((res) => {
        if (res.status) {
          jquery(".dropdown-menu").removeClass("show");
          let Arr = adminTeamList
          for (let i = 0; i < Arr.length; i++) {
            if (Arr[i].id == team.id) {
              Arr[i].is_selected = team.is_selected == 0 ? 1 : 0
            } else {
              Arr[i].is_selected = 0
            }
          }
          const sortArr = Arr.sort((a, b) => parseInt(b.is_selected) - parseInt(a.is_selected))
          dispatch(setVirtualSpaceList(sortArr));
          setAdminTeamList(sortArr);
        } else {
          dispatch(
            showMessage("unsucess", _l("l_error"), res.message ? res.message : "")
          );
        }
      });
    };
    const handleDeleteTeam = (data) => {
      CustomerServices.removeVirtualSpace(data.id).then((res) => {
        if (res.status) {
          setAdminTeamList(adminTeamList.filter((team) => team.id != data.id));
          dispatch(setVirtualSpaceList(adminTeamList.filter((team) => team.id != data.id)));
          dispatch(toastCloseAction());
          if (
            localStorage.getItem("selectedVirtualSpace") &&
            JSON.parse(localStorage.getItem("selectedVirtualSpace")).id == data.id
          ) {
            localStorage.setItem("selectedOffice",localStorage.getItem("prevSelectedOffice"));
            localStorage.removeItem("prevSelectedOffice");
            selectAdminTeam(
              { project_id: localStorage.getItem("prevSelectedOffice") },
              1
            );
            dispatch(
              getTopicTasks(
                localStorage.getItem("prevSelectedOffice"),
                "Dashboard"
              )
            );
            dispatch(
              getDefaultTopics(
                localStorage.getItem("prevSelectedOffice"),
                "",
                "",
                "Dashboard"
              )
            );
          }
          dispatch(
            showMessage("sucess", _l("l_success"), res.message ? res.message : "")
          );
        } else {
          dispatch(
            showMessage("unsucess", _l("l_error"), res.message ? res.message : "")
          );
        }
      });
  
      // setshowAddTeamMember(true);
    };

  const hideMenuButton = (menuName) => {
    let flag = false;
    let currentSpaceDetails = spaceArray.find((w) => w.project_id == selectedOffice && w.project_id != -1);
    
    switch(menuName)
    {
      case "searchBar":
      case "premiumPage":
      case "teams":
      case "mail":
      case "wallet":
      case "company-profile":
      case "more-tools":
      case "proposal":
        if(currentSpaceDetails && currentSpaceDetails.is_external > 0)
        {
          flag = true;
        }
        else if(["SpaceOnBoardingNew"].includes(pageName) && ["/space-onboarding"].includes(viewPage))
        {
          flag = true;
        }
        break;
      default:
        break;
    }
    return flag;
  }

  const getspacedata = (e ,project_id)=>{
    CustomerServices.getSpaceDataOhover(project_id).then((res)=>
    {
      if(res.status)
      {
        setspaceData(res.data)
      }
    })
  }

  const getMentionUsersForFilter = async() => {
    if(userType === "staff" && !mentionStaffArray.length)
    {
      try {
        const response = await AdminServices.getAllProjectMembers();
        let members = [];
        if(response.status && response.data)
        {
          response.data.forEach((project) => {
            project.members.forEach((member) => {
              let memberIndex = members.findIndex((w) => w.label && member.full_name && member.full_name.trim() == w.label.trim() && member.staffid == w.value);
              if(memberIndex === -1)
              {
                let showCrown = false;
                if(member.user_type === "operator" && member.role == 2 && member.contact_role == 1)
                {
                  showCrown = true;
                }
                else if(member.user_type === "contact" && member.contact_role == 1)
                {
                  showCrown = true;
                }
                members.push({label: member.full_name, value: member.staffid, profile_image_url: member.profile_image_url, pillContent: `#${member.user_type}`, projects: [{project_id : project.project_id.toString(),title:project.office_title,space_name:project.postal_address}], showCrown,
                user_type:member.user_type,
                staffid:member.staffid,
                contactid:member.contactid,
                email:member.email,
                full_name: member.full_name
              });
              }
              else {
                members[memberIndex] = {...members[memberIndex], projects: members[memberIndex].projects.concat([{project_id : project.project_id.toString(),title:project.office_title,space_name:project.postal_address}])};
              }
            });
          });
        }
        members.forEach((member) => {member.label = `@${member.label}`});
        members.sort((a, b) => a.label.localeCompare(b.label));
        setMentionStaffArray(members);
      }
      catch(e)
      {
        console.error(e);
        showError("l_something_went_wrong");
      }
    }
  }

  const applyStaffUserFilter = (member, isRemove) => {

    let projectIdArray = [];

    if(isRemove)
    {
      searchMentionUsers = selectedStaffMembersForSpaceFilter.filter((w) => w.value != member.value);
      if(searchMentionUsers.length)
      {
        projectIdArray = searchMentionUsers.reduce((commonProjects, obj) => 
        {
          const projects = obj.projects;
          return commonProjects.filter(project => projects.includes(project));
        }, searchMentionUsers[0].projects);
      }
      let updatedMemberList = mentionStaffArray.concat(member);
      updatedMemberList.sort((a, b) => a.label.localeCompare(b.label));
      setMentionStaffArray(updatedMemberList);
      setSelectedStaffMembersForSpaceFilter(searchMentionUsers);
    }
    else {
      searchMentionUsers = selectedStaffMembersForSpaceFilter.concat(member);
      if(searchMentionUsers.length == 1)
      { 
        let projectIdArrays = []
        projectIdArrays = searchMentionUsers.map((w) => w.projects);
        projectIdArrays =projectIdArrays[0]
        projectIdArrays = projectIdArrays.map((project) => project.project_id)
        projectIdArray.push(projectIdArrays)
      }
      else {
        projectIdArray = searchMentionUsers.reduce((commonProjects, obj) => 
        {
          const projects = obj.projects;
          return commonProjects.filter(project => projects.includes(project));
        }, searchMentionUsers[0].projects);
      }
  
      setSearchString("");
      setSelectedStaffMembersForSpaceFilter(searchMentionUsers);
      setMentionStaffArray(mentionStaffArray.filter((w) => w.value != member.value));
    }


    let allSpaces = spaces.filter(
      (space) => space.status == "0" || space.status == "1" || space.status == "2"
    );
    let list = spaceState.filter(
      (space) => space.status == "0" || space.status == "1" || space.status == "2"
    );
    allSpaces = allSpaces.filter((el) => {
      let flag = true;
      Object.keys(projectFilters).forEach((param) => {
        if(param !== "my_spaces" && projectFilters[param].length && !projectFilters[param].includes(el[param] && !isNaN(el[param]) ? el[param].toString() : el[param] ))
        {
          flag = false;
        }
      });
      if(flag)
      {
        return el;
      }
    });
    if (allSpaces.length > 0) {
      const targetSpace = allSpaces.filter(
        (office) => office.id === isOfficeSelected.id
      );
      const otherOffices = allSpaces.filter(
        (office) => office.id !== isOfficeSelected.id
      );
      var allOffices = targetSpace.concat(otherOffices);
      allOffices.sort(function (a, b) {
        if (parseInt(a.pin_count) < parseInt(b.pin_count)) return 1;
        if (parseInt(a.pin_count) > parseInt(b.pin_count)) return -1;
        if (!a.chat_group_message || a.chat_group_message === '') return 1;
        if (!b.chat_group_message || b.chat_group_message === '') return -1;
        const chatA = moment(a.chat_group_message_date ? a.chat_group_message_date : '');
        const chatB = moment(b.chat_group_message_date ? b.chat_group_message_date : '');
        if ((chatA).isBefore(chatB)) return 1;
        if ((chatA).isAfter(chatB)) return -1;
        if ((parseInt(a.notification_count) + parseInt(a.chat_notification_count) + parseInt(a.unread_notification_count)) > (parseInt(b.notification_count) + parseInt(b.chat_notification_count) + parseInt(b.unread_notification_count))) return -1;
        if ((parseInt(a.notification_count) + parseInt(a.chat_notification_count) + parseInt(a.unread_notification_count)) < (parseInt(b.notification_count) + parseInt(b.chat_notification_count) + parseInt(b.unread_notification_count))) return 1;
        if (a.project_id == isOfficeSelected.id) return -1;
        return 0;
      });
    };
    const filteredSpaces =  spaceArray.filter((w) => projectIdArray.length > 0 && projectIdArray[0].includes(w.project_id)); 
    setActiveSpaces(searchMentionUsers.length ? filteredSpaces : list);
  }
  const selectLiteVersion = () => {
    DefaultLeftPanel();
    setLeftpanelToggleOnLiteversion(true);
    localStorage.setItem("isLiteVersion",true);
    jquery("body").removeClass("task-modal-offcanvas-open");
    if(pageName != "Dashboard" && pageName != "Chat"){
      navigate("/")
      dispatch(setSelectedGloablView("liteVersionView"))
    }else if( pageName == "Dashboard" && contact_role == 3){
      dispatch(leftPanelFilters("","","all"))
      dispatch(setSelectedGloablView("liteVersionView"))
    }
    setSelectedOffice(0)
    setSelectedProject(0)
    if (userType == "staff") { 
      localStorage.removeItem('selectedVirtualSpace')
    }
    if (pageName == "Chat") {
      // localStorage.setItem("isLiteVersion", true)
      setSelectedOffice(0)
      localStorage.setItem("selectedOffice",0);
    } else {
      if (projectId > 0) {
        // navigate("/liteversion");
        handleChangeViewtoFavView()
        localStorage.setItem("selectedOffice",0);
        // localStorage.setItem("isLiteVersion",true);
        setSelectedProject(0)
    const savedDate = localStorage.getItem("selectedDate");
        dispatch(getTaskList(
          client_id,
          contact_id,
          0,
          moment(savedDate).format("YYYY-MM-DD"),
          1,
          activeTaskStatusFilters,
          taskFilters,
          "",
          0,
          1,
          "",
          "",
          "liteVersionView"
          ))
      } else {
        handleChangeViewtoFavView()
      }
    }
  }
  useEffect(() => {
    setTimeout(() => {
      setLeftpanelToggleOnLiteversion(false)
    }, 3000);
  },[leftpanelToggleOnLiteversion]);
  useEffect(() => {
    if (isAdmin == "true" && userType == "staff" && isAllChatUserlistShow) {
      jquery(".provider-list").addClass("expanded");
      showLeftPanelNew();
    }
  },[isAllChatUserlistShow]);

  const handleStaffWalletConfiguration = () => {
    setShowStaffIBANSetup(true);
  };

  const prepareToast = (className, messageBody, header) => {
    setShowToast(true);
    setToastHeader(header);
    setToastClass(className);
    setToastMessage(messageBody);
  };
  const handleSpaceDelete =() => {
    CustomerServices.deleteOrCancelSpace(officeId, project_id, estimateId,  3 ).then((res) => {
      if (res.status == 1) {
        dispatch(showMessage("sucess", _l('l_success'), res.message ? _l(res.message) : _l('l_space') + _l("l_delete") + _l('l_successfully')));
        dispatch(getCustomerSpaces(client_id, contact_id));        

      } else {
        dispatch(showMessage("unsucess", _l('l_error'), res.message ? _l(res.message) : _l('l_something_went_wrong')));
      }
    });
    setShowToast(false);
  }
  const handleChangeViewtoFavView  = () =>{
    let FavView = localStorage.getItem("fav_dashboard")

    // navigate("/") 
    FavView == "AichatView" ?
      dispatch(setSelectedGloablView("AichatView")) :
        FavView == "calendar" ?
          dispatch(setSelectedGloablView("calendarView"))
          : FavView == "my_spaces" ?
            dispatch(setSelectedGloablView("spaceView"))
            : FavView == "kanban_view" ?
              dispatch(setSelectedGloablView("kanbanView"))
              : FavView == "my_tasks" ? dispatch(setSelectedGloablView("listView"))
                : contact_role == 3 ? 
                 navigate("/") 
                   : dispatch(setSelectedGloablView("AichatView"));;

    FavView == "kanban_view" ? setIsKanbanView(true) :setIsKanbanView(false)
  }
  useEffect(() => {    
    CustomerServices.premiumEstimatePlan(project_id).then((res) => {
      if (res && res.status && res.data) {
        setEstimateId(res.data.estimate ? res.data.estimate.estimate_id : 0)
      }
    });
  }, [project_id])


  const handleVisibleMenus = () => {
    if(!menuOptionsFloat)
    {
      setvisibleOptionIcons([]);
      setMenuOptionsFloat(true);
      VISIBLE_MENUS = [];
      SPACE_LIST_RESULTS_PER_PAGE = 8;
    }
    else {
      setVisibleOptions();
      setMenuOptionsFloat(false);
      SPACE_LIST_RESULTS_PER_PAGE = 5;
    }
  };

  const setVisibleOptions = () => {
    switch(userType)
      {
        case "contact":
          VISIBLE_MENUS = CONTACT_MENU_OPTIONS;
          setvisibleOptionIcons(CONTACT_MENU_OPTIONS);
          break;
        case "operator":
          VISIBLE_MENUS = OPERATOR_MENU_OPTIONS;
          setvisibleOptionIcons(OPERATOR_MENU_OPTIONS);
          break;
        default:
          VISIBLE_MENUS = STAFF_MENU_OPTIONS;
          setvisibleOptionIcons(STAFF_MENU_OPTIONS);
      }
  }

  const hideMenuOnOut = () => {
    setTimeout(() => {
      if(!showBackDropClass)
      {
        jquery("#intro-office-list").removeClass("expanded search-backdrop");
      }
      if (menuOptionsFloat) {
        setvisibleOptionIcons([]);
        VISIBLE_MENUS = [];
        SPACE_LIST_RESULTS_PER_PAGE = 8;
      }
      if((projectId == 0 || projectId == null || projectId == undefined || projectId == "null") && (pageName != "dashboardnew" && window.location.pathname != "/dashboard") && (!contactlistShow) && window.location.pathname != "/providerlist"){
        hideLeftPanelNew();
      }else{
        setShowForMeSection(false)
      }
      // jquery(".fixed-left-panel").removeClass("expanded");
   
    // jquery(".fixed-left-panel").removeClass("expanded");
    jquery("body").removeClass("hover-left-penal");
    const universalCommentModal = document.getElementById("comment_close");
    if(universalCommentModal)
    {
      universalCommentModal.click();
    }

    if((userType == "contact" || userType ==  "operator") && contact_role == 3){
      jquery("body").removeClass("with-left-penal");
    }

    setShowForMeSection(false);

    if(SettingMenuRender)
    {
      // setSettingMenuRender(false);
    }

    }, 750);
  };

  const showMenuOnHover = (event) => {
    if (menuButtonRef.current && menuButtonRef.current.contains(event.target) && menuOptionsFloat) {
      SPACE_LIST_RESULTS_PER_PAGE = 5;
      setVisibleOptions();
    }
  };

  const updateActiveMenuOptions = (option) => {
    setActiveMenuOptions([option]);
  };

  const discardBenchmark = () => {
    localStorage.clear();
    localStorage.setItem("version", process.env.REACT_APP_VERSION);
    dispatch({
      type: USER_LOGOUT
    });
    setBenchmarkDiscardModal({});
    navigate("/login");
  };

  const proceedWithPendingPayment = async() => {
    try {
      setSubscriptionLoader(true);
      const email = localStorage.getItem("email");

      const subscriptionDetail = await DocumentsServices.getSubscriptionDetail(0, 0, pendingPaymentInfo.subscription_hash);
      const paymentMethod = subscriptionDetail.data.payment_mode;

      const response = await DocumentsServices.subscribe(
        pendingPaymentInfo.subscription_hash,
        "",
        email,
        1,
        pendingPaymentInfo.project_id,
        1,
        client_id,
        undefined,
        undefined,
        paymentMethod
      );
      if (response.status) {
        let pendingPayments = localStorage.getItem('pending_payments');
        pendingPayments = pendingPayments ? JSON.parse(pendingPayments) : [];
        pendingPayments = pendingPayments.filter((w) => w.subscription_id != pendingPaymentInfo.subscription_id);
        localStorage.setItem("pending_payments", JSON.stringify(pendingPayments));
        dispatch(getCustomerSpaces(client_id, contact_id));
        setPendingPaymentInfo({});
        if (response.data.site) {
          window.location.href = response.data.site;
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setSubscriptionLoader(false);
    }
  };

  const generateThreadId = () => {
    //For Services
    const providerData = taskFilters.find((w) => w.main_key === "providers");
    if(providerData && adminArchieve && ["contact"].includes(userType) && globalView === "AichatView")
    {
      const topic = defaultTopics && defaultTopics.find((w) => w.main_key === "providers");
      if(topic && topic.categories.length)
      {
        topic.categories.forEach(async(category) => {
          if(category.filter_key == providerData.filter_key && category.assistant_id && category.assistant_id !== "" && (!category.thread_id || category.thread_id == "" ))
          {
            try {
              const response = await CustomerServices.generateAIThread(projectId, providerData.main_key, category.filter_key, category.main_sub_key);
              if(response.data)
              {
                dispatch(setTopicThreadId(response.data, providerData.main_key, category.filter_key, category.main_sub_key));
              }
            }
            catch(e)
            {
              console.error(e);
            }
          }
        });
      }
    }
    //For AI Assistant Topic for people section
    const peopleSectionData = taskFilters.find((w) => w.main_key === "my_contact");
    if(peopleSectionData && adminArchieve && ["contact"].includes(userType) && globalView === "AichatView")
    {
      const topic = defaultTopics && defaultTopics.find((w) => w.main_key === "my_contact");
      if(topic && topic.categories.length)
      {
        topic.categories.forEach(async(category) => {
          if(category.contact_role == 4 && category.filter_key == peopleSectionData.filter_key && (!category.thread_id || category.thread_id == ""))
          {
            try {
              const response = await CustomerServices.generateAIThread(projectId, peopleSectionData.main_key, category.filter_key, category.main_sub_key);
              if(response.data)
              {
                dispatch(setTopicThreadId(response.data, peopleSectionData.main_key, category.filter_key, category.main_sub_key));
              }
            }
            catch(e)
            {
              console.error(e);
            }
          }
        });
      }
    }
  }

  useEffect(() => {
    //For search effect similar to global search in left panel
    if(spaceSearchOnGoing)
    {
      if(showBackDropClass)
      {
        jquery("#intro-office-list").addClass("expanded search-backdrop");
      }
      else {
        jquery("#intro-office-list").removeClass("expanded search-backdrop");
      }
    }
  }, [showBackDropClass])

  useEffect(() => {
    jquery(document).mouseup(function (e) {
     var container = jquery('.left-panel-company-setting');
     if (!container.is(e.target) && container.has(e.target).length === 0) {
       var container2 = jquery('.setting-menu-main');
       if (!container2.is(e.target) && container2.has(e.target).length === 0) {
         setSettingMenuRender(false);
       }
     }
   });
  })
  useEffect(() => {  
    jquery(document).mouseup(function (e) {
     var container = jquery('.new_user_profile_bottom');
     if (!container.is(e.target) && container.has(e.target).length === 0) {
       var container2 = jquery('.myprofilesetting-menu-main-close');
       if (!container2.is(e.target) && container2.has(e.target).length === 0) {
        setshowMyProfile(false);
       }
     }
   });
  })

  useEffect(() => {
    setContactlistShow(isContactDirChat);
    if (isContactDirChat) {
      showContactDirectory();
      dispatch(selectJobProposalPage(false));
    }else{
      dispatch(setOpenOrCloseContactDirectoryChat(false));
      // hideContactDirectory();
    }
  }, [isContactDirChat])
  
  useEffect(() => {
    if (leftPanelTab != "my-space") {
      setSelectedSpace({});
      setSelectedOffice(0);
      setSelectedProject(0);
    dispatch(setProject(0));
      localStorage.removeItem("selectedOffice");
    }
  }, [leftPanelTab])
  
  useEffect(() => {
    // dispatch(getDefaultTopics(projectId, "", "", projectId > 0 ? pageName : "dashboardnew",taskFilters));
  }, [projectId])
  
  useEffect(() => {
    const checkEmailSync = async () => {
      let syncString = "";
      try {
        const response = await CustomerServices.getMailSettings();
        if (response.status) {
          response.data.map((item) => {
            if (item["provider"] == "gmail") {
              syncString = "gmail";
              setisSynced("gmail");
            } else if (item["provider"] == "outlook_mail") {
              syncString = "outlook_mail";
              setisSynced("outlook_mail");
            }
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        // setisSynced(syncString)
        return syncString;
      }
    };
    checkEmailSync()
    customerServices.getProvidersAgents().then((res)=>{
      if (res && res.status) {
        dispatch(setProviderAgnetList(res.data))
      }
    })
    customerServices.getProvidersClients().then((res)=>{
      if (res && res.status) {
        dispatch(setProvideClientList(res.data))
      }
    })
  }, [])
  

  const handleChatGroup = (group) => {
    localStorage.setItem("activeGroupId", group.groupId)
    dispatch(getGroupDetails({}));
    dispatch(setFetchedTaskandMessages([]));
    chatServices.getGroupDetails(chatUserId, group.groupId).then((res) => {
      if (res.success) {
        var activeGroupId = localStorage.getItem("activeGroupId");
        if (chatUserId != "" && activeGroupId != "" && activeGroupId != undefined) {
          var socketDataIn = {
            groupId: activeGroupId,
            userId: chatUserId,
          };
          // if (globalView == "AichatView") {
            dispatch(groupIn(socketDataIn));
            dispatch(fetchMessages( 
              localStorage.getItem("chatUserId"), 
              group.groupId,
              0,
              ()=>{},
              1,
              "",
              "",
              "",
              group.unread_count > 0 ? group.unread_count : 20,
          ));  
          // }
        }
        let groupWrapper = {...res.result, groupName : group.groupName};
          dispatch(getGroupDetails(groupWrapper))
          localStorage.setItem("getGroupDetails", JSON.stringify(groupWrapper))
      } else {
        dispatch(showMessage("unsucess", _l("l_error"),res.message));
      }
    });
  }

  const contactList = (groupListExternal,  searhContact) => {
    const filteredCategories = groupListExternal.filter((contact) => {
      const isTitleMatch = contact.groupName.toLowerCase().includes(searhContact.toLowerCase());
      return isTitleMatch;
    });
    return filteredCategories;
  };

  const contactDirTopicChange = (group, fromspace) => {
    showLeftPanelNew()
    
    if (fromspace == false) {
     if (!contactlistShow) {
      setSelectedSpace({});
      if (!is_registered) {
        dispatch(leftPanelFilters("","","all"))
        jquery("body").addClass("with-left-penel");
      }else{
        showLeftPanelNew()
      }
      setContactlistShow(true)
      dispatch(setOpenOrCloseContactDirectoryChat(true));
      dispatch(getGroupDetails({}))
      dispatch(setFetchedTaskandMessages([]))
      dispatch(updateTaskList([]))
      dispatch(setClearmessage());
      dispatch(clearChatTaskList());
      navigate("/");
      dispatch(setSelectedGloablView("AichatView"))
      dispatch(SetSelectedTask({}));
      TaskModalClose();
      dispatch(setBulkTaskRating([]))
      setSelectedProject(0)
      // handleChatGroup(group)
      dispatch(contactDirCount(group.groupId))
      localStorage.setItem("selectedTopic",JSON.stringify(group))
      localStorage.setItem("isContactDir", 1)
      localStorage.removeItem('activeGroupId')
     } else {
      if (Object.keys(group).length) {
        handleChatGroup(group)
      }
      dispatch(contactDirCount(group.groupId))
      localStorage.setItem("selectedTopic",JSON.stringify(group))
     }
    } else {
      setContactlistShow(false)
      dispatch(setOpenOrCloseContactDirectoryChat(false));
      localStorage.removeItem("isContactDir")
    }
  } 
  useEffect(() => {
    if (leftPanelTab == "my-email") {
      customerServices.getEmailTokenActive().then((res) => {
        if (res.status && res.data.status) {
          dispatch(emailTokenExpired(false));
        }else{
          dispatch(emailTokenExpired(true));
        }
      })
    }
    if (leftPanelTab !== "my-providers") {
      localStorage.removeItem("currentCompanyID")
    }
  }, [leftPanelTab])

  return (
    <>
       {/* { ["SpaceOnBoarding"].includes(pageName) ?
       <React.Fragment></React.Fragment>
       :  */}
       <div id="left-panel-main" className={`fixed-left-panel ${ showMyProfile ? "z-index-21" : ""}`} ref={panelRef}
          // onMouseLeave={hideMenuOnOut} 
          >
           { !DEFAULT_SUB_MENU_PAGES.includes(pageName) && ["homepage","my-clients", "my-providers", "equipments"].includes(leftPanelTab) || (emailTokenVaild && leftPanelTab === "my-email") || (isTenantsDataVaild && leftPanelTab === "my-tenants") ?  
           <LeftPanelMain
            setshowMyProfile={setshowMyProfile}
            showMyProfile={showMyProfile}
            setSelectedOffice={setSelectedOffice}
            contactlistShow={contactlistShow}
            setContactlistShow={setContactlistShow}
            contactDirTopicChange={contactDirTopicChange}
            setSettingTopicName={setSettingTopicName}
            setTopicMainKey={setTopicMainKey}
            handleChangeViewtoFavView={handleChangeViewtoFavView}
            isSynced={isSynced}
           /> :
            <LeftPanelSubMenu
            setCompletedTasksCount={setCompletedTasks}
            setInProgressTasksCount={setInProgressTasks}
            setTodoTasksCount={setTodoTasks}
            projectId={projectId}
            searchCategory={searchCategory} 
            pageName={pageName}
            teamLeftPanel={teamLeftPanel}
            setSelectOperators={setSelectOperators}
            setShowScheduleDropDown={setShowScheduleDropDown}
            setShowProviderDropDown={setModal1show}
            allMAilMessages={allMAilMessages}
            allImportantMessages={allImportantMessages}
            hideMailSyncBtn={hideMailSyncBtn}
            createTeamModal={createTeamModal}
            setCreateTeamModal={setCreateTeamModal}
            equipmentModal={equipmentModal}
            setEquipmentModal={setEquipmentModal}
            selectAdminTeam={(team, is_remove) => selectAdminTeam(team, is_remove)}
            selectedTeam={selectedTeam}
            clientTeamData={clientTeamData}
            handleDeleteTeam={handleDeleteTeam}
            handleFavoriteAdminTeam={handleFavoriteAdminTeam}
            handleEditAdminTeam={handleEditAdminTeam}
            topicType ={topicType}
            setTopicType ={setTopicType}
            selectedTeamForEdit ={selectedTeamForEdit}
            setSelectedTeamForEdit ={setSelectedTeamForEdit}
            showAddTeamMember ={showAddTeamMember}
            setshowAddTeamMember ={setshowAddTeamMember}
            adminTeamList ={adminTeamList}
            setAdminTeamList ={setAdminTeamList}
            showUserList={isAllChatUserlistShow}
            searchCategoty={searchCategoty}
            searchTopic={searchTopic}
            showForMeSection={showForMeSection}
            setShowForMeSection={setShowForMeSection}
            spaces={getSearchedOffice}
            setSearchString={setSearchString}
            searchString={searchString}
            setshowMyProfile={setshowMyProfile}
            showMyProfile={showMyProfile}
            projectTaskCount={projectTaskCount}
            selectedOffice={selectedOffice}
            onClickOfficeChange={onClickOfficeChange}
            setSelectedOffice={setSelectedOffice}
            setSelectedProject={setSelectedProject}
            selectedSpace={selectedSpace}
            setSelectedSpace={setSelectedSpace}
            contactDirData={contactList(groupListExternal, searchTopic)}
            contactDirTopicChange={contactDirTopicChange}
            contactlistShow={contactlistShow}
            setContactlistShow={setContactlistShow}
            setsearchTopic={setsearchTopic}
            getspacedata={getspacedata}
            spaceData={spaceData}
            setUserList={setUserList}
            admin={admin}
            adminUser={adminUser}
            contact_role={contact_role}
            createChatChannels={createChatChannels}
            setproject_id={setproject_id}
            setshowProjectStatusList={setshowProjectStatusList}
            getSearchedOffice={getSearchedOffice}
            getUserList={getUserList} 
            setClientIdLoginAsUser={setClientIdLoginAsUser}
            setShowDocumentCanvas={setShowDocumentCanvas}
            setManagePeopleModal={setManagePeopleModal}
            setshowOpenAiModal={setshowOpenAiModal}
            setspaeContent={setspaeContent}
            setOfficeId={setOfficeId}
            setShowToast={setShowToast}
            prepareToast={prepareToast}
            setSelectedspaceStatus={setSelectedspaceStatus}
            showProjectStatusList={showProjectStatusList}
            projectStatusArray={projectStatusArray}
            userList={userList}
            setTopicMainKey={setTopicMainKey}
            setSettingTopicName={setSettingTopicName}
            handleChangeProjectStatus={handleChangeProjectStatus}
            loginHandler={loginHandler}
            selectedspaceStatus={selectedspaceStatus}
            isLoginAsUserDataLoaded={isLoginAsUserDataLoaded}
            handleChangeViewtoFavView={handleChangeViewtoFavView}
            isSynced={isSynced}
            tenantesList={employeeData}
            /> }
        <div
          id="intro-office-list"
          onMouseLeave={hideMenuOnOut}
          className={`fixed-left-panel office-list intro-office-list d-none ${showBackDropClass ? "search-backdrop" : ""} ${isBackdrop ? "comman-backdrop-search" : ""} ${preEstiPageDisable == true ? "for-disabled" : ""
            }`}
        >

          <div className="fixed-left-panel-wrapper h-100 d-flex flex-column">
            <div className={`upper-part ${pageName =="LiteVersion" ||(globalView == "liteVersionView" && (projectId == null || projectId == undefined || projectId == 0))? "bg-transparent" : ""}`}>
              <div className={`company-logo-box position-relative office-box ${leftpanelToggleOnLiteversion ? "with-before":""} ${pageName=="LiteVersion" || ((projectId == null || projectId == undefined || projectId == 0)) ? "" : ""}`}>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center justify-content-between w100minus70 flex-grow-1 office-list-left">
                    {/* <div className={`d-none h50w50 h-100 d-flex align-items-center justify-content-center office-list-icon ${(((projectId == null || projectId == undefined || projectId == 0 || projectId == "null") && !(selectedTeam && Object.keys(selectedTeam).length > 0)) || (pageName == "dashboardnew")) && dashBoradView !== "taskListView" && (!contactlistShow) && localStorage.getItem("is_registered") != 1 && pageName == "dashboardnew" ? 'active' : ''} position-relative`}
                      data-tip={"#" + rel_id}
                      data-for={randomID}
                      data-effect="solid"
                      data-delay-show='1000'
                      data-class="tooltip-main"
                      data-place="right">
                      <a href="#/" className="h40w40 comman-round-box with-bg d-flex align-items-center rounded-circle "
                      onClick={() => {
                        dispatch(setFetchedTaskandMessages([]))
                          dispatch(commanoffcanvasisOpen(false)) 
                          dispatch(SetSelectedTask({}));
                          TaskModalClose();
                          dispatch(getGroupDetails({})); 
                        if ((["operator","contact"].includes(userType) && contact_role != 3) || userType == "staff") {
                          if (!contactlistShow) {
                          dispatch(leftPanelFilters("","","topicFilterClear"))
                          }
                          navigate("/dashboard");
                          dispatch(setDashBoardView("spaceView"))
                          localStorage.removeItem("selectedOffice")
                          localStorage.removeItem("selectedVirtualSpace")
                          dispatch(setBulkTaskRating([]))
                          setSelectedProject(0)
                        }else{
                          selectLiteVersion()
                        }
                        if (contactlistShow) {
                          setContactlistShow(false);
                          dispatch(setOpenOrCloseContactDirectoryChat(false));
                          localStorage.removeItem("isContactDir")
                        }
                      }}
                        >
                        <div
                          className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle d-flex"
                          style={{
                            backgroundImage: `url(${company_logo != null ? company_logo : ""})`,
                          }}
                        >
                          {company_logo == null || company_logo == "" ? <ThreeStarIcon  className="c-icons HW24 fill_green" /> : <></>}
                          </div>
                        {/* {
                          generalUnreadCount > 0 ?
                          <span className="badge-custom d-flex rounded-pill">{generalUnreadCount > 99 ? "99+" : generalUnreadCount}</span>
                          :<></>
                        } * /}
                      </a>
                    </div> */}
                    <div className={`h50w50 h-100 d-flex align-items-center justify-content-center  position-relative`}>
                     <a href="#/"
                      className="h50w50 d-flex align-items-center justify-content-center ic-provider-list office-list-circle radius_3"
                      data-tip={contactlistShow
                        ? _l("l_contacts")
                        : ["taskListView", "calendarView"].includes(dashBoradView) && pageName == "dashboardnew" 
                          ? _l("l_my_task")
                          : dashBoradView == "spaceView" && pageName == "dashboardnew" 
                            ? _l('l_reporting') 
                            : "#"+(selectedSpace.project_id)}
                      data-effect="solid"
                      data-delay-show='1000'
                      data-class="tooltip-main"
                      data-place="right"
                    >
                      {/* ${overdueTaskCounts.some((data)=>data.project_id == space.project_id && data.overdue_count > 0) ? "bg-danger border-0" :""} */}
                      { selectedSpace.is_virtual_space == 1 ?  
                      <div className={`bg-white-05 h45w45 comman-round-box radius_3 title-fonts d-flex flex-column square_box ${pageName =='dashboard' || pageName == "dashboardnew" || contactlistShow ? '' :'active'}`}>
                        <div className="m-auto c-font f-13 office-subtitle text-truncate hover-span">
                          <UsersThree size={18} weight="light" className="c-icons" /> 
                        </div>
                        <span className=" color-white-80 d-block c-font f-13 pt-0 office-subtitle  hover-span fw-light hover-span line-height-1 px-1 text-truncate text-center w-100">
                          {selectedSpace.name}
                        </span>
                        <span
                          className={`rounded-pill badge-custom d-flex ${parseInt(selectedSpace.unread_chat_msg_count) + parseInt(selectedSpace.unread_notification_count) > 0
                            ? ""
                            : "d-none"
                            }`}
                          data-proj-unread-cnt-id={selectedSpace.project_id}
                        >
                          {parseInt(selectedSpace.unread_chat_msg_count) + parseInt(selectedSpace.unread_notification_count)}
                        </span>
                      </div> : 
                      <div className={`bg-white-05 h45w45 comman-round-box radius_3 title-fonts d-flex flex-column square_box ${pageName =='dashboard' || pageName == "dashboardnew" || contactlistShow ? 'current-selected' :'active'}`}>
                        <span className="m-auto c-font f-13 office-subtitle text-truncate hover-span">
                          {contactlistShow
                            ? <AddressBook size={16} weight="light" className="c-icons" />
                            : ["taskListView", "calendarView"].includes(dashBoradView) && pageName == "dashboardnew" 
                              ? <ListBullets size={16} weight="light" className={`c-icons`} />
                              : dashBoradView == "spaceView" && pageName == "dashboardnew" 
                                ? <Gauge size={16} weight="light" className="c-icons " />
                                :<Buildings size={16} weight="light" className="c-icons" />
                          }
                        </span>
                        {pageName =='dashboard' || pageName == "dashboardnew" || contactlistShow ? 
                        <span className=" color-white-80 d-block c-font f-13 pt-0 office-subtitle  hover-span fw-light hover-span line-height-1 px-1 text-truncate text-center w-100">
                          {contactlistShow
                            ? _l("l_contacts")
                            : ["taskListView", "calendarView"].includes(dashBoradView) && pageName == "dashboardnew" 
                              ? _l("l_my_task")
                              : _l('l_reporting')    
                          }
                        </span>
                           :
                        <span className=" color-white-80 d-block c-font f-13 pt-0 office-subtitle  hover-span fw-light hover-span line-height-1 px-1 text-truncate text-center w-100">{selectedSpace.office_title
                            ? selectedSpace.office_title :
                            selectedSpace.name ? selectedSpace.name 
                            : selectedSpace.postal_address} </span>
                        }
                        <span
                          className={`rounded-pill badge-custom d-flex ${Number(selectedSpace.notification_count)+ Number(selectedSpace.unread_notification_count) + (selectedSpace.chat_notification_count ? Number(selectedSpace.chat_notification_count) : 0) > 0
                            ? ""
                            : "d-none"
                            }`}
                          data-proj-unread-cnt-id={selectedSpace.project_id}
                        >
                          {Number(selectedSpace.notification_count)+ Number(selectedSpace.unread_notification_count) + (selectedSpace.chat_notification_count ? Number(selectedSpace.chat_notification_count) : 0)}
                        </span>
                        {selectedSpace.pin_count &&
                          selectedSpace.pin_count == "1" ? (
                          <span className="pin-office">
                            <PushPin size={12} weight="fill" className="m-auto Push-Pin-fill" />
                          </span>
                          
                        ) : (
                          ""
                        )}
                        {overdueTaskCounts && overdueTaskCounts.filter((data)=>data.project_id == selectedSpace.project_id).map((data)=>{
                          return(<>
                          
                            <span
                          className={`rounded-pill badge-custom left-side d-flex  bg-danger ${ data.overdue_count > 0
                            ? ""
                            : "d-none"
                            }`}
                        ><span className="color-white">
                          {data.overdue_count > 99 ? "99+" : data.overdue_count}
                          </span>
                        </span>
                          </>)
                        })}
                      </div>
                    }
                     </a>
                    </div>
                    <div className="comman-list-wrapper-main ps-3 w100minus70 left-toggle-none flex-grow-1">
                      <a href="#/" className="office-list-detail w-100 left-toggle-none"> 
                          <div className="office-list-desc-left w-100">
                            <div className="align-items-center d-flex justify-content-between ">
                            <div className="d-flex align-items-center text-truncate">
                              {contactlistShow || pageName == "dashboardnew" ?
                              <div className="title-fonts fw-semibold text-truncate">
                                {contactlistShow
                                    ? _l("l_contacts")
                                    : ["taskListView", "calendarView"].includes(dashBoradView) && pageName == "dashboardnew" 
                                      ? _l("l_my_task")
                                      : _l('l_reporting')    
                                  }
                                </div>
                               : 
                                <div className="text-truncate max-width-160px  pe-2 c-font f-12">
                                  {selectedSpace.office_title
                                    ? selectedSpace.office_title :
                                    selectedSpace.name ? selectedSpace.name 
                                    : _l("l_office_title_placeholder")}
                                </div>
                                }
                              </div>
                              {contactlistShow || pageName == "dashboardnew" ? <></> :
                              <div className="d-flex align-items-center justify-content-end dropstart dropdown-static-display">
                            { selectedSpace.is_virtual_space == 1 ? <></> :
                              <div className="progress-slider w-70px">
                                <div className="d-flex align-items-center">
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      style={{
                                        width: `${selectedSpace.workspace_score}%`,
                                      }}
                                    ></div>
                                  </div>
                                  <div className="progress-text c-font f-10 title-fonts">
                                    {selectedSpace.workspace_score}%
                                  </div>
                                </div>
                              </div>
                            }
                                <div className="comman_action_icon">
                                <a href="#/"
                                  className="action_icon px-1  h25w25 on-hover-active-toggle-img "
                                  onClick={() => {
                                    pinProject(selectedSpace.project_id);
                                  }}
                                >
                                  {selectedSpace.pin_count &&
                                    selectedSpace.pin_count === "1" ? (
                                    <div className="m-auto">
                                      <PushPin size={16} weight="fill" className="c-icons Push-Pin-fill" />
                                    </div>
                                  ) : (
                                    <div className="m-auto">

                                      <PushPin size={16} weight="light" className="c-icons" />
                                    </div>
                                  )}
                                </a>
                                </div>
                                <div className="multi_inner_wrapper with-min-width d-flex">
                              {
                                selectedSpace.members && selectedSpace.members.filter((u) => u.role == 3 || u.role == 12).length > 0 ?
                                  selectedSpace.members.filter((u) => u.role == 3 || u.role == 12).map((dd, index) => {
                                    if (index == 0) {
                                      return (
                                        <div className="comman-image-box h20w20 rounded-circle"
                                          data-tip={dd.fullname}
                                          data-effect="solid"
                                          data-delay-show='1000'
                                          data-class="tooltip-main"
                                        >
                                          <div
                                            className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 rounded-circle"
                                            style={{
                                              backgroundImage: `url('${dd.profile_image_url}')`
                                            }}
                                          ></div>
                                        </div>
                                      )
                                    }
                                  })
                                  : <></>
                              }
                              { selectedSpace.is_virtual_space == 1 ? 
                                      selectedSpace.creator_details.staff_id == adminUser  ? 
                                    <div className={` comman_action_icon with_margin_minus_10px dropstart`}>
                                    <a href="#/"
                                      className="c-three-dots office-list-static action_icon h25w25 on-hover-active-toggle-img left-toggle-none  "
                                      id="ActiveOfficeOptionsDropdown"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      data-bs-auto-close="outside"
                                      onClick={() => {
                                        // HideDropdown();
                                        jquery(this).siblings(".dropdown-menu").addClass("show");
                                        setUserList([]);
                                      }}
                                    >
                                      <DotsThreeVertical size={16} weight="light" className="c-icons" />
                                    </a>
                                    <ActionDropdown
                                      actions={
                                        admin == 'true' && selectedSpace.creator_details.staff_id == adminUser ?
                                          [{
                                            actionName: _l("l_edit"),
                                            actionHandler: () => {
                                              handleEditAdminTeam(selectedSpace)
                                            },
                                            actionIcon: "PencilSimpleLineIcon",
                                          },
                                          {
                                            actionName: _l("l_delete"),
                                            actionHandler: () => {
                                              dispatch(
                                                showConfirmation(
                                                  _l("l_are_you_sure"),
                                                  _l("l_you_want_to_delete_space"),
                                                  _l("l_yes"),
                                                  _l("l_no"),
                                                  undefined,
                                                  () => { handleDeleteTeam(selectedSpace) }
                                                )
                                              );
    
                                            },
                                            actionIcon: "TrashIcon",
                                          },
                                          ]
                                          : []
                                      }
                                      widthClass="me-1"
                                    />
                                    </div> : <></>
                                  :
                                    <div className={` comman_action_icon with_margin_minus_10px dropstart ${ (contact_role == 3 ) ? "d-none" : ""}`}>
                                    <a href="#/"
                                      className="c-three-dots office-list-static action_icon h25w25 on-hover-active-toggle-img left-toggle-none  "
                                      id="ActiveOfficeOptionsDropdown"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      data-bs-auto-close="outside"
                                      onClick={() => {
                                        // HideDropdown();
                                        jquery(this).siblings(".dropdown-menu").addClass("show");
                                        setUserList([]);
                                      }}
                                    >
                                      <DotsThreeVertical size={16} weight="light" className="c-icons" />
                                    </a>
                                    <ActionDropdown widthClass="w-170"
                                      actions={
                                        admin
                                          ? selectedSpace.is_allowed_to_edit == 0 ? [
                                            {
                                              actionName: _l("l_create")+ " " + _l('l_support'),
                                              actionHandler: () => {
                                                dispatch(
                                                  showConfirmation(
                                                    _l("l_are_you_sure"),
                                                    _l("l_you_want_to_create_chat_channels"),
                                                    _l("l_yes"),
                                                    _l("l_no"),
                                                    undefined,
                                                    () => { createChatChannels(selectedSpace["project_id"]); }
                                                  )
                                                );
                                              },
                                              actionIcon: "ChatIcon",
                                            },
                                            {
                                              actionName: _l("l_login_as_user"),
                                              actionHandler: () => {
                                                setproject_id(selectedSpace.project_id);
                                                    setshowProjectStatusList(false);        
                                                getUserList(
                                                  selectedSpace["client_id"],
                                                  selectedSpace["project_id"]
                                                );
                                                showLeftPanel(true)
                                              },
                                              actionIcon: "UserIcon",
                                            },
                                            {
                                              actionName: _l("l_manage_project_member"),
                                              actionHandler: () => {
                                                setManagePeopleModal(true);
                                                setproject_id(selectedSpace.project_id);
                                              },
                                              actionIcon: "IdentificationBadgeIcon",
                                            },
                                            {
                                              actionName: _l("l_open_ai"),
                                              actionHandler: () => {
                                                setshowOpenAiModal(true);
                                                setproject_id(selectedSpace.project_id);
                                                setspaeContent(selectedSpace.content)
                                              },
                                              actionIcon: "AiIcon",
                                            },
                                            {
                                              actionName: _l("l_delete") +" "+ _l("l_space"),
                                              actionHandler: () => {
                                                setproject_id(selectedSpace.project_id);
                                                setOfficeId(selectedSpace.id)
                                                setShowToast(true);
                                                prepareToast(
                                                  "toast-with-btn",
                                                  _l("l_space_delete_conformation"),
                                                  _l("l_confirm")
                                                );
                                              },
                                              actionIcon: "TrashIcon",
                                            },
                                            {
                                              actionName: _l("l_change") +" "+ _l("l_project_status"),
                                              actionHandler: () => {
                                                setSelectedspaceStatus(parseInt(selectedSpace.project_status == 0 ? 1 : selectedSpace.project_status));
                                                setOfficeId(selectedSpace.id)
                                                setproject_id(selectedSpace.project_id);
                                                setshowProjectStatusList(true)                                        
                                              },
                                              actionIcon: "FlagIcon",
                                            },
                                          ] : [
                                            {
                                              actionName: _l("l_explore_feature"),
                                              actionHandler: () => {
                                                dispatch(setProject(selectedSpace.project_id));
                                                setSelectedOffice(selectedSpace.project_id)
                                                localStorage.setItem("selectedOffice", selectedSpace.project_id);
                                                navigate(`/explore-features?space=${selectedSpace.project_id}`);
                                                
                                              },
                                              actionIcon: "PencilSimpleLineIcon",
                                            },
                                            {
                                              actionName: _l("l_create")+ " " + _l('l_support'),
                                              actionHandler: () => {
                                                dispatch(
                                                  showConfirmation(
                                                    _l("l_are_you_sure"),
                                                    _l("l_you_want_to_create_chat_channels"),
                                                    _l("l_yes"),
                                                    _l("l_no"),
                                                    undefined,
                                                    () => { createChatChannels(selectedSpace["project_id"]); }
                                                  )
                                                );
                                              },
                                              actionIcon: "ChatIcon",
                                            },
                                            {
                                              actionName: _l("l_login_as_user"),
                                              actionHandler: () => {
                                                getUserList(
                                                  selectedSpace["client_id"],
                                                  selectedSpace["project_id"]
                                                );
                                                showLeftPanel(true)
                                              },
                                              actionIcon: "UserIcon",
                                            },
                                            {
                                              actionName: _l("l_manage_project_member"),
                                              actionHandler: () => {
                                                setManagePeopleModal(true);
                                                setproject_id(selectedSpace.project_id);
                                              },
                                              actionIcon: "IdentificationBadgeIcon",
                                            },
                                          ]
                                          : [
                                            {
                                              actionName: _l("l_explore_feature"),
                                              actionHandler: () => {
                                                // navigate(`/space-onboarding?space=${space.project_id}&edit=1`)
                                                dispatch(setProject(selectedSpace.project_id));
                                                setSelectedOffice(selectedSpace.project_id)
                                                localStorage.setItem("selectedOffice", selectedSpace.project_id);
                                                navigate(`/explore-features?space=${selectedSpace.project_id}`);
                                                // navigate(`/companyprofile`)
                                              },
                                              actionIcon: "PencilSimpleLineIcon",
                                            },
                                            {
                                              actionName: _l("l_manage_project_member"),
                                              actionHandler: () => {
                                                setManagePeopleModal(true);
                                                setproject_id(selectedSpace.project_id);
                                              },
                                              actionIcon: "IdentificationBadgeIcon",
                                            },
                                          ] 
                                      }
                                    list={showProjectStatusList ? projectStatusArray : userList.map((item, index) => {
                                      return {
                                        name: item.fullname || item.name,
                                        image: item.profile_image_url,
                                        email: item.email,
                                        type: item.type ? item.type : "",
                                        user_type: item.user_type ? item.user_type : "",
                                        is_primary: item.is_primary
                                          ? item.is_primary
                                          : "",
                                        is_boss_operator: item.is_boss_operator
                                          ? item.is_boss_operator
                                          : "",
                                        client_id: item.userid,
                                        contact_role: item.contact_role,
                                        company: item.company,
                                        id: item.user_type == "Provider" ? item.staff_id : item.contact_id ,
                                        staff_id: item.staff_id,
                                        boss_operator_id: item.boss_operator_id,
                                        session_details : item.session_details,
                                        last_login: item.last_login,
                                        no_of_comments: item.no_of_comments,
                                        task_score: item.task_score,
                                        contact_id : item.contact_id

                                      };
                                    })}
                                    isLoginAsUserDataLoaded={isLoginAsUserDataLoaded}
                                    loder={loder}
                                    unControlList={false}
                                    setLoder={setLoder}
                                    handler={(actionItem) => {
                                      showProjectStatusList ?
                                        handleChangeProjectStatus(actionItem.id) :
                                        loginHandler(
                                          actionItem.email,
                                          actionItem.client_id,
                                          actionItem.type,
                                          actionItem.id,
                                          0,
                                          actionItem
                                        );
                                    }}
                                    selectedspaceStatus={selectedspaceStatus}
                                    setSelectedspaceStatus={setSelectedspaceStatus}
                                    />
                                    </div>
                                  }
                                </div>
                              </div>
                              }
                            </div>
                              <div className="d-flex align-items-center color-white-60">
                                <div className="c-font f-10 text-truncate w100minus85 pe-2">{selectedSpace && selectedSpace.postal_address}
                              </div>
                            </div>
                        </div>             
                      </a>
                    </div>
                    <div className="comman-list-wrapper-main ps-3 w100minus70 left-toggle-none flex-grow-1 me-2 d-none">
                      <div className="list-left-part">
                        <div className="d-flex flex-column">
                          <a href="#/" className="title-fonts fw-semibold text-truncate fw-semibold c-font f-12 title-fonts">
                            {
                              company_name !="" ? company_name :  _l("l_reporting") 
                            }
                          </a>
                          <div className="progress-slider">
                            <a href="#/" className="d-flex align-items-center">
                              <div className="progress">
                                <div
                                  className="progress-bar"
                                  style={{
                                    width: `${pageName == 'dashboard' ? localStorage.getItem('overallWorkSpaceScore') : clientWorkspaceScore}%`,
                                  }}
                                ></div>
                              </div>
                              <div className="progress-text c-font f-12 title-fonts ps-2">
                                {pageName == 'dashboard' ? localStorage.getItem('overallWorkSpaceScore') : clientWorkspaceScore}%
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="list-right-part d-none">
                    <div className="d-flex align-items-center with_margin_minus_10px">
                      <div className="comman_action_icon dropstart ">
                        <div className="dropstart">
                          <a href="#/"
                            className={`action_icon h25w25 on-hover-active-toggle-img ${spaceFilter.length > 1 && pageName != 'dashboard' && pageName != "invoice" && pageName != "estimate" && pageName != "subscription" && pageName != "staff" && pageName != "client" && pageName != "AdminTaskPage" && pageName != "credit_notes" && pageName != "fullcalendar" && pageName != "benchmark" && pageName != "schedule" ? "active" : ""}`}
                            type="button"
                            id="OfficeListDropdown"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            data-tip={_l("l_office_List")}
                            data-for={randomID}
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main"
                            data-place="left"
                          >
                            <Funnel size={16} weight="light" className="c-icons" />
                          </a>                          
                          <ul
                            className="dropdown-menu w-170 officeList_dropdown_transform"
                            aria-labelledby="OfficeListDropdown"
                          >


                            {projectStatusArray.map((ps, k) => {
                              return (
                                <li className="border-bottom-10 taskmenufilter">
                                  <div key={k} className="pill-checkbox dropdown-item d-flex justify-content-between">
                                    <input
                                      type="checkbox"
                                      onChange={() => {
                                        spaceFilterOnStatus(ps.id, "project_status");
                                        if (ps.key !== officeFilterKey) {
                                          setOfficeFilterKey(ps.key);
                                        } else {
                                          setOfficeFilterKey("");
                                        }
                                      } }
                                      className="hidden-input" />
                                    <div className="d-flex align-items-center text-truncate w100minus10">
                                      <div className="text-truncate">
                                        <span className={ps.class}>
                                          {ps.name}
                                        </span>
                                      </div>
                                    </div>
                                    <span className="ofice-list-checkbox m-auto">
                                      <Check size={12} color="#00ff6a" weight="light" />
                                    </span>
                                  </div>
                                </li>
                              );
                            })}


                          </ul>
                        </div>
                      </div>
                      <div className={`comman_action_icon dropstart ${ contact_role == 3 || userType == "staff" ? "d-none" : ""}`}>
                        <a href="#/"
                          className="action_icon h25w25 on-hover-active-toggle-img left-toggle-none"
                          id="ActiveOfficeOptionsDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-tip={_l("l_options")}
                          data-for={randomID}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                        >
                          <DotsThreeVertical size={16} weight="light" className="c-icons" />
                        </a>
                        <ActionDropdown widthClass="w-170"
                          actions={[
                            {
                              actionName: _l("l_edit"),
                              actionHandler: () => {
                                setSettingTopicName("l_company_profile");
                                setTopicMainKey("company-profile");
                              },
                              actionIcon: "PencilSimpleLineIcon",
                            }
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {searchBarFocus &&
              searchString == "" &&
              getSearchedOffice.findIndex(
                (x) => x.pin_count && x.pin_count == 1
              ) === -1 ? (
                <div ref={officeListDivRef}>
                  <CommanPlaceholder imgType="no-found-office" placeholderText = {`${_l("l_type_to_search")}`} />
                </div>
            ) : (getSearchedOffice && getSearchedOffice.length > 0) || (virtualSpaceList && virtualSpaceList.length > 0) ||
              (getinActiveSpaces && getinActiveSpaces.length > 0) ? (
              <ul className={`office-list-main ${showBackDropClass && spaceSearchOnGoing ? "z-index-12" : ""}`} id="left-office-list" ref={officeListDivRef}>
                {/* {userType == "staff" && virtualSpaceList && virtualSpaceList.length > 0?
                <>
                  <TeamList
                    pageName={pageName}
                    handler={(item, is_delete = 0, is_favorite) => {
                      if (is_delete) {
                        handleDeleteTeam(item);
                      } else if (is_favorite) {
                        handleFavoriteAdminTeam(item);
                      } else {
                        handleEditAdminTeam(item);
                      }
                    }}
                    selectAdminTeam={selectAdminTeam}
                    selectedTeam={selectedTeam}
                    OnMouseOverOfficeList={OnMouseOverOfficeList}
                    searchString={searchString}
                  />
                  </>
                  : <></>} */}
                {getSearchedOffice.sort((a,b)=>{
                  if (parseInt(a.pin_count) > parseInt(b.pin_count)) return -1;
                  if (parseInt(a.pin_count) < parseInt(b.pin_count)) return 1;
                  if (!a.chat_group_message || a.chat_group_message === '') return 1;
                  if (!b.chat_group_message || b.chat_group_message === '') return -1;
                  const chatA = moment(a.chat_group_message_date ? a.chat_group_message_date : '');
                  const chatB = moment(b.chat_group_message_date ? b.chat_group_message_date : '');
                  if (chatA.isBefore(chatB)) return 1;
                  if (chatA.isAfter(chatB)) return -1;
                  if ((parseInt(a.notification_count) + parseInt(a.chat_notification_count) + parseInt(a.unread_notification_count)) > (parseInt(b.notification_count) + parseInt(b.chat_notification_count) + parseInt(b.unread_notification_count))) return -1;
                  if ((parseInt(a.notification_count) + parseInt(a.chat_notification_count) + parseInt(a.unread_notification_count)) < (parseInt(b.notification_count) + parseInt(b.chat_notification_count) + parseInt(b.unread_notification_count))) return 1;
                  if (a.project_id == selectedOffice) return 2;
                  return 0;
                  }).sort((a,b)=>{
                    if (a.project_id == localStorage.getItem("selectedOffice")){
                      if (a.pin_count == 1) {
                        return -1
                      } else {
                        return -2
                      }
                    } else {
                      return 1
                    }
                  }).filter((item) => item.project_id ? item.project_id != selectedOffice : item.id != selectedOffice).slice(
                    0,
                    activeOfficePage > 1
                      ? (activeOfficePage - 1) * SPACE_LIST_RESULTS_PER_PAGE + 10
                      : activeOfficePage * SPACE_LIST_RESULTS_PER_PAGE
                  ).map((space, index) => {
                    if (
                      searchBarFocus &&
                      searchString == "" &&
                      space.pin_count &&
                      space.pin_count != 1
                    ) {
                      return <React.Fragment key={index}></React.Fragment>;
                    } else {
                      // && !localStorage.getItem('selectedVirtualSpace')  <-- condition remove for display virtual space in create Teneat Page  -->
                      const activeClass =
                      (selectedOffice > 0 && ((space.project_id ? space.project_id == selectedOffice : space.id == selectedOffice) && (!contactlistShow)) && !noActiveSpacePageNames.includes(pageName)) && (localStorage.getItem("isLiteVersion") != "true") ? "active" : "";
                      const badgeClass =
                        space.notification_count > 0 ? "with-badge" : "";
                      const projectStatus =
                        space.project_status && space.project_status == 2
                          ? "inprogress half"
                          : space.project_status == 3
                            ? "inprogress half"
                            : space.project_status == 5
                              ? "done half"
                              : space.project_status == 6
                                ? "done with_opacity"
                                : space.project_status == 1
                                ? "onboarding"
                                : "";
                      const projectStatusArr = {
                        1: {
                          name: _l("l_todo"),
                          class: "color-gray",
                        },
                        2: {
                          name: _l("l_free_trial"),
                          class: "color-blue",
                        },
                        3: {
                          name: _l("l_subscription_required"),
                          class: "color-blue",
                        },
                        5: {
                          name: _l("l_ready"),
                          class: "color-green",
                        },
                        6: {
                          name: _l("l_active"),
                          class: "color-green",
                        },
                        7: {
                          name: _l("l_inactive"),
                          class: "color-gray",
                        },
                      };
                      var totalNotiCount;
                      if (space.is_virtual_space == 1) {
                        totalNotiCount = parseInt(space.unread_chat_msg_count) + parseInt(space.unread_notification_count)
                      } else {
                        totalNotiCount = Number(space.notification_count)+ Number(space.unread_notification_count) + (space.chat_notification_count ? Number(space.chat_notification_count) : 0);
                      }
                      return (
                        <li
                          key={index}
                          className={`office-box 1 ${(pageName && ['PremiumSubscription', 'leadPage', 'dashboard','MyEmail',"dashboardnew","ProviderList"].includes(pageName)) || ["/create-dpgf-benchmark", '/dashboardfordaterange',"/providerlist"].includes(window.location.pathname) || dashboardBenchmarkId > 0
                            ? ""
                            : activeClass
                            } ${badgeClass}  with-circle ${projectStatus}`}
                          data-space-div={space.project_id}
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <div
                              className="office-list-left flex-grow-1 w-100"
                              onClick={()=>{
                               
                            }}
                            >
                              <div className="d-flex align-items-center">

                                {["right"].map((placement) => (
                                  <OverlayTrigger
                                    trigger={['hover', 'focus']}
                                    // show={true}
                                    delay={{ show: 3000}}
                                    // trigger="click"
                                    key={placement}
                                    placement={placement}
                                    onEnter={(e)=> space.is_virtual_space == 1 ? {} : getspacedata(e,space.project_id)}
                                    overlay={
                                      <Popover
                                        id={`popover-positioned-${placement}`}
                                        className="leftpanelpopover"
                                      >
                                       {spaceData.length > 0 ?  
                                       <Popover.Body className="p-0">
                                          <div className="p-0 popover-detail-wrapper">
                                            <li className={`office-box  p-15 hr_1 pb-2 ${(pageName && ['PremiumSubscription', 'leadPage', 'dashboard','MyEmail',"dashboardnew"].includes(pageName)) || window.location.pathname == '/dashboardfordaterange'
                                            ? ""
                                            : activeClass
                                            } ${badgeClass}  with-circle ${projectStatus}`}>
                                              <a href="#/" className="w-100 text-truncate">
                                                <div className="d-flex align-items-center">
                                                  <div className="c-list-icon">
                                                    <div className="h40w40 with-bg d-flex align-items-center bg-white-05 radius_5 rounded-circle comman-round-box">
                                                      
                                                      <span className="m-auto c-font f-10 p-1 office-subtitle text-truncate hover-span">{ spaceData[0].office_title }</span>
                                                    </div>
                                                  </div>
                                                  <div className="ps-2 w100minus40 title-fonts">
                                                    <div className="c-list-detail text-truncate color-white c-font f-12">
                                                      { spaceData[0].office_title }
                                                    </div>
                                                    <div className="c-font f-10 title-fonts color-white-60 text-truncate">
                                                      { spaceData[0].postal_address }
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="w-100 d-flex align-items-center pt-3">
                                                  <div className="d-flex align-items-center progress-slider flex-grow-1">
                                                    <div className="progress">
                                                      <div className="progress-bar" style={{ width: `${space.workspace_score}%` }}></div>
                                                    </div>
                                                    <div className="progress-text c-font f-10 color-white-60 title-fonts">{space.workspace_score}%</div>
                                                  </div>
                                                </div>
                                              </a>
                                            </li>
                                            <li className="hr_1">
                                              <div className="p-15 py-2">
                                                <div className="color-white-60 text-truncate c-font f-12">
                                                  <span>{_l("l_date")}: </span>
                                                  <span className="with_separator_10">{spaceData[0].date_added}</span><span className="ps-2">{_l("l_project_id")}: </span><span>{spaceData[0].project_id}</span></div>
                                              </div>
                                            </li>
                                            <li className="hr_1">
                                              <div className="p-10">
                                                <div className="c-font f-12 color-white-60 text-truncate">{_l("l_kpi")}</div>
                                                <div className="p-15 pb-0 px-0 row ">
                                                  <div className="col-xl-6">
                                                    <div className="d-flex pb-2">
                                                      <div className="comman_action_icon me-2">
                                                        <div className="d-flex c-list-icon action_icon on-hover-active-toggle-img light-theme-img">
                                                          <Buildings size={16} weight="light" className="c-icons" />
                                                        </div>
                                                      </div>
                                                      <div className="card-list-heading text-truncate ps-1">
                                                        <div className="title-fonts c-font f-16 line-height-1 text-truncate fw-semibold">{spaceData[0].superficies}</div>
                                                        <div className="color-white-60 c-font f-12 text-truncate pt-1">
                                                          <span className="hover-span">{_l("l_sq_meter")}</span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="col-xl-6">
                                                    <div className="d-flex pb-2">
                                                      <div className="comman_action_icon me-2">
                                                        <div className="d-flex c-list-icon action_icon on-hover-active-toggle-img light-theme-img">
                                                          <UserCircleGear size={16} weight="light" className="c-icons" />
                                                        </div>
                                                      </div>
                                                      <div className="card-list-heading text-truncate ps-1">
                                                        <div className="title-fonts c-font f-16 line-height-1 text-truncate fw-semibold">{spaceData[0].employees_count}</div>
                                                        <div className="color-white-60 c-font f-12 text-truncate pt-1">
                                                          <span className="hover-span">{_l("l_tetants")}</span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="col-xl-6">
                                                    <div className="d-flex pb-2">
                                                      <div className="comman_action_icon me-2">
                                                        <div className="d-flex c-list-icon action_icon on-hover-active-toggle-img light-theme-img">
                                                          <UsersThree size={16} weight="light" className="c-icons" />
                                                        </div>
                                                      </div>
                                                      <div className="card-list-heading text-truncate ps-1">
                                                        <div className="title-fonts c-font f-16 line-height-1 text-truncate fw-semibold">{spaceData[0].operator_count}</div>
                                                        <div className="color-white-60 c-font f-12 text-truncate pt-1">
                                                          <span className="hover-span">{_l("l_operators")}</span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="col-xl-6">
                                                    <div className="d-flex pb-2">
                                                      <div className="comman_action_icon me-2">
                                                        <div className="d-flex c-list-icon action_icon on-hover-active-toggle-img light-theme-img">
                                                          <Wrench size={16} weight="light" className="c-icons" />
                                                        </div>
                                                      </div>
                                                      <div className="card-list-heading text-truncate ps-1">
                                                        <div className="title-fonts c-font f-16 line-height-1 text-truncate fw-semibold">{spaceData[0].equipments_count}</div>
                                                        <div className="color-white-60 c-font f-12 text-truncate pt-1">
                                                          <span className="hover-span">{_l("l_equipments")}</span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>

                                                </div>
                                              </div>
                                            </li>
                                            <div>
                                              <div className="">
                                                <div className="c-font f-12 color-white-60 p-10 pb-0">{_l("l_notification")}</div>
                                                <ul className="notification-listing">
                                                  <li className="hr_sepretr dropdown-item">
                                                    <div className="d-flex align-items-center text-truncate  ">
                                                      
                                                        <div className="c-list-icon ">
                                                          <AddTaskIcon className="HW18" />
                                                        </div>
                                                        <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                                          <div className="c-list-detail text-truncate">{_l("l_tasks")}</div>
                                                          {
                                                            space.notification_count == 0 ? (<></>) : (
                                                              <div className="unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex">{spaceData[0].notification_count}</div>
                                                          )}
                                                        </div>
                                                    </div>
                                                  </li>
                                                  <li className="hr_sepretr dropdown-item">
                                                    <div className="d-flex align-items-center text-truncate  ">

                                                      <div className="c-list-icon ">
                                                        <RequestTaskIcon className="HW18 fill-transparent" />
                                                      </div>
                                                      <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                                        <div className="c-list-detail text-truncate">{_l("l_request")}</div>
                                                      </div>
                                                    </div>
                                                  </li>
                                                  <li className="hr_sepretr dropdown-item">
                                                    <div className="d-flex align-items-center text-truncate  ">
                                                      
                                                        <div className="c-list-icon ">
                                                        <Chat size={16} weight="light" className={`c-icons`} />
                                                        </div>
                                                        <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                                          <div className="c-list-detail text-truncate">{_l("l_chat_modal")}</div>
                                                          {
                                                           spaceData[0].notification_count && space.notification_count == 0 ? (<></>) : (
                                                          <div className={`unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex ${spaceData[0].chat_notification_count > 0 ? "" : "d-none"}`}>{spaceData[0].chat_notification_count}</div>
                                                          )}  </div>
                                                    </div>
                                                  </li>
                                                  <li className="hr_sepretr dropdown-item">
                                                    <div className="d-flex align-items-center text-truncate  ">
                                                      
                                                        <div className="c-list-icon ">
                                                        <FileText size={16} weight="light" className="c-icons" />
                                                        </div>
                                                        <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                                          <div className="c-list-detail text-truncate">{_l("l_documents")}</div>
                                                        </div>
                                                    </div>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </Popover.Body> : <></>}
                                      </Popover>
                                    }
                                  >
                                    <a href="#/"
                                      className="h50w50 d-flex align-items-center justify-content-center ic-provider-list office-list-circle radius_3"
                                      data-tip={"#"+(space.project_id) + (space.is_virtual_space == 1 ? (" - "+space.name) : "")}
                                      data-effect="solid"
                                      data-delay-show='1000'
                                      data-class="tooltip-main"
                                      data-place="right"
                                      onClick={() => {
                                        if (space.is_virtual_space && space.is_virtual_space == 1) {
                                          selectAdminTeam(space)
                                        }else{
                                          onClickOfficeChange(space)
                                        }
                                      }} 
                                    >
                                      {/* ${overdueTaskCounts.some((data)=>data.project_id == space.project_id && data.overdue_count > 0) ? "bg-danger border-0" :""} */}
                                      { space.is_virtual_space == 1 ?  
                                      <div className="bg-white-05 h50w50 comman-round-box radius10 title-fonts d-flex flex-column border-0">
                                        <div className="m-auto c-font f-13 office-subtitle text-truncate hover-span">
                                          <UsersThree size={18} weight="light" className="c-icons" /> 
                                        </div>
                                        <span className=" color-white-80 d-block c-font f-13 pt-0 office-subtitle  hover-span fw-light hover-span line-height-1 px-1 text-truncate text-center w-100">
                                          {space.name}
                                        </span>
                                        <span
                                          className={`rounded-pill badge-custom d-flex ${totalNotiCount > 0
                                            ? ""
                                            : "d-none"
                                            }`}
                                          data-proj-unread-cnt-id={space.project_id}
                                        >
                                          {totalNotiCount}
                                        </span>
                                      </div> : 
                                      <div className={`bg-white-05 h45w45 comman-round-box radius_3 title-fonts d-flex flex-column square_box`}>
                                        <span className="m-auto c-font f-13 office-subtitle text-truncate hover-span">
                                            <Buildings size={16} weight="light" className="c-icons" />
                                        </span>
                                        <span className=" color-white-80 d-block c-font f-13 pt-0 office-subtitle  hover-span fw-light hover-span line-height-1 px-1 text-truncate text-center w-100">{space.office_title
                                            ? space.office_title :
                                            space.name ? space.name 
                                            : space.postal_address} </span>
                                        <span
                                          className={`rounded-pill badge-custom d-flex ${totalNotiCount > 0
                                            ? ""
                                            : "d-none"
                                            }`}
                                          data-proj-unread-cnt-id={space.project_id}
                                        >
                                          {totalNotiCount}
                                        </span>
                                        {space.pin_count &&
                                          space.pin_count === "1" ? (
                                          <span className="pin-office">
                                            <PushPin size={12} weight="fill" className="m-auto Push-Pin-fill" />
                                          </span>
                                          
                                        ) : (
                                          ""
                                        )}
                                        {overdueTaskCounts && overdueTaskCounts.filter((data)=>data.project_id == space.project_id).map((data)=>{
                                          return(<>
                                          
                                           <span
                                          className={`rounded-pill badge-custom left-side d-flex  bg-danger ${ data.overdue_count > 0
                                            ? ""
                                            : "d-none"
                                            }`}
                                        ><span className="color-white">
                                          {data.overdue_count > 99 ? "99+" : data.overdue_count}
                                          </span>
                                        </span>
                                          </>)
                                        })}
                                      </div>
                                    }
                                    </a>
                                  </OverlayTrigger>
                                ))}
                                <a href="#/" className="office-list-detail w100minus50 left-toggle-none">
                                <div className="office-list-desc-left w-100">
                                        <div className="align-items-center d-flex justify-content-between ">
                                        <div className="d-flex align-items-center text-truncate">
                                            <div className="text-truncate max-width-160px  pe-2 c-font f-12" onClick={() => {localStorage.setItem("selected_project_client_id",space.client_id);
                                            onClickOfficeChange(space)}}>
                                              {space.office_title
                                                ? space.office_title :
                                                space.name ? space.name 
                                                : _l("l_office_title_placeholder")}
                                            </div>
                                          </div>
                                          <div className="d-flex align-items-center justify-content-end dropstart dropdown-static-display">
                                        { space.is_virtual_space == 1 ? <></> :
                                          <div className="progress-slider w-70px">
                                            <div className="d-flex align-items-center">
                                              <div className="progress">
                                                <div
                                                  className="progress-bar"
                                                  style={{
                                                    width: `${space.workspace_score}%`,
                                                  }}
                                                ></div>
                                              </div>
                                              <div className="progress-text c-font f-10 title-fonts">
                                                {space.workspace_score}%
                                              </div>
                                            </div>
                                          </div>
                                        }
                                          {/* <div className="badge badge-white rounded-pill badge text-truncate badge-white m-w100px rounded-pill fw-light ms-1 me-1" >
                                              <span
                                                className={`text-truncate  ${projectStatusArr[
                                                  space.project_status
                                                ]
                                                  ? projectStatusArr[
                                                  space.project_status
                                                  ]["class"]
                                                  : ""
                                                  }`}
                                                 
                                              >
                                                {projectStatusArr[
                                                  space.project_status
                                                ]
                                                  ? projectStatusArr[
                                                  space.project_status
                                                  ]["name"]
                                                  : ""}
                                              </span>
                                            </div> */}
                                            
                                            <div className="comman_action_icon">
                                            <a href="#/"
                                              className="action_icon px-1  h25w25 on-hover-active-toggle-img "
                                              onClick={() => {
                                                pinProject(space.project_id);
                                              }}
                                            >
                                              {space.pin_count &&
                                                space.pin_count === "1" ? (
                                                <div className="m-auto">
                                                  <PushPin size={16} weight="fill" className="c-icons Push-Pin-fill" />
                                                </div>
                                              ) : (
                                                <div className="m-auto">

                                                  <PushPin size={16} weight="light" className="c-icons" />
                                                </div>
                                              )}
                                            </a>
                                            </div>
                                            <div className="multi_inner_wrapper with-min-width d-flex">
                                          {
                                            space.members && space.members.filter((u) => u.role == 3 || u.role == 12).length > 0 ?
                                              space.members.filter((u) => u.role == 3 || u.role == 12).map((dd, index) => {
                                                if (index == 0) {
                                                  return (
                                                    <div className="comman-image-box h20w20 rounded-circle"
                                                      data-tip={dd.fullname}
                                                      data-effect="solid"
                                                      data-delay-show='1000'
                                                      data-class="tooltip-main"
                                                    >
                                                      <div
                                                        className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 rounded-circle"
                                                        style={{
                                                          backgroundImage: `url('${dd.profile_image_url}')`
                                                        }}
                                                      ></div>
                                                    </div>
                                                  )
                                                }
                                              })
                                              : <></>
                                          }
                                          {
                                            space.members && space.members.filter((u) => u.role == 3 || u.role == 12).length > 1 ?
                                              <div className="comman-image-box h20w20 rounded-circle with_overlay dropstart">
                                                <UncontrolledDropdown className="align-items-center d-flex dropdown justify-content-center w-100">
                                                  <DropdownToggle data-toggle="dropdown" tag="div" className="">
                                                    <div className="unread_count m-auto c-font f-12 d-flex h25w25"><span className="m-auto">+{space.members.filter((u) => u.role == 3 || u.role == 12).length - 1}</span></div>
                                                    <GeneralListing unControlList={true} list={space.members.filter((u) => u.role == 3 || u.role == 12).map((user) => {
                                                      return {
                                                        name: user.fullname,
                                                        image: user.profile_image_url,
                                                        id: user.staff_id,
                                                      };
                                                    })} />
                                                  </DropdownToggle>
                                                </UncontrolledDropdown>
                                              </div>
                                              :
                                              <></>
                                          }
                                              
                                              
                                            </div> 
                                          { space.is_virtual_space == 1 ? 
                                             space.creator_details.staff_id == adminUser  ? 
                                            <div className={` comman_action_icon with_margin_minus_10px dropstart`}>
                                            <a href="#/"
                                              className="c-three-dots office-list-static action_icon h25w25 on-hover-active-toggle-img left-toggle-none  "
                                              id="ActiveOfficeOptionsDropdown"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                              data-bs-auto-close="outside"
                                              onClick={() => {
                                                // HideDropdown();
                                                jquery(this).siblings(".dropdown-menu").addClass("show");
                                                setUserList([]);
                                              }}
                                            >
                                              <DotsThreeVertical size={16} weight="light" className="c-icons" />
                                            </a>
                                            <ActionDropdown
                                              actions={
                                                admin == 'true' && space.creator_details.staff_id == adminUser ?
                                                  [{
                                                    actionName: _l("l_edit"),
                                                    actionHandler: () => {
                                                      handleEditAdminTeam(space)
                                                    },
                                                    actionIcon: "PencilSimpleLineIcon",
                                                  },
                                                  {
                                                    actionName: _l("l_delete"),
                                                    actionHandler: () => {
                                                      dispatch(
                                                        showConfirmation(
                                                          _l("l_are_you_sure"),
                                                          _l("l_you_want_to_delete_space"),
                                                          _l("l_yes"),
                                                          _l("l_no"),
                                                          undefined,
                                                          () => { handleDeleteTeam(space) }
                                                        )
                                                      );
            
                                                    },
                                                    actionIcon: "TrashIcon",
                                                  },
                                                  ]
                                                  : []
                                              }
                                              widthClass="me-1"
                                            />
                                            </div> : <></>
                                          :
                                            <div className={` comman_action_icon with_margin_minus_10px dropstart ${ (contact_role == 3 ) ? "d-none" : ""}`}>
                                            <a href="#/"
                                              className="c-three-dots office-list-static action_icon h25w25 on-hover-active-toggle-img left-toggle-none  "
                                              id="ActiveOfficeOptionsDropdown"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                              data-bs-auto-close="outside"
                                              onClick={() => {
                                                // HideDropdown();
                                                jquery(this).siblings(".dropdown-menu").addClass("show");
                                                setUserList([]);
                                              }}
                                            >
                                              <DotsThreeVertical size={16} weight="light" className="c-icons" />
                                            </a>
                                            <ActionDropdown widthClass="w-170"
                                              actions={
                                                admin
                                                  ? space.is_allowed_to_edit == 0 ? [
                                                    {
                                                      actionName: _l("l_create")+ " " + _l('l_support'),
                                                      actionHandler: () => {
                                                        dispatch(
                                                          showConfirmation(
                                                            _l("l_are_you_sure"),
                                                            _l("l_you_want_to_create_chat_channels"),
                                                            _l("l_yes"),
                                                            _l("l_no"),
                                                            undefined,
                                                            () => { createChatChannels(space["project_id"]); }
                                                          )
                                                        );
                                                      },
                                                      actionIcon: "ChatIcon",
                                                    },
                                                    {
                                                      actionName: _l("l_login_as_user"),
                                                      actionHandler: () => {
                                                        setproject_id(space.project_id);
                                                            setshowProjectStatusList(false);        
                                                        getUserList(
                                                          space["client_id"],
                                                          space["project_id"]
                                                        );
                                                        showLeftPanel(true)
                                                      },
                                                      actionIcon: "UserIcon",
                                                    },
                                                    {
                                                      actionName: _l("l_manage_project_member"),
                                                      actionHandler: () => {
                                                        setManagePeopleModal(true);
                                                        setproject_id(space.project_id);
                                                      },
                                                      actionIcon: "IdentificationBadgeIcon",
                                                    },
                                                    {
                                                      actionName: _l("l_open_ai"),
                                                      actionHandler: () => {
                                                        setshowOpenAiModal(true);
                                                        setproject_id(space.project_id);
                                                        setspaeContent(space.content)
                                                      },
                                                      actionIcon: "AiIcon",
                                                    },
                                                    {
                                                      actionName: _l("l_delete") +" "+ _l("l_space"),
                                                      actionHandler: () => {
                                                        setproject_id(space.project_id);
                                                        setOfficeId(space.id)
                                                        setShowToast(true);
                                                        prepareToast(
                                                          "toast-with-btn",
                                                          _l("l_space_delete_conformation"),
                                                          _l("l_confirm")
                                                        );
                                                      },
                                                      actionIcon: "TrashIcon",
                                                    },
                                                    {
                                                      actionName: _l("l_change") +" "+ _l("l_project_status"),
                                                      actionHandler: () => {
                                                        setSelectedspaceStatus(parseInt(space.project_status == 0 ? 1 : space.project_status));
                                                        setOfficeId(space.id)
                                                        setproject_id(space.project_id);
                                                        setshowProjectStatusList(true)                                        
                                                      },
                                                      actionIcon: "FlagIcon",
                                                    },
                                                  ] : [
                                                    {
                                                      actionName: _l("l_explore_feature"),
                                                      actionHandler: () => {
                                                        dispatch(setProject(space.project_id));
                                                        setSelectedOffice(space.project_id)
                                                        localStorage.setItem("selectedOffice", space.project_id);
                                                        navigate(`/explore-features?space=${space.project_id}`);
                                                        
                                                      },
                                                      actionIcon: "PencilSimpleLineIcon",
                                                    },
                                                    {
                                                      actionName: _l("l_create")+ " " + _l('l_support'),
                                                      actionHandler: () => {
                                                        dispatch(
                                                          showConfirmation(
                                                            _l("l_are_you_sure"),
                                                            _l("l_you_want_to_create_chat_channels"),
                                                            _l("l_yes"),
                                                            _l("l_no"),
                                                            undefined,
                                                            () => { createChatChannels(space["project_id"]); }
                                                          )
                                                        );
                                                      },
                                                      actionIcon: "ChatIcon",
                                                    },
                                                    {
                                                      actionName: _l("l_login_as_user"),
                                                      actionHandler: () => {
                                                        getUserList(
                                                          space["client_id"],
                                                          space["project_id"]
                                                        );
                                                        showLeftPanel(true)
                                                      },
                                                      actionIcon: "UserIcon",
                                                    },
                                                    {
                                                      actionName: _l("l_manage_project_member"),
                                                      actionHandler: () => {
                                                        setManagePeopleModal(true);
                                                        setproject_id(space.project_id);
                                                      },
                                                      actionIcon: "IdentificationBadgeIcon",
                                                    },
                                                  ]
                                                  : [
                                                    {
                                                      actionName: _l("l_explore_feature"),
                                                      actionHandler: () => {
                                                        // navigate(`/space-onboarding?space=${space.project_id}&edit=1`)
                                                        dispatch(setProject(space.project_id));
                                                        setSelectedOffice(space.project_id)
                                                        localStorage.setItem("selectedOffice", space.project_id);
                                                        navigate(`/explore-features?space=${space.project_id}`);
                                                        // navigate(`/companyprofile`)
                                                      },
                                                      actionIcon: "PencilSimpleLineIcon",
                                                    },
                                                    {
                                                      actionName: _l("l_manage_project_member"),
                                                      actionHandler: () => {
                                                        setManagePeopleModal(true);
                                                        setproject_id(space.project_id);
                                                      },
                                                      actionIcon: "IdentificationBadgeIcon",
                                                    },
                                                  ] 
                                              }
                                            list={showProjectStatusList ? projectStatusArray : userList.map((item, index) => {
                                              return {
                                                name: item.fullname || item.name,
                                                image: item.profile_image_url,
                                                email: item.email,
                                                type: item.type ? item.type : "",
                                                user_type: item.user_type ? item.user_type : "",
                                                is_primary: item.is_primary
                                                  ? item.is_primary
                                                  : "",
                                                is_boss_operator: item.is_boss_operator
                                                  ? item.is_boss_operator
                                                  : "",
                                                client_id: item.userid,
                                                contact_role: item.contact_role,
                                                company: item.company,
                                                id: item.user_type == "Provider" ? item.staff_id : item.contact_id ,
                                                staff_id: item.staff_id,
                                                boss_operator_id: item.boss_operator_id,
                                                session_details : item.session_details,
                                                last_login: item.last_login,
                                                no_of_comments: item.no_of_comments,
                                                task_score: item.task_score,
                                                contact_id : item.contact_id

                                              };
                                            })}
                                            isLoginAsUserDataLoaded={isLoginAsUserDataLoaded}
                                            loder={loder}
                                            unControlList={false}
                                            setLoder={setLoder}
                                            handler={(actionItem) => {
                                              showProjectStatusList ?
                                                handleChangeProjectStatus(actionItem.id) :
                                                loginHandler(
                                                  actionItem.email,
                                                  actionItem.client_id,
                                                  actionItem.type,
                                                  actionItem.id,
                                                  0,
                                                  actionItem
                                                );
                                            }}
                                            selectedspaceStatus={selectedspaceStatus}
                                            setSelectedspaceStatus={setSelectedspaceStatus}
                                            />
                                            </div>
                                          }
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center color-white-60" onClick={()=>{onClickOfficeChange(space)}}>
                                        { space && space.chat_group_message == "" ? <div className="c-font f-10 text-truncate w100minus85 pe-2">{space && space.postal_address}</div> :
                                          <div
                                            className="c-font f-10 text-truncate w100minus85 pe-2"
                                            data-tip={ReactHtmlParser(space.chat_group_message)}
                                            data-for={randomID}
                                            data-effect="solid"
                                            data-delay-show='1000'
                                            data-class="tooltip-main"
                                            data-place="top"
                                          >
                                            <b>{space && space.chat_group_name && space.chat_group_name.split(" ")[0]}</b>
                                            {": " + ReactHtmlParser(space.chat_group_message)}
                                          </div>
                                        }
                                          { space.chat_group_message == "" ? <React.Fragment></React.Fragment>
                                            : <div className="badge badge-white rounded-pill badge text-truncate badge-white m-w100px rounded-pill fw-light ms-1 me-1 py-1 px-2">
                                              <span className={`text-truncate`}>
                                                {
                                                moment(space.chat_group_message_date).format("L") == moment(new Date()).format("L") ? moment(space.chat_group_message_date).format("hh:mm A")
                                                 : moment(space.chat_group_message_date).format("DD/MM/YYYY")
                                                }
                                              </span>
                                              <ReactTooltip />
                                              </div> 
                                          }
                                        </div>
                                        

                                    </div>
                                  
                                </a>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    }
                  })}
                {getSearchedOffice.length >= (activeOfficePage - 1) * 10 + 10 &&
                  getSearchedOffice.length != (activeOfficePage - 1) * 10 + 10 ? (
                  <li
                    className="office-box left-view-more-btn"
                    onClick={() => {
                      setActiveOfficePage(activeOfficePage + 1);
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="office-list-left flex-grow-1">
                        <div className="d-flex align-items-center">
                          <a href="#/" className="h50w50 h-100 d-flex align-items-center justify-content-center ic-provider-list office-list-circle bg-transparent">
                            <div className="bg-white-05 h40w40 comman-round-box rounded-circle title-fonts d-flex on-hover-active-toggle-img">
                              <span className="m-auto">
                                <CaretDown size={12} weight="light" className="c-icons mx-2" />
                              </span>
                            </div>
                          </a>
                          <div className="office-list-detail w100minus50 left-toggle-none">
                            <a href="#/" className="btn btn-white-03 w-100 view-more-btn bg-transparent p-0">
                              <div className="d-flex align-item-center justify-content-between comman_action_icon">
                                <span className=" title-fonts c-font f-12 my-auto">
                                  {_l("l_view_more_btn")}
                                </span>
                                <div className="c-three-dots office-list-static h25w25 on-hover-active-toggle-img left-toggle-none with_margin_minus_10px">
                                  <CaretDown size={12} weight="light" className="c-icons" />
                                </div>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  className={`ms-2 d-none ${loder.todoLoder ? "opacity-1" : "opacity-0"
                                    }`}
                                  aria-hidden="true"
                                />
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ) : (
                  <></>
                )}
                <div>
                  {userType != 'staff' && ((spaceFilter.length && spaceFilter.some((s) => s == 7)) ||
                    (searchString == "" &&
                      getinActiveSpaces &&
                      (officeFilterKey == "inactive" ||
                        spaceFilter.length == 0)) &&
                    getinActiveSpaces.length) ? (
                    <li className="office-box no_border py-0 inactive-office">
                      <div className="d-flex align-items-center">
                        <div className="office-list-left flex-grow-1">
                          <div className="d-flex align-items-center">
                            <div className="h50w50 comman-round-box with-bg d-flex rounded-circle border-dashed-2px on-hover-bg-10 opacity-0">
                              <div
                                className="comman-bg-img h-100 w-100 bg-style-cover cover-40"
                                style={{ backgroundImage: `url(${PlusIcon})` }}
                              ></div>
                            </div>

                            <div className="office-list-detail w100minus70 left-toggle-none ps-3">
                              {_l("l_inactive")}
                            </div>
                          </div>
                        </div>
                        <div className="office-list-desc-right d-flex">
                          <Button variant="white-03" size="sm" className="ms-auto" onClick={() => { setInactiveOfficeShow(!inactiveOfficeShow) }}>
                            {inactiveOfficeShow ? (
                              <CaretDown size={12} weight="light" className="c-icons" />
                            ) : (
                              <CaretUp size={12} weight="light" className="c-icons" />
                            )}
                          </Button>
                        </div>
                      </div>
                    </li>
                  ) : (
                    <></>
                  )}
                </div>
                {(userType != 'staff' && spaceFilter.length && spaceFilter.some((s) => s == 7)) ||
                  (searchString == "" &&
                    getinActiveSpaces &&
                    (officeFilterKey == "inactive" || spaceFilter.length == 0) &&
                    getinActiveSpaces.length) ? (
                  getinActiveSpaces.map((space, index) => {
                    return (
                      <li key={index} className={`office-box   inactive-office ${inactiveOfficeShow ? "d-none" : ""}`}>
                        <div className="d-flex align-items-center">
                          <div className="office-list-left flex-grow-1">
                            <div className="d-flex align-items-center">
                              <div
                                className="h50w50 h-100 d-flex align-items-center justify-content-center ic-provider-list"
                                data-bs-toggle="tooltip"
                                title="Office name"
                              >
                              </div>
                              <div className="office-list-detail w100minus50 text-truncate left-toggle-none">
                                <div className="d-flex">
                                  <div className="office-list-desc-left text-truncate ps-1">
                                    <div className="opacity-50 text-truncate">
                                      {space.office_title
                                        ? space.office_title
                                        : _l("l_office_title_placeholder")}
                                    </div>
                                    <div className="c-font f-10 color-white-80 opacity-50 text-truncate">
                                      {space.postal_address}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="office-list-desc-right left-toggle-none ">
                            <div className="d-flex align-items-center justify-content-end dropstart">
                              <a href="#/"
                                className="c-three-dots h25w25 d-flex"
                                id="InactiveOfficeOptionsDropdown"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <DotsThreeVertical size={16} weight="light" className="c-icons" />
                              </a>
                              <ActionDropdown widthClass="w-170"
                                actions={[
                                  {
                                    actionName: _l("l_delete"),
                                    actionHandler: () => { },
                                    actionIcon: "TrashIcon",
                                  },
                                ]}
                              />
                            </div>
                            <div className="c-font f-10 color-white-80 text-end opacity-50">
                              France, Paris
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <></>
                )}
                <ReactTooltip  id={randomID} />
              </ul>
            ) : searchString == "" ? (
              <div ref={officeListDivRef}>
                <CommanPlaceholder imgType="no-office" placeholderText = {_l("l_no_office_to_show")} />
              </div>
            ) : (
              <div ref={officeListDivRef}>
                <CommanPlaceholder imgType="no-office" placeholderText = {_l("l_no_office_found")} />
              </div>
            )}
            <div className="lower-part mt-auto   ">
              <div className={`position-relative left-option-box mt-0 d-flex ${hideMenuButton("searchBar") ? "for-disabled" : ""}`}>
                {
                    selectedStaffMembersForSpaceFilter && selectedStaffMembersForSpaceFilter.length
                  ?
                    <div className="left-option-box-absolute p-10 z-index-12">
                      <div className="align-items-center d-flex upload-image-preview pt-1">
                        {
                          selectedStaffMembersForSpaceFilter.map((member, index) => {
                            return (
                              <div key={index} className="h40w40 comman-round-box with-bg rounded-circle bg-white-05 me-3">
                                <a href="#/" className="upload-close" onClick={() => applyStaffUserFilter(member, true)}>
                                  <X size={12}  weight="light" className="c-icons" />
                                </a>
                                {
                                  member.showCrown
                                  ?
                                  <span className="badge-custom bg-transparent d-flex left-side rounded-pill border-0">
                                    <PremiumEstimateIconCrown className="stroke-transparent path-warning HW14 position-absolute" />
                                  </span>
                                  :
                                  <React.Fragment></React.Fragment>
                                }
                                <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url('${member.profile_image_url}')`, }}>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  :
                    <React.Fragment></React.Fragment>
                }
                {
                  hideMenuButton("searchBar")
                  ?
                    <React.Fragment>
                       <div className="bg-white-05 h50w50 comman-round-box radius_3 d-flex border-transparent"></div>
                       <div className="flex-grow-1 bg-white-05 d-flex radius_3 ml-10 left-toggle-none"></div>
                    </React.Fragment>
                  :
                    <React.Fragment>
                      {contact_role == 3 ? <></> : <><a href="#/"
                        className={`bg-white-05 ${spaceSearchOnGoing ? "z-index-12" : "" } ${contact_role == 3 ? "" : ""} h50w50 LeftPanelSearch comman-round-box radius_3 d-flex border-transparent office-list-icon-search flex-column justify-content-center align-items-center on-hover-active-toggle-img`}
                        data-tip={`${_l("l_search")}`}
                        data-for={randomID}
                        onClick={(e) => { 
                          e.stopPropagation();
                          jquery("#intro-office-list").addClass("searchbutton");
                          setShowBackDropClass(true); 
                          setSpaceSearchOnGoing(true);
                          showLeftpanelonSearchHover();
                        }}  
                        // onMouseOver={() => {
                        //   setTimeout(() => {
                        //     // OnMouseOverOfficeList();
                        //     setShowBackDropClass(true); 
                        //     setSpaceSearchOnGoing(true);
                        //     showLeftpanelonSearchHover();
                        // }, 1000);
                        // }}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-place="right"
                        data-class="tooltip-main"
                      >
                        <div className="comman-bg-img w-100 d-flex on-hover-active-toggle-img">
                          <MagnifyingGlass size={16} weight="light" className="c-icons" />
                        </div>
                        <div className="c-font color-white-80 d-block f-10 fw-light hover-span line-height-1 pt-2 px-1 text-truncate text-center w-100">{_l("l_search")}</div>
                      </a>
                      <div className="z-index-12 flex-grow-1 d-flex radius_3 title-fonts color-white-80  ml-10 left-toggle-none bgspacelisting" ref={officeSearchboxRef}>
                        <Autocomplete  
                          mainWidth="w-100"
                          inputClass="form-control border-0 bg-transparent office-list-icon-search-input d-flex align-items-center h-100 w-100"
                          dropdownClass="max-height-20vh" 
                          options={searchString.length && searchString.trim().charAt(0) === "@" ? mentionStaffArray : []} 
                          value={searchString} 
                          onChange={(e) => setSearchString(e.target.value)} 
                          onSelect={applyStaffUserFilter}
                          onFocus={() => {
                            // setSearchBarFocus(true);
                            getMentionUsersForFilter();
                            setShowBackDropClass(true);
                            setSpaceSearchOnGoing(true);
                          }}
                          filterFunctionForOptionString={(str) => str.replace("@", "")}
                          actualWidth={true}
                          isCallChatEnable={true}
                        />
                      </div> </>}
                    </React.Fragment>
                }
              </div>
              {userType == "staff" && isAdmin == 'true' ?
                <>
                </>
                : <></>}
              {localStorage.getItem("user_type") != "staff" ? (
                <div className={`position-relative left-option-box mt-0 d-flex d-none ${ parseInt(contact_role) != 1 || (!visibleOptionIcons.includes("plan") && !activeMenuOptions.includes("plan")) ? "d-none" : ""} `}>
                   <React.Fragment>
                        <div>
                          <a href="#/"
                            className={`bg-white-05 h50w50 comman-round-box radius_3 d-flex border-transparent left-panel-crown flex-column justify-content-center align-items-center on-hover-active-toggle-img ${PremiumPageActiveClass ? "" : "active"
                              }`}
                            data-tip={`${_l(estimateDetails.package_name ? estimateDetails.package_name : "l_stand_slone_platform" )}`}
                            data-for={randomID}
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main"
                            data-place="right"
                            onClick={() => {
                              if(!disabledThirdPartyToolAccess)
                              {
                                updateActiveMenuOptions("plan");
                                navigate("/premiumsubscription");
                                onPagechangeToggle();
                              }
                            }}
                          >
                            <div className="comman-bg-img w-100 d-flex">
                            <CurrencyEur size={16} weight="bold" className="c-icons color-orange" />
                            </div>
                            <div className="c-font color-white-80 d-block f-10 fw-light hover-span line-height-1 pt-2 px-1 text-truncate text-center w-100">{_l("l_my_plan")}</div>
                          </a>
                        </div>
                        <div
                          className="flex-grow-1 bgspacelisting d-flex radius_3 title-fonts color-white-80 ml-10 left-toggle-none"
                          onClick={() => {
                            if(!disabledThirdPartyToolAccess)
                            {
                              navigate("/premiumsubscription");
                            }
                          }}
                        >
                          <div className="d-flex w-85 justify-content-center align-items-center m-auto">
                            <a href="#/"
                              className="d-flex align-item-center justify-content-between w-100 flex-wrap"
                            >
                              <div className="">
                                {" "}
                                {Object.keys(estimateDetails).length &&
                                  estimateDetails.package_name
                                  ? estimateDetails.package_name
                                  : _l("l_stand_slone_platform")}{" "}
                              </div>
                              <div className="">
                                <span className="c-font fw-semibold">
                                  {Object.keys(estimateDetails).length &&
                                    estimateDetails.estimate_total
                                    ? estimateDetails.estimate_total + " "
                                    : "0"}
                                  /
                                </span>
                                <span className="c-font f-13">
                                  {" " + _l("l_month")}
                                </span>
                              </div>
                            </a>
                          </div>
                        </div>
                      </React.Fragment>
                </div>
              ) : (
                <div className={`position-relative left-option-box mt-0 d-flex ${hideMenuButton("teams") ? "for-disabled" : ""} ${(!visibleOptionIcons.includes("teams") && !activeMenuOptions.includes("teams")) ? "d-none" : "d-none"}`}>  {/*teams option all condition delete as hardik rdadiya */}
                  {hideMenuButton("teams")
                  ?
                    <React.Fragment>
                       <div className="bg-white-05 h50w50 comman-round-box radius_3 d-flex border-transparent"></div>
                       <div className="flex-grow-1 bg-white-05 d-flex radius_3 ml-10 left-toggle-none"></div>
                    </React.Fragment>
                  :
                  <React.Fragment>
                    <div>
                      <a href="#/"
                        className={`bg-white-05 h50w50 comman-round-box radius_3 d-flex border-transparent team-box-open left-panel-team d-flex flex-column justify-content-center align-items-center on-hover-active-toggle-img ${selectedTeam && Object.keys(selectedTeam).length > 0 && (!pageName || pageName !== "dashboard" && (pageName != "LiteVersion"))
                          ? "active"
                          : ""
                          }`}
                        data-tip={`${userType == "staff" ? _l("l_teams") : _l("l_software")
                          }`}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-for={randomID}
                        data-class="tooltip-main"
                        data-place="right"
                        onClick={() => {
                          setshowLeftPanelMenu(true);
                          setMyLeadActiveClass(true);
                          setMyEmailActiveClass(true);
                          setPremiumPageActiveClass(true);
                          if (userType == "staff" && virtualSpaceList && virtualSpaceList.length > 0 && virtualSpaceList.filter((s)=> s.is_selected == 1).length > 0) {
                            selectAdminTeam(virtualSpaceList.filter((s)=> s.is_selected == 1)[0])
                            // navigate("/");
                            localStorage.setItem("isLiteVersion",false)

                          }else{
                            setTeamLeftPanel(teamLeftPanel ? false : true);
                            jquery(".provider-list").addClass("expanded");
                            showLeftPanelNew();
                          }
                          if (pageName == "LiteVersion" || globalView =="liteVersionView" && teamLeftPanel) {
                            hideLeftPanelNew();
                          }
                          if(isAdmin)
                          {
                            getAdminTeamList();
                          }
                        }}
                      >
                        <div className="comman-bg-img w-100 d-flex">
                          {virtualSpaceUnreadMsg > 0 ?
                            <span className=" rounded-pill badge-custom  d-flex p-2 light-pill">{virtualSpaceUnreadMsg}</span>
                            : <></>}
                          <UsersThree size={16} weight="light" className="c-icons" />
                        </div>
                        <div className="c-font color-white-80 d-block f-10 fw-light hover-span line-height-1 pt-2 px-1 text-truncate text-center w-100">{_l("l_teams")}</div>
                      </a>
                    </div>
                    <div
                      className="flex-grow-1 bgspacelisting d-flex radius_3 title-fonts color-white-80 ml-10 left-toggle-none team-box-open"
                      onClick={() => {
                        setTeamLeftPanel(teamLeftPanel ? false : true);
                      }}
                    >
                      <div className="d-flex w-85 justify-content-center align-items-center m-auto">
                        <a href="#/" className="d-flex align-item-center justify-content-between w-100">
                          <div className="">
                            {selectedTeam ? selectedTeam.name : _l("l_team")}
                          </div>
                          <div className="ms-auto">
                            <span className="c-font fw-semibold"></span>
                            <span className="c-font f-13"></span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </React.Fragment>
                  }
                </div>
              )}
              {admin ? (
                <></>
              ) : (
                <></>
              )}

              <div className={`position-relative left-option-box mt-0 d-flex d-none ${hideMenuButton("mail") ? "for-disabled" : ""} ${(!visibleOptionIcons.includes("mail") && !activeMenuOptions.includes("mail")) ? "d-none" : ""}`}>
                  {hideMenuButton("mail")
                  ?
                    <React.Fragment>
                       <div className="bg-white-05 h50w50 comman-round-box radius_3 d-flex border-transparent"></div>
                       <div className="flex-grow-1 bg-white-05 d-flex radius_3 ml-10 left-toggle-none"></div>
                    </React.Fragment>
                  :
                    <React.Fragment>
                      <a href="#/"
                        className={`bg-white-05 h50w50 comman-round-box radius_3 d-flex border-transparent left-panel-email flex-column justify-content-center align-items-center on-hover-active-toggle-img ${MyEmailActiveClass ? "" : "active"
                          }`}
                        data-tip={`${_l("l_email")}`}
                        data-for={randomID}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                        data-place="right"
                        onClick={() => {
                          if(disabledThirdPartyToolAccess)
                          {
                            return false;
                          }
                          updateActiveMenuOptions("mail");
                          accessPremiumFeature(0, () => {
                            setSettingTopicName("l_third_party_integration");
                            setTopicMainKey("third_party_integrations");
                          }, true);
                        }}
                      >
                        <div className="comman-bg-img w-100 d-flex on-hover-active-toggle-img">
                         
                          {unreadComments ?
                            <span className=" rounded-pill badge-custom  d-flex p-2 light-pill">
                              {unreadComments}
                            </span>

                            : <></>}
                          {unreadMessages ?
                            <span className=" rounded-pill badge-custom email-orange d-flex light-pill">
                              {unreadMessages}
                            </span> : <></>}
                            {isEmailSynce === 0 ?  <span className="bg-base-header-color rounded-pill badge-custom d-flex p-2 light-pill">
                              <LockIcon className="HW12 position-absolute" />
                            </span>:<></>
                            }         
                            {userType == "staff" ? 
                            <EnvelopeSimple size={16} weight="light" className="c-icons" />    
                             :             
                            <BezierCurve size={16} weight="light" className="c-icons" />}

                        </div>
                        <div className="c-font color-white-80 d-block f-10 fw-light hover-span line-height-1 pt-2 px-1 text-truncate text-center w-100">{userType == "staff" ? _l("l_email"):_l("l_third_party_integration") } </div>
                      </a>
                      {showSyncMailModal ? (
                        <MailSetting
                          show={showSyncMailModal}
                          handleClose={mailModalHandleClose}
                          handleShow={mailModalHandleShow}
                          setSynchronizedMail={setSynchronizedMailLeft}
                          pageName={pageName}
                        />
                      ) : (
                        <></>
                      )}
                      <div className="flex-grow-1 bgspacelisting d-flex radius_3 title-fonts color-white-80 ml-10 left-toggle-none">
                        <div className="d-flex w-85 align-items-center m-auto" onClick={() => {
                          navigate("/MyEmail");
                        }}>
                          <div className="">{_l("l_email")}</div>
                        </div>
                      </div>
                    </React.Fragment>
                  }
              </div>
              
              <div className={`position-relative left-option-box mt-0 d-flex d-none ${contact_role != 3 && userType == "operator"  && ((visibleOptionIcons.includes("proposals") || activeMenuOptions.includes("proposals")) || proposalUnreadComments > 0) || localStorage.getItem("is_registered") == 1 ? "" : "d-none"} ${hideMenuButton("proposal") ? "for-disabled" : ""}`}>
                {
                   hideMenuButton("proposal")
                   ?
                     <React.Fragment>
                        <div className="bg-white-05 h50w50 comman-round-box radius_3 d-flex border-transparent"></div>
                        <div className="flex-grow-1 bg-white-05 d-flex radius_3 ml-10 left-toggle-none"></div>
                     </React.Fragment>
                   :
                    <React.Fragment> 
                      <a href="#/"
                        className={`bg-white-05 h50w50 comman-round-box radius_3 d-flex border-transparent left-panel-email flex-column justify-content-center align-items-cente on-hover-active-toggle-img ${pageName == "Proposals" || window.location.href.includes("proposals") ? "active" : ""}`}
                        data-tip={`${_l("l_proposals")}`}
                        data-for={randomID}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                        data-place="right"
                        onClick={() => {
                          if (is_registered == 1) {
                            navigate("/onboarding-proposal")
                            dispatch(leftPanelFilters("","","all"));
                            hideContactDirectory();
                            dispatch(setOpenOrCloseContactDirectoryChat(false));


                          }else{
                            navigate("/proposals", { state: { from: viewPage } });
                          }
                        }}
                      >
                        {
                          proposalUnreadComments > 0
                          ?
                          <span id="proposal-badge-count" className="rounded-pill badge-custom  d-flex p-2 light-pill">{proposalUnreadComments}</span>
                          :
                          <React.Fragment></React.Fragment>
                        }
                        <div className="comman-bg-img w-100 d-flex on-hover-active-toggle-img position-relative">
                          <Rows size={16} weight="light" className="c-icons" />
                        </div>
                        <div class="c-font color-white-80 d-block f-10 fw-light hover-span line-height-1 pt-2 px-1 text-truncate text-center">{_l("l_proposals")}</div>
                      </a>
                      <div className="flex-grow-1 bgspacelisting d-flex radius_3 title-fonts color-white-80 ml-10 left-toggle-none">
                        <div className="d-flex w-85 align-items-center m-auto" onClick={() => {
                          if (is_registered == 1) {
                            navigate("/onboarding-proposal")
                            dispatch(leftPanelFilters("","","all"));
                            hideContactDirectory();
                            dispatch(setOpenOrCloseContactDirectoryChat(false));

                          }else{
                            navigate("/proposals", { state: { from: viewPage } });
                          }
                        }}>
                          <div className="">{_l("l_proposals")}</div>
                        </div>
                      </div>
                    </React.Fragment>
                }
              </div>
              <div
                className={`position-relative left-option-box mt-0 d-flex d-none ${ parseInt(contact_role) != 1 ? "d-none" : ""} ${hideMenuButton("wallet") || pageName == "LiteVersion" ? "for-disabled" : ""}`}
              >
                {
                   hideMenuButton("wallet")
                   ?
                     <React.Fragment>
                        <div className="bg-white-05 h50w50 comman-round-box radius_3 d-flex border-transparent"></div>
                        <div className="flex-grow-1 bg-white-05 d-flex radius_3 ml-10 left-toggle-none"></div>
                     </React.Fragment>
                   :
                    <React.Fragment>
                      <a href="#/"
                        id={"wallet_configuration_form"}
                        className={`bg-white-05 h50w50 comman-round-box radius_3 d-flex border-transparent left-panel-wallet flex-column justify-content-center align-items-center on-hover-active-toggle-img ${pageName == 'invoice'? "active" : ""} ${WalletActiveClass ? "" : "active"
                          } ${userType != "staff" ? "" : "for-disabled"}`}
                        data-tip={`${_l("l_my_wallets")}`}
                        data-for={randomID}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                        onClick={() => {
                          if (userType == "staff") {
                            handleStaffWalletConfiguration();
                            return;
                          }
                          if (getIbanModlaOpen) {
                            if (userType == "contact") {
                              setIbanShow(true);
                            } else {
                              setShow(true);
                              onPagechangeToggle();
                            }
                          } else {
                            navigate("/mywallet");
                            return;
                          }
                        }}
                      >
                        <div className="comman-bg-img w-100 d-flex on-hover-active-toggle-img">
                          <div className={`d-flex align-items-center ${getIbanModlaOpen == true ? "" : "d-none"}`}>
                            <span className="bg-base-header-color rounded-pill badge-custom d-flex p-2 light-pill">
                              <LockIcon className="HW12 position-absolute" />
                            </span>
                          </div>
                          <Wallet size={16} weight="light" className="c-icons" />
                        </div>
                        <div className="c-font color-white-80 d-block f-10 fw-light hover-span line-height-1 pt-2 px-1 text-truncate text-center w-100">{_l("l_wallet")}</div>
                      </a>
                      <a href="#/"
                        className={`flex-grow-1 bgspacelisting d-flex radius_3 title-fonts color-white-80 ml-10 left-toggle-none ${userType != "staff" ? "" : "for-disabled"
                          } `}
                        onClick={() => {
                          if (userType == "staff") {
                            return 1;
                          }
                          if (getIbanModlaOpen == true) {
                            if (userType == "contact") {
                              setIbanShow(true);
                            } else {
                              setShow(true);
                              onPagechangeToggle();
                            }
                          } else {
                            navigate("/mywallet");
                            return;
                          }
                        }}
                      >
                        <div className="d-flex w-85 justify-content-center align-items-center m-auto">
                          <div className="">{_l("l_my_wallets")} </div>
                          <div className="ms-auto">
                          </div>
                        </div>
                      </a>
                      {show ? (
                        <WalletSetting
                          show={show}
                          handleClose={handleClose}
                          handleShow={handleShow}
                        />
                      ) : (
                        <></>
                      )}
                      {ibanShow ? (
                        <SpaceIBANConfiguration
                          show={ibanShow}
                          handleClose={() => {
                            setIbanShow(false);
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </React.Fragment>
                }
              </div>
              {/* { localStorage.getItem("user_type") != 'staff' ?  */}
              
              <div className={`position-relative left-option-box mt-0 d-flex d-none ${ contact_role == 3 || (!visibleOptionIcons.includes("company-profile") && !activeMenuOptions.includes("company-profile")) ? "d-none" : ""} ${hideMenuButton("company-profile") ? "for-disabled" : ""}`}>
                {
                   hideMenuButton("company-profile")
                   ?
                    <React.Fragment>
                      <div className="bg-white-05 h50w50 comman-round-box radius_3 d-flex border-transparent"></div>
                      <div className="flex-grow-1 bg-white-05 d-flex radius_3 ml-10 left-toggle-none"></div>
                    </React.Fragment>
                   :
                    <React.Fragment>
                      <a href="#/"
                        className={`bg-white-05 h50w50 comman-round-box radius_3 d-flex border-transparent left-panel-company-setting flex-column justify-content-center align-items-center on-hover-active-toggle-img  ${pageName == 'leadPage' ? 'active' : ''} ${CompanyProfileActiveClass ? "" : "active"
                          }`}
                        data-tip={`${_l("l_setting")}`}
                        data-for={randomID}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-place="right"
                        data-class="tooltip-main"
                        onClick={() => {
                          if(disabledThirdPartyToolAccess)
                          {
                            return false;
                          }
                          updateActiveMenuOptions("company-profile");
                          if (userType == "staff") {
                            setSettingMenuRender(!SettingMenuRender)
                          }else{
                            accessPremiumFeature(0, () => {
                              // navigate("/companyprofile");
                              setSettingTopicName("l_company_profile");
                              setTopicMainKey("company-profile");
                            }, true);
                          }
                        }}
                      >
                        <div className="comman-bg-img w-100 d-flex on-hover-active-toggle-img">
                        {userType == "staff" ?   
                          <GearSix size={16} weight="light" className={`c-icons ${SettingMenuRender ? "color-green" : ""}`} />
                          :
                          <Buildings size={16} weight="light" className={`c-icons`} />
                        }
                        </div>
                        <div className={`c-font color-white-80 d-block f-10 fw-light hover-span line-height-1 pt-2 px-1 text-truncate text-center w-100 ${SettingMenuRender ? "color-green" : ""} `}>{_l("l_setting")}</div>
                      </a>
                      <div className="flex-grow-1 bgspacelisting d-flex radius_3 title-fonts color-white-80 ml-10 left-toggle-none">
                        <div className="d-flex w-85  align-items-center m-auto">
                          <div className="">{_l("l_setting")}</div>
                        </div>
                      </div>
                    </React.Fragment>
                }
              </div>

              {contact_role !== 3 ? 
              <div className={`position-relative left-option-box mt-0 d-flex`}>
                    <React.Fragment>
                      <a href="#/"
                        className={`bg-white-05 h50w50 comman-round-box radius_3 d-flex border-transparent left-panel-company-setting flex-column justify-content-center align-items-center on-hover-active-toggle-img  ${["taskListView", "calendarView"].includes(dashBoradView) && pageName == "dashboardnew" ? 'active' : ''}`}
                        data-tip={`${_l("l_my_task")}`}
                        data-for={randomID}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-place="right"
                        data-class="tooltip-main"
                        onClick={() => {
                          setSelectedSpace({})
                          dispatch(setFetchedTaskandMessages([]))
                          dispatch(commanoffcanvasisOpen(false)) 
                          dispatch(SetSelectedTask({}));
                          TaskModalClose();
                          dispatch(getGroupDetails({})); 
                        if ((["operator","contact"].includes(userType) && contact_role != 3) || userType == "staff") {
                          if (!contactlistShow) {
                          dispatch(leftPanelFilters("","","topicFilterClear"))
                          }
                          navigate("/dashboard");
                          dispatch(setDashBoardView("taskListView"))
                          localStorage.removeItem("selectedOffice")
                          localStorage.removeItem("selectedVirtualSpace")
                          dispatch(setBulkTaskRating([]))
                          setSelectedProject(0)
                        }else{
                          selectLiteVersion()
                        }
                        if (contactlistShow) {
                          setContactlistShow(false)
                          localStorage.removeItem("isContactDir")
                        }
                        }}
                      >
                        <div className="comman-bg-img w-100 d-flex on-hover-active-toggle-img">  
                          <ListBullets size={16} weight="light" className={`c-icons ${["taskListView", "calendarView"].includes(dashBoradView) && pageName == "dashboardnew" ? "color-green" : ""}`} />
                        </div>
                        <div class={`c-font color-white-80 d-block f-10 fw-light hover-span line-height-1 pt-2 px-1 text-truncate text-center w-100 ${["taskListView", "calendarView"].includes(dashBoradView) && pageName == "dashboardnew" ? "color-green" : ""} `}>{_l("l_my_task")}</div>
                      </a>
                      <div className="flex-grow-1 bgspacelisting d-flex radius_3 title-fonts color-white-80 ml-10 left-toggle-none">
                        <div className="d-flex w-85  align-items-center m-auto">
                          <div className="">{_l("l_my_task")}</div>
                        </div>
                      </div>
                    </React.Fragment>
              </div>
              :<></>}
              
              {/* JOB proposal start */}
              <div className={`position-relative left-option-box mt-0 d-flex ${contact_role != 3 && userType == "operator"  && is_registered == 1 ? '' : 'd-none'}`}> 
                {
                    <React.Fragment>
                      <a href="#/"
                        className={`bg-white-05 h50w50 comman-round-box radius_3 d-flex border-transparent left-panel-email flex-column justify-content-center align-items-cente on-hover-active-toggle-img ${isJobProposalActive ? "active" : ""}`}
                        data-tip={`${_l("l_agent_proposals")}`}
                        data-for={randomID}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                        data-place="right"
                        onClick={() => {
                           navigate(localStorage.getItem("is_agent") == 1 ? "/onboarding-proposal" : "/onboarding-proposal?view=job-prposal");
                           dispatch(selectJobProposalPage(true));
                           dispatch(leftPanelFilters("","","all"));
                           hideContactDirectory();
                            dispatch(setOpenOrCloseContactDirectoryChat(false));

                        }}
                      >
                        <div className="comman-bg-img w-100 d-flex on-hover-active-toggle-img position-relative">
                          <Rows size={16} weight="light" className="c-icons" />
                        </div>
                        <div className="c-font color-white-80 d-block f-10 fw-light hover-span line-height-1 pt-2 px-1 text-truncate text-center">{_l("l_agent_proposals")}</div>
                      </a>
                      <div className="flex-grow-1 bg-white-05 d-flex radius_3 title-fonts color-white-80 ml-10 left-toggle-none">
                        <div className="d-flex w-85 align-items-center m-auto" onClick={() => {
                          navigate(localStorage.getItem("is_agent") == 1 ? "/onboarding-proposal" : "/onboarding-proposal?view=job-prposal")
                          dispatch(leftPanelFilters("","","all"));
                          hideContactDirectory();
                          dispatch(setOpenOrCloseContactDirectoryChat(false));

                        }}>
                          <div className="">{_l("l_agent_proposals")}</div>
                        </div>
                      </div>
                    </React.Fragment>
                }
              </div>
              {/* JOB proposal end */}

              {/* provider list section start  */}
              { userType == "contact" && contact_role != 3 ? <div className={`position-relative left-option-box mt-0 d-flex`}>
                <a className={`bg-white-05 h50w50 comman-round-box radius_3 d-flex border-transparent left-panel-email flex-column justify-content-center align-items-center on-hover-active-toggle-img ${ window.location.pathname === "/providerlist" || pageName == "ProviderList" ? "active" : ""}`}
                  data-tip={`${_l("l_providers")}`}
                  data-for={randomID}
                  data-effect="solid"
                  data-delay-show='1000'
                  data-class="tooltip-main"
                  data-place="right"
                  onClick={() => {
                    dispatch(setOpenOrCloseContactDirectoryChat(false));
                    setContactlistShow(false);
                    dispatch(setSelectedGloablView("listView"));
                    navigate("/providerlist");
                  }}
                >
                  <div className="comman-bg-img w-100 d-flex on-hover-active-toggle-img">            
                    <UserPlus size={16} weight="light" className="c-icons" />
                  </div>
                  <div className="c-font color-white-80 d-block f-10 fw-light hover-span line-height-1 pt-2 px-1 text-truncate text-center w-100">{_l("l_providers")} </div>  
                </a>
                <div 
                  className="flex-grow-1 bg-white-05 d-flex radius_3 title-fonts color-white-80 ml-10 left-toggle-none cursor-pointer" 
                  onClick={() => { navigate("/providerlist"); }}
                >
                  <div className="d-flex w-85  align-items-center m-auto">
                    <div className="">{_l("l_providers")}</div>
                  </div>
                </div>
              </div> : <></>}
              {/* provider list section end  */}

              {/* Contact directory start */}
              <div className={`position-relative left-option-box mt-0 d-flex`}>
                <a className={`bg-white-05 h50w50 comman-round-box radius_3 d-flex border-transparent left-panel-email flex-column justify-content-center align-items-center on-hover-active-toggle-img ${localStorage.getItem("is_registered") == 1 || contactlistShow ? "active" : ""}`}
                  data-tip={`${_l("l_contacts")}`}
                  data-for={randomID}
                  data-effect="solid"
                  data-delay-show='1000'
                  data-class="tooltip-main"
                  data-place="right"
                  onClick={() => {
                    if (is_registered) {
                      dispatch(fetchGroupsExternal(chatUserId, 0 , 1));
                    }
                    dispatch(selectJobProposalPage(false));
                    if (!contactlistShow || isContactDirChat || !isContactDirChat ) {
                      contactDirTopicChange({}, false) 
                    }
                  }}
                >
                  <div className="comman-bg-img w-100 d-flex on-hover-active-toggle-img">            
                    <AddressBook size={16} weight="light" className="c-icons" />
                  </div>
                  <div class="c-font color-white-80 d-block f-10 fw-light hover-span line-height-1 pt-2 px-1 text-truncate text-center w-100">{_l("l_contacts")} </div>  
                  {contactDirMsgCount > 0 ? <span className={`rounded-pill badge-custom d-flex`}>{contactDirMsgCount}</span> : <></>}
                </a>
                <div 
                  className="flex-grow-1 bgspacelisting d-flex radius_3 title-fonts color-white-80 ml-10 left-toggle-none cursor-pointer" 
                  onClick={() => {
                    if (!contactlistShow) {
                      contactDirTopicChange({}, false)
                    }
                  }}
                >
                  <div className="d-flex w-85  align-items-center m-auto">
                    <div className="">{_l("l_contacts")}</div>
                  </div>
                </div>
              </div>

              {/* Contact directory end */}

              {/* Dashboard start */}

              <div className={`position-relative left-option-box mt-0 d-flex d-none`}>
                <a className={`bg-white-05 h50w50 comman-round-box radius_3 d-flex border-transparent left-panel-email flex-column justify-content-center align-items-center on-hover-active-toggle-img ${pageName =='dashboard' || pageName == "dashboardnew" ? 'active' :''} ${localStorage.getItem("is_registered") == 1 || contactlistShow ? "active" : ""}`}
                  data-tip={`${_l("l_reporting")}`}
                  data-for={randomID}
                  data-effect="solid"
                  data-delay-show='1000'
                  data-class="tooltip-main"
                  data-place="right"
                  onClick={() => {
                    dispatch(setFetchedTaskandMessages([]))
                      dispatch(commanoffcanvasisOpen(false)) 
                      dispatch(SetSelectedTask({}));
                      TaskModalClose();
                      dispatch(getGroupDetails({})); 
                    if ((["operator","contact"].includes(userType) && contact_role != 3) || userType == "staff") {
                      if (!contactlistShow) {
                      dispatch(leftPanelFilters("","","topicFilterClear"))
                      }
                      navigate("/dashboard");
                      localStorage.removeItem("selectedOffice")
                      localStorage.removeItem("selectedVirtualSpace")
                      dispatch(setBulkTaskRating([]))
                      setSelectedProject(0)
                    }else{
                      selectLiteVersion()
                    }
                    if (contactlistShow) {
                      setContactlistShow(false)
                      localStorage.removeItem("isContactDir")
                    }
                  }}
                >
                  <div className="comman-bg-img w-100 d-flex on-hover-active-toggle-img">            
                    <Gauge size={16} weight="light" className="c-icons" />
                  </div>
                  <div class="c-font color-white-80 d-block f-10 fw-light hover-span line-height-1 pt-2 px-1 text-truncate text-center w-100">{_l("l_reporting")} </div>  
                </a>
              </div>

              {/* Dashboard over */}


              {/* My profile start */}

              <div className={`position-relative left-option-box mt-0 d-flex new_user_profile_bottom`} onClick={() => setshowMyProfile(!showMyProfile)}>
                <a className={`h50w50 d-flex  align-items-center bg-white-05 border-transparent justify-content-center radius_3 flex-column hovertexgreen ${localStorage.getItem("is_registered") == 1 ? "active" : ""} 
                  `}
                  data-tip={`${_l("l_my_profile")}`}
                  data-for={randomID}
                  data-effect="solid"
                  data-delay-show='1000'
                  data-class="tooltip-main"
                  data-place="right"
                >
                  
                  <div className={`h18w18 comman-round-box with-bg d-flex align-items-center bgOfficelisting rounded-circle border-transparent position-relative ${
                    userType == "staff" 
                      ? "staff_user"
                      : userType == "operator"
                      ? "operator_user"
                      : "contact_user"
                  }`}>
                    <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle d-flex on-hover-active-toggle-img" style={{ backgroundImage: `url(${localStorage.getItem("contact_image")})` }}></div>
                  </div>
                  
                  <div class="c-font color-white-80 d-block f-10 fw-light hover-span line-height-1 pt-2 px-1 text-truncate text-center w-100 ">{_l("l_profile")} </div> 
                </a>
                <div className="flex-grow-1 bgspacelisting d-flex radius_3 title-fonts color-white-80 ml-10 left-toggle-none">
                  <div className="d-flex w-85  align-items-center m-auto">
                    <div>{_l("l_profile")}</div>
                  </div>
                </div>
              </div>

              {/* My profile over */}


               {/* Nine dots for setting and toggle */}
               <div className={`position-relative left-option-box mt-0 d-flex d-none`}>
                  {hideMenuButton("more-tools")
                  ?
                    <React.Fragment>
                       <div className="bg-white-05 h50w50 comman-round-box radius_3 d-flex border-transparent"></div>
                       <div className="flex-grow-1 bg-white-05 d-flex radius_3 ml-10 left-toggle-none"></div>
                    </React.Fragment>
                  :
                    <React.Fragment>
                      <a href="#/"
                        className={`bg-white-05 h50w50 comman-round-box radius_3 d-flex border-transparent left-panel-email flex-column justify-content-center align-items-center on-hover-active-toggle-img
                          }`}
                        data-tip={`${_l("l_more")}`}
                        data-for={randomID}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                        data-place="right"
                        onMouseOver={showMenuOnHover}
                        onClick={() => handleVisibleMenus()}
                        ref={menuButtonRef}
                      >
                        <div className="comman-bg-img w-100 d-flex on-hover-active-toggle-img">            
                            <DotsNine size={16} weight="light" className="c-icons" />
                        </div>
                        <div className="c-font color-white-80 d-block f-10 fw-light hover-span line-height-1 pt-2 px-1 text-truncate text-center w-100">{_l("l_more")} </div>
                      </a>
                      <div className="flex-grow-1 bgspacelisting d-flex radius_3 title-fonts color-white-80 ml-10 left-toggle-none">
                        <div className="d-flex w-85 justify-content-center align-items-center m-auto">
                          <div className="">{_l("l_more")}</div>
                          <div className="ms-auto">
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  }
              </div>

              {/* :<></>
              } */}
            </div>
          </div>
        </div>


        {/* Contact directore list start */}
      {/* { contactlistShow ? 
        <div className="fixed-left-panel provider-list z-index-22" >
          <div className="fixed-left-panel-wrapper h-100 d-flex flex-column">
            <div className="upper-part ">
              <div className="company-logo-box position-relative d-flex flex-column justify-content-center">
                <div className="z-index-15">
                  <Form.Group className=" c-input-box w-100 align-items-center d-flex">
                    <MagnifyingGlass size={18}weight="light"className="c-icons m-0"/>
                    <Form.Control type="text" placeholder={_l("l_search")} className="bg-transparent bg-white-03 border-0  flex-grow-1 ps-2" onChange={(e)=>{setSearhContact(e.target.value)}} 
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
                <div className="office-list-main with-padding shortcut-wrapper mt-0 p-0">
                  <div className="mt-0 childTopicTaskFilter accordion-body">
                    <div className="py-1 pt-0 childTopicTaskFilter accordian-body pb-1">
                        { groupListExternal && groupListExternal.length > 0 ?  
                          contactList(groupListExternal, searhContact)
                          .sort((a, b) => {
                           if (a.unreadCount - b.unreadCount !== 0 )  {
                            return b.unreadCount - a.unreadCount;
                          }
                          // If notifications are the same, compare by time in ascending order
                          const timeA = moment(a.lastMessageDate && a.lastMessageDate ? a.lastMessageDate : '');
                          const timeB = moment(b.lastMessageDate && b.lastMessageDate ? b.lastMessageDate : '');
                          return timeB - timeA;
                          }).map((group, index) => {
                            return (
                              <a 
                                className={`position-relative task-sub-list w-100 py-2 ps-10px pe-10px border-bottom ${group.groupId == groupDetails._id ? "active" : ""}`}
                                onClick={() => {
                                  contactDirTopicChange(group, false)
                                }}
                              >
                                <div className="d-flex align-items-center w-100">
                                  <a href="javascript:;" className="accordian-left-title w-100 on-hover-active-toggle-img text-on-hover-active-green d-flex position-relative overflow-visible hovertextgreen">
                                    <div className="d-flex align-items-center w100minus100per comman_action_icon">
                                      <div className="comman_action_icon list-icon h40w40">
                                        <div className="comman-bg-img w-100 h-10 bg-style-cover rounded-circle h40w40"
                                              style={{ 
                                                backgroundImage: `url(${group.displayPicture != "" ? group.displayPicture : 'https://myrpp.myr.ai/orb/assets/images/default-image-placeholder.svg'})`,
                                              }}
                                          ></div>
                                      </div>
                                      <div className="ps-2 w-100">
                                        <div class="d-flex justify-content-between w-100 align-items-center min-h-32px">
                                          <div class="title-font text-truncate color-white-60 left_panel_toggle categorytitle w100minus100per   c-font f-16">{group.groupName}</div>
                                          { group.unreadCount && group.unreadCount > 0 ?
                                            <div class="with-bg h18w18 justify-content-center position-static my-auto  badge-green rounded-circle d-flex right-accordian-header-pill " >
                                              <span class="m-auto ps-0 ">{group.unreadCount}</span>
                                            </div>
                                            : <></>
                                          }
                                        </div>
                                      { group.lastMessage && group.lastMessage != "" 
                                        ? <div class="d-flex justify-content-between w-100 align-items-center color-white-60">
                                            <div class="c-font f-10 text-truncate pe-2 w100minus100per">
                                              <b style={{fontWeight:"600"}}>{`${ReactHtmlParser(group.lastMsgUserName.split(" ")[0])} :`}</b> {ReactHtmlParser(group.lastMessage)}
                                            </div>
                                              <div class="c-font p-1 f-10 badge text-truncate badge-white m-w100px rounded-pill fw-light ms-1 ">
                                                  <span class="text-truncate ps-0">
                                                  { moment(group.lastMessageDate).format("L") == moment(new Date()).format("L") 
                                                    ? moment(group.lastMessageDate).format("hh:mm A")
                                                    : moment(group.lastMessageDate).format("DD/MM/YYYY")
                                                  }
                                                  </span>
                                              </div>
                                          </div>
                                        : <></>
                                      }
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </a>
                            )})
                          : <></>
                        } 
                    </div>
                  </div>
                </div>
          </div>
        </div>
        : <></>
      } */}
        {/* Contact directore list end */}

        <div 
          className={`fixed-left-panel provider-list d-none ${showBackDropClass ? "search-backdrop" : ""} ${isBackdrop ? "comman-backdrop-search" : ""} ${preEstiPageDisable == true ? "for-disabled" : ""
            }`}
        >
          {/* <div className="modal-overlay "></div> */}
          <div className="fixed-left-panel-wrapper h-100 d-flex flex-column">
          {
            !isAllChatUserlistShow ?
            <div className="upper-part ">
              {LeftPanelEmailPage ? (
                <div className="company-logo-box position-relative border-bottom">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-between w100minus100 flex-grow-1 pe-3">
                      <div className="comman-list-wrapper-main ps-2 flex-grow-1 left-toggle-none w100minus120 d-flex align-items-center">
                        <div className="leftpanelnewbg h40w40 comman-round-box d-flex rounded-circle">


                          <EnvelopeSimple size={16} weight="light" className="c-icons" />
                        </div>
                        <div className="list-left-part text-truncate ms-2 w100minus50">
                          <div className="title-fonts fw-semibold text-truncate">
                            {_l("l_my_email")}
                          </div>
                        </div>
                        <div className="list-left-part">
                          <div className="title-fonts fw-semibold text-truncate">

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-right-part comman_action_icon">
                      {hideMailSyncBtn ? (
                        <></>
                      ) : (
                        /*Update  code start */
                        <a href="#/"
                          data-tip={synchronizedMail ? _l("l_revoke_your_mails") + " " + _l(synchronizedMail) : _l("l_symc_your_mails")}
                          data-for={randomID}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                          className={`action_icon with_bg h32w32 d-flex align-items-center ${synchronizedMail ? " " : " "}`}
                          onClick={() => {
                            synchronizedMail
                              ? revokeMailSync()
                              : setShowMailModal(true);
                          }}
                        >
                          {synchronizedMail
                            ? (<> <EmailRevokeIcon className="HW20" /> </>)
                            : (<EmailSyncIcon className="HW20" />)}

                        </a>
                        /*Update  code end */

                       
                      )}
                    </div>
                    
                    <div className={`list-right-part `}>
                      <div className="comman_action_icon dropstart">
                        <a href="#/"
                          className="action_icon h25w25 on-hover-active-toggle-img left-toggle-none"
                          id="ActiveOfficeOptionsDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-tip={_l("l_options")}
                          data-for={randomID}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                        >
                          <DotsThreeVertical size={16} weight="light" className="c-icons" />
                        </a>
                        <ActionDropdown widthClass="w-170"
                          actions={[
                            {
                              actionName: _l("l_add_custom"),
                              actionHandler: () => {
                                bulkAddCustomTopic();
                              },
                              actionIcon: "GearSixIcon",
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                <div className="company-logo-box position-relative d-flex flex-column justify-content-center on-hover-active-toggle-img">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-between w100minus100 flex-grow-1 pe-1">
                      <div className="comman-list-wrapper-main flex-grow-1 left-toggle-none w100minus120">
                        {showSearch ? (
                          <div className="comment-input-box bg-base-header-color pb-0 absolute-position change-position radius_3">
                            <input
                              className="form-control bg-base-header-color border-0 radius_3 text-truncate pe-4"
                              placeholder="Search..."
                              value={searchCategory}
                              onChange={(e) => {
                                if (preEstiPageDisable == true) {
                                  return;
                                }
                                // setSearchTask(e.target.value);
                                setSearchCategory(e.target.value);
                              }}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        {selectedTeam || teamLeftPanel ? (
                          <div className="d-flex align-items-center">
                              {/* <a href="#/"
                                className={`pe-2 ${teamLeftPanel ? "d-none" : ""}`}
                                onClick={() => {
                                  setTeamLeftPanel(true);
                                }}
                              >
                                <CaretLeftIcon />{" "}
                              </a> */}
                              <div className="w-100">
                                <div className="fw-semibold c-font f-12 title-fonts text-truncate hover-span">
                                  {selectedTeam ? selectedTeam.name : _l("l_teams")}
                                </div>
                                <div className="d-flex align-items-center color-white-60 hover-span">
                                    <div className="c-font f-10 text-truncate w100minus85 pe-2">{selectedSpace && selectedSpace.postal_address}</div>
                                </div>
                                <a href="#/"
                                  className={`left-toggle-none c-font f-13 d-flex flex-grow-1 d-none`}
                                >
                                  <div className="progress-slider flex-grow-1 pt-2 pb-2">
                                    <div className="d-flex align-items-center">
                                      <div className="progress">
                                        <div
                                          className="progress-bar"
                                          style={{
                                            width: `${taskCounts && taskCounts.workspace_score ? taskCounts.workspace_score : 0}%`,
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between ms-2">
                                    {/* <div className="title-fonts">Workspace Score</div> */}
                                    <div>
                                      {taskCounts && taskCounts.workspace_score ? taskCounts.workspace_score : 0}
                                      %
                                    </div>
                                  </div>
                                </a>
                            </div>
                          </div>
                        ) : (
                          <>
                            {['bottom'].map((placement) => (
                              <OverlayTrigger
                                trigger={['hover', 'focus']}
                                delay={{ show: 1000 }}
                                key={placement}
                                placement={placement}
                                overlay={
                                <Popover id={`popover-positioned-${placement}`} className={`w-400 ${userType == "contact" ? "" : "d-none"}`}>
                                  <Popover.Body className="p-0">
                                    <ul>
                                      {
                                        taskCounts && 
                                        taskCounts.credit_notes &&
                                        taskCounts.credit_notes.map((note,index) =>{
                                          return(
                                            <li
                                              key={index}
                                              className=" popover-detail-wrapper padding_10"
                                            >
                                              <div className="d-flex align-items-center">
                                                <div className="w100minus100per flex-grow-1">
                                                  <div className="d-flex align-items-center">
                                                    <div className="c-list-icon">
                                                      <div className="h30w30 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05">
                                                        <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{ backgroundImage: `url('${User_2}')` }}>
    
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="ps-2 w100minus40 ">
                                                      <div className="c-list-detail title-fonts fw-semibold text-truncate color-white">
                                                        {note.title}
                                                      </div>
                                                      <div className="c-font f-12 color-white-60 text-truncate body-fonts">
                                                        {taskFilters && taskFilters.length > 0 && taskFilters[0].title}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="badge title-fonts c-font f-13 c-text normal_pill radius_3  fw-semibold">
                                                  <span className="text-warning">
                                                  €{note.total_amount}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center pt-2">
                                                <div className="w100minus100per flex-grow-1">
                                                  <div className="progress-slider pe-3">
                                                    <div className="d-flex align-items-center">
                                                      <ProgressBar now={note.percentages} />
                                                      <div className="ms-2 progress-text c-font f-12 title-fonts w-auto">
                                                        {note.completed_task + "/" + note.total_task}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="badge c-font c-text color-white f-12 fw-light normal_pill radius_3 title-fonts rounded-pill">
                                                  <span className="color-white">
                                                    {_l('l_potential_saving')}
                                                  </span>
                                                </div>
                                              </div>
                                            </li>
                                          )
                                        })
                                      }
                                    </ul>
                                  </Popover.Body>
                                </Popover>
                                }
                              >
                                <div className="list-left-part text-truncate">
                                  {
                                    pageName == "Proposals" ?
                                      <div className="title-fonts fw-semibold text-truncate">
                                        {_l("l_proposals")}
                                      </div>
                                      : contactlistShow
                                      ? <div className="title-fonts fw-semibold text-truncate">
                                          {_l("l_contacts")} 
                                        </div> 
                                      : pageName == "invoice" || pageName == "estimate" || pageName == "subscription" || pageName == "_equipments" || pageName == "staff" || pageName == "client" || pageName == "AdminTaskPage" || pageName == "credit_notes" || pageName == "credit_notes" || pageName == "fullcalendar" || pageName == "benchmark" || pageName == "schedule"?
                                      pageName == "estimate" ? "Estimate" : pageName == "benchmark" ? "Benchmark" : pageName == "schedule" ? "Schedule" : pageName == "subscription"? "Subscription" : pageName == "staff"? "Staff" : pageName == "client"? "Clients" : pageName == "fullcalendar" ? "Full Calendar" : pageName == "AdminTaskPage" ? "Tasks" : pageName == "credit_notes" ? "Credit Notes" : pageName == "_equipments" ? "Equipments" : "Invoice" :
                                      <>
                                        <a href="#/" className="title-fonts fw-semibold text-truncate">
                                          {(pageName == 'dashboard' || pageName == "dashboardnew") 
                                            ? ["taskListView", "calendarView"].includes(dashBoradView) && pageName == "dashboardnew" 
                                              ? _l("l_my_task") :  _l("l_reporting") 
                                                : isLeadPage
                                                ? _l("l_leads")
                                                  : selectedSpace.office_title
                                                    ? selectedSpace.office_title
                                                    : selectedSpace.postal_address}
                                        </a>
                                       
                                       {pageName == "dashboardnew" ? <></> :
                                        <div className="d-flex align-items-center color-white-60">
                                          <div className="c-font f-10 text-truncate  pe-2">{selectedSpace && selectedSpace.postal_address}</div>
                                        <a href="#/"
                                          className={`left-toggle-none c-font f-13 d-flex w-50 d-none`}
                                        >
                                          <div className="progress-slider flex-grow-1 pt-2 pb-2">
                                            <div className="d-flex align-items-center">
                                              <div className="progress">
                                                <div
                                                  className="progress-bar"
                                                  style={{
                                                    width: `${pageName == 'dashboard' ? projectTaskCount && projectTaskCount.workspace_score : isLeadPage
                                                      ? 0
                                                      : projectTaskCount &&
                                                      projectTaskCount.workspace_score
                                                      }%`,
                                                  }}
                                                ></div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="d-flex align-items-center justify-content-between ms-2">
                                            {/* <div className="title-fonts">Workspace Score</div> */}
                                            <div>
                                              {pageName == 'dashboard' ? projectTaskCount && projectTaskCount.workspace_score : isLeadPage
                                                ? 0
                                                : projectTaskCount &&
                                                projectTaskCount.workspace_score}
                                              %
                                            </div>
                                          </div>
                                        </a>
                                        </div>}
                                      </>
                                  }
                                </div>
                              </OverlayTrigger>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                    <div className={`list-right-part on-hover-active-toggle-img left-toggle-none d-none ${showSearch ? "m-0" : ""} `}>
                      <div className="d-flex align-items-center justify-content-end">
                        {showSearch ? (
                          <a href="#/"
                            className="comman_action_icon z-index-2 pe-1"
                            onClick={() => {
                              setshowSearch(false);
                            }}
                          >
                            <div
                              className="h25w25 d-flex"
                              onClick={() => {
                                dispatch(setTaskSearchString(""));
                                setSearchTask("");
                                setSearchCategory("");
                              }}
                            >
                              <X size={16} weight="light" className="c-icons" />
                            </div>
                          </a>
                        ) : (
                          <a href="#/"
                            className="comman_action_icon"
                            onClick={() => {
                              setshowSearch(true);
                            }}
                          >
                            <div className="`comman_action_icon action_icon h25w25 left-toggle-none">
                              <MagnifyingGlass size={16} weight="light" className="c-icons" />
                            </div>
                          </a>
                        )}
                      </div>
                    </div>
                    { 
                      pageName =='dashboard' ?
                      <></>
                      : !contactlistShow 
                  ? <div className="list-right-part">
                      <div className="d-flex align-items-center comman_action_icon">
                        {/* Credit note bal start */}
                        {/* <div class="badge  c-text normal_pill  px-2 radius_3 text-truncate green-text fw-semibold d-flex flex-column align-items-center ">
                          <div className="c-font f-12 color-white-60">{_l("l_credit_note_bal")}Credit note bal</div>
                          <div className="c-font f-14 fw-semibold">€20.00</div>
                        </div> */}
                        {/* Credit note bal over */}
                      
                       {  !["Proposals","invoice","estimate","credit_notes","subscription","staff","_equipments","client","AdminTaskPage","benchmark","schedule","dashboardnew"].includes(pageName) ?  
                       <a href="#/"
                          className={`comman_action_icon action_icon h25w25 left-toggle-none d-none ${teamLeftPanel || selectedTeam || isLeadPage
                            ? "d-none"
                            : ""
                            }`}
                          data-tip={_l("l_pin_project")}
                          data-for={randomID}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                          onClick={() => {
                            pinProject(selectedSpace.project_id);
                          }}
                        >
                          {Object.keys(selectedSpace).length > 0 && getSearchedOffice.filter(
                            (sp) => sp.id === selectedSpace.id
                          ).length &&
                            getSearchedOffice.filter(
                              (sp) => sp.id === selectedSpace.id
                            )[0] &&
                            getSearchedOffice.filter(
                              (sp) => sp.id === selectedSpace.id
                            )[0].pin_count === "1" ? (
                            <div className="m-auto">
                              <PushPin size={16}  weight="fill" className="c-icons green-light-grey Push-Pin-fill" />
                            </div>
                          ) : (
                            <div className="m-auto">
                              <PushPin size={16} weight="light" className="c-icons" />
                            </div>
                          )}
                        </a> : <></>}
                        <div
                          className={`comman_action_icon dropstart dropdown-static with_margin_minus_10px ${!teamLeftPanel && selectedTeam ? "" : ""
                            }`}
                        >
                      {!teamLeftPanel && selectedTeam ?
                        (admin == 'true' && selectedTeam.creator_details.staff_id == adminUser &&  !["Proposals","invoice","estimate","credit_notes","subscription","staff","_equipments","client","AdminTaskPage","benchmark","schedule","dashboardnew","leadPage"].includes(pageName)  ? <>
                          <a href="#/"
                            className={`action_icon h25w25 ${(teamLeftPanel && localStorage.getItem("admin") == "false") || contact_role == 3
                              ? "d-none"
                              : ""
                              }`}
                            id="ActiveOfficeOptionsDropdown"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            data-bs-auto-close="outside"
                            data-tip={_l("l_options")}
                            data-for={randomID}
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main"
                          >
                            <DotsThreeVertical size={16} weight="light" className="c-icons" />
                          </a></>: 
                        <></>) 
                      : <>
                         {!["Proposals","invoice","estimate","credit_notes","subscription","staff","_equipments","client","AdminTaskPage","benchmark","schedule","dashboardnew","leadPage"].includes(pageName) && <a href="#/"
                            className={`action_icon h25w25 ${(teamLeftPanel && localStorage.getItem("admin") == "false") || pageName == "leadPage" || pageName == "Proposals" || pageName == "invoice" || pageName == "estimate" || pageName == "subscription" || pageName == "staff" || pageName == "_equipments" || pageName == "client" || pageName == "AdminTaskPage" || pageName == "credit_notes" || pageName == "fullcalendar" || pageName == "benchmark" || pageName == "schedule" || contact_role == 3 || pageName == "dashboardnew" 
                              ? "d-none"
                              : ""
                              }`}
                            id="ActiveOfficeOptionsDropdown"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            data-bs-auto-close="outside"
                            data-tip={_l("l_options")}
                            data-for={randomID}
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main"
                            onClick={() => {setUserList([]);}}
                          >
                            <DotsThreeVertical size={16} weight="light" className="c-icons" />
                          </a>}
                        </>}
                          <ActionDropdown widthClass="w-170"
                            actions={
                              !teamLeftPanel && selectedTeam &&
                                    admin == 'true' && selectedTeam.creator_details.staff_id == adminUser ?
                                      [{
                                        actionName: _l("l_edit"),
                                        actionHandler: () => {
                                          handleEditAdminTeam(selectedTeam);
                                        },
                                        actionIcon: "PencilSimpleLineIcon",
                                      },
                                      {
                                        actionName: _l("l_delete"),
                                        actionHandler: () => {
                                          dispatch(
                                            showConfirmation(
                                              _l("l_are_you_sure"),
                                              "You want to delete space",
                                              _l("l_yes"),
                                              _l("l_no"),
                                              undefined,
                                              () => { handleDeleteTeam(selectedTeam, 1); }
                                            )
                                          );

                                        },
                                        actionIcon: "TrashIcon",
                                      }]
                            :
                              teamLeftPanel
                                ? [
                                  {
                                    actionName: _l("l_add"),
                                    actionHandler: () => {
                                      setCreateTeamModal(true);
                                    },
                                    actionIcon: "PlusIcon",
                                  },
                                ]
                                : localStorage.getItem("admin")
                                  ? [
                                   
                                    {
                                      actionName: _l("l_create")+ " " + _l('l_support'),
                                      actionHandler: () => {
                                        dispatch(
                                          showConfirmation(
                                            _l("l_are_you_sure"),
                                            _l("l_you_want_to_create_chat_channels"),
                                            _l("l_yes"),
                                            _l("l_no"),
                                            undefined,
                                            () => { createChatChannels(projectId); }
                                          )
                                        );
                                      },
                                      actionIcon: "ChatIcon",
                                    },
                                    {
                                      actionName: _l("l_login_as_user"),
                                      actionHandler: () => {
                                        setproject_id(selectedSpace.project_id);
                                        setshowProjectStatusList(false);                                        
                                        setUserList([]);
                                        let getSpaceDetails =
                                          getSearchedOffice.filter(
                                            (space) =>
                                              space.project_id == projectId
                                          );
                                        if (getSpaceDetails.length) {
                                          getUserList(
                                            getSpaceDetails[0]["client_id"],
                                            getSpaceDetails[0]["project_id"]
                                          );
                                          setClientIdLoginAsUser(
                                            getSpaceDetails[0]["client_id"]
                                          );
                                        }
                                      },
                                      actionIcon: "UserIcon",
                                    },
                                    {
                                      actionName: _l("l_pilot_onboarding"),
                                      actionHandler: () => {
                                        dispatch(getPilotOnboardingDocumentDetail(projectId))
                                        setShowDocumentCanvas(true)
                                        },
                                      actionIcon: "PlusIcon",
                                    },
                                    {
                                      actionName: _l("l_manage_project_member"),
                                      actionHandler: () => {
                                        setManagePeopleModal(true);
                                        setproject_id(selectedSpace.project_id);
                                      },
                                      actionIcon: "IdentificationBadgeIcon",
                                    },
                                    {
                                      actionName: _l("l_open_ai"),
                                      actionHandler: () => {
                                        setshowOpenAiModal(true);
                                        setproject_id(selectedSpace.project_id);
                                        setspaeContent(selectedSpace.content)
                                      },
                                      actionIcon: "AiIcon",
                                    },
                                    {
                                      actionName: _l("l_delete") +" "+ _l("l_space"),
                                      actionHandler: () => {
                                        setproject_id(selectedSpace.project_id);
                                        setOfficeId(selectedSpace.id);
                                        setShowToast(true);
                                        prepareToast(
                                          "toast-with-btn",
                                          _l("l_space_delete_conformation"),
                                          _l("l_confirm")
                                        );
                                      },
                                      actionIcon: "TrashIcon",
                                    },
                                    {
                                      actionName: _l("l_change") +" "+ _l("l_project_status"),
                                      actionHandler: () => {
                                        setproject_id(selectedSpace.project_id);
                                        setSelectedspaceStatus(parseInt(selectedSpace.project_status));
                                        setOfficeId(selectedSpace.id);
                                        setshowProjectStatusList(true)                                        
                                      },
                                      actionIcon: "FlagIcon",
                                    },
                                  ]
                                  :  
                                  [
                                    {
                                      actionName: _l("l_select_all_topics"),
                                      actionHandler: () => {
                                        dispatch(leftPanelFilters("","","selectAllTopics"))
                                      },
                                      actionIcon: "SelectionAll",
                                    },
                                    // {
                                    //   actionName: _l("l_explore_feature"),
                                    //   actionHandler: () => {
                                    //     dispatch(setProject(projectId));
                                    //     setSelectedOffice(projectId)
                                    //     localStorage.setItem("selectedOffice", projectId);
                                    //     navigate(`/explore-features?space=${projectId}`);
                                    //   },
                                    //   actionIcon: "PencilSimpleLineIcon",
                                    // },
                                    {
                                      actionName: _l("l_manage_project_member"),
                                      actionHandler: () => {
                                        setManagePeopleModal(true);
                                        setproject_id(selectedSpace.project_id);
                                      },
                                      actionIcon: "IdentificationBadgeIcon",
                                    },
                                    {
                                      actionName: _l("l_access_office"),
                                      actionHandler:() => {""},
                                      actionIcon: "KeyIcon",
                                    }
                                  
                                  ]
                            }
                            list={showProjectStatusList ? projectStatusArray : userList.map((item, index) => {
                              return {
                                name: item.fullname || item.name,
                                image: item.profile_image_url,
                                email: item.email,
                                type: item.type ? item.type : "",
                                user_type: item.user_type ? item.user_type : "",
                                is_primary: item.is_primary
                                  ? item.is_primary
                                  : "",
                                is_boss_operator: item.is_boss_operator
                                  ? item.is_boss_operator
                                  : "",
                                client_id: item.userid,
                                contact_role: item.contact_role,
                                company: item.company,
                                id : item.user_type == "Provider" ? item.staff_id : item.contact_id,
                                session_details : item.session_details,
                                last_login: item.last_login,
                                no_of_comments: item.no_of_comments,
                                task_score: item.task_score,
                                contact_id : item.contact_id,
                                staff_id : item.staff_id
                              };
                            })}
                            loder={loder}
                            unControlList={false}
                            setLoder={setLoder}
                            handler={(actionItem) => {
                              if(actionItem && actionItem.metaData && actionItem.metaData.key === "edit_user_profile")
                              {
                                setTopicMainKey("edit_user");
                                setSettingTopicName(actionItem.type === "Provider" ? "l_edit_provider_profile" : "l_edit_contact_profile");
                              }
                              else {
                                showProjectStatusList ?
                                handleChangeProjectStatus(actionItem.id) :
                                loginHandler(
                                  actionItem.email,
                                  actionItem.client_id,
                                  actionItem.type,
                                  actionItem.id,
                                  0,
                                  actionItem
                                );
                              }
                            }}
                            selectedspaceStatus={selectedspaceStatus}
                            setSelectedspaceStatus={setSelectedspaceStatus}
                            isLoginAsUserDataLoaded={isLoginAsUserDataLoaded}
                          />
                        </div>
                      </div>
                    </div>
                  : <></>
                  }
                  </div>
                </div>
                <div className="company-logo-box position-relative d-flex flex-column justify-content-center d-none">
                  <a href="#/" className="office-list-detail  left-toggle-none"> 
                      <div className="office-list-desc-left w-100">
                        <div className="align-items-center d-flex justify-content-between ">
                        <div className="d-flex align-items-center text-truncate">
                            <div className="text-truncate max-width-160px  pe-2 c-font f-12">
                              {selectedSpace.office_title
                                ? selectedSpace.office_title :
                                selectedSpace.name ? selectedSpace.name 
                                : _l("l_office_title_placeholder")}
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-end dropstart dropdown-static-display">
                        { selectedSpace.is_virtual_space == 1 ? <></> :
                          <div className="progress-slider w-70px">
                            <div className="d-flex align-items-center">
                              <div className="progress">
                                <div
                                  className="progress-bar"
                                  style={{
                                    width: `${selectedSpace.workspace_score}%`,
                                  }}
                                ></div>
                              </div>
                              <div className="progress-text c-font f-10 title-fonts">
                                {selectedSpace.workspace_score}%
                              </div>
                            </div>
                          </div>
                        }
                            <div className="comman_action_icon">
                            <a href="#/"
                              className="action_icon px-1  h25w25 on-hover-active-toggle-img "
                              onClick={() => {
                                pinProject(selectedSpace.project_id);
                              }}
                            >
                              {selectedSpace.pin_count &&
                                selectedSpace.pin_count === "1" ? (
                                <div className="m-auto">
                                  <PushPin size={16} weight="fill" className="c-icons Push-Pin-fill" />
                                </div>
                              ) : (
                                <div className="m-auto">

                                  <PushPin size={16} weight="light" className="c-icons" />
                                </div>
                              )}
                            </a>
                            </div>
                            <div className="multi_inner_wrapper with-min-width d-flex">
                          {
                            selectedSpace.members && selectedSpace.members.filter((u) => u.role == 3 || u.role == 12).length > 0 ?
                              selectedSpace.members.filter((u) => u.role == 3 || u.role == 12).map((dd, index) => {
                                if (index == 0) {
                                  return (
                                    <div className="comman-image-box h20w20 rounded-circle"
                                      data-tip={dd.fullname}
                                      data-effect="solid"
                                      data-delay-show='1000'
                                      data-class="tooltip-main"
                                    >
                                      <div
                                        className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 rounded-circle"
                                        style={{
                                          backgroundImage: `url('${dd.profile_image_url}')`
                                        }}
                                      ></div>
                                    </div>
                                  )
                                }
                              })
                              : <></>
                          }
                            </div>
                          </div>
                        </div>
                          <div className="d-flex align-items-center color-white-60">
                            <div className="c-font f-10 text-truncate w100minus85 pe-2">{selectedSpace && selectedSpace.postal_address}
                          </div>
                        </div>
                    </div>             
                  </a>
                </div>
                </div>
              )}
            </div>
            :<></>
          }

            {LeftPanelEmailPage || teamLeftPanel ? (
              <></>
            ) : (
              pageName == "Proposals" ?
                <div className="kpi-main-wrapper shortcut-wrapper pe-0">
                  <div className="d-flex align-items-center justify-content-between">
                    {defaultProposalStatus.map((status, index) => {
                      var statusTitle =
                        status == 6
                          ? _l("l_received")
                          : status == 4
                            ? _l("l_pending")
                            : status == 3 ? _l("l_accepted") : _l('l_declined');
                      var statusColor =
                        status == 4
                          ? "color-orange"
                          : status == 6
                            ? "color-blue"
                            : status == 3 ? "color-green" : 'color-red';
                      var taskCount = 0;
                      if (proposalKpiCount) {
                        taskCount =
                          status == 6
                            ? proposalKpiCount.received ? proposalKpiCount.received : 0
                            : status == 4
                              ? proposalKpiCount.pending ? proposalKpiCount.pending : 0
                              : status == 3
                                ? proposalKpiCount.contract ? proposalKpiCount.contract : 0
                                : status == 2
                                  ? proposalKpiCount.declined ? proposalKpiCount.declined : 0
                                  : ""
                      }
                      let isActive = false
                      if (taskFilters && taskFilters.length > 0) {
                        for (let i = 0; i < taskFilters.length; i++) {
                          if (taskFilters[i].filter_key == status) {
                            isActive = true
                          }
                        }
                      }
                      return (
                        <a href="#/"
                          id={`kpi_task_status_${status}`}
                          className={`kpi-box bg-white-05 ${isActive ? "active" : ""
                            }`}
                          onClick={() => {
                            dispatch(
                              leftPanelFilters({
                                main_key: "proposals_status",
                                filter_key: status,
                                title: statusTitle
                              })
                            );
                          }}
                          key={index}
                        >
                          <div className="kpi-upper-box">
                            <div className="d-flex align-items-center">
                              <div className="kpi-upper-box-text">
                                <span className={statusColor}>{statusTitle}</span>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mt-auto">
                            <div
                              className={` w-50 text-center`}
                            >
                              <div className="kpi-statatics">{taskCount} </div>
                            </div>
                          </div>
                        </a>
                      );
                    })}
                  </div>
                </div>
                :
                pageName === 'invoice' || pageName == "estimate" || pageName == "subscription" || pageName == "benchmark" || pageName == "schedule" || pageName == "staff" || pageName == "client" || pageName == "_equipments" || pageName == "credit_notes" ?
                pageName == "estimate" ?
                <div className="kpi-main-wrapper shortcut-wrapper pe-0">
                  <div className="d-flex align-items-center justify-content-between">
                    {defaultEstimateStatus.map((status, index) => {
                      var statusTitle =
                        status == 1
                          ? "Draft"
                          : status == 2
                            ? "Sent"
                            : status == 3 ? "Declined" : "Accepted";
                      var statusColor =
                        status == 1
                          ? "color-white"
                          : status == 3
                            ? "color-red"
                            : status == 2 ? "color-blue" : 'color-green';
                      var taskCount = 0;
                      if (estimateCount) {
                        taskCount =
                          status == 1
                            ? estimateCount.draft ? estimateCount.draft : 0
                            : status == 2
                              ? estimateCount.sent ? estimateCount.sent : 0
                              : status == 4
                                ? estimateCount.accept ? estimateCount.accept : 0
                                : status == 3
                                  ? estimateCount.declined ? estimateCount.declined : 0
                                  : ""
                      }
                      let isActive = false
                      if (taskFilters && taskFilters.length > 0) {
                        for (let i = 0; i < taskFilters.length; i++) {
                          if (taskFilters[i].filter_key == status && taskFilters[i].main_key == "estimate_status") {
                            isActive = true
                          }
                        }
                      }
                      return (
                        <a href="#/"
                          id={`kpi_task_status_${status}`}
                          className={`kpi-box bg-white-05 ${isActive ? "active" : ""
                            }`}
                          onClick={() => {
                            // filterTasksByStatus(status);
                            dispatch(
                              leftPanelFilters({
                                main_key: "estimate_status",
                                filter_key: status,
                                title: statusTitle
                              })
                            );
                          }}
                          key={index}
                        >
                          <div className="kpi-upper-box">
                            <div className="d-flex align-items-center">
                              <div className="kpi-upper-box-text">
                                <span className={statusColor}>{statusTitle}</span>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mt-auto">
                            <div
                              className={` w-50 text-center`}
                            >
                              <div className="kpi-statatics">{taskCount} </div>
                            </div>
                          </div>
                        </a>
                      );
                    })}
                  </div>
                </div>
                :
                pageName == "benchmark" || pageName == "schedule"? 
                <div className="kpi-main-wrapper shortcut-wrapper pe-0">
                  <div className="d-flex align-items-center justify-content-between">
                    {defaultBenchmarkStatus.map((status, index) => {
                      var statusTitle =
                        status == 1
                          ? "Draft"
                          : status == 3
                            ? "Active"
                            : status == 4 ? "Future" : "Expired";
                      var statusColor =
                        status == 1
                          ? "color-white"
                          : status == 3
                            ? "color-green"
                            : status == 4 ? "color-blue" : 'color-red';
                      var taskCount = 0;
                      if (benchmarkCounter) {
                        taskCount =
                          status == 1
                            ? benchmarkCounter.draft_count ? benchmarkCounter.draft_count : 0
                            : status == 3
                              ? benchmarkCounter.active_count ? benchmarkCounter.active_count : 0
                              : status == 4
                                ? benchmarkCounter.fucture_count ? benchmarkCounter.fucture_count : 0
                                : status == 5
                                  ? benchmarkCounter.expired_count ? benchmarkCounter.expired_count : 0
                                  : ""
                      }
                      let isActive = false
                      if (taskFilters && taskFilters.length > 0) {
                        for (let i = 0; i < taskFilters.length; i++) {
                          if (taskFilters[i].filter_key == status && taskFilters[i].main_key == (pageName == "benchmark" ? "benchmark_status" : "schedule_status")) {
                            isActive = true
                          }
                        }
                      }
                      return (
                        <a href="#/"
                          id={`kpi_task_status_${status}`}
                          className={`kpi-box bg-white-05 ${isActive ? "active" : ""
                            }`}
                          onClick={() => {
                            // filterTasksByStatus(status);
                            dispatch(
                              leftPanelFilters({
                                main_key: pageName == "benchmark" ? "benchmark_status" : "schedule_status",
                                filter_key: status,
                                title: statusTitle
                              })
                            );
                          }}
                          key={index}
                        >
                          <div className="kpi-upper-box">
                            <div className="d-flex align-items-center">
                              <div className="kpi-upper-box-text">
                                <span className={statusColor}>{statusTitle}</span>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mt-auto">
                            <div
                              className={` w-50 text-center`}
                            >
                              <div className="kpi-statatics">{taskCount} </div>
                            </div>
                          </div>
                        </a>
                      );
                    })}
                  </div>
                </div>
                :
                pageName == "subscription" ? 
                <div className="kpi-main-wrapper shortcut-wrapper pe-0">
                  <div className="d-flex align-items-center justify-content-between">
                    {defaulSubscriptionStatus.map((status, index) => {
                      var statusTitle =
                        status == 'active'
                          ? "Active"
                          : status == 'canceled'
                            ? "Canceled"
                            : status == 'future' ? "Future" : "Not Subscribed";
                      var statusColor =
                        status == 'active'
                          ? "color-green"
                          : status == 'canceled'
                            ? "color-red"
                            : status == 'future' ? "color-blue" : 'color-white';
                      var taskCount = 0;
                      if (subCounter) {
                        taskCount =
                          status == 'active'
                            ? subCounter.data.active ? subCounter.data.active : 0
                            : status == 'canceled'
                              ? subCounter.data.canceled ? subCounter.data.canceled : 0
                              : status == 'future'
                                ? subCounter.data.future ? subCounter.data.future : 0
                                : status == 'not_subscribed'
                                  ? subCounter.data.not_subscribed ? subCounter.data.not_subscribed : 0
                                  : ""
                      }
                      let isActive = false
                      if (taskFilters && taskFilters.length > 0) {
                        for (let i = 0; i < taskFilters.length; i++) {
                          if (taskFilters[i].filter_key == status && taskFilters[i].main_key == "subscription_status") {
                            isActive = true
                          }
                        }
                      }
                      return (
                        <a href="#/"
                          id={`kpi_task_status_${status}`}
                          className={`kpi-box bg-white-05 ${isActive ? "active" : ""
                            }`}
                          onClick={() => {
                            // filterTasksByStatus(status);
                            dispatch(
                              leftPanelFilters({
                                main_key: 'subscription_status',
                                filter_key: status,
                                title: statusTitle
                              })
                            );
                          }}
                          key={index}
                        >
                          <div className="kpi-upper-box">
                            <div className="d-flex align-items-center">
                              <div className="kpi-upper-box-text">
                                <span className={statusColor}>{statusTitle}</span>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mt-auto">
                            <div
                              className={` w-50 text-center`}
                            >
                              <div className="kpi-statatics">{taskCount} </div>
                            </div>
                          </div>
                        </a>
                      );
                    })}
                  </div>
                </div>
                :
                pageName == "credit_notes" ? 
                <div className="kpi-main-wrapper shortcut-wrapper pe-0">
                  <div className="d-flex align-items-center justify-content-between">
                    {defaultCreditNoteStatus.map((status, index) => {
                      var statusTitle =
                        status == 1
                          ? "Open"
                          : status == 2
                            ? "Close"
                            : "Void";
                      var statusColor =
                        status == 1
                          ? "color-green"
                          : status == 2
                            ? "color-red"
                            : 'color-white';
                      var taskCount = 0;
                      if (subCounter) {
                        taskCount =
                          status == 1
                            ? subCounter.data.open ? subCounter.data.open : 0
                            : status == 2
                              ? subCounter.data.close ? subCounter.data.close : 0
                              : status == 3
                                ? subCounter.data.void ? subCounter.data.void : 0
                                :""
                      }
                      let isActive = false
                      if (taskFilters && taskFilters.length > 0) {
                        for (let i = 0; i < taskFilters.length; i++) {
                          if (taskFilters[i].filter_key == status && taskFilters[i].main_key == "credit_note_status") {
                            isActive = true
                          }
                        }
                      }
                      return (
                        <a href="#/"
                          id={`kpi_task_status_${status}`}
                          className={`kpi-box bg-white-05 _credit_notes ${isActive ? "active" : ""
                            }`}
                          onClick={() => {
                            // filterTasksByStatus(status);
                            dispatch(
                              leftPanelFilters({
                                main_key: 'credit_note_status',
                                filter_key: status,
                                title: statusTitle
                              })
                            );
                          }}
                          key={index}
                        >
                          <div className="kpi-upper-box">
                            <div className="d-flex align-items-center">
                              <div className="kpi-upper-box-text">
                                <span className={statusColor}>{statusTitle}</span>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mt-auto">
                            <div
                              className={` w-50 text-center`}
                            >
                              <div className="kpi-statatics">{taskCount} </div>
                            </div>
                          </div>
                        </a>
                      );
                    })}
                  </div>
                </div>
                :
                pageName == "staff" || pageName == "client" || pageName == "_equipments"?
                <div className="kpi-main-wrapper shortcut-wrapper pe-0">
                  <div className="d-flex align-items-center justify-content-between">
                    {defaultStaffStatus.map((status, index) => {
                      var statusTitle =
                        status == 1
                          ? "Active"
                          : "Deactive";
                      var statusColor =
                        status == 1
                          ? "color-green" : 'color-red';
                      var taskCount = 0;
                      if (staffCounter && staffCounter.data) {
                        taskCount =
                          status == 1
                            ? staffCounter.data.active ? staffCounter.data.active : 0
                            : status == 0
                              ? staffCounter.data.deactive ? staffCounter.data.deactive : 0
                              : ""
                      }
                      let isActive = false
                      if (taskFilters && taskFilters.length > 0) {
                        for (let i = 0; i < taskFilters.length; i++) {
                          if (taskFilters[i].filter_key == status && taskFilters[i].main_key == (pageName == "staff" ? "staff_status" : pageName == "_equipments" ? 'equipment_status' : "client_status")) {
                            isActive = true
                          }
                        }
                      }
                      return (
                        <a href="#/"
                          id={`kpi_task_status_${status}`}
                          className={`kpi-box bg-white-05 _staff ${isActive ? "active" : ""
                            }`}
                          onClick={() => {
                            dispatch(
                              leftPanelFilters({
                                main_key: pageName == "staff" ? "staff_status" : pageName == "_equipments" ? 'equipment_status' : "client_status",
                                filter_key: status,
                                title: statusTitle
                              })
                            );
                          }}
                          key={index}
                        >
                          <div className="kpi-upper-box">
                            <div className="d-flex align-items-center">
                              <div className="kpi-upper-box-text">
                                <span className={statusColor}>{statusTitle}</span>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mt-auto">
                            <div
                              className={` w-50 text-center`}
                            >
                              <div className="kpi-statatics">{taskCount} </div>
                            </div>
                          </div>
                        </a>
                      );
                    })}
                  </div>
                </div>
                :
                <div className="kpi-main-wrapper shortcut-wrapper pe-0">
                  <div className="d-flex align-items-center justify-content-between">
                    {defaultInvoiceStatus.map((status, index) => {
                      var statusTitle =
                        status == 6
                          ? "Draft"
                          : status == 5
                            ? "Cancelled"
                            : status == 2 ? "Paid" : "Unpaid";
                      var statusColor =
                        status == 6
                          ? "color-white"
                          : status == 5
                            ? "color-orange"
                            : status == 2 ? "color-green" : 'color-red';
                      var taskCount = 0;
                      if (invoiceCount) {
                        taskCount =
                          status == 6
                            ? invoiceCount.draft ? invoiceCount.draft : 0
                            : status == 5
                              ? invoiceCount.cancelled ? invoiceCount.cancelled : 0
                              : status == 2
                                ? invoiceCount.paid ? invoiceCount.paid : 0
                                : status == 1
                                  ? invoiceCount.unpaid ? invoiceCount.unpaid : 0
                                  : ""
                      }
                      let isActive = false
                      if (taskFilters && taskFilters.length > 0) {
                        for (let i = 0; i < taskFilters.length; i++) {
                          if (taskFilters[i].filter_key == status) {
                            isActive = true
                          }
                        }
                      }
                      return (
                        <a href="#/"
                          id={`kpi_task_status_${status}`}
                          className={`kpi-box bg-white-05 ${isActive ? "active" : ""
                            }`}
                          onClick={() => {
                            // filterTasksByStatus(status);
                            dispatch(
                              leftPanelFilters({
                                main_key: "invoice_status",
                                filter_key: status,
                                title: statusTitle
                              })
                            );
                          }}
                          key={index}
                        >
                          <div className="kpi-upper-box">
                            <div className="d-flex align-items-center">
                              <div className="kpi-upper-box-text">
                                <span className={statusColor}>{statusTitle}</span>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mt-auto">
                            <div
                              className={` w-50 text-center`}
                            >
                              <div className="kpi-statatics">{taskCount} </div>
                            </div>
                          </div>
                        </a>
                      );
                    })}
                  </div>
                </div>
                : !isAllChatUserlistShow ?
                <div className={`kpi-main-wrapper shortcut-wrapper p-0 with-top-border ${contactlistShow ? "for_disabled d-none": ""}`}>
                {isLeadPage == 1 ?
                  <div className="d-flex align-items-center justify-content-between p-10">
                    {defaultLeadsStatus.map((status, index) => {
                      var statusTitle =
                        status == 1
                          ? _l("l_client_kpi")
                          : status == 3
                            ? _l("l_not_interested_kpi")
                            : status == 16 
                              ? _l("l_lead_kpi") 
                              : status == 28 ? _l("l_ongoing_kpi") : _l('l_interested_kpi');
                      var statusColor =
                        status == 1
                          ? "color-white-80"
                          : status == 3
                            ? 'color-orange'
                            : status == 16 
                              ? "color-green" 
                              : status == 28 ? "color-yellow" : "color-blue";
                      var taskCount = 0;
                      if (projectTaskCount) {
                        taskCount =
                          status == 1
                            ? projectTaskCount.client_count ? projectTaskCount.client_count : 0
                            : status == 3
                              ? projectTaskCount.not_interested_count ? projectTaskCount.not_interested_count : 0
                              : status == 16 
                                ? projectTaskCount.lead_count ? projectTaskCount.lead_count: 0
                                : status == 28 
                                  ? projectTaskCount.ongoing_count ? projectTaskCount.ongoing_count : 0 
                                  : projectTaskCount.interested_count ? projectTaskCount.interested_count : 0
                         }
                      return (
                        <a href="#/"
                          id={`kpi_task_status_${status}`}
                          data-tip={statusTitle}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                          className={`kpi-box bg-white-05 w20per ${filterTaskActive.indexOf(status) > -1 ? "active" : ""
                            }`}
                          onClick={() => {
                          if (!contactlistShow) {
                            filterTasksByStatus(status);
                            dispatch(
                              projectTaskCounts(
                                projectId,
                                customerData.taskFilters
                                  ? customerData.taskFilters
                                  : [],
                                "",
                                isLeadPage, pageName,globalView, leadsFilters, globalSearchString
                              )
                            );
                            if(pageName == "Chat"){
                              dispatch(setOpenGeneralChatGroup(false));
                              navigate("/");
                            }
                          }}
                        }
                          key={index}
                        >
                          <div className="kpi-upper-box w-100">
                            <div className="d-flex align-items-center justify-content-center w-100">
                              <div className="kpi-upper-box-text text-truncate">
                                <span className={statusColor}>{statusTitle}</span>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <div
                              className={`w-10 text-center`}
                            >
                              <div className="kpi-statatics">{ contactlistShow ? 0 : taskCount} </div>
                            </div>
                          </div>
                        </a>
                      );
                    })}
                  </div>
                :
                  <div className="d-flex align-items-center justify-content-between p-10">
                    {defaultTaskStatus.map((status, index) => {
                      var statusTitle =
                        status == 1
                          ? _l("l_todo_kpi_name")
                          : status == 4
                            ? _l("l_ongoing_kpi")
                            : status == 5 ? _l("l_done_kpi") : _l('l_request');
                      var statusColor =
                        status == 1
                          ? "color-white-80"
                          : status == 4
                            ? "color-blue"
                            : status == 5 ? "color-green" : 'color-orange';
                      var taskCount = 0;
                      var requestTaskCount = 0;
                      if (projectTaskCount) {
                        taskCount =
                          status == 1
                            ? projectTaskCount.todo_count ? projectTaskCount.todo_count : 0
                            : status == 4
                              ? projectTaskCount.ongoing_count ? projectTaskCount.ongoing_count : 0
                              : status == 5 ? projectTaskCount.completed_count ? projectTaskCount.completed_count: 0
                               : projectTaskCount.request_count ? projectTaskCount.request_count : 0
                        requestTaskCount =
                          status == 1
                            ? projectTaskCount.todo_request_count ? projectTaskCount.todo_request_count : 0
                            : status == 4
                              ? projectTaskCount.ongoing_request_count ? projectTaskCount.ongoing_request_count : 0
                              : status == 5 ? projectTaskCount.completed_count : projectTaskCount.request_count
                      }

                      return (
                        <a href="#/"
                          id={`kpi_task_status_${status}`}
                          className={`kpi-box bg-white-05 w_25per ${statusTitle == "Request" && isLeadPage == 1 ? "d-none" : ""} ${filterTaskActive.indexOf(status) > -1 ? "active" : ""
                            }`}
                          onClick={() => {
                          if (!contactlistShow) {
                            filterTasksByStatus(status);
                            dispatch(
                              projectTaskCounts(
                                projectId,
                                customerData.taskFilters
                                  ? customerData.taskFilters
                                  : [],
                                "",
                                isLeadPage, pageName,globalView
                              )
                            );
                            if(pageName == "Chat"){
                              dispatch(setOpenGeneralChatGroup(false));
                              navigate("/");
                            }
                          }}
                        }
                          key={index}
                        >
                          <div className="kpi-upper-box">
                            <div className="d-flex align-items-center justify-content-center">
                              <div className="kpi-upper-box-text">
                                <span className={statusColor}>{statusTitle}</span>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <div
                              className={`w-10 text-center`}
                            >
                              <div className="kpi-statatics">{ contactlistShow ? 0 : taskCount} </div>
                              <div
                                className={`kpi-tag-box d-none ${isLeadPage || ['Dashboard', "calendar","dashboard"].includes(pageName) ? "d-none" : "border-right"
                                  }`}
                              >
                                {_l("l_task")}
                              </div>
                            </div>
                            <div
                              className={` w-50 text-center kpi-second-box d-none ${isLeadPage || ['Dashboard', "calendar","dashboard"].includes(pageName) ? "d-none" : ""
                                }`}
                            >
                              <div className="kpi-statatics">
                                {requestTaskCount}{" "}
                              </div>
                              <div className="kpi-tag-box text-warning text-truncate">
                                {_l("l_req")}
                              </div>
                            </div>
                          </div>
                        </a>
                      );
                    })}
                  </div> 
                }
                </div>
                :<></>
            )}
              
          {taskFilters && taskFilters[0] && taskFilters[0].main_key ? 
            <div className=" p-5px pt-0 d-none">
              <div className="d-flex align-items-center p-2 bg-white-03 dropdown">
                <div className="h32w32 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle">
                  <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                    style={{ backgroundImage: `url(${localStorage.getItem("contact_image")})` }}
                  ></div>
                </div>
                <div className="d-flex align-items-center ps-3 w100minus35 " data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                  type="button"
                >
                  <div className="flex-grow-1">
                    <div className="fw-semibold c-font f-12 title-fonts">{_l("l_for_me")}</div>
                    <div className="pill-wrapper">
                      <div className="d-flex align-items-center">
                          {
                            taskFilters && taskFilters.map((tf) => {
                              if (tf.main_key == "my_task") {
                                return (
                                  <div className="badge badge-white rounded-pill fw-light me-1 pill-task-active p-1 max-width75 text-truncate ">
                                    <div className="d-flex align-items-center text-truncate">
                                      <div className="text-truncate">{_l(tf.title)}</div>
                                      <a href="#/" className="ps-1">
                                        <div>
                                          <X size={10} weight="light" className="c-icons" />
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                )
                              }
                            })
                          }

                      </div>
                    </div>
                  </div>

                  <a href="#/" className="with_separator_10"><PlusIcon size={18} weight="light" className="c-icons m-0" /></a>
                  <a href="#/" className="with_separator_10 pe-0"><GearSix size={18} weight="light" className="c-icons m-0" /></a>
                </div>
                <ActionDropdown
                  actions={forMeTopic.map((dt,key) => {
                    let acvtiveIds = []
                    return {
                      ...dt,
                      actionName: _l(dt.title),
                      active:acvtiveIds.includes(dt.filter),
                      iconDisplay: dt.filter_key == "created_by_me" || dt.filter_key == "assigned_to_me" ? false : true,
                      actionHandler: () => {
                        dispatch(
                          leftPanelFilters({
                            main_key: dt.main_key,
                            filter_key: dt.filter_key,
                            title: dt.title,
                            main_sub_key: dt.main_sub_key,
                            sub_category: dt.sub_category,
                          })
                        );
                        dispatch(
                          projectTaskCounts(
                            projectId,
                            taskFilters,
                            {
                              main_key: dt.main_key,
                              filter_key:dt.filter_key,
                              title: dt.title,
                            },
                            isLeadPage, pageName
                          )
                        );
                        HideDropdown();
                      }, 
                      profileImage:dt.filter_key == "created_by_me" ? localStorage.getItem("contact_image") : dt.filter_key == "assigned_to_me" ? "https://myrpp.myr.ai/orb/assets/images/default-image-placeholder.svg":"",
                      actionIcon: dt.filter_key == "pinned" ? "PushPinIcon": "HashIcon"
                    }
                  })
                  }
                  widthClass={"w-100 with-transform-revert"}
                />
              </div>
            </div>
            :<></>}
          
            <div className={`office-list-main with-padding shortcut-wrapper ${pageName === "MyEmail" ? "" : "mt-0"} p-0 ${showBackDropClass ? "z-index-15" : ""}`}>
                <TwoStepAccordians
                setCompletedTasksCount={setCompletedTasks}
                setInProgressTasksCount={setInProgressTasks}
                setTodoTasksCount={setTodoTasks}
                projectId={projectId}
                searchCategory={searchCategory}
                pageName={pageName}
                teamLeftPanel={teamLeftPanel}
                setSelectOperators={setSelectOperators}
                setShowScheduleDropDown={setShowScheduleDropDown}
                setShowProviderDropDown={setModal1show}
                allMAilMessages={allMAilMessages}
                allImportantMessages={allImportantMessages}
                hideMailSyncBtn={hideMailSyncBtn}
                createTeamModal={createTeamModal}
                setCreateTeamModal={setCreateTeamModal}
                equipmentModal={equipmentModal}
                setEquipmentModal={setEquipmentModal}
                selectAdminTeam={(team, is_remove) => selectAdminTeam(team, is_remove)}
                selectedTeam={selectedTeam}
                clientTeamData={clientTeamData}
                handleDeleteTeam={handleDeleteTeam}
                handleFavoriteAdminTeam={handleFavoriteAdminTeam}
                handleEditAdminTeam={handleEditAdminTeam}
                topicType ={topicType}
                setTopicType ={setTopicType}
                selectedTeamForEdit ={selectedTeamForEdit}
                setSelectedTeamForEdit ={setSelectedTeamForEdit}
                showAddTeamMember ={showAddTeamMember}
                setshowAddTeamMember ={setshowAddTeamMember}
                adminTeamList ={adminTeamList}
                setAdminTeamList ={setAdminTeamList}
                showUserList={isAllChatUserlistShow}
                searchCategoty={searchCategoty}
                searchTopic={searchTopic}
                showForMeSection={showForMeSection}
                setShowForMeSection={setShowForMeSection}
                contactlistShow={contactlistShow}
                contactDirData={contactList(groupListExternal, searchTopic)}
                contactDirTopicChange={contactDirTopicChange}
              />
            </div>
            
            {MyEmailActiveClass && (
              <>
                <a href="#/" className="kpi-main-wrapper  shortcut-wrapper border-top d-none" onClick={() => {
                }} >
                  <div className={`d-flex align-items-center justify-content-between d-none`}>
                    {clientTeamData ||
                      (selectedTeam && Object.keys(selectedTeam).length) ? (
                      Object.keys(clientTeamData || selectedTeam).map(
                        (category, index) => {
                          const members =
                            selectedTeam && Object.keys(selectedTeam).length
                              ? selectedTeam.members.length
                                ? selectedTeam.members
                                : []
                              : clientTeamData[category];
                          let memberName = "";
                          members.forEach((member, index) => {
                            memberName += member.full_name;
                            if (members.length - 1 != index) {
                              memberName += " , ";
                            }
                          });

                          if (category === "myr_staff") {
                            return <React.Fragment key={index}></React.Fragment>;
                          }
                          if (category === "staff") {
                            category = "my_people";
                          }
                          return (
                            <React.Fragment key={index}>
                              <div
                                className={`office-img-wrapper  kpi-box  ${selectedTeam &&
                                  Object.keys(selectedTeam).length &&
                                  category == "my_people"
                                  ? ""
                                  : "w-50 bg-white-05"
                                  } ${userType == "operator" &&
                                    category === "operator" &&
                                    pageName == "operatorListReporting"
                                    ? "c-gradinet-active-border"
                                    : ""
                                  } ${selectedTeam &&
                                    Object.keys(selectedTeam).length &&
                                    category != "my_people"
                                    ? "d-none"
                                    : ""
                                  }`}
                                onClick={() => {
                                  navigate(
                                    pageName == "operatorListReporting"
                                      ? "/"
                                      : "/employee-reporting"
                                  );
                                }}
                              >
                                <a href="#/" className={`c-font f-10 pt-1 w-100 `}>
                                  {`${category[0].toUpperCase()}${category.length > 1
                                    ? category.slice(1).split("_").join(" ")
                                    : ""
                                    }`}
                                </a>
                                <div
                                  className={`multi_inner_wrapper d-flex min-height-25 mt-1 ${selectedTeam &&
                                    Object.keys(selectedTeam).length &&
                                    category == "my_people"
                                    ? ""
                                    : "d-none"
                                    }  `}
                                >
                                  {members && members.length ? (
                                    members.map((member, key) => {
                                      if (key < 2)
                                        return (
                                          <a href="#/"
                                            key={key}
                                            data-tip={member.full_name}
                                            data-for={randomID}
                                            data-delay-show='1000'
                                            data-effct="solid"
                                            data-class="tooltip-main"
                                            // title={member.full_name}
                                            className="comman-image-box h25w25 rounded-circle"
                                          >
                                            <div
                                              className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                              style={{
                                                backgroundImage: `url('${member.profile_image ||
                                                  member.profile_image_url
                                                  }')`,
                                              }}
                                            ></div>
                                          </a>
                                        );
                                      else if (key === 2) {
                                        return (
                                          <a href="#/"
                                            key={key}
                                            className="comman-image-box h25w25 with_overlay d-flex rounded-circle dropdown-center dropdown"
                                            type="button"
                                            id="CategoryFilterDropdown"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <div className="unread_count m-auto title-fonts c-font f-10 m-auto">
                                              +{members.length - 2}
                                            </div>
                                            <GeneralListing
                                              list={members.map((item) => {
                                                return {
                                                  name: item.full_name,
                                                  image:
                                                    item.profile_image ||
                                                    item.profile_image_url ||
                                                    member.profile_image_url,
                                                  id: item.staffid,
                                                };
                                              })}
                                            />
                                          </a>
                                        );
                                      }
                                    })
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <span
                                  className={`fw-semibold title-fonts c-font f-13 pt-1 ${selectedTeam &&
                                    Object.keys(selectedTeam).length &&
                                    category == "my_people"
                                    ? "d-none"
                                    : ""
                                    } `}
                                >
                                  {members.length}
                                </span>
                              </div>
                            </React.Fragment>
                          );
                        }
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                </a>
                {/* left penal search box start */}
                <div className="p-10 py-0 z-index-15">
                  <Form.Group className=" c-input-box w-100 align-items-center d-flex ps-10px bgspacelisting" ref={topicSearch}>
                    <MagnifyingGlass size={18}weight="light"className="c-icons m-0"/>
                      <Form.Control type="text" placeholder={_l("l_search")} className="bg-transparent bg-white-03 border-0 searchInputField flex-grow-1 ps-2" 
                      onChange={(e)=>{setsearchTopic(e.target.value)}} onFocus={() => {
                        setShowBackDropClass(true)}} 
                        onBlur={() => {
                        // setShowBackDropClass(false);
                        }} />
                    </Form.Group>
                </div>
                {/* left penal search box end */}
                {contact_role != 3 && !isAllChatUserlistShow ? 
                <div className={`kpi-main-wrapper  shortcut-wrapper with-white-bg p-10 d-flex gap-2 ${(teamLeftPanel ||  hideLeftPanelFooter.indexOf(pageName) > -1 )? "d-none" : ""} ${contactlistShow ? "for_disabled" : ""}`} >
                  <a href="#/" className={`d-flex  bg-white-05 radius_3 comman-round-box border-transparent flex-column justify-content-center align-items-center text-center p-5px h50px flex-grow-1 w-50 ${pageName == "documents" ? "active " : ""} ${contactlistShow ? "pe-none" : ""}`} onClick={()=>{setSettingTopicName("l_drive");
                setTopicMainKey("documents");}}>
                    <div className={` mx-3 comman-round-box  d-flex  `} >
                      <div className={`comman-bg-img h-100 w-100 d-flex on-hover-active-toggle-img `}>
                      <HardDrives
                              size={16}
                              weight="light"
                              className="c-icons"
                            />
                      </div>
                    </div>
                    <div className={` w100minus50  d-flex  title-fonts color-white-80 left-toggle-none team-box-open comman-round-box pt-2 `} >
                      <div className="d-flex justify-content-center align-items-center w-100">
                        <div className="d-flex flex-column align-item-center justify-content-between w-100 c-font f-12">
                          <div className="title-font c-font f-11 fw-bold text-uppercase hover-span ">{_l("l_drive")}</div>
                          {/* <div className="color-white-60 title-font  d-flex align-items-center c-font f-11">
                            <div className="with_separator_10 ps-0 ">{_l("l_invoices")}</div>
                            <div className="with_separator_10 ">{_l("l_estimates")}</div>
                            <div className="with_separator_10 pe-0">{_l("l_subscriptions")}</div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </a>
                  {admin || pageName =='dashboard' || pageName == "dashboardnew" ? <></> :
                  <a className={`d-flex  bg-white-05 radius_3 comman-round-box border-transparent flex-column justify-content-center align-items-center text-center p-5px h50px flex-grow-1 w-50 on-hover-active-toggle-img `}
                      onClick={() => {
                        dispatch(setProject(projectId));
                        setSelectedOffice(projectId)
                        localStorage.setItem("selectedOffice", projectId);
                        navigate(`/explore-features?space=${projectId}`);
                      }}
                  >
                    <div className={`  comman-round-box  d-flex align-items-center justify-content-center `} >
                      <div className={`comman-bg-img h-100 w-100 d-flex `}>
                          <GearSix
                            size={16}
                            weight="light"
                            className="c-icons"
                          />
                      </div>
                    </div>
                    <div className={` w100minus50  d-flex  title-fonts color-white-80 left-toggle-none team-box-open comman-round-box pt-2 `} >
                      <div className="d-flex justify-content-center align-items-center w-100">
                        <div className="d-flex flex-column align-item-center justify-content-between w-100 c-font f-12">
                          <div className="title-font c-font f-11 fw-bold text-uppercase hover-span ">{_l("l_settings")}</div>
                        </div>
                      </div>
                    </div>
                  </a>
                }
                </div> : <></>}
              </>
            )}
          </div>
        </div>
        <ReactTooltip  id={randomID} />
        <AddEditCategory projectId={projectId} />
        {showScheduleDropDown ? (
          <ScheduleDropDown show={showScheduleDropDown} staff={selectOperators} modal1handleClose={() => {
            setShowScheduleDropDown(false)
          }} />
        ) : (
          <></>
        )}

        {Modal1show ? (
          <>
            <ProviderDropdownWithprogressBar show={Modal1show}
              modal1handleClose={() => {
                setModal1show(false)
              }}
              // handleShow={handleShow} 
              staff={selectOperators} />
          </>
        ) : (
          <></>
        )}
        {showDocumentCanvas && onboardDocument ? (
            <CommanOffCanvas
              show={showDocumentCanvas}
              handleClose={() => {
                setShowDocumentCanvas(false);
              }}
              pinHandler={(id, type, flag) => {
                // handlePinUnPin(id, type, flag);
              }}
              data={onboardDocument}
              setData={()=> {}}
              docType={"pilotonboarding"}
              setSelectedId={() => {}}
              isFullScreen={true}
            />
          ) : (
            <></>
          )}
        {
          SettingMenuRender ? (
            <SettingMenu 
            preEstiPageDisable={preEstiPageDisable} 
            removeVirtualSpace={removeVirtualSpace} 
            userType={userType} 
            setSettingTopicName={setSettingTopicName} 
            setTopicMainKey={setTopicMainKey}
            closeModal={()=>{
              setSettingMenuRender(false);
            }}
            />
          ) :
            (<></>)
        }
        {
          showMyProfile ? 
          ( <MyProfileDropDownNew 
            contactlistShow = {contactlistShow}
            setContactlistShow = {setContactlistShow}
            setSelectedProject = {setSelectedProject}
            selectLiteVersion = {selectLiteVersion}
            disabledThirdPartyToolAccess = {disabledThirdPartyToolAccess}
            setSettingTopicName = {setSettingTopicName}
            setTopicMainKey= {setTopicMainKey}
            unreadComments = {unreadComments}
            unreadMessages = {unreadMessages}
            setSettingMenuRender = {setSettingMenuRender}
            SettingMenuRender = {SettingMenuRender}
            estimateDetails = {estimateDetails}
            viewPage = {viewPage}
            proposalUnreadComments = {proposalUnreadComments}
            setshowMyProfile={setshowMyProfile}
            setSelectedSpace = {setSelectedSpace}
            />
          ) : (<></>)
        }
        {
          managePeopleModal ?
          <ManagePrjoectMember 
          show={managePeopleModal}
          handleClose={() => {setManagePeopleModal(false)}}
          project_id={project_id}
          pageName={pageName}
          />
          :
          <></>
        }
        {
          showOpenAiModal ?
        <OpenAi
        show={showOpenAiModal}
        handleClose={()=> {setshowOpenAiModal(false)}}
        project_id={project_id}
        pageName={pageName}
        spacecontent={spacecontent}
        /> :<></>
        }
        {
          settingTopicName
          ?
          <TopicSettingModal 
            data={{main_key:topicMainKey, id: 1}} 
            handleClose={() => { 
              if (settingTopicName == 'l_drive' && topicMainKey == 'documents') {
                dispatch(setLeftPanelTab(localStorage.getItem('lastLeftPanelTab')));
              }
              setSettingTopicName(""); 
              setActiveMenuOptions([])
              if (localStorage.getItem("searchParamUserId")){
                localStorage.removeItem("searchParamUserId")
              }
            }} 
            show={settingTopicName.trim().length} 
            title={settingTopicName} 
            hideFilterArea={["third_party_integrations", "edit_user"].includes(topicMainKey)}
            hideSearchArea={["edit_user"].includes(topicMainKey)}
            fromDrive={settingTopicName == "l_drive" ? true : false}
            />
          :
          <React.Fragment></React.Fragment>
        }
        {
          showStaffIBANSetup
          ?
            <StaffIbanSetup handleClose={() => {setShowStaffIBANSetup(false)}} show={showStaffIBANSetup} />
          :
            <></>
        }
          {showToast ? (
        <Toasts
          delay={2500}
          handler={() => {
            setShowToast(false);
          }}
          header={toastHeader}
          message={toastMessage}
          toastClass={toastClass}
          cancelButtonHandler={() => {
            setShowToast(false);
          }}
          cancelButtonText={_l("l_cancel")}
          confirmButtonHandler={handleSpaceDelete}
          confirmButtonText={_l("l_delete")}
        />
      ) : (
        <></>
      )}
      </div>
       {/* } */}
      {
        spaceInvitationData.project_id > 0
        ?
          <AlertModal handleClose={closeInvitationAlert} message={spaceInvitationData.message} buttonText={"l_ok"} icon={{name: CelebrationIcon}} />
        :
          <React.Fragment></React.Fragment>
      }
      {
        benchmarkDiscardModal.project_id > 0 ?
        <AlertModal handleClose={() => {navigate(`/create-benchmark?space=${benchmarkDiscardModal.project_id}&provider-type=external-benchmark&id=${benchmarkDiscardModal.schedule_id}`);}} message={"l_external_benchmark_discard_alert"} buttonText={"l_continue"} icon={{name: SignIn}} secondaryButtonHandler={discardBenchmark} secondaryButtonText={"l_login"} />
        :
        <React.Fragment></React.Fragment>
      }
      {userType === "operator" &&
      pendingPaymentInfo &&
      pendingPaymentInfo.subscription_id ? (
        <PaymentModal
          address={pendingPaymentInfo.address}
          companyName={pendingPaymentInfo.company_name}
          amount={pendingPaymentInfo.subscription_amount}
          handleClose={() => {navigate("/")}}
          handleSave={proceedWithPendingPayment}
          show={pendingPaymentInfo.subscription_id > 0}
          loader={subscriptionLoader}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
     
    </>
  );
};

export default LeftPanel;
