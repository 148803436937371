import React from "react";
import { _l } from "../../../../hooks/utilities";
import ReactTooltip from "react-tooltip";

const ListWithAction = ({ dataList, onClick }) => {
  return (
    <div className="row m-0 g-0 pt-3">
      {dataList && dataList.map((row, key) => {
        return (
          <div
            key={key}
            className=" col-xl-12 c-box-shadow-box"
            onClick={() => {
              if (!row.overrideCardClickEvent) {
                onClick(row);
              }
            }}
          >
            <div className="task-list-card list-view p-10 cursor-pointer">
              <div class="align-items-center d-flex justify-content-between">
                {row.JSX}
                <div class="c-font f-14 fw-semibold text-truncate title-fonts color-white-60 w100minus100per">
                  {row.label}
                </div>
                {row.allocation ? (
                  <div className="d-flex align-items-center me-3">
                    <div className="d-flex align-items-center w-100 ms-auto title-fonts color-white-60 fw-semibold">
                      {row.allocation}
                    </div>
                  </div>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
                {row.tag ? (
                  <div className="dropdown-center multi_inner_wrapper with-min-width d-flex me-10px with_minus  ">
                    <div
                      className="badge badge-done rounded-pill text-nowrap list-toggle-view-none h32wauto c-font f-12 d-flex align-items-cente"
                      data-tip={row.tag}
                      data-effect="solid"
                      data-delay-show="1000"
                      data-class="tooltip-main"
                    >
                      <span
                        class={`text-truncate max-width75  d-block p-0 my-auto`}
                      >
                        {row.tag}
                      </span>
                    </div>
                    <ReactTooltip />
                  </div>
                ) : (
                  <></>
                )}
                {row.btnText ? (
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center w-100 ms-auto">
                      <a
                        href="#/"
                        className="btn btn-white-05 btn-sm border-0 h32wauto d-flex align-items-center"
                        onClick={() => onClick(row)}
                      >
                        {_l(row.btnText)}
                      </a>
                    </div>
                  </div>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ListWithAction;
