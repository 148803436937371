import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import User_2 from "../../assets/images/users/user_2.jpg";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { DotsNine, CheckCircle, CaretRight,CaretLeft, Funnel } from "phosphor-react";
import CommentSection from "../Comments/CommentSection";
import TaskTrackingListing from "../Offcanvas/TaskTrackingListing";
import ImageViewer from "./ImageViewer";
import CustomerServices from "../../services/customer-services";
import moment from "moment";
import { _l } from "../../hooks/utilities";
import { mapStyle } from "../../assets/map/mapStyle";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { Next } from "react-bootstrap/esm/PageItem";
import ActionDropdown from "../Dropdowns/ActionDropdown";

const ImageDetailOffcanvas = ({data, handleAction, selectedAttachment, setSelectedAttachment, CloseCommentModal ,isChat,docType}) => {
  const [checklistItems, setChecklistItems] = useState([]);
  const [showPrevBtn, setShowPrevBtn] = useState(false);
  const [showNextBtn, setShowNextBtn] = useState(false);
  const [attachIndex, setAttachIndex] = useState(0);
  const [attachNext, setAttachNext] = useState(5);
  const [attachPrivous, setAttachPrivous] = useState(-1);
  const [allAttachments, setAllAttachments] = useState([]);
  const [selectedLatLang, setSelectedLatLang] = useState({});
  const [taskdate, setTaskdate] = useState("")
  useEffect(() => {
    CustomerServices.getCheckList(data.taskId, data.projectId, false, true).then((res) => {
      if (res.status) {
        setChecklistItems(res.data);
      }
    })
    setAllAttachments(data.allAttachments);
  }, []);
  const containerStyle = {
    width: "100%",
    height: "100%",
  };
useEffect(() => {
if (data) {
  setSelectedLatLang({
    lat: +data.attachmentDetails.latitude ,
    lng: +data.attachmentDetails.longitude ,
  });
}
if (data && data.taskDetails ) { 
  let dateNew = data.taskDetails.startdate != null
  ? data.taskDetails.startdate
  : data.taskDetails.duedate && data.taskDetails.duedate != null
  ? data.taskDetails.duedate
  : data.taskDetails.dateadded && data.taskDetails.dateadded != null
  ? data.taskDetails.dateadded 
  :''
  setTaskdate(dateNew)
}
if (data && data.taskDetails.checklistItems) {
  setChecklistItems(data.taskDetails.checklistItems)
  
}
},[data])
  useEffect(() => {
    if(allAttachments && allAttachments.length > 0){
      var selectedImageIndex = allAttachments.findIndex(item => item.id == selectedAttachment.id);
      if(selectedImageIndex > -1){
        setAttachIndex(selectedImageIndex);
        setShowPrevBtn(allAttachments.length != 1 && selectedImageIndex > 0 ? true : false);
        setShowNextBtn(allAttachments.length != 1 && selectedImageIndex < allAttachments.length - 1 ? true : false);
      }else{
        setShowPrevBtn(false);
        setShowNextBtn(false);
      }
    }
  }, [selectedAttachment, allAttachments]);

  const changeAttach = (direction) => {
    if(direction == "prev"){
      setSelectedAttachment(allAttachments[attachIndex - 1]);
    }else{
      setSelectedAttachment(allAttachments[attachIndex + 1]);
    }
  }

  // let date = data.taskDetails.startdate && data.taskDetails.startdate != null
  // ? data.taskDetails.startdate
  // : data.taskDetails.duedate && data.taskDetails.duedate != null
  // ? data.taskDetails.duedate
  // : data.taskDetails.dateadded && data.taskDetails.dateadded != null
  // ? data.taskDetails.dateadded 
  // :data.taskDetails.taskDetails.startdate && data.taskDetails.taskDetails.startdate != null
  // ? data.taskDetails.taskDetails.startdate
  // : data.taskDetails.taskDetails.duedate && data.taskDetails.taskDetails.duedate != null
  // ? data.taskDetails.taskDetails.duedate
  // : data.taskDetails.taskDetails.dateadded && data.taskDetails.taskDetails.dateadded != null
  // ? data.taskDetails.taskDetails.dateadded 
  // :''
  
  return (
    <>
    <div className="form-wrapper-main h-100 d-flex p-2">
            <div className="left-content-part w-300 p-0 h-100 comman-content-scroll-wrapper bg-transparent">
              <div className="border radius_5 overflow-hiiden-web flex-grow-1 d-flex flex-column">
                <div className="px-3 py-2 bg-white-03">
                  <div className="d-flex">
                    <div className="h25w25 h-auto"></div>
                    <div className="color-white-60 c-font f-12 ps-2 w100minus40">
                      Task #{data.taskDetails.id}
                    </div>
                    <div className="dropstart ms-2 d-flex">
                      <a href="#/" className={`comman_action_icon mt-auto`}
                      type="button"
                      id="commentOptionDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-tip={_l("l_options")}
                      data-effect="solid"
                      data-delay-show='1000'
                      data-class="tooltip-main"
                      >
                        <div className="d-flex align-items-center ">
                          <div className="action_icon h25w25 on-hover-active-toggle-img with_bg">
                            <Funnel
                              size={14}
                              className="c-icons"
                              weight="light"
                            />
                          </div>
                        </div>
                      </a>
                      <ActionDropdown
                        actions={[
                          {
                            actionName: _l("l_all"),
                            actionIcon: "SelectionAll",
                            actionHandler: () => {}
                          },
                          {
                            actionName: _l("l_floor_filter"),
                            actionIcon: "StepIcon",
                            actionHandler: () => {}
                          }
                        ]}
                      />
                    </div>
                  </div>
                  <div className="title-font c-font f-14 text-truncate d-none">{data.taskDetails.name}</div>
                  <div className="d-flex">
                    <div className="c-list-icon">
                      <div className="h25w25 comman-round-box with-bg d-flex rounded-circle bg-white-05 bg-style-cover" style={{
                        backgroundImage: `url('${data && data.attachmentDetails && data.attachmentDetails.creator_image_url ?
                        data.attachmentDetails.creator_image_url : "https://myrpp.myr.ai/orb/assets/images/default-image-placeholder.svg"
                        }')` }}>

                      </div>
                    </div>
                    <div className="ps-2 w100minus40 title-fonts">
                      <div className="c-font f-14 text-truncate pt-1" data-tip={data.taskDetails.name ? data.taskDetails.name :"" }
                        data-effect="solid"
                        data-delay-show='1000' data-class="tooltip-main">{data.taskDetails.name ? data.taskDetails.name : "" }</div>
                      <div className="c-font f-12 color-white-80">{moment(taskdate).format("DD/MM/YYYY hh:mm a")}</div>
                      <div className="progress-slider flex-grow-1">
                        <div className="d-flex align-items-center">
                          <ProgressBar variant="success" now={data.taskDetails.checklist_progress> -1 ?
                            data.taskDetails.checklist_progress : 0} />
                            <span className="title-fonts ps-2">{data.taskDetails.checklist_progress > -1 ?
                              data.taskDetails.checklist_progress : 0}%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-2 flex-grow-1 d-flex flex-column overflow-auto">
                <div className="overflow-auto h-100">
                  <TaskTrackingListing 
                    checklistItems={checklistItems} 
                    selectedAttachment={selectedAttachment} 
                    setSelectedAttachment={setSelectedAttachment}
                  />
                  </div>
                  <a href="#/" className="btn btn-secondary w-100 mt-3">
                    <CheckCircle size={18} weight="light" className={`c-icons color-${data.taskDetails.status == 5 ? "green" : data.taskDetails.status == 4 ?  "blue" :  "white" }`} /> <span className={`color-${data.taskDetails.status == 5 ? "green" : data.taskDetails.status == 4 ?  "blue" :  "white" }`}>The task is {data.taskDetails.status == 5 ? _l('l_done') : data.taskDetails.status == 4 ?  _l('l_inprogress') :  _l('l_todo') }.</span>
                    {/* <CheckCircle size={18} weight="light" className="c-icons color-blue" /> <span className="color-blue">The task is complete.</span>
                    <CheckCircle size={18} weight="light" className="c-icons warning" /> <span className="color-orange">The task is complete.</span> */}
                    </a>
                </div>
              </div>
              {
                data.taskDetails.rel_type == "equipment" ?
                  <div className="c-font f-14 pt-1  border radius_5 mt-2 p-1 d-flex">
                    <div className="w-50">
                    <span className="ps-2">{_l("l_surface")}</span>
                    <span className="ps-2">{data.taskDetails.surface ? data.taskDetails.surface : ""}</span>
                    </div>
                    <div className="w-50">
                    <span className="ps-2">{_l("l_duration")}</span>
                    <span className="ps-2">{data.taskDetails.duration ? data.taskDetails.duration : ""}</span>
                    </div>
                  </div>
                  :
                  <></>
              }
              <div className="border radius_5 mt-2">
                <div className="comman-list with-after-40 d-flex">
                  <div className="d-flex w-100">
                {
                  attachPrivous > -1 ?
                    <a href="#/" className="ms-1 bg-silder-btn h18w18 with_overlay d-flex  rounded-circle position-absolute top-50 start-0 translate-middle-y z-index-2"
                      onClick={() => {
                        setAttachNext(attachNext - 1)
                        setAttachPrivous(attachPrivous - 1)
                      }}
                    >
                      <CaretLeft size={14} weight="light" className="c-icons " />
                    </a>
                    : <></>
                }
                {
                  allAttachments.length > attachNext ?
                    <a href="#/" className="me-1 bg-silder-btn h18w18 with_overlay d-flex  rounded-circle position-absolute top-50 end-0 translate-middle-y z-index-2"
                      onClick={() => {
                        setAttachNext(attachNext + 1)
                        setAttachPrivous(attachPrivous + 1)
                      }}
                    >
                      <CaretRight size={14} weight="light" className="c-icons " />
                    </a>
                    : <></>
                }
                    <div className="d-flex m-auto ps-2 upload-image-preview">
                  {
                    allAttachments && allAttachments.length > 0 && allAttachments.filter((att ,key) => key < attachNext && key > attachPrivous).map((attachment) => {
                      return <a href="#/" className={`comman-image-box h40w40 radius_3 upload-image me-2 ${attachment.id == selectedAttachment.id ? "active" : ""}`}  onClick={() => setSelectedAttachment(attachment)}>
                        <div className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 " style={{ backgroundImage: `url(${attachment.image_url})` }}></div>
                      </a>
                    })
                  }
                    {/* <a href="#/" className="comman-image-box h40w40 radius_3 upload-image me-2">
                      <div className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 " style={{ backgroundImage: `url(${User_2})` }}></div>
                    </a>
                    <a href="#/" className="comman-image-box h40w40 radius_3 upload-image me-2">
                      <div className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 " style={{ backgroundImage: `url(${User_2})` }}></div>
                    </a>
                    <a href="#/" className="comman-image-box h40w40 radius_3 upload-image me-2">
                      <div className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 " style={{ backgroundImage: `url(${User_2})` }}></div>
                    </a>
                    <a href="#/" className="comman-image-box h40w40 radius_3 upload-image me-2">
                      <div className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 " style={{ backgroundImage: `url(${User_2})` }}></div>
                    </a> */}
                    </div>
                  </div>
                </div>
                <div className={`comman-list with-after-40 d-none ${data.taskDetails.creator_name && data.taskDetails.creator_name != "" ? "" :"d-none"}`}>
                  <div className="d-flex">
                    <div className="c-list-icon">
                      <div className="h25w25 comman-round-box with-bg d-flex rounded-circle bg-white-05 bg-style-cover" style={{ backgroundImage: `url('${data.taskDetails.creator_profile_image ? data.taskDetails.creator_profile_image : ""}')` }}>

                      </div>
                    </div>
                    <div className="ps-2 w100minus40 title-fonts">
                      <span className="c-font f-14 text-truncate pt-1 pe-3">{data.taskDetails.creator_name ? data.taskDetails.creator_name : ""}</span>
                      {/* <span className="c-font f-12 color-white-80">#Cleaner</span> */}
                    </div>
                  </div>
                </div>
                <div className="comman-list with-after-40 pb-1 map-box-parent">
                <div className=" map-box radius_5" style={{height : "120px"}}>
                <GoogleMap
                      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_SECRET}
                      // id="marker-example"
                      options={{
                        zoom :"10",
                        zoomControl: true,
                        streetViewControl: false,
                        scrollwheel: false,
                        styles: mapStyle,
                      }}
                      mapContainerStyle={containerStyle}
                      center={selectedLatLang}
                      zoom={8}
                    >
                      <Marker position={selectedLatLang} />
                    </GoogleMap>
                    </div>
                  {/* <iframe className="map-box radius_5" 
                  src={`//maps.google.com/maps?q=${22.277631407043938},${70.780673714749}&z=15&output=embed`}
                  // src={`https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10005.660822865373!2d${51.17457}!3d${6.385506}!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbc2d05dc3af26862!2sBORUSSIA-PARK!5e0!3m2!1sen!2suk!4v1448289882279`}
                    width="100%"
                    height="100%"
                    frameborder="0"
                    allowFullScreen
                    crossorigin="anonymous"
                    // aria-hidden="false"
                    // tabIndex="0"
                    >
                  </iframe> */}
                </div>
              </div>
            </div>
            <div className="right-content-part width-calc-300 ms-2">
              <ImageViewer 
                attachmentURL={selectedAttachment.image_url} 
                attachmentDate={selectedAttachment.dateadded} 
                changeAttach={changeAttach} 
                showPrevBtn={showPrevBtn} 
                showNextBtn={showNextBtn}
                handleAction={handleAction} 
                CloseCommentModal={CloseCommentModal}
                isChat={isChat}
                docType={docType}
              />
            </div>
          </div>
      {/* <Modal fullscreen={fullscreen}

        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        scrollable

      >
        <Modal.Header closeButton className="d-none">
          <Modal.Title>Modal Title</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0">
          
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end d-none">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Save</Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};

export default ImageDetailOffcanvas;
