import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {
  ArrowRight,
  Check,
  Copy,
  HourglassSimpleMedium,
  MagnifyingGlass,
  NotePencil,
  X,
} from "phosphor-react";
import { Col } from "react-bootstrap";
import { Row } from "reactstrap";
import {
  _l,
  getImagePreviewIcon,
  showContactDirectory,
  showError,
  showSuccess,
  showSuccessMessage,
  validateEmail,
} from "../../../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ProposalCard from "./Proposal/ProposalCard";
import { addEditDocumentComment } from "../../../actions/documents-actions/documents-actions";
import MessagePlaceHolderScreen from "./MessagePlaceHolderScreen";
import BenchmarkServices from "../../../services/benchmark-services";
import { getBenchmarkDetail, leftPanelFilters, setOpenOrCloseContactDirectoryChat } from "../../../actions/customer";
import GeneralCardView from "../../Documents/GeneralCard";
import CommanLoader from "../../Loader/CommanLoader";
import TopicSettingModal from "../../Modals/TopicSettingModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import AcceptProposalSignatureModal from "../../Modals/AcceptProposalSignatureModal";
import benchmarkServices from "../../../services/benchmark-services";
import customerServices from "../../../services/customer-services";
import { fetchMessages, getGroupDetails, setClearmessage } from "../../../actions/chat";
import jQuery from "jquery"
import UploadCompanyContractModal from "../../Modals/UploadCompanyContractModal";

const ProposalList = ({ mentionList, setStep, getComments }) => {
  const { benchmarkDetail, benchmarkSchedule } = useSelector(
    (state) => state.customer
  );
  const PROPSALS_STATUS = [
    {
      title: "l_send",
      value: "send",
      status: 0,
      color: "color-green",
    },
    {
      title: "l_interested",
      value: "interested",
      status: 1,
      color: "color-blue",
    },
    {
      title: "l_not_interested",
      value: "not_interested",
      status: 2,
      color: "color-orange",
    },
    {
      title: "l_contract",
      value: "contract",
      status: 3,
      color: "color-yellow",
    },
  ];
  const [searchParams, setSerachParams] = useSearchParams();

  let tab = searchParams.get("tab"); 
  let space = searchParams.get("space"); 
  let id = searchParams.get("id"); 
  let itemCount = 0;
  let proposals =
    benchmarkDetail.proposal_list && benchmarkDetail.proposal_list.length
      ? benchmarkDetail.proposal_list[0].proposals
      : [];


  const [selectedTab, setSelectedTab] = useState(tab ? tab :"send");
  const [proposalListCounts, setproposalListCounts] = useState({
    send: 0,
    interested: 0,
    not_interested: 0,
    contract: 0,
  });

  const [newComment, setNewComment] = useState("");
  const [increaseCount, setIncreaseCount] = useState(0);
  const [emailAddress, setEmailAddress] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [agentProfileInfo, setAgentProfileInfo] = useState({});
  const [showSignaturePad, setShowSignaturePad] = useState(false);
  const [showContractModal, setShowContractModal] = useState(false);
  const [currentProposal, setCurrentProposal] = useState({});
  const [signature, setSignature] = useState("");
  const [searchString, setSearchString] = useState("");
  const [proposalList, setproposalList] = useState(proposals && proposals.length > 0 ? proposals : []);
  const [selectedContract, setSelectedContract] = useState();
  const [imagePreviews, setImagePreviews] = useState([]);
  const [contact, setContact] = useState()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  if (proposals && proposals.every((proposal) => proposal.requirement_id > 0)) {
    proposals.sort((a, b) =>
      a.agent_status == 3 ? -1 : b.agent_status == 3 ? 1 : 0
    );
  } else {
    proposals.sort(
      (a, b) =>
        Number(b.amount) - Number(a.amount) ||
        Number(b.is_updated) - Number(a.is_updated)
    );
    let acceptedProposals = proposals.filter((x) => x.status == 3);
    let pendingProposals = proposals.filter((x) => x.status != 3);
    proposals = acceptedProposals.concat(pendingProposals);
  }

  if (benchmarkSchedule && benchmarkSchedule.total_count) {
    Object.values(benchmarkSchedule.total_count).forEach((x) => {
      itemCount += Number(x);
    });
  }



  const showAmountUpdateMessage = () => {
    let message = "";

    return message;
  };

  const sendNewProposal = async () => {
    if (emailAddress && emailAddress.length && validateEmail(emailAddress)) {
      try {
        const response = await BenchmarkServices.addBulkProvidersToCategory(
          benchmarkDetail.project_id,
          benchmarkDetail.provider_id,
          [
            {
              email: emailAddress,
              name: "",
              phonenumber: "",
              company_name: "",
            },
          ],
          0,
          benchmarkDetail.id,
          1
        );
        if (response.status) {
          showSuccess("l_provider_invited");
          dispatch(
            getBenchmarkDetail(benchmarkDetail.id, benchmarkDetail.client_id)
          );
        } else {
          showError("l_something_went_wrong");
        }
      } catch (e) {
        console.error(e);
      } finally {
        setEmailAddress("");
      }
    } else {
      showError("l_please_enter_email");
      return false;
    }
  };

  const editBenchmark = () => {
    if (benchmarkDetail.requirement_id > 0) {
      setStep("view-job");
    } else {
      setStep("basic-details");
    }
  };

  const jobProposalAction = (proposal, action, signature) => {
    let messageText =
      action === "accept"
        ? "l_confirm_accept_proposal"
        : "l_confirm_reject_proposal";
    setShowLoader(true);    
     BenchmarkServices.operatorAcceptRejectJobProposal(
      benchmarkDetail.project_id,
      benchmarkDetail.requirement_id,
      proposal.id,
      action,
      signature,
      benchmarkDetail.id
    ).then((response)=>{
      if (response.status) {
        dispatch(getBenchmarkDetail(benchmarkDetail.id));
        showSuccessMessage(response.message);
        searchParams.delete("tab");
        searchParams.append("tab", "contract");
        setSerachParams(searchParams);
        setShowSignaturePad(false);
        setShowLoader(false);
      } else if (response && Number(response.status) != 1) {
        showError(response.message);
        setShowSignaturePad(false);
        setShowLoader(false);
      }
    })
  };

  const viewOwnerDetails = (data) => {
    searchParams.set("user", data.provider_details.staff_id);
    setSerachParams(searchParams);
    setAgentProfileInfo(data.provider_details);
  };

  const closeAgentProfileView = () => {
    setAgentProfileInfo({});
    searchParams.delete("user");
    setSerachParams(searchParams);
  };

  const handleList = (tab) => {
    switch (tab) {
      case "send":
        setproposalList(
          proposals.filter((proposal) => Number(proposal.agent_status) == 4)
        );
        break;
      case "interested":
        setproposalList(
          proposals.filter((proposal) => Number(proposal.agent_status) == 3 && Number(proposal.status) != 3)
        );

        break;
      case "not_interested":
        setproposalList(
          proposals.filter((proposal) => Number(proposal.agent_status) == 2)
        );

        break;
      case "contract":
        setproposalList(
          proposals.filter(
            (proposal) =>
              Number(proposal.agent_status) == 3 && Number(proposal.status) == 3
          )
        );
        break;

      default:
        setproposalList(
          proposals.filter((proposal) => benchmarkDetail.requirement_id > 0 ? Number(proposal.agent_status) == 4 : proposal )
        );
        break;
    }
  };

  const preparePreview = (files) => {
    let previrewArray = [];
    Array.from(files).map((img) => {
      let preview_url = getImagePreviewIcon(img);
      previrewArray.push({ name: img.name, url: preview_url });
    });
    setImagePreviews(previrewArray);
  };
  const removeImageSelection = (name) => {
    imagePreviews.forEach((thumbnail) => {
      URL.revokeObjectURL(thumbnail.url);
    });
    const updatedFileList = Array.from(selectedContract).filter(
      (img) => img.name != name
    );

    // setImageBuffer(updatedFileList);
    preparePreview(updatedFileList);
  };

  const uploadContract =  (file,proposal) => {
    if (proposal.agent_status == 3 && proposal.status == 4 && proposal.is_agent_signature_upload == 0) {
      setSelectedContract(file);
      setShowContractModal(true);     
      setContact(URL.createObjectURL(file))

    }else{
      showError("l_can_not_upload_contract");
    }
  }

  const saveCompanyContract = (proposal, action, signature) =>{
    setShowLoader(true);    

          benchmarkServices.uploadCompanyContract(proposal.requirement_id, proposal.id,benchmarkDetail.id,selectedContract,signature).then((res)=>{
        if (res && res.status) {
          setShowLoader(false);    
          showSuccessMessage("l_contract_uploaded");
          searchParams.delete("tab");
          searchParams.append("tab", "interested");
          setSerachParams(searchParams);
          setShowContractModal(false);
          dispatch(getBenchmarkDetail(benchmarkDetail.id));

        }else{
          setShowLoader(false);    
          showError(res.message);
          setShowContractModal(false);

        }
      })
  }
  const createChatChannels =  async (firsUserID, secondUserID)=>{
    try {
     let response = await customerServices.createChatbetweenAgentandOperator(firsUserID, secondUserID)

      if (response && response.status && response.data) {
        
        // return
        let groupID = response.data.result._id
        localStorage.setItem("activeGroupId", groupID)
        if (response.data &&response.data.result) {
          dispatch(getGroupDetails(response.data.result));
        }
        dispatch(leftPanelFilters({
          main_key: response.data.result.extraData.mainKey ,
          filter_key: response.data.result.extraData.filterKey,
          title: "",
          main_sub_key: response.data.result.extraData.mainSubKey,
        }));
        showContactDirectory();
        dispatch(setOpenOrCloseContactDirectoryChat(true));
        dispatch(setClearmessage());
        dispatch(fetchMessages(
          localStorage.getItem("chatUserId"),
          groupID,
          0,
          () => { },
          1,
        ))
        navigate("/aichat");
      }
      
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {

    setproposalListCounts({
      send: proposals.filter((proposal) => Number(proposal.agent_status) == 4)
        .length,
      interested: proposals.filter(
        (proposal) => Number(proposal.agent_status) == 3 && Number(proposal.status) != 3
      ).length,
      not_interested: proposals.filter(
        (proposal) => Number(proposal.agent_status) == 2
      ).length,
      contract: proposals.filter(
        (proposal) =>
          Number(proposal.agent_status) == 3 && Number(proposal.status) == 3
      ).length,
    });
  }, [proposals.length]);

  useEffect(() => {
    if (selectedContract) {
      preparePreview(selectedContract);
    }
  }, [selectedContract]);

  useEffect(() => {
    if (tab) {
      setSelectedTab(tab);
      handleList(tab);
    }  
  }, [tab])
    
  return (
    <>
      
        <div className="w-100 d-flex flex-column flex-grow-1 ">
          <Row className="end-0 m-0 z-index-2 position-sticky row start-0 top-0 bgOfficelisting  g-0 light-theme-grey-light-bg light-theme-overall-scroll-gery-shadow">
            <Col className="mb-32px" xl={12}>
              <div className="select-input-cox p-35px w-100 align-items-center d-flex">
                <input
                  className="bottom-0 end-0 form-check-input h-100 position-absolute start-0 top-0 w-100 bg-transparent"
                  name="benchmark-detail"
                  checked
                  disabled
                  type="radio"
                />
                <div class="w100minus100per text-truncate">
                  <div class="c-font f-16 fw-semibold text-truncate title-fonts">
                    {benchmarkDetail.company_name}
                    <span className="ps-10px">
                      {`#${_l(benchmarkDetail.id)} - ${
                        benchmarkDetail.category
                      }`}
                      <span
                        className={`badge rounded-pill ms-2 ${
                          benchmarkDetail.is_opened > 0
                            ? "text-done"
                            : "text-danger"
                        }`}
                      >
                        {benchmarkDetail.is_opened > 0
                          ? _l("l_open")
                          : _l("l_closed")}
                      </span>
                    </span>
                  </div>
                  <div class="c-font color-white-60 f-12 pt-1 text-truncate">
                    {benchmarkDetail.address}
                  </div>
                  {/* <div className="px-3">
                    <div className="c-font f-20 fw-semibold title-fonts py-2">
                      {`#${_l(benchmarkDetail.id)} - ${benchmarkDetail.category}`}
                      <span className={`badge rounded-pill ms-2 ${benchmarkDetail.is_opened > 0 ? "text-done" : "text-danger"}`}>
                        {benchmarkDetail.is_opened > 0 ? _l("l_open") : _l("l_closed")}
                      </span>
                    </div>
            
                  </div> */}
                </div>
                <div className="align-items-center d-flex justify-content-between">
                  <div className="with_separator_10 text-center flex-grow-1 px-3">
                    <div className="c-font f-16 fw-semibold text-truncate title-fonts">
                      {benchmarkDetail.startdate &&
                      moment(new Date(benchmarkDetail.startdate)).isValid()
                        ? moment(new Date(benchmarkDetail.startdate)).format(
                            "DD/MM/YYYY"
                          )
                        : "-"}
                    </div>
                    <div className="c-font color-white-60 f-12 pt-1 text-truncate">
                      {_l("l_start_date")}
                    </div>
                  </div>
                  <div className="with_separator_10 text-center flex-grow-1 px-3">
                    <div className="c-font f-16 fw-semibold text-truncate title-fonts">
                      €{benchmarkDetail.max_budget}
                    </div>
                    <div className="c-font color-white-60 f-12 pt-1 text-truncate">
                      {_l("l_budget")}
                    </div>
                  </div>
                  <div className="with_separator_10 text-center flex-grow-1 px-3">
                    <div className="c-font f-16 fw-semibold text-truncate title-fonts">
                      {itemCount}
                    </div>
                    <div className="c-font color-white-60 f-12 pt-1 text-truncate">
                      {_l("l_item")}
                    </div>
                  </div>
                  <div className="with_separator_10 text-center flex-grow-1 px-3 pe-0 before-dnone">
                    <div className="c-font f-16 fw-semibold text-truncate title-fonts color-green">
                      {proposals.length}
                    </div>
                    <div className="c-font color-white-60 f-12 pt-1 text-truncate">
                      {_l("l_proposal")}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            {benchmarkDetail.is_opened > 0 &&
            benchmarkDetail.requirement_id <= 0 ? (
              <Col className="" xl={12}>
                <div className="d-flex align-items-center mb-32px gap10">
                <Form.Group className="flex-grow-1 c-input-box position-relative chat-main-box comman_action_icon">
                    <div className="chat-comment-box d-flex align-items-center position-relative">
                      <input
                        className="form-control bg-transparent border-0 p-0 shadow-none "
                        type="email"
                        name="email"
                        placeholder={_l("l_email_placeholder")}
                        value={emailAddress}
                        onChange={(e) => {
                          setEmailAddress(e.target.value);
                        }}
                      />
                      <a
                        href="#/"
                        className="action_icon with_bg h32w32"
                        onClick={sendNewProposal}
                      >
                        <ArrowRight
                          size={18}
                          weight="light"
                          className="c-icons"
                        />
                      </a>
                    </div>
                  </Form.Group>
                  <div className="comman_action_icon">
                  <a 
                    href="#/" 
                    className="action_icon h50w50 h53px with_bg" 
                    onClick={(e) => 
                      {
                        navigator.clipboard.writeText(`${process.env.REACT_APP_MYR_WEB_URL}/benchmark/${benchmarkDetail.benchmark_hash}`);
                        showSuccess("l_copied_to_clipboard");
                      }
                    }>
                  <Copy
                      size={18}
                      weight="light"
                      className="c-icons"
                    />
                  </a>
                  </div>
                  {/* <Button variant="primary" className="ms-1" onClick={sendNewProposal}>
                    Invite
                  </Button> */}
                </div>
              </Col>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            <div className="d-flex z-index-2 position-sticky start-0 top-0 mb-10">
              <Col
                className=" pe-0"
                xl={
                  proposals && proposals.findIndex((x) => x.status == 3) === -1
                    ? 8
                    : 12
                }
              >
                <div className="chat-main-box p-0">
                  <div className="chat-comment-box bg-transparent p-0 radius_3 border-0 comman_action_icon">
                    <div className="comment-input-box pb-0">
                      <div className="d-flex align-items-center">
                        <div className="chat-main-box with-bg flex-grow-1 m-0">
                          <div className="chat-comment-box">
                            <div className="comment-input-box">
                              <div className="d-flex align-items-center position-relative">
                                <input
                                  className="bg-transparent border-0 form-control h-35 input-box mr-10 position-relative"
                                  type=""
                                  value={searchString}
                                  placeholder={_l("l_search")}
                                  onChange={(e) => {
                                    setSearchString(e.target.value);
                                  }}
                                />
                                {searchString != "" && (
                                  <a
                                    href="#/"
                                    className="action_icon with_bg h32w32 mr-10"
                                    onClick={() => {
                                      setSearchString("");
                                    }}
                                  >
                                    <X
                                      size={18}
                                      weight="light"
                                      className="c-icons"
                                    />
                                  </a>
                                )}
                                <a
                                  href="#/"
                                  className="action_icon with_bg h32w32 "
                                >
                                  <MagnifyingGlass
                                    size={18}
                                    weight="light"
                                    className="c-icons"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              {proposals && proposals.findIndex((x) => x.status == 3) === -1 ? (
                <Col className="ps-1 comman_action_icon" xl={4}>
                  <Button
                    className="h-100 w-100 border-0 d-flex justify-content-center align-items-center light-theme-white-bg"
                    variant="white-03"
                    onClick={() => editBenchmark()}
                  >
                    <div className="action_icon with_bg h32w32 me-2">
                      <NotePencil
                        size={18}
                        weight="light"
                        className="c-icons"
                      />
                    </div>
                    <span>
                      {benchmarkDetail.requirement_id > 0
                        ? _l("l_view_job")
                        : _l("l_edit_my_benchmark")}
                    </span>
                  </Button>
                </Col>
              ) : (
                <React.Fragment> </React.Fragment>
              )}
            </div>
            <div className={`"w-100  px-0  ${benchmarkDetail.requirement_id > 0 ? "registration-footer-content" : ""}`}>
              <div className="position-sticky top-0">
                <Form.Label
                  className="c-font f-14 title-fonts position-sticky py-2 m-0 z-index-2 rounded-0 w-100 light-theme-grey-light-bg"
                  // style={{ top: "273px" }}
                >
                  {proposals.length ? _l("l_proposals") : ""}
                </Form.Label>
                {benchmarkDetail && benchmarkDetail.requirement_id > 0 && <div className="align-items-center justify-content-between btn-white-05 d-flex p-1  radius_5 text-uppercase overflow-auto">
                  {PROPSALS_STATUS.map((proposal) => {
                    return (
                      <>
                        <label
                          className={`row-btn-label w-25`}
                          for="flexRadioDefault1"
                        >
                          <input
                            type="radio"
                            className="hidden-input"
                            name="proposal_status"
                            value={proposal.value}
                            checked={proposal.value == selectedTab}
                            onChange={(event) => {
                              searchParams.delete("tab");
                              searchParams.append("tab", event.target.value);
                              setSerachParams(searchParams);
                              setSelectedTab(event.target.value);
                              handleList(event.target.value);
                            }}
                          />
                          <div className="row-btn-box c-font f-12 p-2 fw-semibold title-fonts cblue">
                            <span className={`${proposal.color}`}>{_l(proposal.title)}</span>
                            <span className={`ps-1 ${proposal.color}`}>
                              -{" "}
                              {proposal.status == 0
                                ? proposalListCounts.send
                                : proposal.status == 1
                                ? proposalListCounts.interested
                                : proposal.status == 2
                                ? proposalListCounts.not_interested
                                : proposal.status == 3
                                ? proposalListCounts.contract
                                : 0}
                            </span>
                          </div>
                        </label>
                      </>
                    );
                  })}
                </div>}
              </div>
            </div>
          </Row>
          {showAmountUpdateMessage() ? (
            <div className="c-font color-red f-15 fw-semibold my-2 text-truncate title-fonts">
              {showAmountUpdateMessage()}
            </div>
          ) : (
            <React.Fragment></React.Fragment>
          )}

          <div className="flex-grow-1">
            {proposalList.length ? (
              proposalList
                .filter((w) =>benchmarkDetail.requirement_id > 0 ?
                w.provider_details && w.provider_details.full_name
                    .toLowerCase()
                    .includes(searchString.toLowerCase()) 
                    : w.company_name.toLowerCase()
                    .includes(searchString.toLowerCase())
                )
                .map((proposal, index) => {
                  if (benchmarkDetail.requirement_id > 0) {
                    return (
                      <>
                        <GeneralCardView
                          key={index}
                          cardTitle={`#${proposal.id} - ${proposal.provider_details.full_name}`}
                          ownerDetails={{
                            name: `#${proposal.id} - ${proposal.provider_details.full_name}`,
                            profile_image:
                              proposal.provider_details.profile_image_url,
                          }}
                          pills={[]}
                          // pills={getJobProposalPills(proposal)}
                          hideChatAndCall={true}
                          onClick={() => {}}
                          shoWdetails={true}
                          details={proposal.provider_details}
                          ActionButton={
                            proposal.status == 4 &&
                            proposal.agent_status == 3 &&
                            proposal.is_agent_signature_upload == 1 &&
                            proposal.is_company_contract_upload == 1  ? (
                              <div className="d-flex ">
                                <Button
                                  className="me-2"
                                  variant="outline-danger"
                                  size="sm"
                                  onClick={() => {
                                    jobProposalAction(proposal, "reject");
                                  }}
                                >
                                  <div className="d-flex align-items-center">
                                    <X
                                      size={18}
                                      className="c-icons pe-1"
                                      weight="light"
                                    />
                                    {_l("l_reject")}
                                  </div>
                                </Button>
                                <Button
                                  variant="primary"
                                  size="sm"
                                  onClick={() => {
                                    setContact(proposal.contract_preview_url)
                                    setCurrentProposal(proposal);
                                    setShowSignaturePad(true);
                                    // jobProposalAction(proposal, "accept");
                                  }}
                                >
                                  <div className="d-flex align-items-center">
                                    <Check
                                      size={18}
                                      weight="light"
                                      className="c-icons black pe-1"
                                    />
                                    {_l("l_accept")}
                                  </div>
                                </Button>
                              </div>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )
                          }
                          setCurrentProposal={setCurrentProposal}
                          proposal={proposal}
                          viewOwnerDetails={() => {
                            viewOwnerDetails(proposal);
                          }}
                          uploadContract={
                            (file)=>{
                              uploadContract(file,proposal)
                            }}
                          imagePreviews={imagePreviews}
                          removeImageSelection={(file)=>{
                            removeImageSelection(file);
                          }}
                          showDownloadIcon={proposal.company_contract_url ? true : false}
                          showUploadIcon={proposal.status == 4 && proposal.agent_status == 3 && proposal.is_agent_signature_upload == 0 ? true : false}
                          downloadFile = {()=>{
                            if (proposal.company_contract_url) {
                              window.open(proposal.company_contract_url,"_self");
                            }
                          }}
                          createChatChannels={()=>{
                            createChatChannels(localStorage.getItem("staff_id"),proposal.operator_id)
                          }}
                          setSelectedContract={setSelectedContract}
                        />
                      </>
                    );
                  } else {
                    return (
                      <ProposalCard
                        proposal={proposal}
                        benchmark={benchmarkDetail}
                        key={index}
                        increaseCount={increaseCount}
                        setIncreaseCount={setIncreaseCount}
                        setStep={setStep}
                        getComments={getComments}
                      />
                    );
                  }
                })
            ) : (
              <MessagePlaceHolderScreen
                className=""
                description={
                  benchmarkDetail.requirement_id > 0
                    ? "l_waiting_for_applications"
                    : "l_waiting_for_proposals"
                }
                image={{ name: HourglassSimpleMedium }}
                title={"l_congratulation"}
              />
            )}
          </div>
        </div>
      
      {agentProfileInfo && Object.keys(agentProfileInfo).length ? (
        <TopicSettingModal
          data={{
            main_key: "edit_profile",
            id: -1,
            main_key_title: _l("l_edit_profile"),
            title: "l_all",
            is_view_only: 1,
            is_agent_profile: 1,
          }}
          handleClose={() => {
            closeAgentProfileView();
          }}
          hideSearchArea={true}
          hideFilterArea={true}
          show={agentProfileInfo && Object.keys(agentProfileInfo).length}
          title={_l("l_agent_profile")}
          titlePrefix={""}
          project_id={0}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
  
      {showContractModal ? (
        <UploadCompanyContractModal
          show={showContractModal}
          external={true}
          projectId={0}
          estimate={benchmarkDetail}
          hash={""}
          handleClose={() => {
            setShowContractModal(false);
            setContact();
            setSelectedContract();
            setImagePreviews([]);
          }}
          callBack={(signature) => {
            // handleEstimateStatus("Accepted");
            saveCompanyContract(currentProposal, "accept", signature);
          }}
          setSignature={setSignature}
          loder={showLoader}
          contract={contact ? contact : selectedContract}
        />
      ) : (
        <></>
      )}
      {showSignaturePad ? (
        <AcceptProposalSignatureModal
          show={showSignaturePad}
          external={true}
          projectId={0}
          estimate={benchmarkDetail}
          hash={""}
          handleClose={() => {
            setShowSignaturePad(false);
            setContact();
            setSelectedContract();
            setImagePreviews([]);
          }}
          callBack={(signature) => {
            jobProposalAction(currentProposal, "accept", signature);
          }}
          setSignature={setSignature}
          loder={showLoader}
          contract={contact ? contact : selectedContract}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ProposalList;
